import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import ACSnackbar from '../../components/ACSnackbar';
import LogoAndNameForm from '../../components/Company/OnboardingFreemium/logoAndNameForm';
import PublicRegistration from '../../layouts/PublicRegistration';
import { useAccount } from '../../contexts/Account';
import {
  updateCompanyName,
  updateCompanyStoryAndMission,
  uploadCompanyLogoCall,
  updateCompanyBrandFreemiumCall,
} from '../../services/company_services';

const CompanyOnboardingFreemiumStep3 = () => {
  const history = useHistory();
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const { asLoggedUserDetails, accountDetails } = useAccount();
  const [error, setError] = useState('Something went wrong');
  const [showSnackbar, setShowSnackbar] = useState(false);

  async function onSubmit(companyName, uploaderOutput, companyStory) {
    try {
      if (companyName) {
        await sendFormData(companyName);
      }
      if (companyStory) {
        let data = {
          CONTACT_ID: asLoggedUserDetails.userId,
          STORY_AND_MISSION: companyStory,
        };
        await updateCompanyStoryAndMission(data);
      }
      if (uploaderOutput.image && uploaderOutput.originalImage) {
        await sendOriginalLogo(uploaderOutput);
      }
      if (
        companyName ||
        (uploaderOutput.image && uploaderOutput.originalImage)
      ) {
        await updateCompanyBrandFreemiumCall({
          COMPANY_ID: accountDetails.id,
          BRAND_FREEMIUM: true,
        });
      }
      history.push('/company/new/step4');
    } catch (error) {
      setShowSnackbar(true);
      console.log(error);
    }
  }
  async function sendFormData(companyName) {
    try {
      let data = {
        CONTACT_ID: asLoggedUserDetails.userId,
        COMPANY_NAME: companyName || '',
        DISPLAY_NAME: companyName || '',
      };

      await updateCompanyName(data);
    } catch (error) {
      throw error;
    }
  }

  async function sendOriginalLogo(uploaderOutput) {
    try {
      await uploadCompanyLogoCall({
        CONTACT_ID: asLoggedUserDetails.userId,
        COMPANY_LOGO_EDITED: uploaderOutput.image || '',
        COMPANY_LOGO_ORIGINAL: uploaderOutput.originalImage || '',
      });
    } catch (err) {
      throw err;
    }
  }

  const onBack = () => {
    history.push('/company/new/step2');
  };

  return (
    <>
      <ACSnackbar
        onClose={() => setShowSnackbar(false)}
        open={showSnackbar}
        severity="error"
        text={error}
      />
      <PublicRegistration hideImage={isXS}>
        <LogoAndNameForm onSubmit={onSubmit} onBack={onBack} />
      </PublicRegistration>
    </>
  );
};

export default CompanyOnboardingFreemiumStep3;
