export const modifyAllConversations = (newMessage, conversations) => {
  const newConversations = conversations;
  const index = newConversations.findIndex(
    (item) => item.conversationId === newMessage.conversationId
  );
  if (index >= 0) {
    newConversations[index].lastMessage = newMessage.message;
    newConversations[index].lastMessageDate = newMessage.createdAt;
    if (!window.location.search.includes(newMessage.conversationId)) {
      newConversations[index].unreadCount += 1;
    }
    const item = newConversations.splice(index, 1)[0];
    newConversations.splice(0, 0, item);
  } else {
    newConversations.splice(0, 0, newMessage);
  }
};
