import React, { useState } from 'react';
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import { Circle, Clear, Edit } from '@mui/icons-material';

const useStyles = () => ({
  itemExperience: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: '20px',
  },
  positionText: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '24px',
    letterSpacing: -0.43,
  },
  dateText: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '24px',
    letterSpacing: -0.43,
  },
  editButton: {
    border: 'none',
    padding: '0px',
    marginRight: '10px',
    display: 'inline',
    backgroundColor: '#fff',
    alignItems: 'center',
    cursor: 'pointer',
  },
  editIcon: {
    width: '15px',
    height: '15px',
    display: 'inline',
    marginRight: '2px',
  },
  removeIcon: {
    fontSize: '17px',
    bottom: '-3px',
    position: 'relative',
    display: 'inline',
    cursor: 'pointer',
  },
  listItemIcon: {
    minWidth: '16px',
    color: '#D9D9D9',
  },
  listItemLabel: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: -0.43,
  },
  listItem: {
    padding: '0',
  },
  listContainer: {
    padding: '10px',
    marginBottom: '10px',
  },
});

function AdvisorExperience(props) {
  const {
    experienceArray,
    experienceHighlight,
    edit,
    editAction,
    deleteAction,
  } = props;
  const [isDeleting, setIsDeleting] = useState(false);
  const classes = useStyles();

  const extractBoldWords = (htmlString) => {
    const boldWords = new Set();
    const regex = /<b>(.*?)<\/b>/g;
    let match;
    while ((match = regex.exec(htmlString)) !== null) {
      boldWords.add(match[1]);
    }
    return Array.from(boldWords);
  };

  const wrapBoldWords = (array, boldWords) => {
    return array.map((item) => {
      let newItem = item;
      let newPosition = item.position || '';
      let newCompany = item.company || '';
      let newNotes = item.notes || [];
      boldWords.forEach((word) => {
        const regex = new RegExp(`\\b${word}\\b`, 'gi');
        newPosition = newPosition.replace(regex, `<span style="background-color: #FEF8D1;">${word}</span>`);
        newCompany = newCompany.replace(regex, `<span style="background-color: #FEF8D1;">${word}</span>`);
        newNotes = newNotes.map((note) => {
          return note.replace(regex, `<span style="background-color: #FEF8D1;">${word}</span>`);
        });
      });
      return {
        ...newItem,
        position: newPosition,
        notes: newNotes,
        company: newCompany,
      };
    });
  };

  function renderExperienceArray() {
    if (experienceHighlight) {
      const boldWords = extractBoldWords(experienceHighlight);
      const highlightedArray = wrapBoldWords(experienceArray, boldWords);
      return highlightedArray;
    }
    return experienceArray;
  }

  return (
    <div
      style={{
        marginTop: '-10px',
      }}
    >
      {renderExperienceArray().map((exp, index) => (
        <div style={classes.itemExperience} key={exp.position + exp.company}>
          {edit ? (
            <div>
              <button
                onClick={() => {
                  editAction(index);
                }}
                type="submit"
                style={classes.editButton}
              >
                <Edit color="primary" sx={classes.editIcon} />
                <Typography
                  variant="body1"
                  color="primary"
                  sx={{ display: 'inline' }}
                >
                  Edit
                </Typography>
              </button>
              <button
                onClick={() => {
                  setIsDeleting(index);
                }}
                type="submit"
                style={classes.editButton}
              >
                <Clear sx={classes.removeIcon} />
              </button>
              {isDeleting === index ? (
                <>
                  <Typography
                    variant="body1"
                    style={{
                      display: 'inline',
                      marginRight: 10,
                      marginLeft: 10,
                    }}
                  >
                    Are you sure you want to remove this position?
                  </Typography>
                  <Typography
                    variant="body1"
                    color="error"
                    sx={classes.editButton}
                    onClick={() => {
                      setIsDeleting(false);
                    }}
                  >
                    Cancel
                  </Typography>
                  <Typography
                    variant="body1"
                    color="primary"
                    sx={classes.editButton}
                    onClick={() => {
                      setIsDeleting(false);
                      deleteAction(index);
                    }}
                  >
                    Confirm
                  </Typography>
                </>
              ) : null}
            </div>
          ) : null}
          <Typography sx={classes.positionText}>
            <span
              dangerouslySetInnerHTML={{
                __html: `${exp.position} - ${exp.company}`,
              }}
            />
          </Typography>
          <Typography sx={classes.dateText}>
            {`${exp.startDate} - ${exp.endDate ? exp.endDate : 'Present'}`}
          </Typography>
          <List sx={classes.listContainer}>
            {exp.notes &&
              exp.notes.map((note, index2) => (
                <ListItem key={`note-${index2}`} sx={classes.listItem}>
                  <ListItemIcon sx={classes.listItemIcon}>
                    <Circle sx={{ fontSize: 7 }} />
                  </ListItemIcon>
                  <ListItemText
                    classes={{ primary: classes.listItemLabel }}
                    primary={
                      <span dangerouslySetInnerHTML={{ __html: note }} />
                    }
                  />
                </ListItem>
              ))}
          </List>
        </div>
      ))}
    </div>
  );
}

export default AdvisorExperience;
