import React from 'react';
import { Typography, useMediaQuery } from '@mui/material';

const useStyles = (isSM) => ({
  publicContainer: {
    paddingLeft: isSM ? '10px' : '20px',
    paddingRight: isSM ? '10px' : '20px',
    paddingTop: '5px',
    paddingBottom: '5px',
    borderRadius: '25px',
    border: 'solid',
    borderWidth: '2px',
    backgroundColor: '#EAEFF7',
    borderColor: '#EAEFF7',
    color: 'common.black',
  },
  publicTags: {
    color: '#232B35',
    fontWeight: 500,
  },
  container: {
    paddingLeft: isSM ? '10px' : '20px',
    paddingRight: isSM ? '10px' : '20px',
    paddingTop: '5px',
    paddingBottom: '5px',
    borderRadius: '25px',
    border: 'solid',
    borderWidth: '2px',
    backgroundColor: '#F5F7FA',
    borderColor: '#F5F7FA',
    color: '#232B35',
  },
});

function Tag({ label, publicProfile }) {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);

  return (
    <div style={publicProfile ? classes.publicContainer : classes.container}>
      <Typography sx={classes.publicTags} variant="body1">
        {label}
      </Typography>
    </div>
  );
}

export default Tag;
