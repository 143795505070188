import React, { useState } from 'react';
import { Typography } from '@mui/material';
import MarkEmailUnreadOutlinedIcon from '@mui/icons-material/MarkEmailUnreadOutlined';
import AdvisorEmailChangeForm1 from './advisorEmailChangeForm1';
import AdvisorEmailChangeForm2 from './advisorEmailChangeForm2';

const useStyles = () => ({
  topRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleText: {
    fontWeight: 'bold',
  },
  bottomRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '15px',
  },

  fieldWrapperEmail: {
    width: '100%',
  },

  fieldWrapperEmailCode: {
    width: '100%',
  },
  iconContainer: {
    width: '7%',
    paddingTop: '4px',
  },
  text: {
    fontWeight: 'bold',
    width: '93%',
    fontSize: '14px',
  },
});

function AdvisorEmailChange(props) {
  const {
    title,
    resetFlowEmail,
    finishFlow,
    isCallSuccessfull,
    changeSuccessfullCall,
    actionCallback,
  } = props;
  const classes = useStyles();
  const [updateEmailFlow, setUpdateEmailFlow] = useState('SEND_NEW_EMAIL');

  function resetForm(values) {
    resetFlowEmail(values);
  }

  function changeFlowAction() {
    setUpdateEmailFlow('SEND_CODE');
  }

  function cardContectSelector() {
    if (!isCallSuccessfull && updateEmailFlow === 'SEND_NEW_EMAIL') {
      return (
        <div style={classes.fieldWrapperEmail}>
          <AdvisorEmailChangeForm1
            reset={resetForm}
            changeFlow={changeFlowAction}
            changeSuccessfullCall={changeSuccessfullCall}
            actionCallback={actionCallback}
          />
        </div>
      );
    }
    if (isCallSuccessfull && updateEmailFlow === 'SEND_CODE') {
      return (
        <div style={classes.fieldWrapperEmailCode}>
          <AdvisorEmailChangeForm2
            reset={resetForm}
            finishFlow={finishFlow}
            actionCallback={actionCallback}
          />
        </div>
      );
    }
    return (
      <div style={classes.fieldWrapperEmail}>
        <AdvisorEmailChangeForm1
          reset={resetForm}
          changeFlow={changeFlowAction}
          actionCallback={actionCallback}
        />
      </div>
    );
  }

  return (
    <>
      <div style={classes.topRow}>
        <div style={classes.iconContainer}>
          <MarkEmailUnreadOutlinedIcon icconname="key-outlined" />
        </div>
        <Typography variant="h1_old_font" sx={classes.text}>
          {title}
        </Typography>
      </div>
      <div style={classes.bottomRow}>{cardContectSelector()}</div>
    </>
  );
}

export default AdvisorEmailChange;
