import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Paper, Typography, useMediaQuery } from '@mui/material';
import { useAccount } from '../../../contexts/Account';
import AvatarsInvolved from '../../AvatarsInvolved';
import TextGroup from './TextGroup';
import TileEmptyState from './TileEmptyState';
import { ReactComponent as EmptyAdvisors } from './advisors_card_empty_state.svg';

const getClasses = ({ isSM, isAdvisorsRoute }) => ({
  tile: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: isSM ? '100%' : '240px',
    height: '240px',
    borderRadius: '16px',
    padding: '20px',
    marginBottom: isSM ? '10px' : '',
    backgroundColor: '#DBF7EB',
    cursor: !isAdvisorsRoute ? 'pointer' : 'default',
  },
  topText: {
    fontWeight: 500,
    fontSize: '16px',
    color: '#232B35',
  },
});

function CountAdvisorsTile() {
  const { accountDetails, isFreemium } = useAccount();
  const activeAdvisors = accountDetails.dashboardStats.totalAdvisors;
  const isAdvisorsRoute = window.location.pathname === '/advisors';
  const history = useHistory();

  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const classes = getClasses({ isSM, isAdvisorsRoute });

  function getLeftValue() {
    if (isXS) {
      return '190px';
    } else if (isSM) {
      return null;
    }
    return '120px';
  }

  function getRightValue() {
    if (isXS) {
      return null;
    } else if (isSM) {
      return '110px';
    }
    return null;
  }

  return (
    <Paper
      elevation={0}
      sx={classes.tile}
      id="b2bAdvisorsCard"
      onClick={() => {
        if (!isAdvisorsRoute) {
          history.push('/advisors');
        }
      }}
    >
      <Typography sx={classes.topText}>Advisors</Typography>
      {activeAdvisors > 0 ? (
        <>
          <TextGroup
            qty={activeAdvisors}
            decorator="Active advisors"
            width="100%"
            id="activeAdvisorsCount"
          />
          <Box display="flex" flexDirection="row" id="newAdvisorsCount">
            <TextGroup
              qty={accountDetails.dashboardStats.lastMonthAdvisors}
              decorator="New advisors"
              width="100%"
            />
            <Box
              position={isXS ? null : 'absolute'}
              top="148px"
              mt={isXS ? '16px' : '0px'}
              left={getLeftValue()}
              right={getRightValue()}
            >
              <AvatarsInvolved
                avatars={accountDetails.dashboardStats.advisorImages}
                avatarSize={isSM ? 'md' : 'sm'}
                noText
              />
            </Box>
          </Box>
        </>
      ) : (
        <TileEmptyState
          Icon={EmptyAdvisors}
          txt={
            'A tally of your advisors will appear ' +
            (isFreemium ? 'here' : "once you're live!")
          }
        />
      )}
    </Paper>
  );
}

export default CountAdvisorsTile;
