import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Edit } from '@mui/icons-material';

const useStyles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    alignItems: 'center',
    cursor: 'pointer',
  },
  borderless: {
    border: 'none',
    padding: '0px',
  },
  bordered: {
    border: 'solid 2px',
    borderColor: 'primary.main',
    borderRadius: '5px',
    padding: '5px 10px 5px 10px',
  },
  icon: {
    width: '15px',
    height: '15px',
    marginRight: '15px',
  },
  text: {
    fontWeight: 'bold',
  },
});

function EditButton({ onClick, withBorder, fremiumView }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <button
      onClick={onClick}
      type="submit"
      style={{
        ...classes.container,
        ...(!withBorder ? classes.borderless : classes.bordered),
        ...(fremiumView && { marginTop: '-6px' }),
      }}
    >
      <Edit color="primary" sx={classes.icon} />
      <Typography variant="body1_old_font" sx={classes.text} color="primary">
        {fremiumView ? 'EDIT' : t('EDIT-BUTTON-TEXT')}
      </Typography>
    </button>
  );
}

export default EditButton;
