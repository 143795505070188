import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  Chip,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  useMediaQuery,
} from '@mui/material';
import { useAccount } from '../../contexts/Account';
import PublicRegistration from '../../layouts/PublicRegistration';
import FileUploader from '../../components/ProfileUploaderFreemium';
import {
  freemiumB2BOnboardingStep2,
  uploadCompanyContactImgCall,
} from '../../services/company_services';
import ACSnackbar from '../../components/ACSnackbar';

const kindOfWorkOptions = [
  'Founder / CEO',
  'Finance',
  'Operations',
  'Marketing',
  'Sales',
  'Human Resources',
  'IT Admin',
  'Engineering',
  'Customer Service',
  'Internal Communication',
  'Product Management',
  'Product Design',
  'Project / Program Management',
  'Knowledge Management',
  'Creative',
  'Educator',
  'Student',
  'Other',
];

const roleOptions = [
  'Executive (C-level / VP)',
  'Department lead',
  'Team manager',
  'Team member',
  'Solo-preneur / Freelancer',
  'Using AdvisoryCloud just for myself',
];

const advisoryCloudUseOptions = [
  'Advice from our network',
  'Project specific advice',
  'Key decision support',
  'Collaborate with business contacts',
  'Functional insights',
  'Industry insights',
  'Professional mentorship',
];

const preferedAdviceOptions = [
  'Managed Advisory Board',
  'Focus Groups',
  'Executive Roundtables',
  '1-1  Meetings',
  'Custom Surveys',
];

export default function CompanyOnboardingFreemiumStep2() {
  const isXs = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const {
    accountDetails,
    asLoggedUserDetails,
    editAccountInformation,
    editCompanyContactInformation,
    tags,
    currentUserInfo,
  } = useAccount();
  const [kindOfWork, setKindOfWork] = useState('');
  const [role, setRole] = useState('');
  const [advisoryCloudUse, setAdvisoryCloudUse] = useState([]);
  const [preferedAdvice, setPreferedAdvice] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [imgFile, setImgFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const history = useHistory();

  const updateProfile = async () => {
    try {
      setLoading(true);
      if (imgFile && imgFile.originalImage && imgFile.image) {
        await sendProfileImg();
      }
      if (kindOfWork || role || advisoryCloudUse[0] || preferedAdvice[0]) {
        await updateCompany();
      }
      history.push('/company/new/step3');
    } catch (error) {
      console.log('error', error);
      setLoading(false);
      setShowSnackbar(true);
    }
  };

  async function sendProfileImg() {
    const response = await uploadCompanyContactImgCall({
      CONTACT_ID: asLoggedUserDetails.userId,
      CONTACT_EDITED_IMG: imgFile.image,
      CONTACT_ORIGINAL_IMG: imgFile.originalImage,
    });

    const fixedLocation =
      response.data.uploadContactImage.location.split('https://');

    const companyContactModifiedValues = {
      image: {
        ...response.data.uploadContactImage,
        location: fixedLocation[1],
      },
    };

    editCompanyContactInformation(companyContactModifiedValues);
  }

  async function updateCompany() {
    await freemiumB2BOnboardingStep2({
      contactId: asLoggedUserDetails.userId,
      companyId: accountDetails.id,
      kindOfWork,
      role,
      advisoryCloudUse,
      preferedAdvice,
      desiredSkillsTagIds: selectedSkills,
    });
    editAccountInformation({
      kindOfWork,
      role,
      advisoryCloudUse,
      preferedAdvice,
    });
  }

  function displayChips(selected, getFromObject) {
    let selectedLabel;
    if (getFromObject) {
      selectedLabel = tags.skills.find(
        (option) => option.id === selected[0]
      ).label;
    } else {
      selectedLabel = selected[0];
    }
    selectedLabel =
      selectedLabel.length > 19
        ? selectedLabel.slice(0, 19) + '...'
        : selectedLabel;
    return (
      <>
        <Chip
          label={selectedLabel}
          variant="filled"
          style={{
            margin: '0',
            padding: '0',
          }}
        />
        {selected.length > 1 ? (
          <Chip
            label={`+${selected.length - 1} selected`}
            variant="filled"
            style={{
              margin: '0',
              padding: '0',
            }}
          />
        ) : null}
      </>
    );
  }

  const selectStyling = {
    margin: '4px 0px',
    width: '100%',
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',
      height: '50px',
      backgroundColor: '#FFF',
    },
    fieldset: {
      border: '1px solid #C7D0DE',
    },
  };

  const selectTextStyling = {
    fontWeight: '500',
    fontSize: '13px',
    color: '#646D7A',
    marginTop: '10px',
  };

  return (
    <PublicRegistration hideImage={isXs}>
      <ACSnackbar
        open={showSnackbar}
        onClose={() => setShowSnackbar(false)}
        text="Something went wrong"
        severity="error"
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          textAlign: 'center',
          padding: {
            xxs: '0px 16px',
            sm: '0px',
          },
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontWeight: '700',
            fontSize: '28px',
            color: '#000',
            marginBottom: '16px',
          }}
        >
          Tell us about yourself
        </Typography>
        <Typography
          sx={{
            fontWeight: '400',
            fontSize: '14px',
            color: '#646D7A',
            marginBottom: '24px',
          }}
        >
          {accountDetails.userSegment === 'managed_advisory_board'
            ? "We'll customize your experience based on your selections"
            : "We'll customize your AdvisoryCloud experience based on your selections"}
        </Typography>
        <Box display="flex" justifyContent="center" id="contactFileUploader">
          <FileUploader
            resultFiles={setImgFile}
            status={() => {}}
            customText={(ref) => (
              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: '14px',
                  color: '#646D7A',
                  marginBottom: '18px',
                  cursor: 'pointer',
                  marginTop: '-10px',
                }}
                onClick={() => ref.current.browse()}
              >
                Add your photo
              </Typography>
            )}
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: isXs ? '100%' : '374px',
          padding: {
            xxs: '0px 16px',
            sm: '0px 0px 24px',
          },
        }}
      >
        <Typography sx={selectTextStyling}>
          What kind of work do you do?
        </Typography>
        <FormControl
          variant="outlined"
          sx={selectStyling}
          id="kindOfWorkSelect"
        >
          {!kindOfWork && (
            <InputLabel
              id="simple-select-kind-of-work"
              sx={{ color: '#97A3B7' }}
              shrink={false}
            >
              Select response
            </InputLabel>
          )}
          <Select
            labelId="simple-select-kind-of-work"
            id="demo-simple-select-kind-of-work"
            value={kindOfWork}
            onChange={(event) => {
              setKindOfWork(event.target.value);
            }}
          >
            {kindOfWorkOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Typography sx={selectTextStyling}>What is your role?</Typography>
        <FormControl variant="outlined" sx={selectStyling} id="roleSelect">
          {!role && (
            <InputLabel
              id="simple-select-role"
              sx={{ color: '#97A3B7' }}
              shrink={false}
            >
              Select response
            </InputLabel>
          )}
          <Select
            labelId="simple-select-role"
            id="demo-simple-select-role"
            value={role}
            onChange={(event) => {
              setRole(event.target.value);
            }}
          >
            {roleOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {accountDetails.userSegment === 'managed_advisory_board' ? null : (
          <>
            <Typography sx={selectTextStyling}>
              What type of expertise are you seeking?
            </Typography>
            <FormControl
              variant="outlined"
              sx={selectStyling}
              id="preferedAdviceSelect"
            >
              {selectedSkills.length == 0 && (
                <InputLabel
                  id="multi-select-skills"
                  sx={{ color: '#97A3B7' }}
                  shrink={false}
                >
                  Choose all that apply
                </InputLabel>
              )}
              <Select
                labelId="multi-select-skills"
                id="demo-multi-select-skills"
                value={selectedSkills}
                multiple
                multiline={false}
                onChange={(event) => {
                  setSelectedSkills(event.target.value);
                }}
                renderValue={(selected) => displayChips(selected, true)}
              >
                {tags.skills.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}

        {accountDetails.userSegment === 'managed_advisory_board' ? null : (
          <>
            <Typography sx={selectTextStyling}>
              How would you like to receive advice?
            </Typography>
            <FormControl
              variant="outlined"
              sx={selectStyling}
              id="preferedAdviceSelect"
            >
              {preferedAdvice.length == 0 && (
                <InputLabel
                  id="multi-select-prefered"
                  sx={{ color: '#97A3B7' }}
                  shrink={false}
                >
                  Choose all that apply
                </InputLabel>
              )}
              <Select
                labelId="multi-select-prefered"
                id="demo-multi-select-prefered"
                value={preferedAdvice}
                multiple
                multiline={false}
                onChange={(event) => {
                  setPreferedAdvice(event.target.value);
                }}
                renderValue={(selected) => displayChips(selected, false)}
              >
                {preferedAdviceOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}

        {accountDetails.userSegment === 'managed_advisory_board' ? (
          <>
            <Typography sx={selectTextStyling}>
              What expertise would you like on your advisory board?
            </Typography>
            <FormControl
              variant="outlined"
              sx={selectStyling}
              id="preferedAdviceSelect"
            >
              {selectedSkills.length == 0 && (
                <InputLabel
                  id="multi-select-skills"
                  sx={{ color: '#97A3B7' }}
                  shrink={false}
                >
                  Choose all that apply
                </InputLabel>
              )}
              <Select
                labelId="multi-select-skills"
                id="demo-multi-select-skills"
                value={selectedSkills}
                multiple
                multiline={false}
                onChange={(event) => {
                  setSelectedSkills(event.target.value);
                }}
                renderValue={(selected) => displayChips(selected, true)}
              >
                {tags.skills.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        ) : null}

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            zIndex: 1000,
            position: isXs ? 'sticky' : 'relative',
            bottom: 0,
            padding: '10px 0px 20px 0px',
            background: 'white',
          }}
        >
          <Button
            type="button"
            variant="contained"
            color="primary"
            disableRipple
            onClick={updateProfile}
            disabled={loading}
            id="step2ContinueButton"
            sx={{
              width: isXs ? '100%' : '374px',
              height: '44px',
              borderRadius: '50px',
              marginTop: '24px',
              border: 'none',
              outline: 'none',
              boxShadow: 'none',
              textTransform: 'none',
              color: '#FFFFFF',
              fontFamily: 'Poppins',
              fontSize: '16px',
              fontWeight: 700,
              lineHeight: '24px',
              letterSpacing: '-0.4300000071525574px',
              textAlign: 'center',
              backgroundColor: '#3171F6',
              '&:hover': {
                boxShadow: 'none',
              },
              '&:disabled': {
                backgroundColor: '#E0E0E0',
                opacity: '1',
              },
            }}
          >
            Continue
          </Button>
        </div>
        <div style={{ paddingBottom: '35px' }}>
          <Button
            type="button"
            variant="outlined"
            color="primary"
            disableRipple
            id="step2BackButton"
            onClick={() => {
              loading ? null : history.push('/company/new/step1');
            }}
            sx={{
              borderRadius: '50px',
              width: isXs ? '98%' : '374px',
              marginTop: '-12px',
              border: '0px solid',
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: 'transparent',
                border: '0px solid',
              },
              fontWeight: '500',
              fontSize: '13px',
              textTransform: 'none',
              color: '#646D7A',
            }}
          >
            Back
          </Button>
        </div>
      </Box>
    </PublicRegistration>
  );
}
