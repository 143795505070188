import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import ACSnackbar from '../../components/ACSnackbar';
import { useLocation } from 'react-router-dom';
import { useAccount } from '../../contexts/Account';
import { companyFetchPostsCall } from '../../services/company_services';
import {
  AccountType,
  EndpointParameterFilters,
  EndpointResponseObjectName,
} from '../../services/utils/types';
import DashboardCompanyContent from '../../components/Company/DashboardContent/index';

import EndpointCaller from '../../components/EndpointCaller';
import {
  CallTypes,
  useInfiniteScroll,
} from '../../components/Post/ResponseReply/useInfiniteScroll';
import {
  getIndexArrayValues,
  getValuesFromKeyInDataResponse,
  isNil,
} from '../../services/utils';
import { useCompanyExtraFeed } from '../../contexts/CompanyFeed';
import SearchBox from './searchBox';
import CompanyTiles from '../../components/TopTiles/company/CompanyTiles';
import CompanyTopTilesContainer from '../../components/Company/TopTiles';
import Treatment from '../../components/Treatment';
import { featureFlags } from '../../splitSetup';
import DashboardSkeleton from '../../components/SkeletonLoading/dashboardSkeleton';

function Dashboard(props) {
  const location = useLocation();
  const { showPostPopUp, showPostWrapperContent } = props;
  const { t } = useTranslation();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const {
    asLoggedUserDetails,
    accountDetails,
    authenticate,
    isAuthenticated,
    isImpersonated,
    currentUserInfo,
  } = useAccount();
  const [loading, setLoading] = useState(false);
  const [insightCount, setInsightCount] = useState(0);
  const [legacyIdErrorSnackbarVisible, setLegacyIdErrorSnackbarVisible] =
    useState(false);
  const { state, removeAllPosts, addPost } = useCompanyExtraFeed();

  useEffect(() => {
    return () => {
      removeAllPosts();
    };
  }, []);
  const [filterBy, setFilterBy] = useState([]);
  const [sortBy, setSortBy] = useState('');
  const [companyEndpointCalls, setCompanyEndpointCalls] = useState([
    {
      endPointCall: companyFetchPostsCall,
      type: CallTypes.MAIN_CALL,
      endPointCallParameters: {
        ACCOUNT_TYPE: AccountType.CONTACT,
        ADVISOR_ID: '',
        COMPANY_ID: accountDetails.id,
        LIMIT: EndpointParameterFilters.FETCH_LIMIT,
        OFFSET: 0,
        ORDER_BY: getSort(),
        REVERSE: sortBy == 'oldest',
        TYPE: [
          'question',
          'update',
          'boardMeetings',
          'meeting',
          'insight',
        ].join(','),
        COMPANY_CONTACT_ID: asLoggedUserDetails.userId,
      },
      endPointAppSyncName: EndpointResponseObjectName.GET_POSTS,
    },
  ]);

  const {
    posts,
    isMainCallFetching,
    setInitialPosts,
    incrementOffset,
    addInitialPosts,
    addPostLike,
  } = useInfiniteScroll({ endpointCalls: companyEndpointCalls });

  useEffect(() => {
    if (
      state.temporaryNewPost &&
      !posts.find((post) => post.id == state.temporaryNewPost.id)
    ) {
      addInitialPosts(state.temporaryNewPost);
    }
  }, [state.temporaryNewPost]);

  function GetEndpointResponseArray(array) {
    const post = getValuesFromKeyInDataResponse(
      getIndexArrayValues(array, 0),
      'getPosts'
    );
    const insightCounts = getValuesFromKeyInDataResponse(
      getIndexArrayValues(array, 1),
      'getInsights'
    );

    setInsightCount(isNil(insightCounts) ? 0 : insightCounts.length);
    setInitialPosts(isNil(post) ? [] : post);

    setLoading(false);

    if (location.state && location.state.newPost) {
      addPost(location.state.newPost);
    }
  }

  function getSort() {
    if (!sortBy || sortBy == 'newest') {
      return 'sortAt';
    }
    if (sortBy == 'oldest') {
      return 'createdAt';
    }
    if (sortBy == 'recent-activity') {
      return 'updatedAt';
    }
    if (sortBy == 'mostPopular') {
      return 'mostPopular';
    }
  }

  useEffect(() => {
    setLoading(true);
    setCompanyEndpointCalls([
      {
        endPointCall: companyFetchPostsCall,
        type: CallTypes.MAIN_CALL,
        endPointCallParameters: {
          ACCOUNT_TYPE: AccountType.CONTACT,
          ADVISOR_ID: '',
          COMPANY_ID: accountDetails.id,
          LIMIT: EndpointParameterFilters.FETCH_LIMIT,
          OFFSET: 0,
          ORDER_BY: getSort(),
          REVERSE: sortBy == 'oldest',
          TYPE:
            filterBy.length == 0
              ? [
                  'question',
                  'update',
                  'boardMeetings',
                  'meeting',
                  'insight',
                ].join(',')
              : filterBy.join(','),
          COMPANY_CONTACT_ID: asLoggedUserDetails.userId,
        },
        endPointAppSyncName: EndpointResponseObjectName.GET_POSTS,
      },
    ]);
  }, [filterBy, sortBy]);

  useEffect(() => {
    async function fetchUserData() {
      await authenticate();
    }

    if (
      !isAuthenticated &&
      !isImpersonated &&
      props.location.state &&
      props.location.state.onboarded
    ) {
      fetchUserData();
    }

    if (location.state && location.state.missingLegacyId) {
      setLegacyIdErrorSnackbarVisible(true);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('DASHBOARD-HELMET-TITLE')}</title>
      </Helmet>
      <div
        style={{
          paddingLeft: isSM ? '10px' : '0px',
          paddingTop: '30px',
          paddingRight: isSM ? '10px' : '30px',
          display: 'flex',
          flexDirection: 'column',
          flex: 'auto',
          // maxWidth: '1260px',
        }}
      >
        <div
          style={{
            paddingLeft: isSM ? '10px' : '30px',
            paddingRight: isSM ? '10px' : '0px',
            maxWidth: '1260px',
          }}
        >
          <CompanyTopTilesContainer
            circleFirstColor={'rgba(215, 241, 253, 0.5)'}
            circleSecondColor={'rgba(219, 247, 235, 0.5)'}
            title={`Your space to collaborate with your ${
              accountDetails.userSegment === 'managed_advisory_board'
                ? 'advisory board'
                : 'AdvisoryCloud'
            }`}
            subtitle={`Post questions, insights and anything you’d like your ${
              accountDetails.userSegment === 'managed_advisory_board'
                ? 'advisory board'
                : 'AdvisoryCloud'
            } to weigh-in on`}
            basicButtons
          />
        </div>

        <SearchBox
          filterBy={filterBy}
          setFilterBy={setFilterBy}
          sortBy={sortBy}
          setSortBy={setSortBy}
        />

        {!loading ? (
          <div
            style={{
              marginLeft: isSM ? '-6px' : '0px',
              marginRight: isSM ? '-6px' : '-30px',
              backgroundColor: '#FBFCFD',
              // borderTop: '1px solid #E6EDFF',
              borderRight: '1px solid #E6EDFF',
              borderBottom: '1px solid #E6EDFF',
              borderLeft: '0px solid #E6EDFF',
            }}
          >
            <div
              style={{
                maxWidth: '1290px',
                paddingRight: isSM ? '10px' : '30px',
                paddingLeft: isSM ? '10px' : '30px',
              }}
            >
              <DashboardCompanyContent
                showPostPopUp={showPostPopUp}
                showPostWrapperContent={showPostWrapperContent}
                postsList={posts}
                insightCount={insightCount}
                isMainCallFetching={isMainCallFetching}
                incrementOffset={incrementOffset}
                addPostLike={addPostLike}
                filterBy={filterBy}
              />
            </div>
          </div>
        ) : (
          <div
            style={{
              maxWidth: '1290px',
              paddingTop: isSM ? '10px' : '30px',
              paddingRight: isSM ? '10px' : '30px',
              paddingLeft: isSM ? '10px' : '30px',
            }}
          >
            <DashboardSkeleton />
          </div>
        )}
        <EndpointCaller
          endPointCallObjectArray={companyEndpointCalls}
          onEndpointCallsFinished={GetEndpointResponseArray}
          executeCalls={loading}
          isDashboardSkeleton
        />
      </div>

      <ACSnackbar
        open={legacyIdErrorSnackbarVisible}
        text={t('DASHBOARD-IWM-ID-NOT-PRESENT-ERROR')}
        severity="error"
        onClose={() => setLegacyIdErrorSnackbarVisible(false)}
        autoHideDuration={5000}
      />
    </>
  );
}

export default Dashboard;
