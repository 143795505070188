import React, { useState, useEffect } from 'react';
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Button,
  Grid,
  useMediaQuery,
  Dialog,
  DialogContent,
  CircularProgress,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { AddCircleOutlineOutlined } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import Input from './Input';
import UpgradePlanModal from './../../UpgradePlanModal';
import eventBus, { eventBusValues } from './../../../eventBus';
import { useAccount } from './../../../contexts/Account';
import { deleteCompanyContact } from './../../../services/company_services';

const useStyles = () => ({
  fieldContainer: {
    // marginBottom: '15px',
  },
  subtitle: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '400',
    color: '#232B35',
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: -0.43,
  },
  subtitleDescription: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: '500',
    color: '#646D7A',
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: -0.43,
  },
  name: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '600',
    color: '#232B35',
    fontStyle: 'normal',
    fontWeight: 600,
    letterSpacing: -0.43,
  },
  membertag: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: '600',
    color: '#3171F6',
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: -0.43,
  },
  listItem: {
    paddingLeft: '0px',
    paddingTop: '0px',
  },
  plusIcon: {
    fontSize: '17px',
    marginRight: '6px',
    marginLeft: '3px',
    marginBottom: '-3px',
  },
  addNew: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: '500',
    color: '#3171F6',
    fontStyle: 'normal',
    letterSpacing: -0.43,
  },
  addNewSecondary: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: '300',
    color: '#97A3B7',
    fontStyle: 'normal',
    letterSpacing: -0.43,
  },
  inviteButton: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: '600',
    color: '#FFF',
    backgroundColor: '#3171F6',
    fontStyle: 'normal',
    letterSpacing: -0.43,
    width: '90px',
    height: '40px',
    borderRadius: '15px',
    marginRight: '20px',
    textTransform: 'none',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#3171F6',
      boxShadow: 'none',
    },
  },
  cancelButton: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: '600',
    color: '#646D7A',
    fontStyle: 'normal',
    letterSpacing: -0.43,
    width: '90px',
    height: '40px',
    marginRight: '20px',
    textTransform: 'none',
  },
  disabledSpan: {
    color: '#97A3B7',
  },
  dialogContainer: {
    borderRadius: '10px',
    boxShadow: '0 5px 20px rgba(0, 0, 0, 0.07)',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  selectedMemberInfo: {
    textAlign: 'center',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: '400',
    color: '#232B35',
    fontStyle: 'normal',
    letterSpacing: -0.43,
  },
});

function OnboardingAddTeamMembers({ members, addMember, boardSeatsAllowed }) {
  const { accountDetails, asLoggedUserDetails, currentUserInfo } = useAccount();
  const isBoardOwner =
    accountDetails &&
    accountDetails.userData &&
    accountDetails.userData.isBoardOwner;

  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const classes = useStyles(isXS);
  const [newMember, setNewMember] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });
  const [membersList, setMembersList] = useState(members);
  const [showForm, setShowForm] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [disabledForm, setDisabledForm] = useState(true);
  const [loading, setLoading] = useState(false);
  const [upgradePlanModal, setUpgradePlanModal] = useState(false);
  const [openConfrimDialog, setOpenConfrimDialog] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);

  useEffect(() => {
    const { firstName, lastName, email } = newMember;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (firstName && lastName && email && emailRegex.test(email)) {
      setDisabled(false);
    }
    setLoading(false);
    setDisabledForm(false);
  }, [newMember]);

  function toggleForm() {
    eventBus.dispatch(eventBusValues.triggerInviteTeamModal);
  }

  function handleInputChange(e) {
    const { name, value } = e.target;
    setNewMember({
      ...newMember,
      [name]: value,
    });
  }

  function closeConfirmationDialog() {
    setOpenConfrimDialog(false);
    setSelectedMember(null);
  }

  async function deleteContact() {
    setLoading(true);
    await deleteCompanyContact({
      companyId: accountDetails.id,
      userId: asLoggedUserDetails.userId,
      contactToDeleteId: selectedMember.id,
    });
    setOpenConfrimDialog(false);
    setSelectedMember(null);
    setLoading(false);
  }

  function countAvailableSeats() {
    const seats = boardSeatsAllowed - (members.length + 1);
    return seats > 0 ? seats : 0;
  }

  async function handleAddMember() {
    const { firstName, lastName, email } = newMember;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (firstName && lastName && email && emailRegex.test(email)) {
      setDisabledForm(true);
      setLoading(true);
      await addMember(
        {
          firstName,
          lastName,
          email,
        },
        setShowForm
      );
      setNewMember({
        firstName: '',
        lastName: '',
        email: '',
      });
    }
  }

  useEffect(() => {
    setMembersList(members);
  }, [members]);

  return (
    <div>
      <UpgradePlanModal
        upgradePlanModal={upgradePlanModal}
        setUpgradePlanModal={setUpgradePlanModal}
      />
      <Typography sx={{ ...classes.subtitle, marginTop: '20px' }}>
        Invite your team members
      </Typography>
      <Typography sx={classes.subtitleDescription}>
        Add the team members you'd like to invite
      </Typography>
      <List>
        {membersList.map((member, index) => (
          <ListItem key={index} sx={classes.listItem}>
            <ListItemText
              primary={
                <Typography sx={classes.name}>
                  {member.displayName
                    ? member.displayName + ' '
                    : member.firstName + ' ' + member.lastName + ' '}
                  - <span style={classes.membertag}>Team Member</span>{' '}
                  {isBoardOwner ? (
                    <CancelIcon
                      onClick={() => {
                        setOpenConfrimDialog(true);
                        setSelectedMember(member);
                      }}
                      sx={{
                        cursor: 'pointer',
                        position: 'relative',
                        top: '5px',
                        fontSize: '20px',
                        color: '#FEABAC',
                      }}
                    />
                  ) : null}
                </Typography>
              }
            />
          </ListItem>
        ))}
      </List>
      <div
        onClick={toggleForm}
        style={{
          cursor: 'pointer',
          marginBottom: '22px',
        }}
      >
        <Typography sx={classes.addNew}>
          <span
            id="inviteTeamMemberCta"
            style={countAvailableSeats() === 0 ? classes.disabledSpan : null}
          >
            <AddCircleOutlineOutlined sx={classes.plusIcon} />
            Add another team member
          </span>
          {boardSeatsAllowed === -1 ? (
            <span style={classes.addNewSecondary}>
              {'  '} Unlimited user seats
            </span>
          ) : (
            <span style={classes.addNewSecondary}>
              &nbsp;&nbsp;&nbsp;&nbsp;{countAvailableSeats()} user seats
              remaining
            </span>
          )}
        </Typography>
      </div>
      {showForm && (
        <div>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Input
                  parentStyle={classes.fieldContainer}
                  size="small"
                  name="firstName"
                  label="First Name"
                  placeholder="First Name"
                  hideLabel
                  variant="outlined"
                  fullWidth
                  value={newMember.firstName}
                  onChange={handleInputChange}
                  disabled={disabledForm}
                  InputProps={{
                    'data-cy': 'txtYourBiography',
                    style: {
                      height: '44px',
                      borderRadius: '10px',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  parentStyle={classes.fieldContainer}
                  size="small"
                  name="lastName"
                  label="Last Name"
                  placeholder="Last Name"
                  hideLabel
                  variant="outlined"
                  fullWidth
                  value={newMember.lastName}
                  onChange={handleInputChange}
                  disabled={disabledForm}
                  InputProps={{
                    'data-cy': 'txtYourBiography',
                    style: {
                      height: '44px',
                      borderRadius: '10px',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={8}>
                <Input
                  parentStyle={classes.fieldContainer}
                  size="small"
                  name="email"
                  label="Email"
                  placeholder="Enter the email to invite"
                  hideLabel
                  variant="outlined"
                  fullWidth
                  value={newMember.email}
                  onChange={handleInputChange}
                  disabled={disabledForm}
                  InputProps={{
                    'data-cy': 'txtYourBiography',
                    style: {
                      height: '44px',
                      borderRadius: '10px',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddMember}
                  sx={classes.inviteButton}
                  disabled={disabled}
                  id="inviteTeamMemberCtaSend"
                >
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    'Invite'
                  )}
                </Button>
                <Button
                  sx={classes.cancelButton}
                  variant="text"
                  onClick={toggleForm}
                  id="inviteTeamMemberCtaCancel"
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      )}
      <Dialog
        maxWidth="xs"
        open={openConfrimDialog}
        PaperProps={{
          sx: classes.dialogContainer,
        }}
        onClose={closeConfirmationDialog}
      >
        <div>
          <DialogContent>
            <DialogContentText sx={classes.dialogText}>
              Are you sure you want to remove this team member?
              {selectedMember && (
                <div style={{ marginTop: '10px' }}>
                  <Typography sx={classes.selectedMemberInfo}>
                    {selectedMember.displayName
                      ? selectedMember.displayName + ' '
                      : selectedMember.firstName +
                        ' ' +
                        selectedMember.lastName +
                        ' '}
                  </Typography>
                  <Typography sx={classes.selectedMemberInfo}>
                    {selectedMember.email}
                  </Typography>
                </div>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Button
              onClick={closeConfirmationDialog}
              color="primary"
              variant="outlined"
              disabled={loading}
              style={{
                paddingTop: 15,
                paddingBottom: 15,
                fontFamily: 'Poppins',
                height: 37,
                borderRadius: 20,
                textTransform: 'none',
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={deleteContact}
              color="primary"
              variant="contained"
              style={{
                paddingTop: 15,
                paddingBottom: 15,
                fontFamily: 'Poppins',
                height: 37,
                borderRadius: 20,
                textTransform: 'none',
              }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Remove'
              )}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}

export default OnboardingAddTeamMembers;
