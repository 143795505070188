export default {
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT,
  aws_appsync_region: process.env.REACT_APP_APPSYNC_REGION,
  aws_appsync_authenticationType:
    process.env.REACT_APP_APPSYNC_AUTHENTICATION_TYPE,
  userPoolId: process.env.REACT_APP_APPSYNC_USERPOOL_ID,
  userPoolWebClientId: process.env.REACT_APP_APPSYNC_USERPOOL_WEBCLIENT_ID,
  mandatorySignIn: process.env.REACT_APP_APPSYNC_MANDATORY_SIGN_IN === 'true',
  aws_cognito_identity_pool_id: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_APPSYNC_REGION,
  aws_user_files_s3_bucket_region: process.env.REACT_APP_APPSYNC_REGION,
};
