import React from 'react';
import { Paper, useMediaQuery } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Step2Form from './CompanyFreemiumForm';
import { useAccount } from '../../contexts/Account';
import CompanyPreferences from '../AccountSettings/CompanyPreferences';

const useStyles = ({ isXS }) => ({
  formContainer: {
    paddingTop: isXS ? '0px' : '20px',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingBottom: '20px',
    border: 'solid 1px #E5E5E5',
    borderRadius: '10px',
    marginBottom: '20px',
  },
  stepSubtitle: {
    marginBottom: '5px',
    marginTop: '15px',
    color: '#646D7A',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
  },
  stepTitle: {
    // fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    color: '#232B35',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '-20px',
  },
});

function CompanyInformationEditFreemiumCard(props) {
  const { onCancelClick } = props;
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const classes = useStyles({ isXS });
  const { accountDetails, asLoggedUserDetails } = useAccount();
  const contact =
    accountDetails.contacts && accountDetails.contacts.length
      ? accountDetails.contacts.find(
          (companyContact) => companyContact.id === asLoggedUserDetails.userId
        )
      : {};
  const members =
    accountDetails.contacts?.filter(
      (contact) =>
        contact.id !== asLoggedUserDetails.userId &&
        (contact.accountStatus === 'active' || contact.accountStatus === 'new')
    ) || [];
  let formInitialValues = {
    yourFirstName: contact.givenName,
    yourLastName: contact.surName,
    yourTitle: contact.title,
    yourBiography: contact.biography,
    yourPhoneCountryCode: contact.phoneNumber.countryCode,
    yourPhoneNumber:
      contact.phoneNumber.phoneNumber === '5555555555'
        ? ''
        : contact.phoneNumber.phoneNumber,

    members: members || [],
  };

  let schemaShape = {
    yourBiography: Yup.string().required(),
    yourFirstName: Yup.string().required(),
    yourLastName: Yup.string().required(),
    yourTitle: Yup.string().required(),
    yourPhoneNumber: Yup.string().required(),
  };

  const Step1Schema = Yup.object().shape(schemaShape);

  function renderForm(data) {
    const isValidIndex = Object.keys(data).indexOf('isValid');
    const IS_DISABLED = Object.values(data)[`${isValidIndex}`];

    return (
      <Step2Form
        isUpdate
        {...data}
        {...props}
        isDisabled={!IS_DISABLED}
        onCancelClick={onCancelClick}
      />
    );
  }

  return (
    <Paper sx={{ marginBottom: 10, boxShadow: 'none', maxWidth: '1200px' }}>
      <div style={classes.formContainer}>
        <Formik
          initialValues={formInitialValues}
          validationSchema={Step1Schema}
          validateOnMount
        >
          {renderForm}
        </Formik>
      </div>
      <div style={classes.formContainer}>
        <CompanyPreferences freemiumView hideBillingInformation />
      </div>
      <div style={classes.formContainer}>
        <CompanyPreferences freemiumView hidePlanDetails />
      </div>
    </Paper>
  );
}

export default CompanyInformationEditFreemiumCard;
