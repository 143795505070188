import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAccount } from '../contexts/Account';
import {
  queryCheckAdvisorIsFavorite,
  queryWhoSavedAdvisor,
  mutationSaveAdvisor,
} from '../services/company_services';
import eventBus, { eventBusValues } from '../eventBus';
import { AccountType } from '../services/utils/types';

function useSavedAdvisor(advisorId) {
  const { asLoggedUserDetails, accountDetails, type } = useAccount();
  const [isSaved, setIsSaved] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [justUnsaved, setJustUnsaved] = useState(false);
  const location = useLocation();

  async function querySavedAdvisor() {
    if (type === AccountType.ADVISOR) {
      return false;
    }
    try {
      const response = await queryCheckAdvisorIsFavorite({
        ADVISOR_ID: advisorId,
        COMPANY_ID: accountDetails.id,
        USER_ID: asLoggedUserDetails.userId,
      });
      return response && response.data && response.data.checkSavedAdvisor;
    } catch (error) {
      return false;
    }
  }

  async function checkWhoSavedAdvisor() {
    if (type === AccountType.ADVISOR) {
      return false;
    }
    try {
      const response = await queryWhoSavedAdvisor({
        ADVISOR_ID: advisorId,
        COMPANY_ID: accountDetails.id,
        USER_ID: asLoggedUserDetails.userId,
      });
      return response && response.data && response.data.checkWhoSavedAdvisor;
    } catch (error) {
      return false;
    }
  }

  function reloadSaveAdvisor() {
    setIsSaved(null);
    querySavedAdvisor().then((response) => {
      setIsSaved(response);
    });
  }

  function saveAdvisor() {
    if (isLoading || isSaved === null) {
      return;
    }
    if (!isSaved && location.pathname === '/advisors/saved' && !justUnsaved) {
      checkWhoSavedAdvisor()
        .then((response) => {
          if (response && response.contactId) {
            const contactId = response?.contactId;
            const contact = accountDetails.contacts.find(
              (c) => c.id === contactId
            );
            let snackbarMessage =
              'This advisor was saved by other contact only they can unsave the advisor';
            if (contact) {
              snackbarMessage = `This advisor was saved by ${contact.displayName} only they can unsave the advisor`;
            }
            eventBus.dispatch(eventBusValues.advisorUnsaveSB, {
              message: snackbarMessage,
            });
          }
        })
        .catch((error) => {
          console.log({ error });
        });
      return;
    }
    setIsLoading(true);
    setJustUnsaved(isSaved);
    eventBus.dispatch(`${eventBusValues.advisorSaved}${advisorId}`, {
      value: !isSaved,
    });
    mutationSaveAdvisor({
      ADVISOR_ID: advisorId,
      COMPANY_ID: accountDetails.id,
      USER_ID: asLoggedUserDetails.userId,
    })
      .catch(() => {
        setIsSaved(!isSaved);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    let isMounted = true;
    setIsSaved(null);
    querySavedAdvisor().then((res) => {
      if (isMounted) {
        setIsSaved(res);
      }
    });

    eventBus.on(`${eventBusValues.advisorSaved}${advisorId}`, (event) =>
      setIsSaved(event.value)
    );

    return () => {
      eventBus.remove(`${eventBusValues.advisorSaved}${advisorId}`);
      isMounted = false;
    };
  }, [advisorId]);

  return [isSaved, saveAdvisor, reloadSaveAdvisor];
}
export default useSavedAdvisor;
