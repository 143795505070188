import React, { useEffect, useState } from 'react';
import PostResponseReply from './../ResponseReply';
import CompanyEmptyState from './../../EmptyState/CompanyEmptyState';
import { useAccount } from './../../../contexts/Account';
import CompanyEmptyStateFreemium from './../../EmptyState/CompanyEmptyStateFreemium';
import eventBus, { eventBusValues } from './../../../eventBus';

function BoardMeetingsCompanyContent(props) {
  const { postsList, isMainCallFetching, incrementOffset, addPostLike } = props;
  const { isFreemium } = useAccount();

  return (
    <>
      <PostResponseReply
        gotPostList={postsList}
        hideTopCards
        onSinglePostChanged={() => {}}
        multiplePosts
        isMainCallLoading={isMainCallFetching}
        incrementOffset={incrementOffset}
        addPostLike={addPostLike}
        dashboardOrigin
      />
      {postsList.length > 0 ? null : (
        <CompanyEmptyStateFreemium
          content="Schedule your first moderated meeting"
          buttonId="freemiumScheduleMeeting"
          buttonAction={
            isFreemium
              ? () => {
                  eventBus.dispatch(eventBusValues.triggerCompanyUpgradeAction);
                }
              : () => {}
          }
        />
      )}
    </>
  );
}

export default BoardMeetingsCompanyContent;
