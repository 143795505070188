import React, { useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EmptyQuestionSvg from '../../images/empty-question.svg';
import {
  companyFetchPostsCall,
  getSinglePostCall,
} from '../../services/company_services';
import SceneContentStateSelector from '../../components/SceneContentStateSelector';
import FilteredFeed from '../../components/FilteredFeed';
import { AccountType } from '../../services/utils/types';
import { useAccount } from '../../contexts/Account';
import Treatment from '../../components/Treatment';
import { featureFlags } from '../../splitSetup';

function AdvisorBoardroom() {
  const { t } = useTranslation();
  const location = useLocation();
  const { accountDetails, asLoggedUserDetails, currentUserInfo } = useAccount();
  const params = useParams('/:companySlug/boardroom/:postId');
  const { companySlug, postId } = params;

  const imageArray = [
    {
      src: EmptyQuestionSvg,
      alt: 'empty-question.svg',
      width: '200',
    },
  ];

  const selectedCompany =
    asLoggedUserDetails.advisorRelatedCompanies.memberBoards.filter(
      (element) => element.slug === companySlug
    );

  const { companyName } = selectedCompany[0];

  const singlePostRender = postId !== undefined;

  const [postRenderedSelector, setPostRenderedSelector] = useState({
    multiplePost: {
      parameters: {
        ACCOUNT_TYPE: AccountType.ADVISOR,
        ADVISOR_ID: accountDetails.id,
        COMPANY_ID: selectedCompany[0].id,
        LIMIT: 10,
        OFFSET: 0,
        ORDER_BY: 'sortAt',
        ROUTE_FROM: location.pathname,
        REVERSE: false,
        TYPE: 'all',
      },
      asyncName: 'getPosts',
      graphqlEndpoint: companyFetchPostsCall,
      contentTag: FilteredFeed,
    },
    singlePost: {
      parameters: {
        ACCOUNT_TYPE: AccountType.ADVISOR,
        USER_ID: accountDetails.id,
        POST_ID: postId,
      },
      asyncName: 'getPost',
      graphqlEndpoint: getSinglePostCall,
      contentTag: FilteredFeed,
    },
  });

  return (
    <>
      <div
        style={{
          paddingLeft: (isSM) => (isSM ? 10 : 30),
          marginRight: (isSM) => (isSM ? 0 : '20%'),
          paddingRight: (isSM) => (isSM ? 10 : 30),
          display: 'flex',
          flexDirection: 'column',
          flex: 'auto',
          overflowX: 'scroll',
        }}
      >
        <SceneContentStateSelector
          isDashboardSkeleton
          helmetTitle={t('ADVISOR-BOARDROOMS-BOARDROOM-HELMET-TITLE')}
          endPointAppSyncName={
            singlePostRender
              ? postRenderedSelector.singlePost.asyncName
              : postRenderedSelector.multiplePost.asyncName
          }
          endPointCall={
            singlePostRender
              ? postRenderedSelector.singlePost.graphqlEndpoint
              : postRenderedSelector.multiplePost.graphqlEndpoint
          }
          endPointCallParameters={
            singlePostRender
              ? postRenderedSelector.singlePost.parameters
              : postRenderedSelector.multiplePost.parameters
          }
          ContentTag={
            singlePostRender
              ? postRenderedSelector.singlePost.contentTag
              : postRenderedSelector.multiplePost.contentTag
          }
          contentProps={{
            asyncName: singlePostRender
              ? postRenderedSelector.singlePost.asyncName
              : postRenderedSelector.multiplePost.asyncName,
            noUrlChange: true,
          }}
          emptyStateProps={{
            imageArray,
            titleText: t('ADVISOR_BOARDROOMS_QUESTIONS_EMPTY_STATE_TITLE', {
              companyName,
            }),
            contentText: t('ADVISOR_BOARDROOMS_QUESTIONS_EMPTY_STATE_CONTENT', {
              companyName,
            }),
          }}
        />
      </div>
    </>
  );
}

export default AdvisorBoardroom;
