import React from 'react';
import TitleAndContent from './titleAndContent';
import ACButton from '../ACButton';

const useStyles = () => ({
  container: {
    width: '100%',
    textAlign: 'center',
  },
  imageContainer: {
    margin: '0px auto',
    marginBottom: '100px',
    display: 'inline-block',
  },
  image: {
    borderRadius: '50%',
    display: 'inline-block',
    margin: '0px 10px',
    marginBottom: '10px',
    background: 'white',
  },
  button: {
    margin: 'auto',
    display: 'inline-block',
  },
  titleAndContent: {
    marginBottom: '30px',
    whiteSpace: 'break-spaces',
  },
});

export default function EmptyStateContent(props) {
  const {
    imageArray,
    imageContainerBottomMargin,
    titleText,
    contentText,
    buttonText,
    onButtonClick,
  } = props;
  const classes = useStyles();

  let renderImages = true;
  let renderButton = true;

  if (!buttonText || !onButtonClick) {
    renderButton = false;
  }

  if (!imageArray || imageArray.length < 1) {
    renderImages = false;
  }

  return (
    <div style={classes.container}>
      <div
        style={
          renderImages && imageContainerBottomMargin
            ? {
                ...classes.imageContainer,
                marginBottom: imageContainerBottomMargin,
              }
            : {}
        }
      >
        {imageArray &&
          imageArray.length > 0 &&
          imageArray.map((image) => {
            return (
              <img
                key={image.src}
                style={classes.image}
                src={image.src}
                alt={image.alt ? image.alt : 'missing alt'}
                width={image.width && image.width > 0 ? image.width : '200'}
              />
            );
          })}
      </div>
      <div style={classes.titleAndContent}>
        <TitleAndContent titleText={titleText} contentText={contentText} />
      </div>
      <div style={renderButton ? classes.button : {}}>
        {buttonText && onButtonClick ? (
          <ACButton
            color="primary"
            text={buttonText}
            type="action"
            onClick={onButtonClick}
            width="200px"
          />
        ) : null}
      </div>
    </div>
  );
}
