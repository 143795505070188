import React, { useState, useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import { Link, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size';
import ACSnackbar from '../ACSnackbar';
import UserAvatarIcon from '../../icons/user-avatar.svg';

import { useTranslation } from 'react-i18next';
import { DokaImageEditorModal } from 'react-doka';
import {
  locale_en_gb,
  createDefaultImageReader,
  createDefaultImageWriter,
  setPlugins,
  plugin_crop,
  plugin_crop_locale_en_gb,
  plugin_crop_defaults,
  plugin_finetune,
  plugin_finetune_locale_en_gb,
  plugin_finetune_defaults,
  plugin_filter,
  plugin_filter_locale_en_gb,
  plugin_filter_defaults,
  plugin_decorate,
  plugin_decorate_defaults,
  plugin_decorate_locale_en_gb,
  component_shape_editor_locale_en_gb,
} from 'doka/doka';
import { useAccount } from '../../contexts/Account';
import 'doka/doka.css';

import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css';

setPlugins(plugin_crop, plugin_finetune, plugin_filter, plugin_decorate);

registerPlugin(
  FilePondPluginImagePreview,
  FilePondPluginImageExifOrientation,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType,
  FilePondPluginImageResize,
  FilePondPluginImageTransform,
  FilePondPluginImageEdit,
  FilePondPluginImageCrop,
  FilePondPluginFileEncode,
  FilePondPluginImageValidateSize
);

const editorDefaults = {
  imageReader: createDefaultImageReader(),
  imageWriter: createDefaultImageWriter(),
  ...plugin_crop_defaults,
  ...plugin_finetune_defaults,
  ...plugin_filter_defaults,
  ...plugin_decorate_defaults,
  locale: {
    ...locale_en_gb,
    ...plugin_crop_locale_en_gb,
    ...plugin_finetune_locale_en_gb,
    ...plugin_filter_locale_en_gb,
    ...plugin_decorate_locale_en_gb,
    ...component_shape_editor_locale_en_gb,
  },
};

const filePondProfileOptions = {
  dropOnPage: 'true',
  stylePanelLayout: 'compact circle',
  allowImageCrop: 'true',
  allowDrop: 'true',
  allowRemove: 'true',
  allowProcess: 'true',
  allowBrowse: 'true',
  allowFileSizeValidation: 'true',
  allowFileTypeValidation: 'true',
  allowImageExifOrientation: 'true',
  allowImagePreview: 'true',
  allowImageResize: 'true',
  allowImageTransform: 'true',
  allowImageValidateSize: 'true',
  imageTransformVariantsIncludeOriginal: 'true',
  imagePreviewMaxFileSize: '10MB',
  imageResizeMode: 'contain',
  maxFileSize: '9MB',
  imageResizeTargetWidth: '600',
  imageResizeTargetHeight: '600',
  maxFiles: 1,
  credits: 'false',
  acceptedFileTypes: `${['image/jpg', 'image/jpeg', 'image/png', 'image/gif']}`,
  imageCropAspectRatio: '1:1',
  imageTransformOutputMimeType: 'image/png',
  imageEditAllowEdit: 'true',
  styleImageEditButtonEditItemPosition: 'bottom right',
  styleLoadIndicatorPosition: 'right bottom',
  styleProgressIndicatorPosition: 'right bottom',
  styleButtonRemoveItemPosition: 'left bottom',
  styleButtonProcessItemPosition: 'right bottom',
  instantUpload: 'false',
};

const useStyles = makeStyles((theme) => ({
  uploader: {
    width: (prop) => (prop.isXS ? '130px' : '120px'),
    height: (prop) => (prop.isXS ? '130px !important' : '120px'),
    cursor: 'pointer',
    '& .filepond--drop-label': {
      fontSize: '0.8rem',
      color: theme.palette.secondary.dark,
      backgroundColor: '#fff',
      cursor: 'pointer',
    },
    '& .filepond--file': {
      fontSize: '0.8rem',
      cursor: 'pointer',
    },
    '& .filepond--file-status-main': {
      marginRight: '16px',
      cursor: 'pointer',
    },
  },
}));

function ProfileImageUploader(props) {
  const { status, resultFiles, customText } = props;
  const { t } = useTranslation();
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const classes = useStyles({ isXS });
  const { accountDetails, isFreemium } = useAccount();
  const [preloadedImage, setPreloadedImage] = useState();
  const [errorSnackbar, setErrorSnackbar] = useState('');
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [currentImage, setCurrentImage] = useState();
  const [dokaImage, setDokaImage] = useState();
  const [openModal, setOpenModal] = useState(false);
  const ref = React.useRef();
  const location = useLocation();

  const contact = location.pathname.includes('/advisor')
    ? accountDetails
    : accountDetails.userData;

  async function preloadFilepondImage(image) {
    const imageToPreloadURL = await fetch(image, {
      method: 'get',
      headers: {
        'Access-Control-Origin': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
      },
    })
      .then((response) => {
        return response.blob();
      })
      .catch((err) => {
        console.log('preload', err);
        setErrorSnackbar(t('PROFILE-UPLOADER-ERROR'));
        setShowErrorSnackbar(true);
      });

    setPreloadedImage(imageToPreloadURL);
  }

  useEffect(() => {
    if (contact && contact.contactS3Logo) {
      if (
        contact.contactS3Logo.location &&
        !contact.contactS3Logo.location.includes('undefined')
      ) {
        preloadFilepondImage(contact.contactS3Logo.location);
      }
    } else if (contact && contact.image) {
      console.log(
        `${process.env.REACT_APP_IMAGES_URL}${contact.image.imageKey}`
      );
      preloadFilepondImage(
        `${process.env.REACT_APP_IMAGES_URL}${contact.image.imageKey}`
      );
    }
  }, []);

  useEffect(() => {
    if (preloadedImage) {
      const newBlob = preloadedImage.slice(0, preloadedImage.size, 'image/png');
      const newBlobURL = URL.createObjectURL(newBlob);
      setCurrentImage(newBlobURL);
    }
  }, [preloadedImage]);

  function setupFiles(values) {
    if (values.length === 0) {
      setCurrentImage(null);
      setDokaImage(null);
      status(true);
      setOpenModal(false);
      resultFiles({});
    }
    if (values.length === 1) {
      status(false);
      setCurrentImage(values);
    }
    return null;
  }

  function catchUpdate(data) {
    const result = URL.createObjectURL(data);
    setCurrentImage(result);
    setOpenModal(false);
  }

  function catchOutputFiles(data) {
    const outputFiles = ref.current.getFile();

    const base64Images = outputFiles.getFileEncodeBase64String();

    const originalImageBase64String = base64Images[0].data;
    const imageBase64String = base64Images[1].data;

    const originalImageType = data[0].file.type;
    const imageType = data[1].file.type;

    const originalImageBase64URL = `data:${originalImageType};base64,${originalImageBase64String}=`;
    const imageBase64URL = `data:${imageType};base64,${imageBase64String}=`;

    resultFiles({
      originalImage: originalImageBase64URL,
      image: imageBase64URL,
    });
  }

  const cropAspectRatios = [[1, 'Square']];

  return (
    <>
      <div
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <ACSnackbar
          open={showErrorSnackbar}
          text={errorSnackbar}
          severity="error"
          onClose={() => setShowErrorSnackbar(false)}
          style={{ marginTop: isFreemium ? '50px' : '0px' }}
        />
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: isXS ? 'center' : 'flex-start',
            alignItems: 'center',
          }}
        >
          <FilePond
            ref={ref}
            {...filePondProfileOptions}
            labelIdle={`
          <img src="${UserAvatarIcon}" width="140" />
          `}
            files={currentImage}
            className={classes.uploader}
            onerror={(err) => {
              // eslint-disable-next-line no-console
              console.log('filepond', err);
              setErrorSnackbar(`${err.main}: ${err.sub}`);
              setShowErrorSnackbar(true);
              status(true);
            }}
            onupdatefiles={(files) => {
              setupFiles(files);
            }}
            onpreparefile={(files, output) => {
              const originalInput = URL.createObjectURL(output[0].file);
              setDokaImage(originalInput);
              catchOutputFiles(output);
            }}
            imageTransformBeforeCreateBlob={(canvas) =>
              new Promise((resolve) => {
                const ctx = canvas.getContext('2d');
                const cw = canvas.width;
                const ch = canvas.height;
                ctx.globalCompositeOperation = 'destination-in';
                ctx.fillStyle = 'white';
                ctx.beginPath();
                ctx.arc(cw * 0.5, ch * 0.5, cw * 0.5, 0, 2 * Math.PI);
                ctx.fill();
                ctx.fillRect(0, 0, canvas.width, canvas.height);
                resolve(canvas);
              })
            }
            imageEditEditor={{
              open: () => {
                setOpenModal(true);
              },
            }}
          />
        </Box>

        {customText ? (
          customText(ref)
        ) : (
          <div
            style={{
              marginTop: '-15px',
              paddingBottom: '5px',
              paddingTop: '10px',
              width: !isXS ? '135px' : '100%',
              textAlign: 'center',
              color: '#6e7682',
              cursor: 'pointer',
              fontWeight: '500',
              fontSize: '12px',
            }}
          >
            Drag & drop or&nbsp;
            <Link
              style={{
                color: '#3171f6',
                textDecoration: 'none',
              }}
              onClick={(x) => ref.current.browse()}
            >
              upload
            </Link>
          </div>
        )}
        {openModal ? (
          <DokaImageEditorModal
            {...editorDefaults}
            src={dokaImage}
            imageCropAspectRatio="1"
            cropSelectPresetOptions={cropAspectRatios}
            onClose={() => setOpenModal(false)}
            onHide={() => setOpenModal(false)}
            onProcess={({ dest }) => catchUpdate(dest)}
            willRenderCanvas={(shapes, state) => {
              const { utilVisibility, selectionRect } = state;

              if (utilVisibility.crop <= 0) return shapes;
              const { x, y, width, height } = selectionRect;
              return {
                ...shapes,
                interfaceShapes: [
                  {
                    x: x + width * 0.5,
                    y: y + height * 0.5,
                    rx: width * 0.5,
                    ry: height * 0.5,
                    opacity: utilVisibility.crop,
                    inverted: true,
                    backgroundColor: [0, 0, 0, 0.5],
                    strokeWidth: 1,
                    strokeColor: [0, 0, 0],
                  },
                  ...shapes.interfaceShapes,
                ],
              };
            }}
          />
        ) : null}
      </div>
    </>
  );
}

export default ProfileImageUploader;
