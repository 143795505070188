import React from 'react';
import { Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import AccountValidator from './AccountValidator';
import { ACTheme } from '../../themes';

function ProtectedRoute({
  component,
  theme,
  impersonateOverride,
  isPublic,
  isNotFreeTrialScene,
  isOnboarding,
  isTermsOfServiceScene,
  isOnboardingCall,
  location,
  isSetUp,

  wantsToUpgrade,
  ...rest
}) {
  const ImpersonateOverride =
    location && location.state ? location.state.impersonateOverride : false;

  return (
    <Route
      {...rest}
      render={(props) => (
        <ThemeProvider theme={ACTheme}>
          <AccountValidator
            path={rest.path}
            {...props}
            component={component}
            impersonateOverride={ImpersonateOverride}
            isPublic={isPublic}
            isNotFreeTrialScene={isNotFreeTrialScene}
            isOnboarding={isOnboarding}
            isTermsOfServiceScene={isTermsOfServiceScene}
            isOnboardingCall={isOnboardingCall}
            wantsToUpgrade={wantsToUpgrade}
            isSetUp={isSetUp}
          />
        </ThemeProvider>
      )}
    />
  );
}

export default ProtectedRoute;
