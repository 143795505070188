import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  Typography,
  useMediaQuery,
} from '@mui/material';
import AccordionList from './AccordionList';
import CallWindow from '../Company/UpgradeCall';
import CompanyTopTiles from '../Company/TopTiles';
import GeneralOptionsCards from './GeneralOptionsCards';
import LintonJohnson from './LintonJohnson';

const GeneralUpgrade = () => {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);
  const accordionInfo = [
    {
      title: 'Which plan should I choose?',
      id: 'planToChoose',
      body: (
        <>
          <Typography>
            If you have any questions about what plan to choose, you can{' '}
            <span
              style={{
                color: '#0078D4',
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
              onClick={() => {
                setOpen(true);
              }}
            >
              book a consultation with our team here
            </span>
          </Typography>
          <br />
          <Typography>
            If you're a CEO or founder looking to establish an Advisory Board
            for your business and get regular feedback from a dedicated group of
            advisors we recommend our Managed Advisory Board plan. With a
            dedicated Account Manager, quarterly meetings moderated by our team,
            and the unique benefits that come from having an Advisory Board like
            - enhanced credibility, network expansion, strategic guidance,
            access to resources, and more, the Managed Advisory Board is the
            perfect solution to assemble, manage, and reap the benefits of
            having a remote advisory board for your business.
          </Typography>
          <br />
          <Typography>
            If you're looking to use advisors and experts on-demand for
            project-based initiatives, help with specific challenges, or as a
            sounding board for your team we recommend choosing AdvisoryCloud
            Plus. You'll get access to the network, the ability to get feedback
            and collaborate with advisors in your secure workspace, and the
            ability to hire advisors in a number of formats from 1:1 meetings,
            focus groups, custom surveys, and more.
          </Typography>
        </>
      ),
    },
    {
      title: 'What are the benefits of using outside advisors?',
      id: 'benefitsOfAdvisors',
      body: (
        <>
          <Typography>
            Using outside expertise for your business, team, or project offers
            several benefits:
          </Typography>
          <br />
          <Typography component="ol">
            <Typography component="li">
              <strong>Specialized Knowledge:</strong> Outside experts bring a
              depth of specialized knowledge and experience in specific areas
              that may not be available within your organization. This expertise
              can provide valuable insights and perspectives to address complex
              challenges and drive innovation.
            </Typography>
            <Typography component="li">
              <strong>Fresh Perspectives:</strong> External experts offer fresh
              perspectives and alternative approaches to problem-solving,
              helping to break through organizational biases and assumptions.
              Their outsider viewpoint can spark creativity and encourage
              thinking outside the box.
            </Typography>
            <Typography component="li">
              <strong>Efficiency and Focus:</strong> Engaging outside expertise
              allows your internal team to focus on their core responsibilities
              without being distracted by unfamiliar tasks or projects. This can
              lead to increased efficiency and productivity within your
              organization.
            </Typography>
            <Typography component="li">
              <strong>Access to Resources:</strong> External experts often come
              with their own networks, resources, and tools that can be
              leveraged to support your business, team, or project. This may
              include access to specialized technologies, industry connections,
              or funding opportunities.
            </Typography>
            <Typography component="li">
              <strong>Risk Mitigation:</strong> Utilizing outside expertise can
              help mitigate risks associated with unfamiliar or high-stakes
              projects. By tapping into the knowledge and experience of external
              experts, you can reduce the likelihood of costly mistakes and
              improve the likelihood of success.
            </Typography>
            <Typography component="li">
              <strong>Flexibility and Scalability:</strong> External expertise
              offers flexibility and scalability, allowing you to access
              additional resources and skills as needed, without the long-term
              commitment associated with hiring full-time employees. This
              agility enables your organization to adapt quickly to changing
              business needs and market conditions.
            </Typography>
            <Typography component="li">
              <strong>Learning and Development:</strong> Working with outside
              experts provides an opportunity for knowledge transfer and
              professional development for your internal team. They can learn
              new skills, approaches, and best practices from external experts,
              enhancing their capabilities and contributing to long-term
              organizational growth.
            </Typography>
          </Typography>
        </>
      ),
    },
    {
      title: 'Do I have to pay the advisors to collaborate?',
      id: 'payAdvisors',
      body: (
        <>
          <Typography>
            All premium plans come with the ability to invite advisors to your
            secure workspace and collaborate with them through posts and
            messages without additional payment beyond the subscription price.
            Our Managed Advisory Board plan also comes with 4 quarterly meetings
            with your advisory board at no additional cost. In this capacity
            advisors participate for the credential of being an advisor, the
            experience of giving back, the excitement of working on new
            challenges with new people, and the potential for compensated
            engagements as they demonstrate the value they can provide.
          </Typography>
          <Typography>
            For 1:1 meetings, focus groups, project-based work, custom surveys
            and more substantial engagements, advisors can be hired at their
            hourly rate and you can work with our team to structure these.
          </Typography>
        </>
      ),
    },
    {
      title: 'What are the benefits of a AdvisoryCloud Plus?',
      id: 'benefitsOfAdvisoryCloudPlus',
      body: (
        <>
          <Typography>
            Engaging advisors and experts on-demand for project-based
            initiatives, specific challenges, or as a sounding board for your
            team offers a range of valuable benefits:
          </Typography>
          <Typography component="ol">
            <Typography component="li">
              <strong>Flexibility and Agility:</strong> By accessing expertise
              on-demand, you can quickly assemble a team of advisors with the
              specific skills and experience needed for each project or
              challenge. This flexibility allows you to adapt to changing
              priorities and requirements more effectively, ensuring that you
              have the right resources in place when you need them.
            </Typography>
            <Typography component="li">
              <strong>Cost-Effectiveness:</strong> Hiring advisors and experts
              on-demand can be more cost-effective than maintaining a full-time
              in-house team or hiring consultants for long-term engagements. You
              only pay for the expertise and support you need, reducing overhead
              costs and maximizing the return on investment for each project or
              initiative.
            </Typography>
            <Typography component="li">
              <strong>Specialized Knowledge and Experience:</strong> On-demand
              advisors and experts bring specialized knowledge and experience in
              their respective fields, providing insights and guidance that may
              not be available internally. Their expertise can help you address
              complex challenges, overcome obstacles, and capitalize on
              opportunities more effectively, ultimately driving better outcomes
              for your organization.
            </Typography>
            <Typography component="li">
              <strong>Fresh Perspectives and Innovation:</strong> External
              advisors and experts offer fresh perspectives and innovative ideas
              that can inspire creativity and drive innovation within your team.
              By serving as a sounding board, they encourage brainstorming and
              idea generation, leading to novel solutions and approaches that
              can differentiate your organization in the marketplace.
            </Typography>
            <Typography component="li">
              <strong>Reduced Risk and Increased Confidence:</strong> Leveraging
              external expertise can help mitigate risks associated with
              unfamiliar projects or challenges. Advisors and experts provide a
              level of expertise and validation that instills confidence in
              decision-making and reduces the likelihood of costly mistakes or
              missteps.
            </Typography>
            <Typography component="li">
              <strong>Access to Networks and Resources:</strong> On-demand
              advisors and experts often come with their own networks,
              resources, and tools that can be leveraged to support your
              projects and initiatives. Whether it's industry connections,
              research databases, or proprietary methodologies, tapping into
              these resources can provide a competitive advantage and accelerate
              progress towards your goals.
            </Typography>
            <Typography component="li">
              <strong>Learning and Development Opportunities:</strong>{' '}
              Collaborating with external advisors and experts offers valuable
              learning and development opportunities for your internal team.
              They can gain exposure to new ideas, approaches, and best
              practices, enhancing their skills and capabilities for future
              projects and challenges.
            </Typography>
          </Typography>
        </>
      ),
    },
    {
      title: 'What are the benefits of a Managed Advisory Board?',
      id: 'benefitsOfManagedAdvisoryBoard',
      body: (
        <>
          <Typography>
            Building an advisory board from your network of advisors can be a
            strategic move with numerous benefits. By assembling a diverse group
            of knowledgeable individuals, you can gain valuable insights,
            guidance, and support to achieve your goals and drive success for
            your organization. Here are some specific benefits you can expect:
          </Typography>
          <Typography component="ol">
            <Typography component="li">
              <strong>Expertise and Insight:</strong> Your advisory board can
              offer a wealth of expertise across various fields, providing
              valuable insights into industry trends, market dynamics, and
              emerging opportunities. By tapping into their collective wisdom,
              you can make informed decisions that align with your strategic
              objectives.
            </Typography>
            <Typography component="li">
              <strong>Network Expansion:</strong> Each member of your advisory
              board brings with them their own network of contacts and
              connections. Leveraging these networks can open doors to new
              partnerships, collaborations, and business opportunities that can
              propel your organization forward.
            </Typography>
            <Typography component="li">
              <strong>Strategic Guidance:</strong> With diverse perspectives
              represented on your advisory board, you can receive strategic
              guidance on key initiatives, challenges, and long-term planning.
              Their input can help you refine your strategies, identify
              potential risks, and navigate complex issues more effectively.
            </Typography>
            <Typography component="li">
              <strong>Credibility and Reputation:</strong> Associating your
              organization with respected industry experts can enhance your
              credibility and reputation within your industry and beyond. Their
              endorsement can lend legitimacy to your initiatives and attract
              attention from stakeholders, investors, and customers.
            </Typography>
            <Typography component="li">
              <strong>Problem Solving and Innovation:</strong> When faced with
              obstacles or opportunities, your advisory board can serve as a
              think tank, brainstorming solutions and innovative ideas to drive
              growth and overcome challenges. Their collective creativity can
              spark innovation and foster a culture of continuous improvement
              within your organization.
            </Typography>
            <Typography component="li">
              <strong>Access to Resources:</strong> Whether it's access to
              funding, talent, or specialized knowledge, your advisory board
              members can provide valuable resources to support your
              organization's growth and development. Their connections and
              resources can help you overcome resource constraints and
              capitalize on opportunities more effectively.
            </Typography>
            <Typography component="li">
              <strong>Accountability and Governance:</strong>A well-structured
              advisory board can also provide accountability and governance,
              ensuring that your organization operates ethically, transparently,
              and in accordance with best practices. Their oversight can help
              you mitigate risks and maintain compliance with regulatory
              requirements.
            </Typography>
          </Typography>
        </>
      ),
    },
    {
      title: 'What can I do on my free account?',
      id: 'freeAccount',
      body: (
        <>
          <Typography>
            With your free account you can explore the platform, invite your
            team, invite advisors from your network, and collaborate with them
            in your secure workspace through posts, messages, and more. You can
            also search and save advisors from the network.
          </Typography>
          <Typography>
            In order to invite and collaborate with advisors from our network
            you'll need to upgrade to one of our premium plans.
          </Typography>
        </>
      ),
    },
    {
      title: 'Can I invite people from my team and network?',
      id: 'invitePeople',
      body: (
        <>
          <Typography>
            Yes! One of the best parts about our platform is the ability to
            invite your teammates and anyone from our personal network to
            collaborate with you and the advisors in our network.
          </Typography>
        </>
      ),
    },
  ];

  const styles = {
    card: {
      width: '100%',
      borderRadius: '12px',
      padding: '15px',
      border: '1px solid #EFF0F6',
      textAlign: 'center',
      mb: '40px',
    },
  };

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <CallWindow
            meetingId="SOIDIV_AdvisoryCloudPlans"
            meetingData="AdvisoryCloudPlans"
            psz="10"
            style="border: 1px solid #d8d8d8; min-width: 290px; max-width: 900px;"
          />
        </DialogContent>
      </Dialog>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        maxWidth="1260px"
        pr={isSM ? '10px' : '30px'}
        pl={isSM ? '10px' : '30px'}
        pt="30px"
      >
        <CompanyTopTiles
          circleFirstColor="rgba(215, 241, 253, 1)"
          circleSecondColor="rgba(219, 247, 235, 1)"
          title="Get incredible feedback and
                    insight for your business"
          subtitle="Unlock full access to the network with a premium plan "
          circleAlternative
          hideWatchTheVideo
        />
      </Box>
      <Box bgcolor="#FBFCFD" borderTop="1px solid #F5F7FA">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          maxWidth="1260px"
          pr={isSM ? '10px' : '30px'}
          pl={isSM ? '10px' : '30px'}
          py={isSM ? '20px' : '0px'}
        >
          <GeneralOptionsCards customMarginTop={isSM ? '-60px' : '-100px'} />
          <LintonJohnson />
          <Card sx={styles.card} elevation={0}>
            <CardContent>
              <Typography
                variant="h2"
                mb="28px"
                mt="12px"
                sx={{
                  fontWeight: '600',
                  fontSize: '22px',
                  lineHeight: '32px',
                  color: '#232B35',
                }}
              >
                Frequently Asked Questions
              </Typography>
              <AccordionList items={accordionInfo} />
            </CardContent>
          </Card>
        </Box>
      </Box>
    </>
  );
};

export default GeneralUpgrade;
