import React, { useEffect, useState } from 'react';
import { useMediaQuery, TextField, Typography } from '@mui/material';
import ACAvatar from './../../ACAvatar';
import ACButton from './../../ACButton';

function BoardMeetingCommentsBox(props) {
  const { onSubmit, image, loading, value, variant } = props;
  const { ...filteredProps } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [isInputValid, setIsInputValid] = useState(true);

  function handleButtonClick() {
    if (onSubmit) {
      onSubmit(value);
    }
  }

  useEffect(() => {
    if (value.length > 0) {
      if (value.trim().length > 0) {
        setIsInputValid(true);
      } else {
        setIsInputValid(false);
      }
    } else {
      setIsInputValid(false);
    }
  }, [value]);

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            marginRight: '10px',
          }}
        >
          <ACAvatar image={image} size={isSM ? 'sm' : 'md'} />
        </div>
        <TextField
          {...filteredProps}
          fullWidth
          multiline
          sx={{
            paddingRight: '8px',
          }}
          InputProps={{
            style: {
              minHeight: isSM ? '35px' : '43px',
              padding: '7px',
            },
          }}
          variant="outlined"
        />
        <ACButton
          color="primary"
          variant={variant}
          onClick={handleButtonClick}
          disabled={!isInputValid}
          style={{ borderRadius: 5 }}
          loading={loading ? 1 : 0}
          size={isSM ? 'small' : 'medium'}
        >
          <Typography variant="body2">Post</Typography>
        </ACButton>
      </div>
    </div>
  );
}

export default BoardMeetingCommentsBox;
