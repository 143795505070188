import React, { useEffect } from "react";

export default function MinimumDisplayTime({
  children,
  displayTime = 1000,
  visible,
  onHide,
}) {
  function timeOut() {
    setTimeout(() => {
      if (onHide) {
        onHide();
      }
    }, displayTime);
  }

  useEffect(() => {
    if (visible) {
      timeOut();
    }
    return () => {
      clearTimeout(timeOut);
    };
  }, []);

  return (
    <div>
      {visible && (
        <div
          style={{
            bottom: 0,
            display: "flex",
            left: 0,
            position: "absolute",
            right: 0,
            top: 0,
            zIndex: 1,
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
}
