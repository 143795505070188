import React from "react";
import { Paper, Typography, useMediaQuery } from "@mui/material";
import ACButton from './../../ACButton';

const useStyles = ({isSM}) => ({
  mask: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 3,
    border: "none",
  },
  container: {
    "& > *": {
      textAlign: "center",
      paddingTop: "0px",
      marginBottom: 10,
    },
    padding: isSM ? 7 : 10,
    paddingTop: isSM ? 1 : 2,
    display: "flex",
    width: "calc(100% - 10px)",
    maxWidth: "650px",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "50px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  fillWidth: {
    width: "100%",
  },
  title: {
    marginBottom: "20px",
    textAlign: "center",
    fontWeight: "normal",
  },
  content: {
    marginBottom: "25px",
  },
  link: {
    textDecoration: "underline",
    cursor: "pointer",
    color: 'theme.palette.common.black',
    marginLeft: 2,
    marginRight: 2,
  },
  inlineDiv: {
    display: "flex",
    flexDirection: "row",
  },
  alignRight: {
    marginLeft: "auto",
  },
  close: {
    color: 'theme.palette.info.light',
    fontWeight: "normal",
    cursor: "pointer",
  },
});

export default function BasicModal(props) {
  const { isVisible, contentText, buttonText, onButtonClick, onClose } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles({isSM});

  if (!isVisible) {
    return null;
  }

  return (
    <div style={classes.mask}>
      <div style={classes.container}>
        <div style={classes.fillWidth}>
          <Paper sx={classes.container}>
            <div style={classes.alignRight}>
              <Typography
                sx={classes.close}
                variant="h4"
                onClick={onClose}
              >
                x
              </Typography>
            </div>
            <Typography sx={classes.content} variant="h3">
              {contentText}
            </Typography>
            <div sx={classes.alignRight}>
              <ACButton
                color="primary"
                style={{ borderRadius: 3 }}
                onClick={onButtonClick}
              >
                <Typography variant="body2">{buttonText}</Typography>
              </ACButton>
            </div>
          </Paper>
        </div>
      </div>
    </div>
  );
}
