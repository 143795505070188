import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  useMediaQuery,
  Button,
} from '@mui/material';
import AccordionList from '../../components/ManagedAdvisoryBoard/AccordionList';
import CompanyTopTiles from '../../components/Company/TopTiles';
import { ReactComponent as Star } from '../ManagedAdvisoryBoard/Star.svg';
import { ReactComponent as Rating } from '../ManagedAdvisoryBoard/Rating.svg';
import LintonJohnson from './LintonJohnson';
import { CustomCarousel } from '../ManagedAdvisoryBoard/carousel';
import { ReactComponent as Network } from './photos/network.svg';
import { ReactComponent as Collaborate } from './photos/collaborate.svg';
import { ReactComponent as Insight } from './photos/insight.svg';
import GetFeedback from '../ManagedAdvisoryBoard/getFeedback';
import HeroSection from './hero';
import GettingStartedSection from './GettingStarted';
import eventBus, { eventBusValues } from '../../eventBus';

const advisoryNumberWays = [
  {
    id: 'post',
    className: 'formatTile',
    title: 'Post',
    description:
      'Post questions and updates in your AdvisoryCloud to get feedback, ideas, suggestions, and support from your advisors.',
    color: '#DBF7EB',
  },
  {
    id: '1_1_meetings',
    className: 'formatTile',
    title: '1:1 Meetings',
    description:
      'Get guidance, connections, and priceless information through 1:1 meetings with advisors.',
    color: '#FFEED6',
  },
  {
    id: 'focus_groups',
    className: 'formatTile',
    title: 'Focus Groups',
    description:
      'Conduct focus groups with advisors to tackle specific challenges and initiatives you’re working on.',
    color: '#D7F1FD',
  },
  {
    id: 'surveys',
    className: 'formatTile',
    title: 'Surveys',
    description:
      'Commission custom surveys and polls to get opinions and feedback at scale for your company, team, or project.',
    color: '#FEF8D1',
  },
  {
    id: 'custom',
    className: 'formatTile',
    title: 'Custom',
    description:
      'With AdvisoryCloud Plus you can work with our team to structure a custom solution to get the insight you’re looking for',
    color: '#D7E2FD',
  },
];

const accordionInfo = [
  {
    id: 'benefitsQuestion',
    title: 'What are the benefits of AdvisoryCloud Plus?',
    body: (
      <>
        <Typography>
          Engaging advisors and experts on-demand for project-based initiatives,
          specific challenges, or as a sounding board for your team offers a
          range of valuable benefits:
        </Typography>
        <br />
        <Typography component="ol">
          <Typography component="li">
            <strong>Flexibility and Agility:</strong> By accessing expertise
            on-demand, you can quickly assemble a team of advisors with the
            specific skills and experience needed for each project or challenge.
            This flexibility allows you to adapt to changing priorities and
            requirements more effectively, ensuring that you have the right
            resources in place when you need them.
          </Typography>
          <Typography component="li">
            <strong>Cost-Effectiveness:</strong> Hiring advisors and experts
            on-demand can be more cost-effective than maintaining a full-time
            in-house team or hiring consultants for long-term engagements. You
            only pay for the expertise and support you need, reducing overhead
            costs and maximizing the return on investment for each project or
            initiative.
          </Typography>
          <Typography component="li">
            <strong>Specialized Knowledge and Experience:</strong> On-demand
            advisors and experts bring specialized knowledge and experience in
            their respective fields, providing insights and guidance that may
            not be available internally. Their expertise can help you address
            complex challenges, overcome obstacles, and capitalize on
            opportunities more effectively, ultimately driving better outcomes
            for your organization.
          </Typography>
          <Typography component="li">
            <strong>Fresh Perspectives and Innovation:</strong> External
            advisors and experts offer fresh perspectives and innovative ideas
            that can inspire creativity and drive innovation within your team.
            By serving as a sounding board, they encourage brainstorming and
            idea generation, leading to novel solutions and approaches that can
            differentiate your organization in the marketplace.
          </Typography>
          <Typography component="li">
            <strong>Reduced Risk and Increased Confidence:</strong> Leveraging
            external expertise can help mitigate risks associated with
            unfamiliar projects or challenges. Advisors and experts provide a
            level of expertise and validation that instills confidence in
            decision-making and reduces the likelihood of costly mistakes or
            missteps.
          </Typography>
          <Typography component="li">
            <strong>Access to Networks and Resources:</strong> On-demand
            advisors and experts often come with their own networks, resources,
            and tools that can be leveraged to support your projects and
            initiatives. Whether it's industry connections, research databases,
            or proprietary methodologies, tapping into these resources can
            provide a competitive advantage and accelerate progress towards your
            goals.
          </Typography>
          <Typography component="li">
            <strong>Learning and Development Opportunities:</strong>{' '}
            Collaborating with external advisors and experts offers valuable
            learning and development opportunities for your internal team. They
            can gain exposure to new ideas, approaches, and best practices,
            enhancing their skills and capabilities for future projects and
            challenges.
          </Typography>
        </Typography>
      </>
    ),
  },
  {
    id: 'connectQuestion',
    title: 'How do I connect and work with the advisors on your platform?',
    body: (
      <>
        <Typography>
          With AdvisoryCloud plus you’ll get access to the network, the ability
          to collaborate with advisors in your secure workspace through posts
          and messages, and you’ll be able to hire advisors in a number of
          formats from 1:1 meetings, focus groups, custom surveys, and more.
        </Typography>
      </>
    ),
  },
  {
    id: 'payAdvisorQuestion',
    title: 'Do I have to pay the advisors to collaborate?',
    body: (
      <>
        <Typography>
          All premium plans come with the ability to invite advisors to your
          secure workspace and collaborate with them through posts and messages
          without additional payment beyond the subscription price. In this
          capacity advisors participate for the credential of being an advisor,
          the experience of giving back, the excitement of working on new
          challenges with new people, and the potential for compensated
          engagements as they demonstrate the value they can provide.
        </Typography>
        <br />
        <Typography>
          For 1:1 meetings, focus groups, project-based work, custom surveys and
          more substantial engagements, advisors can be hired at their hourly
          rate and you can work with our team to structure these.
        </Typography>
      </>
    ),
  },
  {
    id: 'eligibleQuestion',
    title: 'Who is eligible to join the platform? Are we a good fit?',
    body: (
      <>
        <Typography>
          Anyone who can benefit from outside expertise is eligible to join the
          platform! Team leaders, CXOs, VPs, and any senior professional can use
          the platform to tap into outside expertise and develop an incredible
          sounding board and resource to move your initiatives forward. Whether
          that’s specific expertise to guide a multi-million dollar project or
          general guidance and mentorship for you as a professional.
        </Typography>
      </>
    ),
  },
  {
    id: 'InviteTeamQuestion',
    title: 'Can I invite people from my team and network?',
    body: (
      <>
        <Typography>
          Yes! One of the best parts about our platform is the ability to invite
          your teammates and anyone from our personal network to collaborate
          with you and the advisors in our network.
        </Typography>
      </>
    ),
  },
];

const GeneralUpgrade = () => {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const handleClick = () => {
    eventBus.dispatch(eventBusValues.triggerCompanyUpgradeAction, {
      planCode: 'plus',
    });
  };
  const classes = {
    card: {
      width: '100%',
      borderRadius: '12px',
      padding: '15px',
      border: '1px solid #EFF0F6',
      textAlign: 'center',
      mb: '40px',
    },
    title: {
      fontFamily: 'Poppins',
      fontSize: '36px',
      fontWeight: 700,
      lineHeight: '40px',
      letterSpacing: '-0.43px',
      textAlign: 'center',
      maxWidth: isSM ? '90%' : 430,
    },
    subtitle: {
      marginTop: '15px',
      maxWidth: isSM ? '90%' : 600,
      fontFamily: 'Poppins',
      fontSize: '15px',
      fontWeight: 500,
      lineHeight: '28px',
      letterSpacing: '0.75px',
      textAlign: 'center',
    },
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="start" width="100%">
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="start"
        maxWidth="1260px"
      >
        <Box
          id="acPlusUpgradeHeader"
          display="flex"
          flexDirection="column"
          width="100%"
          alignItems="center"
          pr={isSM ? '10px' : '60px'}
          pl={isSM ? '10px' : '60px'}
          pt={isSM ? '20px' : '30px'}
          pb={isSM ? '20px' : '20px'}
        >
          <CompanyTopTiles
            circleFirstColor="rgba(215, 241, 253, 0.35)"
            circleSecondColor="rgba(219, 247, 235, 0.8)"
            title="AdvisoryCloud Plus"
            subtitle="Get access to the network and collaborate with advisors on-demand"
            circleAlternative
            hideWatchTheVideo
            titleClass={classes.title}
            subtitleClass={classes.subtitle}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '20px',
                marginBottom: '-20px',
              }}
            >
              <Box>
                <Button
                  className="headerCTA"
                  id="getACPlusCTA"
                  onClick={handleClick}
                  disableRipple
                  variant="contained"
                  style={{
                    width: '230px',
                    height: '48px',
                    borderRadius: '40px',
                    padding: '20px',
                    fontFamily: 'Poppins',
                    fontSize: '16px',
                    fontWeight: 700,
                    lineHeight: '24px',
                    letterSpacing: '-0.43px',
                    textAlign: 'left',
                    textTransform: 'none',
                    boxShadow: 'none',
                    border: '1px solid rgba(44, 249, 161, 1)',
                    color: 'rgba(35, 43, 53, 1)',
                    background: 'rgba(219, 247, 235, 1)',
                  }}
                >
                  Get AdvisoryCloud Plus
                </Button>
              </Box>
              <Typography
                sx={{
                  marginTop: '20px',
                  width: '300px',
                  fontFamily: 'Poppins',
                  fontSize: '11px',
                  fontWeight: 300,
                  lineHeight: '15px',
                  letterSpacing: '0.75px',
                  textAlign: 'center',
                }}
              >
                By clicking the button, you’ll be able to see pricing and
                confirm your upgrade
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '5px',
                }}
              >
                <Star
                  style={{
                    height: '14px',
                  }}
                />
                <Typography
                  sx={{
                    fontFamily: 'Poppins',
                    fontSize: '12px',
                    fontWeight: 500,
                    lineHeight: '32px',
                    letterSpacing: '0.5px',
                    textAlign: 'left',
                    marginRight: '10px',
                  }}
                >
                  Trustpilot
                </Typography>
                <Rating
                  style={{
                    marginRight: '5px',
                    height: '14.7px',
                  }}
                />
              </Box>
            </Box>
          </CompanyTopTiles>
        </Box>
      </Box>
      <Box
        bgcolor="rgba(251, 252, 253, 1)"
        display="flex"
        flexDirection="column"
        alignItems="start"
        width="100%"
        borderTop="1px solid rgba(245, 247, 250, 1)"
      >
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="start"
          maxWidth="1260px"
          pr={isSM ? '10px' : '60px'}
          pl={isSM ? '10px' : '60px'}
        >
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-around',
              flexWrap: 'wrap',
              maxWidth: '1160px',
              backgroundColor: '#FFFFFF',
              border: { xxs: 'none', sm: '1px solid #EFF0F6' },
              borderRadius: '10px',

              padding: { xxs: '50px 0px', xs: '50px 0px' },
              marginTop: '30px',
              marginBottom: '15px',
            }}
          >
            <HeroSection
              Photo={Network}
              id="acPlusValue1"
              title="Unlock access to our network of 10,000+ leading professionals"
            />
            <HeroSection
              Photo={Collaborate}
              id="acPlusValue2"
              title="Invite advisors to collaborate in your secure workspace"
            />
            <HeroSection
              Photo={Insight}
              id="acPlusValue3"
              title="Get insight through posts, messages, 1:1 meetings, and more"
            />
          </Box>

          <Box
            sx={{
              width: '100%',
              backgroundColor: '#FFFFFF',
              border: { xxs: 'none', sm: '1px solid #EFF0F6' },
              borderRadius: '10px',
              padding: { xxs: '50px 0px', xs: '50px 40px' },
              marginTop: '15px',
              marginBottom: '30px',
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '22px',
                lineHeight: '32px',
                letterSpacing: '-0.43px',
                textAlign: 'center',
                marginBottom: '30px',
                padding: { xxs: '20px', xs: '0px' },
              }}
            >
              Tap into advisors in a number of ways
            </Typography>

            <CustomCarousel items={advisoryNumberWays} option={2} />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="100%"
          >
            <LintonJohnson />
          </Box>
          <Box
            sx={{
              width: '100%',
              maxWidth: '1160px',
              backgroundColor: '#FFFFFF',
              border: { xxs: 'none', sm: '1px solid #F5F7FA' },
              borderRadius: '10px',
              padding: { xxs: '50px 0px', xs: '50px 40px' },
              marginBottom: '30px',
            }}
          >
            <GetFeedback />
          </Box>
          <Box
            sx={{
              width: '100%',
              maxWidth: '1160px',
              backgroundColor: '#FFFFFF',
              border: { xxs: 'none', sm: '1px solid #F5F7FA' },
              borderRadius: '10px',
              padding: { xxs: '50px 0px', xs: '50px 40px' },
              marginTop: '15px',
              marginBottom: '30px',
            }}
          >
            <GettingStartedSection />
          </Box>
          <Box
            sx={{
              width: '100%',
              maxWidth: '1160px',
              borderRadius: '10px',
              padding: { xxs: '50px 0px', xs: '50px 40px' },
              marginBottom: '30px',
            }}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Poppins',
                  fontSize: { xxs: '28px', xs: '36px' },
                  fontWeight: 700,
                  lineHeight: '40px',
                  letterSpacing: '-0.43px',
                  textAlign: 'center',
                  maxWidth: '90%',
                  marginBottom: '5px',
                }}
              >
                Ready to try it out?
              </Typography>
              <Typography
                sx={{
                  marginTop: '15px',
                  maxWidth: '90%',
                  fontFamily: 'Poppins',
                  fontSize: '15px',
                  fontWeight: 500,
                  lineHeight: '28px',
                  letterSpacing: '0.75px',
                  textAlign: 'center',
                  marginBottom: '20px',
                }}
              >
                Get access to the network and collaborate with advisors
                on-demand
              </Typography>

              <Box>
                <Button
                  id="getACPlusCTAfooter"
                  onClick={handleClick}
                  disableRipple
                  variant="contained"
                  style={{
                    width: '250px',
                    height: '48px',
                    borderRadius: '40px',
                    padding: '20px',
                    fontFamily: 'Poppins',
                    fontSize: '16px',
                    fontWeight: 700,
                    lineHeight: '24px',
                    letterSpacing: '-0.43px',
                    textAlign: 'left',
                    textTransform: 'none',
                    boxShadow: 'none',
                    border: '1px solid rgba(44, 249, 161, 1)',
                    color: 'rgba(35, 43, 53, 1)',
                    background: 'rgba(219, 247, 235, 1)',
                  }}
                >
                  Get AdvisoryCloud Plus
                </Button>
              </Box>
              <Typography
                sx={{
                  marginTop: '15px',
                  maxWidth: { xxs: '80%', xs: '40%', sm: '25%' },
                  fontFamily: 'Poppins',
                  fontSize: '11px',
                  fontWeight: 300,
                  lineHeight: '15px',
                  letterSpacing: '0.75px',
                  textAlign: 'center',
                }}
              >
                By clicking the button, you’ll be able to see pricing and
                confirm your upgrade
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            width="100%"
          >
            <Card sx={classes.card} elevation={0}>
              <CardContent>
                <Typography
                  variant="h2"
                  mb="28px"
                  mt="12px"
                  sx={{
                    fontWeight: '600',
                    fontSize: '22px',
                    lineHeight: '32px',
                    color: '#232B35',
                  }}
                >
                  Frequently Asked Questions
                </Typography>
                <AccordionList items={accordionInfo} />
              </CardContent>
            </Card>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GeneralUpgrade;
