import React, { useState } from 'react';
import { Menu, MenuItem, Fade, Typography, useMediaQuery } from '@mui/material';
import { Edit, MoreVert } from '@mui/icons-material';
import CustomIcon from './../../../../../icons/customIcon';
import { useAccount } from './../../../../../contexts/Account';
import { PostType } from './../../../../../services/utils/types';

const useStyles = (isSM) => ({
  backDrop: {
    zIndex: 999,
  },
  elipsies: {
    color: 'secondary.main',
    position: 'relative',
    fontSize: isSM ? '20px' : '25px',
  },
  button: {
    padding: '0px',
    margin: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '10px',
    outline: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    border: 'none',
    width: '10px',
    height: '22px',
    overflow: 'hidden',
  },
  iconStyle: {
    fontSize: '15px',
    marginBottom: '2px',
    marginRight: '5px',
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  menuItemText: {
    marginBottom: '-3px',
    fontWeight: 600,
  },
});

function VerticalElipsiesMenu(props) {
  const { editClick, pinClick, isPinned, type } = props;
  const { isImpersonated } = useAccount();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const showEdit = () => {
    if (isImpersonated) {
      return true;
    }
    return ![PostType.INSIGHT, PostType.MEETING].includes(type);
  }

  if (!pinClick && !showEdit()) {
    return null;
  }

  return (
    <div>
      <button
        type="button"
        aria-controls="fade-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={classes.button}
      >
        <MoreVert sx={classes.elipsies} />
      </button>
      <Menu
        elevation={0}
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '.MuiMenu-paper': {
            borderRadius: '10px',
            border: '1px solid #E6EDFF',
          },
        }}
      >
        <div>
          <React.Fragment>
            {showEdit() ? (
              <MenuItem onClick={() => editClick()}>
                <Edit sx={classes.iconStyle} />
                <Typography sx={{ fontWeight: 600, marginLeft: '8px' }}>
                  Edit
                </Typography>
              </MenuItem>
            ) : null}
            {pinClick ? (
              <MenuItem onClick={() => pinClick()}>
                <CustomIcon style={classes.iconStyle} iconname="push-pin" />
                <Typography sx={{ fontWeight: 600, marginLeft: '4px' }}>
                  {isPinned ? 'Unpin' : 'Pin'}
                </Typography>
              </MenuItem>
            ) : null}
          </React.Fragment>
        </div>
      </Menu>
    </div>
  );
}

export default VerticalElipsiesMenu;
