import React, { useState } from 'react';
import ACButton from './../../ACButton';
import {
  useMediaQuery,
  Typography,
  TextField,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Button as ButtonMat,
} from '@mui/material';
import { AddCircleOutlineOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useAccount } from './../../../contexts/Account';
import AutocompleteCustome from './Autocomplete';
import BenefitCard from './benefitCard';
import GenericConfirmationModal from './../../GenericConfirmationModal';
import { updateUpgradeFreemiumCompanyAccountData } from './../../../services/company_services';

const useStyles = (isXS) => ({
  companyWebsiteContainer: {
    display: 'flex',
    flex: 'auto',
    flexDirection: 'column',
  },
  continueButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '25px',
  },
  fieldContainer: {
    marginBottom: '25px',
  },
  container: {
    marginTop: '28px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
  },
  thumbnailContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    maxHeight: '250px',
    width: '170px',
  },
  updateCompanyNameContainer: {
    minWidth: isXS ? '94.8vw' : '370px',
    paddingRight: '20px',
  },
  subtitle: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '400',
    color: '#232B35',
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: -0.43,
  },
  subtitleDescription: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: '400',
    color: '#646D7A',
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: -0.43,
    paddingBottom: '20px',
  },
  continueButton: {
    textTransform: 'none',
    fontFamily: 'Poppins',
    fontWeight: '700',
    fontSize: '16px',
    height: '45px',
    width: '225px',
    lineHeight: '24px',
    borderRadius: '40px',
    color: 'white',
    '&:hover': {
      backgroundColor: '#3171F6',
    },
  },
  generalMargin: {
    marginRight: '8px',
    marginBottom: '5px',
    display: 'flex',
    flexDirection: 'row',
    // justifyContent: 'space-between',
  },
  skillsTagsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'colun',
  },
  mainButton: {
    width: '94px',
    height: '44px',
    borderRadius: '10px',
    letterSpacing: -0.43,
    fontStyle: 'normal',
    textTransform: 'none',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '24px',
    backgroundColor: '#3171F6',
    color: 'white',
    marginLeft: '10px',
    marginTop: '10px',
    marginBottom: '10px',
    '&:hover': {
      backgroundColor: '#3171F6',
    },
    '&:disabled': {
      opacity: 0.5,
      backgroundColor: '#3171F6',
      color: 'white',
    },
  },
  secondaryButton: {
    width: '94px',
    height: '44px',
    letterSpacing: -0.43,
    fontStyle: 'normal',
    textTransform: 'none',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#646D7A',
    marginLeft: '10px',
    marginTop: '10px',
    marginBottom: '10px',
    '&:disabled': {
      opacity: 0.5,
      color: '#646D7A',
    },
  },
  addContentContainer: {
    marginRight: '15px',
    paddingBottom: '15px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  plusIcon: {
    fontSize: '20px',
    marginRight: '15px',
    marginLeft: '3px',
  },
  labelText: {
    color: '#232B35',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.43px',
  },
  helperText: {
    color: '#97A3B7',
    textAlign: 'right',
    // fontFamily: 'Poppins',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '-0.43px',
  },
  subtextText: {
    color: '#646D7A',
    // fontFamily: 'Poppins',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.43px',
  },
  tagsContainer: {
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '5px',
    paddingBottom: '5px',
    borderRadius: '25px',
    border: 'solid',
    borderWidth: '2px',
    backgroundColor: '#F5F7FA',
    borderColor: '#F5F7FA',
    color: 'common.black',
  },
  tags: {
    color: '#232B35',
    fontWeight: 500,
    fontSize: '13px',
  },
});

function ProfileUpgradeForm(props) {
  const {
    errors,
    handleBlur,
    handleChange,
    isDisabled,
    setFieldValue,
    touched,
    values,
    isUpdate,
    onValid,
    updateSubmit,
    onInformationUploaded,
  } = props;
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [benefits, setBenefits] = useState(values.benefits ?? []);
  const [benefitToEdit, setBenefitToEdit] = useState();
  const [benefitToDelete, setBenefitToDelete] = useState();
  const [addBenefitModal, setAddBenefitModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const {
    asLoggedUserDetails,
    editAccountInformation,
    accountDetails,
    editCompanyContactInformation,
    tags,
  } = useAccount();
  const categoryOptions = [
    'Compensation Potential',
    'Equity Potential',
    'Formal Roles',
    'Perks',
  ];
  const [category, setCategory] = useState();
  const [details, setDetails] = useState();
  const { t } = useTranslation();
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const classes = useStyles(isXS);

  const [textError, setTextError] = useState('');
  const isContinueButtonDisabled = isDisabled;
  let updatedInfo = {};

  function setAccountInfo(final, value) {
    if (!final) {
      updatedInfo = { ...value };
    } else {
      updatedInfo = { ...updatedInfo, ...value };
      editAccountInformation(updatedInfo);
    }
  }

  async function sendFormData() {
    try {
      let data = {
        CONTACT_ID: asLoggedUserDetails.userId,
        COMPANY_SKILLS: values.companySkills.map((d) => d.id),
        COMPANY_BENEFITS: benefits.map((e) => JSON.stringify(e)),
      };
      await updateUpgradeFreemiumCompanyAccountData(data);

      const accountDetailsModifiedValues = {
        desiredSkillsTagIds: values.companySkills.map((d) => d.id),
        benefits: benefits,
      };
      setAccountInfo(false, accountDetailsModifiedValues);
    } catch (err) {
      const errorMessage = t('STEP1-ERROR-SNACKBAR-TEXT');
      let errorMessageFromAPI = '';
      try {
        errorMessageFromAPI = JSON.parse(err.errors[0].message).error.error;
      } catch (error) {
        console.log(error);
      }
      setTextError(
        errorMessageFromAPI.length === 0 ? errorMessage : errorMessageFromAPI
      );
      throw new Error();
    }
  }

  function submitStep1Form() {
    setIsLoading(true);
    sendFormData()
      .then((x) => {
        // accountDetails.onboardingStatus = 'step3';
        // accountDetails.industryTagIds = values.companyIndustry.map((d) => d.id);
        props.history.push('/setup-step-4');
      })
      .catch(() => {
        setIsSnackbarVisible(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function hasError(id) {
    if (Object.keys(touched).includes(id)) {
      return errors[id];
    }

    return null;
  }

  function handleAdvisorSkillsAutocompleteChange(event, vals) {
    setFieldValue('companySkills', vals);
  }

  function addNewBenefit(benefit) {
    if (benefitToEdit) {
      const updatedBenefits = benefits.map((bnf) => {
        if (
          bnf.category == benefitToEdit.category &&
          bnf.details == benefitToEdit.details
        ) {
          return benefit;
        }
        return bnf;
      });
      setBenefits(updatedBenefits);
      setBenefitToEdit(null);
    } else {
      setBenefits([...benefits, benefit]);
    }
  }

  const removeBenefit = () => {
    const updatedBenefits = benefits.filter(
      (benefit) =>
        benefit.details !== benefitToDelete.details ||
        benefit.category !== benefitToDelete.category
    );
    setBenefits(updatedBenefits);
  };

  const editBenefit = (benefit) => {
    setAddBenefitModal(true);
    setBenefitToEdit(benefit);
    setCategory(benefit.category);
    setDetails(benefit.details);
  };

  const featuredBenefit = (benefitToFeatured) => {
    const featuredCount = benefits
      ? benefits.filter((bnf) => bnf.featured).length
      : 0;
    if (featuredCount == 3 && !benefitToFeatured.featured) {
      setTextError(
        'You have exceeded the limit of 3 board featured per company'
      );
      setIsSnackbarVisible(true);
      return;
    }
    const updatedBenefits = benefits.map((bnf) => {
      if (
        bnf.category == benefitToFeatured.category &&
        bnf.details == benefitToFeatured.details
      ) {
        return {
          ...benefitToFeatured,
          featured: benefitToFeatured.featured ? false : true,
        };
      }
      return bnf;
    });
    setBenefits(updatedBenefits);
  };

  return (
    <>
      <form>
        <ACSnackbar
          id="onboarding-step1-error-snackbar"
          onClose={() => setIsSnackbarVisible(false)}
          open={isSnackbarVisible}
          severity="error"
          text={textError}
        />
        <div>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="old_font" sx={classes.labelText}>
                Preferred skills
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption_old_font" sx={classes.helperText}>
                Minimum of 3
              </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between">
            <Grid item sx={{ marginTop: '5px', marginBottom: '8px' }}>
              <Typography variant="caption_old_font" sx={classes.subtextText}>
                Add the skills and expertise you want your advisors to have
              </Typography>
            </Grid>
          </Grid>
          <AutocompleteCustome
            parentStyle={classes.fieldContainer}
            defaultValue={values.companySkills}
            onChange={handleAdvisorSkillsAutocompleteChange}
            onBlur={handleBlur}
            getOptionLabel={(option) => option.label}
            id="companySkills"
            input-data-cy="txtAdvisorSkills"
            limitTags={3}
            options={tags.skills}
            placeholder="Start by typing or click to select a skill"
          />
          <Box style={classes.skillsTagsContainer}>
            {values.companySkills.map((sk, index) => (
              <Box sx={classes.generalMargin} key={index}>
                <div style={classes.tagsContainer}>
                  <Typography sx={classes.tags} variant="body1">
                    {sk.label}
                  </Typography>
                </div>
              </Box>
            ))}
          </Box>
        </div>
        <Box sx={classes.container}>
          <Grid container justifyContent="space-between">
            <Grid item sx={{marginBottom: '10px'}}>
              <Typography variant="old_font" sx={classes.labelText}>
                Benefits and compensation
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="old_font" sx={classes.helperText}>
                Optional
              </Typography>
            </Grid>
          </Grid>
          {addBenefitModal ? (
            <Box>
              <Grid container justifyContent="space-between">
                <Grid item sx={{ marginTop: '5px', marginBottom: '8px' }}>
                  <Typography
                    variant="caption_old_font"
                    sx={classes.subtextText}
                  >
                    Choose any benefits or compensation opportunities for
                    advisors
                  </Typography>
                </Grid>
              </Grid>
              <FormControl fullWidth>
                <InputLabel
                  sx={{
                    height: '44px',
                    top: '-4px',
                    fontFamily: 'Poppins',
                    display: !category ? 'block' : 'none',
                  }}
                  id="demo-simple-select-label"
                  variant="filled"
                >
                  Select a benefits category
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={category || ''}
                  placeholder="Select a benefits category"
                  sx={{
                    height: '44px',
                    borderRadius: '10px',
                  }}
                  onChange={(e) => {
                    setCategory(e.target.value);
                  }}
                >
                  {categoryOptions.map((option) => (
                    <MenuItem sx={classes.menuItem} key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div style={classes.formInput}>
                <TextField
                  placeholder="Add a short description of what your benefit is. e.g. Offering equity awards for select members of our advisory board"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={5}
                  style={{
                    marginRight: 10,
                    marginTop: 15,
                  }}
                  value={details || ''}
                  InputProps={{
                    maxLength: 2000,
                    style: {
                      fontFamily: 'Poppins',
                      borderRadius: '10px',
                    },
                  }}
                  onChange={(evt) => setDetails(evt.target.value)}
                />
              </div>
              <Grid container justifyContent="flex-end">
                <Box>
                  <ButtonMat
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      addNewBenefit({ category, details });
                      setCategory('');
                      setDetails('');
                      setAddBenefitModal(false);
                      setBenefitToEdit(null);
                    }}
                    sx={classes.mainButton}
                    disabled={!category || !details || details.length < 10}
                  >
                    Save
                  </ButtonMat>
                </Box>
                <Box>
                  <ButtonMat
                    variant="text"
                    color="primary"
                    onClick={() => {
                      setCategory('');
                      setDetails('');
                      setAddBenefitModal(false);
                      setBenefitToEdit(null);
                    }}
                    sx={classes.secondaryButton}
                  >
                    Discard
                  </ButtonMat>
                </Box>
              </Grid>
            </Box>
          ) : null}
        </Box>
        <Grid container>
          {benefits.map((benefit, index) => (
            <Grid item xs={12} sm={6} md={6} key={index}>
              <BenefitCard
                benefit={benefit}
                removeBenefit={() => {
                  setBenefitToDelete(benefit);
                  setConfirmationModal(true);
                }}
                featuredBenefit={featuredBenefit}
                editBenefit={editBenefit}
              />
            </Grid>
          ))}
        </Grid>
        {!addBenefitModal ? (
          <div style={classes.addContentContainer}>
            <ACButton
              color="primary"
              size="medium"
              variant="text"
              style={{
                borderWidth: 2,
                borderRadius: 5,
                paddingLeft: 0,
              }}
              onClick={() => {
                setAddBenefitModal(true);
              }}
            >
              <AddCircleOutlineOutlined sx={classes.plusIcon} />
              <Typography variant="old_font">Add a benefit</Typography>
            </ACButton>
          </div>
        ) : null}
        <div style={classes.continueButtonContainer}>
          <ACButton
            data-testid="btnStep1Continue"
            color="primary"
            // disabled={isContinueButtonDisabled}
            loading={isLoading ? 1 : 0}
            data-cy="btnStep1Continue"
            style={classes.continueButton}
            onClick={() => submitStep1Form()}
          >
            {t('CONTINUE-BUTTON-TEXT')}
          </ACButton>
        </div>
      </form>
      {confirmationModal ? (
        <GenericConfirmationModal
          isVisible={confirmationModal}
          confirmationMessage="Are you sure you want to delete this benefit?"
          onConfirm={() => {
            removeBenefit();
            setConfirmationModal(false);
          }}
          onClose={() => setConfirmationModal(false)}
        />
      ) : null}
    </>
  );
}

export default ProfileUpgradeForm;
