import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, Paper, Typography, Avatar } from '@mui/material';
import { useAccount } from '../../contexts/Account';
import { ReactComponent as Building1 } from '../../icons/building_1.svg';
import { getNearestBoardMeeting } from '../../services/advisor_services';
import HelperTile from './HelperTile';
import eventBus, { eventBusValues } from '../../eventBus';
import dateFormat from '../../utils/dateFormat';

const useStyles = (isSM) => ({
  tilesContainer: {
    display: 'flex',
    displayDirection: 'row',
    flexDirection: isSM ? 'column' : '',
    width: '100%',
    marginBottom: '20px',
    justifyContent: 'space-between',
  },
  tile: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: isSM ? '100%' : '49%',
    height: '238px',
    borderRadius: '16px',
    padding: '8px',
    cursor: 'pointer',
    marginBottom: isSM ? '10px' : '',
  },
  topText: {
    fontWeight: 500,
    fontSize: '12px',
    position: 'absolute',
    top: '21px',
    right: '18px',
    color: '#74879A',
  },
  topTextStyle: {
    fontWeight: 500,
    fontSize: '12px',
    color: '#74879A',
    fontFamily: 'Poppins',
  },
  titleContainer: {
    display: 'flex',
    marginTop: '40px',
    marginLeft: '20px',
    alignItems: 'center',
  },
  tileTitle: {
    fontWeight: 600,
    fontSize: isSM ? '18px' : '24px',
    fontFamily: 'Poppins',
  },
  tileText: {
    fontWeight: 400,
    fontSize: '18px',
    marginLeft: '20px',
    marginTop: '4%',
    fontFamily: 'Poppins',
  },
  tileTextStyle: {
    fontWeight: 400,
    fontSize: '18px',
    marginLeft: '20px',
    marginTop: '4%',
    fontFamily: 'Poppins',
  },
  featuredTile: {
    backgroundColor: '#DBF7EB',
  },
  buildingIconContainer: {
    position: 'absolute',
    right: '0px',
    bottom: '0px',
    lineHeight: 0,
  },
});

function SearchMeetingTiles() {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const history = useHistory();
  const [featuredBoard, setFaturedBoard] = useState({
    id: null,
    companyId: null,
    title: 'Loading...',
    date: '',
    headline: '',
    logo: null,
  });
  const { accountDetails } = useAccount();
  const { t } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      try {
        const nearestMeetingCompany = await getNearestBoardMeeting({
          ADVISOR_ID: accountDetails.id,
        });
        if (
          nearestMeetingCompany.data &&
          nearestMeetingCompany.data.getNearestBoardMeeting
        ) {
          const meetingData = nearestMeetingCompany.data.getNearestBoardMeeting;
          if (meetingData) {
            setFaturedBoard({
              id: meetingData.id,
              companyId: meetingData.companyId,
              headline: meetingData.postTitle,
              title: meetingData.postCompanyContact.companyName,
              date: dateFormat(
                meetingData.meetingTime,
                'mmmm dS, yyyy @ h:MM TT'
              ),
              logo: meetingData.postCompanyContact.imageURL,
            });
          }
        }
      } catch (error) {
        console.log({ error });
      }
    }

    fetchData();
  }, []);

  function featureClickAction() {
    const board = accountDetails.boards.memberBoards.find(
      (advisorBoard) => advisorBoard.id === featuredBoard.companyId
    );
    if (board) {
      history.push(`/post/${featuredBoard.id}`);
    } else {
      eventBus.dispatch(eventBusValues.triggerCompanyDrawerAction, {
        companyId: featuredBoard.companyId,
        hidePostSection: false,
      });
    }
  }

  return (
    <div style={classes.tilesContainer}>
      <Paper
        elevation={0}
        sx={{ ...classes.tile, ...classes.featuredTile }}
        onClick={featureClickAction}
        id="upcomingBoardMeetingTile"
      >
        <Typography sx={classes.topText} style={classes.topTextStyle}>
          Upcoming Meeting
        </Typography>
        <div style={classes.titleContainer}>
          {featuredBoard.logo && (
            <Avatar
              alt="Company Logo"
              src={featuredBoard.logo}
              style={{ width: 50, height: 50, marginRight: 10 }}
            />
          )}
          <Typography style={classes.tileTitle}>
            {featuredBoard.title}
          </Typography>
        </div>
        <Typography
          sx={classes.tileText}
          style={{ ...classes.tileTextStyle, marginTop: 15 }}
        >
          {featuredBoard.headline}
        </Typography>
        <Typography
          sx={classes.tileText}
          style={{ ...classes.tileTextStyle, marginTop: 5 }}
        >
          {featuredBoard.date}
        </Typography>
        <div style={classes.buildingIconContainer}>
          <Building1 />
        </div>
      </Paper>
      <HelperTile
        title="Take part in live board meetings"
        text="Learn more about how live board meetings work"
        cardId="boardMeetingsVideo"
        takeTour
      />
    </div>
  );
}
export default SearchMeetingTiles;
