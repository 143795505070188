import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import ConstructionIcon from '@mui/icons-material/Construction';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const useStyles = (isSM) => ({
  filterContainer: {
    border: 'solid 1px #F0F1F3',
    borderRadius: '10px',
    margin: '15px',
    marginRight: isSM ? '15px' : '-10px',
    padding: isSM ? '0px' : '15px',
  },
  filterContainerTitle: {
    color: '#0F78FD',
    fontWeight: 600,
    fontSize: '14px',
  },
  filterContainerTitleMobile: {
    color: '#0F78FD',
    fontWeight: '600',
    fontSize: '14px',
    paddingLeft: '0',
    fontFamily: 'Poppins',
  },
  filterContainerList: {
    listStyleType: 'none',
    paddingLeft: '0px',
  },
  mobileIndustries: {
    color: '#767680',
  },
  filterContainerIconHeader: {
    paddingTop: '15px',
  },
  icon: {
    color: '#273762',
  },
  linkButton: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    color: '#767680',
    fontSize: '12px',
    fontWeight: 400,
    display: 'inline',
    margin: '0px',
    padding: '0px',
    fontFamily: 'Poppins',
  },
});

function AdvisorSearchExpertiseFilter() {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [openIndustries, setOpenIndustries] = React.useState(false);
  const [openSkill, setOpenSkill] = React.useState(false);
  const classes = useStyles(isSM);
  const { t } = useTranslation();

  const handleClickIndustries = () => {
    setOpenIndustries(!openIndustries);
  };

  const handleClickSkill = () => {
    setOpenSkill(!openSkill);
  };

  const industries = [
    'Private Equity',
    'Semi-conductors',
    'Accounting',
    'Apparel & Fashion',
    'Consumer Electronics',
    'Entertainment',
    'Food & Beverage',
    'Luxury Goods',
    'Management Consulting',
    'Transportation',
    'Telecom',
    'Biotechnology',
    'Real Estate',
    'Hospitality',
    'Investment Management',
    'Mining and Metals',
    'Utilities',
    'Aerospace & Defense',
    'Network Security',
    'Financial Services',
  ];

  const skills = [
    'Go-to-market Strategy',
    'SaaS',
    'Angel Investing',
    'Customer Acquisition',
    'Advertising',
    'Financial Strategy',
    'Digital Transformation',
    'Healthcare Consulting',
    'Consumer Packaged Goods',
    'IT Management',
    'Organizational Leadership',
    'Research and Development',
    'B2B Marketing',
    'Cyber-Security',
    'Software Development',
    'Communications',
    'Mechanical Engineering',
    'Artificial Intelligence',
    'Equity Capital Markets',
    'Clean Technology',
  ];

  function searchByIndustry(tag) {
    window.location.replace(`/use-advisors/browse/industry/${tag}`);
  }

  function searchBySkill(tag) {
    window.location.replace(`/use-advisors/browse/expertise/${tag}`);
  }

  return !isSM ? (
    <Paper sx={classes.filterContainer} elevation={0}>
      <div style={classes.filterContainerIconHeader}>
        <CorporateFareIcon sx={classes.icon} />
      </div>
      <Typography sx={classes.filterContainerTitle}>
        {t('POPULAR-INDUSTRIES')}
      </Typography>
      <ul style={classes.filterContainerList}>
        {industries.map((sk) => {
          return (
            <li key={sk}>
              <button
                type="button"
                style={classes.linkButton}
                onClick={() => searchByIndustry(sk)}
              >
                {sk}
              </button>
            </li>
          );
        })}
      </ul>
      <div style={classes.filterContainerIconHeader}>
        <ConstructionIcon sx={classes.icon} />
      </div>
      <Typography sx={classes.filterContainerTitle}>
        {t('POPULAR-EXPERTISE')}
      </Typography>
      <ul style={classes.filterContainerList}>
        {skills.map((sk) => {
          return (
            <li key={sk}>
              <button
                type="button"
                style={classes.linkButton}
                onClick={() => searchBySkill(sk)}
              >
                {sk}
              </button>
            </li>
          );
        })}
      </ul>
    </Paper>
  ) : (
    <Paper sx={classes.filterContainer} elevation={0}>
      <nav aria-label="secondary mailbox folders">
        <ListItemButton onClick={handleClickIndustries}>
          <ListItemIcon sx={classes.icon}>
            <CorporateFareIcon />
          </ListItemIcon>
          <ListItemText
            sx={classes.filterContainerTitleMobile}
            primary={t('POPULAR-INDUSTRIES')}
          />
          {openIndustries ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openIndustries} timeout="auto" unmountOnExit>
          <List>
            {industries.map((sk) => {
              return (
                <ListItem key={sk} disablePadding>
                  <ListItemButton
                    component="a"
                    onClick={() => searchByIndustry(sk)}
                  >
                    <ListItemText sx={classes.mobileIndustries} primary={sk} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Collapse>
      </nav>
      <nav aria-label="secondary mailbox folders">
        <ListItemButton onClick={handleClickSkill}>
          <ListItemIcon sx={classes.icon}>
            <ConstructionIcon />
          </ListItemIcon>
          <ListItemText
            sx={classes.filterContainerTitleMobile}
            primary={t('POPULAR-EXPERTISE')}
          />
          {openSkill ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openSkill} timeout="auto" unmountOnExit>
          <List>
            {skills.map((sk) => {
              return (
                <ListItem key={sk} disablePadding>
                  <ListItemButton
                    component="a"
                    onClick={() => searchBySkill(sk)}
                  >
                    <ListItemText sx={classes.mobileIndustries} primary={sk} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Collapse>
      </nav>
    </Paper>
  );
}

export default AdvisorSearchExpertiseFilter;
