import React, { useState, useEffect } from 'react';
import { useMediaQuery, Typography, Button, Dialog } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getPublicAdvisorRecurlyPlan } from '../services/advisor_services';
import { getAdvisorBySlugPublic } from '../services/Backend/account';
import AdvisorPublicProfile from '../scenes/AdvisorPublicProfile';
import LoadingIndicatorLogo from '../components/LoadingIndicatorLogo';
import AdvisoryCloudLogo from '../components/AdvisoryCloudLogo';
import ActionButton from '../components/Advisor/Profile/components/actionsButton';

const useStyles = (isSM) => ({
  companyContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 'auto',
    justifyContent: 'center',
  },
  mainButtonContainer: {
    display: 'flex',
    width: '100%',
    elevation: 5,
    justifyContent: 'center',
    position: 'sticky',
    bottom: '5px',
    zIndex: 3,
  },
  mainButton: {
    fontWeight: 'bold',
    width: isSM ? '90%' : '14vw',
    minWidth: '150px',
    height: isSM ? '50px' : '35px',
    textTransform: 'none',
    fontSize: isSM ? '18px' : '12px',
    borderColor: '#6736FA',
    borderRadius: '4px',
    background: '#6736FA',
    color: '#FFF',
    '&:hover': {
      background: '#6736FA',
    },
    whiteSpace: 'nowrap',
  },
  loadingContainer: {
    alignItems: 'center',
    bottom: '0px',
    display: 'flex',
    justifyContent: 'center',
    left: '0px',
    position: 'fixed',
    right: '0px',
    top: '0px',
  },
  hadlineText: {
    display: 'inline',
    fontWeight: 500,
    lineHeight: '24px',
    fontSize: '16px',
    wordBreak: 'keep-all',
    color: '#646D7A',
  },
  headlineLink: {
    fontWeight: 700,
    textDecoration: 'none',
    color: '#3171F6',
    display: 'inline',
    lineHeight: '24px',
    wordBreak: 'keep-all',
    fontSize: '16px',
    marginLeft: '7px',
  },
  blueContainer: {
    width: '100%',
    position: 'absolute',
    height: '150px',
    zIndex: 1,
    top: '80px',
    backgroundColor: '#1663F7',
  },
  logo: {
    marginLeft: '15px',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  website: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '20px',
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: isSM ? 'center' : 'space-between',
    backgroundColor: '#FFFFFF',
    height: '80px',
    padding: '0px 20px',
  },
  iframeContainer: {
    position: 'relative',
    paddingBottom: isSM ? '0px' : '50%',
    height: isSM ? '100%' : '550px',
    width: isSM ? '100%' : '800px',
    overflow: 'hidden',
  },
  iframe: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    overflowX: 'visible',
  },
  viewContainer: {
    display: 'flex',
    flex: 'auto',
    justifyContent: 'center',
    overflowX: 'hidden',
    zIndex: 2,
  },
  childrenContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: '#F5F7FA',
  },
});

function BasePublicLayout() {
  const [advisor, setAdvisor] = useState({});
  const { t } = useTranslation();
  const [advisorName, setAdvisorName] = useState('');
  const [meetUrl, setMeetUrl] = useState('');
  const [openMeetIFrame, setOpenMeetIFrame] = useState(false);
  const [loadingComplete, setLoadingComplete] = useState(false);
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const params = useParams('/profile/:advisorSlug');
  const { advisorSlug } = params;

  if (!advisorSlug) {
    window.location.replace(
      'https://www.advisorycloud.com/use-advisors/browse'
    );
  }

  React.useEffect(() => {
    getPublicAdvisorRecurlyPlan({
      recurlyPlanCode: advisor.recurlyPlanCode,
    })
      .then((result) => {
        const planCode = result.data.getRecurlyPlan;
        if (planCode.meetingsEnabled) {
          setMeetUrl(
            `https://register.advisorycloud.com/connect-with-me/a.html?first_name=${advisor.givenName}&last_name=${advisor.surName}&sosfcontactid=${advisor.salesforceContactId}&id=${advisor.id}&hourly_rate=${advisor.meetingRate}`
          );
        } else {
          setMeetUrl(
            `https://register.advisorycloud.com/connect-with-me/b.html?first_name=${advisor.givenName}&last_name=${advisor.surName}&sosfcontactid=${advisor.salesforceContactId}&id=${advisor.id}`
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [advisor]);

  useEffect(() => {
    getAdvisorBySlugPublic({ advisorSlug }).then((response) => {
      if (response.errors) {
        if (response.errors.length > 0) {
          window.location.replace(
            'https://www.advisorycloud.com/use-advisors/browse'
          );
        }
      } else {
        setAdvisor(response.data.getAdvisor);
        setAdvisorName(response.data.getAdvisor.givenName);
        setLoadingComplete(true);
      }
    });
  }, []);

  function handleCloseDialog() {
    setOpenMeetIFrame(false);
  }

  function renderBaseComponents() {
    return !loadingComplete ? (
      <div style={classes.companyContainer}>
        <div style={classes.loadingContainer}>
          <LoadingIndicatorLogo size={200} iconFontSize={93} iconRight={105}>
            <div style={classes.childrenContainer}>
              <div style={classes.viewContainer}>
                <AdvisorPublicProfile
                  advisor={advisor}
                  setOpenMeetIFrame={setOpenMeetIFrame}
                />
              </div>
            </div>
          </LoadingIndicatorLogo>
        </div>
      </div>
    ) : (
      <div style={classes.companyContainer}>
        <div style={classes.childrenContainer}>
          <div style={classes.viewContainer}>
            <AdvisorPublicProfile
              advisor={advisor}
              setOpenMeetIFrame={setOpenMeetIFrame}
            />
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <Dialog
        open={openMeetIFrame}
        fullScreen={isSM}
        maxWidth="md"
        height="md"
        onClose={handleCloseDialog}
      >
        <div style={classes.iframeContainer}>
          <iframe
            src={meetUrl}
            title="Meet"
            frameBorder="0"
            style={classes.iframe}
          />
        </div>
      </Dialog>
      <div style={classes.companyContainer}>
        <div style={classes.header}>
          <div style={classes.logo}>
            <AdvisoryCloudLogo width={200} />
          </div>
          {isSM ? null : (
            <div style={classes.website}>
              <Typography sx={classes.hadlineText}>
                Interested in becoming an advisor?
                <a
                  style={classes.headlineLink}
                  href="https://advisorycloud.com/join-an-advisory-board?source=publicprofile"
                >
                  {'Get started here >'}
                </a>
              </Typography>
            </div>
          )}
        </div>
        {!isSM && <div style={classes.blueContainer} />}
        {renderBaseComponents()}
        {isSM && (
          <div style={classes.mainButtonContainer}>
            <ActionButton
              isPublicProfileView
              advisor={advisor}
              isMobilePublicProfile
            />
          </div>
        )}
      </div>
    </>
  );
}

export default BasePublicLayout;
