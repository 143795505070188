import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { postShare } from '../../services/LinkedIn';
import { useAccount } from '../../contexts/Account';
import { AccountType } from '../../services/utils/types';
import LoadingLogoSkeleton from '../SkeletonLoading/loadingLogoSkeleton';

const useStyles = ({ fontFamily, isSM }) => ({
  container: {
    paddingTop: '10px',
  },
  contentWithBorder: {
    alignSelf: 'center',
    height: '80%',
    margin: '4px',
    border: '1px solid',
    borderColor: 'secondary.main',
    borderRadius: '10px',
    backgroundColor: '#ffffff',
    padding: '0 0 20px 0',
    marginBottom: '10px',
    boxShadow: '2px 2px 18px #888888',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'center',
  },
  contentNoBorder: {
    width: '470',
  },
  textHeadline: {
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontWeight: '800',
    fontSize: '24px',
    padding: '25px 25px 15px',
  },
  dialogContainer: {
    borderRadius: '10px',
    boxShadow: '0 5px 20px rgba(0, 0, 0, 0.07)',
    padding: isSM ? '5px' : '30px',
  },
  dialogActions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'row',
    marginTop: '20px',
    marginBottom: '20px',
  },
  mainButton: {
    borderWidth: '4px',
    padding: '15px 50px 15px 50px',
    textTransform: 'none',
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    fontSize: '14px',
    height: '45px',
    borderRadius: '40px',
  },
});

export default function LinkedInPostModal({
  linkedInPostModal,
  setLinkedInPostModal,
  linkedInResponse,
  fontFamily,
  shareProfile,
  contents,
}) {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles({ fontFamily, isSM });
  const { accountDetails, type } = useAccount();
  const { t } = useTranslation();
  const { displayName, headline, publicProfileUrl } = accountDetails;
  let title = contents ? contents.title : null;
  let text = contents ? contents.text : null;
  const shareUrl =
    contents && contents.link
      ? contents.link
      : `${window.location.origin}/profile/${publicProfileUrl}`;
  if (shareProfile) {
    title = headline ? `${displayName} - ${headline}` : displayName;
    text = `${t('ADVISOR-LINKEDIN-POST-TEXT')}`;
  }
  const [linkedInPostTitle, setLinkedInPostTitle] = useState(title || '');
  const [postBody, setBodyTitle] = useState(text || '');
  const [postShareUrl, setPostShareUrl] = useState(shareUrl);
  const [isLoading, setIsLoading] = useState(false);

  async function postInLinkedIn() {
    setIsLoading(true);
    const shareThumbnailUrl =
      'https://app.advisorycloud.com/images/linkedin-thumbnail.png';
    try {
      const postResponse = await postShare(
        linkedInPostTitle,
        postBody,
        postShareUrl,
        shareThumbnailUrl,
        type === AccountType.COMPANY ? accountDetails.id : null
      );
      linkedInResponse(postResponse);
      setLinkedInPostModal(false);
    } catch (error) {
      console.error('Error posting to LinkedIn:', error);
    } finally {
      setIsLoading(false);
    }
  }

  function handleClose() {
    setLinkedInPostModal(false);
  }

  const inputLabelProps = {
    style: {
      fontFamily: fontFamily,
    },
  };

  return (
    <Dialog
      open={linkedInPostModal}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: classes.dialogContainer,
      }}
    >
      <DialogTitle id="alert-dialog-title">
        <Typography sx={classes.textHeadline}>
          {contents && contents.formTitle
            ? contents.formTitle
            : shareProfile
            ? t('ADVISOR-SHARE-LINKEDIN-MODAL-TITLE')
            : t('STATUS-SHARE-LINKEDIN-MODAL-TITLE')}
        </Typography>
      </DialogTitle>
      <DialogContent sx={classes.contentNoBorder}>
        <Box sx={classes.container}>
          <div style={classes.advisorHeadline}>
            <TextField
              id="advisorHeadline"
              placeholder="Title"
              label="Title"
              variant="outlined"
              fullWidth
              sx={{
                marginRight: '10px',
                marginTop: '0px',
              }}
              value={linkedInPostTitle}
              InputLabelProps={inputLabelProps}
              inputProps={{
                maxLength: 200,
                style: {
                  fontFamily: fontFamily,
                },
              }}
              onChange={(evt) => setLinkedInPostTitle(evt.target.value)}
            />
          </div>
          <div style={classes.advisorHeadline}>
            <TextField
              placeholder="Description"
              label="Description"
              variant="outlined"
              fullWidth
              multiline
              rows={2}
              sx={{
                marginRight: '10px',
                marginTop: '15px',
                fontFamily: fontFamily,
              }}
              value={postBody}
              InputLabelProps={inputLabelProps}
              inputProps={{
                style: {
                  fontFamily: fontFamily,
                },
              }}
              onChange={(evt) => setBodyTitle(evt.target.value)}
            />
          </div>
          <div style={classes.advisorHeadline}>
            <TextField
              id="advisorHeadline"
              placeholder="Share Url"
              label="Share Url"
              variant="outlined"
              fullWidth
              style={{
                marginRight: 10,
                marginTop: 15,
                fontFamily: fontFamily,
              }}
              value={postShareUrl || ''}
              InputLabelProps={inputLabelProps}
              inputProps={{
                maxLength: 200,
                style: {
                  fontFamily: fontFamily,
                },
              }}
              onChange={(evt) => setPostShareUrl(evt.target.value)}
            />
          </div>
        </Box>
      </DialogContent>
      <DialogActions sx={classes.dialogActions}>
        <Button
          disableRipple
          color="primary"
          onClick={() => {
            handleClose();
          }}
          sx={{
            ...classes.mainButton,
            color: '#3171F6',
            backgroundColor: '#fff',
            '&:hover': {
              backgroundColor: '#fff',
            },
            fontFamily: 'Poppins',
            border: '1px solid #3171F6',
          }}
        >
          {t('CANCEL')}
        </Button>
        <Button
          disableRipple
          color="primary"
          onClick={() => {
            postInLinkedIn();
          }}
          sx={{
            ...classes.mainButton,
            color: '#ffffff',
            backgroundColor: '#3171F6',
            opacity: isLoading ? 0.5 : 1,
            '&:hover': {
              backgroundColor: isLoading ? '#3171F6' : '#285cdb',
            },
            fontFamily: 'Poppins',
          }}
          disabled={!postBody || !linkedInPostTitle || isLoading}
        >
          {isLoading ? (
            <LoadingLogoSkeleton />
          ) : (
            t('ADVISOR-SHARE-LINKEDIN-MODAL-ACTION')
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
