import React, { useEffect, useState } from 'react';
import { Typography, TextField, useMediaQuery } from '@mui/material';
import ACAvatar from './../../ACAvatar';
import ACButton from './../../ACButton';

const useStyles = (isSM) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  avatarContainer: {
    display: 'flex',
    marginRight: '10px',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flex: 'auto',
    width: '100%',
    marginTop: '8px',
    borderRadius: '8px',
  },
  input: {
    borderRadius: '8px',
    backgroundColor: '#F2F3F5',
    minHeight: isSM ? '35px' : '43px',
    padding: '7px 7px 7px 14px',
    fontFamily: 'Poppins',
  },
  firstRowContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  warningText: {
    color: 'error.main',
  },
});

function Reply(props) {
  const {
    onSubmit,
    image,
    type,
    loading,
    onCancel,
    value,
    variant,
    placeholder,
  } = props;
  const { showCancel, ...filteredProps } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [IS_INPUT_VALID, setIS_INPUT_VALID] = useState(false);
  const classes = useStyles(isSM);

  function handleButtonClick() {
    if (onSubmit) {
      onSubmit(value);
    }
  }

  function handleCancelClick() {
    onCancel();
  }

  useEffect(() => {
    if (value.length > 0) {
      if (value.trim().length > 29) {
        setIS_INPUT_VALID(true);
      } else {
        setIS_INPUT_VALID(false);
      }
    } else {
      setIS_INPUT_VALID(false);
    }
  }, [value]);

  function renderResponseType() {
    return (
      <React.Fragment>
        {IS_INPUT_VALID ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {showCancel && (
              <ACButton
                variant="outlined"
                onClick={handleCancelClick}
                disabled={!IS_INPUT_VALID}
                style={{
                  borderRadius: 5,
                  border: 'none',
                  marginRight: '7px',
                }}
                size={isSM ? 'small' : 'medium'}
              >
                <Typography
                  style={{
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '15px',
                    lineHeight: '22px',
                    color: '#97A3B7',
                  }}
                >
                  Discard
                </Typography>
              </ACButton>
            )}

            <ACButton
              variant={variant}
              onClick={handleButtonClick}
              disabled={!IS_INPUT_VALID || loading}
              style={{
                borderRadius: '40px',
                padding: '15px 25px 15px 25px',
                backgroundColor: '#3171F6',
              }}
              loading={loading}
              size={isSM ? 'small' : 'medium'}
            >
              <Typography
                style={{
                  fontStyle: 'normal',
                  fontWeight: 800,
                  fontSize: '15px',
                  lineHeight: '22px',
                  color: '#FFFFFF',
                }}
              >
                {type === 'reply' ? 'Reply' : 'Post'}
              </Typography>
            </ACButton>
          </div>
        ) : (
          <Typography sx={classes.warningText} variant="caption">
            {value === undefined || value === null || value.length === 0
              ? null
              : ' A minimum of 30 characters are required.'}
          </Typography>
        )}
      </React.Fragment>
    );
  }

  return (
    <div style={classes.container}>
      <div style={classes.firstRowContainer}>
        <div style={classes.avatarContainer}>
          <ACAvatar image={image} size={isSM ? 'sm' : 'md'} />
        </div>
        <TextField
          {...filteredProps}
          fullWidth
          multiline
          placeholder={placeholder ? placeholder : 'Add a comment...'}
          InputProps={{
            classes: {
              root: classes.input,
              notchedOutline: classes.notchedOutline,
            },
          }}
          variant="outlined"
        />
      </div>
      <div style={classes.inputContainer}>{renderResponseType()}</div>
    </div>
  );
}

export default Reply;
