import React from 'react';
import { useHistory } from 'react-router-dom';
import AdvisorList from './../../Advisor/List';
import { AdvisorDetailCardMetric } from './../../../services/utils/types';
import eventBus, { eventBusValues } from './../../../eventBus';

function MyAdvisorsContent(props) {
  const {
    endpointResponse,
    isMainCallFetching,
    incrementOffset,
    fetchAdvisor,
    endPagination,
    contentProps = {},
  } = props;

  const history = useHistory();

  function backButtonClickAction() {
    history.push('/advisors');
  }

  function answersClickAction(advisor) {
    history.push(
      `/advisors/${advisor.publicProfileUrl}#${AdvisorDetailCardMetric.ANSWERS}`
    );
  }

  function commentsClickAction(advisor) {
    history.push(
      `/advisors/${advisor.publicProfileUrl}#${AdvisorDetailCardMetric.COMMENTS}`
    );
  }

  function requestMeetingClickAction(advisor) {
    const url = `${process.env.REACT_APP_LEGACY_URL}/profile/${advisor.publicProfileUrl}/speak-with-me?advisor_first_name=${advisor.givenName}&advisor_last_name=${advisor.surName}`;
    window.open(url, '_blank');
  }

  function openAdvisorProfile(advisor) {
    eventBus.dispatch(eventBusValues.triggerAdvisorDrawerAction, advisor);
  }

  function starClickAction(advisor) {
    // eslint-disable-next-line no-console
    console.log(`Clicked star ${advisor.publicProfileUrl}`);
  }

  function breakthroughsClickAction(advisor) {
    // eslint-disable-next-line no-console
    console.log(`Breakthroughs ${advisor.publicProfileUrl}`);
  }

  function reportAdvisorClickClickAction(advisor) {
    // eslint-disable-next-line no-console
    console.log(`Clicked Report Advisor ${advisor.publicProfileUrl}`);
  }

  const clickActions = {
    answers: answersClickAction,
    comments: commentsClickAction,
    requestMeeting: requestMeetingClickAction,
    star: starClickAction,
    // Not Implemented
    breakthroughs: breakthroughsClickAction,
    reportAdvisor: reportAdvisorClickClickAction,
  };

  const propsToPass = {
    individualAdvisor: {
      clickActions,
      backButtonClickAction,
      answersClickAction,
      commentsClickAction,
    },
    ...contentProps,
  };

  return (
    <>
      <AdvisorList
        onClickAction={openAdvisorProfile}
        advisorList={endpointResponse}
        isMainCallLoading={isMainCallFetching}
        incrementOffset={incrementOffset}
        endPagination={endPagination}
        doNotUsePagination
        {...propsToPass}
        fetchAdvisor={fetchAdvisor}
        doNotInfiniteScroll
        requestMeetingClickAction={requestMeetingClickAction}
      />
    </>
  );
}
export default MyAdvisorsContent;
