import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  useMediaQuery,
  Card,
  CardContent,
  Avatar,
  IconButton,
  Typography,
  Box,
  Dialog,
  Chip,
} from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import CircleIcon from '@mui/icons-material/Circle';
import { AccountType } from './../../../services/utils/types';
import ActionsButton from './ActionsButton';
import PostCardBottom from './PostCardBottom';
import ACSnackbar from './../../ACSnackbar';
import InviteToJoinConfirmationModal from './../../Company/InviteToJoinConfirmationModal';
import CustomIcon from './../../../icons/customIcon';
import { getPublicAdvisorRecurlyPlan } from './../../../services/advisor_services';
import eventBus, { eventBusValues } from './../../../eventBus';
import { useAccount } from './../../../contexts/Account';
import { isNil } from './../../../services/utils';
import { SnackbarType } from './../../../services/utils/types';
import { inviteToJoin } from './../../../services/company_services';
import dateFormat from './../../../utils/dateFormat';
import ChipWithRef from './ChipWithRef';
import IframeEmbedModal from './../../IframeEmbedModal';
import useCanMessage from './../../../hooks/useMessagePermission';
import StarSymbol from './../Profile/components/star-symbol.svg';
import { sendFSEventInvitedAdvisor } from '../../../services/FullStory';

const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: '#D7F1FD',
  color: '#232B35',
  fontWeight: '500',
  borderRadius: '20px',
  padding: '0 8px',
  border: '1px solid #4BC6FF',
  height: '26px',
  width: '103px',
  marginLeft: '10px',
  fontSize: '12px',
  letterSpacing: '-0.43px',
  paddingRight: '0px',
  paddingLeft: '0px',
  marginBottom: '10px',
}));

const AdvisorDetailCard = (props) => {
  const {
    advisor,
    // answers
    answers,
    answersClick,
    answersFilled,
    answersBadge,
    answersBadgeContent,
    answersDisabled,
    // comments
    comments,
    commentsClick,
    commentsFilled,
    commentsBadge,
    commentsBadgeContent,
    commentsDisabled,
    // breakthroughs
    cardClick,
    requestMeetingClick,
    starClicked,
    isStarFilled,
    advisorAvatar,
    showBottom,
    topMatch,
    displayTopMatchBadge,
    topMatchCheckbox,
  } = props;
  const {
    conversations,
    tags,
    accountDetails,
    currentUserInfo,
    isFreeTrial,
    isFreemium,
    asLoggedUserDetails,
    type,
    isImpersonated,
  } = useAccount();
  const history = useHistory();
  const canMessage = useCanMessage(advisor.id, AccountType.ADVISOR);
  const [alreadyMessagedBoardOwner, setAlreadyMessagedBoardOwner] =
    useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [snackbarSuccess, setSnackbarSuccess] = useState(false);
  const [snackbarWarning, setSnackbarWarning] = useState(false);
  const [refresh, doRefresh] = useState(0);
  const [inviteModalLoading, setInviteModalLoading] = useState(false);
  const [selectedAdvisor, setSelectedAdvisor] = useState(null);
  const [processing, setProcessing] = useState([]);
  const [showInviteToJoinConfirmation, setShowInviteToJoinConfirmation] =
    useState(false);
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isLessThan1150 = useMediaQuery((theme) =>
    theme.breakpoints.down([1150])
  );
  const isMD = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const isLG = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  const { t } = useTranslation();
  const [openMeetIFrame, setOpenMeetIFrame] = useState(false);
  const [meetUrl, setMeetUrl] = useState(false);
  const [favoriteSkills, setFavoriteSkills] = useState([]);
  const [cardWidth, setCardWidth] = useState(0);
  const cardContentRef = useRef();

  const [iFrameSource, setIframeSource] = useState('');
  const [meetingsEnabled, setMeetingsEnabled] = useState(false);
  const [openIframe, setOpenIframe] = useState(false);
  const [isTopMatch, setIsTopMatch] = useState(topMatch);
  let highlightColor = topMatch ? '#D7F1FD' : '#FEF8D1';

  const [invitationNote, setInvitationNote] = useState("");

  const isOnBoard = useMemo(() => {
    return (
      (accountDetails.boardAdvisors &&
        accountDetails.boardAdvisors.some((ba) => ba.id == advisor.id)) ||
      (accountDetails.invitedAdvisors &&
        accountDetails.invitedAdvisors.some((ba) => ba.id == advisor.id))
    );
  }, []);

  function findingConversation() {
    if (conversations && conversations.length > 0) {
      const index = conversations.findIndex(
        (conversation) =>
          conversation.conversationMemberAccountId === advisor.id
      );
      setAlreadyMessagedBoardOwner(index > -1);
    }
  }

  function conversationClick(event) {
    if (event && event.stopPropagation) event.stopPropagation();
    if (canMessage === undefined) return;
    if (canMessage) {
      const found = conversations.find(
        (conversation) =>
          conversation.conversationMemberAccountId === advisor.id
      );
      if (found) {
        history.push(`/messages?conversationId=${found.conversationId}`);
      } else {
        eventBus.dispatch(eventBusValues.onSendConversationNewMessage, {
          senderId: advisor.id,
          senderName: advisor.displayName,
        });
      }
    } else {
      eventBus.dispatch(
        type === AccountType.COMPANY
          ? eventBusValues.triggerCompanyUpgradeAction
          : eventBusValues.triggerAdvisorUpgradeAction,
        {
          action: () => {
            eventBus.dispatch(eventBusValues.onSendConversationNewMessage, {
              senderId: advisor.id,
              senderName: advisor.displayName,
            });
          },
        }
      );
    }
  }

  function sendFSEventOnInvite({ outcome, inviteId }) {
    sendFSEventInvitedAdvisor({
      advisorId: selectedAdvisor.id,
      asLoggedUserDetails,
      outcome,
      inviteId: inviteId || '',
      source: 'card',
      invitationNote: invitationNote,
      invitationIncludedNote: invitationNote !== "" ? true : false
    });
  }

  function confirmInviteToJoin() {
    setInviteModalLoading(true);
    inviteToJoinCall(selectedAdvisor.id);
  }

  function inviteToJoinCall(advisorId) {
    setProcessing([...processing, advisorId]);
    inviteToJoin({
      ADVISOR_ID: advisorId,
      COMPANY_ID: accountDetails.id,
      USER_ID: asLoggedUserDetails.userId,
      INVITATION_NOTE: invitationNote,
      IS_TOP_MATCH: topMatch
    })
      .then(({ data }) => {
        setSnackMessage(`${advisor.displayName} has been invited to your board`);
        setSnackbarSuccess(true);
        sendFSEventOnInvite({
          outcome: 'invited',
          inviteId: data.inviteAdvisorToJoin.id,
        });
        if (accountDetails.invitedAdvisors) {
          accountDetails.invitedAdvisors.push({
            id: selectedAdvisor.id,
            inviteDate: new Date().toISOString(),
          });
        } else {
          accountDetails.invitedAdvisors = [
            {
              id: selectedAdvisor.id,
              inviteDate: new Date().toISOString(),
            },
          ];
        }
        doRefresh(refresh + 1);
        setShowInviteToJoinConfirmation(false);
        setInviteModalLoading(false);
        setSnackMessage(
          `${selectedAdvisor.displayName} has been invited to your board`
        );
        setSnackbarSuccess(true);
      })
      .catch((e) => {
        sendFSEventOnInvite({
          outcome: 'error',
        });
        setShowInviteToJoinConfirmation(false);
        setInviteModalLoading(false);
        const err = JSON.parse(e.errors[0].message);
        const { failValue, code } = err;
        if (
          code &&
          (code === 'UnsuccessfullyInvitedToJoin' ||
            code === 'UnsuccessfullyInvitedToJoinFixable')
        ) {
          if (code === 'UnsuccessfullyInvitedToJoinFixable') {
            setSnackMessage(err.error);
          } else {
            setSnackMessage(
              "You'll be able to invite advisors to join your board once you've completed your onboarding call."
            );
          }
          setSnackbarWarning(true);
          return;
        }
        const failValueModal = {
          '': () => showCallNotScheduledModal(),
          null: () => showCallNotScheduledModal(),
          undefined: () => showCallNotScheduledModal(),
          'Not Scheduled': () => showCallNotScheduledModal(),
          Scheduled: () =>
            showCallScheduledModal(accountDetails.onboardingDate),
        };
        if (
          failValueModal[failValue] &&
          code !== 'UnsuccessfullyInvitedToJoin' &&
          code !== 'UnsuccessfullyInvitedToJoinFixable'
        ) {
          failValueModal[failValue]();
        }
        setSnackMessage('Warning');
        setSnackbarWarning(true);
      });
  }

  function showCallNotScheduledModal() { }

  function inviteToJoinClickAction(advisor) {
    setSelectedAdvisor(advisor.advisor);
    setShowInviteToJoinConfirmation(true);
  }

  function bookCallAction() {
    if (meetingsEnabled) {
      setIframeSource(
        `https://register.advisorycloud.com/connect-with-me/c.html?first_name=${advisor.givenName
        }&requester_cognito_id=${currentUserInfo?.username ?? ''
        }&recipient_cognito_id=${advisor.id
        }&is_board_member=${isOnBoard}&company_slug=${accountDetails?.slug ?? ''
        }&hourly_rate=${advisor.meetingRate}`
      );
    } else {
      setIframeSource(
        `https://register.advisorycloud.com/connect-with-me/c.html?first_name=${advisor.givenName
        }&requester_cognito_id=${currentUserInfo?.username ?? ''
        }&recipient_cognito_id=${advisor.id
        }&is_board_member=${isOnBoard}&company_slug=${accountDetails?.slug ?? ''
        }`
      );
    }
    setOpenIframe(true);
  }

  function hireAction() {
    if (meetingsEnabled) {
      setIframeSource(
        `https://register.advisorycloud.com/connect-with-me/q.html?first_name=${advisor.givenName
        }&requester_cognito_id=${currentUserInfo?.username ?? ''
        }&recipient_cognito_id=${advisor.id
        }&is_board_member=${isOnBoard}&company_slug=${accountDetails?.slug ?? ''
        }&sosfcontactid=${accountDetails?.salesforceContactId ?? ''
        }&hourly_rate=${advisor.meetingRate}`
      );
    } else {
      setIframeSource(
        `https://register.advisorycloud.com/connect-with-me/q.html?first_name=${advisor.givenName
        }&requester_cognito_id=${currentUserInfo?.username ?? ''
        }&recipient_cognito_id=${advisor.id
        }&is_board_member=${isOnBoard}&company_slug=${accountDetails?.slug ?? ''
        }&sosfcontactid=${accountDetails?.salesforceContactId ?? ''}`
      );
    }
    setOpenIframe(true);
  }

  function getActionDropdownTitles(evaluated) {
    const chatTitle = {
      icon: (
        <CustomIcon
          iconname={'airplane'}
          sx={{
            fontSize: '14px',
            marginBottom: '5px',
            marginRight: '11px',
          }}
        />
      ),
      title: t('CHAT'),
      click: () => {
        conversationClick();
      },
    };
    const inviteToJoinTitle = {
      icon: (
        <CustomIcon
          iconname="invite-join"
          sx={{
            fontSize: '12px',
            marginBottom: '5px',
            marginRight: '18px',
            marginLeft: '-3px',
          }}
        />
      ),
      title: t('INVITE_TO_JOIN'),
      click: () => {
        if (isFreeTrial && !isImpersonated) {
          eventBus.dispatch(eventBusValues.triggerCompanyUpgradeAction, {
            action: () => {
              eventBus.dispatch(
                `${eventBusValues.onInviteToJoinCompany}-${advisor.id}`,
                {
                  advisor,
                }
              );
            },
          });
        } else {
          eventBus.dispatch(
            `${eventBusValues.onInviteToJoinCompany}-${advisor.id}`,
            {
              advisor,
            }
          );
        }
      },
    };
    const bookCall = {
      icon: (
        <CustomIcon
          iconname="book-call"
          sx={{
            fontSize: '12px',
            marginBottom: '5px',
            marginRight: '15px',
          }}
        />
      ),
      title: 'Book 1:1 Video Call',
      click: () => {
        if (isFreeTrial && !isImpersonated) {
          eventBus.dispatch(eventBusValues.triggerCompanyUpgradeAction, {
            action: () => {
              eventBus.dispatch(eventBusValues.onBook1on1VideoCall);
            },
          });
        } else bookCallAction();
      },
    };
    const hireAdvisor = {
      icon: (
        <CustomIcon
          iconname="hire-advisor"
          sx={{
            fontSize: '12px',
            marginBottom: '5px',
            marginRight: '15px',
          }}
        />
      ),
      title: 'Hire Advisor',
      click: () => {
        if (isFreeTrial && !isImpersonated) {
          eventBus.dispatch(eventBusValues.triggerCompanyUpgradeAction, {
            action: () => {
              eventBus.dispatch(eventBusValues.onHireAdvisor);
            },
          });
        } else {
          hireAction();
        }
      },
    };
    if (isFreemium) {
      const titles = [chatTitle, bookCall, hireAdvisor];
      if (!evaluated && type === AccountType.COMPANY) {
        inviteToJoinTitle.icon = (
          <CustomIcon
            iconname="invite-join"
            sx={{
              fontSize: '12px',
              marginBottom: '5px',
              marginRight: '15px',
            }}
          />
        );
        titles.unshift(inviteToJoinTitle);
      }
      return titles;
    }
    const titles = [];
    if (
      !evaluated &&
      type === AccountType.COMPANY &&
      !accountDetails.invitedAdvisors.some(
        (invitedAdvisor) => invitedAdvisor.id === advisor.id
      )
    ) {
      titles.push(inviteToJoinTitle);
    }
    titles.push(chatTitle);
    titles.push(bookCall);
    titles.push(hireAdvisor);
    return titles;
  }

  function getAffiliation() {
    let text = '';
    if (
      accountDetails.boardAdvisors &&
      accountDetails.boardAdvisors.length &&
      accountDetails.boardAdvisors.some((item) => item.id == advisor.id)
    ) {
      const matchingAdvisor = accountDetails.boardAdvisors.find(
        (item) => item.id == advisor.id
      );
      text += `Board member since ${dateFormat(
        matchingAdvisor.joinedOnDate,
        'mmmm dS, yyyy'
      )}`;
    } else if (
      accountDetails.invitedAdvisors &&
      accountDetails.invitedAdvisors.length &&
      accountDetails.invitedAdvisors.some((item) => item.id == advisor.id)
    ) {
      const matchingAdvisor = accountDetails.invitedAdvisors.find(
        (item) => item.id == advisor.id
      );
      text += `Invited on ${dateFormat(
        matchingAdvisor.inviteDate,
        'mmmm dS, yyyy'
      )}`;
    } else {
      text = '';
    }
    return text;
  }

  useEffect(() => {
    findingConversation();

    eventBus.on(eventBusValues.onNewConversation, findingConversation);
    eventBus.on(
      `${eventBusValues.onInviteToJoinCompany}-${advisor.id}`,
      inviteToJoinClickAction
    );
    eventBus.on(eventBusValues.onBook1on1VideoCall, bookCallAction);
    eventBus.on(eventBusValues.onHireAdvisor, hireAction);

    return () => {
      eventBus.remove(eventBusValues.onNewConversation, findingConversation);
      eventBus.remove(
        `${eventBusValues.onInviteToJoinCompany}-${advisor.id}`,
        inviteToJoinClickAction
      );
      eventBus.remove(eventBusValues.onBook1on1VideoCall, bookCallAction);
      eventBus.remove(eventBusValues.onHireAdvisor);
    };
  }, []);

  function mapSkills(skills) {
    if (!isNil(skills)) {
      const filteredArray = tags.skills.filter((element) =>
        skills.includes(String(element.id))
      );
      const mapSkillTag = filteredArray.map((element) => {
        return {
          id: element.id,
          label: element.label,
        };
      });
      return mapSkillTag;
    }
    return [];
  }

  function renderIcon() {
    if (alreadyMessagedBoardOwner) {
      return (
        <IconButton
          style={{
            width: '100%',
            height: '100%',
            color: '#232B35',
          }}
          className="conversationButton"
          onClick={conversationClick}
        >
          <CustomIcon iconname="comments" style={{ fontSize: 20 }} />
        </IconButton>
      );
    }

    return (
      <IconButton
        style={{
          width: '100%',
          height: '100%',
          color: '#232B35',
        }}
        className="conversationButton"
        onClick={conversationClick}
      >
        <CustomIcon iconname="airplane" style={{ fontSize: 16 }} />
      </IconButton>
    );
  }

  const HighlightedText = ({ text }) => {
    return <span dangerouslySetInnerHTML={{ __html: text }} />;
  };

  function setAdvisorSkills() {
    const displaySkills = mapSkills(advisor.favoriteSkillsIds);
    let nonFavoriteSkills =
      advisor.skillTagIds &&
        advisor.skillTagIds.length > 0 &&
        displaySkills &&
        displaySkills.length > 0
        ? advisor.skillTagIds.filter(
          (tag) => !advisor.favoriteSkillsIds?.includes(tag)
        )
        : [];
    nonFavoriteSkills = mapSkills(nonFavoriteSkills).sort((a, b) =>
      a.label > b.label ? 1 : -1
    );
    return [...displaySkills, ...nonFavoriteSkills];
  }

  useEffect(() => {
    setAdvisorSkills();
    setFavoriteSkills(setAdvisorSkills());
    eventBus.on(eventBusValues.openRequestMeetingIframe, (id) => {
      if (id === advisor.id) {
        setOpenMeetIFrame(true);
      }
    });

    if (advisor) {
      getPublicAdvisorRecurlyPlan({
        recurlyPlanCode: advisor.recurlyPlanCode,
      })
        .then((result) => {
          const planCode = result?.data?.getRecurlyPlan;
          if (planCode && planCode.meetingsEnabled) {
            setMeetingsEnabled(planCode.meetingsEnabled);
            setMeetUrl(
              `https://register.advisorycloud.com/connect-with-me/a.html?first_name=${advisor.givenName}&last_name=${advisor.surName}&sosfcontactid=${advisor.salesforceContactId}&id=${advisor.id}&hourly_rate=${advisor.meetingRate}`
            );
          } else {
            setMeetUrl(
              `https://register.advisorycloud.com/connect-with-me/b.html?first_name=${advisor.givenName}&last_name=${advisor.surName}&sosfcontactid=${advisor.salesforceContactId}&id=${advisor.id}`
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (
      accountDetails.invitedAdvisors &&
      accountDetails.invitedAdvisors.length &&
      accountDetails.invitedAdvisors.some((item) => item.id == advisor.id)
    ) {
      const matchingAdvisor = accountDetails.invitedAdvisors.find(
        (item) => item.id == advisor.id
      );
      console.log(matchingAdvisor);
      setIsTopMatch(matchingAdvisor.isTopMatch);
    }

    return () => {
      eventBus.remove(eventBusValues.openRequestMeetingIframe);
    };
  }, []);

  function getAllowedSkills() {
    if (!isSM && cardContentRef && cardContentRef.current) {
      if (cardContentRef.current.offsetWidth > cardWidth) {
        setCardWidth(cardContentRef.current.offsetWidth);
      }
      let pixels = 150;
      let count = 0;
      for (let index = 0; index < favoriteSkills.length; index++) {
        const wordLen = 68 + favoriteSkills[index].label.length * 7.3;
        pixels += wordLen;
        if (pixels < (cardWidth || cardContentRef.current.offsetWidth)) {
          count++;
        } else {
          break;
        }
      }
      return count;
    }
    let pixels = 150;
    let count = 0;
    for (let index = 0; index < favoriteSkills.length; index++) {
      const wordLen = 68 + favoriteSkills[index].label.length * 7.3;
      pixels += wordLen;
      if (pixels < 700) {
        count++;
      } else {
        break;
      }
    }
    return count;
  }
  const allowedItems = getAllowedSkills();
  function getAdvisorBiography(biography) {
    if (!biography || biography.length <= 500) {
      return biography;
    }
    return `${biography.slice(0, 500)}...`;
  }

  function getHighlight(param) {
    if (advisor.highlights && advisor.highlights[param]) {
      const boldWords = extractBoldWords(advisor.highlights[param]);
      let newLabel = advisor[param] ? advisor[param] : '';
      boldWords.forEach((word) => {
        const regex = new RegExp(`\\b${word}\\b`, 'gi');
        newLabel = newLabel.replace(
          regex,
          `<span style="background-color: ${highlightColor}; color: #232B35;">${word}</span>`
        );
      });
      return newLabel;
    }
    return advisor[param];
  }

  function getHeadlineMaxWidth() {
    if (isSM && topMatch) return '100%';
    if (isMD) return '80%';
    if (isLG) return '84%';
    return '95%';
  }

  function renderOpportunityButton(width) {
    let button = (
      <ActionsButton
        style={{
          height: 45,
          marginTop: isSM ? '15px' : '2px',
          background: '#3171F6',
          borderRadius: '25px',
          fontFamily: 'Poppins',
          fontWeight: 700,
          fontSize: 16,
          lineHeight: '24px',
          letterSpacing: '-0.43px',
        }}
        isAction={true}
        buttonTitles={getActionDropdownTitles(showBottom)}
        color="primary"
        size="small"
        width={width}
        label="Connect"
        onClick={(event) => {
          if (requestMeetingClick) {
            requestMeetingClick();
          } else {
            setOpenMeetIFrame(true);
          }
          event.stopPropagation();
        }}
      />
    );

    return button;
  }

  function starSelector() {
    if (isStarFilled === null)
      return (
        <CustomIcon
          iconname="star"
          style={{ fontSize: '20px', color: '#F0F0F0' }}
        />
      );
    if (isStarFilled) {
      return (
        <CustomIcon
          iconname="star"
          style={{ fontSize: '20px', color: '#FFC400' }}
        />
      );
    } else {
      return (
        <CustomIcon
          iconname="star-outlined"
          style={{ fontSize: '20px', color: '#C9C9DA' }}
        />
      );
    }
  }

  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: '#ffffff',
      '&:before': {
        border: '1px solid #E6EDFF',
        boxShadow: '0px 1px 10px 0px #E6EDFE',
      },
    },
    [`& .${tooltipClasses.tooltip}`]: {
      color: '#232B35',
      padding: '10px',
      backgroundColor: '#ffffff',
      borderRadius: '10px',
      border: '1px solid #E6EDFF',
      boxShadow: '0px 1px 10px 0px #E6EDFE',
      fontSize: 12,
    },
  }));

  function AdvisorInfo() {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: 'Poppins',
            fontWeight: 600,
            fontSize: 20,
            lineHeight: '24px',
            letterSpacing: '-0.43px',
            color: '#232B35',
            marginLeft: '10px',
            marginTop: '0px',
            marginBottom: '10px',
          }}
        >
          {advisor.displayName}
        </Typography>
        {accountDetails.companyName !== 'null' &&
          advisor.featuredAdvisor &&
          !topMatch && (
            <CustomTooltip
              title="Featured Advisors have priority search listings and may participate in our featured advisor interviews and podcasts."
              placement={isSM ? 'bottom' : 'right'}
            >
              <img
                src={StarSymbol}
                alt="star-symbol"
                style={{
                  width: '27px',
                  height: '27px',
                  marginLeft: '5px',
                  marginBottom: '10px',
                }}
              />
            </CustomTooltip>
          )}
        {(topMatch && displayTopMatchBadge || isTopMatch) && !isSM ? (
          <StyledChip label="Top Match!" />
        ) : null}
      </Box>
    );
  }

  function renderHeadline() {
    return (
      <Typography
        variant="subtitle1"
        sx={{
          fontFamily: 'Poppins',
          fontWeight: 400,
          fontSize: 16,
          lineHeight: '24px',
          letterSpacing: '-0.25px',
          color: '#646D7A',
          marginLeft: '10px',
          marginBottom: '-15px',
          maxWidth: getHeadlineMaxWidth(),
          paddingBottom: advisor.headline ? '0px' : '20px',
        }}
      >
        <HighlightedText text={getHighlight('headline')} />
      </Typography>
    );
  }

  function renderSkills() {
    if (advisor.highlights && advisor.highlights.skilltags) {
      const boldWords = extractBoldWords(advisor.highlights.skilltags);
      const highlightedArray = wrapBoldWords(
        favoriteSkills,
        boldWords,
        topMatch
      ).sort((a, b) => {
        if (a.isHighlighted === b.isHighlighted) return 0;
        if (a.isHighlighted) return -1;
        if (b.isHighlighted) return 1;
      });
      return highlightedArray;
    }
    return favoriteSkills;
  }

  const extractBoldWords = (htmlString) => {
    const boldWords = new Set();
    const regex = /<b>(.*?)<\/b>/g;
    let match;
    while ((match = regex.exec(htmlString)) !== null) {
      boldWords.add(match[1]);
    }
    return Array.from(boldWords);
  };

  const wrapBoldWords = (array, boldWords, topMatch) => {
    return array.map((item) => {
      let newItem = item;
      let isHighlighted = false;
      boldWords.forEach((word) => {
        const regex = topMatch
          ? new RegExp(`^${word}\\w*`, 'gi')
          : new RegExp(`\\b${word}\\b`, 'gi');
        if (regex.test(newItem.label)) {
          isHighlighted = true;
        }
      });
      return { ...newItem, isHighlighted };
    });
  };

  return (
    <React.Fragment>
      <InviteToJoinConfirmationModal
        isVisible={showInviteToJoinConfirmation}
        advisor={selectedAdvisor}
        onConfirm={() => confirmInviteToJoin()}
        onClose={() => {
          setShowInviteToJoinConfirmation(false);
          sendFSEventOnInvite({ outcome: 'cancelled' });
        }}
        isLoading={inviteModalLoading}
        snackMessage={snackMessage}
        setSnackbarSuccess={setSnackbarSuccess}
        setSnackbarWarning={setSnackbarWarning}
        snackbarSuccess={snackbarSuccess}
        snackbarWarning={snackbarWarning}
        setInvitationNote={setInvitationNote}
        topMatches={topMatch}
      />
      <ACSnackbar
        open={snackbarSuccess}
        text={snackMessage ? t(snackMessage) : ''}
        severity={SnackbarType.SUCCESS}
        onClose={() => setSnackbarSuccess(false)}
        autoHideDuration={6000}
        style={{ marginTop: isFreemium ? '50px' : '0px' }}
      />
      <ACSnackbar
        open={snackbarWarning}
        text={snackMessage ? t(snackMessage) : ''}
        severity={SnackbarType.WARNING}
        onClose={() => setSnackbarWarning(false)}
        autoHideDuration={6000}
        style={{ marginTop: isFreemium ? '50px' : '0px' }}
      />
      <Dialog
        open={openMeetIFrame}
        fullScreen={isSM}
        maxWidth="md"
        height="md"
        onClose={() => setOpenMeetIFrame(false)}
      >
        <Box
          sx={{
            position: 'relative',
            paddingBottom: '50%',
            height: 550,
            width: 800,
            overflow: 'hidden',
          }}
        >
          <iframe
            src={meetUrl}
            title="Meet"
            frameBorder="0"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              overflowX: 'visible',
            }}
          />
        </Box>
      </Dialog>
      <IframeEmbedModal
        openModal={openIframe}
        setOpenModal={setOpenIframe}
        iframeEmbedLink={iFrameSource}
      />
      <Card
        className="advisorCard"
        elevation={0}
        sx={{
          cursor: 'pointer',
          borderRadius: '16px',
          border: '1px solid #E6EDFF',
          display: isSM ? 'flex' : '',
          marginBottom: '30px',
          justifyContent: isSM ? 'center' : '',
          width: '100%',
          '&:hover': {
            boxShadow: '0px 1px 10px 0px #E6EDFE',
          },
        }}
      >
        <CardContent
          onClick={cardClick}
          sx={{ width: '100%', padding: '16px 26px' }}
        >
          <Box
            sx={{ width: '100%' }}
            spacing={isSM ? '' : 2}
            display="flex"
            flexDirection="column"
            ref={cardContentRef}
          >
            <Box display="flex" flexDirection={isSM ? 'column' : 'row'}>
              <Box
                sx={{
                  padding: isSM
                    ? topMatch
                      ? '20px 10px'
                      : '20px'
                    : '20px 20px 20px 0px',
                  display: isSM ? 'flex' : '',
                  justifyContent: isSM ? 'space-between' : '',
                }}
              >
                {type === AccountType.COMPANY ? (
                  <IconButton
                    className="cardSavedAdvisor"
                    onClick={(event) => {
                      if (event.stopPropagation) {
                        event.stopPropagation();
                      }
                      starClicked();
                    }}
                    sx={{
                      height: '35px',
                      width: '35px',
                      left: isSM ? '-29px' : '-10px',
                      top: isSM ? '-34px' : '-18px',
                      position: 'relative',
                    }}
                  >
                    {starSelector()}
                  </IconButton>
                ) : (
                  <div
                    style={{
                      height: '30px',
                      width: '35px',
                      left: isSM ? '-29px' : '-10px',
                      top: isSM ? '-34px' : '-18px',
                      position: 'relative',
                    }}
                  ></div>
                )}
                {advisor.featuredAdvisor ? (
                  type === 'advisor' && isFreemium ? (
                    <Avatar
                      src={advisorAvatar}
                      sx={{
                        width: 130,
                        height: 130,
                        marginTop: isSM ? '' : '-30px',
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        display: 'inline-block',
                        borderRadius: '50%',
                        width: 138,
                        height: 138,
                        background:
                          'linear-gradient(180deg, #4DF4FF 0%, rgba(38, 83, 242, 0.69) 43.75%, rgba(61, 19, 182, 0.63) 80.21%, #6736FA 100%)',
                        padding: '4px',
                        marginTop: isSM ? '' : '-30px',
                      }}
                    >
                      <Avatar
                        src={advisorAvatar}
                        sx={{
                          width: 130,
                          height: 130,
                        }}
                      />
                    </div>
                  )
                ) : (
                  <Avatar
                    src={advisorAvatar}
                    sx={{
                      width: 130,
                      height: 130,
                      marginTop: isSM ? '' : '-30px',
                    }}
                  />
                )}
                {isSM && !topMatch && <Box width="35px" />}
                {isSM && topMatchCheckbox && (
                  <Box
                    sx={{
                      height: '35px',
                      width: '35px',
                      right: isSM ? '-29px' : '-10px',
                      top: isSM ? '-34px' : '-18px',
                      position: 'relative',
                    }}
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    {topMatchCheckbox}
                  </Box>
                )}
              </Box>
              <Box display="flex" flexDirection="column" width="100%">
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: isSM ? 'center' : 'flex-end',
                    flexDirection: 'row',
                    marginTop: '16px',
                    alignItems: 'center',
                  }}
                  width="100%"
                >
                  {!isLessThan1150 || isSM ? (
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: 'Poppins',
                        fontWeight: 500,
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: 12,
                        lineHeight: '32px',
                        color: '#232B35',
                        marginRight: isSM ? '' : '30px',
                      }}
                    >
                      {getAffiliation()}
                    </Typography>
                  ) : null}

                  {!isSM && (
                    <div
                      style={{
                        width: 34,
                        height: 34,
                        background: '#F5F7FA',
                        borderRadius: 20,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginRight: '20px',
                      }}
                    >
                      {renderIcon()}
                    </div>
                  )}
                  {!isSM && renderOpportunityButton(146)}
                </Box>
                {isSM && renderOpportunityButton('100%')}
                <div className="cardInfo">
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginTop: isSM ? '15px' : '-30px',
                    }}
                  >
                    <AdvisorInfo />
                    {isSM && !topMatch && (
                      <div
                        style={{
                          width: 34,
                          height: 34,
                          background: '#F5F7FA',
                          borderRadius: 20,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        {renderIcon()}
                      </div>
                    )}
                  </Box>
                  {isLessThan1150 && !isSM && (
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: 'Poppins',
                        fontWeight: 500,
                        fontSize: 12,
                        color: '#232B35',
                        marginLeft: '10px',
                        marginTop: '-5px',
                      }}
                    >
                      {getAffiliation()}
                    </Typography>
                  )}
                  {advisor.headline && renderHeadline()}
                  <div style={{ marginTop: '10px' }}>
                    {renderSkills().map((skill, index) => (
                      <ChipWithRef
                        key={index}
                        label={skill.label}
                        isHighlighted={skill.isHighlighted}
                        allowedItems={allowedItems}
                        chipIndex={index}
                        highlightColor={highlightColor}
                      />
                    ))}
                  </div>
                </div>
              </Box>
            </Box>
          </Box>

          <Typography
            variant="body2"
            sx={{
              fontFamily: 'Poppins',
              fontWeight: 400,
              fontSize: 14,
              lineHeight: '24px',
              letterSpacing: '-0.43px',
              color: '#646D7A',
              mt: 2,
            }}
          >
            <HighlightedText
              text={getAdvisorBiography(getHighlight('biography'))}
            />
            {advisor.explainerSearchText && (
              <Box display="flex" alignItems="center" mt={2}>
                <CircleIcon sx={{ color: highlightColor, mr: 1 }} />
                <Typography sx={{ color: '#232B35', fontSize: '12px' }}>
                  <HighlightedText text={advisor.explainerSearchText} />
                </Typography>
              </Box>
            )}
          </Typography>
          {showBottom && (
            <PostCardBottom
              size={isSM ? 'xs' : 'lg'}
              iconSeparation={30}
              // answers
              answers={answers}
              answersClick={answersClick}
              answersFilled={answersFilled}
              answersBadge={answersBadge}
              answersBadgeContent={answersBadgeContent}
              answersDisabled={answersDisabled}
              // comments
              comments={comments}
              commentsClick={commentsClick}
              commentsFilled={commentsFilled}
              commentsBadge={commentsBadge}
              commentsBadgeContent={commentsBadgeContent}
              commentsDisabled={commentsDisabled}
              buttonsColor="#232B35"
            />
          )}
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default AdvisorDetailCard;
