import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Box, Divider } from '@mui/material';

export default function AnswerSkeleton() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '10px',
        backgroundColor: '#FFFFFF',
        padding: '25px',
        width: '100%',
      }}
    >
      <Skeleton
        animation="wave"
        variant="rectangular"
        height={106}
        sx={{
          backgroundColor: '#F0F4F8',
          marginBottom: '10px',
          borderRadius: '10px',
          width: '100%',
          marginTop: '30px',
        }}
      />

      <Box sx={{ display: 'flex', marginTop: '20px' }}>
        <Skeleton
          animation="wave"
          variant="rounded"
          height={34}
          sx={{
            backgroundColor: '#F5F7FA',
            marginBottom: '10px',
            marginRight: '30px',
            borderRadius: '100px',
            width: '100%',
            maxWidth: '162px',
          }}
        />
        <Skeleton
          animation="wave"
          variant="rounded"
          height={34}
          sx={{
            backgroundColor: '#F5F7FA',
            marginBottom: '30px',
            borderRadius: '100px',
            width: '100%',
            maxWidth: '162px',
          }}
        />
      </Box>
      <Skeleton
        animation="wave"
        variant="rectangular"
        height={65}
        sx={{
          backgroundColor: '#F0F4F8',

          marginBottom: '30px',
          borderRadius: '10px',
          width: '(100% - 30px)',
          marginLeft: '30px',
        }}
      />
      <Skeleton
        animation="wave"
        variant="rounded"
        height={34}
        sx={{
          backgroundColor: '#F5F7FA',
          marginBottom: '10px',
          borderRadius: '100px',
          width: '100%',
          maxWidth: '162px',
          marginLeft: '30px',
        }}
      />
    </Box>
  );
}
