import React, { Fragment, useCallback, useEffect } from 'react';
import { Button as MaterialButton, CircularProgress, Box } from '@mui/material';
import ActionButton from './actionButton';
import LoadingLogoSkeleton from '../SkeletonLoading/loadingLogoSkeleton';

const useStyles = ({ width }) => ({
  button: {
    textTransform: 'capitalize',
    borderRadius: '10px',
    width: width,
  },
  actionButton: {
    textTransform: 'capitalize',
    width: width,
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: width,
  },
  buttonProgress: {
    position: 'absolute',
  },
});

export default function ACButton(props) {
  const coverRatio = 0.9;
  const [parentSize, setParentSize] = React.useState(0);
  const parentRef = React.useRef(null);
  const {
    children,
    color,
    id,
    onMount,
    type,
    variant,
    loading,
    disabled,
    text,
    size,
    style,
    buttonTitles,
    addtopclasses,
  } = props;
  const filteredProps = { ...props };
  const classes = useStyles(props);
  delete filteredProps.onMount;
  const [newSize, setNewSize] = React.useState(() => {
    return changeSize(size);
  });

  if (buttonTitles) {
    var items = [];
    buttonTitles.forEach((prop) => {
      items.push({
        title: prop.title,
        callback: () => {
          prop.click();
        },
      });
    });
  }

  React.useEffect(() => {
    setNewSize(changeSize(size));
  }, [size]);

  function isNil(value) {
    return value === undefined;
  }

  function changeSize(size) {
    if (isNil(size) && variant === 'outlined') {
      return 34;
    }
    if (isNil(size) && (variant === 'contained' || variant === 'text')) {
      return 36;
    }
    if (size === 'large' && variant === 'outlined') {
      return 40;
    }
    if (
      size === 'large' &&
      (variant === 'contained' || variant === 'text' || isNil(variant))
    ) {
      return 42;
    }
    if (size === 'medium' && variant === 'outlined') {
      return 34;
    }
    if (
      size === 'medium' &&
      (variant === 'contained' || variant === 'text' || isNil(variant))
    ) {
      return 36;
    }
    if (size === 'small' && variant === 'outlined') {
      return 28;
    }
    if (
      size === 'small' &&
      (variant === 'contained' || variant === 'text' || isNil(variant))
    ) {
      return 30;
    }
  }

  const handleReference = useCallback((ref) => {
    if (onMount) {
      const data = { ref };

      if (id) {
        data.id = id;
      }

      onMount(data);
    }
  });

  function buttonClassname() {
    if (type === 'action') {
      return classes.actionButton;
    }
    return classes.button;
  }

  useEffect(() => {
    const { clientHeight, clientWidth } = parentRef.current;
    setParentSize(Math.min(clientHeight, clientWidth));
  }, [size]);

  return (
    <Fragment>
      <Box sx={classes.wrapper} ref={parentRef}>
        {!buttonTitles ? (
          <MaterialButton
            {...filteredProps}
            ref={handleReference}
            color={color}
            className={`${addtopclasses ? 'viewOpportunityTop' : ''}`}
            // style={buttonClassname()}
            variant={variant || 'contained'}
            disabled={loading === 1 || disabled ? true : null}
            size={size}
            style={{
              ...buttonClassname(),
              height: newSize,
              borderRadius: 18,
              ...style,
              minHeight: '37px',
            }}
          >
            {text}
            {!loading && <>{children}</>}
          </MaterialButton>
        ) : (
          <ActionButton
            color={color}
            itemList={items}
            label={'Actions'}
          ></ActionButton>
        )}
        {loading === 1 ? (
          <div style={classes.buttonProgress}>
            <LoadingLogoSkeleton />
          </div>
        ) : null}
      </Box>
    </Fragment>
  );
}
