import React from 'react';
import { Typography } from '@mui/material';

const useStyles = () => ({
  container: {
    margin: 'auto',
    width: '100%',
  },
  title: {
    color: 'theme.palette.primary.main',
    textAlign: 'center',
    margin: 'auto',
  },
  content: {
    color: 'theme.palette.primary.main',
    textAlign: 'center',
    margin: 'auto',
  },
});

export default function TitleAndContent(props) {
  const { titleText, contentText } = props;
  const classes = useStyles();

  return (
    <section>
      <Typography sx={classes.title} variant="subtitle1">
        {titleText}
      </Typography>
      <Typography sx={classes.content} variant="subtitle2">
        {contentText}
      </Typography>
    </section>
  );
}
