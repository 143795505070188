import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Paper, Modal } from '@mui/material';
import emailImage from '../../images/mail-check-outlined.svg';

const useStyles = () => ({
  modalContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    minWidth: '586px',
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  message: {
    maxWidth: '450px',
  },
  emailImage: {
    marginTop: '20px',
    width: '180px',
  },
});

function RemoveMemberConfirmationModal({ visible, email, onDismiss }) {
  const classes = useStyles();
  const { t } = useTranslation();

  if (!visible) return null;

  return (
    <Modal open={visible} onClose={onDismiss} sx={classes.modalContainer}>
      <Paper sx={classes.container}>
        <Typography variant="h5_old_font" color="primary">
          {t('NEW-COMPANY-MEMBER-MODAL-CARD-TITLE')}
        </Typography>

        <img alt="" src={emailImage} style={classes.emailImage} />

        <Typography variant="h2_old_font" sx={classes.message}>
          {t('NEW-COMPANY-MEMBER-MODAL-CARD-MESSAGE', { email })}
        </Typography>
      </Paper>
    </Modal>
  );
}

export default RemoveMemberConfirmationModal;
