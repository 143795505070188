import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useMediaQuery, Typography, IconButton, Avatar } from '@mui/material';
import { getAdvisor } from '../../../services/Backend/account';
import { getAdvisorImagePath } from '../../../services/utils';
import DefaultAdvisorAvatar from '../../../icons/user-avatar.svg';
import eventBus, { eventBusValues } from '../../../eventBus';
import CustomIcon from '../../../icons/customIcon';
import { useAccount } from '../../../contexts/Account';

const useStyles = () => ({
  container: {
    minWidth: (isSM) => (isSM ? '0px' : '250px'),
    maxWidth: '100%',
    height: '67px',
    cursor: 'pointer',
    borderRight: 'none',
    backgroundColor: '#FBFCFD',
  },
  cardContainer: {
    maxWidth: '100%',
    padding: '0px 10px',
  },
  displayName: {
    whiteSpace: 'pre',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '13px',
    letterSpacing: '-0.43px',
    color: '#232B35',
  },
  position: {
    whiteSpace: 'pre',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '10px',
    letterSpacing: '0.75px',
    color: '#1C2F49',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'end',
    fontWeight: 400,
    marginRight: '20px',
  },
  rowContainer: {
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '100%',
  },
  infoContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    overflow: 'hidden',
    maxWidth: '100%',
  },
});

function AdvisorItem(props) {
  const { advisorId } = props;
  const [staticInfo, setStaticInfo] = useState({
    name: '',
    image: '',
    position: '',
  });
  const [advisorData, setAdvisorData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [noShow, setNoShow] = useState(false);
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { isFreeTrial } = useAccount();
  const classes = useStyles(isSM);
  const history = useHistory();

  function visitProfile() {
    eventBus.dispatch(eventBusValues.triggerAdvisorDrawerAction, {
      ...advisorData,
      boardRoomView: true,
    });
  }

  function formatText(text, size) {
    if (text && text.length > size) {
      text.substring(0, size);
      return `${text.substring(0, size)}...`;
    }
    return text;
  }

  useEffect(() => {
    let isMounted = true;
    function getAdvisorData() {
      getAdvisor(advisorId)
        .then((response) => {
          if (isMounted) {
            setStaticInfo({
              name: response.data.getAdvisor.displayName,
              image: getAdvisorImagePath(response.data.getAdvisor),
              position: response.data.getAdvisor.title || 'Advisor',
            });
            setAdvisorData(response.data.getAdvisor);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setNoShow(true);
        });
    }

    getAdvisorData();

    return () => {
      isMounted = false;
    };
  }, [advisorId]);

  function createConversation() {
    eventBus.dispatch(eventBusValues.closeAdvisorDrawer);
    if (isFreeTrial) {
      eventBus.dispatch(eventBusValues.triggerAdvisorUpgradeAction, {
        gatedAction: false,
      });
      return;
    } else {
      history.push({
        pathname: 'messages',
        search: `?senderId=${advisorId}`,
      });
    }
  }

  if (noShow) return null;
  return (
    <div style={classes.container} role="none" onClick={visitProfile}>
      <div style={classes.rowContainer}>
        <div style={classes.infoContainer}>
          <div style={{ marginLeft: '15px' }}>
            <Avatar
              src={staticInfo.image || DefaultAdvisorAvatar}
              style={{ width: 48, height: 48 }}
            />
          </div>
          <div style={classes.cardContainer}>
            <Typography sx={classes.displayName}>
              {isLoading ? 'Loading...' : formatText(staticInfo.name, 15)}
            </Typography>
            <Typography sx={classes.position}>
              {formatText(staticInfo.position, 20)}
            </Typography>
          </div>
        </div>
        <div style={classes.iconContainer}>
          <IconButton
            style={{ backgroundColor: '#F5F7FA' }}
            onClick={(event) => {
              event.stopPropagation();
              createConversation();
            }}
          >
            <CustomIcon iconname="airplane" style={{ fontSize: 14 }} />
          </IconButton>
        </div>
      </div>
    </div>
  );
}

export default AdvisorItem;
