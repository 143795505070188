import React, { useEffect, useState } from 'react';
import {
  useMediaQuery,
  Typography,
  Box,
  Switch,
  Card,
  CardContent,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#3171F6',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const CardPlanCode = ({ selected, onClick, membership, type, price }) => {
  const priceNum = Number(price.recurlyPlanCodePrice);

  const PlanTypeIndicator = ({ membership }) => {
    return (
      <div
        style={{
          position: 'absolute',
          top:
            membership === 'essentials'
              ? -25
              : membership === 'professional'
              ? 230
              : 70,
          right:
            membership === 'essentials'
              ? -40
              : membership === 'professional'
              ? -40
              : -60,
          display: 'flex',
          alignItems: 'center',
          zIndex: -1,
        }}
      >
        <div
          style={{
            width: '114px',
            height: '114px',
            borderRadius: '50%',
            backgroundColor:
              membership === 'essentials'
                ? '#D7F1FD'
                : membership === 'professional'
                ? '#DBF7EB'
                : '#FFEED6',
          }}
        />
      </div>
    );
  };

  return (
    <Card
      onClick={onClick}
      sx={{
        width: '220px',
        height: '320px',
        border: '1px solid',
        borderColor: selected ? '#3171F6' : '#C7D0DE',
        borderRadius: '15px',
        cursor: 'pointer',
        position: 'relative',
        zIndex: 1,
        boxShadow: selected ? '0 0 0 2px #3171F6' : 'none',
      }}
    >
      <CardContent>
        <Typography
          sx={{
            color: '#646D7A',
            fontSize: '12px',
            fontWeight: 700,
            lineHeight: '18px',
            letterSpacing: '-0.43px',
            marginBottom: '5px',
          }}
        >
          {membership === 'essentials' && 'Essentials Membership'}
          {membership === 'professional' && 'Professional Membership'}
          {membership === 'executive' && 'Executive Membership'}
        </Typography>
        {type === 'month' && (
          <Typography
            sx={{
              color: '#232B35',
              fontSize: '10px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '24px',
              letterSpacing: '-0.43px',
            }}
          >
            {membership === 'essentials' && (
              <>
                <span style={{ fontSize: '24px', fontWeight: 800 }}>
                  ${priceNum}
                </span>{' '}
                / month
              </>
            )}
            {membership === 'professional' && (
              <>
                <span style={{ fontSize: '24px', fontWeight: 800 }}>
                  ${priceNum}
                </span>{' '}
                / month
              </>
            )}
            {membership === 'executive' && (
              <>
                <span style={{ fontSize: '24px', fontWeight: 800 }}>
                  ${priceNum}
                </span>{' '}
                / month
              </>
            )}
          </Typography>
        )}
        {type === 'annual' && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              alignContent: 'center',
              justifyContent: 'space-between',
              paddingRight: '10px',
            }}
          >
            <Typography
              sx={{
                color: '#232B35',
                fontSize: '10px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '24px',
                letterSpacing: '-0.43px',
              }}
            >
              {membership === 'essentials' && (
                <>
                  <span style={{ fontSize: '24px', fontWeight: 800 }}>
                    ${Math.ceil(priceNum / 12)}
                  </span>{' '}
                  / month
                </>
              )}
              {membership === 'professional' && (
                <>
                  <span style={{ fontSize: '24px', fontWeight: 800 }}>
                    ${Math.ceil(priceNum / 12)}
                  </span>{' '}
                  / month
                </>
              )}
              {membership === 'executive' && (
                <>
                  <span style={{ fontSize: '24px', fontWeight: 800 }}>
                    ${Math.ceil(priceNum / 12)}
                  </span>{' '}
                  / month
                </>
              )}
            </Typography>
            <Typography
              sx={{
                color: '#3171F6',
                fontSize: '8px',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: '12px',
                letterSpacing: '-0.43px',
                marginTop: '5px',
              }}
            >
              {membership === 'essentials' && `Billed $${priceNum} / year`}
              {membership === 'professional' && `Billed $${priceNum} / year`}
              {membership === 'executive' && `Billed $${priceNum} / year`}
            </Typography>
          </Box>
        )}
        <Typography
          sx={{
            color: '#232B35',
            fontSize: '10px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '15px',
            letterSpacing: '-0.43px',
            marginBottom: '20px',
          }}
        >
          {membership === 'essentials' && 'Join another advisory board'}
          {membership === 'professional' && 'Expand your reach as an advisor'}
          {membership === 'executive' &&
            'Turn experience into income potential'}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            sx={{
              color: '#232B35',
              fontSize: '10px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '24px',
              letterSpacing: '-0.43px',
            }}
          >
            {membership === 'essentials' && (
              <>
                ✓ Join <span style={{ fontWeight: 700 }}>1</span>{' '}
                additional advisory board
              </>
            )}
            {membership === 'professional' && (
              <>
                ✓ Placement on <span style={{ fontWeight: 700 }}>3</span>{' '}
                advisory boards
              </>
            )}
            {membership === 'executive' && (
              <>
                ✓ <span style={{ fontWeight: 700 }}>Unlimited </span>access to
                advisory boards
              </>
            )}
          </Typography>
          <Typography
            sx={{
              color: '#232B35',
              fontSize: '10px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '24px',
              letterSpacing: '-0.43px',
            }}
          >
            ✓ Attend quarterly board meetings
          </Typography>
          {type === 'month' && (
            <Typography
              sx={{
                color: '#232B35',
                fontSize: '10px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '24px',
                letterSpacing: '-0.43px',
              }}
            >
              ✓ Listing in our advisor network
            </Typography>
          )}
          {type === 'annual' && (
            <Typography
              sx={{
                color: '#232B35',
                fontSize: '10px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '24px',
                letterSpacing: '-0.43px',
              }}
            >
              ✓ <span style={{ fontWeight: 700 }}>Featured</span> in our advisor
              network
            </Typography>
          )}
          <Typography
            sx={{
              color: '#232B35',
              fontSize: '10px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '24px',
              letterSpacing: '-0.43px',
            }}
          >
            ✓ Exclusive networking opportunities
          </Typography>
          <Typography
            sx={{
              color: '#232B35',
              fontSize: '10px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '24px',
              letterSpacing: '-0.43px',
            }}
          >
            ✓ Receive advisory role inquiries
          </Typography>
          {membership === 'professional' && (
            <Typography
              sx={{
                color: '#232B35',
                fontSize: '10px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '24px',
                letterSpacing: '-0.43px',
              }}
            >
              ✓ Professional brand promotion tools
            </Typography>
          )}
          {membership === 'executive' && (
            <Typography
              sx={{
                color: '#232B35',
                fontSize: '10px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '24px',
                letterSpacing: '-0.43px',
              }}
            >
              ✓ Professional brand promotion tools
            </Typography>
          )}
          {membership === 'executive' && (
            <Typography
              sx={{
                color: '#232B35',
                fontSize: '10px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '24px',
                letterSpacing: '-0.43px',
              }}
            >
              ✓ Custom hourly rate for paid inquiries
            </Typography>
          )}
          <Typography
            sx={{
              color: '#232B35',
              fontSize: '10px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '24px',
              letterSpacing: '-0.43px',
            }}
          >
            ✓ 1-1 profile optimization call
          </Typography>
        </Box>
      </CardContent>
      <PlanTypeIndicator membership={membership} />
    </Card>
  );
};

export default function Step1({
  onContinue,
  onOptionChange,
  month1,
  month2,
  month3,
  annual1,
  annual2,
  annual3,
}) {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const [selectedPlan, setSelectedPlan] = useState('month');
  const [selectedOption, setSelectedOption] = useState(2);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const handleSelectOption = (option) => {
    setSelectedOption(option);
    let selectedData;
    let counterpartData;

    if (selectedPlan === 'month') {
      selectedData = option === 1 ? month1 : option === 2 ? month2 : month3;
      counterpartData =
        option === 1 ? annual1 : option === 2 ? annual2 : annual3;
    } else {
      selectedData = option === 1 ? annual1 : option === 2 ? annual2 : annual3;
      counterpartData = option === 1 ? month1 : option === 2 ? month2 : month3;
    }

    onOptionChange(selectedPlan, option, selectedData, counterpartData);
  };

  useEffect(() => {
    let selectedData = selectedPlan === 'month' ? month2 : annual2;
    let counterpartData = selectedPlan === 'month' ? annual2 : month2;
    onOptionChange(selectedPlan, selectedOption, selectedData, counterpartData);
  }, [selectedPlan]);

  useEffect(() => {
    if (month1 && month2 && month3 && annual1 && annual2 && annual3) {
      setIsDataLoaded(true);
    }
  }, [month1, month2, month3, annual1, annual2, annual3]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: { xxs: '20px', sm: '0px' },
      }}
    >
      <Typography
        sx={{
          color: '#232B35',
          textAlign: 'center',
          fontSize: '20px',
          fontStyle: 'normal',
          fontWeight: 800,
          lineHeight: '24px',
          letterSpacing: '-0.43px',
          marginTop: '50px',
          marginBottom: '0px',
        }}
      >
        Upgrade and unlock your full potential as an advisor
      </Typography>
      <Typography
        sx={{
          color: '#232B35',
          textAlign: 'center',
          fontSize: '14x',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '24px',
          letterSpacing: '-0.43px',
          marginBottom: '10px',
        }}
      >
        Start your paid membership today to get access to all features and
        benefits
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 1,
          marginBottom: '20px',
        }}
      >
        <Typography
          sx={{
            color: '#3171F6',
            fontSize: '12x',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '24px',
            letterSpacing: '-0.43px',
          }}
        >
          Go annual and save
        </Typography>
        <AntSwitch
          checked={selectedPlan === 'month' ? false : true}
          onChange={() => {
            setSelectedPlan(selectedPlan == 'month' ? 'annual' : 'month');
            setSelectedOption(2);
          }}
          inputProps={{ 'aria-label': 'ant design' }}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xxs: 'column', xs: 'row' },
          justifyContent: { xxs: 'center', sm: 'space-between' },
          flexWrap: 'wrap',
          marginBottom: '30px',
          gap: 5,
        }}
      >
        {selectedPlan === 'month' ? (
          <>
            <CardPlanCode
              selected={selectedOption === 1}
              onClick={() => handleSelectOption(1)}
              membership="essentials"
              type={selectedPlan}
              price={month1}
            />
            <CardPlanCode
              selected={selectedOption === 2}
              onClick={() => handleSelectOption(2)}
              membership="professional"
              type={selectedPlan}
              price={month2}
            />
            <CardPlanCode
              selected={selectedOption === 3}
              onClick={() => handleSelectOption(3)}
              membership="executive"
              type={selectedPlan}
              price={month3}
            />
          </>
        ) : (
          <>
            <CardPlanCode
              selected={selectedOption === 1}
              onClick={() => handleSelectOption(1)}
              membership="essentials"
              type={selectedPlan}
              price={annual1}
            />
            <CardPlanCode
              selected={selectedOption === 2}
              onClick={() => handleSelectOption(2)}
              membership="professional"
              type={selectedPlan}
              price={annual2}
            />
            <CardPlanCode
              selected={selectedOption === 3}
              onClick={() => handleSelectOption(3)}
              membership="executive"
              type={selectedPlan}
              price={annual3}
            />
          </>
        )}
      </Box>

      <button
        disabled={!isDataLoaded}
        style={{
          marginBottom: '20px',
          position: 'relative',
          fontSize: '15px',
          borderRadius: '999px',
          fontWeight: 700,
          width: '168px',
          height: '44px',
          backgroundColor: isDataLoaded ? '#3171F6' : '#bdbdbd',
          color: isDataLoaded ? '#FFFFFF' : '#666666',
          borderColor: 'none',
          fontFamily: 'Poppins',
          border: 'none',
          outline: 'none',
          cursor: 'pointer',
        }}
        onClick={onContinue}
        variant="contained"
      >
        Continue
      </button>
    </Box>
  );
}
