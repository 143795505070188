import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useMediaQuery, Paper, Typography, Box } from '@mui/material';
import { getSinglePostCall } from '../../services/company_services';
import { AccountType, PostType } from '../../services/utils/types';
import { useAccount } from '../../contexts/Account';
import PostPopUp from '../../components/Post/PopUp';
import ACSnackbar from '../../components/ACSnackbar';
import PostCardHeader from '../../components/Post/Card/PostCardHeader';
import EndpointCaller from '../../components/EndpointCaller';
import { isNil } from '../../services/utils';

const useStyles = (isSM) => ({
  screenContainer: {
    paddingLeft: isSM ? '10px' : '30px',
    paddingTop: '30px',
    marginRight: isSM ? '0px' : '20%',
    paddingRight: isSM ? '10px' : '30px',
    display: 'flex',
    flexDirection: 'column',
    flex: 'auto',
  },
  postEditingContainer: {
    padding: '10px',
  },
});

function IndividualPostEdit(props) {
  const [editingPost, setEditingPost] = useState();
  const [comingFrom, setComingFrom] = useState('');
  const [loading, setLoading] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [postTypeMismatch, setPostTypeMismatch] = useState(false);
  const { postType } = props;
  const location = useLocation();
  const { accountDetails, asLoggedUserDetails } = useAccount();
  const params = useParams('/:postType/:postId/edit');
  const { postId } = params;
  const history = useHistory();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);

  useEffect(() => {
    if (location.state && location.state.editingPost) {
      setEditingPost(location.state.editingPost);

      if (location.state.comingFrom) {
        setComingFrom(location.state.comingFrom);
      }
    } else {
      setLoading(true);
    }
  }, []);

  const endpointCalls = [
    {
      endPointCall: getSinglePostCall,
      endPointCallParameters: {
        USER_ID: asLoggedUserDetails.userId,
        ACCOUNT_TYPE: AccountType.CONTACT,
        POST_ID: postId,
      },
    },
  ];

  function getEndpointResponseArray(array) {
    setLoading(false);

    if (array.length < 1 || !array[0]) {
      setShowErrorSnackbar(true);
      return;
    }

    const post = array[0].data.getPost;

    if (post.type !== postType) {
      setShowErrorSnackbar(true);
      setPostTypeMismatch(true);
    }
    setEditingPost(post);
  }

  function goBack() {
    if (comingFrom) {
      history.push(comingFrom);
    } else {
      history.push('/dashboard');
    }
  }

  function editPostCallback(editedPost) {
    goBack();
  }

  function cancelCallback() {
    goBack();
  }

  function getEditText() {
    switch (postType) {
      case PostType.UPDATE:
        return 'Edit Update';
      case PostType.UPDATES:
        return 'Edit Update';
      case PostType.QUESTION:
        return 'Edit Question';
      case PostType.QUESTIONS:
        return 'Edit Question';
      case PostType.INSIGHT:
        return 'Edit Insight';
      case PostType.MEETING:
        return 'Edit Meeting';
      default:
        return '';
    }
  }

  return (
    <div style={classes.screenContainer}>
      <ACSnackbar
        open={showErrorSnackbar}
        severity="error"
        text="404 Error"
        onClose={() => setShowErrorSnackbar(false)}
      />
      <EndpointCaller
        endPointCallObjectArray={endpointCalls}
        onEndpointCallsFinished={getEndpointResponseArray}
        executeCalls={loading}
      />
      {loading || !editingPost || postTypeMismatch ? null : (
        <Paper sx={classes.postEditingContainer}>
          <PostCardHeader
            noEllipsis
            type={postType}
            postData={editingPost}
            initialTime={editingPost.createdAt}
            companyName={editingPost.companyName}
            companyAvatar={
              accountDetails.companyS3Logo
                ? accountDetails.companyS3Logo.location
                : ''
            }
          />
          <Box mt="25px">
            <Typography variant="h4">{getEditText()}</Typography>
          </Box>
          <PostPopUp
            type={postType}
            editMode
            editingPost={editingPost}
            callbackAfterEdit={editPostCallback}
            callbackAfterCancel={cancelCallback}
          />
        </Paper>
      )}
    </div>
  );
}

export default IndividualPostEdit;
