import React from 'react';
import {
  Autocomplete,
  InputLabel,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';

const useStyles = () => ({
  inputLabel: {
    marginBottom: '5px',
    color: 'black',
  },
  inputContainer: {
    width: '100%',
  },
  input: {
    width: '100%',
  },
  helperText: {
    color: 'grey.600',
  },
  helperErrorText: {
    color: 'error.main',
  },
  responsivePosition: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  option: {
    backgroundColor: 'white',
    '&:not(:hover)': {
      backgroundColor: 'white',
    },
  },
  icon: {
    color: 'primary.main',
  },
  chip: {
    display: 'none',
  },
});

function Input({ helperText, id, labelBox, options, placeholder, ...rest }) {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles();

  return (
    <div style={classes.inputContainer}>
      {isSM ? (
        <InputLabel sx={classes.inputLabel} htmlFor={id}>
          <Typography variant="caption_old_font">{labelBox}</Typography>
          <Typography variant="caption_old_font">
            {helperText}
          </Typography>
        </InputLabel>
      ) : (
        <InputLabel sx={classes.inputLabel} htmlFor={id}>
          <div style={classes.responsivePosition}>
            <Typography variant="caption_old_font">{labelBox}</Typography>
            <Typography variant="caption_old_font">
              {helperText}
            </Typography>
          </div>
        </InputLabel>
      )}

      <Autocomplete
        {...rest}
        id={id}
        multiple
        options={options}
        classes={{
          option: classes.option,
          popupIndicator: classes.icon,
          tag: classes.chip,
        }}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            sx={classes.input}
            inputProps={{
              ...params.inputProps,
              'data-testid': rest['input-data-testid'],
              'data-cy': rest['input-data-cy'],
            }}
            placeholder={placeholder}
            variant="outlined"
          />
        )}
      />
    </div>
  );
}

export default Input;
