import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMediaQuery, Typography, Button, Link } from '@mui/material';
import { useAccount } from '../../contexts/Account';
import ACSnackbar from '../../components/ACSnackbar';
import LoadingIndicatorLogo from '../../components/LoadingIndicatorLogo';
import LinkedInPostModal from '../../components/LinkedInPostModal';
import eventBus, { eventBusValues } from '../../eventBus';
import {
  isLinkedinAuthenticated,
  authorizeWithPopUp,
  getAccessToken,
  closeChild,
} from '../../services/LinkedIn';
import CustomIcon from '../../icons/customIcon';
import { ReactComponent as PerksImage } from './img/perks.svg';
import { ReactComponent as RolesImage } from './img/formal_roles.svg';
import { ReactComponent as EquityImage } from './img/equity_potential.svg';
import { ReactComponent as CompensationImage } from './img/compensation_potential.svg';
import { getCompanyInfoBoardroomMenu } from '../../services/company_services';

const useStyles = ({ isSM, isXS }) => ({
  screenContainer: {
    width: '100%',
    paddingLeft: isSM ? '0px' : '50px',
    paddingTop: '30px',
    paddingRight: isSM ? '0px' : '30px',
    display: 'flex',
    flexDirection: 'column',
    flex: 'auto',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  cardContainer: {
    width: '100%',
  },
  benefitImage: {
    width: '118px',
    height: '96px',
  },
  loadingIndicatorPosition: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '50px',
  },
  benefits: {
    width: '100%',
    height: '100%',
  },
  otherBenefits: {
    marginTop: '15px',
    padding: '0 20px 0 0',
  },
  additionalBenefitsContainer: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '10px',
    width: '100%',
    maxWidth: '1120px',
    padding: '20px',
    border: '1px solid #E6EDFF',
  },
  additionalBenefitsRightContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginRight: '4%',
  },
  additionalBenefitsLeftContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '200%',
  },
  additionalBenefitsCategory: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
  },
  additionalBenefitsTitle: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '24px',
    marginBottom: '10px',
  },
  additionalBenefitsText: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    marginBottom: '10px',
  },
  benefitBoxContainer: {
    width: '1OO%',
    display: 'flex',
    maxWidth: '1140px',
    flexDirection: 'row',
    justifyContent: 'start',
    background: '#FFFFFF',
    borderRadius: '10px',
    padding: isSM ? '20px' : '20px 20px 20px 0',
    overflow: 'hidden',
  },
  benefitLinkBox: {
    width: isXS ? '100%' : '48%',
    height: isXS ? '280px' : '233px',
    background: '#FFFFFF',
    border: '1px solid #E6EDFF',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    background: '#FFFFFF',
    borderRadius: '10px',
    padding: '20px 20px 10px 20px',
    overflow: 'hidden',
    marginBottom: isXS ? '15px' : '',
  },
  benefitLinkBoxContainer: {
    width: '100%',
    display: 'flex',
    maxWidth: '1140px',
    padding: isSM ? '0 20px 0 20px' : '20px 20px 20px 0',
    flexDirection: isXS ? 'column' : 'row',
    justifyContent: 'space-between',
  },
  textBenefitColumnContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'space-between',
  },
  textBenefitHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  textBenefitsContainer: {
    marginTop: '10px',
    width: '100%',
    marginRight: isSM ? '5px' : '',
  },
  benefitHeader: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '18px',
    marginBottom: '10px',
  },
  benefitText: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '24px',
    marginBottom: '10px',
  },
  linkText: {
    cursor: 'pointer',
    color: '#0F78FD',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '12px',
    textTransform: 'none',
    border: 'none',
    paddingLeft: '0px',
  },
  metrics: {
    display: 'flex',
    flexDirection: isXS ? 'column' : 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  largeMetricContainer: {
    display: 'flex',
    width: isXS ? '100%' : '40%',
    height: '180px',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: '10px',
  },
  metricContainer: {
    display: 'flex',
    width: isXS ? '100%' : '28%',
    height: '180px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10px',
    marginBottom: isXS ? '15px' : '',
  },
  blue: {
    backgroundColor: '#D7F1FD',
  },
  green: {
    backgroundColor: '#DBF7EB',
  },
  gray: {
    backgroundColor: '#F5F7FA',
  },
  largeMetricNumber: {
    textAlign: 'left',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '18px',
    paddingRight: '20px',
  },
  metricNumber: {
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '32px',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  largeHeaderMetricContainer: {
    display: 'flex',
    marginLeft: '20px',
    alignItems: 'center',
    height: '30%',
  },
  bodyMetricContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '40%',
  },
  metricText: {
    fontStyle: 'normal',
    margin: '0 20px 0 20px',
    fontWeight: '500',
    textAlign: 'center',
    fontSize: '15px',
  },
  largeMetricText: {
    fontStyle: 'normal',
    margin: '0 20px 0 20px',
    fontWeight: '500',
    textAlign: 'left',
    lineHeight: '23px',
    fontSize: '15px',
  },
  boardMemberBenefitsContainer: {
    padding: isSM ? '20px' : '20px 20px 20px 0',
    maxWidth: '1140px',
  },
});

function Benefits() {
  const { t } = useTranslation();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const classes = useStyles({ isSM, isXS });
  const [companyBoardData, setCompanyBoardData] = useState();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [loadingPosts, setLoadingPosts] = useState(true);
  const [openLinkedInPostModal, setOpenLinkedInPostModal] = useState(false);
  const [successSnackbar, setSuccessSnackbar] = useState(false);
  const [errorSnackbar, setErrorSnackbar] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const categoryOptions = [
    {
      text: 'Compensation Potential',
      description: 'Compensated opportunities for advisors',
      icon: 'benefits-compensation',
      image: <CompensationImage style={classes.benefitImage} />,
    },
    {
      text: 'Equity Potential',
      description: 'Equity opportunities for advisors',
      icon: 'benefits-equity',
      image: <EquityImage style={classes.benefitImage} />,
    },
    {
      text: 'Formal Roles',
      description: 'Formal role opportunities for advisors',
      icon: 'benefits-roles',
      image: <RolesImage style={classes.benefitImage} />,
    },
    {
      text: 'Perks',
      description: 'Perks and discounts for advisors',
      icon: 'benefit-perk',
      image: <PerksImage style={classes.benefitImage} />,
    },
  ];

  const [loading, setLoading] = useState(true);
  const { asLoggedUserDetails, isFreeTrial } = useAccount();

  const params = useParams('/:companySlug/updates');
  const { companySlug } = params;

  const selectedCompany =
    asLoggedUserDetails.advisorRelatedCompanies.memberBoards.find(
      (element) => element.slug === companySlug
    );

  async function getCompanyData() {
    try {
      const result = await getCompanyInfoBoardroomMenu({
        companyId: selectedCompany.id,
      });
      setLoading(false);
      setCompanyBoardData(result.data.getCompany);
    } catch (err) {
      console.log(err);
      setShowSnackbar(true);
      setLoadingPosts(false);
    }
  }
  function getDescriptionByText(text) {
    const option = categoryOptions.find((option) => option.text === text);
    return option ? option.description : '';
  }
  function getImageByText(text) {
    const option = categoryOptions.find((option) => option.text === text);
    return option ? option.image : '';
  }

  async function linkedInSign() {
    if (isFreeTrial) {
      eventBus.dispatch(eventBusValues.triggerAdvisorUpgradeAction, {
        gatedAction: true,
        companyName: selectedCompany.companyName,
      });
    } else {
      const isAuthenticatedLinkedIn = isLinkedinAuthenticated();
      if (isAuthenticatedLinkedIn) {
        setOpenLinkedInPostModal(true);
      } else {
        await authorizeWithPopUp();
      }
    }
  }
  async function clickedAction() {
    if (isFreeTrial) {
      eventBus.dispatch(eventBusValues.triggerAdvisorUpgradeAction, {
        gatedAction: true,
        companyName: selectedCompany.companyName,
      });
    } else {
      console.log('');
    }
  }

  function formatBody(text) {
    const textArray = text.split(' ');
    const elementsArray = [];
    let wordBucket = [];

    textArray.forEach((word, index) => {
      if (
        word.startsWith('https://') ||
        word.startsWith('http://') ||
        word.startsWith('www.') ||
        word.includes(`\n`)
      ) {
        elementsArray.push(
          <span key={`${index}s1`}>{wordBucket.join(' ')}</span>
        );
        elementsArray.push(<span key={`${index}s2`}> </span>);
        if (!word.includes(`\n`)) {
          elementsArray.push(
            <Link key={`${index}l1`} href={word} target="_blank">
              {word}
            </Link>
          );
          elementsArray.push(<span key={`${index}s3`}> </span>);
        } else {
          let separatedWord = word.split(`\n`);
          separatedWord.forEach((piece, brIndex) => {
            if (
              piece.startsWith('https://') ||
              piece.startsWith('http://') ||
              piece.startsWith('www.')
            ) {
              elementsArray.push(
                <Link
                  key={`${brIndex}link${index}`}
                  href={piece}
                  target="_blank"
                >
                  {piece}
                </Link>
              );
            } else if (piece !== '') {
              elementsArray.push(
                <span key={`${index}piece${brIndex}`}>{piece}</span>
              );
            }
            if (brIndex !== separatedWord.length - 1) {
              elementsArray.push(<br key={`${brIndex}br${index}`} />);
            } else {
              elementsArray.push(<span key={`${index}sp${brIndex}`}> </span>);
            }
          });
        }

        wordBucket = [];
      } else {
        wordBucket.push(word);
      }
    });

    elementsArray.push(<span key="finalItem">{wordBucket.join(' ')}</span>);
    return (
      <React.Fragment>{elementsArray.map((element) => element)}</React.Fragment>
    );
  }

  function getIconByText(text) {
    const option = categoryOptions.find((option) => option.text === text);
    return option ? option.icon : '';
  }

  function orderBenefits() {
    let benefitsOrdered = companyBoardData.benefits.sort(function (a, b) {
      if (a.category < b.category) {
        return -1;
      }
      if (a.category > b.category) {
        return 1;
      }
      return 0;
    });
  }

  async function linkedInResponse(linkedinResponse) {
    if (linkedinResponse === 201) {
      setSuccessMessage('Your profile is shared successfully.');
      setSuccessSnackbar(true);
    } else if (linkedinResponse === 422) {
      setErrorMessage(
        'You have already posted this content, either update the post or try again in 10 minutes'
      );
      setErrorSnackbar(true);
    } else {
      setErrorMessage('An error occurred while sharing your profile.');
      setErrorSnackbar(true);
    }
  }

  useEffect(() => {
    if (companyBoardData) {
      setLoadingPosts(false);
    }
  }, [companyBoardData]);

  useEffect(() => {
    getCompanyData();
  }, [companySlug]);

  useEffect(() => {
    window.linkedinWait = true;

    const interval = setInterval(() => {
      if (window.linkedinSuccess) {
        clearInterval(interval);
        closeChild();
        getAccessToken(window.linkedinSuccess);
        setOpenLinkedInPostModal(true);
      }
    }, 600);

    if (isLinkedinAuthenticated()) {
      window.linkedinWait = undefined;
      clearInterval(interval);
    }
    return () => {
      window.linkedinWait = undefined;
      clearInterval(interval);
    };
  }, []);

  if (loading) {
    return (
      <div style={classes.screenContainer}>
        <div style={classes.loadingIndicatorPosition}>
          <LoadingIndicatorLogo size={200} iconFontSize={93} iconRight={105} />
        </div>
      </div>
    );
  }

  return (
    <div style={classes.screenContainer}>
      <Helmet>
        <title>{t('ADVISOR-BOARDROOMS-BENEFITS-HELMET-TITLE')}</title>
      </Helmet>
      <ACSnackbar
        open={showSnackbar}
        text={t('ADVISOR-BOARDROOM-LEFT-MENU-ERROR')}
        severity="error"
        onClose={() => setShowSnackbar(false)}
        autoHideDuration={6000}
      />
      <div style={classes.benefits}>
        {loadingPosts ? (
          <div style={classes.loadingIndicatorPosition}>
            <LoadingIndicatorLogo iconFontSize={15} iconRight={13} />
          </div>
        ) : (
          <div style={classes.cardContainer}>
            <div
              id="boardRoi"
              style={{
                ...classes.benefitBoxContainer,
                borderRadius: '10px',
              }}
            >
              <div style={classes.textBenefitsContainer}>
                <Typography
                  style={{
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '20px',
                    color: '#232B35',
                    marginBottom: '10px',
                  }}
                >
                  What's the ROI of board work?
                </Typography>
                <Typography
                  sx={classes.benefitText}
                  style={{ marginBottom: '25px' }}
                >
                  According to the{' '}
                  <u
                    onClick={() => {
                      window.open(
                        'https://hbr.org/2016/05/serving-on-boards-helps-executives-get-promoted',
                        '_blank'
                      );
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    Harvard Business Review
                  </u>{' '}
                  :
                </Typography>
                <div id="metricsContainer" style={classes.metrics}>
                  <div
                    id="averageIncrease"
                    style={{ ...classes.metricContainer, ...classes.blue }}
                  >
                    <div>
                      <Typography sx={classes.metricNumber}>13%</Typography>
                    </div>
                    <div style={classes.bodyMetricContainer}>
                      <Typography sx={classes.metricText}>
                        Average increase in annual pay for executives after
                        serving on a board.
                      </Typography>
                    </div>
                  </div>
                  <div
                    id="promotionPercentage"
                    style={{ ...classes.metricContainer, ...classes.green }}
                  >
                    <div>
                      <Typography sx={classes.metricNumber}>44%</Typography>
                    </div>
                    <div style={classes.bodyMetricContainer}>
                      <Typography sx={classes.metricText}>
                        Greater likelihood of being promoted after adding a
                        board role to your resume.
                      </Typography>
                    </div>
                  </div>
                  <div
                    id="booostMetric"
                    style={{ ...classes.largeMetricContainer, ...classes.gray }}
                  >
                    <div style={classes.largeHeaderMetricContainer}>
                      <Typography sx={classes.largeMetricNumber}>
                        Instantly boost your career
                      </Typography>
                    </div>
                    <div style={classes.bodyMetricContainer}>
                      <Typography sx={classes.largeMetricText}>
                        Adding a board role to your resume is the #1 way to
                        boost your career
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="boardMemberBenefitsContainer"
              style={classes.boardMemberBenefitsContainer}
            >
              <Typography
                style={{
                  fontStyle: 'normal',
                  fontWeight: '600',
                  fontSize: '20px',
                }}
                id="boardMemberBenefitsTitle"
              >
                Board Member Benefits
              </Typography>
              <Typography
                style={{
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fontSize: '14px',
                  marginTop: '10px',
                }}
              >
                Experience the benefits of being an advisory board member
              </Typography>
            </div>
            <div style={classes.benefitLinkBoxContainer}>
              <div style={classes.benefitLinkBox} id="professionalMaterials">
                <div style={classes.textBenefitColumnContainer}>
                  <div style={classes.textBenefitHeaderContainer}>
                    <Typography sx={classes.benefitHeader}>
                      Add this board role to your professional materials
                    </Typography>
                    <img
                      src="https://cdn.advisorycloud.com/images/benefits_professional_materials.svg"
                      alt="professional_materials"
                      style={{ width: 73, height: 56 }}
                    />
                  </div>
                  <Typography sx={classes.benefitText}>
                    One major benefit of becoming a board member is being able
                    to add it to your professional materials, like your resume
                    and LinkedIn.
                  </Typography>
                  <div id="professionalMaterialsLink">
                    <Button
                      sx={classes.linkText}
                      onClick={clickedAction}
                      id="add_role_linkedin"
                    >
                      Click here to see how to add this role to your LinkedIn.
                    </Button>
                  </div>
                </div>
              </div>
              <div style={classes.benefitLinkBox} id="shareCompanyAffiliation">
                <div style={classes.textBenefitColumnContainer}>
                  <div style={classes.textBenefitHeaderContainer}>
                    <Typography sx={classes.benefitHeader}>
                      Share your status as an advisor for{' '}
                      {selectedCompany.companyName}
                    </Typography>
                  </div>
                  <Typography sx={classes.benefitText}>
                    Take part in active boardroom discussions and live quarterly
                    board meetings to start connecting board owners and fellow
                    advisors.
                  </Typography>
                  <div>
                    <Button
                      sx={classes.linkText}
                      onClick={linkedInSign}
                      id="shareCompanyAffiliationLink"
                    >
                      Click here to share your status as an advisor on LinkedIn
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            {companyBoardData.benefits &&
              companyBoardData.benefits.length > 0 &&
              companyBoardData.benefits.map((benefit) => (
                <div
                  className="benefitsContainer"
                  style={{ ...classes.otherBenefits }}
                >
                  <div
                    className={`${benefit.category.replace(/ /g, '')}Benefit`}
                    style={{ ...classes.additionalBenefitsContainer }}
                  >
                    <div style={classes.additionalBenefitsLeftContainer}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          marginBottom: 10,
                          alignItems: 'center',
                        }}
                      >
                        <CustomIcon
                          iconname={getIconByText(benefit.category)}
                          color="primary"
                          fontSize="small"
                          style={{
                            color: '#232B35',
                            marginBottom: 10,
                            marginRight: 10,
                            overflow: 'visible',
                          }}
                        />
                        <Typography sx={classes.additionalBenefitsCategory}>
                          {benefit.category}
                        </Typography>
                      </div>
                      <Typography sx={classes.additionalBenefitsTitle}>
                        {getDescriptionByText(benefit.category)}
                      </Typography>
                      <Typography sx={classes.additionalBenefitsText}>
                        {formatBody(benefit.details)}
                      </Typography>
                    </div>
                    <div style={classes.additionalBenefitsRightContainer}>
                      {getImageByText(benefit.category)}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
      <ACSnackbar
        open={successSnackbar}
        text={successMessage}
        severity="success"
        onClose={() => setSuccessSnackbar(false)}
        autoHideDuration={6000}
      />
      <ACSnackbar
        open={errorSnackbar}
        text={errorMessage}
        severity="error"
        onClose={() => setErrorSnackbar(false)}
        autoHideDuration={6000}
      />
      {openLinkedInPostModal ? (
        <>
          <LinkedInPostModal
            linkedInPostModal={openLinkedInPostModal}
            setLinkedInPostModal={setOpenLinkedInPostModal}
            linkedInResponse={linkedInResponse}
            fontFamily={'Poppins'}
            contents={{
              title: `I've joined ${selectedCompany.companyName}'s advisory board`,
              text: `I'm excited to help ${selectedCompany.companyName} execute on their mission and look forward to contributing as a member of the advisory board. I found the opportunity through AdvisoryCloud.com. If you're interested in learning more, you can find a link to my profile below.`,
            }}
          />
        </>
      ) : null}
    </div>
  );
}

export default Benefits;
