import React, { useState } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  useMediaQuery,
  Select,
  Box,
} from '@mui/material';
import CustomIcon from '../../icons/customIcon';
import Treatment from '../../components/Treatment';
import { featureFlags } from '../../splitSetup';
import { useAccount } from '../../contexts/Account';

const getClasses = (isSM) => ({
  selectTextField: {
    height: '40px',
    fontFamily: 'Poppins',
    fontSize: '14px',
    color: '#74879A',
    fontWeight: 400,
    padding: '5px',
    height: '44px',
    borderRadius: '10px',
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #E6EDFF',
    },
  },
});

const SearchBox = ({
  filterBy: initialFilterBy,
  setFilterBy,
  sortBy,
  setSortBy,
}) => {
  const [currentFilterBy, setCurrentFilterBy] = useState(initialFilterBy);
  // const [currentSortBy, setCurrentSortBy] = useState(initialSortBy);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedButton, setSelectedButton] = useState('viewAll');
  const { currentUserInfo } = useAccount();

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
    if (buttonName === 'viewAll') {
      setFilterBy([]);
    }
    if (buttonName === 'posts') {
      setFilterBy(['question', 'update']);
    }
    if (buttonName === 'insights') {
      setFilterBy(['insight']);
    }
    if (buttonName === 'meetings') {
      setFilterBy(['meeting', 'boardMeetings']);
    }
  };

  const handleFilterChange = (event) => {
    setCurrentFilterBy(event.target.value);
  };

  const handleSortChange = (event) => {
    setSortBy(event.target.value);
  };

  const handleDropdownClose = () => {
    setFilterBy(currentFilterBy);
    // setSortBy(currentSortBy);
    setIsDropdownOpen(false);
  };

  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = getClasses(isSM);

  return (
    <Box
      sx={{
        width: { xxs: '100%', sm: '120%' },
        position: 'sticky',
        top: { xxs: -2, sm: 0 },
        backgroundColor: '#ffffff',
        zIndex: 10,
        paddingTop: { xxs: '20px', sm: '10px' },
        paddingBottom: '10px',
        paddingLeft: isSM ? '10px' : '30px',
        borderBottom: '1px solid #E6EDFF',
      }}
    >
      {!isSM ? (
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            flexDirection: { xxs: 'column', sm: 'row' },
          }}
        >
          <button
            id="viewAll"
            onClick={() => handleButtonClick('viewAll')}
            style={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor:
                selectedButton === 'viewAll' ? '#EFF0F6' : '#ffffff',
              border:
                selectedButton === 'viewAll'
                  ? '1px solid #EFF0F6'
                  : '1px solid #000000',
              borderRadius: '90px',
              color: '#333',
              cursor: 'pointer',
              fontSize: '14px',
              fontWeight: 'bold',
              padding: '8px 18px',
              textAlign: 'center',
              textDecoration: 'none',
            }}
          >
            View All
          </button>
          <button
            id="posts"
            onClick={() => handleButtonClick('posts')}
            style={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor:
                selectedButton === 'posts' ? '#D7F1FD' : '#ffffff',
              border:
                selectedButton === 'posts'
                  ? '1px solid #D7F1FD'
                  : '1px solid #000000',
              borderRadius: '90px',
              color: '#333',
              cursor: 'pointer',
              fontSize: '14px',
              fontWeight: 'bold',
              padding: '8px 18px',
              textAlign: 'center',
              textDecoration: 'none',
            }}
          >
            <CustomIcon
              iconname="post"
              style={{
                fontSize: '16px',
                marginRight: '7px',
                height: '18px',
              }}
            ></CustomIcon>
            Posts
          </button>
          <button
            id="insights"
            onClick={() => handleButtonClick('insights')}
            style={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor:
                selectedButton === 'insights' ? '#DBF7EB' : '#ffffff',
              border:
                selectedButton === 'insights'
                  ? '1px solid #DBF7EB'
                  : '1px solid #000000',
              borderRadius: '90px',
              color: '#333',
              cursor: 'pointer',
              fontSize: '14px',
              fontWeight: 'bold',
              padding: '8px 18px',
              textAlign: 'center',
              textDecoration: 'none',
            }}
          >
            <CustomIcon
              iconname="insights"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '22px',
                marginRight: '3px',
              }}
            ></CustomIcon>
            Insights
          </button>
          <button
            id="meetings"
            onClick={() => handleButtonClick('meetings')}
            style={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor:
                selectedButton === 'meetings' ? '#FFEED6' : '#ffffff',
              border:
                selectedButton === 'meetings'
                  ? '1px solid #FFEED6'
                  : '1px solid #000000',
              borderRadius: '90px',
              color: '#333',
              cursor: 'pointer',
              fontSize: '14px',
              fontWeight: 'bold',
              padding: '8px 18px',
              textAlign: 'center',
              textDecoration: 'none',
            }}
          >
            <CustomIcon
              iconname="calendar-meeting"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '20px',
                marginRight: '7px',
              }}
            ></CustomIcon>
            Meetings
          </button>
        </Box>
      ) : (
        <Select
          labelId="filter-label"
          id="filter-select"
          value={selectedButton}
          onChange={handleFilterChange}
          style={{ padding: '10px 5px 10px 5px' }}
          sx={{
            height: '40px',
            fontFamily: 'Poppins',
            fontSize: '14px',
            color: '#232B3',
            fontWeight: 400,
            padding: '5px',
            height: '44px',
            width: { xxs: '99%', xs: '100%' },
            borderRadius: '10px',
            '& .MuiOutlinedInput-notchedOutline': {
              border: '1px solid #E6EDFF',
            },
          }}
        >
          <MenuItem
            id="viewAll"
            value="viewAll"
            onClick={() => handleButtonClick('viewAll')}
          >
            View All
          </MenuItem>
          <MenuItem
            id="posts"
            value="posts"
            onClick={() => handleButtonClick('posts')}
          >
            Posts
          </MenuItem>
          <MenuItem
            id="insights"
            value="insights"
            onClick={() => handleButtonClick('insights')}
          >
            Insights
          </MenuItem>
          <MenuItem
            id="meetings"
            value="meetings"
            onClick={() => handleButtonClick('meetings')}
          >
            Meetings
          </MenuItem>
        </Select>
      )}
    </Box>
  );
};

export default SearchBox;
