import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import FeedBackImg from './10k.png';

export default function GetFeedback() {
  const isUnder1356px = useMediaQuery('(max-width:1356px)');
  const isUnder1500px = useMediaQuery('(max-width:1500px)');
  const isUnder376px = useMediaQuery('(max-width:376px)');
  return (
    <Box sx={{ padding: { xxs: '0px 20px', sm: '20px' } }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isUnder1356px ? 'column' : 'row',
          justifyContent: { xxs: 'center', sm: 'space-between' },
          alignItems: 'center',
          gap: isUnder1356px ? '20px 0px' : '0px',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '650px',
            gap: { xxs: '20px 0px', sm: '0px' },
            marginBottom: isUnder1356px ? '80px' : '0px',
            zIndex: 3,
          }}
        >
          <Box
            sx={{
              width: { xxs: '100%', sm: '410px' },
              maxWidth: '410px',
              height: { xxs: '100%', sm: '197px' },
              border: '2px solid #EFF0F6',
              borderRadius: '10px',
              padding: '30px',
              backgroundColor: '#FBFCFD',
              zIndex: 3,
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '17px',
                lineHeight: '22px',
                letterSpacing: '0.75px',
                marginBottom: '10px',
              }}
            >
              Unlock access to our network
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '15px',
                lineHeight: '22px',
                letterSpacing: '0.75px',
              }}
            >
              Get full access to our network of advisors and business
              professionals, interested in collaborating with you.
            </Typography>
          </Box>
          <Box
            sx={{
              width: { xxs: '100%', sm: '410px' },
              maxWidth: '410px',
              height: { xxs: '100%', sm: '197px' },
              border: '2px solid #EFF0F6',
              borderRadius: '10px',
              padding: '30px',
              backgroundColor: '#FBFCFD',
              zIndex: 3,
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '17px',
                lineHeight: '22px',
                letterSpacing: '0.75px',
                marginBottom: '10px',
              }}
            >
              High-quality global experts
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '15px',
                lineHeight: '22px',
                letterSpacing: '0.75px',
              }}
            >
              We have the largest global network of advisors available at your
              fingertips. Search, discover, and be matched with the expertise
              you need.
            </Typography>
          </Box>
          <Box
            sx={{
              width: { xxs: '100%', sm: '410px' },
              maxWidth: '410px',
              height: { xxs: '100%', sm: '197px' },
              border: '2px solid #EFF0F6',
              borderRadius: '10px',
              padding: '30px',
              backgroundColor: '#FBFCFD',
              zIndex: 3,
            }}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '17px',
                lineHeight: '22px',
                letterSpacing: '0.75px',
                marginBottom: '10px',
              }}
            >
              Work with a dedicated team
            </Typography>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '15px',
                lineHeight: '22px',
                letterSpacing: '0.75px',
              }}
            >
              Our team is here to help you connect with the right advisors,
              suggest the right formats to work with them, and help you get the
              most from outside experts, from consultation to scheduling.
            </Typography>
          </Box>

          {isUnder1356px ? null : (
            //Primera flecha
            <>
              <Box
                sx={{
                  position: 'absolute',
                  top: '9.5%',
                  left: '50%',
                  width: '3px',
                  height: '130px',
                  bgcolor: '#EFF0F6',
                  transform: 'translateY(50%)',
                  zIndex: 1,
                }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: isUnder1500px ? '11.6%' : '11%',
                  left: '44.7%',
                  width: '3px',
                  height: isUnder1500px ? '100px' : '112px',
                  bgcolor: '#EFF0F6',
                  transform: 'rotate(90deg)',
                  zIndex: 1,
                }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: isUnder1500px ? '32%' : '31.2%',
                  left: '55.2%',
                  width: '3px',
                  height: isUnder1500px ? '95px' : '110px',
                  bgcolor: '#EFF0F6',
                  transform: 'rotate(90deg)',
                  zIndex: 1,
                }}
              />

              {/* Segunda flecha */}
              <Box
                sx={{
                  position: 'absolute',
                  top: '25%',
                  left: '55.5%',
                  width: '3px',
                  height: '350px',
                  bgcolor: '#EFF0F6',
                  transform: 'rotate(90deg)',
                  zIndex: 1,
                }}
              />

              {/* Tercera flecha */}
              <Box
                sx={{
                  position: 'absolute',
                  top: '47%',
                  left: '50%',
                  width: '3px',
                  height: '164px',
                  bgcolor: '#EFF0F6',
                  transform: 'translateY(50%)',
                  zIndex: 1,
                }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: isUnder1500px ? '76%' : '75%',
                  left: '44%',
                  width: '3px',
                  height: isUnder1500px ? '111px' : '126px',
                  bgcolor: '#EFF0F6',
                  transform: 'rotate(90deg)',
                  zIndex: 1,
                }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: isUnder1500px ? '52%' : '51%',
                  left: '55.5%',
                  width: '3px',
                  height: isUnder1500px ? '100px' : '115px',
                  bgcolor: '#EFF0F6',
                  transform: 'rotate(90deg)',
                  zIndex: 1,
                }}
              />
            </>
          )}
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            maxWidth: '410px',
            width: '100%',
            zIndex: 6,
          }}
        >
          {/* <Feedback /> */}
          <img src={FeedBackImg} atl="feedback" width="100%" />
        </Box>
        {isUnder1356px && (
          <Box
            sx={{
              position: 'absolute',
              top: '-200px',
              left: '50%',
              width: '3px',
              height: '700px',
              bgcolor: '#EFF0F6',
              transform: 'translateX(-50%) translateY(50%)',
              zIndex: 1,
            }}
          />
        )}
      </Box>
    </Box>
  );
}
