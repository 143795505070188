import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import ACSnackbar from '../components/ACSnackbar';
import AdvisorPublicJoinForm from '../components/Advisor/PublicJoinForm';
import PublicRegistration from '../layouts/PublicRegistration';
import { publicContactRegister } from '../services/Backend/publicCalls';
import { getCompanyPublicInfoBySlug } from '../services/company_services';
import getError from '../utils/apiError';

const PublicContactRegister = () => {
  const history = useHistory();
  const { companySlug, id } = useParams('/join/team/:companySlug/:id');
  const [companyDisplayName, setCompanyDisplayName] = useState('');
  const [image, setImage] = useState(null);
  const [error, setError] = useState('Something went wrong');
  const [showSnackbar, setShowSnackbar] = useState(false);

  const onSubmit = async ({ givenName, surName, email }, setIsLoading) => {
    try {
      if (!companyDisplayName) {
        setShowSnackbar(true);
        return;
      }
      const response = await publicContactRegister({
        givenName,
        surName,
        email,
        companySlug,
        companyName: companyDisplayName,
        companyId: id,
      });
      if (response.errors) {
        setError(getError(response));
        setShowSnackbar(true);
        if (setIsLoading) {
          setIsLoading(false);
        }
      } else {
        history.push(`/company/confirmation?email=${email}`);
      }
    } catch (error) {
      console.log(error);
      setShowSnackbar(true);
      if (setIsLoading) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const getCompanyInfo = async () => {
      try {
        const response = await getCompanyPublicInfoBySlug({
          slug: companySlug,
          preventWithOwnerImage: true,
        });
        setImage(response.data.getCompany.image);
        setCompanyDisplayName(response.data.getCompany.displayName);
      } catch (error) {
        console.log(error);
        setShowSnackbar(true);
      }
    };
    getCompanyInfo();
  }, [companySlug]);

  return (
    <>
      <ACSnackbar
        onClose={() => setShowSnackbar(false)}
        open={showSnackbar}
        severity="error"
        text={error}
      />
      <PublicRegistration>
        <AdvisorPublicJoinForm
          companySlug={companyDisplayName}
          onSubmit={onSubmit}
          image={image}
        />
      </PublicRegistration>
    </>
  );
};

export default PublicContactRegister;
