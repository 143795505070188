import React from 'react';

function Breakthroughs() {
  return (
    <div>
      <span>Breakthroughs</span>
    </div>
  );
}

export default Breakthroughs;
