import React from 'react';
import { useAccount } from '../contexts/Account';
import AdvisorDashboard from '../scenes/AdvisorDashboardV2';
import TopBar from './Topbar';
import { Switch, Route } from 'react-router-dom';
import { Box } from '@mui/material';
import Treatment from './../components/Treatment';
import { featureFlags } from './../splitSetup';

const useStyles = ({ isFreemium }) => ({
  baseContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    marginTop: isFreemium ? '50px' : '0px',
    backgroundColor: '#f4f8ff',
  },
  impersonationBanner: {
    position: 'fixed',
    width: '100%',
    zIndex: 1000,
  },
  contentContainer: {
    flex: 'auto',
    display: 'flex',
    flexDirection: 'row',
  },
  mainViewContainer: {
    flex: 'auto',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
  },
  viewContainer: {
    display: 'flex',
    flex: 'auto',
    overflowX: 'hidden',
  },
});

function Layout() {
  const { isImpersonated, isFreemium, currentUserInfo } = useAccount();
  const classes = useStyles({ isFreemium });

  return (
    <Treatment
      userId={currentUserInfo.username}
      splitNames={featureFlags.master_redesign}
      updateOnSdkTimedout
      evaluatedComponent={
        <div style={classes.baseContainer}>
          <TopBar />
          {isImpersonated && (
            <div style={classes.impersonationBanner}>
              {/* Aquí podrías poner el componente de la banner de impersonation si existe */}
            </div>
          )}
          <div style={classes.contentContainer}>
            <div style={classes.mainViewContainer}>
              <Box sx={classes.viewContainer}>
                <Switch>
                  <Route path="/v2/dashboard" component={AdvisorDashboard} />
                </Switch>
              </Box>
            </div>
          </div>
        </div>
      }
    />
  );
}

export default Layout;
