import React from 'react';
import { Typography, useMediaQuery } from '@mui/material';
import CustomIcon from './../../../icons/customIcon';

const useStyles = (isSM) => ({
  mainConatiner: {
    display: 'flex',
    justifyContent: 'end',
    flexDirection: 'row',
    width: '100%',
  },
  shrinkControlContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  mainArea: {
    flexShrink: 0,
    marginRight: '5px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 'fit-content',
    paddingRight: '8px',
    height: isSM ? '22px' : '32px',
    color: '#273762',
    borderRadius: '15px',
  },
  text: {
    color: '#232B35',
    fontSize: '10px',
    marginLeft: '15px',
    marginRight: '10px',
    fontWeight: 500,
  },
  circle: {
    height: isSM ? '15px' : '25px',
    width: isSM ? '15px' : '25px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: '50%',
  },
});

function HighlighterPill(props) {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const {
    type,
    noLogo,
    CustomIcon,
    isMeeting,
    pillColor,
    upcomingBoardMeeting,
  } = props;
  const iconStyle = {
    color: '#232B35',
    fontSize: '18px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '15px',
    paddingBottom: '1px',
    fontWeight: 900,
    width: '34px',
  };

  function getMeetingDate() {
    if (upcomingBoardMeeting) {
      const meetingDate = new Date(upcomingBoardMeeting);
      return (
        <span style={{ fontWeight: 700 }}>
          {meetingDate.getMonth() + 1}/{meetingDate.getDate()}
        </span>
      );
    }
    return '';
  }

  function getPaddingRight() {
    if (isSM) {
      if (type === 'Peer Advisory Board') {
        return '8px';
      }
      return '6px';
    }
    return '0px';
  }

  function getPaddingLeft() {
    if (isSM) {
      if (type === 'Peer Advisory Board') {
        return '11px';
      }
      return '9px';
    }
    return '0px';
  }

  return (
    <div
      className={`${isMeeting ? 'boardMeetingBadge' : ''}`}
      style={classes.mainConatiner}
    >
      <div style={classes.shrinkControlContainer}>
        <div
          style={{
            ...classes.mainArea,
            backgroundColor: pillColor,
            paddingLeft: getPaddingLeft(),
          }}
          id="profileHighlightPill"
        >
          {!noLogo ? (
            <div
              style={{
                ...classes.circle,
                backgroundColor: pillColor,
                paddingRight: getPaddingRight(),
              }}
            >
              {!CustomIcon ? (
                <CustomIcon iconname="cloud-logo" style={iconStyle} />
              ) : (
                React.cloneElement(CustomIcon, { style: iconStyle })
              )}
            </div>
          ) : null}
          <Typography sx={classes.text}>
            {type} {isMeeting && getMeetingDate()}
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default HighlighterPill;
