export const labels = {
  labelInvalidField: '',
  labelFileWaitingForSize: '',
  labelFileSizeNotAvailable: '',
  labelFileLoading: '',
  labelFileLoadError: '',
  labelFileProcessing: '',
  labelFileProcessingComplete: '',
  labelFileProcessingAborted: '',
  labelFileProcessingError: '',
  labelFileProcessingRevertError: '',
  labelFileRemoveError: '',
  labelTapToCancel: '',
  labelTapToRetry: '',
  labelTapToUndo: '',
  labelButtonRemoveItem: '',
  labelButtonAbortItemLoad: '',
  labelButtonRetryItemLoad: '',
  labelButtonAbortItemProcessing: '',
  labelButtonUndoItemProcessing: '',
  labelButtonRetryItemProcessing: '',
  labelButtonProcessItem: '',
  labelMaxFileSizeExceeded: '',
  labelMaxFileSize: '',
  labelMaxTotalFileSizeExceeded: '',
  labelMaxTotalFileSize: '',
  labelFileTypeNotAllowed: '',
  fileValidateTypeLabelExpectedTypes: '',
  imageValidateSizeLabelFormatError: '',
  imageValidateSizeLabelImageSizeTooSmall: '',
  imageValidateSizeLabelImageSizeTooBig: '',
  imageValidateSizeLabelExpectedMinSize: '',
  imageValidateSizeLabelExpectedMaxSize: '',
  imageValidateSizeLabelImageResolutionTooLow: '',
  imageValidateSizeLabelImageResolutionTooHigh: '',
  imageValidateSizeLabelExpectedMinResolution: '',
  imageValidateSizeLabelExpectedMaxResolution: '',
};
