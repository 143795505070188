import React from 'react';
import { ReactComponent as Sealth } from './stealth.svg';
import { ReactComponent as Assemble } from './assemble.svg';
import { ReactComponent as Collaborate } from './collaborate.svg';
import { ReactComponent as Moderate } from './moderate.svg';
import { Box, Typography } from '@mui/material';

function GettingStarted() {
  return (
    <Box sx={{ padding: { xxs: '5px', sm: '20px' } }}>
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: '28px',
          lineHeight: '32px',
          letterSpacing: '-0.43px',
          textAlign: 'center',
          marginBottom: '10px',
        }}
      >
        Getting started is easy
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xxs: 'column', sm: 'row' },
          alignItems: { xxs: 'center', sm: 'normal' },
          position: 'relative',
        }}
      >
        <Box
          sx={{
            width: { xxs: '200px', sm: '242px' },
            height: { xxs: '200px', sm: '242px' },
            borderRadius: '50%',
            background: '#FEF8D1',
            position: 'absolute',
            left: { xxs: '0%', xs: '25%', sm: '8%', md: '5%' },
            top: { xxs: '5%', sm: '2%' },
            zIndex: 1,
          }}
        />
        <Box
          sx={{
            marginLeft: { xxs: '0px', sm: '20px' },
            marginBottom: { xxs: '-60px', sm: '0px' },
            width: '100%',
            zIndex: 2,
          }}
        >
          <Sealth style={{ width: '100%', minWidth: '350px' }} />
        </Box>

        <Box
          sx={{
            marginTop: { xxs: '0px', sm: '50px' },
            maxWidth: '412px',
            minWidth: '250px',
            marginLeft: { xxs: '0px', sm: '60px' },
            padding: '20px',
            zIndex: 2,
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '22px',
              lineHeight: '32px',
              letterSpacing: '-0.43px',
              marginBottom: '10px',
            }}
          >
            Schedule your onboarding call
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '15px',
              lineHeight: '22px',
              letterSpacing: '0.75px',
              marginBottom: '20px',
            }}
          >
            Partner with a dedicated account manager for a smooth launch of your
            advisory board.
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '15px',
              lineHeight: '22px',
              letterSpacing: '0.75px',
            }}
          >
            We'll work to understand your goals, build your company profile, and
            handle everything from scheduling meetings to facilitating
            communications among your advisory board
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xxs: 'column-reverse', sm: 'row' },
          alignItems: { xxs: 'center', sm: 'normal' },
          position: 'relative',
          marginTop: { xxs: '100px', sm: '0px' },
        }}
      >
        <Box
          sx={{
            marginTop: { xxs: '0px', sm: '50px' },
            maxWidth: '412px',
            minWidth: '250px',
            marginLeft: { xxs: '0px', sm: '60px' },
            padding: '20px',
            zIndex: 2,
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '22px',
              lineHeight: '32px',
              letterSpacing: '-0.43px',
              marginBottom: '10px',
            }}
          >
            Assemble your advisory board
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '15px',
              lineHeight: '22px',
              letterSpacing: '0.75px',
              marginBottom: '20px',
            }}
          >
            Assemble an advisory board from our network of advisors based on
            your goals.
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '15px',
              lineHeight: '22px',
              letterSpacing: '0.75px',
            }}
          >
            Once your profile is published Advisors can join your advisory board
            through your profile, you can invite specific advisors, and work
            with our team to determine your best matches.
          </Typography>
        </Box>
        <Box
          sx={{
            width: { xxs: '200px', sm: '242px' },
            height: { xxs: '200px', sm: '242px' },
            borderRadius: '50%',
            background: '#D7F1FD',
            position: 'absolute',
            right: { xxs: '0%', xs: '25%', sm: '-2%', md: '-2%', lg: '0%' },
            top: { xxs: '-5%', sm: '-10%' },
            zIndex: 1,
          }}
        />
        <Box
          sx={{
            marginLeft: { xxs: '0px', sm: '20px' },
            width: '100%',
            zIndex: 2,
          }}
        >
          <Assemble style={{ width: '100%', minWidth: '350px' }} />
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xxs: 'column', sm: 'row' },
          alignItems: { xxs: 'center', sm: 'normal' },
          position: 'relative',
          marginTop: { xxs: '40px', sm: '0px' },
        }}
      >
        <Box
          sx={{
            width: { xxs: '200px', sm: '242px' },
            height: { xxs: '200px', sm: '242px' },
            borderRadius: '50%',
            background: '#DBF7EB',
            position: 'absolute',
            left: { xxs: '0%', xs: '25%', sm: '8%', md: '5%' },
            top: { xxs: '5%', sm: '2%' },
            zIndex: 1,
          }}
        />
        <Box
          sx={{
            zIndex: 2,
            marginBottom: { xxs: '-80px', sm: '0px' },
            width: '100%',
          }}
        >
          <Collaborate style={{ width: '100%', minWidth: '350px' }} />
        </Box>

        <Box
          sx={{
            marginTop: { xxs: '0px', sm: '50px' },
            maxWidth: '450px',
            minWidth: '350px',
            padding: '20px',
            zIndex: 2,
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '22px',
              lineHeight: '32px',
              letterSpacing: '-0.43px',
              marginBottom: '10px',
            }}
          >
            Collaborate in your digital boardroom
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '15px',
              lineHeight: '22px',
              letterSpacing: '0.75px',
              marginBottom: '20px',
            }}
          >
            Post questions, message, meet, and crowd-source complimentary
            insights from your advisors on a regular basis through your
            boardroom.
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '15px',
              lineHeight: '22px',
              letterSpacing: '0.75px',
              marginBottom: '20px',
            }}
          >
            Advisors participate on your advisory board in exchange for the
            professional credential of being an advisor for your company -
            allowing you to have an advisory board for a flat monthly-fee.
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '15px',
              lineHeight: '22px',
              letterSpacing: '0.75px',
            }}
          >
            You can also hire standout advisors hourly for consultations,
            project-based work, and more.
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xxs: 'column-reverse', sm: 'row' },
          alignItems: { xxs: 'center', sm: 'normal' },
          position: 'relative',
          marginTop: { xxs: '40px', sm: '0px' },
        }}
      >
        <Box
          sx={{
            marginTop: { xxs: '0px', sm: '50px' },
            maxWidth: '412px',
            minWidth: '250px',
            marginLeft: { xxs: '0px', sm: '60px' },
            padding: '20px',
            zIndex: 2,
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '22px',
              lineHeight: '32px',
              letterSpacing: '-0.43px',
              marginBottom: '10px',
            }}
          >
            Quarterly moderated board meetings
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '15px',
              lineHeight: '22px',
              letterSpacing: '0.75px',
              marginBottom: '20px',
            }}
          >
            Meet quarterly with your advisory board moderated and facilitated by
            AdvisoryCloud.
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '15px',
              lineHeight: '22px',
              letterSpacing: '0.75px',
              marginBottom: '20px',
            }}
          >
            Leave each meeting with new ideas and ways to solve your most
            pressing challenges. Receive full transcripts, summary, and analysis
            from our team.
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '15px',
              lineHeight: '22px',
              letterSpacing: '0.75px',
            }}
          >
            We'll work to understand your goals, build your company profile, and
            handle everything from scheduling meetings to facilitating
            communications among your advisory board
          </Typography>
        </Box>
        <Box
          sx={{
            width: { xxs: '200px', sm: '242px' },
            height: { xxs: '200px', sm: '242px' },
            borderRadius: '50%',
            background: '#D7F1FD',
            position: 'absolute',
            right: { xxs: '0%', xs: '25%', sm: '8%', md: '5%' },
            top: { xxs: '5%', sm: '2%' },
            zIndex: 1,
          }}
        />
        <Box
          sx={{
            marginLeft: { xxs: '0px', sm: '20px' },
            marginBottom: { xxs: '-70px', sm: '0px' },
            zIndex: 2,
            width: '100%',
          }}
        >
          <Moderate style={{ width: '100%', minWidth: '350px' }} />
        </Box>
      </Box>
    </Box>
  );
}

export default GettingStarted;
