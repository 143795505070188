import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EmptyStateContent from '../../components/EmptyStateContent';
import ACButton from '../../components/ACButton';
import EmptyAnswersSvg from '../../images/empty-answers.svg';
import EmptyCommentsSvg from '../../images/empty-comments.svg';
import {
  AccountType,
  AdvisorDetailCardMetric,
  EndpointResponseObjectName,
} from '../../services/utils/types';
import {
  getResponsesByAdvisor,
  getSinglePostCall,
} from '../../services/company_services';
import { useAccount } from '../../contexts/Account';
import FilteredFeed from '../FilteredFeed';
import SceneContentStateSelector from '../SceneContentStateSelector';
import { isNil, getAdvisorImagePath } from '../../services/utils';
import AdvisorItem from '../Advisor/List/AdvisorItem';

const useStyles = () => ({
  addMargin: {
    marginBottom: '25px',
    clear: 'both',
  },
  addMargin2: {
    marginBottom: '125px',
  },
  back: {
    float: 'left',
    clear: 'both',
    display: 'flex',
    marginBottom: '10px',
  },
});

function IndividualAdvisor(props) {
  const {
    advisorData,
    clickActions,
    advisorDetailCardMetric,
    onBackButtonClicked,
    mapSkills,
  } = props;
  const classes = useStyles();
  const { accountDetails, asLoggedUserDetails } = useAccount();
  const [feedType, setFeedType] = useState(advisorDetailCardMetric);
  const [postType, setPostType] = useState(advisorDetailCardMetric);
  const params = useParams('/:companySlug/questions/:postId');
  const [showEmptyState, setShowEmptyState] = useState(false);
  const [emptyStateImage, setEmptyStateImage] = useState([]);
  const [emptyStateImageAlt, setEmptyStateImageAlt] = useState('');
  const [emptyStateTitle, setEmptyStateTitle] = useState('');
  const { postId } = params;
  const singlePostRender = postId !== undefined;

  function createPostRenderSelector() {
    return {
      multiplePost: {
        parameters: {
          ADVISOR_ID: advisorData.id,
          COMPANY_ID: accountDetails.id,
          RESPONSE_TYPE:
            feedType === 'answer' || feedType === 'comment'
              ? feedType
              : 'answer',
          USER_ID: asLoggedUserDetails.userId,
        },
        asyncName: EndpointResponseObjectName.GET_RESPONSES_BY_ADVISOR,
        graphqlEndpoint: getResponsesByAdvisor,
        contentTag: FilteredFeed,
      },
      singlePost: {
        parameters: {
          ACCOUNT_TYPE: AccountType.CONTACT,
          USER_ID: asLoggedUserDetails.userId,
          POST_ID: postId,
        },
        asyncName: EndpointResponseObjectName.GET_POST,
        graphqlEndpoint: getSinglePostCall,
        contentTag: FilteredFeed,
      },
    };
  }

  function changeFeedType(value) {
    setShowEmptyState(false);
    setFeedType(value);
  }

  const [postRenderedSelector, setPostRenderedSelector] = useState(
    createPostRenderSelector()
  );
  const { t } = useTranslation();

  useEffect(() => {
    switch (feedType) {
      case AdvisorDetailCardMetric.BREAKTHROUGHS:
        setEmptyStateTitle('Breakthroughs not implemented yet.');
        setShowEmptyState(true);
        break;
      case AdvisorDetailCardMetric.COMMENTS:
        setEmptyStateImage(EmptyCommentsSvg);
        setEmptyStateImageAlt('empty-comments.svg');
        setEmptyStateTitle(
          `${advisorData.displayName} hasn't made any comments.`
        );
        setPostType('comment');
        if (advisorData.countComments <= 0 || !advisorData.countComments) {
          setShowEmptyState(true);
        }
        setPostRenderedSelector(createPostRenderSelector());
        break;
      default:
        setEmptyStateImage(EmptyAnswersSvg);
        setEmptyStateImageAlt('empty-answers.svg');
        setEmptyStateTitle(
          `${advisorData.displayName} hasn't answered any questions.`
        );
        setPostType('answer');
        if (isNil(advisorData.countAnswers) || advisorData.countAnswers <= 0) {
          setShowEmptyState(true);
        }
        setPostRenderedSelector(createPostRenderSelector());
    }
  }, [feedType, setPostType, setPostRenderedSelector]);

  const imageArray = [
    {
      src: emptyStateImage,
      alt: emptyStateImageAlt,
      width: '200',
    },
  ];

  return (
    <>
      <div style={classes.back}>
        <ACButton
          color="primary"
          size="small"
          onClick={() => {
            onBackButtonClicked();
          }}
        >
          Back
        </ACButton>
      </div>
      <div style={classes.addMargin}>
        <AdvisorItem
          advisorDetailData={advisorData}
          // answers
          answers={advisorData.countAnswers ? advisorData.countAnswers : 0}
          answersClick={() => {
            changeFeedType(AdvisorDetailCardMetric.ANSWERS);
          }}
          answersFilled={postType === AdvisorDetailCardMetric.ANSWERS}
          // comments
          comments={advisorData.countComments ? advisorData.countComments : 0}
          commentsFilled={postType === AdvisorDetailCardMetric.COMMENTS}
          commentsClick={() => {
            changeFeedType(AdvisorDetailCardMetric.COMMENTS);
          }}
          // breakthroughs
          // service clicks
          requestMeetingClick={() => {
            clickActions.requestMeeting(advisorData);
          }}
          reportAdvisorClick={() => {
            clickActions.reportAdvisor(advisorData);
          }}
          isStar
          // highlighter
          profileHighlighter
          profileHighlighterType="Advisory Board Member"
          profileHighlighterDate={advisorData.createdAt}
          // advisorDetail
          advisorName={advisorData.displayName}
          advisorInfo={advisorData.title}
          advisorAvatar={getAdvisorImagePath(advisorData)}
          skills={mapSkills(advisorData.skillTagIds)}
          bio={advisorData.biography}
        />
      </div>
      {showEmptyState ? (
        <div style={classes.addMargin2} key="Empty state">
          <EmptyStateContent
            imageArray={imageArray}
            imageContainerBottomMargin="20px"
            titleText={emptyStateTitle}
          />
        </div>
      ) : null}
      <SceneContentStateSelector
        helmetTitle={t('COMPANY-MY-UPDATES-HELMET-TITLE')}
        endPointAppSyncName={
          singlePostRender
            ? postRenderedSelector.singlePost.asyncName
            : postRenderedSelector.multiplePost.asyncName
        }
        endPointCall={
          singlePostRender
            ? postRenderedSelector.singlePost.graphqlEndpoint
            : postRenderedSelector.multiplePost.graphqlEndpoint
        }
        endPointCallParameters={
          singlePostRender
            ? postRenderedSelector.singlePost.parameters
            : postRenderedSelector.multiplePost.parameters
        }
        ContentTag={
          singlePostRender
            ? postRenderedSelector.singlePost.contentTag
            : postRenderedSelector.multiplePost.contentTag
        }
        contentProps={{
          asyncName: singlePostRender
            ? postRenderedSelector.singlePost.asyncName
            : postRenderedSelector.multiplePost.asyncName,
          doNotShowBackClick: true,
        }}
        doNotShowEmptyStateScene
        setFullScreen
      />
    </>
  );
}

export default IndividualAdvisor;
