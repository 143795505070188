import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  FormControl,
  TextField,
  useMediaQuery,
  MenuItem,
  InputLabel,
} from '@mui/material';
import { getBoardMeetings } from '../../services/advisor_services';
import SceneContentStateSelector from '../../components/SceneContentStateSelector';
import FilteredFeed from '../../components/FilteredFeed';
import SearchMeetingTiles from '../../components/TopTiles/SearchMeetingsTiles';
import BoardMeetingSearchBar from '../../components/BoardMeetingSearchBar';
import { useAccount } from '../../contexts/Account';

const getClasses = (isSM) => ({
  container: {
    paddingLeft: isSM ? '10px' : '30px',
    paddingRight: isSM ? '10px' : '30px',
    display: 'flex',
    flexDirection: 'column',
    flex: 'auto',
    overflowX: 'scroll',
    maxWidth: '1200px',
    paddingTop: '20px',
  },

  image: {
    borderRadius: '16px',
    width: '100%',
  },
  selectsContainers: {
    marginTop: '10px',
    display: 'flex',
    flexDirection: isSM ? 'column' : 'row',
    alignItems: 'start',
    width: '100%',
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  searchContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  showMeContainer: {
    width: isSM ? '100%' : '34%',
    marginRight: isSM ? '0' : '16px',
  },
  keywordsInput: {
    display: 'flex',
    flex: 'auto',
    border: '1px solid #E6EDFF',
    borderRadius: '10px',
  },
  inputTextfield: {
    height: '30px',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.4300000071525574px',
  },
  selectTextField: {
    height: '40px',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.4300000071525574px',
    borderRadius: '10px',
    fontStyle: 'normal',
    display: 'flex',
    alignItems: 'center',
  },
  customTextField: {
    '& input::placeholder': {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: 400,
      color: '#74879A',
    },
  },
  menuItemText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '-0.43px',
  },
});

function AdvisorBoardRoomUpdates() {
  const { t } = useTranslation();
  const { accountDetails } = useAccount();
  const [filterOption, setFilterOption] = useState('');
  const [timeOption, setTimeOption] = useState('');
  const [keywords, setKeywords] = useState('');

  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = getClasses(isSM);

  const postRenderedSelector = {
    parameters: {
      // AND THIS, TO ONLY BRING ALL THE BOARD MEETINGD
      ADVISOR_ID: accountDetails.id,
      OFFSET: 0,
      FILTER: filterOption || 'ALL',
      SEARCH_STRING: keywords,
      UPCOMING_MEETINGS: timeOption ? timeOption === 'UPCOMING' : true,
      LIVE_MEETINGS: timeOption ? timeOption === 'UPCOMING' : true,
      PAST_MEETINGS: timeOption ? timeOption === 'PAST' : false,
    },
    asyncName: 'getBoardMeetings',
    graphqlEndpoint: getBoardMeetings, // CREATE A NEW CALL THAT TAKES THE FILTERS AND ONLY BRINGS BOARD_MEETINGS
    contentTag: FilteredFeed, // THIS CAN STAY? ITS EASIER
  };

  return (
    <div style={classes.container}>
      <SearchMeetingTiles />
      <div style={classes.filterContainer}>
        <div style={classes.searchContainer}>
          <BoardMeetingSearchBar
            onSearchButtonClicked={(val) => setKeywords(val)}
          />
        </div>
        <div style={classes.selectsContainers}>
          <div
            style={{
              ...classes.showMeContainer,
              marginBottom: isSM ? 15 : 0,
            }}
          >
            <FormControl
              variant="outlined"
              size="small"
              style={{ display: 'inline' }}
            >
              {filterOption ? null : (
                <InputLabel
                  sx={{
                    color: '#74879A',
                  }}
                >
                  All Boards
                </InputLabel>
              )}
              <TextField
                id="select-meeting-type"
                data-testid="select-meeting-type"
                data-cy="select-meeting-type"
                value={filterOption}
                variant="outlined"
                select
                size="small"
                fullWidth
                onChange={(event) => {
                  setFilterOption(event.target.value);
                }}
                InputProps={{
                  style: classes.selectTextField,
                }}
                sx={classes.customTextField}
              >
                <MenuItem value="ALL" sx={classes.menuItemText}>
                  {t('MEETING-FILTER-OPTION-ALL')}
                </MenuItem>
                <MenuItem value="BOARD" sx={classes.menuItemText}>
                  {t('MEETING-FILTER-OPTION-MINE')}
                </MenuItem>
              </TextField>
            </FormControl>
          </div>
          <div style={classes.showMeContainer}>
            <FormControl
              variant="outlined"
              size="small"
              style={{ display: 'inline' }}
            >
              {timeOption ? null : (
                <InputLabel
                  sx={{
                    color: '#74879A',
                  }}
                >
                  Upcoming
                </InputLabel>
              )}
              <TextField
                id="select-meeting-time"
                data-testid="select-meeting-time"
                data-cy="select-meeting-time"
                value={timeOption}
                variant="outlined"
                select
                size="small"
                fullWidth
                onChange={(event) => {
                  setTimeOption(event.target.value);
                }}
                InputProps={{
                  style: classes.selectTextField,
                }}
                sx={classes.customTextField}
              >
                <MenuItem value="UPCOMING" sx={classes.menuItemText}>
                  {t('UPCOMING_MEETINGS_LABEL')}
                </MenuItem>
                <MenuItem value="PAST" sx={classes.menuItemText}>
                  {t('PAST_MEETINGS_LABEL')}
                </MenuItem>
              </TextField>
            </FormControl>
          </div>
          <Box
            sx={{
              ...classes.showMeContainer,
              paddingRight: '0px',
            }}
          />
        </div>
      </div>
      <SceneContentStateSelector
        helmetTitle={t('ADVISOR-BOARD-MEETING-HELMET-TITLE')}
        endPointAppSyncName={postRenderedSelector.asyncName}
        endPointCall={postRenderedSelector.graphqlEndpoint}
        endPointCallParameters={postRenderedSelector.parameters}
        ContentTag={postRenderedSelector.contentTag}
        contentProps={postRenderedSelector}
        doNotShowEmptyStateScene
        isDashboardSkeleton
      />
    </div>
  );
}

export default AdvisorBoardRoomUpdates;
