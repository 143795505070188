import React from 'react';
import ReactDOM from 'react-dom';
import { SplitFactory } from '@splitsoftware/splitio-react';
import sdkConfig from './splitSetup';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <SplitFactory config={sdkConfig} updateOnSdkTimedout={true}>
      {({ factory, isReady, isTimedout, lastUpdate }) => {
        return <App />;
      }}
    </SplitFactory>
  </React.StrictMode>,
  document.getElementById('root')
);
