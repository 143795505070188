import React from 'react';
import { Box, Paper, Typography, useMediaQuery } from '@mui/material';
import { useAccount } from '../../../contexts/Account';
import TextGroup from './TextGroup';
import TileEmptyState from './TileEmptyState';
import { ReactComponent as EmptyDashboard } from './boardroom_tile_empty_state.svg';

const getClasses = ({ isSM }) => ({
  tile: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: isSM ? '100%' : '240px',
    height: '240px',
    borderRadius: '16px',
    padding: '20px',
    marginBottom: isSM ? '10px' : '',
    backgroundColor: '#D7F1FD',
    marginLeft: isSM ? '0px' : '20px',
  },
  topText: {
    fontWeight: 500,
    fontSize: '16px',
    color: '#232B35',
  },
});

function PostsCountTile() {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = getClasses({ isSM });
  const { accountDetails, isFreemium } = useAccount();

  return (
    <Paper elevation={0} sx={classes.tile} id="b2bBoardroomCard">
      <Typography sx={classes.topText}>
        {isFreemium ? 'Activity' : 'Boardroom'}
      </Typography>
      {accountDetails &&
      accountDetails.dashboardStats &&
      accountDetails.dashboardStats.postsCount > 0 ? (
        <>
          <Box display="flex" flexDirection="row">
            <TextGroup
              qty={accountDetails.dashboardStats.postsCount}
              decorator="Posts"
              id="postsCount"
            />
            <TextGroup
              qty={accountDetails.dashboardStats.answersCount}
              decorator="Answers"
              id="answersCount"
            />
          </Box>
          <Box display="flex" flexDirection="row">
            <TextGroup
              qty={accountDetails.dashboardStats.commentsCount}
              decorator="Comments"
              id="commentsCount"
            />
            <TextGroup
              qty={accountDetails.dashboardStats.likesCount}
              decorator="Likes"
              id="likesCount"
            />
          </Box>
        </>
      ) : (
        <TileEmptyState
          Icon={EmptyDashboard}
          txt={
            isFreemium
              ? 'Stats from your AdvisoryCloud will appear here.'
              : 'Stats from your boardroom will appear once you post!'
          }
        />
      )}
    </Paper>
  );
}
export default PostsCountTile;
