import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Avatar,
  Typography,
  useMediaQuery,
  TextField,
  Grid,
  Select,
  MenuItem,
} from '@mui/material';
import { ArrowBackIos, EditOutlined, CancelRounded } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
import { RWebShare } from 'react-web-share';
import ACSnackbar from '../../ACSnackbar';
import DefaultCompany from './../../../icons/building_round.svg';
import { useAccount } from '../../../contexts/Account';
import BoardJoinButton from '../../BoardJoinButton';
import LinkedInPostModal from '../../LinkedInPostModal';
import { AccountType } from '../../../services/utils/types';
import {
  isLinkedinAuthenticated,
  authorizeWithPopUp,
  getAccessToken,
  closeChild,
} from '../../../services/LinkedIn';
import { ReactComponent as LinkedInIcon } from './../../Advisor/Profile/components/linkedin.svg';
import { ReactComponent as ShareIcon } from './../../Advisor/Profile/components/share.svg';
import { ReactComponent as AdvisoryCloudLogo } from './../../AdvisoryCloudLogo/AdvisoryCloudLogo.svg';
import FilePondCompany from './../LogoFreemiumUploader/index';
import CustomIcon from '../../../icons/customIcon';
import employees from './../Onboarding/employeesData.json';
import AutocompleteCustome from '../OnboardingFreemium/Autocomplete';

const useStyles = ({ isSM }) => ({
  card: {
    width: '100%',
  },
  publicProfileHeaderContainer: {},
  slideRigthArrow: {
    cursor: 'pointer',
    color: '#3171F6',
    marginTop: '10px',
    marginLeft: '50px',
  },
  mainInformationContainer: {},
  title: {
    fontWeight: 600,
    fontSize: isSM ? '20px' : '36px',
    textAlign: isSM ? 'center' : 'auto',
    fontFamily: 'Poppins',
    lineHeight: 1.2,
  },
  headline: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#646D7A',
    maxHeight: '50px',
    minWidth: '0px',
    marginTop: '0px',
    position: 'relative',
    wordBreak: 'break-all',
    textOverflow: 'ellipsis',
    overflowWrap: 'break-word',
    textAlign: isSM ? 'center' : 'auto',
  },
  emptyHeadline: {
    fontSize: '12px',
    fontWeight: 400,
    color: '#646D7A',
    maxHeight: '50px',
    minWidth: '0px',
    marginTop: '0px',
    position: 'relative',
    wordBreak: 'break-all',
    textOverflow: 'ellipsis',
    overflowWrap: 'break-word',
    textAlign: isSM ? 'center' : 'auto',
    fontS: 'italic',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  imageAndBiographyContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  imageContainer: {
    width: isSM ? '180px' : '150px',
    height: isSM ? '180px' : '150px',
    margin: isSM ? 'auto' : '0 20px 0 0',
    border: 'solid 2px white',
  },
  imageContainerOwnProfile: {
    width: isSM ? '180px' : '215px',
    height: isSM ? '180px' : '215px',
    margin: isSM ? 'auto' : '0 20px 0 0',
    border: 'solid 2px white',
  },
  biographyInformationContainer: {
    display: 'flex',
    marginLeft: '20px',
    width: '70%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  profileOptionDrawerContainerWithBar: {
    position: 'absolute',
    right: '1.5vw',
    display: 'flex',
  },
  profileOptionDrawerContainerWithBarMobile: {
    position: 'absolute',
    right: '30px',
    display: 'flex',
  },
  mobileImageContainer: {
    width: '100%',
    marginTop: '20px',
  },
  buttonContainer: {
    width: '100%',
    marginTop: '15px',
    justifyContent: 'center',
    position: 'fixed',
    bottom: '-2px',
    left: '0px',
    zIndex: 10,
  },
  editContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    border: 'none',
    padding: '0px',
    marginRight: '5px',
    minWidth: '100px',
  },
  editContainerMobile: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    border: 'none',
    padding: '0px',
    // marginRight: '10px',
    minWidth: '100px',
  },
  editIcon: {
    fontSize: '20px',
    marginRight: '5px',
    color: '#323b35',
  },
  editText: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#323b35',
  },
  logoContainer: {
    width: '180px',
    height: '40px',
    // cursor: props.cursor,
  },
  fieldContainer: {
    minWidth: '300px',
  },
  fieldContainerMobile: {
    width: '100%',
  },
});

function CompanyOwnSlideHeader({
  company,
  handleClickAway,
  companyCEO,
  isAcPlusCompany,
  isEditingState,
  setIsEditingState,
  handleEditChange,
  editValues,
  renderEditSaveButton,
  inEditForm,
  setInEditForm,
  setUploaderOutput,
  sendFormData,
  boxRef,
}) {
  const navRef = useRef();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down([1000]));
  const hideLogo = useMediaQuery((theme) => theme.breakpoints.down([1280]));
  const hideLogoEditState = useMediaQuery((theme) =>
    theme.breakpoints.down([1420])
  );
  const { t } = useTranslation();
  const classes = useStyles({ isSM });
  const { accountDetails, type, isFreemium } = useAccount();
  const [linkedInPostModal, setLinkedInPostModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [successSnackbar, setSuccessSnackbar] = useState(false);
  const [filePondStatus, setFilePondStatus] = useState(true);
  const [sticky, setSticky] = useState(false);

  let shareContent = {};

  if (accountDetails.userSegment === 'managed_advisory_board') {
    shareContent = {
      formTitle:
        'Let your LinkedIn network know you’re building an Advisory Board!',
      title: `Join the ${company.displayName} Advisory Board!`,
      text: `We’re seeking new advisory board members at ${company.displayName}. Join today and help us succeed! Open to anyone who supports our mission!\nRequest an invitation here if you're interested in joining! All perspectives are welcome.`,
      linkedInText: `We're using @advisorycloud to build our advisory board and are seeking outside experts to join our sounding board and help us reach our goals.\nRequest an invitation here if you’re interested in joining! All perspectives are welcome.`,
      link: `https://app.advisorycloud.com/join/advisors/${accountDetails.slug}/${accountDetails.id}`,
    };
  } else {
    shareContent = {
      formTitle:
        'Let your LinkedIn network know you’re building an AdvisoryCloud!',
      title: `Join our AdvisoryCloud`,
      text: `We’re seeking outside experts to join our sounding board and help us reach our goals.\nRequest an invitation here if you're interested in joining! All perspectives are welcome`,
      linkedInText: `We're using @advisorycloud to build our Advisory Board and we’re seeking new advisory board members for {company name}. \nJoin today and help us succeed! Open to anyone who supports our mission! Request an invitation here if you’re interested in joining! All perspectives are welcome.`,
      link: `https://app.advisorycloud.com/join/advisors/${accountDetails.slug}/${accountDetails.id}`,
    };
  }

  useEffect(() => {
    window.linkedinWait = true;

    const interval = setInterval(() => {
      if (window.linkedinSuccess) {
        clearInterval(interval);
        closeChild();
        getAccessToken(window.linkedinSuccess);
        setLinkedInPostModal(true);
      }
    }, 600);

    if (isLinkedinAuthenticated()) {
      window.linkedinWait = undefined;
      clearInterval(interval);
    }
    return () => {
      window.linkedinWait = undefined;
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (boxRef && boxRef.current) {
      boxRef.current.addEventListener('scroll', onScroll);
    }

    return () => {
      if (boxRef && boxRef.current) {
        boxRef.current.removeEventListener('scroll', onScroll);
      }
    };
  }, []);

  function renderCompanySizeOption(option) {
    if (!option || !option.label) return `Company size`;
    if (option.label === '1') return `${option.label} employee`;
    return `${option.label} employees`;
  }

  function edit() {
    setIsEditingState(!isEditingState);
  }

  async function linkedInAction() {
    const isAuthenticatedLinkedIn = isLinkedinAuthenticated();
    if (isAuthenticatedLinkedIn) {
      setLinkedInPostModal(true);
    } else {
      await authorizeWithPopUp();
    }
  }

  async function linkedInResponse(linkedinResponse) {
    if (linkedinResponse === 201) {
      setSuccessMessage('Your profile is shared successfully.');
      setSuccessSnackbar(true);
    } else if (linkedinResponse === 422) {
      setSuccessMessage(
        'You have already posted this content, either update the post or try again in 10 minutes'
      );
      setSuccessSnackbar(true);
    } else {
      setSuccessMessage('An error occurred while sharing your profile.');
      setSuccessSnackbar(true);
    }
  }

  const handleCopyLink = () => {
    navigator.clipboard.writeText(
      `https://app.advisorycloud.com/join/advisors/${accountDetails.slug}/${accountDetails.id}`
    );
    setSuccessMessage(
      'Link copied, you can now share it with whoever you choose'
    );
    setSuccessSnackbar(true);
  };

  function handleCompanyEmployeesAutocompleteChange(event, vals) {
    handleEditChange({
      target: { name: 'companySize', value: vals ? vals.id : '' },
    });
  }

  function onScroll() {
    if (navRef && navRef.current && navRef.current.getBoundingClientRect) {
      setSticky(
        navRef.current.getBoundingClientRect().top < (isFreemium ? 80 : 20)
      );
    }
  }

  function getImageSrc() {
    if (company.image && company.image.id) {
      return `https://${company.image.location}`;
    } else if (isAcPlusCompany && companyCEO && companyCEO.contactLogoId) {
      return companyCEO.contactLogoLocation;
    }
    return DefaultCompany;
  }

  return (
    <div style={classes.card}>
      <ACSnackbar
        open={successSnackbar}
        text={successMessage}
        severity="success"
        onClose={() => setSuccessSnackbar(false)}
        autoHideDuration={6000}
        style={{ marginTop: isFreemium ? '50px' : '0px' }}
      />
      {linkedInPostModal ? (
        <LinkedInPostModal
          linkedInPostModal={linkedInPostModal}
          setLinkedInPostModal={setLinkedInPostModal}
          linkedInResponse={linkedInResponse}
          fontFamily={'Poppins'}
          contents={{ ...shareContent, text: shareContent.linkedInText }}
        />
      ) : null}
      {!isSM ? (
        <Box sx={classes.publicProfileHeaderContainer} ref={navRef}>
          <Box
            sx={{
              display: 'flex',
              position: sticky ? 'fixed' : null,
              width: 'calc(70% - 60px)',
              paddingTop: sticky ? '10px' : 0,
              marginLeft: sticky ? '-1px' : 0,
              backgroundColor: 'white',
              zIndex: 1000,
              height: '60px',
              top: sticky ? (isFreemium ? '50px' : '0') : null,
            }}
          >
            {!hideLogo && !(hideLogoEditState && isEditingState) && (
              <AdvisoryCloudLogo style={classes.logoContainer} />
            )}
            <div style={classes.profileOptionDrawerContainerWithBar}>
              {isEditingState ? (
                <>
                  <button
                    onClick={sendFormData}
                    style={classes.editContainerMobile}
                  >
                    <CustomIcon
                      iconname="save-outlined"
                      style={classes.editIcon}
                    />
                    <Typography color="primary" sx={classes.editText}>
                      Save
                    </Typography>
                  </button>
                  <button
                    onClick={() => {
                      setIsEditingState(false);
                      setInEditForm('');
                    }}
                    style={classes.editContainerMobile}
                  >
                    <CancelRounded sx={classes.editIcon} />
                    <Typography color="primary" sx={classes.editText}>
                      Discard
                    </Typography>
                  </button>
                </>
              ) : (
                <>
                  <button onClick={edit} style={classes.editContainerMobile}>
                    <EditOutlined color="primary" sx={classes.editIcon} />
                    <Typography color="primary" sx={classes.editText}>
                      {t('EDIT-BUTTON-TEXT')}
                    </Typography>
                  </button>
                  <button
                    onClick={linkedInAction}
                    style={classes.editContainer}
                  >
                    <LinkedInIcon
                      color="primary"
                      style={{
                        marginRight: 5,
                        fontSize: 25,
                      }}
                    />
                    <Typography color="primary" sx={classes.editText}>
                      {t('STEP6-ADVISOR-PROMOTE')}
                    </Typography>
                  </button>
                  <RWebShare
                    data={{
                      text: shareContent.text,
                      url: shareContent.link,
                      title: shareContent.title,
                    }}
                  >
                    <button style={classes.editContainer}>
                      <ShareIcon color="primary" style={classes.editIcon} />
                      <Typography color="primary" sx={classes.editText}>
                        Share
                      </Typography>
                    </button>
                  </RWebShare>
                  <Box
                    onClick={handleCopyLink}
                    sx={{
                      width: '265px',
                      border: '1px solid #EFF0F6',
                      backgroundColor: '#FFFFFF',
                      padding: '6px',
                      paddingTop: '8px',
                      borderRadius: '10px',
                      cursor: 'pointer',
                      height: '35px',
                      marginTop: '2px',
                      marginBottom: '5px',
                      flexGrow: 1,
                    }}
                  >
                    {accountDetails && accountDetails.slug ? (
                      <Typography
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          fontSize: '12px',
                          fontWeight: 400,
                          letterSpacing: '-0.43px',
                        }}
                      >
                        https://app.advisorycloud.com/join/advisors/
                        {accountDetails.slug}/{accountDetails.id}
                      </Typography>
                    ) : (
                      ''
                    )}
                  </Box>
                  <ArrowBackIos
                    sx={classes.slideRigthArrow}
                    fontSize="small"
                    onClick={handleClickAway}
                  />
                </>
              )}
            </div>
          </Box>

          <div style={classes.mainInformationContainer}>
            <div style={classes.row}>
              <div style={classes.imageAndBiographyContainer}>
                {isEditingState && !inEditForm ? (
                  <Box sx={classes.imageContainerOwnProfile}>
                    <FilePondCompany
                      id="AOBF-B2B-Freemium-companyLogo"
                      disabled={false}
                      resultFiles={setUploaderOutput}
                      status={setFilePondStatus}
                      uploaderText={null}
                      isSlide
                    />
                  </Box>
                ) : (
                  <Avatar
                    sx={classes.imageContainerOwnProfile}
                    src={getImageSrc()}
                  />
                )}
                <div style={classes.biographyInformationContainer}>
                  <Box
                    sx={{
                      minHeight: '50px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {isEditingState &&
                    (inEditForm == 'displayName' || !inEditForm) ? (
                      <TextField
                        onChange={handleEditChange}
                        name="displayName"
                        height={269}
                        InputProps={{
                          maxLength: 2000,
                          style: {
                            ...classes.fieldContainer,
                            fontSize: '30px',
                            fontWeight: '600',
                            borderRadius: '10px',
                          },
                          inputProps: {
                            style: {
                              padding: '2px 14px',
                            },
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              {renderEditSaveButton('displayName')}
                            </InputAdornment>
                          ),
                        }}
                        value={editValues.displayName}
                      />
                    ) : (
                      <Typography sx={classes.title}>
                        {company.displayName}
                        {renderEditSaveButton('displayName')}
                      </Typography>
                    )}
                  </Box>
                  <Box
                    sx={{
                      minHeight: '35px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {isEditingState &&
                    (inEditForm == 'website' || !inEditForm) ? (
                      <TextField
                        onChange={handleEditChange}
                        name="website"
                        placeholder="Website"
                        height={269}
                        InputProps={{
                          maxLength: 2000,
                          style: {
                            ...classes.fieldContainer,
                            marginTop: '3px',
                            marginBottom: '3px',
                            borderRadius: '10px',
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              {renderEditSaveButton('website')}
                            </InputAdornment>
                          ),
                          inputProps: {
                            style: {
                              padding: '10px 14px',
                            },
                          },
                        }}
                        value={editValues.website}
                      />
                    ) : (
                      <Typography
                        sx={
                          company.website
                            ? classes.headline
                            : classes.emptyHeadline
                        }
                      >
                        {company.website ? company.website : 'Add your website'}
                        {renderEditSaveButton('website')}
                      </Typography>
                    )}
                  </Box>
                  <Box
                    sx={{
                      minHeight: '35px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {isEditingState &&
                    (inEditForm == 'companySize' || !inEditForm) ? (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <AutocompleteCustome
                          parentStyle={{
                            ...classes.fieldContainer,
                            width: '300px',
                          }}
                          // defaultValue={industries}
                          onChange={handleCompanyEmployeesAutocompleteChange}
                          name="companySize"
                          options={employees}
                          placeholder="Company Size"
                          getOptionLabel={renderCompanySizeOption}
                          defaultValue={{
                            id: editValues.companySize,
                            label: editValues.companySize,
                          }}
                          inputStyles={{
                            paddingTop: '1px',
                            paddingBottom: '0px',
                          }}
                          multiple={false}
                        />
                        {renderEditSaveButton('companySize')}
                      </div>
                    ) : (
                      <Typography
                        sx={
                          company.companySize
                            ? classes.headline
                            : classes.emptyHeadline
                        }
                      >
                        {renderCompanySizeOption({
                          label: company.companySize,
                        })}
                        {renderEditSaveButton('companySize')}
                      </Typography>
                    )}
                  </Box>
                  {company.headline ? (
                    <Typography sx={classes.headline}>
                      {company.headline}
                    </Typography>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </Box>
      ) : (
        <Box style={classes.publicProfileHeaderContainer} ref={navRef}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              position: sticky ? 'fixed' : null,
              // width: 'calc(100% - 60px)',
              width: '100%',
              // paddingTop: sticky ? '10px' : 0,
              marginLeft: sticky ? '-20px' : 0,
              backgroundColor: 'white',
              zIndex: 1000,
              height: '60px',
              top: sticky ? (isFreemium ? '50px' : '0') : null,
            }}
          >
            {isEditingState ? (
              <Grid container sx={{ marginTop: '20px', maxWidth: '70%' }}>
                <Grid>
                  <button
                    onClick={sendFormData}
                    style={classes.editContainerMobile}
                  >
                    <CustomIcon
                      iconname="save-outlined"
                      style={classes.editIcon}
                    />
                    <Typography color="primary" sx={classes.editText}>
                      Save
                    </Typography>
                  </button>
                </Grid>
                <Grid>
                  <button
                    onClick={() => {
                      setIsEditingState(false);
                      setInEditForm('');
                    }}
                    style={classes.editContainerMobile}
                  >
                    <CancelRounded sx={classes.editIcon} />
                    <Typography color="primary" sx={classes.editText}>
                      Discard
                    </Typography>
                  </button>
                </Grid>
              </Grid>
            ) : (
              <Grid container sx={{ marginTop: '20px', maxWidth: '100%' }}>
                <Grid>
                  <button onClick={edit} style={classes.editContainerMobile}>
                    <EditOutlined color="primary" sx={classes.editIcon} />
                    <Typography color="primary" sx={classes.editText}>
                      {t('EDIT-BUTTON-TEXT')}
                    </Typography>
                  </button>
                </Grid>
                <Grid>
                  <button
                    onClick={linkedInAction}
                    style={{
                      ...classes.editContainerMobile,
                      marginTop: '-5px',
                    }}
                  >
                    <LinkedInIcon
                      color="primary"
                      style={{
                        marginRight: 10,
                        fontSize: 30,
                      }}
                    />
                    <Typography color="primary" sx={classes.editText}>
                      {t('STEP6-ADVISOR-PROMOTE')}
                    </Typography>
                  </button>
                </Grid>
                <Grid>
                  <RWebShare
                    data={{
                      text: shareContent.text,
                      url: shareContent.link,
                      title: shareContent.title,
                    }}
                  >
                    <button style={classes.editContainerMobile}>
                      <ShareIcon color="primary" style={classes.editIcon} />
                      <Typography color="primary" sx={classes.editText}>
                        Share
                      </Typography>
                    </button>
                  </RWebShare>
                </Grid>
              </Grid>
            )}
            <div
              style={{
                ...classes.profileOptionDrawerContainerWithBarMobile,
                // marginTop: sticky ? '5px' : '50px',
                marginTop: '0px',
              }}
            >
              <ArrowBackIos
                sx={{
                  ...classes.slideRigthArrow,
                  marginLeft: '25px',
                  marginTop: '20px',
                }}
                fontSize="small"
                onClick={handleClickAway}
              />
            </div>
          </Box>

          <Box sx={classes.mobileImageContainer} display="flex">
            {isEditingState && !inEditForm ? (
              <Box sx={{ ...classes.imageContainer, height: '220px' }}>
                <FilePondCompany
                  id="AOBF-B2B-Freemium-companyLogo"
                  disabled={false}
                  resultFiles={setUploaderOutput}
                  status={setFilePondStatus}
                  uploaderText={null}
                  isSlide
                />
              </Box>
            ) : (
              <Avatar sx={classes.imageContainer} src={getImageSrc()} />
            )}
          </Box>
          {isEditingState && (inEditForm == 'displayName' || !inEditForm) ? (
            <Box sx={{ width: '100%' }}>
              <TextField
                onChange={handleEditChange}
                name="displayName"
                height={269}
                sx={{ width: '100%' }}
                InputProps={{
                  maxLength: 2000,
                  style: {
                    ...classes.fieldContainerMobile,
                    marginTop: '25px',
                    borderRadius: '10px',
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      {renderEditSaveButton('displayName')}
                    </InputAdornment>
                  ),
                }}
                value={editValues.displayName}
              />
            </Box>
          ) : (
            <Typography
              sx={{
                ...classes.title,
                paddingTop: '10px',
                paddingBottom: '10px',
              }}
            >
              {company.displayName}
              {renderEditSaveButton('displayName')}
            </Typography>
          )}
          {isEditingState && (inEditForm == 'website' || !inEditForm) ? (
            <div>
              <TextField
                onChange={handleEditChange}
                name="website"
                height={269}
                sx={{ width: '100%' }}
                InputProps={{
                  maxLength: 2000,
                  style: {
                    ...classes.fieldContainerMobile,
                    marginTop: '5px',
                    marginBottom: '5px',
                    borderRadius: '10px',
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      {renderEditSaveButton('website')}
                    </InputAdornment>
                  ),
                }}
                value={editValues.website}
              />
            </div>
          ) : (
            <Typography
              sx={{
                ...classes.headline,
                paddingTop: '10px',
                paddingBottom: '10px',
              }}
            >
              {company.website}
              {renderEditSaveButton('website')}
            </Typography>
          )}

          {isEditingState && (inEditForm === 'companySize' || !inEditForm) ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                // maxWidth: '300px',
              }}
            >
              <Select
                onChange={handleEditChange}
                name="companySize"
                value={editValues.companySize}
                displayEmpty
                sx={{
                  ...classes.fieldContainer,
                  marginTop: '3px',
                  marginBottom: '3px',
                  borderRadius: '10px',
                  width: inEditForm === 'companySize' ? '70%' : '100%',
                }}
              >
                {employees.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {renderCompanySizeOption(option)}
                  </MenuItem>
                ))}
              </Select>
              {renderEditSaveButton('companySize')}
            </div>
          ) : (
            <Typography
              sx={{
                ...classes.headline,
                paddingBottom: '10px',
                paddingTop: '10px',
              }}
            >
              {renderCompanySizeOption({
                label: company.companySize,
              })}
              {renderEditSaveButton('companySize')}
            </Typography>
          )}
          <Box
            onClick={handleCopyLink}
            sx={{
              width: '100%',
              border: '1px solid #EFF0F6',
              backgroundColor: '#FFFFFF',
              padding: '6px',
              paddingTop: '8px',
              borderRadius: '10px',
              cursor: 'pointer',
              height: '35px',
              marginTop: '2px',
              marginBottom: '5px',
              flexGrow: 1,
            }}
          >
            {accountDetails && accountDetails.slug ? (
              <Typography
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  fontSize: '12px',
                  fontWeight: 400,
                  letterSpacing: '-0.43px',
                }}
              >
                https://app.advisorycloud.com/join/advisors/
                {accountDetails.slug}/{accountDetails.id}
              </Typography>
            ) : (
              ''
            )}
          </Box>
          <Box sx={classes.buttonContainer} display="flex">
            {type === AccountType.ADVISOR ? (
              <BoardJoinButton
                boardId={company.id}
                company={company}
                companyCEO={companyCEO}
                staticClass="joinButtonTop"
              />
            ) : null}
          </Box>
        </Box>
      )}
    </div>
  );
}

export default CompanyOwnSlideHeader;
