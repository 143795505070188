import React from 'react';
import CustomIcon from '../../icons/customIcon';

const useStyles = () => ({
  elementsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 1,
    width: 'fit-content',
    height: 'fit-content',
    position: 'relative',
  },

  xsImage: {
    borderRadius: '50%',
    height: '20px',
    width: '20px',
  },
  compactImage: {
    borderRadius: '50%',
    height: '44px',
    width: '44px',
  },
  smallImage: {
    borderRadius: '50%',
    height: '34px',
    width: '34px',
  },
  mediumImage: {
    borderRadius: '50%',
    height: '50px',
    width: '50px',
  },
  adminUserCard: {
    borderRadius: '50%',
    height: '55px',
    width: '55px',
  },
  largeImage: {
    borderRadius: '50%',
    height: '85px',
    width: '85px',
  },
  largeMobileImage: {
    borderRadius: '50%',
    height: '73px',
    width: '73px',
  },
  extraLargeImage: {
    borderRadius: '50%',
    height: '100px',
    width: '100px',
  },
  extraExtraLargeImage: {
    borderRadius: '50%',
    height: '120px',
    width: '120px',
  },

  starButton: {
    borderRadius: '50%',
    background: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 'none',
    border: 'none',
    height: '0px',
    width: '15px',
    left: '-10px',
    top: '-5px',
    cursor: 'pointer',
    position: 'absolute',
  },

  starIconFilled: {
    fontSize: '20px',
    color: '#FFC400',
  },

  starIconEmpty: {
    fontSize: '20px',
    color: '#C9C9DA',
  },
});

export default function ACAvatar(props) {
  const {
    avatarClick,
    image,
    isStar,
    isStarFilled,
    onClick,
    borderColor,
    size,
  } = props;
  const classes = useStyles();

  function sizeSelector() {
    if (size === 'compact') {
      return classes.compactImage;
    }
    if (size === 'xs') {
      return classes.xsImage;
    }
    if (size === 'sm') {
      return classes.smallImage;
    }
    if (size === 'md') {
      return classes.mediumImage;
    }
    if (size === 'adminUserCard') {
      return classes.adminUserCard;
    }
    if (size === 'lg') {
      return classes.largeImage;
    }
    if (size === 'lg-mobile') {
      return classes.largeMobileImage;
    }
    if (size === 'xl') {
      return classes.extraLargeImage;
    }
    if (size == 'xxl') return classes.extraExtraLargeImage;
    return classes.mediumImage;
  }

  function starSelector() {
    if (isStar && isStarFilled) {
      return (
        <CustomIcon
          fontSize="small"
          iconname="star"
          sx={{ color: '#FFC400' }}
          style={{ root: classes.starIconFilled }}
        />
      );
    }
    if (isStar) {
      return (
        <CustomIcon
          fontSize="small"
          iconname="star-outlined"
          sx={{ color: '#C9C9DA' }}
          style={{ root: classes.starIconEmpty }}
        />
      );
    }
    return null;
  }

  return (
    <div style={classes.elementsContainer}>
      {isStar && (
        <button
          type="button"
          style={classes.starButton}
          onClick={(event) => {
            onClick();
            event.stopPropagation();
          }}
        >
          {starSelector()}
        </button>
      )}
      <img
        alt=""
        style={{
          border: borderColor ? `.5px solid ${borderColor}` : 'none',
          cursor: avatarClick ? 'pointer' : 'auto',
          ...sizeSelector(),
        }}
        onClick={avatarClick ? avatarClick : () => {}}
        src={image}
      />
    </div>
  );
}
