import React from 'react';
import { useMediaQuery } from '@mui/material';

import PostPopUp from './../PopUp';

const useStyles = () => ({
  container: {
    width: '100%',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  mainContainer: {
    width: '100%',
  },
});

function PostWrapper(props) {
  const {
    children,
    showPostWrapperContent,
    postWrapperType,
    hidePostWrapperContent,
  } = props;

  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles();

  return (
    <div style={classes.mainContainer}>
      {isSM && showPostWrapperContent ? (
        <div style={classes.container}>
          <PostPopUp
            onChange={() => hidePostWrapperContent(false)}
            type={postWrapperType}
            isMobile
          />
        </div>
      ) : (
        <>{children}</>
      )}
    </div>
  );
}

export default PostWrapper;
