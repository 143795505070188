import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
  Button,
  TextField,
  CircularProgress,
} from '@mui/material';
import { Clear } from '@mui/icons-material';
import ACSnackbar from './../../ACSnackbar';
import eventBus, { eventBusValues } from './../../../eventBus';
import { createConversationMessage } from './../../../services/conversation_services';
import { useAccount } from './../../../contexts/Account';

const useStyles = () => ({
  container: {
    '& :first-child': {
      borderRadius: '10px',
    },
  },
  titleContainer: {
    padding: '0 24px',
    height: '35px',
  },
  title: {
    color: '#232B35',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
  },
  description: {
    color: '#232B35',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
  },
  label: {
    color: '#232B35',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
  },
  cancelButton: {
    textTransform: 'none',
    color: '#646D7A',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
  },
  sendButton: {
    backgroundColor: '#6736FA',
    fontSize: '15px',
    marginRight: '15px',
    borderRadius: '40px',
    fontWeight: 700,
    width: '185px',
    color: '#ffffff',
    textTransform: 'none',
    '&:hover': {
      background: '#6736FA',
    },
    '&:disabled': {
      color: '#ffffff',
      opacity: '0.3',
    },
  },
  loadingContainer: {
    alignItems: 'center',
    bottom: '0px',
    display: 'flex',
    justifyContent: 'center',
    left: '0px',
    position: 'fixed',
    right: '0px',
    top: '0px',
    zIndex: 999,
  },
  clearIconContainer: {
    position: 'absolute',
    cursor: 'pointer',
    right: '13px',
    top: '13px',
    fontSize: '13px',
    color: '#97A3B7',
  },
  clearIcon: {
    fontSize: '23px',
  },
  actionsBox: {
    padding: '20px 8px 28px',
  },
});

const NewConversationPopUp = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [senderId, setSenderId] = useState('');
  const [recipientName, setRecipientName] = useState('');
  const { type, asLoggedUserDetails } = useAccount();
  const [snackBarMessages, setSnackBarMessages] = useState();
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const { isFreemium } = useAccount();

  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);

  const handleOpen = () => {
    setIsOpen(true);
    setMessageText('');
  };

  const handleClose = () => {
    setIsOpen(false);
    setMessageText('');
  };

  const handleSendMessage = () => {
    setIsSaving(true);
    createConversationMessage({
      accountType: type,
      senderId: asLoggedUserDetails.userId,
      receiver: senderId,
      message: messageText,
    }).then(({ data }) => {
      setIsSaving(false);
      setSnackBarMessages(
        `Success: Your message has been sent to ${recipientName}.`
      );
      setIsSnackbarVisible(true);
      handleClose();
    });
  };

  const handleTextChange = (event) => {
    setMessageText(event.target.value);
  };

  useEffect(() => {
    eventBus.on(eventBusValues.onSendConversationNewMessage, (data) => {
      setSenderId(data.senderId);
      setRecipientName(data.senderName);
      if (!isOpen) {
        handleOpen();
      }
    });

    return () => {
      eventBus.remove(eventBusValues.onSendConversationNewMessage);
    };
  }, []);

  const isSendMessageDisabled = messageText.trim().length === 0;

  return (
    <>
      <ACSnackbar
        text={snackBarMessages}
        onClose={() => setIsSnackbarVisible(false)}
        open={isSnackbarVisible}
        severity="success"
        variant="filled"
        style={{ marginTop: isFreemium ? '50px' : '0px' }}
      />
      <Dialog
        open={isOpen}
        onClose={handleClose}
        PaperProps={{ style: { borderRadius: '10px', maxWidth: '600px' } }}
      >
        <DialogTitle sx={classes.titleContainer}>
          <p style={classes.title}>Send a Message</p>
          <div style={classes.clearIconContainer}>
            <Clear sx={classes.clearIcon} onClick={handleClose} />
          </div>
        </DialogTitle>
        <DialogContent>
          <p style={classes.description}>
            Send {recipientName} a message. A new conversation will appear in
            Messages and you'll be notified when you receive a response.
          </p>
          <p style={classes.label}>Your message</p>
          <div id="messageModalText">
            <TextField
              multiline
              minRows={5}
              value={messageText}
              onChange={handleTextChange}
              variant="outlined"
              placeholder={`Since this is your first message to ${recipientName}, be sure to include the reason why you're reaching out.`}
              fullWidth
              sx={{
                ...classes.container,
                background: '#FFFFFF',
                border: '1px solid #EFF0F7',
                borderRadius: '10px',
              }}
              inputProps={{
                style: {
                  color: '#232B35',
                  fontFamily: 'Poppins',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: '14px',
                  lineHeight: '24px',
                  letterSpacing: '-0.43px',
                },
              }}
            />
          </div>
        </DialogContent>
        <DialogActions sx={classes.actionsBox}>
          <Button
            id="messageModalCancelButton"
            onClick={handleClose}
            sx={classes.cancelButton}
          >
            Cancel
          </Button>
          <Button
            id="messageModalSendButton"
            onClick={handleSendMessage}
            disabled={isSendMessageDisabled || isSaving}
            sx={classes.sendButton}
          >
            Send Message
            {isSaving && (
              <CircularProgress
                size={30}
                sx={{ position: 'absolute', color: 'white' }}
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default NewConversationPopUp;
