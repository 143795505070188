import React, { useEffect, useState } from 'react';
import { Backdrop, useMediaQuery } from '@mui/material';
import ACAvatar from './../../ACAvatar';
import DefaultAvatar from './../../../icons/user-avatar.svg';
import { ReactComponent as ExpandCircleIcon } from './expand-circle-down.svg';
import Menu from './Menu';
import eventBus, { eventBusValues } from './../../../eventBus';
import { useAccount } from './../../../contexts/Account';
import CustomIcon from './../../../icons/customIcon';

const useStyles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '40px',
    height: '50px',
    border: 'none',
    marginLeft: '15px',
    backgroundColor: 'transparent',
    cursor: 'pointer',
  },
  backDrop: {
    zIndex: 0,
    backgroundColor: 'transparent',
  },
  image: {
    width: '35px',
    height: '35px',
    borderRadius: '35px',
  },
  icon: {
    color: '#232B35',
    minWidth: '20px',
    minHeight: '20px',
    marginRight: '3px',
  },
});

function AdvisorAvatarMenuButton({
  onAvatarMenuIconCLick,
  image,
  handleAddCompanyContact,
}) {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [drawerIsOnEditingMode, setDrawerIsOnEditingMode] = useState(false);
  const classes = useStyles();
  const { accountDetails, asLoggedUserDetails } = useAccount();
  let userInfo = accountDetails.contacts.find(
    (contact) => contact.id === asLoggedUserDetails.userId
  );

  function handleButtonClick() {
    if (!isSM) {
      if (!drawerIsOnEditingMode) {
        eventBus.dispatch(eventBusValues.closeAdvisorDrawer);
        setIsMenuVisible((prevValue) => {
          return !prevValue;
        });
      } else {
        eventBus.dispatch(eventBusValues.drawerTryToCloseOnEdit);
      }
    }
  }

  function renderIcon() {
    if (isSM) {
      return null;
    }
    return <ExpandCircleIcon style={classes.icon} />;
  }

  function renderMenu() {
    if (isMenuVisible && !isSM) {
      return (
        <Backdrop
          onClick={() => setIsMenuVisible(false)}
          sx={classes.backDrop}
          open={true}
        >
          <Menu
            handleAddCompanyContact={handleAddCompanyContact}
            hideMenu={() => setIsMenuVisible(false)}
          />
        </Backdrop>
      );
    }

    return null;
  }

  useEffect(() => {
    eventBus.on(eventBusValues.triggerCloseBlocker, (data) => {
      setDrawerIsOnEditingMode(data);
    });

    return () => {
      eventBus.remove(eventBusValues.triggerCloseBlocker);
    };
  }, []);

  return (
    <>
      <button
        id="b2bNavatarDropDown"
        style={classes.container}
        type="button"
        onClick={() => {
          if (!isSM) {
            handleButtonClick();
          } else {
            onAvatarMenuIconCLick();
          }
        }}
        // onMouseOver={() => {
        //   if (!isSM) {
        //     handleButtonClick();
        //   }
        // }}
      >
        {renderIcon()}
        {isSM ? (
          <CustomIcon iconname="hamburger" style={{ fontSize: 20, width: '3em' }} />
        ) : (
          <ACAvatar
            image={
              userInfo.image && !userInfo.image.location.endsWith('undefined')
                ? `https://${userInfo.image.location}`
                : DefaultAvatar
            }
            size="md"
            avatarClick={() => {}}
          />
        )}
      </button>

      {renderMenu()}
    </>
  );
}

export default AdvisorAvatarMenuButton;
