import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Typography, List, ListItem, ListItemIcon, Paper } from '@mui/material';
import ACButton from '../ACButton';
import ACSnackbar from '../ACSnackbar';
import Password from '../PasswordSet/PasswordsContainer';
import { CheckCircle } from '@mui/icons-material';
import {
  forgotPasswordSubmit,
  forgotPassword,
} from '../../services/Authentication';
import { Transitions } from '../../services/Transition';
import { ForgotPasswordResponses } from '../../services/Authentication/constants';

const useStyles = () => ({
  contentContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  passwordContainer: {
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  buttonContainer: {
    marginTop: '25px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  label: {
    color: 'text.primary',
    fontSize: '16px',
    fontWeight: 600,
    fontFamily: 'Poppins',
  },
  invalidIcon: {
    fontSize: '20px',
  },
  tooltipText: {
    marginTop: '10px',
    fontWeight: 500,
    fontSize: '15px',
    letterSpacing: '-0.4300000071525574px',
  },
  tooltipBodyText: {
    fontSize: '13px',
    letterSpacing: '-0.4300000071525574px',
  },
  tooltipItemIcon: {
    fontSize: '5px',
    marginLeft: '-15px',
    marginRight: '-25px',
  },
  listItemContainer: {
    alignContent: 'center',
  },
  validIcon: {
    fontSize: '20px',
    color: 'primary.main',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    borderRadius: '10px',
  },
  resetButton: {
    textTransform: 'none',
    fontFamily: 'Poppins',
    fontWeight: '700',
    fontSize: '16px',
    height: '45px',
    width: '225px',
    lineHeight: '24px',
    borderRadius: '40px',
    color: 'white',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#3171F6',
    },
  },
});

const PasswordResetForm = (props) => {
  const { errorType } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email');
  const code = queryParams.get('code');
  const [errorSnackbar, setErrorSnackbar] = useState(false);
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const validations = useMemo(
    () => [
      {
        criteria: (v1 = '') => v1.length >= 8,
        labelTranslationKey: 'PASSWORD-8-CHAR-MIN-ERROR-MESSAGE',
      },
      {
        criteria: (v1 = '') => v1.match(`[A-Z]`) && v1.match(`[a-z]`),
        labelTranslationKey:
          'PASSWORD-MUST-CONTAIN-UPPER-AND-LOWER-CASE-LETTER-ERROR-MESSAGE',
      },
      {
        criteria: (v1 = '') => v1.match(`[0-9]`),
        labelTranslationKey: 'PASSWORD-MUST-CONTAIN-NUMBER-ERROR-MESSAGE',
      },
      {
        criteria: (v1 = '') => v1.match(`[@,#,%,^,*,(,),!,?,~,$,&]`),
        labelTranslationKey:
          'PASSWORD-MUST-CONTAIN-SPECIAL-CHARACTER-ERROR-MESSAGE',
      },
    ],
    []
  );

  async function handleRequest() {
      setLoading(true);
      const newPasswordSubmit = await forgotPasswordSubmit(
        email,
        code,
        password
      );
      setLoading(false);

      if (newPasswordSubmit === ForgotPasswordResponses.SUCCESS) {
        props.history.push({
          pathname: '/login',
          state: {
            passwordUpdated: Transitions.Types.PASSWORD_UPDATED,
          },
        });
      } else if (
        newPasswordSubmit === ForgotPasswordResponses.CODE_MISMATCH_EXCEPTION ||
        newPasswordSubmit === ForgotPasswordResponses.EXPIRED_CODE_EXCEPTION
      ) {
        forgotPassword(email);
        errorType(Transitions.Types.EXPIRED_CODE);
      } else {
        errorType(Transitions.Types.FAIL_CODE);
      }
    
  }

  useEffect(() => {
    const HasError =
      validations.filter((validation) => !validation.criteria(password))
        .length > 0;

    setHasError(HasError);
  }, [password]);

  function tooltipGenerator(evaluatedPassword) {
    return (
      <>
        <Typography sx={classes.tooltipText} color="inherit">
          Password must include:
        </Typography>
        <List dense>
          {validations.map((validation) => {
            const valid = validation.criteria(evaluatedPassword);
            return (
              <ListItem sx={classes.listItemContainer}>
                <ListItemIcon sx={classes.tooltipItemIcon}>
                  <CheckCircle
                    sx={valid ? classes.validIcon : classes.invalidIcon}
                  />
                </ListItemIcon>
                <Typography sx={classes.tooltipBodyText} color="inherit">
                  {t(validation.labelTranslationKey)}
                </Typography>
              </ListItem>
            );
          })}
        </List>
      </>
    );
  }

  const textWasEntered = (data) => {
    if (data.id === 'password') {
      setPassword(data.content);
    }
  };

  const passwordLabelText = () => {
    return <Typography sx={classes.label}>{t('NEW_PASSWORD')}</Typography>;
  };

  return (
    <Paper sx={classes.formContainer} elevation={0}>
      <ACSnackbar
        open={errorSnackbar}
        text={t('PASSWORD_VALIDATION_MATCH')}
        severity="error"
        onClose={() => setErrorSnackbar(false)}
        autoHideDuration={6000}
      />
      <div style={classes.contentContainer}>
        <div style={classes.passwordContainer}>
          <Password
            onKeyUpData={textWasEntered}
            passwordLabel={passwordLabelText()}
            width="100%"
            passwordTooltip={tooltipGenerator(password)}
            validations={validations}
            setPasswordForValidation={setPassword}
          />

          <div style={classes.buttonContainer}>
            <ACButton
              type="submit"
              color="primary"
              onClick={() => handleRequest()}
              disabled={hasError}
              loading={loading ? 1 : 0}
              style={classes.resetButton}
            >
              Reset
            </ACButton>
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default withRouter(PasswordResetForm);
