import React from 'react';
import { IconButton, Typography } from '@mui/material';
import CustomIcon from '../../../icons/customIcon';

const useStyles = () => ({
  root: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginTop: '20px',
  },
  iconButton: {
    margin: '0 5px 0 10px',
  },
  iconAndText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonActionTitle: {
    margin: '0 10px',
    fontSize: '15px',
    fontWeight: 600,
  },
});

const ActionButtons = (props) => {
  const { companyBoardData } = props;
  const classes = useStyles();

  function openProfile(event) {
    console.log(event);
  }
  function sendToWebsite(event) {
    event.stopPropagation();
    window.open(companyBoardData.website);
  }

  return (
    <div style={classes.root}>
      <div style={classes.iconAndText}>
        <IconButton sx={classes.iconButton} onClick={openProfile}>
          <CustomIcon
            iconname="join_page"
            style={{
              color: '#232B35',
              fontSize: 16,
              height: 16,
              width: 16,
            }}
          />
        </IconButton>
        <Typography variant="old_font" sx={classes.buttonActionTitle}>
          Profile
        </Typography>
      </div>
      {companyBoardData && companyBoardData.website && (
        <div style={classes.iconAndText}>
          <IconButton sx={classes.iconButton} onClick={sendToWebsite}>
            <CustomIcon
              iconname="company_website"
              style={{
                color: '#232B35',
                fontSize: 16,
                height: 16,
                width: 16,
              }}
            />
          </IconButton>
          <Typography variant="old_font" sx={classes.buttonActionTitle}>
            Website
          </Typography>
        </div>
      )}
    </div>
  );
};

export default ActionButtons;
