import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import ACSnackbar from './../../ACSnackbar';
import PostResponseReply from './../../Post/ResponseReply';
import {
  authorize,
  getAccessToken,
  isLinkedinAuthenticated,
} from './../../../services/LinkedIn';
import LinkedInPostModal from './../../LinkedInPostModal';
import { useAccount } from './../../../contexts/Account';

function DashboardAdvisorContent(props) {
  const { postsList, isMainCallFetching, incrementOffset, addPostLike } = props;
  const [singlePostMode, setSinglePostMode] = useState(false);
  const [successSnackbar, setSuccessSnackbar] = useState(false);
  const [errorSnackbar, setErrorSnackbar] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [linkedInPostModal, setLinkedInPostModal] = useState(false);
  const { isFreemium } = useAccount();

  function SinglePostChangedCallback(isSinglePost) {
    setSinglePostMode(isSinglePost);
  }

  async function linkedInSign() {
    const isAuthenticatedLinkedIn = isLinkedinAuthenticated();
    if (isAuthenticatedLinkedIn) {
      setLinkedInPostModal(true);
    } else {
      await authorize();
    }
  }

  async function linkedInResponse(linkedinResponse) {
    if (linkedinResponse === 201) {
      setSuccessMessage('Your profile is shared successfully.');
      setSuccessSnackbar(true);
    } else if (linkedinResponse === 422) {
      setErrorMessage(
        'You have already posted this content, either update the post or try again in 10 minutes'
      );
      setErrorSnackbar(true);
    } else {
      setErrorMessage('An error occurred while sharing your profile.');
      setErrorSnackbar(true);
    }
  }

  function checkIfLinkedinCode() {
    const parsed = queryString.parse(window.location.search);
    if (parsed.code && !isLinkedinAuthenticated()) {
      if (!isLinkedinAuthenticated()) {
        getAccessToken(parsed).then((response) => {
          if (response.data && response.data.getLinkedinAccessToken) {
            linkedInSign();
          } else {
            setErrorMessage('An error occurred while sharing your profile.');
            setErrorSnackbar(true);
          }
        });
      } else {
        linkedInSign();
      }
    }
  }

  useEffect(() => {
    checkIfLinkedinCode();
  }, []);

  return (
    <>
      <ACSnackbar
        open={successSnackbar}
        text={successMessage}
        severity="success"
        onClose={() => setSuccessSnackbar(false)}
        autoHideDuration={6000}
        style={{ marginTop: isFreemium ? '50px' : '0px' }}
      />
      <ACSnackbar
        open={errorSnackbar}
        text={errorMessage}
        severity="error"
        onClose={() => setErrorSnackbar(false)}
        autoHideDuration={6000}
        style={{ marginTop: isFreemium ? '50px' : '0px' }}
      />

      <PostResponseReply
        gotPostList={postsList}
        onSinglePostChanged={SinglePostChangedCallback}
        showHelperCard={postsList.length > 0}
        multiplePosts
        isMainCallLoading={isMainCallFetching}
        incrementOffset={incrementOffset}
        addPostLike={addPostLike}
        dashboardOrigin
      />
      {linkedInPostModal ? (
        <>
          <LinkedInPostModal
            linkedInPostModal={linkedInPostModal}
            setLinkedInPostModal={setLinkedInPostModal}
            linkedInResponse={linkedInResponse}
            shareProfile
          />
        </>
      ) : null}
    </>
  );
}

export default DashboardAdvisorContent;
