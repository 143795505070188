import React from 'react';
import { Typography } from '@mui/material';
import ACAvatar from './../../ACAvatar';
import DefaultCompany from './../../../icons/building_round.svg';

const useStyles = () => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: '20px',
    marginBottom: '10px',
    alignItems: 'center',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '10px',
    marginLeft: '10px',
  },
  contactText: {
    fontSize: '16px',
    fontWeight: 600,
    color: '#232B35',
  },
  joinedText: {
    fontSize: '15px',
    fontWeight: 500,
    color: '#646D7A',
  },
  imageContainer: {
    width: '40px',
    height: '40px',
  },
});

function CompanyContact(props) {
  const { contact, customAvatar } = props;
  const classes = useStyles();

  return (
    <div
      className={`${contact.isBoardOwner ? 'boardOwnerBlock' : ''}`}
      style={classes.mainContainer}
    >
      {customAvatar ? (
        customAvatar()
      ) : (
        <ACAvatar
          size="lg"
          image={contact.contactLogoLocation || DefaultCompany}
          style={classes.imageContainer}
        />
      )}
      <div style={classes.textContainer}>
        <Typography sx={classes.contactText}>{contact.displayName}</Typography>
        <Typography sx={classes.joinedText}>{contact.title}</Typography>
      </div>
    </div>
  );
}

export default CompanyContact;
