import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMediaQuery, Paper, Typography } from '@mui/material';
import MarkEmailUnreadOutlinedIcon from '@mui/icons-material/MarkEmailUnreadOutlined';
import AdvisorEmailChange from './advisorEmailChange/advisorEmailChange';
import AdvisorPasswordChange from './advisorPasswordChange';
import DecoratedButton from './decoratedButton';
import { UpdateAttributesFlow } from '../../../../services/Transition';
import { useAccount } from '../../../../contexts/Account';
import { forgotPassword } from '../../../../services/Authentication';
import { ForgotPasswordResponses } from '../../../../services/Authentication/constants';
import { AccountType } from '../../../../services/utils/types';
import ACSnackbar from '../../../ACSnackbar';

const useStyles = (isSM) => ({
  card: {
    width: '100%',
    backgroundColor: 'white',
    borderRadius: '10px',
    paddingTop: '25px',
    paddingLeft: isSM ? '15px' : '38px',
    paddingRight: isSM ? '15px' : '38px',
    paddingBottom: isSM ? '15px' : '35px',
    border: 'solid 1px #F0F1F3',
  },
  iconContainer: {
    width: isSM ? '0px' : '7%',
    paddingTop: '4px',
  },
  text: {
    fontWeight: 'bold',
    width: '93%',
    fontSize: '14px',
  },
  topRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'none',
    outline: 'none',
    padding: '0px',
    '&:hover': { backgroundColor: 'transparent' },
  },
  actionsTable: {
    width: '100%',
  },
  bottomRow: {
    display: 'flex',
    width: '100%',
    marginTop: '15px',
    flexGrow: 1,
  },
  initialEmail: {
    wordBreak: 'break-all',
  },
  actionCell: {
    height: isSM ? '60px' : '30px',
    width: isSM ? '100%' : '30%',
    textAlign: 'center',
    color: 'primary.main',
    cursor: 'pointer',
    fontSize: '14px',
  },
});

const CardScene = (props) => {
  const {
    flowType,
    title,
    buttonIcon,
    buttonText,
    subtitle,
    changeAccountTypeLoader,
  } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const [flowContent, setFlowContent] = useState(UpdateAttributesFlow.DEFAULT);
  const [isLoading, setIsLoading] = useState(false);
  const { accountDetails, switchToAccountType, isFreemium } = useAccount();
  const history = useHistory();
  const [
    successfullRequestChangePassword,
    setSuccessfullRequestChangePassword,
  ] = useState(false);
  const [snackBarMessage, setSnackbarMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarStyle, setSnackbarStyle] = useState('error');

  async function cognitoResetPassword() {
    setIsLoading(true);
    try {
      const forgotPasswordResult = await forgotPassword(accountDetails.email);

      if (forgotPasswordResult === ForgotPasswordResponses.SUCCESS) {
        setIsLoading(false);
        setFlowContent(UpdateAttributesFlow.CHANGE_PASSWORD);
      } else {
        setSuccessfullRequestChangePassword(false);
        setIsLoading(false);
      }
    } catch (err) {
      setSuccessfullRequestChangePassword(false);
    }
  }

  function setFlow() {
    if (flowType === UpdateAttributesFlow.CHANGE_EMAIL) {
      setFlowContent(UpdateAttributesFlow.CHANGE_EMAIL);
    }
    if (flowType === UpdateAttributesFlow.CHANGE_PASSWORD) {
      cognitoResetPassword();
    }
    if (flowType === UpdateAttributesFlow.CHANGE_ACCOUNT_TYPE) {
      changeAccountTypeLoader(true);
      switchToAccountType(AccountType.COMPANY).then(() => {
        history.push('/dashboard');
      });
    }
  }

  function emailChangeCallback(message) {
    setSnackbarMessage(message.slice(message.indexOf(':') + 1, message.length));
    setOpenSnackbar(true);
  }

  function resetFlowEmail() {
    setFlowContent(UpdateAttributesFlow.DEFAULT);
    setSuccessfullRequestChangePassword(false);
  }

  function finishFlowSuccesfully() {
    setFlowContent(UpdateAttributesFlow.DEFAULT);
    setSnackbarStyle('success');
  }

  function contentSelection() {
    if (flowContent === UpdateAttributesFlow.DEFAULT) {
      return <DefaultContent />;
    }
    if (flowContent === UpdateAttributesFlow.CHANGE_EMAIL) {
      return (
        <AdvisorEmailChange
          title={title}
          initialEmail={accountDetails.email}
          resetFlowEmail={resetFlowEmail}
          isCallSuccessfull={successfullRequestChangePassword}
          changeSuccessfullCall={setSuccessfullRequestChangePassword}
          finishFlow={finishFlowSuccesfully}
          actionCallback={emailChangeCallback}
        />
      );
    }
    if (flowContent === UpdateAttributesFlow.CHANGE_PASSWORD) {
      return (
        <AdvisorPasswordChange
          title={title}
          finishFlow={finishFlowSuccesfully}
        />
      );
    }
    return <></>;
  }

  const DefaultContent = () => {
    return (
      <section>
        <div style={classes.topRow}>
          <div style={classes.iconContainer}>
            <MarkEmailUnreadOutlinedIcon icconname="key-outlined" />
          </div>
          <Typography variant="h1" sx={classes.text}>
            {title}
          </Typography>
        </div>
        <div style={classes.bottomRow}>
          <div style={classes.iconContainer} />
          <table style={classes.actionsTable}>
            <tbody>
              <tr
                style={isSM ? { display: 'flex', flexDirection: 'column' } : {}}
              >
                <td>{subtitle}</td>
                <td
                  style={{
                    ...classes.actionCell,
                    ...(isSM ? { display: 'flex', justifyContent: 'end' } : {}),
                  }}
                >
                  <DecoratedButton
                    buttonIcon={buttonIcon}
                    buttonText={buttonText}
                    clickAction={() => setFlow()}
                    loading={isLoading}
                    customFont="Poppins"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    );
  };

  return (
    <>
      <Paper elevation={0} sx={classes.card}>
        {contentSelection()}
      </Paper>
      <ACSnackbar
        text={snackBarMessage}
        onClose={() => setOpenSnackbar(false)}
        open={openSnackbar}
        severity={snackbarStyle}
        style={{ marginTop: isFreemium ? '50px' : '0px' }}
      />
    </>
  );
};

export default CardScene;
