import React from 'react';
import { Box, Button, Modal, Typography, useMediaQuery } from '@mui/material';
import { PostType } from '../../services/utils/types';

const useStyles = (isSM) => ({
  container: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    top: '46%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isSM ? '95%' : '485px',
    height: isSM ? '340px' : '310px',
    background: '#fff',
    boxShadow: '1px 2px 5px #bebebe',
    border: 'solid 1px #E6EDFE',
    borderRadius: '15px',
    padding: isSM ? '0' : '0 5px',
  },
  mainMessageTitle: {
    textAlign: 'center',
    fontSize: '20px',
    color: '#232B35',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    paddingTop: '50px',
    marginBottom: '24px',
  },
  mainMessage: {
    textAlign: 'center',
    color: '#232B35',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    padding: isSM ? '0px 20px' : '0px 50px',
    marginBottom: '24px',
  },
  mainButton: {
    width: '218px',
    borderRadius: '40px',
    backgroundColor: '#3171F6',
    textTransform: 'none',
    height: '45px',
    margin: '5px',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '15px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    color: '#FFFFFF',
    padding: '5px',
    '&:hover': {
      backgroundColor: '#3171F6',
    },
  },
});

export default function ResponseSubmitedModal({ open, setOpen, type }) {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);

  function handleClose() {
    setOpen(false);
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <Box sx={classes.container}>
        <Typography style={classes.mainMessageTitle}>
          Success, your {type === PostType.UPDATE ? 'comment' : 'answer'} has
          been submitted.
        </Typography>
        <Typography sx={classes.mainMessage}>
          Please do not expect a response, however we guarantee your message
          will be shared and you may receive a request to speak at an upcoming
          board meeting to discuss further.
        </Typography>
        <Button
          color="primary"
          size="large"
          variant="contained"
          sx={classes.mainButton}
          onClick={handleClose}
        >
          Continue
        </Button>
      </Box>
    </Modal>
  );
}
