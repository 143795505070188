import React from 'react';
import { Box, Modal, useMediaQuery } from '@mui/material';

const useStyles = (isSM) => ({
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isSM ? '100%' : '637px',
    height: isSM ? '235px' : '355px',
    background: '#fff',
    boxShadow: '1px 2px 5px #bebebe',
    border: 'solid 1px #E6EDFE',
    p: 4,
  },
});

export default function DemoVideoModal({ loginModal, setLoginModal }) {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);

  function handleClose() {
    setLoginModal(false);
  }

  return (
    <div>
      <Modal
        open={loginModal}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <Box sx={classes.container}>
          <iframe
            src="https://player.vimeo.com/video/663152857?h=4c05a54fcf&amp;wmode=opaque"
            title="demoVideo"
            allow="autoplay; fullscreen; picture-in-picture"
            width="100%"
            height="100%"
          />
        </Box>
      </Modal>
    </div>
  );
}
