import React from 'react';
import { Fab } from '@mui/material';

function ConversationNotification(props) {
  const { value, styleProps } = props;

  return value > 0 ? (
    <Fab
      size="small"
      color="secondary"
      role="none"
      style={{
        height: '22px',
        width: '22px',
        minWidth: '22px',
        minHeight: '22px',
        position: 'relative',
        top: '-12px',
        right: '10px',
        color: 'white',
        fontSize: '10px',
        fontWeight: 'bold',
        paddingTop: '2px',
        boxShadow: 'none',
        backgroundColor: 'red',
        ...styleProps,
      }}
    >
      {value < 100 ? value : '+99'}
    </Fab>
  ) : null;
}

export default ConversationNotification;
