import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Typography, Grid, useMediaQuery } from '@mui/material';
import ACButton from '../../components/ACButton';
import CardScene from '../../components/CardScene';

const useStyles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  scheduleOnceContainer: {
    width: '75%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  text: {
    textAlign: 'center',
    color: 'primary.main',
  },
  textSecondary: {
    textAlign: 'center',
  },
  textSecondaryCountdown: {
    textAlign: 'center',
    color: 'primary.main',
  },
  textOr: {
    textAlign: 'center',
    color: 'secondary.dark',
  },

  buttonGroup: {
    marginTop: '20px',
    paddingBottom: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

function CallWindow(props) {
  const { isOnboarding } = props;
  const { t } = useTranslation();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const [showCountdown, setShowCountdown] = useState(false);
  const [seconds, setSeconds] = useState(3);
  const [errorStatus, setErrorStatus] = useState(1);

  const RenderHeader = () => {
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        direction="column"
        spacing={2}
      >
        <Grid item>
          <Typography variant="h5_old_font" sx={classes.text}>
            {isOnboarding ? t('CALL_WELCOME') : t('CALL_UPGRADE')}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="old_font" sx={classes.textSecondary}>
            {isOnboarding ? t('CALL_SCHEDULE') : t('CALL_UPGRADE_SCHEDULE')}
          </Typography>
        </Grid>
        {showCountdown ? (
          <Grid item>
            <Typography variant="old_font" sx={classes.textSecondaryCountdown}>
              {t('CALL_COUNTDOWN', { seconds })}
            </Typography>
          </Grid>
        ) : null}
      </Grid>
    );
  };

  const RenderFooter = () => {
    return (
      <div style={classes.buttonGroup}>
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          spacing={2}
        >
          <Grid item>
            <Typography variant="old_font" sx={classes.textOr}>
              {t('CALL_OR')}
            </Typography>
          </Grid>
          <Grid item>
            <ACButton
              width="250px"
              size="small"
              type="submit"
              color="primary"
              onClick={() => {
                props.history.push('/company/onboarding/step1');
              }}
            >
              {t('CALL_GET_STARTED')}
            </ACButton>
          </Grid>
        </Grid>
      </div>
    );
  };

  function startCountdown() {
    let timer = 2;
    const countDown = setInterval(() => {
      if (timer > 0) {
        setSeconds(timer);
        timer -= 1;
      } else {
        clearInterval(countDown);
        props.history.push('/company/onboarding/step1');
      }
    }, 1000);
  }

  useEffect(() => {
    const widget = document.createElement('script');
    widget.type = 'text/javascript';
    widget.src = 'https://cdn.oncehub.com/mergedjs/so.js';

    document
      .getElementById(process.env.REACT_APP_SCHEDULE_ONCE_ELEMENT_ID)
      .appendChild(widget);

    return () => {
      const scheduleOnceScript = document.getElementById(
        process.env.REACT_APP_SCHEDULE_ONCE_ELEMENT_ID
      );
      if (scheduleOnceScript) {
        scheduleOnceScript.parentNode.removeChild(scheduleOnceScript);
      }
    };
  }, []);

  useEffect(() => {
    function checkExist() {
      const countDown = setInterval(() => {
        if (document.body.contains(document.getElementById('callbackData'))) {
          clearInterval(countDown);
          const extracted = document.getElementById('callbackData').innerHTML;
          setErrorStatus(extracted);
        }
      }, 1000);
    }
    checkExist();
  }, []);

  useEffect(() => {
    const result = document.getElementById('callbackData');
    if (errorStatus === '0') {
      setShowCountdown(true);
      startCountdown();
      result.parentNode.removeChild(result);
    }
  }, [errorStatus]);

  return (
    <div style={classes.container}>
      <Helmet>
        <title>{t('CALL_TITLE')}</title>
        <script>
          {`function SOAfterConfirmationFunction(data) {
            var element = document.createElement('p')
            element.setAttribute("id", "callbackData")
            element.setAttribute("hidden", "true")
            element.innerHTML = data.errorStatus
            document.body.appendChild(element)
          }`}
        </script>
      </Helmet>
      <div style={classes.scheduleOnceContainer}>
        <CardScene header={<RenderHeader />} footer={<RenderFooter />}>
          <div
            id={process.env.REACT_APP_SCHEDULE_ONCE_ELEMENT_ID}
            data-so-page={process.env.REACT_APP_SCHEDULE_ONCE_PAGE}
            data-height="550"
            data-style="border: 1px solid #d8d8d8; max-width: 900px;"
            data-psz="11"
          />
        </CardScene>
      </div>
    </div>
  );
}

export default CallWindow;
