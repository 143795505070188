import React, { useCallback } from 'react';
import { Icon } from '@mui/material';
import '../font-icons/icons.css';

const classes = {
  iconOverflow: {
    overflow: 'visible',
  },
};
export default function CustomIcon(props) {
  const { iconname, onMount, id } = props;

  const handleReference = useCallback((ref) => {
    if (onMount) {
      const data = { ref };

      if (id) {
        data.id = id;
      }

      onMount(data);
    }
  }, []);

  return (
    <Icon
      ref={handleReference}
      style={{ ...classes.iconOverflow, ...props.sx }}
      className={`ac-icon-${iconname}`}
      {...props}
    />
  );
}
