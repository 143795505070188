import React from 'react';
import { Typography } from '@mui/material';
import CustomIcon from './../../../../icons/customIcon';

const useStyles = () => ({
  mainConatiner: {
    display: 'flex',
    flexDirection: 'row',
    flexShrink: 0,
  },
  shrinkControlContainer: {
    flexShrink: 0,
  },
  button: {
    border: 'none',
    display: 'flex',
    outline: 'none',
    backgroundColor: 'transparent',
    flexShrink: 0,
    padding: '0px',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    width: 'fit-content',
    '&:disabled': {
      cursor: 'default',
    },
  },
  likesText: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    marginTop: '4px',
    color: '#232b35',
  },
  likesTextMobile: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    color: '#232b35',
    fontSize: '0.7rem',
  },
});

function LikeComponent(props) {
  const classes = useStyles();
  const { numLikes, onClick, iconSize, filled, disabled, isFirst } = props;

  const unsetNumber =
    numLikes === null || numLikes === undefined || isNaN(numLikes);

  function singularPlural(number) {
    if (number === 1) {
      return 'Like';
    }
    return 'Likes';
  }

  function renderLikes() {
    if (unsetNumber && iconSize === 'xs') {
      return (
        <Typography variant="caption" sx={classes.likesTextMobile}>
          0 Likes
        </Typography>
      );
    }
    if (unsetNumber) {
      return (
        <Typography variant="body1" sx={classes.likesText}>
          0 Likes
        </Typography>
      );
    }
    if (!unsetNumber && iconSize === 'xs') {
      return (
        <Typography variant="caption" sx={classes.likesTextMobile}>
          {`${numLikes} ${singularPlural(numLikes)}`}
        </Typography>
      );
    }
    if (!unsetNumber) {
      return (
        <Typography variant="body1" sx={classes.likesText}>
          {`${numLikes} ${singularPlural(numLikes)}`}
        </Typography>
      );
    }
    return null;
  }

  function sizeSelector() {
    if (iconSize === 'xs') {
      return '15px';
    }
    if (iconSize === 'sm') {
      return '19px';
    }
    if (iconSize === 'lg') {
      return '24px';
    }
    return '19px';
  }

  function renderIcon() {
    if (filled) {
      return (
        <CustomIcon
          iconname="like"
          style={{
            color: '#1663F7',
            fontSize: sizeSelector(),
            marginRight: '10px',
            height: '26px',
          }}
        />
      );
    }
    return (
      <CustomIcon
        iconname="like"
        style={{
          color: '#232B35',
          fontSize: sizeSelector(),
          marginRight: '10px',
          height: '26px',
        }}
      />
    );
  }

  return (
    <div style={classes.mainConatiner}>
      <div style={classes.shrinkControlContainer}>
        <button
          className={isFirst ? 'boardroomFeaturedLike' : ''}
          style={classes.button}
          onClick={() => onClick()}
          id="likeButton"
          type="button"
          disabled={disabled}
        >
          {renderIcon()}
          {renderLikes()}
        </button>
      </div>
    </div>
  );
}

export default LikeComponent;
