import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import LoadingIndicatorLogo from '../LoadingIndicatorLogo';
import ACSnackbar from '../ACSnackbar';
import { isNil } from '../../services/utils';
import MinimumDisplayTime from './minimumDisplayTime';
import Treatment from '../Treatment';
import { featureFlags } from '../../splitSetup';
import { useAccount } from '../../contexts/Account';

const classes = {
  loadingIndicatorPosition: {
    alignItems: 'center',
    bottom: '0px',
    display: 'flex',
    justifyContent: 'center',
    left: '0px',
    position: 'fixed',
    right: '0px',
    top: '50%',
  },
  circularProgressPosition: {
    position: 'relative',
    margin: 'auto',
    width: '40px',
    height: '40px',
  },
};

function EndpointCaller(props) {
  const {
    endPointCallObjectArray,
    onEndpointCallsFinished,
    executeCalls,
    loadingIconType,
    doNotFetch,
    isCardSkeleton,
    isBoardSekeleton,
    isDashboardSkeleton,
  } = props;

  const { t } = useTranslation();
  const location = useLocation();
  const { currentUserInfo } = useAccount();
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [errorSnackbarMessage, setErrorSnackbarMessage] = useState(
    t('SOMETHING_WENT_WRONG')
  );
  const [loadingFirstTime, setLoadingFirstTime] = useState(true);
  const endpointResponseArray = new Array([endPointCallObjectArray.length]);
  let loading = executeCalls;
  let displayTimerActive = executeCalls;
  let completedEndpointCalls = 0;
  let selectedLoadingIconType = loadingIconType;

  if (selectedLoadingIconType !== 'circular') {
    selectedLoadingIconType = 'logo';
  }

  function MakeCall(endPointCallObject, index) {
    endPointCallObject
      .endPointCall(endPointCallObject.endPointCallParameters)
      .then((response) => {
        if (
          location.pathname.includes('/updates') ||
          location.pathname.includes('/boardroom')
        ) {
          const postsFiltered = response.data.getPosts.filter(
            (post) => !post.adminId
          );
          response.data.getPosts = postsFiltered;
        }
        endpointResponseArray[index] = response;
      })
      .catch((error) => {
        console.log(error);
        endpointResponseArray[index] = undefined;
        // setErrorSnackbarMessage(t('SOMETHING_WENT_WRONG'));
        setErrorSnackbarMessage('Something went wrong.');
        setShowErrorSnackbar(true);
      })
      .finally(() => {
        completedEndpointCalls += 1;

        if (completedEndpointCalls >= endPointCallObjectArray.length) {
          loading = false;
          onEndpointCallsFinished(endpointResponseArray);
        }
      });
  }

  function executeAllCalls() {
    for (let i = 0; i < endPointCallObjectArray.length; i += 1) {
      MakeCall(endPointCallObjectArray[i], i);
    }
  }

  useEffect(() => {
    if (isNil(doNotFetch) || !doNotFetch) {
      if (executeCalls) {
        if (loadingFirstTime) {
          executeAllCalls();
          setLoadingFirstTime(false);
        }
      } else {
        setLoadingFirstTime(true);
      }
    }
  }, [executeCalls]);

  return (
    <>
      <ACSnackbar
        open={showErrorSnackbar}
        text={errorSnackbarMessage}
        severity="error"
        onClose={() => setShowErrorSnackbar(false)}
      />
      <div
        style={
          selectedLoadingIconType === 'circular'
            ? classes.circularProgressPosition
            : {}
        }
      >
        <MinimumDisplayTime
          displayTime={doNotFetch ? 0 : 1000}
          visible={loading || displayTimerActive}
          onHide={() => {
            displayTimerActive = false;

            if (
              (isNil(doNotFetch) && !loading) ||
              (!isNil(doNotFetch) && doNotFetch) ||
              (!isNil(doNotFetch) && !doNotFetch && !loading)
            ) {
              onEndpointCallsFinished(endpointResponseArray);
            }
          }}
        >
          {!isCardSkeleton && !isBoardSekeleton && !isDashboardSkeleton && (
            <>
              {selectedLoadingIconType === 'logo' ? (
                <div style={classes.loadingIndicatorPosition}>
                  <LoadingIndicatorLogo
                    size={200}
                    iconFontSize={93}
                    iconRight={105}
                  />
                </div>
              ) : (
                <div>
                  <CircularProgress />
                </div>
              )}
            </>
          )}
        </MinimumDisplayTime>
      </div>
    </>
  );
}

export default EndpointCaller;
