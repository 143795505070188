import React, { useState } from 'react';
import { FormControlLabel, Switch, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#3171F6',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255,255,255,.35)'
        : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const BrandYourCompanySlider = ({
  brandYourCompanySlider,
  setBrandYourCompanySlider,
}) => {
  const [sliderOffEnabled, setSliderOffEnabled] = useState(
    !brandYourCompanySlider
  );
  const [sliderOnEnabled, setSliderOnEnabled] = useState(
    brandYourCompanySlider
  );

  const handleSwitchOffChange = (event) => {
    setSliderOffEnabled(event.target.checked);
    setSliderOnEnabled(!event.target.checked);
    setBrandYourCompanySlider(!event.target.checked);
  };

  const handleSwitchOnChange = (event) => {
    setSliderOnEnabled(event.target.checked);
    setSliderOffEnabled(!event.target.checked);
    setBrandYourCompanySlider(event.target.checked);
  };

  return (
    <Box
      sx={{
        paddingLeft: '50px',
      }}
    >
      {/* <FormControlLabel
        control={
          <AntSwitch
            checked={sliderOffEnabled}
            onChange={handleSwitchOffChange}
          />
        }
        label={
          <Typography
            variant="subtitle1"
            style={{
              color: sliderOffEnabled ? '#3171F6' : '#97A3B7',
              fontFamily: 'Poppins',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: '24px',
              letterSpacing: '-0.43px',
              marginLeft: '5px',
            }}
          >
            {sliderOffEnabled ? 'Off' : 'On'}
          </Typography>
        }
      /> */}
      <FormControlLabel
        control={
          <AntSwitch
            checked={sliderOnEnabled}
            onChange={handleSwitchOnChange}
          />
        }
        label={
          <Typography
            variant="subtitle1"
            style={{
              color: sliderOnEnabled ? '#3171F6' : '#97A3B7',
              fontFamily: 'Poppins',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: '24px',
              letterSpacing: '-0.43px',
              marginLeft: '5px',
            }}
          >
            {sliderOffEnabled ? 'Off' : 'On'}
          </Typography>
        }
      />
    </Box>
  );
};

export default BrandYourCompanySlider;
