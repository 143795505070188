import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as Arrow } from './arrow.svg';

export function CustomCarousel({ items, option }) {
  const originalLength = items.length;
  const [dynamicItems, setDynamicItems] = useState([
    ...items,
    ...items,
    ...items,
  ]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const theme = useTheme();
  const isUnder1400px = useMediaQuery('(max-width:1400px)');
  const isUnder600px = useMediaQuery('(max-width:600px)');
  const isUnder390px = useMediaQuery('(max-width:389px)');
  const numVisibleItems = isUnder1400px ? 1 : 2;
  const itemWidth = isUnder600px ? 400 : 500;
  const height = option === 1 ? 326 : 230;

  useEffect(() => {
    if (currentIndex < originalLength) {
      setDynamicItems((prev) => [...items, ...prev]);
      setCurrentIndex(currentIndex + originalLength);
    } else if (currentIndex >= dynamicItems.length - originalLength * 2) {
      setDynamicItems((prev) => [...prev, ...items]);
    }
  }, [currentIndex, dynamicItems.length, originalLength, items]);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => prevIndex - 1);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => prevIndex + 1);
  };

  return (
    <Box
      sx={{
        overflow: 'hidden',
        width: `${itemWidth * numVisibleItems}px`,
        position: 'relative',
        mx: 'auto',
      }}
    >
      <IconButton
        className="useCaseScroll"
        onClick={handlePrev}
        sx={{
          position: 'absolute',
          left: '0%',
          top: '50%',
          transform: 'translateY(-50%)',
          zIndex: 2,
        }}
      >
        <Arrow style={{ transform: 'rotate(180deg)' }} />
      </IconButton>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          width: `calc(${itemWidth}px * ${dynamicItems.length})`,
          flexDirection: 'row',
          transform: `translateX(-${itemWidth * currentIndex}px)`,
          transition: 'transform 0.5s ease',
          marginLeft: isUnder390px ? '-16px' : '0px',
        }}
      >
        {dynamicItems.map((item, index) => (
          <Box
            className={item.className || null}
            id={item.id || null}
            key={index}
            sx={{
              display: 'flex',
              width: { xxs: '300px', xs: '410px' },
              height: { xxs: '100%', xs: height },
              flexDirection: 'column',
              padding: '30px',
              boxSizing: 'border-box',
              borderRadius: '10px',
              border: '2px solid #EFF0F6',
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <Typography
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mb: '30px',
                  height: '32px',
                  padding: '10px',
                  backgroundColor: item.color,
                  borderRadius: '90px',
                  fontWeight: 500,
                  fontSize: '16px',
                  lineHeight: '18px',
                  letterSpacing: '-0.43px',
                }}
              >
                {item.title}
              </Typography>
            </Box>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '15px',
                lineHeight: '22px',
                letterSpacing: '0.75px',
              }}
            >
              {item.description}
            </Typography>
          </Box>
        ))}
      </Box>
      <IconButton
        className="useCaseScroll"
        onClick={handleNext}
        sx={{
          position: 'absolute',
          right: isUnder390px ? '8%' : '0%',
          top: '50%',
          transform: 'translateY(-50%)',
          zIndex: 2,
        }}
      >
        <Arrow />
      </IconButton>
    </Box>
  );
}
