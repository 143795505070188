import React from 'react';
import { Redirect } from 'react-router-dom';
import defaultAdvisorAvatar from '../../icons/user-avatar.svg';
import { Enviroments } from './types/index';

function addZeroIfNeeded(number) {
  if (number < 10) {
    return `0${number}`;
  }

  return number.toString();
}

export function convertTimestamp(timestamp, removeTime) {
  // Expected Format: yyyy-mm-ddThh:mm:ss.mmmZ
  // Target Format: mm/dd/yyyy @ hh:mm

  const date = new Date(timestamp);

  const year = date.getFullYear();
  const month = addZeroIfNeeded(date.getMonth() + 1);
  const day = addZeroIfNeeded(date.getDate());
  const hours = addZeroIfNeeded(date.getHours());
  const minutes = addZeroIfNeeded(date.getMinutes());

  if (removeTime) {
    return `${month}/${day}/${year}`;
  }

  return `${month}/${day}/${year} @ ${hours}:${minutes}`;
}

export function isNil(value) {
  if (value === undefined || value === null) {
    return true;
  }
  return false;
}

export function getIndexArrayValues(array, index) {
  if (array.length >= index) {
    return array[index];
  }
  return [];
}

export function isArrayEmpty(array) {
  return !array.length > 0;
}

export function getValuesFromKeyInDataResponse(obj, key) {
  if (isNil(obj) || isNil(obj.data) || isNil(obj.data[`${key}`])) {
    return null;
  }
  return obj.data[`${key}`];
}

export function capitalize(word) {
  if (typeof word !== 'string' || word.length <= 0) return '';
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function getValueFromKey(obj, key) {
  if (isNil(obj) || isNil(obj[`${key}`])) {
    return null;
  }
  return obj[`${key}`];
}

export function redirectToWithProvider({ path, location }) {
  if (isNil(location)) {
    return (
      <Redirect
        to={{
          pathname: path,
        }}
      />
    );
  }
  return (
    <Redirect
      to={{
        pathname: path,
        state: { from: location },
      }}
    />
  );
}

export function getConsoleEnvironmentUrl() {
  if (process.env.NODE_ENV === Enviroments.PRODUCTION) {
    return 'https://console.advisorycloud.com';
  }
  if (process.env.NODE_ENV === Enviroments.DEVELOPMENT) {
    return 'https://console-dev.advisorycloud.com';
  }
  if (process.env.NODE_ENV === Enviroments.TEST) {
    return 'https://console-test.advisorycloud.com';
  }
  return null;
}

export function getDefaultImageObj() {
  return {
    id: null,
    location: defaultAdvisorAvatar,
  };
}

export function isImageNotDefined(imageUrl) {
  if (
    isNil(imageUrl) ||
    isNil(imageUrl.location) ||
    imageUrl.location.includes('undefined')
  ) {
    return true;
  }
  return false;
}

export function getValidAdvisorsImageObj(imageUrl) {
  if (isImageNotDefined(imageUrl)) {
    return getDefaultImageObj();
  }
  return imageUrl;
}

export function getValidAdvisorsImagePathFromImgObj(imageUrl) {
  return getValidAdvisorsImageObj(imageUrl).location;
}

export function getAdvisorImagePath(accountDetails) {
  const { image } = accountDetails;
  if (image && image.imageKey) {
    return `${process.env.REACT_APP_IMAGES_URL}${image.imageKey}`;
  }
  return defaultAdvisorAvatar;
}
export function verifyAdvisorImagePath(accountDetails) {
  const { avatarUrl, image } = accountDetails;
  if (image && image.imageKey) {
    return true;
  }
  if (
    avatarUrl === null ||
    avatarUrl === 'https://www.advisorycloud.com/uploads'
  ) {
    return false;
  }
  return true;
}

export function getModeratorImagePath(imageKey) {
  let path;
  if (imageKey) {
    path = `${process.env.REACT_APP_IMAGES_URL}${imageKey}`;
  } else {
    path = defaultAdvisorAvatar;
  }
  return path;
}
