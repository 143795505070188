const Transitions = {};

Transitions.Types = {
  COMPONENT_TRANSITION: 'COMPONENT_TRANSITION',
  FIRST_TIME: 'FIRST_TIME',
  EXPIRED_CODE: 'EXPIRED_CODE',
  FAIL_CODE: 'FAIL_CODE',
  PASSWORD_UPDATED: 'PASSWORD_UPDATED',
};

export { Transitions };

export const UpdateAttributesFlow = {
  DEFAULT: 'DEFAULT',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  CHANGE_EMAIL: 'CHANGE_EMAIL',
  CHANGE_ACCOUNT_TYPE: 'CHANGE_ACCOUNT_TYPE',
};
