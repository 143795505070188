import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, Typography } from '@mui/material';

const useStyles = () => ({
  noRecord: {
    padding: '75px 45px',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  noRecordTitle: {
    fontSize: '18px',
    paddingTop: '15px',
    paddingBottom: '5px',
    textAlign: 'center',
    color: 'black',
    width: '100%',
  },
  noRecordSubTitle: {
    fontSize: '16px',
    textAlign: 'center',
    color: '#878787',
    width: '100%',
  },
  clickTag: {
    color: 'primary.main',
    fontSize: '16px',
    textAlign: 'center',
    width: '100%',
    cursor: 'pointer',
  },
});

export default function NoChat() {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div style={classes.noRecord}>
      <img
        style={classes.noRecordImages}
        src="https://cdn.advisorycloud.com/images/messages_empty_state_icon.png"
        alt={t('CONVERSATION_NOT_MESSAGES')}
      />
      <Typography sx={classes.noRecordTitle}>
        {t('CONVERSATION_NOT_MESSAGES')}
      </Typography>
      <Typography sx={classes.noRecordSubTitle}>
        {t('CONVERSATION_NOT_MESSAGES_DESCRIPTION')}
      </Typography>
      {!isSM && (
        <Typography sx={classes.clickTag} id="messagesGetStarted">
          {t('CONVERSATION_NOT_MESSAGES_CLICK_ACTION')}
        </Typography>
      )}
    </div>
  );
}
