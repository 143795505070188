import React from 'react';

function ArchivedUpdates() {
  return (
    <div>
      <span>Archived Updates</span>
    </div>
  );
}

export default ArchivedUpdates;
