import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import {
  ChevronLeft,
  TrendingUp,
  Launch,
  FavoriteBorder,
  HelpOutlineRounded,
  Close,
  EditOutlined,
} from '@mui/icons-material';
import { useAccount } from './../../../contexts/Account';
import eventBus, { eventBusValues } from './../../../eventBus';
import { ReactComponent as Rocket } from '../../../images/charm-rocket.svg';
import { getAdvisorQueryParams } from '../../../utils/queryParams';

const useStyles = () => ({
  container: {
    marginTop: '24px',
    paddingLeft: '20px',
  },
  backButton: {
    border: 'none',
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '160px',
    padding: '0px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '32px',
    letterSpacing: '0.75px',
    position: 'absolute',
    color: '#232B35',
  },
  menuItemsContainer: {
    marginTop: '40px',
  },
  iconStyle: {
    marginRight: '12px',
    marginLeft: '17px',
    marginTop: '6px',
    fontSize: '20px',
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0px',
    paddingRight: '20px',
    paddingLeft: '20px',
    color: '#646D7A',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    paddingBottom: '4px',
    paddingTop: '6px',
    marginRight: '-20px',
    marginLeft: '-20px',
    width: '300px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '32px',
    letterSpacing: '0.75px',
    '&:hover': {
      color: '#232B35',
    },
  },
  menuItemLogout: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0px',
    paddingRight: '20px',
    paddingLeft: '18px',
    color: '#646D7A',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    paddingBottom: '4px',
    paddingTop: '6px',
    width: '260px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '32px',
    letterSpacing: '0.75px',
    '&:hover': {
      color: '#232B35',
    },
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  avatarDetailsContainer: {
    marginLeft: '10px',
  },
  closeButtonContainer: {
    position: 'absolute',
    left: '90%',
    top: '22px',
    zIndex: 2,
    color: '#97A3B7',
  },
});

function ProfileList({ setIsOpen, onBackClick }) {
  const classes = useStyles();
  const { accountDetails, signOut, currentPlan, isFreeTrial, isFreemium } =
    useAccount();
  const history = useHistory();
  const { t } = useTranslation();

  async function onLogoutClick() {
    await signOut();
  }

  function handleMenuItem(url, newTab = false) {
    if (newTab) {
      window.open(url, '_blank');
    } else {
      window.location.replace(url);
    }
  }

  function openProfile() {
    setIsOpen(false);
    onBackClick(true);
    eventBus.dispatch(eventBusValues.triggerAdvisorDrawerAction, {
      ...accountDetails,
      openInEdit: false,
    });
  }

  function helpCenterClick() {
    const url = 'https://advisorycloud.com/whatsnew/';
    const params = `member_type=advisor&email=${accountDetails.email}&first_name=${accountDetails.givenName}&last_name=${accountDetails.surName}&sosfcontactid=${accountDetails.salesforceContactId}&recurly_plan_code=${accountDetails.recurlyPlanCode}&membership_tier=${currentPlan.salesforceValues.tier}`;
    window.open(`${url}?${params}`, '_blank');
  }

  function navigateInApp(path) {
    history.push(path);
  }

  function editProfileClick(event) {
    setIsOpen(false);
    onBackClick(true);
    eventBus.dispatch(eventBusValues.triggerAdvisorDrawerAction, {
      ...accountDetails,
      openInEdit: true,
    });
  }

  function getOptions() {
    return (
      <div style={classes.menuItemsContainer}>
        <button
          id="advisorEditProfileMobileSubMenuOption"
          style={classes.menuItem}
          onClick={editProfileClick}
          type="button"
        >
          <EditOutlined sx={classes.iconStyle} />
          {t('EDIT-PROFILE-MENU-ITEM-TEXT')}
        </button>
        <button
          id="advisorViewProfileMobileSubMenuOption"
          style={classes.menuItem}
          onClick={openProfile}
          type="button"
        >
          <Launch sx={classes.iconStyle} />

          {t('VIEW-PROFILE-MENU-ITEM-TEXT')}
        </button>
        <button
          id="advisorHelpCenterMobileSubMenuOption"
          style={classes.menuItem}
          onClick={() => {
            helpCenterClick();
          }}
          type="submit"
        >
          <HelpOutlineRounded sx={classes.iconStyle} />
          {t('HELP-CENTER-MENU-ITEM-TEXT')}
        </button>
        {accountDetails.upgradeUrl && (
          <button
            id="advisorUpgradeMobileSubMenuOption"
            style={classes.menuItem}
            onClick={() =>
              handleMenuItem(
                `${accountDetails.upgradeUrl}?iwm_advisor_id=${accountDetails.iwmId}&sosfcontactid=${accountDetails.salesforceContactId}&email=${accountDetails.email}&plancode=${accountDetails.recurlyPlanCode}`,
                true
              )
            }
            type="submit"
          >
            <TrendingUp sx={classes.iconStyle} />
            {t('UPGRADE-MEMBERSHIP-MENU-ITEM-TEXT')}
          </button>
        )}
        <button
          id="advisorMemberSettingsMobileSubMenuOption"
          style={classes.menuItem}
          onClick={() => navigateInApp('/account')}
          type="submit"
        >
          <FavoriteBorder sx={classes.iconStyle} />

          {t('MEMBERSHIP-SETTINGS-MENU-ITEM-TEXT')}
        </button>
        <button
          id="advisorBuildBoardMobileSubMenuOption"
          style={{
            ...classes.menuItem,
            paddingRight: '0px',
            width: '100%',
          }}
          onClick={() =>
            window.location.assign(
              `https://advisorycloud.com/add-a-board-to-your-membership?${getAdvisorQueryParams(
                { accountDetails, isFreeTrial, isFreemium }
              )}`,
              '_blank'
            )
          }
          type="submit"
        >
          <Box
            sx={{
              backgroundColor: '#D7F1FD',
              display: 'flex',
              alignItems: 'center',
              height: '68px',
              borderRadius: '12px',
              paddingLeft: '10px',
              width: '100%',
            }}
          >
            <Rocket
              style={{
                ...classes.iconStyle,
                marginBottom: '8px',
                marginRight: '12px',
                marginLeft: '5px',
              }}
            />
            <Box>
              <Typography
                sx={{
                  fontSize: '15px',
                  fontWeight: '500',
                  lineHeight: '32px',
                  letterSpacing: '-0.25px',
                  textAlign: 'left',
                  color: '#232B35',
                  height: '18px',
                  marginTop: '14px',
                }}
              >
                Build your own Advisory Board
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '400',
                  lineHeight: '32px',
                  letterSpacing: '-0.25px',
                  textAlign: 'left',
                  color: '#1D95CD',
                  marginTop: '4px',
                }}
              >
                Comes free with your membership!
              </Typography>
            </Box>
          </Box>
        </button>
        <button
          id="advisorLogoutMobileSubMenuOption"
          style={classes.menuItemLogout}
          type="submit"
          onClick={onLogoutClick}
        >
          {t('LOGOUT-MENU-ITEM-TEXT')}
        </button>
      </div>
    );
  }

  return (
    <div style={classes.container}>
      <button
        id="advisorMobileReturnOption"
        type="submit"
        style={classes.backButton}
        onClick={onBackClick}
      >
        <ChevronLeft />
        Main Menu
      </button>
      <div style={classes.closeButtonContainer}>
        <Close
          onClick={() => {
            setIsOpen(false);
            onBackClick(true);
          }}
        />
      </div>
      {getOptions()}
    </div>
  );
}

export default ProfileList;
