import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, Button } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import AdvisoryCloudLogo from './../../AdvisoryCloudLogo';

const useStyles = (isSM) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '45px',
    paddingRight: '45px',
    backgroundColor: '#ffffff',
    width: '100%',
    height: '50px',
    boxShadow: '1px 1px 1px #bbbbbb57',
  },
  logo: {
    marginLeft: isSM ? '10%' : '0%',
    cursor: 'pointer',
  },
  menuButton: {
    padding: '0px',
    border: 'none',
    backgroundColor: 'transparent',
  },
  viewProfileButton: {
    width: '98px',
    height: '35px',
    textTransform: 'none',
    fontSize: '12px',
    borderColor: '#0F78FD',
    background: '#0F78FD',
    color: '#FFF !important',
    '&:hover': {
      background: '#0F78FD',
    },
    fontFamily: 'Poppins',
  },
});

function AdvisorSearchTopMenu({ onMenuIconClick }) {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div style={classes.container}>
      <div style={classes.logo}>
        <AdvisoryCloudLogo
          removeHyperlink
          onClick={() => {
            window.location.assign('https://www.advisorycloud.com');
          }}
          width={150}
        />
      </div>
      {isSM ? (
        <button
          onClick={onMenuIconClick}
          style={classes.menuButton}
          type="submit"
        >
          <PersonIcon style={{ color: '#bdd2f0' }} />
        </button>
      ) : (
        <Button
          variant="outlined"
          onClick={() => {
            history.push('/login');
          }}
          sx={classes.viewProfileButton}
        >
          {t('LOGIN_TITLE')}
        </Button>
      )}
    </div>
  );
}

export default AdvisorSearchTopMenu;
