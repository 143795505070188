import React, { useReducer, useContext } from 'react';

const FeedContext = React.createContext();

const INITIAL_STATE = {
  posts: [],
  temporaryNewPost: null,
};

const ActionTypes = {
  ADD_POST: 'ADD_POST',
  CLEAN_POSTS: 'CLEAN_POSTS',
};

const feedReducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.ADD_POST: {
      return {
        ...state,
        posts: [...state.posts, action.payload],
        temporaryNewPost: action.payload,
      };
    }
    case ActionTypes.CLEAN_POSTS: {
      return { ...state, posts: [], temporaryNewPost: null };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const useFeed = (initialState = INITIAL_STATE) => {
  const [state, dispatch] = useReducer(feedReducer, initialState);

  function getPosts() {
    return state.posts;
  }

  function addPost(post) {
    dispatch({
      type: ActionTypes.ADD_POST,
      payload: post,
    });
  }

  function removeAllPosts() {
    dispatch({ type: ActionTypes.CLEAN_POSTS });
  }

  return { state, getPosts, addPost, removeAllPosts };
};

export const CompanyExtraFeedProvider = (props) => {
  const { children } = props;
  return (
    <FeedContext.Provider value={useFeed()}>{children}</FeedContext.Provider>
  );
};

export const useCompanyExtraFeed = () => useContext(FeedContext);
