import { Storage } from 'aws-amplify';
import { Enviroments } from '../utils/types';

export async function uploadToS3({
  BLOB_LOAD,
  CONTENT_TYPE,
  FILE_ID,
  S3_BUCKET,
}) {
  let region = process.env.REACT_APP_APPSYNC_REGION;
  let targetBucket = S3_BUCKET;
  if (
    S3_BUCKET === process.env.REACT_APP_S3_BUCKET_IMAGES &&
    process.env.NODE_ENV === Enviroments.PRODUCTION
  ) {
    region = 'us-east-1';
    targetBucket = targetBucket.replace('us-west-2', 'us-east-1');
  }
  await Storage.put(FILE_ID, BLOB_LOAD, {
    bucket: targetBucket,
    region,
    contentType: CONTENT_TYPE,
    cacheControl: 'no-cache',
  });
}
