import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import ACAvatar from './../../../ACAvatar';
import DefaultCompany from './board-empty-state.svg';
import {
  getCompanyPublicInfoBoardroomMenu,
  getCompanyInfoBoardroomMenu,
} from './../../../../services/company_services/index';
import { convertTimestamp } from './../../../../services/utils';

const useStyles = () => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: '30px',
    marginBottom: '10px',
    alignItems: 'center',
    fontFamily: 'Poppins',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginRight: '10px',
    marginLeft: '10px',
    cursor: 'default',
  },
  companyText: {
    fontSize: '100%',
    fontWeight: 560,
  },
  publicCompanyText: {
    fontSize: '16px',
    fontWeight: 500,
    fontFamily: 'Poppins',
  },
  joinedText: {
    fontSize: '85%',
  },
  imageContainer: {
    width: '40px',
    height: '40px',
  },
  loadingContainer: {
    width: '165px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

function BoardPosition(props) {
  const { companyId, advisorId, publicProfile, applied } = props;
  const [image, setImage] = useState(DefaultCompany);
  const [companyName, setCompanyName] = useState('');
  const [joinedAt, setJoinedAt] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const classes = useStyles();

  useEffect(() => {
    async function getCompanyData() {
      let queryCall = getCompanyInfoBoardroomMenu;
      if (publicProfile) queryCall = getCompanyPublicInfoBoardroomMenu;
      queryCall({ companyId })
        .then((response) => {
          if (response.data && response.data.getCompany) {
            const companyData = response.data.getCompany;
            setCompanyName(companyData.displayName);
            if (
              companyData.image &&
              companyData.image.location &&
              companyData.image.id
            ) {
              setImage(`https://${companyData.image.location}`);
            }
            if (companyData.boardAdvisors && companyData.boardAdvisors.length) {
              const thisAdvisor = companyData.boardAdvisors.find(
                (advisor) => advisor.id === advisorId
              );
              if (thisAdvisor && thisAdvisor.joinedOnDate) {
                setJoinedAt(
                  `Joined ${convertTimestamp(thisAdvisor.joinedOnDate, true)}`
                );
              }
            }
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    getCompanyData();
  }, []);

  if (isLoading) {
    return (
      <div style={classes.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <div style={classes.mainContainer}>
        <ACAvatar
          size="sm"
          image={image || DefaultCompany}
          style={classes.imageContainer}
        />
        <div style={classes.textContainer}>
          <div
            style={
              !publicProfile ? classes.companyText : classes.publicCompanyText
            }
          >
            {companyName}
          </div>
          {!publicProfile ? (
            <>
              {applied ? (
                <div
                  style={{ ...classes.joinedText, textTransform: 'uppercase' }}
                >
                  Application Pending
                </div>
              ) : (
                <div style={classes.joinedText}>{joinedAt}</div>
              )}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default BoardPosition;
