import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { ReactComponent as BuildingOutlined } from '../../icons/building-outlined.svg';
import { ReactComponent as Talking } from '../../icons/talking.svg';
import { ArrowDownwardOutlined } from '@mui/icons-material';

const GeneralOptionsCards = ({ customMarginTop }) => {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <Grid
      container
      direction={isSM ? 'column' : 'row'}
      justifyContent="space-around"
      sx={{
        alignItems: isSM ? 'center' : null,
        marginTop: customMarginTop || null,
      }}
    >
      <Grid
        item
        xxs={12}
        sm={6}
        md={4}
        sx={{ display: { xxs: 'none', md: 'block' } }}
      >
        <CardComponent
          id="freeUpgradeTile"
          currentText
          title="AdvisoryCloud Free"
          subtitle="Use the platform to collaborate with your network"
          list={[
            'Limited access to 10,000+ advisors',
            'Invite your own advisors',
            'Secure workspace to collaborate',
            '1 team member',
            'Limited messaging',
          ]}
          adormentColor="#D7F1FD"
        />
      </Grid>
      <Grid item xxs={12} sm={6} md={4}>
        <CardComponent
          id="acPlusUpgradeTile"
          title="AdvisoryCloud Plus"
          subtitle="Collaborate with advisors from our network on-demand"
          list={[
            'Everything in AdvisoryCloud Free',
            'Access to 10,000+ advisors',
            'Meet with advisors 1:1 or in groups',
            '1:1 onboarding and support',
            'and more...',
          ]}
          firstItemBold
          target="/upgrade/b2b_ac_plus"
          adormentColor="#DBF7EB"
          buttonId="acPlusLearnMoreCTA"
          borderButtonColor="#2CF9A1"
          CustomIcon={Talking}
        />
      </Grid>

      <Grid item xxs={12} sm={6} md={4}>
        <CardComponent
          id="mabUpgradeTile"
          title="Managed Advisory Board"
          subtitle="Get regular feedback from a dedicated advisory board"
          list={[
            'Everything in AdvisoryCloud Plus',
            'Dedicated Account Manager',
            'Advisory Board Assembly in 2 weeks',
            'Moderated Quarterly Meetings',
            'Meeting Transcripts and Analysis',
            'and more...',
          ]}
          firstItemBold
          target="/upgrade/b2b_managed_advisory_board"
          adormentColor="#D7F1FD"
          buttonId="mabLearnMoreCTA"
          borderButtonColor="#4BC6FF"
          CustomIcon={BuildingOutlined}
        />
      </Grid>
      <Grid
        item
        xxs={12}
        sm={6}
        md={4}
        sx={{ display: { xxs: 'block', md: 'none' } }}
      >
        <CardComponent
          id="freeUpgradeTile"
          currentText
          title="AdvisoryCloud Free"
          subtitle="Use the platform to collaborate with your network"
          list={[
            'Limited access to 10,000+ advisors',
            'Invite your own advisors',
            'Secure workspace to collaborate',
            '1 team member',
            'Limited messaging',
          ]}
          adormentColor="#D7F1FD"
        />
      </Grid>
    </Grid>
  );
};

export default GeneralOptionsCards;

const CardList = ({ items, dotColor, firstItemBold }) => {
  return (
    <Box>
      {items.map((item, index) => (
        <Box key={index} display="flex" alignItems="center" mb="12px">
          <Box
            sx={{
              width: 8,
              height: 8,
              borderRadius: '50%',
              bgcolor: dotColor,
              mr: '6px',
            }}
          />
          <Typography
            sx={{
              fontWeight: firstItemBold && index === 0 ? '700' : '500',
              fontSize: '14px',
              lineHeight: '23px',
              color: '#232B35',
              whiteSpace: 'nowrap',
            }}
          >
            {item}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

const CardComponent = ({
  adormentColor,
  borderButtonColor,
  CustomIcon,
  target,
  currentText,
  firstItemBold,
  list,
  title,
  subtitle,
  id,
  buttonId,
}) => {
  const history = useHistory();
  const location = useLocation();
  const weirdGap = useMediaQuery('(min-width:1280px) and (max-width:1426px)');
  const styles = {
    card: {
      width: '100%',
      borderRadius: '12px',
      padding: weirdGap ? '15px 8px' : '15px',
      border: '1px solid #EFF0F6',
      textAlign: 'center',
      mb: '40px',
    },
    title: {
      fontWeight: '700',
      fontSize: '18px',
      lineHeight: '24px',
      color: '#232B35',
      marginBottom: '12px',
    },
    subtitle: {
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#232B35',
    },
  };

  return (
    <Box
      textAlign="center"
      px={weirdGap ? '4px' : '12px'}
      sx={{
        maxWidth: {
          xxs: '400px',
        },
      }}
    >
      {currentText && (
        <Typography
          sx={{
            color: '#646D7A',
            fontWeight: '500',
            fontSize: '14px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '104px',
          }}
        >
          <Box
            sx={{
              border: '1px solid #646D7A',
              borderRadius: '50%',
              width: '20px',
              height: '20px',
              mr: '4px',
              pt: '1px',
            }}
          >
            <ArrowDownwardOutlined sx={{ fontSize: '14px' }} />
          </Box>
          Your current plan
        </Typography>
      )}
      <Card sx={styles.card} elevation={0} id={id} className="upgradeTile">
        <CardContent sx={{ padding: '12px' }}>
          {CustomIcon && (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                my: '24px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '50%',
                  backgroundColor: adormentColor,
                  width: '55px',
                  height: '55px',
                }}
              >
                <CustomIcon />
              </Box>
            </Box>
          )}
          <Typography variant="h2" sx={styles.title}>
            {title}
          </Typography>
          <Typography sx={styles.subtitle}>{subtitle}</Typography>
          <Divider sx={{ my: '24px' }} />
          {target && (
            <Button
              onClick={() => {
                const currentRouteState = location?.state || {};
                history.push(target, currentRouteState);
              }}
              id={buttonId}
              className="learnMoreCTA"
              sx={{
                fontWeight: '700',
                fontSize: '14px',
                lineHeight: '24px',
                textTransform: 'none',
                color: 'black',
                backgroundColor: adormentColor,
                border: `1px solid ${borderButtonColor}`,
                borderRadius: '40px',
                width: '168px',
                height: '44px',
                marginBottom: '24px',
              }}
            >
              Learn More
            </Button>
          )}
          {list && (
            <CardList
              items={list}
              dotColor={adormentColor}
              firstItemBold={firstItemBold}
            />
          )}
        </CardContent>
      </Card>
    </Box>
  );
};
