import React from 'react';
import {
  InputLabel,
  TextField,
  Typography,
  useMediaQuery,
  Grid,
} from '@mui/material';

const useStyles = () => ({
  inputLabel: {
    marginBottom: '10px',
    color: '#232B35',
    width: '100%',
  },
  inputLabelMain: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    color: '#232B35',
  },
  inputContainer: {
    width: '100%',
  },
  helperText: {
    color: 'grey.600',
  },
  helperErrorText: {
    color: 'error.main',
  },
  responsivePosition: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

function Input({
  id,
  label,
  parentStyle,
  errorTextInRed,
  helperText,
  FormHelperTextProps,
  hideLabel,
  ...rest
}) {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles();
  function classSelector() {
    if (FormHelperTextProps) {
      return FormHelperTextProps;
    }
    if (errorTextInRed) {
      return classes.helperErrorText;
    }
    return classes.helperText;
  }

  return (
    <div style={{ ...classes.inputContainer, ...(parentStyle || {}) }}>
      {!hideLabel &&
        (isSM ? (
          <InputLabel sx={classes.inputLabel} htmlFor={id}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography sx={classes.inputLabelMain}>{label}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption_old_font" sx={classSelector()}>
                  {helperText}
                </Typography>
              </Grid>
            </Grid>
          </InputLabel>
        ) : (
          <InputLabel sx={classes.inputLabel} htmlFor={id}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography sx={classes.inputLabelMain}>{label}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption_old_font" sx={classSelector()}>
                  {helperText}
                </Typography>
              </Grid>
            </Grid>
          </InputLabel>
        ))}

      <TextField
        {...rest}
        sx={{ ...classes.inputContainer, ...(rest.sx || {}) }}
        id={id}
        variant="outlined"
      />
    </div>
  );
}

export default Input;
