import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Avatar,
  Box,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CustomIcon from './../../../icons/customIcon';
import DefaultAdvisorAvatar from './../../../icons/user-avatar.svg';
import { useAccount } from './../../../contexts/Account';
import ConversationNotification from './../../Conversation/Notification';
import eventBus, { eventBusValues } from './../../../eventBus';
import {
  readSingleMessage,
  readMessages,
} from './../../../services/conversation_services';
import dateFormat from './../../../utils/dateFormat';
import useCanMessage from './../../../hooks/useMessagePermission';

const StatusPill = ({ status }) => {
  if (!status || status === 'member') {
    return null;
  }

  return (
    <div
      style={{
        position: 'absolute',
        top: '-8px',
        right: '-6px',
        height: '18px',
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
        fontSize: '10px',
        fontWeight: 600,
        letterSpacing: '-0.32px',
        paddingRight: 10,
        paddingLeft: 10,
        background: status === 'invitation' ? '#4BC6FF' : '#1D95CD',
      }}
    >
      {status === 'invitation' ? 'Invitation' : 'Pending'}
    </div>
  );
};

const BoardCard = ({
  logo,
  memberName,
  companyName,
  status,
  onClick,
  boardMember,
  memberType,
  isRecentlyMessaged,
  isBoardOwner,
  boardMembersTab,
  showLastMessage,
  boardOwnerId,
}) => {
  const {
    conversations,
    currentUserInfo,
    asLoggedUserDetails,
    type,
    updateReadMessages,
  } = useAccount();
  const isLG = useMediaQuery((theme) => theme.breakpoints.down([1550]));
  const history = useHistory();
  const [alreadyMessagedBoardOwner, setAlreadyMessagedBoardOwner] =
    useState(false);
  const [currentConversation, setCurrentConversation] = useState({});
  const [messageReceived, setMessageReceived] = useState(false);
  const [messageReceivedDate, setMessageReceivedDate] = useState('');
  const [unread, setUnread] = useState(0);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const trimValue = isLG ? 16 : 20;
  const isSelected =
    queryParams.get('conversationId') === currentConversation.conversationId;
  const trimmedMemberName = memberName
    ? memberName.length > trimValue
      ? memberName.substring(0, 12) + '...'
      : memberName
    : '';
  const canMessage = useCanMessage(boardMember.id, memberType);

  function conversationClick(member) {
    if (canMessage === undefined) return;
    if (canMessage) {
      if (currentConversation.conversationId) {
        readMessages({
          accountId: asLoggedUserDetails.userId,
          accountType: type,
          conversationId: currentConversation.conversationId,
        });
        updateReadMessages(conversations, currentConversation.conversationId);
        history.push(
          `/messages?conversationId=${currentConversation.conversationId}`
        );
      } else {
        eventBus.dispatch(eventBusValues.onSendConversationNewMessage, {
          senderId: member.id,
          senderName: member.displayName,
        });
      }
    } else {
      eventBus.dispatch(eventBusValues.triggerAdvisorUpgradeAction, {
        gatedAction: true,
        companyName: companyName,
      });
    }
  }

  function findConversation() {
    if (boardMembersTab) {
      const index = conversations.findIndex(
        (conversation) =>
          conversation.conversationMemberAccountId === boardMember.id
      );
      if (index >= 0) {
        return true;
      }
    }
    return false;
  }

  function renderIcon() {
    if (isRecentlyMessaged || alreadyMessagedBoardOwner || findConversation()) {
      return (
        <IconButton
          style={{ width: '100%', height: '100%', color: ' #232B35' }}
          onClick={() => conversationClick(boardMember)}
        >
          <CustomIcon iconname="comments" style={{ fontSize: 20, zIndex: 3 }} />
          <ConversationNotification
            value={unread}
            styleProps={{
              position: 'absolute',
              top: '-4px',
              right: '-10px',
              paddingTop: '0px',
              fontSize: '8px',
              backgroundColor: '#FF6666',
            }}
          />
        </IconButton>
      );
    }

    return (
      <IconButton
        style={{ width: '100%', height: '100%', color: ' #232B35' }}
        onClick={() => conversationClick(boardMember)}
      >
        <CustomIcon iconname="airplane" style={{ fontSize: 16, zIndex: 3 }} />
      </IconButton>
    );
  }

  function findingConversation() {
    if (!isRecentlyMessaged && isBoardOwner) {
      const index = conversations.findIndex(
        (conversation) =>
          conversation.conversationMemberAccountId === boardMember.id
      );
      if (index >= 0) {
        setCurrentConversation(conversations[index]);
      }
      setAlreadyMessagedBoardOwner(index > -1);
    }
  }

  function newMessage(event) {
    if (
      currentUserInfo.username !== event.senderId &&
      !window.location.search.includes(event.conversationId)
    ) {
      setUnread((prev) => prev + 1);
    } else {
      readSingleMessage({
        accountId: asLoggedUserDetails.userId,
        accountType: type,
        conversationId: event.conversationId,
        messageId: event.id,
      });
    }
    setMessageReceived(event.senderId !== asLoggedUserDetails.userId);
    setMessageReceivedDate(event.createdAt);
  }

  useEffect(() => {
    findingConversation();
    const index = conversations.findIndex(
      (conversation) =>
        conversation.conversationMemberAccountId === boardMember.id
    );
    if (index >= 0) {
      setCurrentConversation(conversations[index]);
      setMessageReceived(
        conversations[index].conversationMemberAccountId ===
          conversations[index].lastMessageSender
      );
      setMessageReceivedDate(conversations[index].lastMessageDate);
      setUnread(conversations[index].unreadCount);
      eventBus.on(
        `${eventBusValues.onNewMessage}${conversations[index].conversationId}`,
        (event) => newMessage(event)
      );
      eventBus.on(
        `${eventBusValues.onReadConversation}${conversations[index].conversationId}`,
        () => {
          setUnread(0);
        }
      );
    }
    eventBus.on(eventBusValues.onNewConversation, findingConversation);

    return () => {
      eventBus.remove(eventBusValues.onNewConversation, findingConversation);
      eventBus.remove(
        `${eventBusValues.onNewMessage}${currentConversation.conversationId}`,
        (event) => newMessage(event)
      );
      eventBus.remove(
        `${eventBusValues.onReadConversation}${currentConversation.conversationId}`
      );
    };
  }, []);

  function getId() {
    if (boardOwnerId) {
      return boardOwnerId;
    }
    if (isBoardOwner) {
      return 'boardOwnerCard';
    }
    return '';
  }

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: isBoardOwner ? '76px' : showLastMessage ? '76px' : '74px',
        background: isBoardOwner ? '#E0E9F8' : '#FBFCFD',
        borderRadius: '12px',
        justifyContent: 'center',
        paddingLeft: '20px',
        paddingRight: '20px',
        backgroundColor: !isSelected
          ? null
          : isRecentlyMessaged
          ? '#E0E9F8'
          : '#EFF0F6',
        '&:hover': {
          backgroundColor: '#E0E9F8',
          boxShadow: 'none',
        },
        cursor: 'pointer',
      }}
      className={isBoardOwner ? '' : ' advisorBoardMemberCard'}
      id={getId()}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <div
          onClick={onClick ? onClick : conversationClick}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'start',
            width: '80%',
          }}
        >
          <Avatar
            src={logo || DefaultAdvisorAvatar}
            alt="Company Logo"
            style={{
              width: '50px',
              height: '50px',
              borderRadius: '25px',
              backgroundColor: '#ffffff',
            }}
          />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '13px',
                lineHeight: '24px',
                letterSpacing: '-0.43px',
                color: '#232B35',
                marginLeft: 10,
              }}
            >
              {trimmedMemberName}
            </div>
            {boardMember && boardMember.title && (
              <div
                style={{
                  fontFamily: 'Poppins',
                  fontStyle: 'normal',
                  fontWeight: 300,
                  fontSize: '10px',
                  lineHeight: '5px',
                  marginBottom: '5px',
                  letterSpacing: '-0.43px',
                  color: '#1C2F49',
                  marginLeft: 10,
                  marginTop: 5,
                }}
              >
                {boardMember.title.length > 18
                  ? boardMember.title.substring(0, 18) + '...'
                  : boardMember.title}
              </div>
            )}
            {showLastMessage && (
              <Typography
                sx={{
                  fontWeight: '300',
                  fontSize: '10px',
                  color: '#646D7A',
                  marginLeft: '10px',
                }}
              >
                {messageReceived ? 'Received' : 'Sent'}:{' '}
                {dateFormat(messageReceivedDate, 'monthAndDay')}
              </Typography>
            )}
          </div>
        </div>
        <div
          style={{
            width: 34,
            height: 34,
            background: '#F5F7FA',
            borderRadius: 20,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {renderIcon()}
        </div>
        <StatusPill status={status} />
      </Box>
    </Box>
  );
};

export default BoardCard;
