import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size';
import ACSnackbar from './../../../ACSnackbar';
import { Storage } from 'aws-amplify';

import { useTranslation } from 'react-i18next';
import { DokaImageEditorModal } from 'react-doka';
import {
  locale_en_gb,
  createDefaultImageReader,
  createDefaultImageWriter,
  setPlugins,
  plugin_crop,
  plugin_crop_locale_en_gb,
  plugin_crop_defaults,
  plugin_finetune,
  plugin_finetune_locale_en_gb,
  plugin_finetune_defaults,
  plugin_filter,
  plugin_filter_locale_en_gb,
  plugin_filter_defaults,
  plugin_decorate,
  plugin_decorate_defaults,
  plugin_decorate_locale_en_gb,
  component_shape_editor_locale_en_gb,
} from 'doka/doka';
import { useAccount } from './../../../../contexts/Account';
import 'doka/doka.css';

import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css';
import { Enviroments } from './../../../../services/utils/types';

setPlugins(plugin_crop, plugin_finetune, plugin_filter, plugin_decorate);

registerPlugin(
  FilePondPluginImagePreview,
  FilePondPluginImageExifOrientation,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType,
  FilePondPluginImageResize,
  FilePondPluginImageTransform,
  FilePondPluginImageEdit,
  FilePondPluginImageCrop,
  FilePondPluginFileEncode,
  FilePondPluginImageValidateSize
);

const editorDefaults = {
  imageReader: createDefaultImageReader(),
  imageWriter: createDefaultImageWriter(),
  ...plugin_crop_defaults,
  ...plugin_finetune_defaults,
  ...plugin_filter_defaults,
  ...plugin_decorate_defaults,
  locale: {
    ...locale_en_gb,
    ...plugin_crop_locale_en_gb,
    ...plugin_finetune_locale_en_gb,
    ...plugin_filter_locale_en_gb,
    ...plugin_decorate_locale_en_gb,
    ...component_shape_editor_locale_en_gb,
  },
};

const filePondProfileOptions = {
  dropOnPage: 'true',
  stylePanelLayout: 'compact circle',
  allowImageCrop: 'true',
  allowDrop: 'true',
  allowRemove: 'true',
  allowProcess: 'true',
  allowBrowse: 'true',
  allowFileSizeValidation: 'true',
  allowFileTypeValidation: 'true',
  allowImageExifOrientation: 'true',
  allowImagePreview: 'true',
  allowImageResize: 'true',
  allowImageTransform: 'true',
  allowImageValidateSize: 'true',
  imageTransformVariantsIncludeOriginal: 'true',
  imagePreviewMaxFileSize: '10MB',
  imageResizeMode: 'contain',
  maxFileSize: '10MB',
  imageResizeTargetWidth: '600',
  imageResizeTargetHeight: '600',
  maxFiles: 1,
  credits: 'false',
  acceptedFileTypes: `${['image/jpg', 'image/jpeg', 'image/png', 'image/gif']}`,
  imageCropAspectRatio: '1:1',
  imageTransformOutputMimeType: 'image/png',
  imageEditAllowEdit: 'true',
  styleImageEditButtonEditItemPosition: 'bottom right',
  styleLoadIndicatorPosition: 'right bottom',
  styleProgressIndicatorPosition: 'right bottom',
  styleButtonRemoveItemPosition: 'left bottom',
  styleButtonProcessItemPosition: 'right bottom',
  instantUpload: 'false',
};

const useStyles = makeStyles((theme) => ({
  uploader: {
    width: '110px',
    '& .filepond--drop-label': {
      fontSize: '0.8rem',
      color: theme.palette.secondary.dark,
    },
    '& .filepond--file': {
      fontSize: '0.8rem',
    },
    '& .filepond--file-status-main': {
      marginRight: '16px',
    },
  },
}));

function AdvisorImageUploader(props) {
  const { resultFiles } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const { accountDetails, isFreemium } = useAccount();
  const [preloadedImage, setPreloadedImage] = useState();
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [currentImage, setCurrentImage] = useState();
  const [dokaImage, setDokaImage] = useState();
  const [openModal, setOpenModal] = useState(false);
  const ref = React.useRef();

  async function preloadFilepondImage() {
    try {
      const region =
        process.env.NODE_ENV === Enviroments.PRODUCTION
          ? 'us-east-1'
          : process.env.REACT_APP_APPSYNC_REGION;
      const image = accountDetails.image.imageKey.replace('public/', '');
      const imageToPreloadURL = await Storage.get(image, {
        bucket: process.env.REACT_APP_S3_BUCKET_IMAGES,
        region,
        download: true,
      });
      setPreloadedImage(imageToPreloadURL.Body);
    } catch (error) {
      setShowErrorSnackbar(true);
    }
  }

  useEffect(() => {
    if (
      (accountDetails.image && accountDetails.image.imageKey) ||
      (accountDetails.avatarUrl &&
        !accountDetails.avatarUrl.includes('undefined'))
    ) {
      preloadFilepondImage();
    }
  }, []);

  useEffect(() => {
    if (preloadedImage) {
      const newBlob = preloadedImage.slice(0, preloadedImage.size, 'image/png');
      const newBlobURL = URL.createObjectURL(newBlob);
      setCurrentImage(newBlobURL);
    }
  }, [preloadedImage]);

  function setupFiles(values) {
    if (values.length === 0) {
      setCurrentImage(null);
      setDokaImage(null);
      setOpenModal(false);
      resultFiles({});
    }
    if (values.length === 1) {
      setCurrentImage(values);
    }
    return null;
  }

  function catchUpdate(data) {
    const result = URL.createObjectURL(data);
    setCurrentImage(result);
    setOpenModal(false);
  }

  function catchOutputFiles(data) {
    resultFiles({
      originalImage: data[0].file,
      image: data[1].file,
    });
  }

  const cropAspectRatios = [[1, 'Square']];

  return (
    <>
      <div
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <ACSnackbar
          open={showErrorSnackbar}
          text={t('PROFILE-UPLOADER-ERROR')}
          severity="error"
          onClose={() => setShowErrorSnackbar(false)}
          style={{ marginTop: isFreemium ? '50px' : '0px' }}
        />
        <FilePond
          ref={ref}
          {...filePondProfileOptions}
          labelIdle={`${t('COMPANY-LOGO-UPLOADER-DRAG')}
          <span class="filepond--label-action">${t(
            'COMPANY-LOGO-UPLOADER-BROWSE'
          )}</span>`}
          files={currentImage}
          className={classes.uploader}
          onerror={(err) => {
            console.log('filepond', err);
          }}
          onupdatefiles={(files) => {
            setupFiles(files);
          }}
          onpreparefile={(files, output) => {
            const originalInput = URL.createObjectURL(output[0].file);
            setDokaImage(originalInput);
            catchOutputFiles(output);
          }}
          imageTransformBeforeCreateBlob={(canvas) =>
            new Promise((resolve) => {
              const ctx = canvas.getContext('2d');
              const cw = canvas.width;
              const ch = canvas.height;
              ctx.globalCompositeOperation = 'destination-in';
              ctx.fillStyle = 'white';
              ctx.beginPath();
              ctx.arc(cw * 0.5, ch * 0.5, cw * 0.5, 0, 2 * Math.PI);
              ctx.fill();
              ctx.fillRect(0, 0, canvas.width, canvas.height);
              resolve(canvas);
            })
          }
          imageEditEditor={{
            open: () => {
              setOpenModal(true);
            },
          }}
        />
        {openModal ? (
          <DokaImageEditorModal
            {...editorDefaults}
            src={dokaImage}
            imageCropAspectRatio="1"
            cropSelectPresetOptions={cropAspectRatios}
            onClose={() => setOpenModal(false)}
            onHide={() => setOpenModal(false)}
            onProcess={({ dest }) => catchUpdate(dest)}
            willRenderCanvas={(shapes, state) => {
              const { utilVisibility, selectionRect } = state;

              if (utilVisibility.crop <= 0) return shapes;
              const { x, y, width, height } = selectionRect;
              return {
                ...shapes,
                interfaceShapes: [
                  {
                    x: x + width * 0.5,
                    y: y + height * 0.5,
                    rx: width * 0.5,
                    ry: height * 0.5,
                    opacity: utilVisibility.crop,
                    inverted: true,
                    backgroundColor: [0, 0, 0, 0.5],
                    strokeWidth: 1,
                    strokeColor: [0, 0, 0],
                  },
                  ...shapes.interfaceShapes,
                ],
              };
            }}
          />
        ) : null}
      </div>
    </>
  );
}

export default AdvisorImageUploader;
