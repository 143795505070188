import React, { useEffect, useState } from 'react';
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';
import { Avatar, IconButton, Typography, useMediaQuery } from '@mui/material';
import DefaultAdvisorAvatar from '../../icons/user-avatar.svg';
import ConversationNotification from './Notification';
import eventBus, { eventBusValues } from '../../eventBus';
import { useAccount } from '../../contexts/Account';
import { readSingleMessage } from '../../services/conversation_services';
import CustomIcon from '../../icons/customIcon';

const useStyles = (isSM) => ({
  container: {
    minWidth: isSM ? '0px' : '250px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    height: '67px',
    cursor: 'pointer',
    borderRight: 'none',
    backgroundColor: '#FBFCFD',
  },
  cardContainer: {
    marginLeft: '15px',
  },
  displayName: {
    whiteSpace: 'pre',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    color: '#232B35',
  },
  position: {
    whiteSpace: 'pre',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '10px',
    letterSpacing: '0.75px',
    color: '#1C2F49',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'end',
    fontWeight: 400,
    marginRight: '20px',
  },
  rowContainer: {
    width: '100%',
    boxSizing: 'content-box',
    borderLeft: '#FBFCFD solid 4px',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '100%',
  },
  selectedRow: {
    borderLeft: '#0F78FD solid 3px',
    backgroundColor: '#F9FBFF',
  },
  infoContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    overflow: 'hidden',
    width: '100%',
    marginLeft: '15px',
  },
});

function ConversationLeftMenuItem(props) {
  const { id, updatedConvestions, setShow } = props;
  const { asLoggedUserDetails, type } = useAccount();
  const [unread, setUnread] = useState(0);
  const [staticInfo, setStaticInfo] = useState({
    name: '',
    image: '',
    position: '',
  });
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isSelected = queryParams.get('conversationId') === id;

  function formatText(text, size) {
    if (text && text.length > size) {
      text.substring(0, size);
      return `${text.substring(0, size)}...`;
    }
    return text;
  }

  function goToConversation() {
    setShow(false);
    if (!isSelected) {
      eventBus.dispatch(eventBusValues.closeAdvisorDrawer);
      history.push({
        pathname: 'messages',
        search: `?conversationId=${id}`,
      });
    }
  }

  useEffect(() => {
    const selected = updatedConvestions.find(
      (conversation) => conversation.conversationId === id
    );
    if (selected) {
      setStaticInfo({
        image: selected.conversationMemberImage,
        name: selected.conversationMemberDisplayName,
        position: selected.conversationMemberPosition,
      });
      setUnread(selected.unreadCount);
    }
    eventBus.on(`${eventBusValues.onNewMessage}${id}`, (event) => {
      if (
        !window.location.search.includes(id) &&
        event.senderId !== asLoggedUserDetails.userId
      ) {
        setUnread((prevValue) => prevValue + 1);
      } else {
        readSingleMessage({
          accountId: asLoggedUserDetails.userId,
          accountType: type,
          conversationId: id,
          messageId: event.id,
        });
      }
    });
    eventBus.on(`${eventBusValues.onReadConversation}${id}`, () => {
      setUnread(0);
    });

    return () => {
      eventBus.remove(`${eventBusValues.onNewMessage}${id}`);
      eventBus.remove(`${eventBusValues.onReadConversation}${id}`);
    };
  }, []);

  return (
    <div style={classes.container} onClick={goToConversation} role="none">
      <div
        style={{
          ...classes.rowContainer,
          ...(isSelected ? classes.selectedRow : {}),
        }}
      >
        <div style={classes.infoContainer}>
          <div>
            <Avatar
              src={staticInfo.image || DefaultAdvisorAvatar}
              style={{ width: '48px', height: '48px' }}
            />
          </div>
          <div style={classes.cardContainer}>
            <Typography sx={classes.displayName}>
              {formatText(staticInfo.name, 15)}
            </Typography>
            <Typography sx={classes.position}>
              {formatText(staticInfo.position, 20)}
            </Typography>
          </div>
        </div>
        <div style={classes.iconContainer}>
          <IconButton style={{ backgroundColor: '#F5F7FA', padding: '7px' }}>
            <CustomIcon iconname="comments" style={{ fontSize: '21px' }} />
          </IconButton>
          <ConversationNotification
            value={unread}
            styleProps={{
              position: 'absolute',
              top: '17px',
              right: '15px',
              paddingTop: '0px',
              paddingBottom: '1px',
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default ConversationLeftMenuItem;
