import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Button,
  Typography,
  TextField,
  Avatar,
  useMediaQuery,
} from '@mui/material';

const useStyles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  bodyText: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#2E384D',
  },
  input: {
    width: '340px',
    borderRadius: '10px',
    backgroundColor: '#F5F7FA',
    height: '44px',
    marginBottom: '14px',
    border: '1px solid #C7D0DE',
    '& .MuiOutlinedInput-root': {
      height: '44px',
    },
    fieldset: {
      border: 'none',
    },
    input: {
      '&:-webkit-autofill': {
        transitionDelay: '9999s',
        transitionProperty: 'background-color, color, height, width',
      },
    },
  },
  label: {
    color: '#97A3B7',
    fontSize: '14px',
    fontWeight: '500',
    top: '-5px',
  },
  button: {
    width: '340px',
    height: '44px',
    borderRadius: '50px',
    marginTop: '10px',
    fontWeight: '700',
    fontSize: '14px',
    backgroundColor: '#3171F6',
    boxShadow: '0px 8px 15px 0px #E6EDFE26',
    '&:hover': {
      backgroundColor: '#3171F6',
      boxShadow: '0px 8px 15px 0px #E6EDFE26',
    },
    '&:disabled': {
      backgroundColor: '#E0E0E0',
      boxShadow: '0px 8px 15px 0px #E6EDFE26',
      opacity: '1',
    },
  },
});

const AdvisorPublicJoinForm = ({ onSubmit, companySlug, image }) => {
  const classes = useStyles();
  const [loading, setIsLoading] = useState(false);
  const location = useLocation();
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const [formData, setFormData] = useState({
    givenName: '',
    surName: '',
    email: '',
  });

  const { givenName, surName, email } = formData;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    setIsLoading(true);
    if (givenName && surName && email) {
      onSubmit(formData, setIsLoading);
    }
  };

  function getText() {
    if (location.pathname.includes('advisor')) {
      return "You've been invited to participate as an advisor on ";
    }
    return "You've been invited to help manage ";
  }

  const isFormValid = givenName && surName && email;

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const emailParam = params.get('email');
    if (emailParam) {
      setFormData((prevData) => ({ ...prevData, email: emailParam }));
    }
  }, [location.search]);

  return (
    <Box sx={classes.root}>
      {image && image.id && (
        <Avatar
          src={`${process.env.REACT_APP_IMAGES_URL}${image.id}`}
          sx={{ width: '120px', height: '120px', marginBottom: '20px' }}
        />
      )}
      <Typography variant="h2" sx={{ fontSize: '28px', fontWeight: '700' }}>
        Welcome!
      </Typography>
      <Box margin="16px 0 32px" sx={{ width: isXS ? '340px' : null }}>
        <Typography sx={classes.bodyText}>
          {getText()}
          <span style={{ fontWeight: '700' }}>{companySlug}'s</span>
        </Typography>
        <Typography sx={classes.bodyText}>
          AdvisoryCloud. Fill out the information below to get access.
        </Typography>
      </Box>
      <TextField
        sx={classes.input}
        InputLabelProps={{
          style: classes.label,
        }}
        label="First name"
        name="givenName"
        value={givenName}
        onChange={handleChange}
      />
      <TextField
        sx={classes.input}
        InputLabelProps={{
          style: classes.label,
        }}
        label="Last name"
        name="surName"
        value={surName}
        onChange={handleChange}
      />
      <TextField
        sx={classes.input}
        InputLabelProps={{
          style: classes.label,
        }}
        label="Email"
        name="email"
        value={email}
        onChange={handleChange}
      />
      <Button
        sx={classes.button}
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={!isFormValid || loading}
      >
        Continue
      </Button>
    </Box>
  );
};

export default AdvisorPublicJoinForm;
