import React, { useState, useRef, useEffect } from 'react';
import { useMediaQuery, IconButton } from '@mui/material';
import {
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  HighlightOff,
} from '@mui/icons-material';

const useStyles = () => ({
  carouselSlideContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '100%',
  },
  carouselSlideComponent: {
    height: '100%',
    overflow: 'hidden',
    width: '90%',
    transition: 'all 0.5s ease-in-out',
    position: 'relative',
    display: 'flex',
  },
  carouselSlideFade: {
    width: '15px',
    background:
      'linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.4962359943977591) 50%, rgba(255,255,255,0) 100%)',
    position: 'absolute',
    height: '100%',
    right: '0px',
    zIndex: 999,
  },
  buttonLeft: {
    border: `1px solid`,
    borderColor: '#C9C9DB',
    zIndex: 999,
    marginRight: '5px',
  },
  buttonRight: {
    border: `1px solid`,
    borderColor: '#C9C9DB',
    zIndex: 999,
  },
  deleteButton: {
    zIndex: 999,
    position: 'absolute',
    top: '2px',
    right: '2px',
    padding: '0px',
    margin: '0px',
    display: 'flex',
    width: '18px',
    height: '18px',
    borderRadius: '50%',
    overflow: 'hidden',
  },
  slideRightArrow: {
    paddingLeft: '2px',
  },
  slideLeftArrow: {
    paddingRight: '2px',
  },
  deleteIcon: {
    fontSize: '22px',
    backgroundColor: 'white',
    borderRadius: '50%',
    padding: '0px',
    margin: '0px',
  },
  itemsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    transform:
      'translateX(calc( (var(--offset) * ((var(--elementWidth) * -1px) + -15px))))',
    transition: 'transform 1s ease-out',
    listStyleType: 'none',
    paddingLeft: '0px',
  },
  slide: {
    border: 'none',
    marginRight: '15px',
    ariaHidden: true,
    position: 'relative',
  },
  slideSelected: {
    border: `2px solid`,
    borderColor: '#0F78FD',
    borderRadius: '5px',
    marginRight: '20px',
    ariaHidden: true,
    overflow: 'hidden',
    zIndex: 999,
    position: 'relative',
    WebkitAnimation: 'fadeOut 500ms',
    MozAnimation: 'fadeOut 500ms',
    animation: 'fadeOut 500ms',
  },
  innerButton: {
    outline: 'none',
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    '& :focus': {
      outline: 'none',
    },
    padding: '0px',
    margin: '0px',
    display: 'flex',
    width: 'fit-content',
    height: 'fit-content',
  },
});

function GenericCarousel(props) {
  const classes = useStyles();
  const { children, deleteElement, slideSelected, isEdit } = props;
  const listRef = useRef();
  const [slideIndex, setSlideIndex] = useState(0);
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const childWidth = children[0].props.width;

  function prevSlide() {
    if (slideIndex === 0) {
      setSlideIndex(0);
    } else {
      setSlideIndex(slideIndex - 1);
      slideSelected(slideIndex - 1);
    }
  }

  function nextSlide() {
    if (slideIndex === children.length - 1) {
      setSlideIndex(slideIndex);
      slideSelected(slideIndex);
    } else {
      setSlideIndex(slideIndex + 1);
      slideSelected(slideIndex + 1);
    }
  }

  function slideStartMoment() {
    if (!isSM && slideIndex === 3) {
      return 1;
    }
    if (!isSM && slideIndex > 3) {
      return slideIndex - 2;
    }
    if (!isSM && slideIndex < 3) {
      return 0;
    }

    return slideIndex;
  }

  function selectItem(indexValue) {
    setSlideIndex(indexValue);
  }

  function isWidth() {
    if (childWidth === null || childWidth === undefined) {
      return '105';
    }
    return childWidth;
  }

  function deleteSlideLogic(data) {
    if (data.originIndex === slideIndex && data.originIndex !== 0) {
      deleteElement(data.key);
      slideSelected(data.originIndex - 1);
      setSlideIndex(data.originIndex - 1);
    } else if (
      data.originIndex !== slideIndex &&
      data.originIndex === 0 &&
      children.length !== 1
    ) {
      deleteElement(data.key);
      slideSelected(slideIndex);
      setSlideIndex(slideIndex - 1);
    } else if (
      data.originIndex === slideIndex &&
      data.originIndex === 0 &&
      children.length === 1
    ) {
      deleteElement(data.key);
      slideSelected(-1);
      setSlideIndex();
    } else {
      deleteElement(data.key);
    }
  }

  useEffect(() => {
    slideSelected(0);
  }, []);

  return (
    <div style={classes.carouselSlideContainer} id="carousel">
      {children.length > 3 ? (
        <IconButton
          color={slideIndex === 0 ? 'secondary' : 'primary'}
          disabled={slideIndex === 0}
          sx={classes.buttonLeft}
          onClick={() => prevSlide()}
          component="span"
          size="small"
        >
          <ArrowBackIosOutlined sx={classes.slideLeftArrow} fontSize="small" />
        </IconButton>
      ) : null}

      <div style={classes.carouselSlideComponent}>
        <ul
          ref={listRef}
          style={{
            ...classes.itemsContainer,
            '--offset': slideStartMoment(),
            '--elementWidth': isWidth(),
          }}
        >
          {children.map((obj, index) => {
            const selected = index === slideIndex;
            const cloneElement = React.cloneElement(obj, {
              selected,
            });

            return (
              <li
                key={children[index].props.img}
                style={selected ? classes.slideSelected : classes.slide}
              >
                <button
                  onClick={() => {
                    selectItem(index);
                    slideSelected(index);
                  }}
                  type="button"
                  style={classes.innerButton}
                >
                  {cloneElement}
                </button>
                {isEdit ? (
                  <IconButton
                    color="primary"
                    component="span"
                    sx={classes.deleteButton}
                    onClick={() => {
                      deleteSlideLogic({
                        originIndex: index,
                        key: children[index].key,
                      });
                    }}
                    size="small"
                  >
                    <HighlightOff sx={classes.deleteIcon} />
                  </IconButton>
                ) : null}
              </li>
            );
          })}
        </ul>
        <div style={classes.carouselSlideFade} />
      </div>
      {children.length > 3 ? (
        <IconButton
          color={slideIndex === children.length - 1 ? 'secondary' : 'primary'}
          component="span"
          disabled={slideIndex === children.length - 1}
          sx={classes.buttonRight}
          onClick={() => nextSlide()}
          size="small"
        >
          <ArrowForwardIosOutlined
            sx={classes.slideRightArrow}
            fontSize="small"
          />
        </IconButton>
      ) : null}
    </div>
  );
}

export default GenericCarousel;
