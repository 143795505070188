import React from 'react';

function Opportunities() {
  return (
    <div>
      <span>Opportunities</span>
    </div>
  );
}

export default Opportunities;
