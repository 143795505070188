import React from 'react';
import { keyframes } from '@mui/system';
import Box from '@mui/material/Box';
import Logo from './logo.svg';

const skeletonKeyframes = keyframes`
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
`;

const movingColor1 = 'rgba(210, 210, 210, 0.349)';
const movingColor2 = 'rgba(230, 230, 230, 0.6)';

const skeletonStyle = {
  animation: `${skeletonKeyframes} 4s linear infinite`,
  background: `linear-gradient(90deg, ${movingColor1} 0%, rgba(200, 200, 200, 1) 50%, ${movingColor2} 100%)`,
  backgroundSize: '200% 100%',
  width: '350px',
  height: '350px',
  maskImage: `url(${Logo})`,
  WebkitMaskImage: `url(${Logo})`,
  maskSize: 'cover',
  WebkitMaskSize: 'cover',
  maskRepeat: 'no-repeat',
  WebkitMaskRepeat: 'no-repeat',
  maskPosition: 'center',
  WebkitMaskPosition: 'center',
};

const containerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100vw',
  height: '100vh',
};

const LoadingSkeleton = () => {
  return (
    <Box sx={containerStyle}>
      <Box sx={skeletonStyle} />
    </Box>
  );
};

export default LoadingSkeleton;
