import React, { useEffect } from 'react';

const AdvisoryCloudPlusOnboarding = () => {
  useEffect(() => {
    const widget = document.createElement('script');
    widget.type = 'text/javascript';
    widget.src = 'https://cdn.oncehub.com/mergedjs/so.js';

    document
      .getElementById('SOIDIV_AdvisoryCloudPlusOnboarding')
      .appendChild(widget);

    return () => {
      const scheduleOnceScript = document.getElementById(
        'SOIDIV_AdvisoryCloudPlusOnboarding'
      );
      if (scheduleOnceScript) {
        scheduleOnceScript.parentNode.removeChild(scheduleOnceScript);
      }
    };
  }, []);

  return (
    <div>
      <div
        id="SOIDIV_AdvisoryCloudPlusOnboarding"
        data-so-page="AdvisoryCloudPlusOnboarding"
        data-height="550"
        data-style="border: 1px solid #d8d8d8; min-width: 290px; max-width: 900px;"
        data-psz="10"
      ></div>
    </div>
  );
};

export default AdvisoryCloudPlusOnboarding;
