import React, { useState } from 'react';
import {
  TextField,
  Typography,
  Checkbox,
  useMediaQuery,
  FormControlLabel,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ACButton from './../../../ACButton';
import {
  Clear,
  SaveOutlined,
  KeyboardReturnOutlined,
} from '@mui/icons-material';

const useStyles = (isSM) => ({
  container: {
    display: 'flex',
    flexDirection: isSM ? 'column-reverse' : 'row',
    marginTop: '10px',
  },
  mainContainer: {
    width: isSM ? '100%' : '70%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  subContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    padding: '0px',
    marginTop: '5px',
  },
  subtitle: {
    fontWeight: 600,
    fontSize: '105%',
  },
  bulletList: {
    marginLeft: '3px',
    marginTop: isSM ? '0px' : '-30px',
    marginBottom: '0px',
    paddingLeft: '15px',
  },
  actionContainer: {
    width: isSM ? '100%' : '30%',
    flexDirection: isSM ? 'row' : 'column',
    justifyContent: isSM ? 'space-between' : 'start',
    justifyItems: isSM ? 'stretch' : 'start',
    display: 'flex',
    marginTop: isSM ? '-10px' : '5px',
    marginBottom: isSM ? '5px' : '0px',
    marginRight: isSM ? '0px' : '-24px',
    marginLeft: '0px',
  },
  icon: {
    fontSize: isSM ? '15px' : '25px',
    marginRight: isSM ? '25px' : '20px',
  },
  deleteIcon: {
    fontSize: '17px',
    marginLeft: '3px',
    bottom: '-4px',
    position: 'relative',
    display: 'inline',
    cursor: 'pointer',
  },
  enterIcon: {
    fontSize: '15px',
    bottom: '-3px',
    position: 'relative',
    display: 'inline',
  },
});

function EditExperience(props) {
  const { t } = useTranslation();
  const { experience, savePosition, discardChanges, tempChanges } = props;
  const [position, setPosition] = useState(experience.position);
  const [company, setCompany] = useState(experience.company);
  const [startDate, setStartDate] = useState(
    experience.startDate ? new Date(experience.startDate, 1, 1) : null
  );
  const [endDate, setEndDate] = useState(
    experience.endDate && experience.endDate !== 'Present'
      ? new Date(experience.endDate, 1, 1)
      : null
  );
  const [notes, setNotes] = useState(experience.notes || []);
  const [isCurrent, setIsCurrent] = useState(experience.endDate === 'Present');
  const [isDisplayPosition, setIsDisplayPosition] = useState(
    experience.displayTitle
  );
  const [newNote, setNewNote] = useState('');

  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);

  function currentCheckbox() {
    return (
      <Checkbox
        value={isCurrent || false}
        color="primary"
        defaultChecked={isCurrent || false}
        onChange={(event, vals) => {
          setIsCurrent(vals);
          saveTempChanges();
        }}
      />
    );
  }

  function displayPositionCheckbox() {
    return (
      <Checkbox
        value={isDisplayPosition || false}
        color="primary"
        defaultChecked={isDisplayPosition || false}
        onChange={(event, vals) => {
          setIsDisplayPosition(vals);
          saveTempChanges();
        }}
      />
    );
  }

  function removeNote(index) {
    setNotes(notes.filter((_, i) => i !== index));
  }

  function addNote(event) {
    if (event.key === 'Enter' && event.target.value) {
      setNotes([...notes, event.target.value]);
      setNewNote('');
    }
  }

  function saveChanges() {
    if (position && company && startDate && (endDate || isCurrent)) {
      let newEndDate;
      if (endDate) {
        newEndDate = endDate.getFullYear();
      }
      const newExp = {
        position,
        company,
        startDate: startDate ? startDate.getFullYear() : null,
        endDate: isCurrent ? 'Present' : newEndDate,
        notes,
        displayTitle: isDisplayPosition,
      };
      savePosition(newExp);
    }
  }

  function saveTempChanges() {
    let newEndDate;
    if (endDate) {
      newEndDate = endDate.getFullYear();
    }
    const newExp = {
      position,
      company,
      startDate: startDate ? startDate.getFullYear() : null,
      endDate: isCurrent ? 'Present' : newEndDate,
      notes,
      displayTitle: isDisplayPosition,
    };
    tempChanges(newExp);
  }

  function isDisabled() {
    return !(position && company && startDate && (endDate || isCurrent));
  }

  function bulletHelperText() {
    return (
      <Typography
        onClick={() => {
          if (newNote) {
            setNotes([...notes, newNote]);
            setNewNote('');
          }
        }}
        style={{ display: 'inline', float: 'right', cursor: 'pointer' }}
      >
        Press
        <b> Enter</b>
        <KeyboardReturnOutlined sx={classes.enterIcon} />
      </Typography>
    );
  }

  return (
    <div style={classes.container}>
      <div style={classes.mainContainer}>
        <div style={classes.subContainer}>
          <TextField
            placeholder="Position"
            variant="outlined"
            size="small"
            value={position || ''}
            fullWidth
            onChange={(evt) => {
              setPosition(evt.target.value);
              saveTempChanges();
            }}
          />
        </div>
        <div style={classes.subContainer}>
          <TextField
            placeholder="Company"
            variant="outlined"
            size="small"
            value={company || ''}
            fullWidth
            onChange={(evt) => {
              setCompany(evt.target.value);
              saveTempChanges();
            }}
          />
        </div>

        <div
          style={{
            ...classes.subContainer,
            flexDirection: isSM ? 'column' : 'row',
          }}
        >
          <FormControlLabel
            control={currentCheckbox()}
            label="It’s my current position"
          />
          <FormControlLabel
            control={displayPositionCheckbox()}
            label="Display position"
          />
        </div>
        <div style={classes.subContainer}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              views={['year']}
              value={startDate}
              minDate={new Date(1950, 1, 1)}
              maxDate={endDate || new Date()}
              onChange={(newValue) => {
                setStartDate(newValue);
                saveTempChanges();
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  style={{ width: isSM ? '50%' : '30%' }}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: 'Start',
                  }}
                />
              )}
            />
            {!isCurrent ? (
              <DatePicker
                views={['year']}
                value={endDate}
                minDate={startDate || new Date(1950, 1, 1)}
                maxDate={new Date()}
                onChange={(newValue) => {
                  setEndDate(newValue);
                  saveTempChanges();
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    style={{ marginLeft: 5, width: isSM ? '50%' : '30%' }}
                    size="small"
                    inputProps={{
                      ...params.inputProps,
                      placeholder: 'End',
                    }}
                  />
                )}
              />
            ) : null}
          </LocalizationProvider>
        </div>
        <div style={{ ...classes.subContainer, marginTop: '10px' }}>
          <Typography sx={classes.subtitle}>
            {t('ADVISOR-EXPERIENCE-POINTS')}
          </Typography>
        </div>
        <div style={classes.subContainer}>
          <TextField
            placeholder="Add bullet point"
            variant="outlined"
            size="small"
            value={newNote}
            fullWidth
            helperText={bulletHelperText()}
            onChange={(evt) => setNewNote(evt.target.value)}
            onKeyPress={(evt) => {
              addNote(evt);
              saveTempChanges();
            }}
          />
        </div>
        <div style={classes.subContainer}>
          {notes ? (
            <ul style={classes.bulletList}>
              {notes.map((bullet, index) => (
                <li>
                  {bullet}
                  <Clear
                    onClick={() => {
                      removeNote(index);
                      saveTempChanges();
                    }}
                    sx={classes.deleteIcon}
                  />
                </li>
              ))}
            </ul>
          ) : null}
        </div>
      </div>
      <div style={classes.actionContainer}>
        <ACButton
          color="primary"
          variant="outlined"
          size="medium"
          style={{
            borderWidth: '2px',
            borderRadius: '5px',
            marginLeft: '0px',
            width: isSM ? '100%' : '79%',
          }}
          onClick={discardChanges}
        >
          <Clear sx={classes.icon} />
          <Typography style={{ fontSize: isSM ? '80%' : '100%' }}>
            {t('ADVISOR-DISCARD-CHANGES')}
          </Typography>
        </ACButton>
        <ACButton
          color="primary"
          size="medium"
          disabled={isDisabled()}
          style={{
            borderWidth: 2,
            borderRadius: 5,
            marginRight: 0,
            marginTop: isSM ? 0 : 10,
            width: isSM ? '100%' : '79%',
          }}
          onClick={saveChanges}
        >
          <SaveOutlined sx={classes.icon} />
          <Typography style={{ fontSize: isSM ? '80%' : '100%' }}>
            {t('ADVISOR-EXPERIENCE-SAVE')}
          </Typography>
        </ACButton>
      </div>
    </div>
  );
}

export default EditExperience;
