import React, { useState } from 'react';
import { useMediaQuery, Paper, Typography, Button, Box } from '@mui/material';
import { ReactComponent as AdvisorsGroup } from '../../images/advisors-group.svg';
import { useAccount } from '../../contexts/Account';
import { getAdvisorQueryParams } from '../../utils/queryParams';
import IframeEmbedModal from '../../components/IframeEmbedModal';
import { is } from 'date-fns/locale';

const useStyles = (isSM) => ({
  screenContainer: {
    width: '100%',
    paddingTop: isSM ? '15px' : '40px',
    paddingLeft: isSM ? '15px' : '50px',
    paddingRight: isSM ? '15px' : '50px',
    display: 'flex',
    flexDirection: isSM ? 'column' : 'row',
  },
  screenContentContainer: {
    width: isSM ? '100%' : '728px',
    display: 'flex',
    flexDirection: 'column',
  },
  card: {
    width: '100%',
    backgroundColor: 'white',
    borderRadius: '10px',
    paddingTop: '25px',
    paddingLeft: isSM ? '15px' : '38px',
    paddingRight: isSM ? '15px' : '38px',
    paddingBottom: isSM ? '15px' : '35px',
    border: 'solid 1px #F0F1F3',
  },
  text: {
    fontWeight: 'bold',
    width: isSM ? '85%' : '93%',
    fontSize: '14px',
  },
  buttonText: {
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '24px',
    textTransform: 'none',
    width: '100%',
    border: '2px solid #2C71F6',
    borderRadius: '8px',
    marginBottom: '8px',
    height: '46px',
  },
});

function BuildYourOwnBoardTile() {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { accountDetails, isFreeTrial, isFreemium } = useAccount();
  const classes = useStyles(isSM);
  const [openIframe, setOpenIframe] = useState(false);
  const iFrameSource = `https://register.advisorycloud.com/mab-give-away/?${getAdvisorQueryParams(
    { accountDetails, isFreeTrial, isFreemium }
  )}`;

  function getB2BAccountColor() {
    if (accountDetails.freeB2BAccountStatus === 'Active') {
      return '#19AD6E';
    }
    if (accountDetails.freeB2BAccountStatus === 'Paused') {
      return '#FFD700';
    }
    if (accountDetails.freeB2BAccountStatus === 'Cancelled') {
      return '#232B35';
    }
    if (accountDetails.freeB2BAccountStatus === 'Shared') {
      return '#3171F6';
    }
    return '#FF0000';
  }
  function goToCreateBoard() {
    window.location.assign(
      `https://advisorycloud.com/add-a-board-to-your-membership?${getAdvisorQueryParams(
        { accountDetails, isFreeTrial, isFreemium }
      )}`,
      '_blank'
    );
  }

  return (
    <div style={classes.screenContainer}>
      <div style={classes.screenContentContainer}>
        <Paper elevation={0} sx={{ ...classes.card, display: 'flex' }}>
          <AdvisorsGroup
            style={{
              marginRight: isSM ? '18px' : '16px',
              width: isSM ? '160px' : '80px',
            }}
          />
          <div
            style={{
              paddingRight: '12px',
              marginTop: '12px',
              paddingLeft: isSM ? '-20px' : null,
            }}
          >
            <Typography variant="h1" sx={classes.text}>
              Build your own Advisory Board
            </Typography>
            <Typography mt="20px" ml={isSM ? '-58px' : null}>
              Status:{' '}
              <span style={{ color: getB2BAccountColor() }}>
                {accountDetails.freeB2BAccountStatus || 'Inactive'}
              </span>
            </Typography>
            <Typography mt="12px" ml={isSM ? '-58px' : null}>
              Billing: Included free with your advisor membership*
            </Typography>
            {isSM &&
              (!accountDetails.freeB2BAccountStatus ||
                accountDetails.freeB2BAccountStatus === 'Inactive') && (
                <Box
                  mt="20px"
                  ml={isSM ? '-58px' : null}
                  mr={isSM ? '-14px' : null}
                >
                  <Button
                    onClick={goToCreateBoard}
                    disableRipple
                    sx={{
                      backgroundColor: '#2C71F6',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: '#2C71F6',
                      },
                      ...classes.buttonText,
                    }}
                  >
                    Activate
                  </Button>
                  <Button
                    onClick={() => {
                      setOpenIframe(true);
                    }}
                    disableRipple
                    sx={{
                      backgroundColor: 'white',
                      color: '#2C71F6',
                      '&:hover': {
                        backgroundColor: 'white',
                      },
                      ...classes.buttonText,
                    }}
                  >
                    Give away
                  </Button>
                </Box>
              )}
            <Typography
              mt="12px"
              ml={isSM ? '-58px' : null}
              sx={{ fontSize: '12px', lineHeight: '18px', color: '#646D7A' }}
            >
              *As an advisor on AdvisoryCloud you have the option to build an
              advisory board for your company, team, or a special project at no
              additional cost. You can also give this credit away to someone
              else in your network.
            </Typography>
          </div>
          {!isSM &&
            (!accountDetails.freeB2BAccountStatus ||
              accountDetails.freeB2BAccountStatus === 'Inactive') && (
              <Box sx={{ minWidth: '116px' }}>
                <Button
                  onClick={goToCreateBoard}
                  disableRipple
                  sx={{
                    backgroundColor: '#2C71F6',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#2C71F6',
                    },
                    ...classes.buttonText,
                  }}
                >
                  Activate
                </Button>
                <Button
                  onClick={() => {
                    setOpenIframe(true);
                  }}
                  disableRipple
                  sx={{
                    backgroundColor: 'white',
                    color: '#2C71F6',
                    '&:hover': {
                      backgroundColor: 'white',
                    },
                    ...classes.buttonText,
                  }}
                >
                  Give away
                </Button>
              </Box>
            )}
          <IframeEmbedModal
            openModal={openIframe}
            setOpenModal={setOpenIframe}
            iframeEmbedLink={iFrameSource}
            width={isSM ? '100%' : '650px'}
            height="475px"
          />
        </Paper>
      </div>
    </div>
  );
}

export default BuildYourOwnBoardTile;
