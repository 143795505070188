import { useEffect, useState, useMemo } from 'react';
import { useAccount } from '../contexts/Account';

export default function useAdvisorCanJoin(company) {
  const { accountDetails, currentPlan } = useAccount();

  const [availableBoards, setAvailableBoards] = useState({
    value: false,
    code: 'NO_AVAILABLE_BOARDS',
  });

  useEffect(() => {
    // console.log({ accountDetails, company, currentPlan });

    // console.log(currentPlan.boardsCanJoinCount);
    // console.log(currentPlan.boardsCanJoinCount === undefined);
    // console.log(currentPlan.boardsCanJoinCount === null);

    const memberBoards = accountDetails.boards.memberBoards;
    const appliedBoards = accountDetails.boards.appliedBoards;

    const companyBoardAlreadyExists = [...memberBoards, ...appliedBoards].some(
      (board) => board.id === company.id
    );

    if (companyBoardAlreadyExists) {
      setAvailableBoards({
        value: false,
        code: 'COMPANY_BOARD_ALREADY_EXISTS',
      });
      return;
    }

    if (company.complimentaryBoardPosition) {
      setAvailableBoards({
        value: true,
        code: 'COMPLIMENTARY_BOARD_POSITION',
      });
      return;
    }

    if (company.boardType === 'P2P') {
      const p2pBoardAlreadyExists = [...memberBoards, ...appliedBoards].some(
        (board) => board.boardType === 'P2P'
      );

      if (p2pBoardAlreadyExists) {
        setAvailableBoards({
          value: false,
          code: 'P2P_BOARD_ALREADY_EXISTS',
        });
        return;
      } else {
        setAvailableBoards({
          value: true,
          code: 'P2P_BOARD_AVAILABLE',
        });
        return;
      }
    }

    if (currentPlan.boardsCanJoinCount === -1) {
      setAvailableBoards({
        value: true,
        code: 'UNLIMITED_BOARDS',
      });
      return;
    }

    const invitation = accountDetails.boards.invitedBoards.find(
      (board) => board.id === company.id
    );
    // console.log({ invitation });

    if (invitation && invitation.invitation && !invitation.invitation.bulk) {
      setAvailableBoards({
        value: true,
        code: 'INVITATION_BOARD_AVAILABLE',
      });
      return;
    }

    const seatsFilled = [...memberBoards].filter(filterMemberBoards);
    const appliesFilled = [...appliedBoards].filter(filterAppliedBoards);

    // console.log({
    //   accountDetails,
    //   seatsFilled,
    //   appliesFilled,
    //   currentPlan,
    // });

    if (
      seatsFilled.length + appliesFilled.length <
      currentPlan.boardsCanJoinCount
    ) {
      setAvailableBoards({
        value: true,
        code: 'SEATS_AVAILABLE',
      });
      return;
    }
    // console.log({
    //   seatsFilled: seatsFilled.length,
    //   appliesFilled: appliesFilled.length,
    // });

    setAvailableBoards({
      value: false,
      code: 'NO_AVAILABLE_BOARDS',
    });
  }, [accountDetails.boards, currentPlan, company]);

  return availableBoards;
}

// relationships filters
const filterMemberBoards = (board) =>
  !board.isAcPlus &&
  !board.isComplimentaryBoardPosition &&
  !board.comesFromNonBulkInvite &&
  board.boardType !== 'P2P';

const filterAppliedBoards = (board) =>
  !board.isAcPlus &&
  !board.isComplimentaryBoardPosition &&
  board.boardType !== 'P2P';

export function remainingAdvisorSeats() {
  const { accountDetails, currentPlan } = useAccount();
  const memberBoards = accountDetails.boards.memberBoards;
  const appliedBoards = accountDetails.boards.appliedBoards;

  const remainingSeats = useMemo(() => {
    console.log('setting remaining seats');
    if (currentPlan.boardsCanJoinCount === -1) {
      // return '∞';
      return 'Unlimited';
    }

    const seatsFilled = [...memberBoards].filter(filterMemberBoards);
    const appliesFilled = [...appliedBoards].filter(filterAppliedBoards);
    const count =
      currentPlan.boardsCanJoinCount -
      (seatsFilled.length + appliesFilled.length);

    if (count < 0) {
      return 0;
    }

    return count;
  }, [accountDetails.boards, currentPlan]);

  return remainingSeats;
}
