import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import { TextField } from 'formik-mui';
import { Formik, Field } from 'formik';
import CustomIcon from '../../../../../icons/customIcon';
import * as Yup from 'yup';
import DecoratedButton from '../decoratedButton';
import { useAccount } from '../../../../../contexts/Account';
import { updateAdvisorEmail } from '../../../../../services/advisor_services';

const useStyles = (isSM) => ({
  topRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  titleText: {
    fontWeight: 'bold',
  },
  bottomRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '15px',
    width: '100%',
  },
  buttonsContainer: {
    display: 'flex',
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: isSM ? 'column' : 'row',
    justifyContent: isSM ? 'center' : 'flex-start',
    alignItems: isSM ? 'center' : 'flex-start',
  },
  formElementButton: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingTop: '10px',
    flexShrink: '0px',
  },
  acIcon: {
    marginBottom: '1px',
    fontSize: '15px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },

  fieldWrapperEmail: {
    width: isSM ? '100%' : '70%',
  },
});

const FieldWrapper = withStyles((theme) => ({
  root: {
    '& .MuiInputBase-input': {
      color: theme.palette.common.black,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.secondary.main,
    },
    '& .MuiInputBase-input:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 100px #fafafa inset',
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: theme.palette.primary.main,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.primary.main,
    },
    '& .Mui-error:after': {
      borderBottomColor: theme.palette.error.main,
    },
  },
}))(Field);

function AdvisorEmailChangeForm1(props) {
  const { title, changeFlow, reset, changeSuccessfullCall, actionCallback } =
    props;
  const { t } = useTranslation();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const classes = useStyles(isSM);
  const [isLoading, setIsLoading] = useState(false);
  const { accountDetails, currentUserInfo } = useAccount();

  const ChangeEmailSchema = Yup.object().shape({
    email: Yup.string()
      .email(`${t('USERNAME_REQUIRED')}`)
      .required(`${t('USERNAME_REQUIRED')}`),
  });

  async function sendNewEmail(data) {
    setIsLoading(true);
    try {
      const response = await updateAdvisorEmail({
        ACCESS_TOKEN: currentUserInfo.accessToken.jwtToken,
        ADVISOR_ID: accountDetails.id,
        NEW_EMAIL: data.email,
      });

      if (response.data.updateAdvisorEmail) {
        changeFlow();
        changeSuccessfullCall(true);
      } else {
      }
    } catch (err) {
      let errorMessage = `${t(
        'ADVISOR_SETTING_CARD_FORGOT_PASSWORD_RESPONSE_CALL'
      )}`;
      if (err.errors && err.errors.length) {
        errorMessage = err.errors[0].message;
      }
      console.log(errorMessage);
      reset(errorMessage);
      actionCallback(`FAIL:${errorMessage}`);
      reset(errorMessage);
      changeSuccessfullCall(false);
    }
    setIsLoading(false);
  }

  const getACIcon = (iconName) => {
    return <CustomIcon iconname={iconName} style={classes.acIcon} />;
  };

  return (
    <>
      <div style={classes.topRow}>
        <Typography variant="h1_old_font" sx={classes.titleText}>
          {title}
        </Typography>
      </div>
      <div style={classes.bottomRow}>
        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={ChangeEmailSchema}
          validateOnMount
          validateOnChange
          validateOnBlur={false}
        >
          {(formik) => {
            const { handleChange, values, setFieldTouched, isValid } = formik;
            return (
              <form data-testid="formAdvisorChangeEmail" style={classes.form}>
                <FieldWrapper
                  style={classes.fieldWrapperEmail}
                  component={TextField}
                  autoComplete="off"
                  type="email"
                  name="email"
                  variant="outlined"
                  placeholder={accountDetails.email}
                  value={values.email}
                  onChange={(e) => {
                    handleChange(e);
                    setFieldTouched('email');
                  }}
                  inputProps={{
                    'aria-label': `${t('ARIA_LABEL_ADVISOR_CHANGE_EMAIL')}`,
                    'aria-required': 'true',
                  }}
                />
                <div style={classes.buttonsContainer}>
                  <div style={classes.formElementButton}>
                    <DecoratedButton
                      buttonIcon={getACIcon('save-outlined')}
                      buttonText={t('ADVISOR_SETTING_CARD_SAVE_EMAIL')}
                      clickAction={() => sendNewEmail(values)}
                      loading={isLoading}
                      isDisabled={!isValid}
                    />
                  </div>
                  <div style={classes.formElementButton}>
                    <DecoratedButton
                      buttonIcon={getACIcon('cancel-outlined')}
                      buttonText={t('ADVISOR_SETTING_CARD_CANCEL')}
                      clickAction={reset}
                      isDisabled={isLoading}
                    />
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </>
  );
}

export default AdvisorEmailChangeForm1;
