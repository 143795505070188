import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { Box, Modal, Typography, useMediaQuery, Button } from '@mui/material';
import AdvisoryCloudLogo from '../../components/AdvisoryCloudLogo';

const useStyles = (isSM) => ({
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isSM ? '95%' : '637px',
    background: '#fff',
    boxShadow: '1px 2px 5px #bebebe',
    border: 'solid 1px #E6EDFE',
    p: 4,
  },
  header: {
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 400,
    color: '#273762',
    paddingTop: '15px',
  },
  description: {
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: 400,
    color: '#7E7E88',
    paddingTop: '8px',
    margin: isSM ? '0px' : '0px 110px',
  },
  logoContainer: {
    marginTop: isSM ? '2em' : '4em',
    width: '100%',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
  },
  dialogContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '20px',
  },
  viewProfileButton: {
    width: '160px',
    height: '35px',
    marginBottom: '20px',
    textTransform: 'none',
    fontSize: '12px',
    border: '1px solid #0F78FD',
    color: '#0F78FD',
    background: '#0F78FD',
    color: '#FFF !important',
    '&:hover': {
      background: '#0F78FD',
    },
  },
});

export default function LoginModal({ loginModal, setLoginModal }) {
  const { t } = useTranslation();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const history = useHistory();
  const location = useLocation();

  function handleClose() {
    setLoginModal(false);
  }

  function login() {
    history.push('/login');
  }

  function register() {
    window.location.assign(
      'https://register.advisorycloud.com/advisory-board-plans-overview/'
    );
  }

  return (
    <div style={classes.dialogContainer}>
      <Modal
        open={loginModal}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <Box sx={classes.container}>
          <>
            <div style={classes.logoContainer}>
              <AdvisoryCloudLogo
                removeHyperlink
                onClick={() => {
                  if (location.pathname !== '/dashboard') {
                    history.push('/dashboard');
                  }
                }}
                width={200}
              />
            </div>
            <div>
              <Typography variant="old_font" sx={classes.header}>
                {t('NOT-GATED-IN-TEXT')}
              </Typography>
            </div>
            <div style={classes.description}>
              <Typography variant="old_font">
                {t('NOT-GATED-IN-DESCRIPTION')}
              </Typography>
            </div>
            <div style={classes.actionsContainer}>
              <Button
                variant="outlined"
                onClick={() => register()}
                sx={classes.viewProfileButton}
              >
                {t('NOT-GATED-MODAL-START-FREE-TEXT')}
              </Button>
              <Button variant="text" onClick={() => login()}>
                {t('NOT-GATED-MODAL-LOGIN-TEXT')}
              </Button>
            </div>
          </>
        </Box>
      </Modal>
    </div>
  );
}
