import React, { useEffect, useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { useAccount } from '../../contexts/Account';
import AdvisorsTopBar from '../../components/Advisor/TopBar';
import AdvisorFilter from '../../components/Advisor/Filter';
import LoadingIndicatorLogo from '../../components/LoadingIndicatorLogo';
import MyAdvisorsContent from '../../components/Company/MyAdvisorsContent';
import CompanyEmptyState from '../../components/EmptyState/CompanyEmptyState';
import {
  getMyAdvisors,
  searchMyAdvisors,
} from '../../services/company_services';
import Treatment from '../../components/Treatment';
import { featureFlags } from '../../splitSetup';

const getClasses = (isSM) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: isSM ? '10px' : '30px',
    paddingTop: 0,
    paddingBottom: '20px',
    paddingRight: isSM ? '10px' : '30px',
  },
});

const PAGE_SIZE = 10;
let endPagination = false;
let offset = 0;
let cleanFilters = true;
let filters = {};

function InvitedAdvisors(props) {
  const { boxRef } = props;
  const {
    accountDetails,
    currentUserInfo,
    asLoggedUserDetails,
    isImpersonated,
  } = useAccount();
  const [myAdvisors, setMyAdvisors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = getClasses(isSM);

  const contact = isImpersonated
    ? accountDetails.contacts.find((c) => c.id === asLoggedUserDetails.userId)
    : accountDetails.contacts.find((c) => c.id === currentUserInfo.username);
  function advisorsCall() {
    setIsLoading(true);
    getMyAdvisors({
      COMPANY_ID: accountDetails.id,
      TYPE: 'invitedAdvisors',
      USER_ID: contact.id,
      LIMIT: PAGE_SIZE,
      START_KEY: offset,
      RAW_DATA: true,
    })
      .then(({ data }) => {
        if (offset === 0) {
          setMyAdvisors(data.getMyAdvisors);
        } else {
          setMyAdvisors((prev) => [...prev, ...data.getMyAdvisors]);
        }
        if (data.getMyAdvisors.length < PAGE_SIZE) {
          endPagination = true;
        }
        offset += 1;
      })
      .catch((err) => {
        console.log({ err });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function handleSearch(props) {
    offset = 0;
    const emptyFilters =
      (!props.keywords || props.keywords === '') &&
      (!props.skills || props.skills.length === 0) &&
      (!props.industries || props.industries.length === 0);
    cleanFilters = emptyFilters;
    filters = { ...props };
    endPagination = false;
    if (boxRef && boxRef.current) {
      boxRef.current.lastScrollHeight = null;
    }
    if (emptyFilters) {
      advisorsCall();
    } else {
      setMyAdvisors([]);
      setIsLoading(true);
      searchMyAdvisors({
        SEARCH_STRING: props.keywords,
        OFFSET: 0,
        SKILLS_TAGS_IDS: props.skills.map((skill) => skill.id),
        INDUSTRY_TAGS_IDS: props.industries.map((industry) => industry.id),
        POSITION_TAGS_IDS: [],
        COMPANY_ID: accountDetails.id,
        CONTACT_ID: contact.id,
        TYPE: 'invitedAdvisors',
      })
        .then(({ data }) => {
          if (data.searchMyAdvisors.advisors.length < PAGE_SIZE) {
            endPagination = true;
          }
          setMyAdvisors(data.searchMyAdvisors.advisors);
          offset = data.searchMyAdvisors.offset;
        })
        .catch((err) => {
          console.log({ err });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }

  function paginate() {
    if (!endPagination) {
      if (cleanFilters) {
        advisorsCall();
      } else {
        setIsLoading(true);
        searchMyAdvisors({
          SEARCH_STRING: filters.keywords,
          OFFSET: offset,
          SKILLS_TAGS_IDS: filters.skills,
          INDUSTRY_TAGS_IDS: filters.industries,
          POSITION_TAGS_IDS: [],
          COMPANY_ID: accountDetails.id,
          CONTACT_ID: contact.id,
          TYPE: 'invitedAdvisors',
        })
          .then(({ data }) => {
            setMyAdvisors((prev) => [
              ...prev,
              ...data.searchMyAdvisors.advisors,
            ]);
            if (data.searchMyAdvisors.advisors.length < PAGE_SIZE) {
              endPagination = true;
            }
            offset = data.searchMyAdvisors.offset;
          })
          .catch((err) => {
            console.log({ err });
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  }

  useEffect(() => {
    advisorsCall();
    const handleScroll = () => {
      const currentScrollHeight = boxRef.current.scrollHeight;
      const currentScrollTop = boxRef.current.scrollTop;
      const offsetHeight = boxRef.current.offsetHeight;
      const threshold = 50;

      if (
        currentScrollHeight - offsetHeight - threshold <= currentScrollTop &&
        !isLoading
      ) {
        if (boxRef.current.lastScrollHeight !== currentScrollHeight) {
          paginate();
          boxRef.current.lastScrollHeight = currentScrollHeight;
        }
      }
    };

    if (boxRef && boxRef.current) {
      boxRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (boxRef && boxRef.current) {
        boxRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <>
      <AdvisorsTopBar boxRef={boxRef} />
      <div style={classes.container}>
        {accountDetails.invitedAdvisors &&
          accountDetails.invitedAdvisors.length > 0 && (
            <AdvisorFilter
              onSearchButtonClicked={handleSearch}
              hidePeerFilter
            />
          )}
        {isLoading && (
          <div
            style={{
              alignItems: 'center',
              bottom: '0px',
              display: 'flex',
              justifyContent: 'center',
              left: '0px',
              position: 'fixed',
              right: '0px',
              top: '50%',
            }}
          >
            <LoadingIndicatorLogo
              size={200}
              iconFontSize={93}
              iconRight={105}
            />
          </div>
        )}
        {myAdvisors.length > 0 && (
          <Box pt="30px" sx={{ maxWidth: '1200px' }}>
            <MyAdvisorsContent
              endpointResponse={myAdvisors}
              fetchAdvisor
              propsToPass={{ removeBelowButtons: true }}
              contentProps={{ setIsLoading }}
            />
          </Box>
        )}
        {!isLoading && myAdvisors.length === 0 && <CompanyEmptyState />}
      </div>
    </>
  );
}

export default InvitedAdvisors;
