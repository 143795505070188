import React, { useState, useEffect } from 'react';
import { Box, Paper, Typography, Grid } from '@mui/material';
import { useAccount } from '../../contexts/Account';
import CompanyPreferences from '../AccountSettings/CompanyPreferences';
import EditButton from './EditButton';
import FilePondProfile from '../ProfileUploaderFreemium/onlyDisplay';

const useStyles = (isSM) => ({
  card: {
    maxWidth: '1200px',
    width: '100%',
  },
  companyInformationSectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingBottom: '5px',
  },
  title: {
    fontWeight: 'bold',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingRight: '20px',
  },
  storyAndMissionContainer: {
    maxWidth: '1200px',
    wordWrap: 'break-word',
  },
  mainInformationContainer: {
    display: 'flex',
    flexDirection: isSM ? 'column' : 'row',
    justifyContent: 'space-between',
  },
  companyName: {
    marginTop: '20px',
  },
  topTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    color: '#232B35',
    marginTop: '15px',
  },
  profileInformationContainer: {
    height: '120px',
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '15px',
  },
  profileDetails: {
    display: 'flex',
    flexDirection: 'column',
    height: '120px',
    justifyContent: 'space-between',
    marginLeft: '10px',
    marginTop: '5px',
  },
  companyDetails: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '120px',
    justifyContent: 'space-between',
    marginLeft: '10px',
    marginTop: '5px',
    padding: '40px',
  },
  longText: {
    whiteSpace: 'pre-wrap',
  },
  subtitle: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '400',
    color: '#232B35',
    fontStyle: 'normal',
    fontWeight: 700,
    letterSpacing: -0.43,
  },
  skillTag: {
    fontWeight: 500,
    whiteSpace: 'pre-wrap',
    fontSize: '100%',
    fontSize: '16px',
    color: '#232B35',
    backgroundColor: '#EAEFF7',
    borderRadius: '100px',
    lineheight: '24px',
    letterSpacing: '.75px',
    fontSize: '13px',
    padding: '10px',
    margin: '10px',
  },
  section: {
    border: 'solid 1px #E5E5E5',
    borderRadius: '10px',
    padding: '20px',
    marginBottom: '20px',
  },
});

function CompanyInformationViewCard({ isSM, onEditClick }) {
  const classes = useStyles(isSM);
  const { accountDetails } = useAccount();
  const yourInformation = accountDetails.userData;

  return (
    <Paper elevation={0} sx={classes.card}>
      <div style={classes.companyInformationSectionContainer}>
        <Box sx={{ ...classes.section }}>
          <div style={classes.row}>
            <Typography
              variant="body1_old_font"
              sx={{ ...classes.title, ...classes.subtitle }}
            >
              About you
            </Typography>
            <EditButton onClick={onEditClick} fremiumView />
          </div>
          <div style={classes.profileInformationContainer}>
            <FilePondProfile />
            <div style={classes.profileDetails}>
              <Typography variant="body1_old_font">
                {`${yourInformation.givenName} ${yourInformation.surName}`}
              </Typography>
              {yourInformation.title && yourInformation.title.length > 0 && (
                <Typography variant="body1_old_font">
                  {yourInformation.title}
                </Typography>
              )}
              <Typography variant="body1_old_font">
                {yourInformation.email}
              </Typography>
              <Typography variant="body1_old_font">
                {yourInformation.phoneNumber &&
                yourInformation.phoneNumber.phoneNumber &&
                yourInformation.phoneNumber.phoneNumber !== '5555555555'
                  ? yourInformation.phoneNumber.phoneNumber
                  : ''}
              </Typography>
            </div>
          </div>
          {yourInformation.biography &&
            yourInformation.biography.length > 0 && (
              <Typography variant="body1_old_font" sx={classes.topTitle}>
                Biography
              </Typography>
            )}
          <pre style={classes.longText}>
            <Typography variant="body1_old_font">
              {yourInformation.biography}
            </Typography>
          </pre>
        </Box>
        <Box sx={classes.section}>
          <CompanyPreferences freemiumView hideBillingInformation />
        </Box>
        <Box sx={classes.section}>
          <CompanyPreferences freemiumView hidePlanDetails />
        </Box>
      </div>
    </Paper>
  );
}

export default CompanyInformationViewCard;
