import React from 'react';
import { Typography } from '@mui/material';

const useStyles = () => ({
  container: {
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '5px',
    paddingBottom: '5px',
    borderRadius: '25px',
    border: 'solid',
    borderWidth: '2px',
    borderColor: 'primary.main',
    color: 'primary.main',
  },
});

function Tag({ label }) {
  const classes = useStyles();

  return (
    <div style={classes.container}>
      <Typography variant="body1_old_font">{label}</Typography>
    </div>
  );
}

export default Tag;
