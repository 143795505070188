import React, { useState } from 'react';
import {
  Autocomplete,
  Typography,
  Paper,
  OutlinedInput,
  Modal,
  TextField,
  CircularProgress,
} from '@mui/material';
import ACButton from './../../ACButton';
import { useAccount } from './../../../contexts/Account';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import countries from './countries.json';
import { inviteCompanyContact } from './../../../services/company_services';

const useStyles = () => ({
  modalContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    borderRadius: '16px',
    width: '535px',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'start',
  },
  title: {
    marginBottom: '30px',
  },
  field: {
    marginBottom: '20px',
  },
  sendButtonContainer: {
    display: 'flex',
    flex: 'auto',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  option: {
    fontSize: '15px',
    '& > span': {
      marginRight: '10px',
      fontSize: '18px',
    },
  },
  textfieldClass: {
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: '0px',
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: '0px',
    },
  },
});

function NewCompanyMemberModal({
  companyId,
  visible,
  onDismiss,
  onInvitationSend,
  onInvitationSendError,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { accountDetails } = useAccount();
  const [buttonLoading, setButtonLoading] = useState(false);
  const formInitialValues = {
    firstName: '',
    lastName: '',
    title: '',
    phone: '',
    email: '',
    country: '',
  };
  const NewCompanyMemberSchema = Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    title: Yup.string().required(),
    country: Yup.string().required(),
    phone: Yup.string().required().matches(/^\d+$/).min(10).max(10),
    email: Yup.string()
      .required()
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ),
  });

  function countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== 'undefined'
      ? isoCode
          .toUpperCase()
          .replace(/./g, (char) =>
            String.fromCodePoint(char.charCodeAt(0) + 127397)
          )
      : isoCode;
  }

  async function onSendInvitationClick(data) {
    setButtonLoading(true);
    try {
      const response = await inviteCompanyContact({
        userId: accountDetails.userData.id,
        companyId,
        email: data.email,
        givenName: data.firstName,
        phoneCountryCode: data.country,
        phoneNumber: data.phone.toString(),
        surName: data.lastName,
        title: data.title,
      });
      onInvitationSend(response.data.inviteCompanyContact);
    } catch (error) {
      onInvitationSendError(error);
    }
    setButtonLoading(false);
  }

  if (!visible) return null;

  return (
    <Modal open={visible} onClose={onDismiss} sx={classes.modalContainer}>
      <Paper sx={classes.container}>
        <Typography variant="h4_old_font" color="primary" sx={classes.title}>
          {t('NEW-COMPANY-MEMBER-CARD-TITLE')}
        </Typography>
        <Formik
          initialValues={formInitialValues}
          validationSchema={NewCompanyMemberSchema}
          validateOnMount
          onSubmit={onSendInvitationClick}
        >
          {(data) => {
            const isFormValid =
              data.isValid &&
              data.touched.firstName &&
              data.touched.lastName &&
              data.touched.email &&
              data.touched.phone &&
              data.touched.title &&
              data.values.country.length === 2;

            return (
              <form onSubmit={data.handleSubmit}>
                <div style={classes.field}>
                  <label htmlFor="firstName">
                    <Typography variant="body1_old_font">
                      {t('NEW-COMPANY-MEMBER-FIRST-NAME-LABEL')}
                    </Typography>
                  </label>

                  <OutlinedInput
                    data-cy="firstName"
                    data-testid="firstName"
                    id="firstName"
                    value={data.values.firstName}
                    onBlur={data.handleBlur}
                    onChange={data.handleChange}
                    size="sm"
                    fullWidth
                  />
                </div>
                <div style={classes.field}>
                  <label htmlFor="lastName">
                    <Typography variant="body1_old_font">
                      {t('NEW-COMPANY-MEMBER-LAST-NAME-LABEL')}
                    </Typography>
                  </label>

                  <OutlinedInput
                    data-cy="lastName"
                    data-testid="lastName"
                    id="lastName"
                    value={data.values.lastName}
                    onBlur={data.handleBlur}
                    onChange={data.handleChange}
                    size="sm"
                    fullWidth
                  />
                </div>
                <div style={classes.field}>
                  <label htmlFor="title">
                    <Typography variant="body1_old_font">
                      {t('NEW-COMPANY-MEMBER-TITLE-LABEL')}
                    </Typography>
                  </label>

                  <OutlinedInput
                    data-cy="title"
                    data-testid="title"
                    id="title"
                    value={data.values.title}
                    onBlur={data.handleBlur}
                    onChange={data.handleChange}
                    size="sm"
                    fullWidth
                  />
                </div>
                <div style={classes.field}>
                  <label htmlFor="countryCode">
                    <Typography variant="body1_old_font">
                      {t('NEW-COMPANY-MEMBER-COUNTRY-LABEL')}
                    </Typography>
                  </label>

                  <Autocomplete
                    options={countries}
                    autoHighlight
                    onChange={(_, value) =>
                      data.setFieldValue('country', value.code)
                    }
                    classes={{
                      option: classes.option,
                    }}
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <span>{countryToFlag(option.code)}</span>
                        <span>{`${option.label} (${option.code}) +${option.phone}`}</span>
                      </li>
                    )}
                    value={countries.find(
                      (e) => e.code === data.values.country
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        data-cy="country"
                        data-testid="country"
                        id="country"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                </div>
                <div style={classes.field}>
                  <label htmlFor="phone">
                    <Typography variant="body1_old_font">
                      {t('NEW-COMPANY-MEMBER-PHONE-LABEL')}
                    </Typography>
                  </label>

                  <OutlinedInput
                    data-cy="phone"
                    data-testid="phone"
                    id="phone"
                    type="number"
                    inputProps={{
                      style: classes.textfieldClass,
                    }}
                    value={data.values.phone}
                    onBlur={data.handleBlur}
                    onChange={data.handleChange}
                    size="sm"
                    fullWidth
                  />
                </div>
                <div style={classes.field}>
                  <label htmlFor="email">
                    <Typography variant="body1_old_font">
                      {t('NEW-COMPANY-MEMBER-EMAIL-LABEL')}
                    </Typography>
                  </label>

                  <OutlinedInput
                    data-cy="email"
                    data-testid="email"
                    id="email"
                    value={data.values.email}
                    onBlur={data.handleBlur}
                    onChange={data.handleChange}
                    size="sm"
                    fullWidth
                  />
                </div>
                <div style={classes.sendButtonContainer}>
                  <ACButton
                    color="primary"
                    type="submit"
                    size="medium"
                    style={{
                      borderWidth: 2,
                      display: 'flex',
                      backgroundColor: isFormValid
                        ? '#0F78FD'
                        : 'rgba(0, 0, 0, 0.12)',
                    }}
                    disabled={!isFormValid}
                  >
                    {buttonLoading ? (
                      <CircularProgress size={25} sx={{ color: 'white' }} />
                    ) : (
                      <Typography variant="body1_old_font">
                        {t('NEW-COMPANY-MEMBER-SEND-INVITATION-BUTTON-TEXT')}
                      </Typography>
                    )}
                  </ACButton>
                </div>
              </form>
            );
          }}
        </Formik>
      </Paper>
    </Modal>
  );
}

export default NewCompanyMemberModal;
