import React, { useEffect, useState } from 'react';
import { SwipeableDrawer, useMediaQuery } from '@mui/material';
import LoadingIndicatorLogo from './../../LoadingIndicatorLogo';
import eventBus, { eventBusValues } from './../../../eventBus';
import ConversationContainer from './../Container';

const ConversationDrawer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [conversationId, setConversationId] = useState();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    eventBus.on(eventBusValues.triggerConversationDrawer, (data) => {
      if (data.close) {
        setIsOpen(false);
      } else {
        setIsOpen((prevValue) => !prevValue);
      }
      if (data.conversationId) {
        setConversationId(data.conversationId);
      }
    });

    return () => {
      eventBus.remove(eventBusValues.triggerConversationDrawer);
    };
  }, []);

  return (
    <div style={{ width: '100%' }}>
      <SwipeableDrawer
        disableSwipeToOpen
        anchor="right"
        open={isOpen}
        onOpen={() => {}}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        {isOpen ? (
          <div
            style={{
              display: 'flex',
              flex: 'auto',
              width: isSM ? '90vw' : '60vw',
            }}
          >
            <ConversationContainer
              propConversationId={conversationId}
              isDrawer
            />
          </div>
        ) : null}
      </SwipeableDrawer>
    </div>
  );
};

export default ConversationDrawer;
