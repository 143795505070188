import React from 'react';
import { Typography } from '@mui/material';
import ACAvatar from './../../../../ACAvatar';

const useStyles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    width: 'fit-content',
    height: 'fit-content',
  },
  titleName: {
    color: 'theme.palette.info.dark',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '18px',
    padding: 0,
    margin: 0,
    letterSpacing: '-.3px',
    color: '#232B35',
  },
  subtitle: {
    color: 'theme.palette.secondary.dark',
    fontStyle: 'normal',
    fontWeight: 400,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: 'auto',
    marginLeft: '10px',
  },
  titleNameContainer: {
    display: 'flex',
    width: 'auto',
  },
  subtitleContainer: {
    display: 'flex',
    width: 'auto',
  },
});

function PostHeaderAvatar(props) {
  const {
    image,
    titleName,
    subtitle,
    avatarBorderColor,
    avatarSize,
    fontSize,
    fontSeparation,
    isStar,
    isStarFilled,
    starClicked,
    avatarClick,
  } = props;
  const classes = useStyles();

  function renderDays() {
    if (subtitle === null || subtitle === undefined) {
      return <React.Fragment>&nbsp;</React.Fragment>;
    }
    return subtitle;
  }

  function renderSize() {
    if (avatarSize === null || avatarSize === undefined) {
      return 'md';
    }
    return avatarSize;
  }

  function subtitleSize() {
    if (fontSize === 'lg') {
      return 'h1';
    }
    if (fontSize === 'sm') {
      return 'body1';
    }
    if (fontSize === 'xs') {
      return 'caption';
    }
    return 'body1';
  }

  function titleSize() {
    if (fontSize === 'lg') {
      return 'h2';
    }
    if (fontSize === 'sm') {
      return 'body2';
    }
    if (fontSize === 'xs') {
      return 'caption';
    }
    return 'body2';
  }

  return (
    <div style={classes.container}>
      <ACAvatar
        image={image}
        avatarClick={avatarClick}
        size={renderSize()}
        borderColor={avatarBorderColor}
        isStar={isStar}
        isStarFilled={isStarFilled}
        onClick={starClicked}
      />
      <div
        style={{
          ...classes.textContainer,
          cursor: avatarClick ? 'pointer' : 'auto',
        }}
        onClick={avatarClick ? avatarClick : () => {}}
      >
        <div style={classes.titleNameContainer}>
          <Typography variant={titleSize()} sx={classes.titleName}>
            {titleName}
          </Typography>
        </div>
        <div
          style={{
            ...classes.subtitleContainer,
            marginTop: fontSeparation ? fontSeparation : 1,
          }}
        >
          <Typography
            component="div"
            variant={subtitleSize()}
            sx={classes.subtitle}
          >
            {renderDays()}
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default PostHeaderAvatar;
