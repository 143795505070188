import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Paper, Typography, useMediaQuery } from '@mui/material';
import emptyStateImg from './emptyState.png';
import { useAccount } from '../../contexts/Account';
import eventBus, { eventBusValues } from '../../eventBus';

function Card({ title, text }) {
  return (
    <Paper
      elevation={0}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: { xxs: '100%', xs: '245px' },
        height: '238px',
        borderRadius: '16px',
        padding: '30px',
        backgroundColor: '#D7F1FD',
      }}
    >
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: '18px',
          color: '#232B35',
          marginBottom: '20px',
          marginTop: '5px',
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          fontWeight: 300,
          fontSize: { xxs: '16px', xs: '12px' },
          color: '#232B35',
        }}
      >
        {text}
      </Typography>
    </Paper>
  );
}

function CompanyEmptyStateFreemium(props) {
  const { isFreemium } = useAccount();
  const matches = useMediaQuery('(min-width: 787px) and (max-width: 1390px)');
  const history = useHistory();
  const {
    content = 'Start browsing advisors to invite to your boardroom',
    buttonText = 'Schedule meeting',
    buttonAction = () => {
      eventBus.dispatch(eventBusValues.triggerCompanyUpgradeAction);
    },
    buttonId,
  } = props;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xxs: 'column', xs: 'row' },
          alignItems: 'center',
          justifyContent: { xxs: 'center', xs: 'space-between' },
          marginTop: { xxs: '25px', xs: '50px' },
          marginBottom: { xxs: '25px', xs: '50px' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: { xxs: '100%', xs: '333px' },
            padding: { xxs: '20px', xs: '0px' },
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '28px',
              color: '#232B35',
              marginBottom: '30px',
            }}
          >
            {content}
          </Typography>
          <Button
            id={buttonId || null}
            sx={{
              backgroundColor: '#DBF7EB',
              fontWeight: 600,
              fontSize: '20px',
              color: '#232B35',
              width: '100%',
              height: '100px',
              borderRadius: '50px',
              textTransform: isFreemium ? 'none' : 'uppercase',
              '&:hover': {
                backgroundColor: '#DBF7EB',
              },
              marginBottom: { xxs: '10px', xs: '0px' },
            }}
            onClick={buttonAction}
          >
            {buttonText}
          </Button>
        </Box>
        <Box>
          <img src={emptyStateImg} style={{ width: '100%' }} />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 3,
          marginBottom: '50px',
          maxWidth: matches ? '70%' : { xxs: '100%', xs: '80%', md: '100%' },
        }}
      >
        <Card
          sx={{
            order: {
              xxs: 2,
              sm: 1,
            },
          }}
          title="Strategic Guidance"
          text="Talk through your business - Advisors can give you an outside
    perspective and help with setting clear objectives, target markets,
    and a strategy that aligns with your vision and current resources."
        />
        <Card
          sx={{
            order: {
              xxs: 3,
              sm: 2,
            },
          }}
          title="Pitch Deck Review"
          text="Use advisors to review your pitch deck and provide feedback to ensure you go into your investor meetings fully prepared and with a group of advisors that add credibility to your venture."
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: { xxs: '100%', xs: '490px' },
            height: '238px',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px',
            order: matches
              ? -1
              : {
                  xxs: -1,
                  md: 0,
                },
          }}
        >
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '28px',
              color: '#232B35',
              width: { xxs: '100%', xs: '434px' },
              height: { xxs: '100%', xs: '120px' },
              lineHeight: '40px',
              marginBottom: '10px',
            }}
          >
            See how other companies use moderated meetings to help them
            accomplish goals
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '18px',
              color: '#232B35',
              height: '27px',
              lineHeight: '27px',
              letterSpacing: '.5px',
              marginBottom: { xxs: '20px', xs: '0px' },
            }}
          >
            Meet with your entire board or a select group
          </Typography>
        </Box>
        <Card
          sx={{
            order: {
              xxs: 4,
              sm: 4,
            },
          }}
          title="Sales & Marketing"
          text="Share your Sales & Marketing strategy and materials with your advisors to get live guidance and feedback on reaching and converting your ideal customers."
        />
        <Card
          sx={{
            order: {
              xxs: 5,
              sm: 5,
            },
          }}
          title="Investor Demos"
          text="Invite potential investors to your meeting and expand your network of people who can make introductions to potential angel investors, VCs, and PE firms."
        />
        <Card
          sx={{
            order: {
              xxs: 6,
              sm: 6,
            },
          }}
          title="Product Feedback"
          text="Conduct a demo of your MVP, beta, or product live for a group of advisors that make up your target demographic. Get invaluable feedback to help build and grow your product."
        />
        <Card
          sx={{
            order: {
              xxs: 7,
              sm: 7,
            },
          }}
          title="Launch Traction"
          text="Hold a meeting with your advisory board about a new product, promotion, or initiative and ask them to help spread the word. Get early traction on your initiative through their audience and reach."
        />
      </Box>
    </>
  );
}

export default CompanyEmptyStateFreemium;
