import React, { useState } from 'react';
import { useMediaQuery, Paper } from '@mui/material';
import PostCardBottom from '../CardBottom';
import PostCardHeader from './PostCardHeader';
import PostCardBody from './PostCardBody';
import { PostType } from './../../../services/utils/types';

const useStyles = () => ({
  card: {
    backgroundColor: 'transparent',
    borderRadius: '0px',
    width: '100%',
  },
  separator: {
    marginBottom: '15px',
  },
});

const PostCard = (props) => {
  const {
    postData,
    // body
    body,
    video,
    attachments,
    primaryAttachment,
    // likes
    likes,
    likesClick,
    likesFilled,
    likesDisabled,
    // answers
    answers,
    answersClick,
    answersFilled,
    answersBadge,
    answersBadgeContent,
    answersDisabled,
    // comments
    comments,
    commentsClick,
    commentsFilled,
    commentsBadge,
    commentsBadgeContent,
    commentsDisabled,
    // breakthroughs
    breakthroughs,
    breakthroughsClick,
    breakthroughsFilled,
    breakthroughsGold,
    breakthroughsBold,
    breakthroughsDisabled,
    // service clicks
    editClick,
    deleteClick,
    pinClick,
    isPinned,
    bodyClick,
    bodyDisabled,
    // date and preview format
    type,
    dashboardFormat,
    createdAt,
    // avatars & others
    contributorAvatars,
    companyName,
    companyAvatar,
    contributorsClick,
    contributorsDisabled,
    noEllipsis,
    // extra text
    linkAtTheEnd,
    // extras
    isFirst,
    isOpportunityView,
    isSingleBoardMeetingPost,
    boardMeetingLocked,
  } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles();
  const [timeLive, setTimeLive] = useState(false);
  function footerType() {
    if (
      [
        PostType.UPDATE,
        PostType.BOARD_MEETING,
        PostType.INSIGHT,
        PostType.MEETING,
      ].includes(type)
    ) {
      return (
        <PostCardBottom
          size={isSM ? 'xs' : 'lg'}
          // likes
          likes={likes}
          likesClick={likesClick}
          likesFilled={likesFilled}
          likesDisabled={likesDisabled}
          // comments
          comments={comments}
          commentsClick={commentsClick}
          commentsFilled={commentsFilled}
          commentsBadge={commentsBadge}
          commentsBadgeContent={commentsBadgeContent}
          commentsDisabled={commentsDisabled}
          // service props
          avatars={contributorAvatars}
          // clicks
          contributorsClick={contributorsClick}
          contributorsDisabled={contributorsDisabled}
          // extras
          isFirst={isFirst}
          iconSeparation={20}
          isPost
        />
      );
    }
    if (type === PostType.QUESTION) {
      return (
        <PostCardBottom
          size={isSM ? 'xs' : 'lg'}
          // likes
          likes={likes}
          likesClick={likesClick}
          likesFilled={likesFilled}
          likesDisabled={likesDisabled}
          // answers
          answers={answers}
          answersClick={answersClick}
          answersFilled={answersFilled}
          answersDisabled={answersDisabled}
          answersBadge={answersBadge}
          answersBadgeContent={answersBadgeContent}
          // comments
          comments={comments}
          commentsClick={commentsClick}
          commentsFilled={commentsFilled}
          commentsBadge={commentsBadge}
          commentsBadgeContent={commentsBadgeContent}
          commentsDisabled={commentsDisabled}
          // breakthroughs
          breakthroughs={breakthroughs}
          breakthroughsClick={breakthroughsClick}
          breakthroughsFilled={breakthroughsFilled}
          breakthroughsGold={breakthroughsGold}
          breakthroughsBold={breakthroughsBold}
          breakthroughsDisabled={breakthroughsDisabled}
          // extras
          isFirst={isFirst}
          // service
          avatars={contributorAvatars}
          contributorsClick={contributorsClick}
          contributorsDisabled={contributorsDisabled}
          isPost
        />
      );
    }
    return null;
  }

  function arrayLogic(data) {
    if (data === null || data === undefined) {
      return null;
    }
    return data;
  }

  return (
    <Paper elevation={0} sx={classes.card}>
      <div style={classes.separator}>
        <PostCardHeader
          postData={postData}
          type={type}
          initialTime={createdAt}
          companyName={companyName}
          companyAvatar={companyAvatar}
          isOpportunityView={isOpportunityView}
          // dashboardFormat={dashboardFormat ? true : false}
          dashboardFormat={dashboardFormat}
          editClick={editClick}
          pinClick={pinClick}
          isPinned={isPinned}
          deleteClick={deleteClick}
          noEllipsis={noEllipsis}
          setTimeLive={setTimeLive}
        />
      </div>
      <div style={classes.separator}>
        <PostCardBody
          postData={postData}
          body={body}
          bodyClick={bodyClick}
          attachments={arrayLogic(attachments)}
          video={video}
          dashboardFormat={dashboardFormat}
          bodyDisabled={bodyDisabled}
          primaryAttachment={primaryAttachment}
          linkAtTheEnd={linkAtTheEnd}
          isSingleBoardMeetingPost={isSingleBoardMeetingPost}
          boardMeetingLocked={boardMeetingLocked}
          timeLive={timeLive}
        />
      </div>
      {postData.type === PostType.BOARD_MEETING && !isSingleBoardMeetingPost
        ? null
        : footerType()}
    </Paper>
  );
};

export default PostCard;
