import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Button,
  CircularProgress,
  Menu,
  MenuItem,
} from '@mui/material';
import { useAccount } from './../../../contexts/Account';
import IframeEmbedModal from './../../IframeEmbedModal';
import CustomIcon from './../../../icons/customIcon';
import eventBus, { eventBusValues } from './../../../eventBus';

const useStyles = () => ({
  buttonText: {
    fontWeight: 'bold',
  },
  iconStyle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '26px',
    marginRight: '7px',
  },
  buttonContentContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
  },
  buttonContent: {
    display: 'flex',
    alignItems: 'center',
  },
  spinnerContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  spinner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-12px',
    marginLeft: '-12px',
  },
  menu: {
    marginTop: '10px',
    width: '280px',
    borderRadius: '12px',
    border: '1px solid var(--Gray-5, #F5F7FA)',
    background: '#FFF',
    boxShadow: '0px 1px 10px 0px rgba(230, 237, 254, 0.25)',
  },
  menuTitle: {
    color: '#232B35',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '28px',
    letterSpacing: '0.75px',
  },
  menuSubtitle: {
    color: '#646D7A',
    fontFeatureSettings: 'clig off, liga off',
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.25px',
  },
  menuItemContent: {
    borderTop: '1px solid #EFF0F6',
  },
  menuItemIcon: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '2px',
  },
});

function CompanyPostButton(props) {
  const {
    buttonColor,
    buttonSize,
    buttonVariant,
    loading,
    isDisabled,
    onClickPost,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { accountDetails } = useAccount();
  const isButtonDisabled = isDisabled || loading;
  const buttonBackgroundColor = isButtonDisabled ? '#e0e0e0' : '#3171F6';
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const [mouseOverButton, setMouseOverButton] = useState(false);
  const [mouseOverMenu, setMouseOverMenu] = useState(false);

  const history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const enterButton = (event) => {
    setMouseOverButton(true);
    setAnchorEl(event.currentTarget);
  };

  const leaveButton = () => {
    setTimeout(() => {
      setMouseOverButton(false);
    }, 750);
  };

  const enterMenu = () => {
    setMouseOverMenu(true);
  };

  const leaveMenu = () => {
    setMouseOverMenu(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenModal = () => {
    handleClose();
    setOpenModal(true);
  };

  useEffect(() => {
    eventBus.on(eventBusValues.triggerMeetAdvisors, (data) => {
      setOpenModal(true);
    });
    return () => {
      eventBus.remove(eventBusValues.triggerMeetAdvisors);
    };
  }, []);

  const open = mouseOverButton || mouseOverMenu;

  return (
    <>
      <Button
        color={buttonColor}
        size={buttonSize}
        variant={buttonVariant ? buttonVariant : 'contained'}
        disableRipple={true}
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '200px',
          height: '48px',
          backgroundColor: buttonBackgroundColor,
          justifyContent: 'center',
          color: isButtonDisabled ? '#b1b1b1' : '#FFF',
          borderRadius: '25px',
          boxShadow: 'none',
          fontWeight: 700,
          zIndex: 0,
          fontSize: '16px',
          textTransform: 'none',
          '&:hover': {
            backgroundColor: isButtonDisabled
              ? buttonBackgroundColor
              : 'inherit',
            boxShadow: 'none',
          },
          '&:active': {
            transform: 'none',
            boxShadow: 'none',
          },
        }}
        onClick={handleClick}
        // onMouseEnter={enterButton}
        // onMouseLeave={leaveButton}
        disabled={isButtonDisabled}
        id="b2bNavBarFeedbackButton"
      >
        <div style={classes.buttonContentContainer}>
          <div style={classes.buttonContent}>
            <CustomIcon
              iconname="bubbles-question"
              style={classes.iconStyle}
            ></CustomIcon>
            <Typography variant="old_font" sx={classes.buttonText}>
              Get feedback
            </Typography>
          </div>
          {loading && (
            <div style={classes.spinnerContainer}>
              <CircularProgress
                size={32}
                className={classes.spinner}
                color="primary"
              />
            </div>
          )}
        </div>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        sx={{
          '& .MuiMenu-paper': { ...classes.menu },
          '& .MuiMenu-list': { paddingTop: 0, paddingBottom: 0 },
        }}
        // open={open}
        open={Boolean(anchorEl)}
        // MenuListProps={{
        //   onMouseEnter: enterMenu,
        //   onMouseLeave: leaveMenu,
        // }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem key="placeholder" style={{ display: 'none' }} />
        <MenuItem
          id="postActionButton"
          onClick={() => {
            onClickPost();
            handleClose();
          }}
          style={{ paddingTop: '10px', paddingBottom: '10px' }}
        >
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CustomIcon
                iconname="post"
                style={{
                  ...classes.menuItemIcon,
                  fontSize: '19px',
                  paddingLeft: '4px',
                  marginRight: '10px',
                }}
              />
              <Typography sx={{ ...classes.menuTitle }}>Post</Typography>
            </div>
            <Typography sx={{ ...classes.menuSubtitle }}>
              Post something to your
              {accountDetails.userSegment === 'managed_advisory_board'
                ? ' boardroom'
                : ' AdvisoryCloud'}
            </Typography>
          </div>
        </MenuItem>
        <MenuItem
          id="insightsActionButton"
          onClick={() => {
            history.push('/insights');
            handleClose();
          }}
          style={{
            ...classes.menuItemContent,
            paddingTop: '10px',
            paddingBottom: '10px',
          }}
        >
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CustomIcon
                iconname="insights"
                style={{
                  ...classes.menuItemIcon,
                  fontSize: '25px',
                  marginRight: '5px',
                }}
              />
              <Typography sx={{ ...classes.menuTitle }}>
                Get custom insights
              </Typography>
            </div>
            <Typography sx={{ ...classes.menuSubtitle }}>
              Get feedback from custom audiences
            </Typography>
          </div>
        </MenuItem>
        <MenuItem
          id="meetWithAdvisorsActionButton"
          onClick={handleOpenModal}
          style={{
            ...classes.menuItemContent,
            paddingTop: '10px',
            paddingBottom: '10px',
          }}
        >
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CustomIcon
                iconname="calendar-meeting"
                style={{
                  ...classes.menuItemIcon,
                  fontSize: '22px',
                  marginRight: '10px',
                }}
              />
              <Typography sx={{ ...classes.menuTitle }}>
                Meet with advisors
              </Typography>
            </div>
            <Typography sx={{ ...classes.menuSubtitle }}>
              Meet with advisors 1:1 or in a group
            </Typography>
          </div>
        </MenuItem>
      </Menu>

      <IframeEmbedModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        iframeEmbedLink={`https://register.advisorycloud.com/get-feedback/meet-with-advisors/a.html?first_name=${accountDetails.userData.givenName}&last_name=${accountDetails.userData.surName}&plancode=${accountDetails.recurlyPlanCode}&cognito_id=${accountDetails.userData.id}&sosfcontactid=${accountDetails.salesforceContactId}&email=${accountDetails.userData.email}&company_name=${accountDetails.companyName}`}
        width="800px"
        height="675px"
      />
    </>
  );
}

export default CompanyPostButton;
