import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Box, Paper, Typography, useMediaQuery } from '@mui/material';
import ACAvatar from '../../components/ACAvatar';
import { useAccount } from '../../contexts/Account';
import { getCompanyInfoBoardroomMenu } from '../../services/company_services';
import Tag from '../../components/AccountSettings/Tag';

const useStyles = (isSM) => ({
  screenContainer: {
    paddingLeft: isSM ? '0px' : '50px',
    paddingTop: '30px',
    paddingRight: isSM ? '0px' : '30px',
    display: 'flex',
    flexDirection: 'column',
    flex: 'auto',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  cardContainer: {
    padding: '20px',
    maxWidth: '778px',
    width: '100%',
    marginBottom: '20px',
  },
  companyInformationSectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '20px',
    width: '100%',
    borderBottom: 'solid',
    borderBottomWidth: '1px',
    paddingBottom: '40px',
    borderBottomColor: 'grey.300',
  },
  title: {
    fontWeight: 'bold',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  storyAndMissionContainer: {
    maxWidth: '728px',
    wordWrap: 'break-word',
  },
  imageContainer: {
    width: '245.8px',
    height: '79.99px',
    marginTop: '20px',
  },
  mainInformationContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  companyInformationContainer: {
    height: '60px',
    marginTop: '50px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  companyName: {
    marginTop: '20px',
    marginBottom: '30px',
  },
  tagsTitle: {
    fontWeight: 'bold',
    marginTop: '30px',
    marginBottom: '15px',
  },
  tagsContainer: {
    '& > *': {
      marginRight: '8px',
      marginBottom: '5px',
    },
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  yourInformationSectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '20px',
    width: '100%',
  },
  separator: {
    marginTop: '10px',
  },
  ceoAvatarContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '20px',
    marginBottom: '20px',
  },
  ceoAvatarDetailsContainer: {
    marginLeft: '20px',
  },
  ceoBiography: {
    marginTop: '10px',
    marginBottom: '50px',
  },
  companyMembersContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: '30px',
    marginBottom: '20px',
  },
  companyMember: {
    width: '200px',
    marginBottom: '50px',
    marginRight: '20px',
  },
  companyAvatarContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '20px',
  },
  companyAvatarDetailsContainer: {
    marginLeft: '20px',
  },
});

const testBio = `
Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.

The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.
`;

function Boardroom(props) {
  const { t } = useTranslation();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const { tags } = useAccount();
  const [loading, setLoading] = useState(true);
  const [companyData, setCompanyData] = useState(null);
  const [industriesMap] = useState(
    tags.industries.reduce(
      (currentObject, industry) => ({
        ...currentObject,
        [industry.id]: industry.label,
      }),
      {}
    )
  );
  const [skillsMap] = useState(
    tags.skills.reduce(
      (currentObject, skill) => ({
        ...currentObject,
        [skill.id]: skill.label,
      }),
      {}
    )
  );

  async function getCompanyData() {
    setLoading(true);
    try {
      const result = await getCompanyInfoBoardroomMenu(props.match.params.id);
      setCompanyData(result.data.getCompany);
    } catch (err) {
      console.log('RS ERR', props);
    }
    setLoading(false);
  }

  useEffect(() => {
    getCompanyData();
  }, []);

  function renderCompanyDetails() {
    if (loading || !companyData) return null;
    const CEO = companyData.contacts.find((c) => c.isBoardOwner);
    const members = companyData.contacts.filter((c) => !c.isBoardOwner);

    return (
      <Paper sx={classes.cardContainer}>
        <Box sx={classes.companyInformationSectionContainer}>
          <div style={classes.row}>
            <Typography variant="body1" sx={classes.title}>
              {t('COMPANY-INFORMATION-CARD-TITLE')}
            </Typography>
          </div>

          <div style={classes.mainInformationContainer}>
            <img
              alt={t('COMPANY-IMAGE-ALT')}
              style={classes.imageContainer}
              src={`https://${companyData.image.location}`}
            />

            <div style={classes.companyInformationContainer}>
              <Typography variant="h2">{companyData.website}</Typography>

              <Typography variant="h2">
                {t('COMPANY-EMPLOYEES-LABEL', {
                  employees: companyData.companySize || 0,
                })}
              </Typography>
            </div>
          </div>

          <Typography variant="h2" sx={classes.companyName}>
            {companyData.companyName}
          </Typography>

          <div style={classes.storyAndMissionContainer}>
            <Typography variant="body1">
              {companyData.storyAndMission}
            </Typography>
          </div>

          <Typography variant="body1" sx={classes.tagsTitle}>
            {t('COMPANY-INDUSTRY-LABEL')}
          </Typography>

          <div style={classes.tagsContainer}>
            {companyData.industryTagIds.map((id) => (
              <Tag key={id} label={industriesMap[id]} />
            ))}
          </div>

          <Typography variant="body1" sx={classes.tagsTitle}>
            {t('COMPANY-DESIRED-SKILLS-LABEL')}
          </Typography>

          <div style={classes.tagsContainer}>
            {companyData.desiredSkillsTagIds.map((id) => (
              <Tag key={id} label={skillsMap[id]} />
            ))}
          </div>

          <Typography variant="body1" sx={classes.tagsTitle}>
            {t('COMPANY-PERKS-LABEL')}
          </Typography>
          <Typography variant="body1">{t('COMPANY-BENEFITS')}</Typography>
          <Typography variant="body1">
            {t('COMPANY-BENEFITS-EXAMPLE-COMPANY')}
          </Typography>
          <Typography variant="body1">
            {t('COMPANY-BENEFITS-EXAMPLE-POSITION-TITLE', {
              companyName: companyData.companyName,
            })}
          </Typography>
          <div style={classes.separator}>
            <Typography variant="body1">{companyData.perks}</Typography>
          </div>
        </Box>

        <div style={classes.yourInformationSectionContainer}>
          <div style={classes.ceoAvatarContainer}>
            <ACAvatar image={CEO.image.location} size="xl" />
            <div style={classes.ceoAvatarDetailsContainer}>
              <Typography variant="body1">{CEO.displayName}</Typography>
              <Typography variant="body1">{CEO.title}</Typography>
            </div>
          </div>

          <Typography variant="body2">
            {t('BOARDROOM-CEO-BIOGRAPHY-TITLE')}
          </Typography>

          <Typography variant="body1" sx={classes.ceoBiography}>
            {CEO.biography}
          </Typography>

          <Typography variant="body1" sx={classes.title}>
            {t('BOARDROOM-COMPANY-MEMBERS-TITLE')}
          </Typography>

          <div style={classes.companyMembersContainer}>
            {members.map((m) => (
              <div style={classes.companyMember}>
                <div style={classes.companyAvatarContainer}>
                  <ACAvatar image={m.image.location} size="md" />
                  <div style={classes.companyAvatarDetailsContainer}>
                    <Typography variant="body2">{m.displayName}</Typography>
                    <Typography variant="body1">{m.title}</Typography>
                  </div>
                </div>
                <Typography variant="body1">{testBio}</Typography>
              </div>
            ))}
          </div>
        </div>
      </Paper>
    );
  }

  return (
    <div style={classes.screenContainer}>
      <Helmet>
        <title>
          {t('BOARDROOM-TITLE', {
            companyName: companyData ? companyData.companyName : '',
          })}
        </title>
      </Helmet>

      {renderCompanyDetails()}
    </div>
  );
}

export default Boardroom;
