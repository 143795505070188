import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  useMediaQuery,
  List,
  ListItemButton,
  IconButton,
  ListItemText,
  Box,
  Collapse,
  Typography,
  Fab,
  Avatar,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AdvisoryCloudLogo from './../../AdvisoryCloudLogo';
import DefaultAvatar from './../../../icons/user-avatar.svg';
import { getRecentlyMessagedBoardMembers } from './../../../services/advisor_services';
import { getAdvisor } from './../../../services/Backend/account';
import { ReactComponent as Expandcircle } from './expand-circle-down.svg';
import { useAccount } from './../../../contexts/Account';
import eventBus, { eventBusValues } from './../../../eventBus';
import CardBoard from './boards';
import { AccountType } from './../../../services/utils/types';
import Treatment from './../../Treatment';
import { featureFlags } from './../../../splitSetup';

const useStyles = () => ({
  logoContainer: {
    position: 'absolute',
    top: '-35px',
    left: '23px',
    height: '60px',
  },
  menuItemContainer: {
    height: '62px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '22px',
    lineHeight: '32px',
    letterSpacing: '0.75px',
    color: '#232B35',
  },
  menuLabelsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  menuSecondaryLabel: {
    color: 'grey.500',
  },
  closeButtonContainer: {
    position: 'absolute',
    left: '90%',
    top: '22px',
    zIndex: 2,
    fontSize: 25,
    color: '#97A3B7',
  },
  boards: {
    paddingLeft: '15px',
    paddingRight: '15px',
    marginTop: '-15px',
  },
  mainContainer: {
    height: 'calc(100vh - calc(100vh - 100%) - 145px)',
    maxHeight: '90vh',
    overflowY: 'auto',
    marginTop: '60px',
  },
  sublist: {
    paddingLeft: '20px',
  },
});

function MenuItem({
  id,
  label,
  secondaryLabel,
  onClick,
  children,
  newTag,
  arrowDown,
}) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  function renderCollapse() {
    if (children) {
      return (
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      );
    }
    return null;
  }

  function handleMenuItemClick() {
    setIsOpen(!isOpen);

    if (onClick) {
      onClick();
    }
  }

  return (
    <>
      <ListItemButton
        sx={classes.menuItemContainer}
        onClick={handleMenuItemClick}
      >
        <div style={classes.menuLabelsContainer}>
          <ListItemText id={id} primary={label} disableTypography={true} />
          {secondaryLabel && (
            <ListItemText
              classes={classes.menuSecondaryLabel}
              primary={secondaryLabel}
            />
          )}
        </div>
        {newTag && (
          <Fab
            variant="extended"
            size="small"
            color="secondary"
            role="none"
            style={{
              height: '18px',
              marginLeft: '4px',
              color: '#1976d2',
              fontSize: '9px',
              fontWeight: 'bold',
              paddingTop: '2px',
              boxShadow: 'none',
              backgroundColor: '#E7EDFD',
            }}
          >
            New
          </Fab>
        )}
        {arrowDown && <ArrowDropDownIcon />}
      </ListItemButton>

      {renderCollapse()}
    </>
  );
}

function visitProfile(advisor) {
  eventBus.dispatch(eventBusValues.triggerAdvisorDrawerAction, {
    ...advisor,
    boardRoomView: true,
  });
}

function MemberItem({ advisorId, currentId, companyName }) {
  const [advisorData, setAdvisorData] = useState(false);

  useEffect(() => {
    let isMounted = true;
    function getAdvisorData() {
      getAdvisor(advisorId)
        .then((response) => {
          if (isMounted) {
            if (
              response.data.getAdvisor.accountStatus === 'active' &&
              response.data.getAdvisor.image
            ) {
              setAdvisorData(response.data.getAdvisor);
            }
          }
        })
        .catch(() => {});
    }
    if (advisorId !== currentId) {
      getAdvisorData();
    }
    return () => {
      isMounted = false;
    };
  }, [advisorId]);

  if (advisorData) {
    return (
      <CardBoard
        onClick={() => visitProfile(advisorData)}
        logo={
          advisorData.image && advisorData.image.imageKey
            ? `${process.env.REACT_APP_IMAGES_URL}${advisorData.image.imageKey}`
            : ''
        }
        memberName={advisorData.displayName}
        companyName={companyName}
        boardMember={advisorData}
        memberType={AccountType.ADVISOR}
        status="member"
        key={`${advisorData.id}-boardCard`}
        boardMembersTab
      />
    );
  }
  return null;
}

function OptionsList({ accountDetails, onDismiss, setIsOpen, onProfileClick }) {
  const history = useHistory();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const { asLoggedUserDetails, isFreemium, currentUserInfo } = useAccount();
  let userInfo = accountDetails.contacts.find(
    (contact) => contact.id === asLoggedUserDetails.userId
  );
  const [recentMessagedAdvisors, setRecentMessagedAdvisors] = useState([]);
  const [boardAdvisorsData, setBoardAdvisorsData] = useState([]);

  useEffect(() => {
    if (Object.keys(accountDetails).length != 0) {
      if (
        accountDetails.boardAdvisors &&
        accountDetails.boardAdvisors.length > 0
      ) {
        setBoardAdvisorsData(accountDetails.boardAdvisors);
      }
    }
  }, [accountDetails]);

  useEffect(() => {
    (async () => {
      if (boardAdvisorsData.length > 0) {
        const advisors = await getRecentlyMessagedBoardMembers({
          ID: asLoggedUserDetails.userId,
          BOARD_MEMBERS: boardAdvisorsData.map((advisor) => advisor.id),
        });
        setRecentMessagedAdvisors(
          advisors.data.getRecentlyMessagedBoardMembers
        );
      }
    })();
  }, [boardAdvisorsData]);

  function handleMenuItem(route) {
    history.push(route);
    onDismiss();
  }

  return (
    <>
      <div style={classes.closeButtonContainer}>
        <CloseIcon
          onClick={() => {
            setIsOpen(false);
          }}
        />
      </div>
      <div style={classes.logoContainer}>
        <AdvisoryCloudLogo
          removeHyperlink
          onClick={() => history.push('/dashboard')}
          width={189}
        />
      </div>
      <Box sx={classes.mainContainer}>
        <List>
          <MenuItem
            id="companyDashboardMobileMenuOption"
            label="Dashboard"
            onClick={() => handleMenuItem('/dashboard')}
          />
          <MenuItem
            id="companyAdvisorsMobileMenuOption"
            label="Advisors"
            onClick={() =>
              handleMenuItem(isFreemium ? '/advisors/search' : '/advisors')
            }
          />

          <MenuItem
            id="companyInsightsMobileMenuOption"
            label="Insights"
            onClick={() => handleMenuItem('/insights')}
          />
          <MenuItem
            id="companyMessagesMobileMenuOption"
            label="Messages"
            onClick={() => handleMenuItem('/messages')}
          />
        </List>

        <div
          id="companyConversationsMobileMenuSection"
          style={{
            width: '100%',
            paddingBottom: '20px',
          }}
        >
          {recentMessagedAdvisors &&
            recentMessagedAdvisors.length > 0 &&
            recentMessagedAdvisors.map((bm, i) => {
              return bm != null ? (
                <CardBoard
                  logo={
                    bm && bm.image && bm.image.imageKey
                      ? `${process.env.REACT_APP_IMAGES_URL}${bm.image.imageKey}`
                      : ''
                  }
                  memberName={bm.displayName}
                  companyName={accountDetails.displayName}
                  memberType={AccountType.ADVISOR}
                  boardMember={bm}
                  status="member"
                  isRecentlyMessaged
                  key={`${bm.id}-recentMessagedAdvisors-${i}`}
                />
              ) : null;
            })}
        </div>

        <div
          style={{
            width: '100%',
          }}
          id="boardMemberList"
        >
          {boardAdvisorsData && boardAdvisorsData.length > 0 && (
            <Typography
              id="companyAdvisorsMobileMenuSection"
              sx={{
                fontFamily: 'Poppins',
                fontSize: 22,
                marginTop: '20px',
                fontWeight: 500,
                letterSpacing: '0.75px',
                paddingLeft: '16px',
                alignSelf: 'flex-start',
              }}
            >
              Your
              {accountDetails.userSegment === 'managed_advisory_board'
                ? ' Advisory Board'
                : ' AdvisoryCloud'}
            </Typography>
          )}

          <div
            id="companyAdvisorsMobileMenuSection"
            style={{
              width: '100%',
              paddingBottom: '50px',
            }}
          >
            {boardAdvisorsData &&
              boardAdvisorsData.length > 0 &&
              boardAdvisorsData.map((bm) => {
                return (
                  <MemberItem
                    key={`${bm.id}-boardAdvisorsData`}
                    advisorId={bm.id}
                    currentId={accountDetails.id}
                    companyName={accountDetails.displayName}
                  />
                );
              })}
          </div>
        </div>
      </Box>

      <Box
        id="companyMobileSubMenu"
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          position: 'absolute',
          bottom: 0,
          padding: '4px 10px 10px 30px',
        }}
        onClick={onProfileClick}
      >
        <Avatar
          src={
            userInfo.image && !userInfo.image.location.endsWith('undefined')
              ? `https://${userInfo.image.location}`
              : DefaultAvatar
          }
          sx={{ width: '60px', height: '60px' }}
        />
        <Typography
          style={{
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '20px',
            lineHeight: '32px',
            letterSpacing: 0.75,
            color: '#232B35',
            textAlign: 'left',
            width: '100%',
            paddingLeft: '20px',
          }}
        >
          {userInfo.displayName}
        </Typography>
        <IconButton sx={{ fontSize: '30px' }}>
          <Expandcircle
            style={{
              color: '#232B35',
            }}
          />
        </IconButton>
      </Box>
    </>
  );
}

export default OptionsList;
