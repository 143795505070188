import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, Typography, OutlinedInput } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CustomIcon from '../../../../../icons/customIcon';
import DecoratedButton from '../decoratedButton';
import { confirmEmailCode } from '../../../../../services/Authentication';
import { ConfirmEmailResponses } from '../../../../../services/Authentication/constants';

const useStyles = (isSM) => ({
  topRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleText: {
    fontWeight: 'bold',
  },
  bottomRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '15px',
    width: '100%',
  },
  acIcon: {
    marginBottom: '1px',
    fontSize: '15px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  sendCodeContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: '5px',
    textAlign: isSM ? 'center' : null,
  },
  sendCodeRow: {
    display: 'flex',
    flexDirection: isSM ? 'column' : 'row',
    width: '100%',
    justifyContent: isSM ? 'center' : 'flex-start',
    alignItems: 'center',
    marginTop: '10px',
  },
  sendCodeForm: {
    width: '100%',
    display: 'flex',
    marginLeft: isSM ? '0px' : '10px',
    justifyContent: isSM ? 'center' : 'flex-start',
    alignItems: 'center',
    marginTop: isSM ? '5px' : '0px',
  },
  fieldWrapperEmail: {
    width: isSM ? '100%' : '60%',
  },
  fieldWrapperCode: {
    width: 'auto',
    minHeight: '42px',
    height: '42px',
  },
  enterConfirmationCode: {
    width: isSM ? '100%' : 'auto',
    flexShrink: 0,
  },
});

const FieldWrapper = withStyles((theme) => ({
  root: {
    '& .MuiInputBase-input': {
      color: theme.palette.common.black,
      height: '5px',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.secondary.main,
    },
    '& .MuiInputBase-input:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 100px #fafafa inset',
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: theme.palette.primary.main,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.primary.main,
    },
    '& .Mui-error:after': {
      borderBottomColor: theme.palette.error.main,
    },
  },
}))(OutlinedInput);

function AdvisorEmailChangeForm2(props) {
  const { title, finishFlow, reset, actionCallback } = props;
  const { t } = useTranslation();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const classes = useStyles(isSM);
  const [isLoading, setIsLoading] = useState(false);

  const ChangeEmailCodeSchema = Yup.object().shape({
    code: Yup.string().required(`${t('PASSWORD_VALIDATION_CODE')}`),
  });

  async function sendNewEmailCode(code) {
    setIsLoading(true);
    try {
      const confirmEmailResults = await confirmEmailCode(code);
      if (confirmEmailResults.code) {
        if (
          confirmEmailResults.code ===
          ConfirmEmailResponses.CODE_MISMATCH_EXCEPTION
        ) {
          actionCallback(`FAIL:${t('ADVISOR_CHANGE_PASSWORD_CODE_MISMATCH')}`);
        } else if (
          confirmEmailResults.code === ConfirmEmailResponses.EXPIRED_CODE
        ) {
          actionCallback(`FAIL:${t('ADVISOR_CHANGE_PASSWORD_CODE_EXPIRE')}`);
        } else {
          actionCallback(`FAIL:${t('PASSWORD_VALIDATION_CODE')}`);
        }
        reset('ERROR');
      } else {
        finishFlow('Email successfully changed.');
        actionCallback(
          `SUCCESS:${t('ADVISOR_CHANGE_EMAIL_SUCCESSFULLY_CHANGED')}`
        );
      }
    } catch (err) {
      reset('ERROR');
      actionCallback(`FAIL:${t('PASSWORD_VALIDATION_CODE')}`);
    }
    setIsLoading(false);
  }

  const saveACIcon = () => {
    return <CustomIcon iconname="save-outlined" style={classes.acIcon} />;
  };

  return (
    <>
      <div style={classes.topRow}>
        <Typography variant="h1" sx={classes.titleText}>
          {title}
        </Typography>
      </div>
      <div style={classes.bottomRow}>
        <div style={classes.sendCodeContainer}>
          <Typography color="primary">
            {t('ADVISOR_SETTING_CARD_CODE_SENT')}
          </Typography>
          <div style={classes.sendCodeRow}>
            <Typography sx={classes.enterConfirmationCode}>
              {t('ADVISOR_SETTING_CARD_TYPE_SENT_CODE')}
            </Typography>
            <Formik
              initialValues={{
                code: '',
              }}
              validationSchema={ChangeEmailCodeSchema}
              validateOnChange
              validateOnMount
              validateOnBlur={false}
            >
              {(formik) => {
                const { handleChange, values, setFieldTouched, isValid } =
                  formik;
                return (
                  <form
                    data-testid="formAdvisorChangeEmailCode"
                    style={classes.sendCodeForm}
                  >
                    <FieldWrapper
                      sx={classes.fieldWrapperCode}
                      autoComplete="off"
                      name="code"
                      variant="outlined"
                      value={values.code}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldTouched('code');
                      }}
                      inputProps={{
                        'aria-label': `${t(
                          'ARIA_LABEL_ADVISOR_CHANGE_EMAIL_SEND_CODE'
                        )}`,
                        'aria-required': 'true',
                      }}
                    />
                    <DecoratedButton
                      buttonIcon={saveACIcon()}
                      buttonText={t('ADVISOR_SETTING_CARD_CONFIRM_EMAIL')}
                      clickAction={() => sendNewEmailCode(values.code)}
                      loading={isLoading}
                      isDisabled={!isValid}
                    />
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdvisorEmailChangeForm2;
