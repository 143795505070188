import React from 'react';
import { Typography } from '@mui/material';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { useTranslation } from 'react-i18next';

function CancelButton({ onClick }) {
  const { t } = useTranslation();

  return (
    <button
      onClick={onClick}
      type="submit"
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#fff',
        padding: '0px',
        alignItems: 'center',
        border: 'none',
        cursor: 'pointer',
      }}
    >
      <HighlightOffRoundedIcon
        sx={{
          width: '18px',
          height: '18px',
          marginRight: '5px',
          color: '#757575',
        }}
      />
      <Typography
        sx={{
          fontWeight: 'bold',
          color: '#757575',
          fontFamily: 'Poppins',
        }}
        color="primary"
      >
        {t('CANCEL-BUTTON-TEXT')}
      </Typography>
    </button>
  );
}

export default CancelButton;
