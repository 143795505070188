import React, { useState } from 'react';
import { Typography, useMediaQuery } from '@mui/material';
import CustomIcon from './../../../../icons/customIcon';
import TimeElapsed from './../../../../utils/TimeElapsed';
import VerticalElipsiesMenu from './RightHandVerticalElipsies';
import PostTypeIcon from './PostTypeDecorator';
import BoardMeetingIconBadge from './BoardMeetingIconBadge';
import PostHeaderAvatar from './PostHeaderAvatar';
import eventBus, { eventBusValues } from './../../../../eventBus';
import { useAccount } from './../../../../contexts/Account';
import BoardMeetingPopUp from './../../BoardMeetingPopUp';

const useStyles = (isSM) => ({
  postCardHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  rightGroup: {
    display: 'flex',
    width: 'fit-content',
    height: 'fit-content',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightText: {
    display: 'flex',
    textAlign: 'end',
    justifyContent: 'end',
    alignItems: 'end',
    flexDirection: 'column',
  },
  iconContainer: {
    marginRight: '5px',
    display: 'flex',
    flexDirection: isSM ? 'column-reverse' : 'row',
    justifyContent: isSM ? 'end' : 'center',
    alignItems: isSM ? 'end' : 'center',
  },
  pinnedFont: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '32px',
    whiteSpace: 'nowrap',
    color: '#646D7A',
  },
  pinnedIcon: {
    color: '#FFC068',
    fontSize: '17px',
    display: 'flex',
    alignSelf: 'center',
    marginRight: '5px',
  },
  pillContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyItems: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: isSM ? '10px' : '20px',
  },
});

function PostCardHeader(props) {
  const {
    postData,
    noEllipsis,
    type,
    initialTime,
    companyName,
    dashboardFormat,
    editClick,
    deleteClick,
    isOpportunityView,
    companyAvatar,
    pinClick,
    isPinned,
    setTimeLive,
  } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [openBoardMeetingPost, setOpenBoardMeetingPost] = useState(false);

  const { asLoggedUserDetails, isImpersonated } = useAccount();
  const classes = useStyles(isSM);
  const isBoardMeetingPost = postData.type === 'boardMeeting';

  function typeGroupSelector() {
    if (isBoardMeetingPost) {
      if (
        asLoggedUserDetails.userId === postData.companyContactId &&
        isImpersonated
      ) {
        return (
          <VerticalElipsiesMenu
            editClick={() => setOpenBoardMeetingPost(true)}
            pinClick={pinClick}
            isPinned={isPinned}
            type={type}
          />
        );
      }
    } else if (!noEllipsis) {
      return (
        <VerticalElipsiesMenu
          editClick={editClick}
          pinClick={pinClick}
          isPinned={isPinned}
          deleteClick={deleteClick}
          type={type}
        />
      );
    }
    return null;
  }

  function timeElapsedResults() {
    return isOpportunityView || isBoardMeetingPost ? (
      ''
    ) : (
      <TimeElapsed
        initialTime={initialTime}
        dashboardFormat={dashboardFormat ? 1 : 0}
        size={isSM ? 'sm' : 'lg'}
      />
    );
  }

  function pinnedText() {
    if (isPinned) {
      return (
        <div style={classes.pillContainer}>
          {isSM ? null : <CustomIcon style={classes.pinnedIcon} iconname="pin" />}
          <Typography sx={classes.pinnedFont}>Pinned post</Typography>
        </div>
      );
    }
    return null;
  }

  return (
    <>
      <div style={classes.postCardHeaderContainer}>
        <PostHeaderAvatar
          titleName={companyName}
          subtitle={timeElapsedResults()}
          isBoardMeeting
          fontSize={isSM ? 'sm' : 'lg'}
          image={companyAvatar}
          avatarClick={() =>
            eventBus.dispatch(eventBusValues.triggerCompanyDrawerAction, {
              companyId: postData.companyId,
              hidePostSection: true,
            })
          }
        />
        <div style={classes.rightGroup}>
          <div style={classes.rightText}>
            <div style={classes.iconContainer}>
              {pinnedText()}
              {isBoardMeetingPost ? (
                <BoardMeetingIconBadge
                  postData={postData}
                  setTimeLive={setTimeLive}
                  dashboardFormat={dashboardFormat}
                  isPinned={isPinned}
                />
              ) : null}
              <PostTypeIcon type={type} />
            </div>
          </div>
          {typeGroupSelector()}
        </div>
      </div>
      <BoardMeetingPopUp
        open={openBoardMeetingPost}
        postData={postData}
        closeAction={() => setOpenBoardMeetingPost(false)}
      />
    </>
  );
}

export default PostCardHeader;
