import React from 'react';
import { Box, useMediaQuery } from '@mui/material';
import AdvisoryCloudLogo from './../components/AdvisoryCloudLogo';

const useStyles = ({ isXS }) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    width: '100%',
    height: '100%',
  },
  topLeftLogo: {
    position: 'fixed',
    top: '-20px',
    left: '96px',
    transform: 'none',
    zIndex: 1,
  },
  bottomRightImage: {
    position: 'fixed',
    bottom: '0px',
    right: '0px',
    height: isXS ? '200px' : '370px',
  },
  centerComponent: {
    paddingTop: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 0,
    height: '100%',
    width: '100%',
  },
});

const PublicRegistration = ({ children, hideImage }) => {
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const classes = useStyles({ isXS });

  if (isXS) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center">
        <AdvisoryCloudLogo removeHyperlink onClick={() => {}} width={218} />
        {children}
        {hideImage ? null : (
          <img
            src="https://cdn.advisorycloud.com/images/public_registration_bg.png"
            alt="Public Registration"
            style={{ ...classes.bottomRightImage, zIndex: -1 }}
          />
        )}
      </Box>
    );
  }

  return (
    <Box sx={classes.root}>
      <Box sx={classes.topLeftLogo}>
        <AdvisoryCloudLogo removeHyperlink onClick={() => {}} width={218} />
      </Box>
      {hideImage ? null : (
        <img
          src="https://cdn.advisorycloud.com/images/public_registration_bg.png"
          alt="Public Registration"
          style={classes.bottomRightImage}
        />
      )}
      <Box sx={classes.centerComponent}>{children}</Box>
    </Box>
  );
};

export default PublicRegistration;
