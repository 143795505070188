import React from 'react';
import { useTranslation } from 'react-i18next';
import { Step, StepLabel, Stepper, useMediaQuery } from '@mui/material';

const useStyles = (isSM) => ({
  stepperContainer: {
    width: isSM ? '100%' : '700px',
    backgroundColor: 'transparent',
    paddingTop: isSM ? '70px' : '50px',
  },
});

const STEPS_LOCALES = [
  { key: 'COMPANY-ONBOARDING-STEP-1', id: 0 },
  { key: 'COMPANY-ONBOARDING-STEP-2', id: 1 },
  { key: 'COMPANY-ONBOARDING-STEP-3', id: 2 },
];

function OnboardingStepper({ activeStep }) {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const { t } = useTranslation();

  function renderSteps() {
    return STEPS_LOCALES.map((STEP_LOCALE) => (
      <Step key={STEP_LOCALE.id}>
        <StepLabel>{t(STEP_LOCALE.key)}</StepLabel>
      </Step>
    ));
  }

  return (
    <Stepper
      activeStep={activeStep}
      alternativeLabel
      sx={classes.stepperContainer}
    >
      {renderSteps()}
    </Stepper>
  );
}

export default OnboardingStepper;
