import React from 'react';
import { useMediaQuery } from '@mui/material';
import AnswersButton from './Buttons/answersButton';
import CommentsButton from './Buttons/commentsButton';

const useStyles = (props) => ({
  groupContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  iconContainer: {
    display: 'flex',
    height: 'fit-content',
    paddingBottom: props.isPost ? '7px' : '0px',
    marginTop: '20px',
  },
  answersContainer: {
    order: props.answersOrder,
  },
  commentsContainer: {
    order: props.commentsOrder,
  },
  iconSeparatorBreakthroughs: {
    marginTop: 0,
    order: props.breakthoughsOrder,
  },
});

const PostCardBottom = (props) => {
  const {
    size,
    answers,
    comments,
    commentsFilled,
    commentsClick,
    commentsBadge,
    commentsBadgeContent,
    commentsDisabled,
    answersClick,
    answersFilled,
    answersBadge,
    answersBadgeContent,
    answersDisabled,
    iconSeparation,
    buttonsColor,
  } = props;
  const classes = useStyles(props);
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  function iconSeparationSelection() {
    if (isSM) {
      return '8px';
    }
    if (iconSeparation) {
      return iconSeparation;
    }
    return '10px';
  }

  return (
    <div style={classes.groupContainer}>
      <div style={classes.iconContainer}>
        {answers || answers === 0 ? (
          <div
            style={{
              ...classes.answersContainer,
              marginRight: iconSeparationSelection(),
            }}
          >
            <AnswersButton
              iconSize={size}
              numAnswers={answers}
              badge={answersBadge}
              filled={answersFilled}
              onClick={answersClick}
              badgeContent={answersBadgeContent}
              disabled={answersDisabled}
              buttonColor={buttonsColor}
            />
          </div>
        ) : null}
        {comments || comments === 0 ? (
          <div
            style={{
              ...classes.commentsContainer,
              marginRight: iconSeparationSelection(),
            }}
          >
            <CommentsButton
              iconSize={size}
              numComments={comments}
              filled={commentsFilled}
              onClick={commentsClick}
              badge={commentsBadge}
              badgeContent={commentsBadgeContent}
              disabled={commentsDisabled}
              buttonColor={buttonsColor}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PostCardBottom;
