import React from 'react';
import { Avatar, Box, Card, CardContent, Typography } from '@mui/material';

const LintonJohnson = () => {
  const styles = {
    card: {
      width: '100%',
      borderRadius: '12px',
      padding: '15px',
      border: '1px solid #EFF0F6',
      textAlign: 'center',
      mb: '40px',
    },
  };

  return (
    <Card sx={styles.card} elevation={0}>
      <CardContent sx={{ position: 'relative' }}>
        <Box
          sx={{
            width: { xxs: '190px', sm: '225px' },
            height: { xxs: '190px', sm: '225px' },
            borderRadius: '50%',
            background: '#DBF7EB',
            position: 'absolute',
            left: '50px',
            bottom: '40px',
            zIndex: 0,
          }}
        />
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: { xxs: '22px', sm: '36px' },
            lineHeight: '42px',
            letterSpacing: '-0.43px',
            marginLeft: { xxs: '0px', sm: '200px' },
            marginBottom: '20px',
            maxWidth: '755px',
            zIndex: 1,
            textAlign: 'left',
            position: 'relative',
          }}
        >
          "We've been able to connect with incredible advisors for our business
          on AdvisoryCloud"
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            zIndex: 1,
            marginLeft: { xxs: '0px', sm: '140px' },
          }}
        >
          <Avatar
            src={process.env.PUBLIC_URL + '/images/person.png'}
            style={{ marginRight: '32px', width: '104px', height: '104px' }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
              textAlign: 'left',
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '15px',
                lineHeight: '22px',
                letterSpacing: '0.75px',
                marginBottom: '10px',
              }}
            >
              Linton Johnson
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '15px',
                lineHeight: '22px',
                letterSpacing: '0.75px',
                marginBottom: '10px',
              }}
            >
              Founder and CEO - Ovis
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default LintonJohnson;
