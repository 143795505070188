import React, { useState } from 'react';
import { Menu, MenuItem, Button } from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import {
  subscribeToGoogleCalendar,
  unsubscribeFromGoogleCalendar,
  subscribeToAppleCalendar,
} from '../../services/advisor_services';
import LoadingLogoSkeleton from '../SkeletonLoading/loadingLogoSkeleton';

const CalendarProviderButton = ({
  syncStates,
  setSyncStates,
  advisorId,
  companyIds,
  isLoading,
  setIsLoading,
}) => {
  const { googleSync, appleSync, outlookSync, microsoftSync } = syncStates;
  const { setGoogleSync, setAppleSync, setOutlookSync, setMicrosoftSync } =
    setSyncStates;
  const [anchorEl, setAnchorEl] = useState(null);

  const providers = [
    {
      key: 'google',
      name: 'Google Calendar',
      sync: googleSync,
      setSync: setGoogleSync,
    },
    {
      key: 'apple',
      name: 'Apple Calendar',
      sync: appleSync,
      setSync: setAppleSync,
    },
    {
      key: 'outlook',
      name: 'Outlook Calendar',
      sync: outlookSync,
      setSync: setOutlookSync,
    },
    {
      key: 'microsoft',
      name: 'Microsoft Calendar',
      sync: microsoftSync,
      setSync: setMicrosoftSync,
    },
  ];

  const handleOpenMenu = (event) => setAnchorEl(event.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);

  // Inicializar Google Login una sola vez en el componente funcional
  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const token = tokenResponse.code;
      const authCode = tokenResponse.code;
      try {
        setIsLoading(true);
        const response = await subscribeToGoogleCalendar({
          provider: 'google',
          token,
          authCode,
          advisorId,
          companyIds,
        });

        if (response.data.subscribeToGoogleCalendar) {
          setGoogleSync(true);
          console.log('Calendar successfully synced with Google.');
        } else {
          console.error('Error syncing with Google Calendar.');
        }
      } catch (error) {
        console.error('Error during Google Calendar subscription:', error);
      } finally {
        setIsLoading(false);
      }
    },
    onError: () => console.error('Google Login Failed'),
    scope:
      'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
    access_type: 'offline', // Solicita el REFRESH_TOKEN
    flow: 'auth-code', // Solicita el código de autorización
  });

  const handleGoogleLogin = () => {
    googleLogin();
  };

  const handleAppleLogin = async (setAppleSync) => {
    try {
      console.log('Initializing Apple login process...');

      const isDevEnv =
        window.location.origin === 'https://app-dev.advisorycloud.com';
      const redirectURI = isDevEnv
        ? 'https://app-dev.advisorycloud.com/dashboard'
        : process.env.REACT_APP_APPLE_REDIRECT_URI;

      const clientId = isDevEnv
        ? 'com.advisorycloud.web.calendar'
        : process.env.REACT_APP_APPLE_CLIENT_ID;

      console.log('Using redirectURI:', redirectURI);
      console.log('Using clientId:', clientId);

      if (!window.AppleID || !window.AppleID.auth) {
        throw new Error(
          'AppleID library is not loaded. Ensure the script is included in your index.html.'
        );
      }

      console.log('AppleID library is loaded. Initializing auth...');

      window.AppleID.auth.init({
        clientId,
        scope: 'name email',
        redirectURI,
        usePopup: true,
      });

      console.log('Auth initialized with clientId:', clientId);
      const response = await window.AppleID.auth.signIn();

      console.log('Sign-in response received:', response);

      const { authorization } = response;
      const appleToken = authorization.id_token;

      if (!appleToken) {
        throw new Error('No token received from Apple Sign-In.');
      }

      console.log('Apple token received:', appleToken);

      setIsLoading(true);

      console.log('Sending token to backend...');
      const apiResponse = await subscribeToAppleCalendar({
        provider: 'apple',
        token: appleToken,
        advisorId,
        companyIds,
      });

      console.log('API response received:', apiResponse);

      if (apiResponse.data.subscribeToAppleCalendar) {
        setAppleSync(true);
        console.log('Calendar successfully synced with Apple.');
      } else {
        console.error('Error syncing with Apple Calendar:', apiResponse);
      }
    } catch (error) {
      console.error('Error during Apple Calendar subscription:', error.message);
      console.error('Full error details:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleUnsubscribe = async (setGoogleSync) => {
    try {
      setIsLoading(true);
      const response = await unsubscribeFromGoogleCalendar({
        provider: 'google',
        advisorId,
        companyIds,
      });

      if (response.data.unsubscribeFromGoogleCalendar) {
        setGoogleSync(false);
        console.log('Successfully unsubscribed from Google Calendar.');
      } else {
        console.error('Error unsubscribing from Google Calendar.');
      }
    } catch (error) {
      console.error('Error during Google Calendar unsubscription:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSync = async (provider, setSync, syncState) => {
    setAnchorEl(null);
    if (syncState) {
      switch (provider) {
        case 'google':
          await handleGoogleUnsubscribe(setSync);
          break;
        case 'apple':
          console.log('TODO: Add unsubscribe logic for Apple Calendar.');
          break;
        default:
          console.log(`Unsubscribe for ${provider} not implemented.`);
      }
    } else {
      switch (provider) {
        case 'google':
          handleGoogleLogin(setSync);
          break;
        case 'apple':
          await handleAppleLogin(setSync);
          break;
        default:
          console.log(`Login for ${provider} not implemented.`);
      }
    }
  };

  return (
    <>
      <Button
        onClick={handleOpenMenu}
        variant="contained"
        sx={{
          backgroundColor: isLoading ? '#3171F6' : '#3171F6',
          color: isLoading ? '#FFFFFF' : '#FFFFFF',
          fontSize: '16px',
          borderRadius: '999px',
          padding: '0',
          fontWeight: 700,
          textTransform: 'none',
          width: '296px',
          height: '44px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          '&:disabled': {
            backgroundColor: '#3171F6',
            color: '#FFFFFF',
            opacity: 0.7,
          },
        }}
        disabled={isLoading}
      >
        {isLoading ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <LoadingLogoSkeleton />
          </div>
        ) : (
          'Manage Calendar Sync'
        )}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {providers.map((provider) => (
          <MenuItem
            key={provider.key}
            onClick={() =>
              handleSync(provider.key, provider.setSync, provider.sync)
            }
          >
            {provider.sync
              ? `Unsubscribe from ${provider.name}`
              : `Sync with ${provider.name}`}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default CalendarProviderButton;
