import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormikForm from './Step2FormikForm';
import { useAccount } from './../../../contexts/Account';
import {
  updateCompanyProfileData,
  uploadCompanyContactImgCall,
  updateCompanyOnBoarding,
} from './../../../services/company_services/index';
import { countries } from './../../../services/utils/countryCode';
import { NullCountry } from './../../../services/utils/types';

function Step2Form(props) {
  const { formRef, onValid, isUpdate, updateSubmit, onInformationUploaded } =
    props;
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const [filePondStatus, setFilePondStatus] = useState(true);
  const [uploaderOutput, setUploaderOutput] = useState([]);
  const location = useLocation();
  const {
    accountDetails,
    completeCompanyOnboarding,
    authenticate,
    editCompanyContactInformation,
    isImpersonated,
    asLoggedUserDetails,
  } = useAccount();
  const contact =
    accountDetails.contacts && accountDetails.contacts.length
      ? accountDetails.contacts.find(
          (companyContact) => companyContact.id === asLoggedUserDetails.userId
        )
      : {};

  const isCompanyContact =
    location.pathname === '/company-member/onboarding/profile';

  const Step2Schema = isCompanyContact
    ? Yup.object().shape({
        yourFirstName: Yup.string(),
        yourLastName: Yup.string(),
      })
    : Yup.object().shape({
        yourBiography: Yup.string(),
        yourFirstName: Yup.string(),
        yourLastName: Yup.string(),
        yourTitle: Yup.string(),
        yourPhoneCountryCode: Yup.string(),
        yourPhoneNumber: Yup.string(),
      });

  async function sendFormData(data) {
    try {
      const updatedContact = await updateCompanyProfileData({
        CONTACT_ID: contact.id,
        CONTACT_FIRST_NAME: data.yourFirstName,
        CONTACT_LAST_NAME: data.yourLastName,
        CONTACT_BIO: data.yourBiography,
        CONTACT_TITLE: data.yourTitle,
        PHONE_COUNTRY_CODE: data.yourPhoneCountryCode,
        PHONE_NUMBER: data.yourPhoneNumber ? data.yourPhoneNumber : '5555555555',
      });

      const companyContactModifiedValues = {
        givenName: data.yourFirstName,
        surName: data.yourLastName,
        biography: data.yourBiography,
        title: data.yourTitle,
        phoneNumber: updatedContact.data.updateCompanyContact.phoneNumber,
      };

      editCompanyContactInformation(companyContactModifiedValues);
    } catch (err) {
      throw new Error();
    }
  }

  async function sendProfileImg() {
    try {
      if (
        !uploaderOutput ||
        !uploaderOutput.image ||
        !uploaderOutput.originalImage
      ) {
        return;
      }
      const response = await uploadCompanyContactImgCall({
        CONTACT_ID: contact.id,
        CONTACT_EDITED_IMG: uploaderOutput.image,
        CONTACT_ORIGINAL_IMG: uploaderOutput.originalImage,
      });

      const fixedLocation =
        response.data.uploadContactImage.location.split('https://');

      const companyContactModifiedValues = {
        image: {
          ...response.data.uploadContactImage,
          location: fixedLocation[1],
        },
      };

      editCompanyContactInformation(companyContactModifiedValues);
      if (onInformationUploaded) {
        onInformationUploaded();
      }
    } catch (err) {
      console.log(err);
      throw new Error();
    }
  }

  function countryToFlag(isoCode) {
    if (isoCode === null || isoCode === undefined) {
      return null;
    }
    return typeof String.fromCodePoint !== 'undefined'
      ? isoCode
          .toUpperCase()
          .replace(/./g, (char) =>
            String.fromCodePoint(char.charCodeAt(0) + 127397)
          )
      : isoCode;
  }

  function submitStep2Form(data) {
    let values = data;

    if (!values && isUpdate) {
      values = formRef.current.values;
    }

    setIsLoading(true);
    Promise.all([sendFormData(values), sendProfileImg()])
      .then(() => {
        updateCompanyOnBoarding({
          CONTACT_ID: contact.id,
          ON_BOARDING_STATE: true,
          PHONE_COUNTRY_CODE: values.yourPhoneCountryCode,
        })
          .then(() => {
            if (!isImpersonated && !isUpdate) {
              authenticate();
            }
            setIsLoading(false);
            if (!isUpdate) {
              completeCompanyOnboarding();
              if (isCompanyContact) {
                window.location.href = '/dashboard?ntm=true';
              } else {
                window.location.href = '/';
              }
            }
          })
          .catch((error) => {
            console.log(error);
            setIsLoading(false);
            setIsSnackbarVisible(true);
          });
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setIsSnackbarVisible(true);
      });
  }
  const formInitialValues = {
    yourFirstName: contact.givenName,
    yourLastName: contact.surName,
    yourTitle: contact.title,
    yourBiography: contact.biography,
    yourPhoneCountryCode: contact.phoneNumber.countryCode,
    yourPhoneNumber: '',
  };

  useEffect(() => {
    if (isUpdate && updateSubmit) {
      updateSubmit(submitStep2Form);
    }
  }, [submitStep2Form]);

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={Step2Schema}
      validateOnMount
      validateOnChange
      validateOnBlur
      innerRef={formRef}
    >
      {(formik) => {
        return (
          <FormikForm
            {...formik}
            {...props}
            t={t}
            isSnackbarVisible={isSnackbarVisible}
            setIsSnackbarVisible={setIsSnackbarVisible}
            isXS={isXS}
            setUploaderOutput={setUploaderOutput}
            setFilePondStatus={setFilePondStatus}
            countryToFlag={countryToFlag}
            countries={countries}
            NullCountry={NullCountry}
            submitStep2Form={submitStep2Form}
            onValid={onValid}
            isLoading={isLoading}
            filePondStatus={filePondStatus}
          />
        );
      }}
    </Formik>
  );
}

export default Step2Form;
