import React from 'react';
import { Dialog, Slide, Typography, useMediaQuery } from '@mui/material';
import ACButton from '../ACButton';
import { useTranslation } from 'react-i18next';
import AdvisoryCloudLogo from '../../components/AdvisoryCloudLogo';

const useStyles = () => ({
  header: {
    maxWidth: '100%',
    textAlign: 'center',
    margin: '5em 5em 3em 5em',
  },
  logoContainer: {
    marginTop: '4em',
    width: '100%',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
  },
  dialogContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  bottomActions: {
    margin: '0 0 3em 0',
  },
  buttonText: {
    fontWeight: 'bold',
    fontSize: '120%',
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function LoginModal({ loginModal, setLoginModal }) {
  const { t } = useTranslation();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);

  function handleClose() {
    setLoginModal(false);
  }

  function handleButtonsClick(route) {
    window.location.replace(route);
  }

  return (
    <div style={classes.dialogContainer}>
      <Dialog
        open={loginModal}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div style={classes.logoContainer}>
          <AdvisoryCloudLogo
            removeHyperlink
            onClick={() => {
              if (location.pathname !== '/dashboard') {
                history.push('/dashboard');
              }
            }}
            width={200}
          />
        </div>
        <div style={classes.header}>
          <Typography
            variant="h1_old_font"
            style={{
              fontSize: isSM ? '1.25rem' : '2rem',
              fontWeight: 'bold',
              marginBottom: '1.25rem',
            }}
          >
            {t('NOT-LOGGED-IN-TEXT')}
          </Typography>
        </div>
        <div style={classes.bottomActions}>
          <div style={classes.buttonBox}>
            <ACButton
              color="primary"
              style={{
                marginBottom: '2em',
                width: '70%',
                borderRadius: '0',
                height: '10%',
              }}
              onClick={() =>
                handleButtonsClick('https://app.advisorycloud.com/login')
              }
            >
              <Typography variant="old_font" sx={classes.buttonText}>
                {t('LOGIN-MODAL-LOGIN-TEXT')}
              </Typography>
            </ACButton>
          </div>
          <div style={classes.buttonBox}>
            <ACButton
              color="primary"
              variant="outlined"
              style={{
                borderWidth: 2,
                width: '70%',
                borderRadius: '0',
                height: '10%',
              }}
              onClick={() =>
                handleButtonsClick(
                  'https://register.advisorycloud.com/advisory-board-plans-overview/'
                )
              }
            >
              <Typography variant="old_font" sx={classes.buttonText}>
                {t('LOGIN-MODAL-SIGNUP-TEXT')}
              </Typography>
            </ACButton>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
