import React, { useState, useEffect } from 'react';
import {
  Typography,
  useMediaQuery,
  Paper,
  Switch,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ACButton from '../ACButton';
import ACSnackbar from '../ACSnackbar';
import { mutationUpdateContactEmailPreferences } from '../../services/company_services';
import { mutationUpdateAdvisorEmailPreferences } from '../../services/advisor_services';
import { AccountType } from '../../services/utils/types';
import { useAccount } from '../../contexts/Account';
import eventBus, { eventBusValues } from '../../eventBus';
import { editBillingInfo, viewPastInvoices } from '../../services/Recurly';

const useStyles = (isSM) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  containerNotification: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  sectionTitle: {
    marginLeft: isSM ? '10px' : '0px',
    color: 'grey.500',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  buttonContainer: {
    display: 'inline-flex',
    flexDirection: 'row',
  },
  switchContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  notificationsTitle: {
    fontWeight: 'bold',
  },
  notificationsTextContainer: {
    maxWidth: '1200px',
    wordWrap: 'break-word',
  },
  longText: {
    whiteSpace: 'pre-wrap',
  },
  topRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  iconContainer: {
    width: isSM ? '15%' : '7%',
    paddingTop: 4,
  },
  iconContainerBell: {
    width: isSM ? '15%' : '7%',
  },
  actionsTable: {
    width: '100%',
    fontSize: '14px',
    fontFamily: 'Poppins',
  },
  actionCell: {
    height: isSM ? '60px' : '30px',
    width: isSM ? '30%' : '30%',
    textAlign: 'center',
    cursor: 'pointer',
    color: '#3171F6',
    fontWeight: '500',
  },
  text: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '400',
    color: '#232B35',
    fontStyle: 'normal',
    fontWeight: 700,
    letterSpacing: -0.43,
  },
  bottomRow: {
    display: 'flex',
    width: '100%',
    marginTop: '15px',
    flexGrow: 1,
  },
  card2: {
    width: '100%',
    backgroundColor: 'white',
    marginBottom: '25px',
    paddingTop: '25px',
    // paddingLeft: isSM ? '15px' : '38px',
    // paddingRight: isSM ? '15px' : '38px',
    paddingBottom: isSM ? '15px' : '20px',
    maxWidth: '1200px',
  },
  dialogContainer: {
    borderRadius: '10px',
    boxShadow: '0 5px 20px rgba(0, 0, 0, 0.07)',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dialogText: {
    color: 'black',
  },
});

function NotificationsSettings(props) {
  const {
    toggleValues,
    toggleTextValues,
    user,
    companyId,
    acountType,
    formValues,
    setFormValues,
    hidePlanDetails,
    hideBillingInformation,
  } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const { t } = useTranslation();
  const [isErrorSnackbarVisible, setIsErrorSnackbarVisible] = useState(false);
  const [isSuccessSnackbarVisible, setIsSuccessSnackbarVisible] =
    useState(false);
  const [disableSwitchs, setDisableSwitchs] = useState(false);
  const { accountDetails, isFreeTrial, isFreemium, currentPlan } = useAccount();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalLoading] = useState(false);
  const [teamMembersCount, setTeamMembersCount] = useState(0);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [allEmails, setAllEmails] = useState(
    Object.values(formValues).some((v) => !v) ||
      Object.keys(formValues).length === 0
  );
  const CEO = accountDetails.contacts.find((c) => c.isBoardOwner);
  const history = useHistory();

  useEffect(() => {
    if (accountDetails.contacts) {
      setTeamMembersCount(
        accountDetails.contacts.filter(
          (contact) =>
            contact.accountStatus === 'active' ||
            contact.accountStatus === 'new'
        ).length
      );
    }
  }, [accountDetails]);

  function getBillingCycleValue() {
    if (currentPlan) {
      if (currentPlan.recurlyPlanCodeTerm === 'None') {
        return 'None';
      }
      if (
        currentPlan.salesforceValues &&
        currentPlan.salesforceValues.billingCycle
      ) {
        return currentPlan.salesforceValues.billingCycle;
      }
    }
    return '';
  }

  const billingCycle = getBillingCycleValue();

  function upgradePlan() {
    if (isFreemium) {
      history.push('/upgrade/b2b_general');
    } else if (isFreeTrial) {
      eventBus.dispatch(eventBusValues.triggerCompanyUpgradeAction);
    } else {
      window.open(
        'https://register.advisorycloud.com/advisory-services/',
        '_blank'
      );
    }
  }

  function goToCancelMembership() {
    setIsModalVisible(true);
    window.location =
      'mailto:advisory.solutions@advisorycloud.com?subject=Cancel%20My%20Company%20Membership';
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    return [
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
      date.getFullYear(),
    ].join('/');
  }

  function companyBillingEdit() {
    if (acountType == AccountType.COMPANY) {
      editBillingInfo(accountDetails.companyRecurlyHostedLoginToken);
    } else {
      editBillingInfo(accountDetails.advisorRecurlyHostedLoginToken);
    }
  }

  function viewPastInvoice() {
    if (acountType == AccountType.COMPANY) {
      viewPastInvoices(accountDetails.companyRecurlyHostedLoginToken);
    } else {
      viewPastInvoices(accountDetails.advisorRecurlyHostedLoginToken);
    }
  }

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  function getAccountStatusText() {
    if (isFreemium) {
      return 'Free Account Active';
    }
    if (isFreeTrial) {
      return 'Free Trial Active';
    }
    return CEO.accountStatus || '';
  }

  function changedSwitch(checked, formKey) {
    setDisableSwitchs(true);
    if (formKey === 'all') {
      setAllEmails(checked);
      let newFormValues = { ...formValues };
      toggleValues.forEach((value) => {
        newFormValues[value] = !checked;
      });
      setFormValues(newFormValues);
    } else {
      setFormValues({
        ...formValues,
        [formKey]: !checked,
      });
      if (!allEmails) {
        setAllEmails(
          Object.values({ ...formValues, [formKey]: !checked }).some((v) => !v)
        );
      }
    }
    if (acountType == AccountType.COMPANY) {
      mutationUpdateContactEmailPreferences({
        ATTRIBUTE: formKey,
        COMPANY_ID: companyId,
        CONTACT_ID: user.id,
        VALUE: !checked,
      })
        .then(() => {
          setIsSuccessSnackbarVisible(true);
        })
        .catch(() => {
          setFormValues({
            ...formValues,
            [formKey]: checked,
          });
          setIsErrorSnackbarVisible(true);
        })
        .finally(() => {
          setDisableSwitchs(false);
        });
    } else {
      mutationUpdateAdvisorEmailPreferences({
        ATTRIBUTE: formKey,
        ADVISOR_ID: user.id,
        VALUE: !checked,
      })
        .then(() => {
          setIsSuccessSnackbarVisible(true);
        })
        .catch(() => {
          setFormValues({
            ...formValues,
            [formKey]: checked,
          });
          setIsErrorSnackbarVisible(true);
        })
        .finally(() => {
          setDisableSwitchs(false);
        });
    }
  }

  return (
    <>
      <ACSnackbar
        id="notifications-error-snackbar"
        onClose={() => setIsErrorSnackbarVisible(false)}
        open={isErrorSnackbarVisible}
        severity="error"
        text={t('COMPANY-MEMBERS-GENERIC-ERROR')}
        style={{ marginTop: isFreemium ? '50px' : '0px' }}
      />

      <ACSnackbar
        id="notifications-success-snackbar"
        onClose={() => setIsSuccessSnackbarVisible(false)}
        open={isSuccessSnackbarVisible}
        text={t('COMPANY-NOTIFICATIONS-SUCCESS')}
        style={{ marginTop: isFreemium ? '50px' : '0px' }}
      />

      <div style={classes.container}>
        <div
          style={{
            ...classes.buttonContainer,
            justifyContent:
              acountType == AccountType.COMPANY ? 'flex-end' : 'flex-start',
            width: '728px',
          }}
        ></div>
        {!hidePlanDetails && (
          <>
            <Paper elevation={0} sx={classes.card2}>
              <section>
                <div style={classes.topRow}>
                  <Typography variant="h1_old_font" sx={classes.text}>
                    Plan details
                  </Typography>
                </div>
                <div style={classes.bottomRow}>
                  <table style={classes.actionsTable}>
                    <tbody>
                      <tr>
                        <td>
                          {t('COMPANY-MEMBERSHIP-STARTDATE', {
                            startDate:
                              formatDate(accountDetails.createdAt) || '',
                          })}
                        </td>
                        <td style={classes.actionCell}>
                          <Typography onClick={() => upgradePlan()}>
                            {t('COMPANY-MEMBERSHIP-UPGRADE-MEMBERSHIP')}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        <td style={classes.accountStatus}>
                          {t('COMPANY-MEMBERSHIP-STATUS', {
                            status: getAccountStatusText() || '',
                          })}
                        </td>
                        <td style={classes.actionCell}>
                          <Typography onClick={() => goToCancelMembership()}>
                            {t('COMPANY-MEMBERSHIP-CANCEL-MEMBERSHIP')}
                          </Typography>
                        </td>
                      </tr>
                      <tr>
                        {accountDetails.boardSeatsAllowed === -1 ? (
                          <td>Company Users: Unlimited users</td>
                        ) : (
                          <td>
                            {t('COMPANY-MEMBERSHIP-BOARDS-JOINED', {
                              boards: teamMembersCount,
                              boardsCanJoinCount:
                                accountDetails.boardSeatsAllowed,
                            })}
                          </td>
                        )}
                        <td style={classes.actionCell} />
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
            </Paper>

            <Paper elevation={0} sx={classes.card2}>
              <section>
                <div style={classes.topRow}>
                  <Typography variant="h1_old_font" sx={classes.text}>
                    Billing information
                  </Typography>
                </div>
                <div style={classes.bottomRow}>
                  <table style={classes.actionsTable}>
                    <tbody>
                      <tr>
                        <td>{accountDetails.companyName}</td>
                        {accountDetails.companyRecurlyHostedLoginToken ? (
                          <td style={classes.actionCell}>
                            <Typography onClick={() => companyBillingEdit()}>
                              {t('COMPANY-ACCOUNT-EDIT-BILLING')}
                            </Typography>
                          </td>
                        ) : (
                          <td />
                        )}
                      </tr>
                      <tr>
                        <td>
                          {t('COMPANY-ACCOUNT-BILLEDCYCLE', {
                            billedCycle: billingCycle || '',
                          })}
                        </td>
                        {accountDetails.companyRecurlyHostedLoginToken ? (
                          <td style={classes.actionCell}>
                            <Typography onClick={() => viewPastInvoice()}>
                              {t('COMPANY-ACCOUNT-PAST-INVOICES')}
                            </Typography>
                          </td>
                        ) : (
                          <td />
                        )}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
            </Paper>
          </>
        )}
        {!hideBillingInformation && (
          <>
            <Paper elevation={0} sx={{ ...classes.card2, marginBottom: 0 }}>
              <div style={classes.containerNotification}>
                <div style={classes.contentContainer}>
                  <Typography variant="body1_old_font" sx={classes.text}>
                    Notifications
                  </Typography>

                  <div style={classes.notificationsTextContainer}>
                    <pre style={classes.longText}>
                      <Typography variant="body1_old_font">
                        {t('COMPANY-NOTIFICATIONS-INFO-TEXT')}
                      </Typography>
                    </pre>
                  </div>
                  <div style={classes.switchContainer}>
                    <Typography
                      variant="body1"
                      sx={classes.sectionTitle}
                      style={{
                        fontWeight: 'bold',
                        color: 'black',
                      }}
                    >
                      Opt-in to email notifications from AdvisoryCloud
                    </Typography>
                    <Switch
                      checked={allEmails}
                      onChange={(event, checked) => {
                        if (allEmails) {
                          setOpenConfirmationDialog(true);
                        } else {
                          changedSwitch(checked, 'all');
                        }
                      }}
                      color="primary"
                      disabled={disableSwitchs}
                    />
                  </div>
                  {toggleValues.map((value) => {
                    return (
                      <div style={classes.switchContainer} key={value}>
                        <Typography
                          variant="body1_old_font"
                          sx={classes.sectionTitle}
                          style={{
                            fontWeight: 'bold',
                            color: 'black',
                          }}
                        >
                          {t(toggleTextValues[value])}
                        </Typography>
                        <Switch
                          checked={!formValues[value]}
                          onChange={(event, checked) =>
                            changedSwitch(checked, value)
                          }
                          color="primary"
                          disabled={disableSwitchs}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </Paper>
          </>
        )}
      </div>
      <Dialog
        open={isModalVisible}
        sx={classes.dialogContainer}
        maxWidth="md"
        onClose={() => {
          if (!isModalLoading) {
            setIsModalVisible(false);
          }
        }}
      >
        <div style={classes.dialogContainer}>
          <DialogContent>
            <DialogContentText sx={classes.dialogText}>
              {t('COMPANY-CANCEL-MEMBERSHIP-INTRUCTIONS')}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={classes.dialogActions}>
            <ACButton
              variant="contained"
              color="primary"
              onClick={() => {
                setIsModalVisible(false);
              }}
              style={{
                paddingTop: isSM ? '30px' : '0px',
                paddingBottom: isSM ? '30px' : '0px',
              }}
            >
              OK
            </ACButton>
          </DialogActions>
        </div>
      </Dialog>
      <Dialog
        maxWidth="xs"
        open={openConfirmationDialog}
        PaperProps={{
          sx: classes.dialogContainer,
        }}
        onClose={() => setOpenConfirmationDialog(false)}
      >
        <div>
          <DialogContent>
            <DialogContentText sx={classes.dialogText}>
              By disabling this option, you may not receive critical account
              notifications from AdvisoryCloud. We recommend keeping this
              setting on during the duration of your membership
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Button
              onClick={() => {
                changedSwitch(false, 'all');
                setOpenConfirmationDialog(false);
              }}
              color="primary"
              variant="outlined"
              style={{
                paddingTop: 15,
                paddingBottom: 15,
                fontFamily: 'Poppins',
                height: 37,
                borderRadius: 20,
                textTransform: 'none',
              }}
            >
              Opt-out
            </Button>
            <Button
              onClick={() => setOpenConfirmationDialog(false)}
              color="primary"
              variant="contained"
              style={{
                paddingTop: 15,
                paddingBottom: 15,
                fontFamily: 'Poppins',
                height: 37,
                borderRadius: 20,
                textTransform: 'none',
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
}

export default NotificationsSettings;
