import React from 'react';
import { Alert, Snackbar } from '@mui/material';

function ACSnackbar(props) {
  const { open, onClose, text, severity, sx } = props;
  const style =
    severity === 'success'
      ? {
          width: '100%',
          bgcolor: 'primary.main',
          color: 'white',
          '& .MuiAlert-icon': {
            color: 'white',
          },
        }
      : {
          width: '100%',
        };

  return (
    <Snackbar open={open} onClose={onClose} sx={sx}>
      <Alert
        onClose={onClose}
        severity={severity}
        variant="standard"
        sx={style}
      >
        {text}
      </Alert>
    </Snackbar>
  );
}

export default ACSnackbar;
