import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Clear } from '@mui/icons-material';
import ACAvatar from './../../ACAvatar';
import { useAccount } from './../../../contexts/Account';
import DefaultAdvisorAvatar from './../../../icons/user-avatar.svg';
import { AccountType } from './../../../services/utils/types';
import { sendSurveyResponses } from './../../../services/Backend/account';
import TrustPilotEmbedModal from './../../TrustPilotEmbedModal';
import { getTrustPilotLink } from './../../../services/Backend/account';
import AdvisoryCloudLogo from './../../AdvisoryCloudLogo';

const useStyles = (isXS) => ({
  dialogContainer: {
    borderRadius: '15px',
    maxWidth: '485px',
    boxShadow: '0 5px 20px rgba(0, 0, 0, 0.07)',
    padding: '30px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentWithBorder: {
    padding: '0 24px',
  },
  contentImage: {
    width: isXS ? '150px' : '120px',
    height: isXS ? '150px' : '120px',
    marginTop: isXS ? '10px' : '0px',
    marginBottom: isXS ? '20px' : '0px',
    boxShadow: '1px 2px 5px #bebebe',
    border: 'solid 2px white',
  },
  text: {
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
  },
  link: {
    color: 'black',
  },
  avatarTitleContainer: {
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    color: '#232B35',
    paddingTop: '22px',
  },
  avatarDetailsContainer: {
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    color: '#232B35',
    paddingBottom: '25px',
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#F5F7FA',
    width: 'calc(100% + 60px)',
    marginBottom: '-30px',
  },
  actionsHeader: {
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    color: '#232B35',
    padding: '20px 60px 0',
  },
  doNotShowLabel: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    color: '#232B35',
  },
  actionsButton: {
    textTransform: 'none',
    height: '40px',
    borderRadius: '50px',
    width: '142px',
    margin: '5px',
    flexGrow: 1,
    border: '2px solid #0F78FD',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    color: '#3171F6',
    padding: '5px',
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      border: '2px solid #0F78FD',
    },
  },
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'row',
    paddingTop: '1rem',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    margin: '1rem 2rem 1rem 2rem',
    alignItems: 'center',
  },
  textHeadline: {
    color: '#232B35',
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    paddingTop: '10px',
  },
  dialogContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
    maxWidth: '50rem',
  },
  logoContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    overflowY: 'visible',
  },
  contentNoBorder: {
    padding: '0 4rem',
  },
  howToImprove: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
  },
  submitContainer: {
    padding: '2rem 6rem',
  },
  clearIcon: {
    margin: '25px 5px 0 5px',
    float: 'right',
    cursor: 'pointer',
    alignSelf: 'end',
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ReviewPopUp({
  postData,
  reviewPopUpOpen,
  setReviewPopUpOpen,
}) {
  const { accountDetails, type, updateHideTrustPilot } = useAccount();
  const [feedbackPopUp, setFeedbackPopUp] = useState(false);
  const [neverShowAgain, setNeverShowAgain] = useState(false);
  const [trustModal, setTrustModal] = useState(false);
  const [trustPilotLink, setTrustPilotLink] = useState('');
  let buttonEvents = false;
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const classes = useStyles(isXS);
  const [body, setBody] = useState('');
  const { t } = useTranslation();

  function handleClose() {
    setFeedbackPopUp(false);
    setReviewPopUpOpen(false);
    updateHideTrustPilot(accountDetails.id, type, true);
  }

  function handlePersonalizedClose() {
    setFeedbackPopUp(false);
    setReviewPopUpOpen(false);
    if (neverShowAgain || buttonEvents) {
      updateHideTrustPilot(accountDetails.id, type, true);
    }
  }

  function handleTextChange(event) {
    setBody(event.target.value);
  }

  function handleFeedback() {
    setFeedbackPopUp(true);
  }

  function handleExcellent() {
    buttonEvents = true;
    getTrustPilotLink({
      REFERENCE: accountDetails.recurlySubscriptionUuid,
      EMAIL: accountDetails.email,
      NAME: accountDetails.displayName,
    }).then((resp) => {
      if (resp && resp.data && resp.data.getTrustPilotLink) {
        setTrustPilotLink(resp.data.getTrustPilotLink);
        setTrustModal(true);
        setReviewPopUpOpen(false);
      }
    });
  }

  async function submitResponse() {
    if (type === AccountType.COMPANY) {
      const response = await sendSurveyResponses({
        userId: accountDetails.userData.id,
        body: body,
        accountType: type,
        companyId: accountDetails,
      });
    } else {
      const response = await sendSurveyResponses({
        userId: accountDetails.id,
        body: body,
        accountType: type,
      });
    }
    setNeverShowAgain(true);

    handleClose();
  }

  function displayImage() {
    if (postData.postCompanyContact.companyContactImage != null) {
      if (
        !postData.postCompanyContact.companyContactImage.includes('undefined')
      ) {
        return postData.postCompanyContact.companyContactImage;
      }
    }
    return DefaultAdvisorAvatar;
  }

  function handleCheckboxClick() {
    setNeverShowAgain(!neverShowAgain);
  }

  return (
    <div>
      <Dialog
        open={reviewPopUpOpen}
        TransitionComponent={Transition}
        maxWidth="sm"
        fullScreen={isXS}
        keepMounted
        onClose={handlePersonalizedClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          sx: classes.dialogContainer,
        }}
      >
        {isXS ? (
          <div style={classes.clearIcon}>
            <Clear onClick={handlePersonalizedClose} />
          </div>
        ) : null}
        <div style={classes.dialogContentContainer}>
          <div style={classes.logoContainer}>
            <ACAvatar
              style={classes.contentImage}
              size="lg"
              image={displayImage()}
            />
          </div>
          <div style={classes.header}>
            <Typography sx={classes.textHeadline}>
              {t('REVIEW-YOUR-ANSWER-HAS-BEEN-SUBMITTED')}
            </Typography>
          </div>
          <DialogContent sx={classes.contentWithBorder}>
            <Typography style={classes.text}>
              {t('REVIEW-THANK-YOU-FOR-YOUR-CONTRIBUTION', {
                companyName: postData.postCompanyContact.companyName,
              })}
            </Typography>
            <div>
              <Typography style={classes.avatarTitleContainer}>
                {postData.postCompanyContact.name}
              </Typography>
              <Typography style={classes.avatarDetailsContainer}>
                {postData.postCompanyContact.title} -{' '}
                {postData.postCompanyContact.companyName}
              </Typography>
            </div>
          </DialogContent>
          <DialogActions disableSpacing sx={classes.actionsContainer}>
            <Typography style={classes.actionsHeader}>
              {t('REVIEW-ARE-YOU-ENJOYING', {
                companyName: postData.postCompanyContact.companyName,
              })}
            </Typography>
            <div style={classes.actions}>
              <Button
                variant="outlined"
                color="primary"
                sx={classes.actionsButton}
                onClick={handleFeedback}
              >
                {t('REVIEW-NEEDS-IMPROVEMENT')}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                sx={classes.actionsButton}
                onClick={handleFeedback}
              >
                {t('REVIEW-SATISFACTORY')}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                sx={classes.actionsButton}
                onClick={handleExcellent}
              >
                {t('REVIEW-EXCELLENT')}
              </Button>
            </div>
            <div style={classes.actions}>
              <FormControlLabel
                classes={{
                  label: classes.doNotShowLabel,
                }}
                control={
                  <Checkbox
                    checked={neverShowAgain}
                    onChange={handleCheckboxClick}
                    color="primary"
                  />
                }
                label={t('REVIEW-NEVER-SHOW-AGAIN')}
              />
            </div>
          </DialogActions>
        </div>
      </Dialog>
      <Dialog
        open={feedbackPopUp}
        TransitionComponent={Transition}
        fullWidth
        maxWidth="sm"
        keepMounted
        onClose={handlePersonalizedClose}
        PaperProps={{
          sx: classes.dialogContainer,
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <div style={classes.clearIcon}>
          <Clear onClick={handlePersonalizedClose} style={{ float: 'right' }} />
        </div>
        <div style={classes.header}>
          <AdvisoryCloudLogo link="dashboard" width={250} />
        </div>
        <DialogContent sx={classes.contentNoBorder}>
          <DialogContentText
            sx={classes.howToImprove}
            id="alert-dialog-slide-description"
          >
            {t('REVIEW-HOW-IMPROVED')}
          </DialogContentText>
          <TextField
            variant="outlined"
            multiline
            fullWidth
            value={body}
            onChange={handleTextChange}
            rows={3}
            maxrows={3}
          />
        </DialogContent>
        <DialogActions sx={classes.submitContainer}>
          <Button
            variant="outlined"
            color="primary"
            sx={classes.actionsButton}
            onClick={submitResponse}
          >
            {t('REVIEW-SUBMIT-RESPONSE')}
          </Button>
        </DialogActions>
      </Dialog>
      {trustModal ? (
        <>
          <TrustPilotEmbedModal
            trustModal={trustModal}
            setTrustModal={setTrustModal}
            trustPilotLink={trustPilotLink}
            setFeedbackPopUp={setFeedbackPopUp}
          />
        </>
      ) : null}
    </div>
  );
}
