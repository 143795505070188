import React, { useState, useEffect } from 'react';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { ChevronRight, ContentCopy } from '@mui/icons-material';
import { useAccount } from './../../../../contexts/Account';
import CustomIcon from './../../../../icons/customIcon';
import ActionButton from './actionsButton';

const useStyles = () => ({
  root: {
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: '50px',
  },
  buttons: {
    '& > *': {
      margin: 'theme.spacing(1)',
    },
  },
  shareButton: {
    color: '#232B35',
    backgroundColor: '#F5F7FA',
    width: '34px',
    height: '34px',
    margin: '7px',
    marginRight: '20px',
  },
  iconShare: {
    fontSize: '20px',
  },
  icon: {
    fontSize: '22px',
  },
  backButton: {
    marginLeft: '20px',
    cursor: 'pointer',
  },
  headlineLink: {
    width: '100%',
    fontWeight: 700,
    fontSize: '16px',
    fontStyle: 'normal',
    color: '#3171F6',
    paddingLeft: '5px',
    letterSpacing: -0.43,
  },
});

export default function ButtonGroupBar(props) {
  const classes = useStyles();
  const { conversations, isImpersonated } = useAccount();
  const [alreadyMessagedBoardOwner, setAlreadyMessagedBoardOwner] =
    useState(false);
  const [shareMenuAnchor, setShareMenuAnchor] = useState(null);

  const {
    isPublicProfileView,
    isPublicProfile,
    backAction,
    isOnBoard,
    onConversation,
    onShare,
    onInvite,
    onShareProfile,
    onSharePublic,
    advisor,
    showInactiveAdvisorBar,
  } = props;

  function findingConversation() {
    if (conversations && conversations.length > 0) {
      const index = conversations.findIndex(
        (conversation) =>
          conversation.conversationMemberAccountId === advisor.id
      );
      setAlreadyMessagedBoardOwner(index > -1);
    }
  }

  useEffect(() => {
    findingConversation();
  }, [advisor]);

  const openShareMenu = (event) => {
    setShareMenuAnchor(event.currentTarget);
  };

  const closeShareMenu = () => {
    setShareMenuAnchor(null);
  };

  const handleShareInApp = () => {
    onShareProfile();
    closeShareMenu();
  };

  const handleSharePublic = () => {
    onSharePublic();
    closeShareMenu();
  };

  return (
    <div>
      <div
        style={{
          ...classes.root,
          marginBottom: isPublicProfile ? '-100px' : null,
        }}
      >
        {!isPublicProfile ? (
          <div style={classes.backButton}>
            <ChevronRight
              onClick={backAction}
              sx={{
                fontSize: '30px',
              }}
              color="primary"
            />
          </div>
        ) : (
          <div></div>
        )}
        <Box sx={classes.buttons}>
          {isImpersonated && (
            <IconButton sx={classes.shareButton} onClick={openShareMenu}>
              <ContentCopy sx={classes.iconShare} />
            </IconButton>
          )}
          {!isPublicProfile && !isPublicProfileView ? (
            <IconButton sx={classes.shareButton} onClick={onConversation}>
              <CustomIcon
                iconname={alreadyMessagedBoardOwner ? 'comments' : 'airplane'}
                style={{
                  fontSize: alreadyMessagedBoardOwner ? 24 : 20,
                  zIndex: 3,
                }}
              />
            </IconButton>
          ) : null}
          <ActionButton
            isPublicProfileView={isPublicProfileView}
            isOnBoard={isOnBoard}
            onInvite={onInvite}
            onConversation={onConversation}
            advisor={advisor}
            showInactiveAdvisorBar={showInactiveAdvisorBar}
          />
        </Box>
        <Menu
          anchorEl={shareMenuAnchor}
          open={Boolean(shareMenuAnchor)}
          onClose={closeShareMenu}
          className={classes.shareMenu}
        >
          <MenuItem onClick={handleShareInApp}>In-App</MenuItem>
          <MenuItem onClick={handleSharePublic}>Public</MenuItem>
        </Menu>
      </div>
    </div>
  );
}
