import React from 'react';
import { Typography, Badge } from '@mui/material';
import { withStyles } from '@mui/styles';
import CustomIcon from './../../../../../icons/customIcon';

const useStyles = (buttonColor = '#2271f2') => ({
  mainConatiner: {
    display: 'flex',
    flexDirection: 'row',
    flexShrink: 0,
  },
  shrinkControlContainer: {
    flexShrink: 0,
  },
  button: {
    border: 'none',
    display: 'flex',
    outline: 'none',
    backgroundColor: 'transparent',
    padding: '0px',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&:disabled': {
      cursor: 'default',
    },
  },
  commentsTextLarge: {
    fontStyle: 'normal',
    fontWeight: '600',
    marginTop: '4px',
    fontSize: '16px',
    color: buttonColor,
  },
  commentsTexMobile: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    color: buttonColor,
  },
});

const StyledBadge = withStyles(() => ({
  badge: {
    height: '14px',
    minWidth: '14px',
    padding: '0px',
    borderRadius: '50%',
    right: '22px',
    top: '4px',
    fontSize: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '2px',
  },
}))(Badge);

const StyledBadgeLG = withStyles(() => ({
  badge: {
    height: '14px',
    minWidth: '14px',
    padding: '0px',
    borderRadius: '50%',
    right: '24.5px',
    top: '6px',
    fontSize: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '2px',
  },
}))(Badge);
const StyledBadgeXS = withStyles(() => ({
  badge: {
    height: 11,
    minWidth: 11,
    padding: 0,
    borderRadius: '50%',
    right: 17.5,
    top: 2,
    fontSize: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 1,
  },
}))(Badge);

function CommentsButton(props) {
  const {
    numComments,
    onClick,
    filled,
    badge,
    disabled,
    badgeContent,
    iconSize,
    buttonColor,
  } = props;
  const classes = useStyles(buttonColor);

  function sizeSelector() {
    if (iconSize === 'xs') {
      return '19px';
    }
    if (iconSize === 'sm') {
      return '24px';
    }
    if (iconSize === 'lg') {
      return '28px';
    }
    return '19px';
  }

  function renderIcon() {
    if (filled) {
      return (
        <CustomIcon
          iconname="comments"
          style={{
            color: '#1663F7',
            fontSize: sizeSelector(),
            marginRight: '8px',
            marginTop: -1,
          }}
        />
      );
    }
    return (
      <CustomIcon
        iconname="comments"
        style={{
          color: '#2271f2',
          fontSize: sizeSelector(),
          marginRight: '8px',
          marginTop: -1,
        }}
      />
    );
  }

  function renderContent() {
    if (
      badgeContent === null ||
      badgeContent === undefined ||
      isNaN(badgeContent)
    ) {
      return <span>&#9679;</span>;
    }
    return badgeContent;
  }

  const unsetNumber =
    numComments === null || (numComments === undefined) | isNaN(numComments);

  function renderComments() {
    if (unsetNumber && iconSize === 'xs') {
      return (
        <Typography variant="caption" sx={classes.commentsTexMobile}>
          0 Comments
        </Typography>
      );
    }
    if (unsetNumber) {
      return (
        <Typography variant="body1" sx={classes.commentsTextLarge}>
          0 Comments
        </Typography>
      );
    }
    if (!unsetNumber && iconSize === 'xs') {
      return (
        <Typography variant="caption" sx={classes.commentsTexMobile}>
          {`${numComments} Comments`}
        </Typography>
      );
    }
    if (!unsetNumber) {
      return (
        <Typography variant="body1" sx={classes.commentsTextLarge}>
          {`${numComments} Comments`}
        </Typography>
      );
    }
    return null;
  }

  function renderBadge() {
    if (badge || badgeContent) {
      return false;
    }
    return true;
  }

  function renderBadgeStyle() {
    if (iconSize === 'lg') {
      return (
        <StyledBadgeLG
          badgeContent={renderContent()}
          color="error"
          invisible={renderBadge()}
        >
          {renderIcon()}
        </StyledBadgeLG>
      );
    }
    if (iconSize === 'xs') {
      return (
        <StyledBadgeXS
          badgeContent={renderContent()}
          color="error"
          invisible={renderBadge()}
        >
          {renderIcon()}
        </StyledBadgeXS>
      );
    }
    return (
      <StyledBadge
        badgeContent={renderContent()}
        color="error"
        invisible={renderBadge()}
      >
        {renderIcon()}
      </StyledBadge>
    );
  }

  return (
    <div style={classes.mainConatiner}>
      <div style={classes.shrinkControlContainer}>
        <button
          style={classes.button}
          onClick={(event) => {
            onClick();
            event.stopPropagation();
          }}
          id="commentsButton"
          type="button"
          disabled={disabled}
        >
          {renderBadgeStyle()}
          {renderComments()}
        </button>
      </div>
    </div>
  );
}

export default CommentsButton;
