import React, { useEffect, useState } from 'react';
import CustomIcon from './../../../icons/customIcon';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useAccount } from './../../../contexts/Account';
import Treatment from './../../Treatment';
import { featureFlags } from './../../../splitSetup';

const useStyles = () => ({
  box: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    textAlign: 'left',
    justifyContent: 'flex-start',
    borderRadius: '25px',
    marginTop: '15px',
    color: 'black',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '-0.43px',
    textTransform: 'none',
    paddingLeft: '15px',
    width: '146px',
    height: '32px',
    marginLeft: '1px',
  },
  arrawdown: {
    position: 'absolute',
    right: '10px',
    top: '6px',
  },
});

const PostTypeSelector = ({ setPostType, currentPostType }) => {
  const { isImpersonated, currentUserInfo } = useAccount();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedType, setSelectedType] = useState({});
  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTypeSelect = (type) => {
    setSelectedType(type);
    setPostType(type.type);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'post-type-popover' : undefined;

  useEffect(() => {
    const postTypesType = {
      question: {
        color: '#D7F1FD',
        icon: (
          <CustomIcon iconname="bubbles-question" style={{ fontSize: 20 }} />
        ),
        label: 'Question',
        type: 'question',
      },
      update: {
        color: '#D7E2FD',
        icon: (
          <CustomIcon iconname="bullhorn-outline" style={{ fontSize: 20 }} />
        ),
        label: 'Update',
        type: 'update',
      },
      meeting: {
        color: '#FFEED6',
        icon: <CustomIcon iconname="post-meetings" style={{ fontSize: 20 }} />,
        label: 'Meeting',
        type: 'meeting',
      },
      insight: {
        color: '#DBF7EB',
        icon: <CustomIcon iconname="insights" style={{ fontSize: 20 }} />,
        label: 'Insight',
        type: 'insight',
      },
    };

    // Cuando el componente se monta o cuando `currentPostType` cambia:
    if (currentPostType == postTypesType.question.type) {
      setSelectedType(postTypesType.question);
    } else if (currentPostType == postTypesType.update.type) {
      setSelectedType(postTypesType.update);
    } else if (currentPostType == postTypesType.meeting.type) {
      setSelectedType(postTypesType.meeting);
    } else if (currentPostType == postTypesType.insight.type) {
      setSelectedType(postTypesType.insight);
    }
  }, [currentPostType]);

  return (
    <div>
      {selectedType ? (
        <Button
          id="postTypeButton"
          onClick={handleClick}
          sx={{
            ...classes.button,
            backgroundColor: selectedType.color,
            '&:hover': { backgroundColor: selectedType.color },
          }}
          startIcon={selectedType.icon}
          endIcon={<ArrowDropDownIcon sx={classes.arrawdown} />}
        >
          {selectedType.label}
        </Button>
      ) : (
        <Button onClick={handleClick}>Select a post type</Button>
      )}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box sx={classes.box} p={2}>
          <Typography>Select a post type</Typography>
          {/* <Button
            sx={{
              ...classes.button,
              backgroundColor: '#DBF7EB',
              '&:hover': { backgroundColor: '#DBF7EB' },
            }}
            onClick={() =>
              handleTypeSelect({
                color: '#DBF7EB',
                icon: <CalendarTodayIcon />,
                label: 'Board Meeting',
                type: 'boardMeeting',
              })
            }
            startIcon={<CalendarTodayIcon />}
          >
            Board Meeting
          </Button> */}
          {/* <Button
            sx={{
              ...classes.button,
              backgroundColor: '#FFEED6',
              '&:hover': { backgroundColor: '#FFEED6' },
            }}
            onClick={() =>
              handleTypeSelect({
                color: '#FFEED6',
                icon: <ShowChartIcon />,
                label: 'Poll',
                type: 'boardMeeting',
              })
            }
            startIcon={<ShowChartIcon />}
          >
            Poll
          </Button> */}
          <Button
            id="UpdatePostType"
            sx={{
              ...classes.button,
              backgroundColor: '#D7E2FD',
              '&:hover': { backgroundColor: '#D7E2FD' },
            }}
            onClick={() =>
              handleTypeSelect({
                color: '#D7E2FD',
                icon: (
                  <CustomIcon
                    iconname="bullhorn-outline"
                    style={{ fontSize: 20 }}
                  />
                ),
                label: 'Update',
                type: 'update',
              })
            }
            startIcon={
              <CustomIcon
                iconname="bullhorn-outline"
                style={{ fontSize: 20 }}
              />
            }
          >
            Update
          </Button>
          <Button
            id="QuestionPostType"
            sx={{
              ...classes.button,
              backgroundColor: '#D7F1FD',
              '&:hover': { backgroundColor: '#D7F1FD' },
            }}
            onClick={() =>
              handleTypeSelect({
                color: '#D7F1FD',
                icon: (
                  <CustomIcon
                    iconname="bubbles-question"
                    style={{ fontSize: 20 }}
                  />
                ),
                label: 'Question',
                type: 'question',
              })
            }
            startIcon={
              <CustomIcon
                iconname="bubbles-question"
                style={{ fontSize: 20 }}
              />
            }
          >
            Question
          </Button>

          {isImpersonated && (
            <>
              <Button
                id="MeetingPostType"
                sx={{
                  ...classes.button,
                  backgroundColor: '#FFEED6',
                  '&:hover': { backgroundColor: '#FFEED6' },
                }}
                onClick={() =>
                  handleTypeSelect({
                    color: '#FFEED6',
                    icon: (
                      <CustomIcon
                        iconname="post-meetings"
                        style={{ fontSize: 20 }}
                      />
                    ),
                    label: 'Meeting',
                    type: 'meeting',
                  })
                }
                startIcon={
                  <CustomIcon
                    iconname="post-meetings"
                    style={{ fontSize: 20 }}
                  />
                }
              >
                Meeting
              </Button>
              <Button
                id="InsightPostType"
                sx={{
                  ...classes.button,
                  backgroundColor: '#DBF7EB',
                  '&:hover': { backgroundColor: '#DBF7EB' },
                }}
                onClick={() =>
                  handleTypeSelect({
                    color: '#DBF7EB',
                    icon: (
                      <CustomIcon
                        iconname="insights"
                        style={{ fontSize: 20 }}
                      />
                    ),
                    label: 'Insight',
                    type: 'insight',
                  })
                }
                startIcon={
                  <CustomIcon iconname="insights" style={{ fontSize: 20 }} />
                }
              >
                Insight
              </Button>
            </>
          )}
        </Box>
      </Popover>
    </div>
  );
};

export default PostTypeSelector;
