import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import NewsAndEvents from "./NewsAndEvents";
import Invitations from "./Invitations";
import MyBoards from "./MyBoards";
import UpcomingBoardMeetings from "./UpcomingBoardMeetings";

const Dashboard = () => {
  return (
    <Box sx={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      {/* Encabezado */}
      <Box sx={{ marginBottom: "20px" }}>
        <Typography variant="h4" sx={{ fontWeight: "bold" }}>
          Welcome, <span style={{ color: "#2563eb" }}>Sunny</span>
        </Typography>
        <Typography variant="body1" sx={{ color: "text.secondary" }}>
          Here’s your dashboard for your boards.
        </Typography>
      </Box>

      {/* Layout Principal */}
      <Grid container spacing={3}>
        {/* Columna Principal */}
        <Grid item xs={12} md={8}>
          <NewsAndEvents />
          <Invitations />
          <MyBoards />
        </Grid>

        {/* Columna Secundaria */}
        <Grid item xs={12} md={4}>
          <UpcomingBoardMeetings />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
