import React from 'react';
import { Paper, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AdvisoryCloudLogo from './../../../AdvisoryCloudLogo';
import { useAccount } from './../../../../contexts/Account';
import ACButton from './../../../ACButton';

const classes = {
  outerContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    width: '100%',
    borderRadius: '0px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'center',
    zIndex: 3,
  },
  centerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
  },
};

function Step6Header({ handleViewOpportunitiesClick }) {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isMd = useMediaQuery((theme) => theme.breakpoints.down([1140]));
  const { t } = useTranslation();
  const { accountDetails } = useAccount();
  const {
    image,
    displayName,
    headline,
    skillTagIds,
    positionTagIds,
    biography,
  } = accountDetails;
  const fullInfo =
    image &&
    image.imageKey &&
    displayName &&
    headline &&
    skillTagIds &&
    (positionTagIds || biography);

  return (
    <div style={{ ...classes.outerContainer, height: isSM ? 130 : 80 }}>
      <Paper
        sx={{
          ...classes.container,
          position: isSM ? 'fixed' : 'static',
          height: isSM ? '130px' : '80px',
          backgroundColor: 'white',
          justifyContent: isMd ? 'center' : 'space-around',
        }}
      >
        {!isSM && !isMd && (
          <AdvisoryCloudLogo
            removeHyperlink
            onClick={() => {}}
            width={180}
            cursor="default"
          />
        )}
        <div
          style={{
            ...classes.centerContainer,
            flexDirection: isSM ? 'column' : 'row',
          }}
        >
          {!isSM ? (
            <Typography
              style={{
                marginRight: '10px',
                marginBottom: isSM ? '5px' : '0px',
                fontSize: '20px',
              }}
              id={fullInfo ? 'completeHeadline' : 'unfinishedHeadline'}
            >
              <strong style={{ fontWeight: 600 }}>
                {t(`STEP6-HEADER-${fullInfo ? '' : 'NOT-'}FULL-INFO-1`)}
              </strong>
              {t(`STEP6-HEADER-${fullInfo ? '' : 'NOT-'}FULL-INFO-2`)}
            </Typography>
          ) : (
            <>
              <Typography
                style={{
                  marginRight: '10px',
                  marginBottom: isSM ? '5px' : '0px',
                  fontSize: '16px',
                }}
              >
                <strong style={{ fontWeight: 600 }}>
                  {t(`STEP6-HEADER-${fullInfo ? '' : 'NOT-'}FULL-INFO-1`)}
                </strong>
              </Typography>
              <Typography
                style={{
                  marginRight: '10px',
                  marginBottom: isSM ? '5px' : '0px',
                  fontSize: '16px',
                }}
              >
                {t(`STEP6-HEADER-${fullInfo ? '' : 'NOT-'}FULL-INFO-2`)}
              </Typography>
            </>
          )}

          <div style={{ display: 'flex', paddingBottom: isSM ? 10 : 0 }}>
            <ACButton
              onClick={handleViewOpportunitiesClick}
              id="viewOpportunityButton"
              color="primary"
              style={{
                fontFamily: 'Poppins',
                fontSize: 16,
                fontWeight: 700,
                borderRadius: 40,
                width: 205,
                height: 46,
              }}
              text="View Open Boards"
            />
          </div>
        </div>
        {!isSM && !isMd && (
          <div
            style={{
              width: fullInfo ? 170 : 130,
            }}
          />
        )}
      </Paper>
    </div>
  );
}

export default Step6Header;
