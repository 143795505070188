import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { PriorityHigh } from '@mui/icons-material';
import IconUnderlay from '../../components/IconUnderlay';
import LoadingIndicatorLogo from '../../components/LoadingIndicatorLogo';
import EmailImage from '../../images/Invitation.svg';
import { companySeatsInfo } from '../../services/company_services';
import CardScene from '../../components/CardScene';
import ACSnackbar from '../../components/ACSnackbar';
import ACButton from '../../components/ACButton';
import OopsWindow from '../Oops';
import { hasUrlParams } from '../../services/Authentication/constants';

const useStyles = (isSM) => ({
  card: {
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: 'common.white',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    height: '350px',
    width: !isSM && '600px',
    paddingTop: '20px',
  },

  confirmationLabelTop: {
    textAlign: 'center',
    color: 'secondary.dark',
    marginRight: '100px',
    marginLeft: '100px',
    padding: '0px',
    display: 'inline-flex',
    flexDirection: 'column',
  },
  subtitle: {
    maxWidth: 400,
    textAlign: 'center',
    color: 'secondary.dark',
  },
  emailCheckedImage: {
    height: '100px',
    width: '100px',
  },
  loadingText: {
    marginTop: '30px',
    color: 'grey',
    marginBottom: '40px',
  },
  loadingContainer: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '100px',
    width: '100%',
    height: isSM ? '100vh' : 'auto',
  },
  iconWarning: {
    fontSize: '70px',
    color: 'error.main',
  },
});

const WelcomeCompanyMember = () => {
  // HERE
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const queryParams = new URLSearchParams(location.search);
  const [email, setEmail] = useState();
  const [code, setCode] = useState();
  const [companyName, setComapanyName] = useState();
  const [hasAvailabelSeats, setHasAvailabelSeats] = useState();
  const [unhandledError, setUnhandledError] = useState(false);

  const [displayedComponent] = useState(() => {
    const getEmail = queryParams.get('email');
    const getCode = queryParams.get('code');

    if (getEmail !== null && getCode !== null) {
      setEmail(queryParams.get('email').replace(/\s/g, '+'));
      setCode(getCode);
      return hasUrlParams.PARAMS;
    }

    return hasUrlParams.NO_PARAMS;
  });

  async function getComapnySeatsInfo() {
    const companySeatsInfoCall = await companySeatsInfo({
      email: queryParams.get('email'),
    });
    const companySeatsInfoResponse = await companySeatsInfoCall.json();
    if (companySeatsInfoResponse.errors) {
      const errorMsg = JSON.parse(companySeatsInfoResponse.errors[0].message);
      setUnhandledError(errorMsg.error);
    } else {
      setHasAvailabelSeats(
        companySeatsInfoResponse.data.companySeatsInfo.hasAvailableSeats
      );
      setComapanyName(
        companySeatsInfoResponse.data.companySeatsInfo.companyName
      );
    }
  }
  getComapnySeatsInfo();

  const ReturnWindow = () => {
    if (displayedComponent === hasUrlParams.NO_PARAMS || unhandledError) {
      return (
        <>
          <Helmet>
            <title>{t('COMPANY-JOIN-MEMBER-HELMET-TITLE-ERROR')}</title>
          </Helmet>
          <OopsWindow />
        </>
      );
    }
    if (hasAvailabelSeats == null) {
      return (
        <>
          <CardScene clearPaper>
            <div style={classes.loadingContainer}>
              <LoadingIndicatorLogo
                size={200}
                iconFontSize={93}
                iconRight={105}
              />
            </div>
          </CardScene>
        </>
      );
    }
    if (!hasAvailabelSeats) {
      return (
        <>
          <Helmet>
            <title>Sorry! Board Is Full!</title>
          </Helmet>
          <CardScene>
            <Box id="confirmation-card" sx={classes.card}>
              <IconUnderlay size={90}>
                <PriorityHigh sx={classes.iconWarning} />
              </IconUnderlay>
              <div style={classes.confirmationLabelTop}>
                <Typography
                  variant="h2_old_font"
                  style={{ marginTop: '-3vh', fontSize: '180%' }}
                >
                  {t('FULL-BOARD-TITLE')}
                </Typography>
                <Typography style={{ marginTop: '2vh' }} variant="h2_old_font">
                  {t('FULL-BOARD-SUBTITLE')}
                </Typography>
              </div>
            </Box>
          </CardScene>
        </>
      );
    }
    if (displayedComponent === hasUrlParams.PARAMS) {
      return (
        <>
          <Helmet>
            <title>{t('COMPANY-JOIN-MEMBER-HELMET-TITLE')}</title>
          </Helmet>
          <CardScene>
            <Box id="confirmation-card" sx={classes.card}>
              <ACSnackbar
                id="confirmation-request-received-snackbar"
                text={t('COMPANY-REQUEST-RECEIVED-LABEL')}
                onClose={() => setIsSnackbarVisible(false)}
                open={isSnackbarVisible}
              />
              <img
                alt={t('COMPANY-EMAIL-CHECKED-IMAGE-ALT')}
                style={classes.emailCheckedImage}
                src={EmailImage}
              />
              <div style={classes.confirmationLabelTop}>
                <Typography variant="h2_old_font">
                  {t('JOIN-MEMBER-COMPANY-LABEL', { companyName })}
                </Typography>
              </div>
              <ACButton
                width="100px"
                size="small"
                type="submit"
                color="primary"
                onClick={() => {
                  history.push(`/password/set?email=${email}&code=${code}`);
                }}
              >
                {t('JOIN-MEMBER-CONTINUE-BUTTON')}
              </ACButton>
            </Box>
          </CardScene>
        </>
      );
    }
    return <></>;
  };

  return (
    <>
      <ReturnWindow />
    </>
  );
};

export default WelcomeCompanyMember;
