import { convertToRaw, convertFromRaw, EditorState } from 'draft-js';

export function findTextNewUrl(newEditorState, newText, oldText) {
  try {
    const currentSelection = newEditorState.getSelection(); // Guardar la selección actual
    const newState = newEditorState.getCurrentContent();
    const newRaw = JSON.stringify(convertToRaw(newState));
    let newJsonContent = JSON.parse(newRaw);
    const newTextBlocks = newText.split('\n');
    const oldTextBlocks = oldText.split('\n');

    // console.log({
    //   newJsonContent,
    //   // newText,
    //   // oldText,
    //   newTextBlocks,
    //   oldTextBlocks,
    // });

    for (let ii = 0; ii < newTextBlocks.length; ii++) {
      const newBlock = newTextBlocks[ii];
      const oldBlock = oldTextBlocks[ii];

      if (newBlock !== oldBlock) {
        let newLines = newBlock.split(' ');
        let oldLines = oldBlock.split(' ');
        let index = -1;

        // Encontrar dónde están las diferencias en las líneas nuevas y viejas
        for (let jj = 0; jj < newLines.length; jj++) {
          if (newLines[jj] !== oldLines[jj]) {
            index = jj;
            break;
          }
        }

        if (index > -1 && newLines[index]) {
          const newWord = newLines[index];

          // Regex para detectar una URL parcial (permitir detectar matches en tiempo real)
          const partialUrlRegex = new RegExp(
            '^(https?:\\/\\/)?' + // protocolo opcional
              '(www\\.)?' + // "www." opcional
              '[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\\.[a-zA-Z]{2,}' + // dominio
              '(\\/[-a-zA-Z0-9@:%._\\+~#=]*)?', // ruta opcional
            'i'
          );

          // Si detectamos un match parcial (URL detectada mientras se escribe)
          if (partialUrlRegex.test(newWord)) {
            //console.log(newWord, 'Is being treated as a URL');
            const rawFinalState = {
              ...newJsonContent,
            };

            let newLinkOffset = 0;
            for (let kk = 0; kk < index; kk++) {
              newLinkOffset += newLines[kk].length + 1;
            }

            const countKeys = Object.keys(newJsonContent.entityMap).length;

            // Si la entidad ya existe en este rango, extender el enlace
            const existentEntityRange = rawFinalState.blocks[
              ii
            ].entityRanges.find(
              (entityRange) => entityRange.offset === newLinkOffset
            );
            if (existentEntityRange) {
              existentEntityRange.length = newWord.length; // Extender el enlace
            } else {
              // Crear un nuevo enlace si no existe
              rawFinalState.blocks[ii].entityRanges.push({
                offset: newLinkOffset,
                length: newWord.length,
                key: countKeys,
              });

              rawFinalState.entityMap[countKeys] = {
                type: 'LINK',
                mutability: 'MUTABLE',
                data: {
                  url: newWord, // Aplicar el enlace a la URL
                  targetOption: '_blank',
                },
              };
            }

            const finalContentState = convertFromRaw(rawFinalState);

            // Crear un nuevo estado del editor con el contenido actualizado
            const updatedEditorState =
              EditorState.createWithContent(finalContentState);

            // Restaurar la selección anterior
            const editorStateWithSelection = EditorState.acceptSelection(
              updatedEditorState,
              currentSelection
            );

            return editorStateWithSelection;
          } else if (newWord.endsWith(' ')) {
            // Si el usuario inserta un espacio, detener el hyperlink
            //console.log('Space detected, stopping hyperlink.');
            return false;
          }
        }
      }
    }
  } catch (error) {
    console.log({ error });
  }
  return false;
}
