import React from "react";
import { Box, Typography, Paper, Button, Grid } from "@mui/material";

const meetingsData = [
  { date: "Dec 8", day: 8, title: "Bloom Innovation", time: "12:30 PM" },
  { date: "Dec 12", day: 12, title: "HireList.AI future plan meeting", time: "12:30 PM" },
  { date: "Dec 20", day: 20, title: "CSCONNECT Innovation", time: "12:30 PM" },
  { date: "Dec 2", day: 2, title: "Skyriver IT board meeting", time: "12:30 PM" },
];

const UpcomingBoardMeetings = () => (
  <Paper
    elevation={3}
    sx={{
      padding: "20px",
      borderRadius: "12px",
      backgroundColor: "#fff",
    }}
  >
    {/* Título */}
    <Typography sx={{ fontSize: "20px", fontWeight: "bold", marginBottom: "16px" }}>
      Upcoming Board Meetings
    </Typography>

    {/* Lista de reuniones */}
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      {meetingsData.map((meeting, index) => (
        <Paper
          key={index}
          elevation={1}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "16px",
            borderRadius: "12px",
            border: "1px solid #e5e7eb",
            backgroundColor: "#f9fbff",
          }}
        >
          {/* Fecha */}
          <Box
            sx={{
              width: "60px",
              height: "60px",
              backgroundColor: "#dbeafe",
              borderRadius: "8px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "14px", color: "#2563eb", fontWeight: "bold" }}>
              Dec
            </Typography>
            <Typography sx={{ fontSize: "20px", fontWeight: "bold", color: "#2563eb" }}>
              {meeting.day}
            </Typography>
          </Box>

          {/* Detalles */}
          <Box sx={{ flex: 1, marginLeft: "16px" }}>
            <Typography sx={{ fontSize: "16px", fontWeight: "bold", marginBottom: "4px" }}>
              {meeting.title}
            </Typography>
            <Typography sx={{ fontSize: "14px", color: "#6b7280" }}>
              {meeting.date} • {meeting.time}
            </Typography>
          </Box>
        </Paper>
      ))}
    </Box>

    {/* Botón */}
    <Button
      variant="contained"
      sx={{
        marginTop: "20px",
        textTransform: "none",
        fontSize: "16px",
        padding: "10px 20px",
        borderRadius: "8px",
        background: "linear-gradient(90deg, #2563eb, #1e3a8a)",
      }}
    >
      Sync with calendar
    </Button>
  </Paper>
);

export default UpcomingBoardMeetings;
