import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, Typography } from '@mui/material';
import { AccountType } from '../../services/utils/types/index';
import { useAccount } from '../../contexts/Account';
import BoardMeetingPopUp from '../Post/BoardMeetingPopUp';
import ACButton from '../ACButton';
import CustomIcon from '../../icons/customIcon';

const useStyles = (isSM) => ({
  container: {
    backgroundColor: '#092341',
    height: '55px',
  },
  demo: {
    paddingLeft: isSM ? '0px' : '125px',
    height: '55px',
    paddingTop: '6px',
    backgroundColor: '#092341',
    paddingBottom: '6px',
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    position: 'fixed',
    alignItems: 'center',
    zIndex: 100,
  },
  backContainerContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '2rem',
    justifyContent: isSM ? 'center' : 'flex-start',
    alignItems: 'center',
  },
  consoleText: {
    fontWeight: 600,
    color: 'white',
    fontSize: '1rem',
    textDecoration: 'underline',
  },
  noSearchBar: {
    marginLeft: isSM ? '5px' : '30px',
    display: 'flex',
    flexGrow: 1,
  },
  impersonateDecoartion: {
    marginLeft: isSM ? '5px' : '15px',
    marginRight: isSM ? '5px' : '130px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  impersonateText: {
    color: 'white',
    fontWeight: 600,
    marginLeft: '10px',
    fontSize: '0.85rem',
  },
});

function UpperBar() {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const { type, accountDetails } = useAccount();
  const classes = useStyles(isSM);
  const history = useHistory();

  const [openBoardMeetingPost, setOpenBoardMeetingPost] = useState(false);

  function backToConsoleClick() {
    window.location.assign(process.env.REACT_APP_ADMIN_URL);
  }

  function boardMeetingButton() {
    return (
      <ACButton
        style={{
          backgroundColor: 'transparent',
          margin: '0px',
          padding: '0px',
          boxShadow: 'none',
        }}
        onClick={() => setOpenBoardMeetingPost(true)}
      >
        <Typography variant="old_font" sx={classes.consoleText}>
          {t('IMPERSONATE-UPPERBAR-CREATE-BOARDMEETING')}
        </Typography>
      </ACButton>
    );
  }

  function redirectToOnboarding() {
    const location = {
      pathname: '/company/onboarding/step1',
      state: { impersonateOverride: true },
    };
    return history.push(location);
  }

  return (
    <>
      <div style={classes.container}>
        <div style={classes.demo}>
          <div style={classes.backContainerContainer}>
            <ACButton
              style={{
                backgroundColor: 'transparent',
                margin: '0px',
                padding: '0px',
                boxShadow: 'none',
              }}
              onClick={() => backToConsoleClick()}
            >
              <Typography variant="old_font" sx={classes.consoleText}>
                {t('IMPERSONATE-UPPERBAR-BACK-TO-CONSOLE')}
              </Typography>
            </ACButton>
            {type === AccountType.COMPANY &&
              accountDetails.userData.isBoardOwner && (
                <ACButton
                  style={{
                    backgroundColor: 'transparent',
                    margin: '0px',
                    padding: '0px',
                    boxShadow: 'none',
                  }}
                  onClick={() => redirectToOnboarding()}
                >
                  <Typography variant="old_font" sx={classes.consoleText}>
                    {t('IMPERSONATE-UPPERBAR-REDIRECT-TO-ONBOARDING')}
                  </Typography>
                </ACButton>
              )}
            {boardMeetingButton()}
          </div>

          <div style={classes.noSearchBar} />

          <div style={classes.impersonateDecoartion}>
            <ACButton
              style={{
                backgroundColor: 'transparent',
                margin: '0px',
                padding: '0px',
                boxShadow: 'none',
              }}
              disabled
            >
              <CustomIcon
                iconname="show-outlined"
                style={{
                  display: 'flex',
                  width: 'fit-content',
                  height: 'fit-content',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'white',
                  fontSize: '1.5rem',
                }}
                size="small"
              />
              <Typography variant="old_font" sx={classes.impersonateText}>
                {t('IMPERSONATE-UPPERBAR-IMPERSONATE')}
              </Typography>
            </ACButton>
          </div>
        </div>
      </div>
      <BoardMeetingPopUp
        open={openBoardMeetingPost}
        closeAction={() => setOpenBoardMeetingPost(false)}
      />
    </>
  );
}

export default UpperBar;
