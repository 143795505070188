import React, { useEffect, useState } from 'react';
import {
  useMediaQuery,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
} from '@mui/material';
import { Clear } from '@mui/icons-material';
import ACSnackbar from './../../ACSnackbar';
import AddIcon from '@mui/icons-material/Add';
import CircularProgress from '@mui/material/CircularProgress';
import { mutationSendInviteContacts } from './../../../services/company_services';
import eventBus, { eventBusValues } from './../../../eventBus';

export default function InviteTeamMembersModal({
  boardOwner,
  onInviteSent,
  accountDetails,
}) {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const initialEmailsState = ['', '', ''];
  const [emails, setEmails] = useState(['']);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isInviteButtonDisabled, setIsInviteButtonDisabled] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [emailErrors, setEmailErrors] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const updateEmails = (index, value) => {
    const newEmails = [...emails];
    newEmails[index] = value;
    setEmails(newEmails);

    if (emailErrors[index]) {
      const newErrors = [...emailErrors];
      newErrors[index] = !validateEmail(value) && value !== '';
      setEmailErrors(newErrors);
    }

    setIsInviteButtonDisabled(
      !newEmails.some(validateEmail) || emailErrors.includes(true)
    );
  };

  const handleBlur = () => {
    const newErrors = emails.map(
      (email) => !validateEmail(email) && email !== ''
    );
    setEmailErrors(newErrors);
    setIsInviteButtonDisabled(
      !emails.some(validateEmail) || newErrors.includes(true)
    );
  };

  const handleAddMoreEmails = () => {
    setEmails([...emails, '']);
  };

  const handleInvite = async () => {
    const validEmails = emails.filter((email) => validateEmail(email));
    const baseUrl = `https://app.advisorycloud.com/join/team/${accountDetails.slug}/${accountDetails.id}`;
    const links = validEmails.map(
      (email) => `${baseUrl}?email=${encodeURIComponent(email)}`
    );

    setIsSending(true);
    setIsInviteButtonDisabled(true);
    try {
      await mutationSendInviteContacts({
        EMAILS: validEmails,
        BOARD_OWNER: boardOwner,
        COMPANY_ID: accountDetails.id,
        COMPANY_NAME: accountDetails.companyName,
        LINKS: links,
      });
      onInviteSent();
      handleClose();
    } catch (error) {
      console.log('error:', error);
    } finally {
      setIsSending(false);
    }
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    if (!openModal) {
      setEmails(initialEmailsState);
      eventBus.on(eventBusValues.triggerInviteTeamModal, () => {
        setOpenModal(true);
      });
    }
    return () => {
      eventBus.remove(eventBusValues.triggerInviteTeamModal);
    };
  }, [openModal]);

  return (
    <Dialog
      open={openModal}
      onClose={handleClose}
      PaperProps={{
        id: 'inviteTeamMembersModal',
        sx: {
          borderRadius: '10px',
          width: '100%',
          height: '100%',
          maxWidth: { xxs: '100%', sm: '656px' },
          maxHeight: { xxs: '100%', sm: '491px' },
          m: 'auto',
          padding: '10px',
        },
      }}
    >
      {isSM && (
        <div style={{ float: 'right', cursor: 'pointer' }}>
          <Clear onClick={handleClose} style={{ float: 'right' }} />
        </div>
      )}
      <ACSnackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        autoHideDuration={6000}
        text="Link copied, you can now share it with whoever you choose"
        severity="success"
        onClose={() => setSnackbarOpen(false)}
      />
      <DialogTitle
        sx={{
          color: '#232B35',
          textAlign: 'center',
          fontSize: '20px',
          fontWeight: 800,
          lineHeight: '24px',
          letterSpacing: '-0.43px',
          marginTop: '10px',
          marginBottom: '-5px',
        }}
      >
        Invite your team members
      </DialogTitle>
      <Typography
        sx={{
          color: '#646D7A',
          textAlign: 'center',
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '24px',
          letterSpacing: '-0.43px',
          marginBottom: '10px',
        }}
      >
        Invite team members to collaborate in your{' '}
        {accountDetails.userSegment === 'managed_advisory_board'
          ? 'advisory board'
          : 'AdvisoryCloud'}
      </Typography>
      <DialogContent
        sx={{
          paddingLeft: '30px',
          paddingRight: '30px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '0px',
          }}
        >
          <Typography
            sx={{
              color: '#646D7A',
              fontSize: '13px',
              fontWeight: 500,
              lineHeight: '24px',
              letterSpacing: '-0.43px',
            }}
          >
            Send invites
          </Typography>
        </Box>
        <Box sx={{ maxHeight: '180px', height: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              position: 'fixed',
              overflowY: 'hidden',
              paddingRight: '10px',
              maxHeight: '180px',
              height: '100%',
              '&::-webkit-scrollbar': {
                width: '8px',
                backgroundColor: '#F8F8F8',
              },
              '&::-webkit-scrollbar-thumb': {
                borderRadius: '5px',
                backgroundColor: '#C7C7C7',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#B7B7B7',
              },
              '&::-webkit-scrollbar-thumb:active': {
                backgroundColor: '#B7B7B7',
                boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
              },
              '&:hover': {
                overflowY: 'auto',
              },
            }}
          >
            {emails.map((email, index) => (
              <TextField
                error={emailErrors[index]}
                helperText={emailErrors[index] ? 'Invalid email' : ''}
                type="email"
                className="inviteTeamMembersEmailInput"
                sx={{
                  margin:
                    index === emails.length - 1 ? '0px' : '0px 0px 15px 0px',
                  width: { xxs: '80vw', sm: '557px' },
                  '& .MuiInputBase-root': {
                    height: '44px',
                    borderRadius: '10px',
                  },
                  '& .MuiInputBase-input': {
                    height: '100%',
                  },
                  '& .Mui-error': {
                    color: '#f44336',
                  },
                  input: {
                    '&:-webkit-autofill': {
                      transitionDelay: '9999s',
                      transitionProperty:
                        'background-color, color, height, width',
                    },
                  },
                }}
                key={index}
                value={email}
                onChange={(e) => updateEmails(index, e.target.value)}
                onBlur={handleBlur}
                placeholder="Email address"
                fullWidth
                margin="normal"
              />
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingRight: '20px',
          }}
        >
          <Button
            disableRipple
            sx={{
              color: '#646D7A',
              fontSize: '13px',
              fontWeight: 500,
              lineHeight: '24px',
              letterSpacing: '-0.43px',
              textTransform: 'none',
              marginTop: '0px',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
            onClick={handleAddMoreEmails}
            id="inviteTeamMembersAddMore"
          >
            <AddIcon
              sx={{ color: '#646D7A', fontSize: '18px', marginRight: '5px' }}
            />
            <Typography
              sx={{
                color: '#646D7A',
                fontSize: '13px',
                fontWeight: 500,
                lineHeight: '24px',
                letterSpacing: '-0.43px',
              }}
            >
              Add more
            </Typography>
          </Button>
          <Typography
            sx={{
              color: '#97A3B7',
              fontSize: '13px',
              fontWeight: 300,
              lineHeight: '24px',
              letterSpacing: '-0.43px',
            }}
          >
            {accountDetails.boardSeatsAllowed === -1
              ? 'Unlimited user seats'
              : `${
                  accountDetails.boardSeatsAllowed -
                  accountDetails.contacts.length
                } of ${accountDetails.boardSeatsAllowed} user seats left`}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px',
          }}
        >
          <button
            onClick={handleInvite}
            disabled={isInviteButtonDisabled}
            style={{
              marginBottom: '20px',
              position: 'relative',
              fontSize: '16px',
              borderRadius: '999px',
              fontWeight: 700,
              width: '133px',
              height: '44px',
              backgroundColor: isInviteButtonDisabled ? '#cccccc' : '#3171F6',
              color: '#FFFFFF',
              borderColor: 'none',
              fontFamily: 'Poppins',
              border: 'none',
              outline: 'none',
              cursor: isInviteButtonDisabled ? 'default' : 'pointer',
            }}
            variant="contained"
            id="inviteTeamMembersSend"
          >
            Invite
            {isSending && (
              <CircularProgress
                size={28}
                thickness={5}
                style={{
                  color: '#3171F6',
                  position: 'absolute',
                  left: '40%',
                  top: '20%',
                }}
              />
            )}
          </button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
