export function getTimeDifferenceInSeconds(initialTime) {
  const currentTime = new Date();
  const savedTime = new Date(initialTime);
  const diffTime = Math.abs(currentTime - savedTime) / 1000;
  return diffTime;
}

export function getLastLoginTimeLabel(seconds) {
  // TODO change to internationalization
  const MORE_OR_EQUAL_THAN_A_YEAR = 'y';
  const MORE_THAN_A_MONTH = 'mo';
  const MORE_THAN_A_WEEK = 'w';
  const MORE_THAN_A_DAY = 'd';
  const MORE_THAN_AN_AHOUR = 'h';
  const MORE_OR_LESS_THAN_A_MINUTE = 'm';
  let timeLabel = '';
  let highestTimeFound = false;
  // seconds in a year
  if (!highestTimeFound && seconds >= 31536000) {
    timeLabel = Math.trunc(seconds / 31536000) + MORE_OR_EQUAL_THAN_A_YEAR;
    highestTimeFound = true;
  }
  // seconds in a Month(4 weeks)
  if (!highestTimeFound && seconds >= 2419200) {
    timeLabel = Math.trunc(seconds / 2419200) + MORE_THAN_A_MONTH;
    highestTimeFound = true;
  }
  // seconds in a week
  if (!highestTimeFound && seconds >= 604800) {
    timeLabel = Math.trunc(seconds / 604800) + MORE_THAN_A_WEEK;
    highestTimeFound = true;
  }
  // seconds in a Day
  if (!highestTimeFound && seconds >= 86400) {
    timeLabel = Math.trunc(seconds / 86400) + MORE_THAN_A_DAY;
    highestTimeFound = true;
  }
  // seconds in an hour
  if (!highestTimeFound && seconds >= 3600) {
    timeLabel = Math.trunc(seconds / 3600) + MORE_THAN_AN_AHOUR;
    highestTimeFound = true;
  }
  // seconds in a minute
  if (!highestTimeFound && seconds >= 60) {
    timeLabel = Math.trunc(seconds / 60) + MORE_OR_LESS_THAN_A_MINUTE;
    highestTimeFound = true;
  }
  // less than a minute
  if (!highestTimeFound) {
    timeLabel = 1 + MORE_OR_LESS_THAN_A_MINUTE;
    highestTimeFound = true;
  }
  return timeLabel;
}
