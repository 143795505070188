import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RWebShare } from 'react-web-share';
import { Button, CircularProgress, useMediaQuery } from '@mui/material';
import { ChevronLeft, Save } from '@mui/icons-material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { ReactComponent as LinkedInIcon } from './linkedin.svg';
import { ReactComponent as ReplyIcon } from './reply.svg';
import AdvisoryCloudLogo from './../../../AdvisoryCloudLogo';
import LinkedInPostModal from './../../../LinkedInPostModal';
import { useAccount } from './../../../../contexts/Account';
import { isLinkedinAuthenticated, authorize } from './../../../../services/LinkedIn';

const useStyles = (isXS) => ({
  root: {
    marginTop: isXS ? '0px' : '-30px',
    marginRight: '15px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actionbuttons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'right',
    margin: 'theme.spacing(1)',
  },
  buttons: {
    width: isXS ? '100%' : 'inherit',
  },
  flatButton: {
    textTransform: 'none',
    color: '#232B35',
    fontFamily: 'Poppins',
  },
  linkButton: {
    textTransform: 'none',
    borderRadius: '10px',
    border: '1px solid #EFF0F7',
    marginLeft: '5px',
    height: '35px',
    color: '#232B35',
    fontFamily: 'Poppins',
  },
  backButton: {
    cursor: 'pointer',
    position: 'relative',
    fontSize: '36px',
  },
  logoButton: {
    marginLeft: '20px',
    cursor: 'pointer',
  },
});

export default function buttonGroupBarOwnProfile(props) {
  const { t } = useTranslation();
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isXS);
  const { accountDetails } = useAccount();
  const { publicProfileUrl } = accountDetails;
  const {
    isSaving,
    setIsSaving,
    publicProfileFlag,
    backAction,
    isEditing,
    setSuccessMessage,
    setSuccessSnackbar,
    setIsEditing,
  } = props;
  const publicProfile = publicProfileFlag
    ? `https://${process.env.REACT_APP_PUBLIC_PROFILES_URL}/${publicProfileUrl}`
    : `advisorycloud.com/profile/${publicProfileUrl}`;
  const [linkedInPostModal, setLinkedInPostModal] = useState(false);

  function copyToClipboard() {
    navigator.clipboard.writeText(publicProfile);
    setSuccessMessage(t('PROFILE-CLIPBOARD-MESSAGE'));
    setSuccessSnackbar(true);
  }

  async function linkedInResponse(linkedinResponse) {
    if (linkedinResponse === 201) {
      setSuccessMessage('Your profile is shared successfully.');
      setSuccessSnackbar(true);
    } else if (linkedinResponse === 422) {
      setSuccessMessage(
        'You have already posted this content, either update the post or try again in 10 minutes'
      );
      setSuccessSnackbar(true);
    } else {
      setSuccessMessage('An error occurred while sharing your profile.');
      setSuccessSnackbar(true);
    }
  }

  async function linkedInSign() {
    const isAuthenticatedLinkedIn = isLinkedinAuthenticated();
    if (isAuthenticatedLinkedIn) {
      setLinkedInPostModal(true);
    } else {
      await authorize();
    }
  }

  return (
    <div style={classes.root}>
      <div style={classes.logoButton}>
        {!isXS ? (
          <AdvisoryCloudLogo removeHyperlink onClick={backAction} width={200} />
        ) : null}
      </div>
      <div style={classes.buttons}>
        <div style={classes.actionbuttons} id="actionbuttonsContainer">
          {isSaving ? (
            <div style={classes.loadingContainer}>
              <CircularProgress />
            </div>
          ) : (
            <Button
              variant="text"
              id={isEditing ? 'saveButton' : 'editButton'}
              sx={classes.flatButton}
              startIcon={isEditing ? <Save /> : <EditOutlinedIcon />}
              onClick={() => {
                if (isEditing) {
                  setIsSaving(true);
                } else {
                  setIsEditing(true);
                }
              }}
            >
              {isEditing ? 'Save' : 'Edit'}
            </Button>
          )}
          {!isEditing ? (
            <>
              <Button
                variant="text"
                id="promoteButton"
                sx={classes.flatButton}
                startIcon={<LinkedInIcon />}
                onClick={linkedInSign}
              >
                Promote
              </Button>

              <RWebShare
                data={{
                  text: `${t('ADVISOR-LINKEDIN-POST-TEXT')}`,
                  url: publicProfile,
                  title: 'Share your advisor profile',
                }}
                onClick={() => {
                  setSuccessMessage('Your profile is shared successfully.');
                  setSuccessSnackbar(true);
                }}
              >
                <Button
                  variant="text"
                  id="shareButton"
                  sx={classes.flatButton}
                  startIcon={<ReplyIcon />}
                >
                  Share
                </Button>
              </RWebShare>
              {!isXS ? (
                <Button
                  variant="outlined"
                  sx={classes.linkButton}
                  onClick={copyToClipboard}
                >
                  {publicProfile}
                </Button>
              ) : null}
            </>
          ) : null}
          {isEditing ? (
            <ChevronLeft
              sx={classes.backButton}
              onClick={() => setIsEditing(false)}
              color="primary"
              fontSize="large"
            />
          ) : (
            <ChevronLeft
              sx={classes.backButton}
              onClick={backAction}
              color="primary"
              fontSize="large"
            />
          )}
        </div>
      </div>
      {linkedInPostModal ? (
        <>
          <LinkedInPostModal
            linkedInPostModal={linkedInPostModal}
            setLinkedInPostModal={setLinkedInPostModal}
            linkedInResponse={linkedInResponse}
            fontFamily={'Poppins'}
            shareProfile
          />
        </>
      ) : null}
    </div>
  );
}
