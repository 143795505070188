const publicContactRegisterMutation = `
mutation MyMutation(
  $companyId: String!,
  $companyName: String!,
  $companySlug: String!,
  $email: String!,
  $givenName: String!,
  $surName: String!
) {
  publicContactRegister(
    companyId: $companyId,
    companyName: $companyName,
    companySlug: $companySlug,
    email: $email,
    givenName: $givenName,
    surName: $surName
  ) {
    id
  }
}
`;

export async function publicContactRegister({
  companyId,
  companyName,
  companySlug,
  email,
  givenName,
  surName,
}) {
  const response = await fetch(process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT, {
    method: 'POST',
    headers: {
      'x-api-key': process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT_KEY,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: publicContactRegisterMutation,
      variables: {
        companyId,
        companyName,
        companySlug,
        email,
        givenName,
        surName,
      },
    }),
  });

  const data = await response.json();
  return data;
}
