import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography, Link, useMediaQuery } from '@mui/material';
import BoardMeetingActions from './BoardMeetingActions';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircle';
import { EditorState, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useAccount } from './../../../../contexts/Account';
import Carousel from './../../Carousel';
import CarouselCard from './../../Carousel/CarosuelCard';
import eventBus, { eventBusValues } from './../../../../eventBus';
import './textEditor.css';

const useStyles = () => ({
  mainContainer: {
    display: 'flex',
    width: '100%',
    height: 'fit-content',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  group: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'fit-content',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  bodyDashboard: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    display: 'inline-block',
    width: '100%',
    maxHeight: '107px',
    overflow: 'hidden',
    position: 'relative',
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
    hyphens: 'auto',
    color: '#232B35',
  },
  bodyIndividual: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    maxWidth: '100%',
    display: 'inline-block',
    height: 'fit-content',
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
    hyphens: 'auto',
    color: 'info.dark',
  },
  more: {
    display: 'flex',
    alignSelf: 'flex-end',
    height: 'fit-content',
    width: '100px',
    bottom: '0px',
    right: '0px',
    background:
      'linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 7%, rgba(255,255,255,1) 100%)',
  },
  text: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    color: '#3676F4',
  },
  preview: {
    width: '100%',
    height: '310px',
    borderColor: 'secondary.main',
    borderRadius: '5px',
    marginBottom: '10px',
    display: 'flex',
    overflowX: 'scroll',
    '&::-webkit-scrollbar': {
      height: '10px',
      WebkitAppearance: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '4px',
      backgroundColor: 'rgba(0,0,0,.5)',
      WebkitBoxShadow: '0 0 1px rgba(255,255,255,.5)',
    },
  },
  videoPreview: {
    position: 'relative',
    paddingBottom: '56.25%',
    height: '0px',
    width: '100%',
    overflow: 'hidden',
    marginTop: 10,
  },
  stickyVideoPreview: {
    position: 'fixed',
    top: '0px',
    left: '0px',
    zIndex: 2,
    paddingBottom: '56.25%',
    width: '100%',
    overflow: 'hidden',
  },
  videoFrame: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
  },
  grayOverlay: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    zIndex: 1,
    height: '100%',
    opacity: '0.7',
    backgroundColor: 'black',
  },
  playButton: {
    width: '300px',
    opacity: 1,
  },
  LockedVideoFrame: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
  },
  previewImage: {
    height: '100%',
    borderRadius: '5px',
    margin: '0px',
    paddin: '0px',
  },
  carouselContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '95px',
    width: '100%',
  },
  buttonBody: {
    borderRadius: '5px',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    '&:disabled': {
      cursor: 'default',
    },
    backgroundColor: 'transparent',
    width: '100%',
    margin: '0px',
    padding: '0px',
  },
});

function PostCardBody(props) {
  const {
    postData,
    body,
    video,
    dashboardFormat,
    attachments,
    bodyClick,
    bodyDisabled,
    primaryAttachment,
    linkAtTheEnd,
    isSingleBoardMeetingPost,
    boardMeetingLocked,
    timeLive,
  } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles();
  const [isOverflowing, setIsOverflowing] = useState();
  const [sticky, setSticky] = useState(false);
  const [selectedSrc, setSelectedSrc] = useState({ primaryAttachment });
  const [selectedPdf, setSelectedPdf] = useState(false);
  const [mouseOverLink, setMouseOverLink] = useState(false);
  const elementRef = useRef();
  const videoRef = useRef();
  const isBoardMeetingPost = postData.type === 'boardMeeting';

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    if (body) {
      try {
        const contentState = convertFromRaw(JSON.parse(body));
        setEditorState(EditorState.createWithContent(contentState));
      } catch (err) {
        setEditorState(EditorState.createEmpty());
      }
    }
  }, [body]);

  function isOverflowed(element) {
    return (
      element.scrollHeight > element.clientHeight ||
      element.scrollWidth > element.clientWidth
    );
  }

  function onScroll() {
    if (
      videoRef &&
      videoRef.current &&
      videoRef.current.getBoundingClientRect
    ) {
      setSticky(videoRef.current.getBoundingClientRect().top < -10);
    }
  }

  useEffect(() => {
    const resultsOverflow = isOverflowed(elementRef.current);
    setIsOverflowing(resultsOverflow);

    if (isSM && isBoardMeetingPost) {
      window.addEventListener('scroll', onScroll, { passive: true });
    }

    return () =>
      window.removeEventListener('scroll', onScroll, { passive: true });
  }, [isOverflowing]);

  function selectArrayElement(data) {
    if (data === -1) {
      setSelectedSrc('');
    } else {
      setSelectedPdf(attachments[data].url.includes('.pdf'));
      setSelectedSrc(attachments[data].url);
    }
  }

  function renderVideo() {
    if (video && video.length !== 0) {
      return (
        <div style={classes.videoPreview}>
          <iframe
            src={video}
            title="postVideo"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            style={classes.videoFrame}
          />
        </div>
      );
    }
    return null;
  }

  function renderVideoGate() {
    return (
      <div
        onClick={() =>
          eventBus.dispatch(eventBusValues.triggerCompanyDrawerAction, {
            companyId: postData.companyId,
          })
        }
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          justifyContent: 'center',
          textAlign: 'center',
          flexDirection: 'column',
        }}
      ></div>
    );
  }

  function renderVideoBoard() {
    return (
      <div
        style={
          isBoardMeetingPost && !dashboardFormat && sticky
            ? classes.stickyVideoPreview
            : classes.videoPreview
        }
      >
        {timeLive && !isSingleBoardMeetingPost && (
          <div style={classes.grayOverlay}>
            <PlayCircleOutlineIcon
              sx={classes.playButton}
              onClick={
                !boardMeetingLocked
                  ? bodyClick
                  : () =>
                    eventBus.dispatch(
                      eventBusValues.triggerCompanyDrawerAction,
                      {
                        companyId: postData.companyId,
                      }
                    )
              }
            // sx={{ fontSize: 205, color: '#FFFFFF', opacity: 1 }}
            />
          </div>
        )}
        <iframe
          src={getEmbebVideoUrl(postData.streamLink)}
          title="postVideo"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          style={
            boardMeetingLocked ? classes.LockedVideoFrame : classes.videoFrame
          }
        />
        {boardMeetingLocked ? renderVideoGate() : null}
      </div>
    );
  }

  function getEmbebVideoUrl(url) {
    if (!url) {
      return;
    }

    let regExp =
      /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    let match = url.match(regExp);
    if (match && match[1].length === 11) {
      return `https://www.youtube.com/embed/${match[1]}?autoplay=0`;
    }

    regExp =
      /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
    match = url.match(regExp);
    if (match && match[3] && match[3].length > 0) {
      return `https://player.vimeo.com/video/${match[3]}?autoplay=0`;
    }

    regExp =
      /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:event\/(?:\w+\/)?|event\/([^\/]*)\/event\/|event\/(\d+)\/event\/|event\/|)(\d+)(?:$|\/|\?)/;
    match = url.match(regExp);
    if (match && match[3] && match[3].length > 0) {
      return `https://vimeo.com/event/${match[3]}/embed?autoplay=0`;
    }

    return;
  }

  function renderBoardMettingInfo() {
    if (isBoardMeetingPost) {
      return (
        <BoardMeetingActions
          isSingleBoardMeetingPost={isSingleBoardMeetingPost}
          boardMeetingLocked={boardMeetingLocked}
          post={postData}
          stickyButton={sticky}
          automaticCalendarLink
          body={formatBody(body)}
        />
      );
    }
    return null;
  }

  function renderAttachments() {
    if (
      attachments === null ||
      attachments === undefined ||
      attachments.length === 0
    ) {
      return null;
    }
    if (attachments.length > 1 || attachments[0].url.includes('.pdf')) {
      return (
        <React.Fragment>
          {selectedPdf ? null : (
            <Box sx={classes.preview} style={{ paddingTop: '9px' }}>
              <img src={selectedSrc} alt="" style={classes.previewImage} />
            </Box>
          )}
          <section style={classes.carouselContainer}>
            <Carousel slideSelected={selectArrayElement}>
              {attachments.map((slide) => {
                return (
                  <CarouselCard
                    key={slide.url}
                    img={slide.url}
                    name={slide.name}
                  />
                );
              })}
            </Carousel>
          </section>
        </React.Fragment>
      );
    }

    if (attachments.length === 1 && dashboardFormat) {
      return (
        <button
          type="button"
          onClick={() => bodyClick()}
          disabled={bodyDisabled}
          style={{ ...classes.buttonBody, paddingTop: '9px' }}
        >
          <Box sx={classes.preview}>
            <img src={attachments[0].url} alt="" style={classes.previewImage} />
          </Box>
        </button>
      );
    }

    if (attachments.length === 1 && !dashboardFormat) {
      return (
        <Box sx={classes.preview}>
          <img src={attachments[0].url} alt="" style={classes.previewImage} />
        </Box>
      );
    }

    return null;
  }

  function formatBody(text) {
    if (editorState && editorState.getCurrentContent().hasText()) {
      return (
        <Editor
          editorState={editorState}
          toolbarHidden
          readOnly
          editorStyle={{
            marginTop: '-15px',
            color: '#232B35',
          }}
        />
      );
    }

    const textArray = text.split(' ');
    const elementsArray = [];
    let wordBucket = [];
    textArray.forEach((word, index) => {
      if (
        word.startsWith('https://') ||
        word.startsWith('http://') ||
        word.includes(`\n`)
      ) {
        elementsArray.push(
          <span key={`${index}s1`}>{wordBucket.join(' ')}</span>
        );
        elementsArray.push(<span key={`${index}s2`}> </span>);
        if (!word.includes(`\n`)) {
          elementsArray.push(
            <Link
              key={`${index}l1`}
              href={word}
              target="_blank"
              onMouseOver={() => setMouseOverLink(true)}
              onMouseLeave={() => setMouseOverLink(false)}
            >
              {word}
            </Link>
          );
          elementsArray.push(<span key={`${index}s3`}> </span>);
        } else {
          let separatedWord = word.split(`\n`);
          separatedWord.forEach((piece, brIndex) => {
            if (piece.startsWith('https://') || piece.startsWith('http://')) {
              elementsArray.push(
                <Link
                  key={`${brIndex}link${index}`}
                  href={piece}
                  target="_blank"
                  onMouseOver={() => setMouseOverLink(true)}
                  onMouseLeave={() => setMouseOverLink(false)}
                >
                  {piece}
                </Link>
              );
            } else if (piece !== '') {
              elementsArray.push(
                <span key={`${index}piece${brIndex}`}>{piece}</span>
              );
            }
            if (brIndex !== separatedWord.length - 1) {
              elementsArray.push(<br key={`${brIndex}br${index}`} />);
            } else {
              elementsArray.push(<span key={`${index}sp${brIndex}`}> </span>);
            }
          });
        }

        wordBucket = [];
      } else {
        wordBucket.push(word);
      }
    });

    elementsArray.push(<span key="finalItem">{wordBucket.join(' ')}</span>);
    if (linkAtTheEnd) {
      elementsArray.push(<span key={'extra03'}> </span>);
      elementsArray.push(
        <Link
          key={'extra04'}
          href={linkAtTheEnd.targetUrl}
          target={linkAtTheEnd.newTab ? '_blank' : '_self'}
          onMouseOver={() => setMouseOverLink(true)}
          onMouseLeave={() => setMouseOverLink(false)}
        >
          {linkAtTheEnd.text}
        </Link>
      );
    }
    return (
      <React.Fragment>{elementsArray.map((element) => element)}</React.Fragment>
    );
  }

  function renderBody() {
    if (dashboardFormat) {
      return (
        <div style={classes.mainContainer}>
          <button
            type="button"
            style={classes.buttonBody}
            onClick={() => (mouseOverLink ? () => { } : bodyClick())}
            disabled={bodyDisabled}
          >
            <div style={classes.group}>
              <Typography
                align="left"
                ref={elementRef}
                sx={classes.bodyDashboard}
              >
                {!isBoardMeetingPost ? formatBody(body) : null}
              </Typography>
              {isOverflowing ? (
                <Typography component="span" sx={classes.more}>
                  ...
                  <Typography component="span" sx={classes.text}>
                    &nbsp; Read more
                  </Typography>
                </Typography>
              ) : null}
            </div>
          </button>

          {(postData.meetingLink || postData.streamLink) ? (
            <>
              {renderVideo()}
              {isBoardMeetingPost ? renderVideoBoard() : null}
            </>
          ) : null}
          {renderAttachments()}
          {renderBoardMettingInfo()}
        </div>
      );
    }
    return (
      <div style={classes.mainContainer}>
        <div style={classes.group}>
          <Typography
            ref={elementRef}
            id="bodyParagraph"
            sx={classes.bodyIndividual}
            align="left"
          >
            {!isBoardMeetingPost ? formatBody(body) : null}
          </Typography>
        </div>
        {renderVideo()}
        {isBoardMeetingPost && isSM ? (
          <div ref={videoRef} style={{ height: 1, display: 'flex' }} />
        ) : null}
        {isBoardMeetingPost ? renderVideoBoard() : null}
        {isBoardMeetingPost && renderBoardMettingInfo()}
        {renderAttachments()}
      </div>
    );
  }

  return <React.Fragment>{renderBody()}</React.Fragment>;
}

export default PostCardBody;
