import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Box, Divider } from '@mui/material';

export default function DashboardSkeleton() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #E6EDFF',
        borderRadius: '10px',
        backgroundColor: '#FFFFFF',
        padding: '25px',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
          <Skeleton
            animation="wave"
            variant="circular"
            width={56}
            height={56}
            sx={{
              backgroundColor: '#F5F7FA',
              marginRight: '20px',
            }}
          />

          <Skeleton
            animation="wave"
            variant="rounded"
            height={34}
            sx={{
              backgroundColor: '#F5F7FA',
              borderRadius: '100px',
              width: { xxs: '108px', sm: '100%' },
              maxWidth: '201px',
            }}
          />
        </Box>

        <Skeleton
          animation="wave"
          variant="rounded"
          sx={{
            display: 'flex',
            width: { xxs: '108px', sm: '148px' },
            height: '48.38px',
            backgroundColor: '#D7E2FD',
            borderRadius: '10px',
            flexShrink: 0,
          }}
        />
      </Box>

      <Box>
        <Skeleton
          animation="wave"
          variant="rounded"
          height={21}
          sx={{
            backgroundColor: '#F5F7FA',
            marginBottom: '10px',
            borderRadius: '100px',
            width: '100%',
          }}
        />
        <Skeleton
          animation="wave"
          variant="rounded"
          height={21}
          sx={{
            backgroundColor: '#F5F7FA',
            marginBottom: '10px',
            borderRadius: '100px',
            width: '100%',
          }}
        />
        <Skeleton
          animation="wave"
          variant="rounded"
          height={21}
          sx={{
            backgroundColor: '#F5F7FA',
            marginBottom: '10px',
            borderRadius: '100px',
            width: '100%',
          }}
        />
        <Skeleton
          animation="wave"
          variant="rounded"
          height={21}
          sx={{
            backgroundColor: '#F5F7FA',
            marginBottom: '10px',
            borderRadius: '100px',
            width: '100%',
          }}
        />
        <Box sx={{ display: 'flex', marginTop: '30px', marginBottom: '10px' }}>
          <Skeleton
            animation="wave"
            variant="rounded"
            height={34}
            sx={{
              backgroundColor: '#F5F7FA',
              marginBottom: '10px',
              borderRadius: '100px',
              width: '100%',
              maxWidth: '162px',
              marginRight: '30px',
            }}
          />
          <Skeleton
            animation="wave"
            variant="rounded"
            height={34}
            sx={{
              backgroundColor: '#F5F7FA',
              marginBottom: '10px',
              borderRadius: '100px',
              width: '100%',
              maxWidth: '162px',
            }}
          />
        </Box>
      </Box>
      <Divider />
      <Skeleton
        animation="wave"
        variant="rectangular"
        height={106}
        sx={{
          backgroundColor: '#F0F4F8',
          marginBottom: '10px',
          borderRadius: '10px',
          width: '100%',
          marginTop: '30px',
        }}
      />

      <Box sx={{ display: 'flex', marginTop: '20px' }}>
        <Skeleton
          animation="wave"
          variant="rounded"
          height={34}
          sx={{
            backgroundColor: '#F5F7FA',
            marginBottom: '10px',
            marginRight: '30px',
            borderRadius: '100px',
            width: '100%',
            maxWidth: '162px',
          }}
        />
        <Skeleton
          animation="wave"
          variant="rounded"
          height={34}
          sx={{
            backgroundColor: '#F5F7FA',
            marginBottom: '30px',
            borderRadius: '100px',
            width: '100%',
            maxWidth: '162px',
          }}
        />
      </Box>
      <Skeleton
        animation="wave"
        variant="rectangular"
        height={65}
        sx={{
          backgroundColor: '#F0F4F8',

          marginBottom: '30px',
          borderRadius: '10px',
          width: '(100% - 30px)',
          marginLeft: '30px',
        }}
      />
      <Skeleton
        animation="wave"
        variant="rounded"
        height={34}
        sx={{
          backgroundColor: '#F5F7FA',
          marginBottom: '10px',
          borderRadius: '100px',
          width: '100%',
          maxWidth: '162px',
          marginLeft: '30px',
        }}
      />
    </Box>
  );
}
