export const LoginResponseTypes = {
  USER_MIGRATED: 'USER_MIGRATED',
  ERROR_IN_COGNITO_RESPONSE: 'ERROR_IN_COGNITO_RESPONSE',
  COGNITO_UNKNOW_ERROR: 'COGNITO_UNKNOW_ERROR',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  UKNOWN_ERROR: 'UKNOWN_ERROR',
  UKNOWN_ERROR_SUCCESS: 'UKNOWN_ERROR_SUCCESS',
  AUTHENTICATION_ERROR: 'AUTHENTICATION_ERROR',
  ADMIN: 'USER_ADMIN',
};

export const LoginResponses = {
  INCORRECT_USERNAME_PASSWORD_CODE: 'Incorrect username or password.',
  USER_NOT_FOUND_CODE: 'User does not exist.',
  PASSWORD_ATTEMPTS_EXCEEDED: 'Password attempts exceeded',
  RESOURCE_NOT_FOUND_CODE: 'ResourceNotFoundException',
  FIRST_TIME_LOGIN_MESSAGE:
    'PreAuthentication failed with error FIRST TIME LOGIN.',
  PASSWORD_RESET_REQUIRED_EXCEPTION: 'Password reset required for the user',
  NOT_AUTHORIZED_EXCEPTION: 'User is disabled.',
};

export const ConfirmEmailResponses = {
  INVALID_PARAMETER: 'InvalidParameterException',
  EXPIRED_CODE: 'ExpiredCodeException',
  LIMIT_EXCEEDED: 'LimitExceededException',
  TOO_MANY_REQUESTS: 'TooManyRequestsException',
  NOT_AUTHORIZED_EXCEPTION: 'NotAuthorizedException',
  USER_NOT_FOUND: 'UserNotFoundException',
  SUCCESS: 'SUCCESS',
  CODE_MISMATCH_EXCEPTION: 'CodeMismatchException',
  EXPIRED_CODE_EXCEPTION: 'ExpiredCodeException',
  THROTTLING_EXCEPTION: 'ThrottlingException',
  LAMBDA_UNHANDLE: 'Lambda:Unhandled',
};

export const GetAccountContextResponses = {
  CANT_GET_CURRENT_USER_INFO: 'CANT_GET_CURRENT_USER_INFO',
  UKNOWN_ERROR: 'UKNOWN_ERROR',
  ACCOUNT_DETAILS_ERROR: 'ACCOUNT_DETAILS_ERROR',
  SUCCESS: 'SUCCESS',
};

export const hasUrlParams = {
  PARAMS: 'WITH_PARAMS',
  NO_PARAMS: 'NO_PARAMS',
};

export const FilePondInitialResponse = {
  HAS_FILE: 'HAS_FILE',
  NO_FILE: 'NO_FILE',
};

export const ForgotPasswordErrorResponses = {
  INVALID_PARAMETER: 'InvalidParameterException',
  EXPIRED_CODE: 'ExpiredCodeException',
  LIMIT_EXCEEDED: 'LimitExceededException',
  TOO_MANY_REQUESTS: 'TooManyRequestsException',
  NOT_AUTHORIZED_EXCEPTION: 'NotAuthorizedException',
  USER_NOT_FOUND: 'UserNotFoundException',
};

export const ForgotPasswordResponses = {
  SUCCESS: 'SUCCESS',
  CODE_MISMATCH_EXCEPTION: 'CodeMismatchException',
  EXPIRED_CODE_EXCEPTION: 'ExpiredCodeException',
};
