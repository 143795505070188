import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAccount } from '../../../contexts/Account';
import { getCompanyInfoBoardroomMenu } from './../../../services/company_services';
import { respondBoardInvitation } from './../../../services/advisor_services';
import ACSnackbar from './../../ACSnackbar';

function RespondCompanyInvitationByUrl() {
  const location = useLocation();
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const [snackBarMessages, setSnackBarMessages] = useState('');
  const [loading, setLoading] = useState(true);
  const { accountDetails, editAccountInformation, isFreeTrial } = useAccount();

  // Define acceptInvitation and declineInvitation here
  const acceptInvitation = (company) => {
    respondBoardInvitation({
      ADVISOR_ID: accountDetails.id,
      COMPANY_ID: company.id,
      STATUS: 'accepted',
      IS_FREE_TRIAL: isFreeTrial,
    })
      .then(() => {
        const now = new Date().getTime();
        accountDetails.boards.memberBoards.push({
          createdAt: now,
          id: company.id,
          companyLogo: company.image ? `${company.image.location}` : '',
          companyName: company.displayName,
          slug: company.slug,
        });
        const index = accountDetails.boards.invitedBoards.findIndex(
          (bc) => bc.id === company.id
        );
        if (index > -1) {
          accountDetails.boards.invitedBoards.splice(index, 1);
        }
        setSnackBarMessages(
          `You have joined ${company.companyName}'s advisory board!`
        );
        setIsSnackbarVisible(true);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        localStorage.removeItem('processingInvite' + company.id);
      });
  };

  const declineInvitation = (company) => {
    respondBoardInvitation({
      ADVISOR_ID: accountDetails.id,
      COMPANY_ID: company.id,
      STATUS: 'declined',
      IS_FREE_TRIAL: isFreeTrial,
    })
      .then((res) => {
        const index = accountDetails.boards.invitedBoards.findIndex(
          (bc) => bc.id === company.id
        );
        if (index > -1) {
          accountDetails.boards.invitedBoards.splice(index, 1);
          //   editAccountInformation(accountDetails);
        }
        setSnackBarMessages(
          `${company.companyName} invitation has been declined and removed from your dashboard`
        );
        setIsSnackbarVisible(true);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (!loading && !isSnackbarVisible) {
      editAccountInformation(accountDetails);
    }
  }, [isSnackbarVisible]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get('invite') && queryParams.get('invite_action')) {
      const companySample = accountDetails.boards.invitedBoards.find(
        (sample) => sample.slug === queryParams.get('invite')
      );
      if (companySample) {
        const processingInvite = localStorage.getItem(
          'processingInvite' + companySample.id
        );
        if (processingInvite) {
          setSnackBarMessages(
            `Invite already being processed. Please wait for the process to complete.`
          );
          setIsSnackbarVisible(true);
          return;
        }

        localStorage.setItem('processingInvite' + companySample.id, 'true');
        if (queryParams.get('invite_action') === 'accept') {
          getCompanyInfoBoardroomMenu({ companyId: companySample.id })
            .then(({ data }) => {
              acceptInvitation(data.getCompany);
            })
            .catch((error) => {
              console.error(error);
            });
        } else if (queryParams.get('invite_action') === 'decline') {
          declineInvitation(companySample);
        }
      }
    }
  }, []);

  return (
    <ACSnackbar
      text={snackBarMessages}
      onClose={() => setIsSnackbarVisible(false)}
      open={isSnackbarVisible}
      severity="success"
    />
  );
}

export default RespondCompanyInvitationByUrl;
