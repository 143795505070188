import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Paper, Typography, useMediaQuery } from '@mui/material';
import { useAccount } from '../../contexts/Account';
import PublicProfileCard from '../../components/PublicProfileCard';
import Experience from '../../components/Experience';
import LoginModal from '../../components/LoginModal';
import Tag from '../../components/Tag';
import BoardPosition from '../../components/Advisor/Profile/components/boardPosition';
import ButtonGroupBar from '../../components/Advisor/Profile/components/buttonGroupBar';

const useStyles = ({ isSM, isSeeMoreSelected }) => ({
  card: {
    width: '100%',
    marginBottom: '20px',
    borderRadius: '25px',
    boxShadow: '0px 8px 30px rgba(54, 118, 244, 0.1)',
    maxWidth: '1200px',
    marginTop: isSM ? '30px' : '60px',
    marginLeft: isSM ? '10px' : '5%',
    marginRight: isSM ? '10px' : '5%',
  },
  advisorPurpleDot: {
    fontWeight: 'bold',
    width: '10px',
    height: '10px',
    marginRight: '10px',
    borderColor: '#0F78FD',
    borderRadius: '10px',
    background: '#6736FA',
  },
  companyInformationSectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '20px',
    width: '100%',
    paddingBottom: '40px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  mainButton: {
    fontWeight: 700,
    width: isSM ? '90%' : '14vw',
    minWidth: '150px',
    height: isSM ? '50px' : '35px',
    textTransform: 'none',
    fontSize: isSM ? '18px' : '12px',
    marginLeft: '10px',
    borderColor: '#6736FA',
    borderRadius: '4px',
    background: '#6736FA',
    color: '#FFF',
    '&:hover': {
      background: '#6736FA',
    },
    whiteSpace: 'nowrap',
  },
  mainInformationContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: isSM ? '0 20px 0 20px' : '0 60px 0 60px',
  },
  leftContainer: {
    width: '65%',
    padding: isSM ? '0 0 0 20' : '0 0 0 60px',
  },
  rightContainer: {
    width: '25%',
    padding: isSM ? '0 20px 0 0' : '0 60px 0 0',
  },
  tagsTitle: {
    fontWeight: 600,
    marginTop: '30px',
    fontSize: '20px',
    marginBottom: '15px',
  },
  advisorButtonText: {
    fontSize: '15px',
    fontWeight: 400,
  },
  experienceTitle: {
    fontWeight: 600,
    fontSize: '20px',
    marginTop: '30px',
    marginBottom: '-15px',
  },
  tagsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    width: '100%',
    marginTop: isSM ? '20px' : -25,
    maxWidth: '100%',
  },
  commonSpacing: {
    marginRight: '8px',
    marginBottom: '5px',
  },
  industryAndInterestsTagsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  boardsContainer: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  boards: {
    maxHeight: isSeeMoreSelected ? '143px' : '',
    width: '127%',
    overflowY: isSeeMoreSelected ? 'scroll' : 'hidden',
  },
  advisorButtonContainer: {
    height: '90px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: '30px',
    backgroundColor: '#F5F7FA',
    marginBottom: '20px',
  },
  seeMoreTitle: {
    color: 'primary.main',
    cursor: 'pointer',
  },
  biographyText: {
    fontWeight: 400,
    whiteSpace: 'pre-wrap',
    fontSize: '100%',
    fontSize: '16px',
    color: '#232B35',
  },
  bodyContainer: {
    display: isSM ? '' : 'flex',
    justifyContent: 'space-between',
    margin: isSM ? '0 20px' : '',
    maxWidth: isSM ? '90%' : '',
  },
  rightSideTitle: {
    marginRight: '8px',
    marginBottom: '20px',
    fontWeight: 'bold',
    marginTop: '30px',
    fontSize: '20px',
  },
});

function AdvisorPublicProfile(props) {
  let { advisor } = props;
  const { t } = useTranslation();
  const { tags } = useAccount();
  let industries = [...tags.industries];
  const [loginModal, setLoginModal] = useState(false);
  const [isSeeMoreSelected, setIsSeeMoreSelected] = useState(false);
  const [totalBoardsCount, setTotalBoardsCount] = useState(3);
  const [displaySkills, setDisplaySkills] = useState([]);
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles({ isSM, isSeeMoreSelected });

  function boardsArray(boards) {
    let filteredArray = boards.slice(0, totalBoardsCount);
    return filteredArray.map((company) => (
      <BoardPosition
        publicProfile
        key={company.id}
        companyId={company.id}
        advisorId={advisor.id}
      />
    ));
  }

  function seeMoreActions() {
    setIsSeeMoreSelected(true);
    setTotalBoardsCount(totalBoardsCount + 5);
  }

  const advisorIndustries = advisor.industryTagIds
    ? advisor.industryTagIds
    : [];
  industries = industries
    ? industries.filter((industry) => advisorIndustries.includes(industry.id))
    : [];

  function sortExperience(a, b) {
    if (a.endDate === 'Present' && b.endDate !== 'Present') return -1;
    if (a.endDate !== 'Present' && b.endDate === 'Present') return 1;
    if (Number(a.startDate) < Number(b.startDate)) return 1;
    if (Number(a.startDate) > Number(b.startDate)) return -1;
    return 0;
  }

  const experienceSorted = advisor.experiences
    ? advisor.experiences.sort(sortExperience)
    : [];

  useEffect(() => {
    let skills = [];
    if (advisor.skillTagIds) {
      skills = [...skills, ...advisor.skillTagIds];
    }
    if (advisor.favoriteSkillsIds) {
      advisor.favoriteSkillsIds.forEach((favSkill) => {
        const fromIndex = skills.indexOf(favSkill);
        const element = skills.splice(fromIndex, 1)[0];
        skills = [element, ...skills];
      });
    }
    skills = skills.slice(0, 5);
    skills = skills.map((skillId) =>
      tags.skills.find((skill) => skillId === skill.id)
    );

    setDisplaySkills(skills);
  }, []);

  return (
    <>
      {!isSM ? (
        <Paper sx={classes.card}>
          <div style={classes.companyInformationSectionContainer}>
            <ButtonGroupBar
              isPublicProfile
              isPublicProfileView
              advisor={advisor}
            />
            <div style={classes.mainInformationContainer}>
              <PublicProfileCard
                advisor={advisor}
                setLoginModal={setLoginModal}
              />
              <div style={classes.tagsContainer}>
                {displaySkills.map((skill, index) => (
                  <Box sx={classes.commonSpacing} key={index}>
                    <Tag label={skill.label} publicProfile />
                  </Box>
                ))}
              </div>
            </div>
            <div style={classes.bodyContainer}>
              <div style={classes.leftContainer}>
                <Typography variant="body1" sx={classes.tagsTitle}>
                  {t('PUBLIC-ADVISOR-BIOGRAPHY-LABEL')}
                </Typography>

                <Typography variant="body1" sx={classes.biographyText}>
                  {advisor.biography}
                </Typography>

                <Typography variant="body1" sx={classes.experienceTitle}>
                  {t('PUBLIC-ADVISOR-EXPERIENCE-LABEL')}
                </Typography>

                {experienceSorted
                  ? experienceSorted.map((experience, index) => (
                      <Experience
                        content={experience}
                        publicProfile
                        key={index}
                      />
                    ))
                  : null}
              </div>
              <div style={classes.rightContainer}>
                <div style={classes.boardsContainer}>
                  <Typography variant="body1" sx={classes.rightSideTitle}>
                    {t('PUBLIC-ADVISOR-BOARD-POSITIONS-LABEL')}
                  </Typography>
                  <div style={classes.boards}>
                    {advisor.boards.memberBoards &&
                    advisor.boards.memberBoards.length ? (
                      <>{boardsArray(advisor.boards.memberBoards)}</>
                    ) : null}
                  </div>
                </div>
                {advisor.boards.memberBoards.length > totalBoardsCount && (
                  <Typography
                    onClick={() => seeMoreActions()}
                    sx={classes.seeMoreTitle}
                  >
                    {t('PUBLIC-SEE-MORE-BOARDS-LABEL')}
                  </Typography>
                )}
                {advisor.interests && advisor.interests.length > 0 ? (
                  <div style={classes.boardsContainer}>
                    <Typography variant="body1" sx={classes.rightSideTitle}>
                      {t('ADVISOR-ACCOUNT-INTEREST-LABEL')}
                    </Typography>

                    <div style={classes.industryAndInterestsTagsContainer}>
                      {advisor.interests.map((interest, index) => (
                        <Box sx={classes.commonSpacing} key={index}>
                          <Tag label={interest} publicProfile />
                        </Box>
                      ))}
                    </div>
                  </div>
                ) : null}

                <div style={classes.boardsContainer}>
                  <Typography variant="body1" sx={classes.rightSideTitle}>
                    {t('PUBLIC-ADVISOR-INDUSTRY-LABEL')}
                  </Typography>

                  <div style={classes.industryAndInterestsTagsContainer}>
                    {industries.map((industry, index) => (
                      <Box sx={classes.commonSpacing} key={index}>
                        <Tag label={industry.label} publicProfile />
                      </Box>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <>
              <LoginModal
                loginModal={loginModal}
                setLoginModal={setLoginModal}
              />
            </>
          </div>
        </Paper>
      ) : (
        <Paper sx={classes.card} elevation={3}>
          <div style={classes.companyInformationSectionContainer}>
            <div style={classes.mainInformationContainer}>
              <PublicProfileCard
                advisor={advisor}
                setLoginModal={setLoginModal}
              />
            </div>
            <div style={classes.tagsContainer}>
              {displaySkills.map((skill, index) => (
                <Box sx={classes.commonSpacing} key={index}>
                  <Tag label={skill.label} publicProfile />
                </Box>
              ))}
            </div>
            <div style={classes.bodyContainer}>
              <Typography variant="body1" sx={classes.tagsTitle}>
                {t('PUBLIC-ADVISOR-BIOGRAPHY-LABEL')}
              </Typography>

              <Typography variant="body1" sx={classes.biographyText}>
                {advisor.biography}
              </Typography>
              <div style={classes.boardsContainer}>
                <Typography variant="body1" sx={classes.rightSideTitle}>
                  {t('PUBLIC-ADVISOR-BOARD-POSITIONS-LABEL')}
                </Typography>
                <div style={classes.boards}>
                  {advisor.boards.memberBoards &&
                  advisor.boards.memberBoards.length ? (
                    <>{boardsArray(advisor.boards.memberBoards)}</>
                  ) : null}
                </div>
              </div>
              {advisor.boards.memberBoards.length > totalBoardsCount && (
                <Typography
                  onClick={() => setTotalBoardsCount(totalBoardsCount + 5)}
                  sx={classes.seeMoreTitle}
                >
                  {t('PUBLIC-SEE-MORE-BOARDS-LABEL')}
                </Typography>
              )}

              <Typography variant="body1" sx={classes.experienceTitle}>
                {t('PUBLIC-ADVISOR-EXPERIENCE-LABEL')}
              </Typography>

              {experienceSorted
                ? experienceSorted.map((experience, index) => (
                    <Experience
                      content={experience}
                      publicProfile
                      key={index}
                    />
                  ))
                : null}
            </div>
            <>
              <LoginModal
                loginModal={loginModal}
                setLoginModal={setLoginModal}
              />
            </>
          </div>
        </Paper>
      )}
    </>
  );
}

export default AdvisorPublicProfile;
