import React from 'react';
import {
  Autocomplete,
  InputLabel,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';

const useStyles = () => ({
  inputLabel: {
    marginBottom: '10px',
    color: 'black',
  },
  inputContainer: {
    width: '100%',
  },
  helperText: {
    color: 'grey.600',
  },
  responsivePosition: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

function Input({
  parentStyle,
  helperText,
  id,
  label,
  options,
  placeholder,
  FormHelperTextProps,
  multiple = true,
  ...rest
}) {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles();

  return (
    <div style={{ ...classes.inputContainer, ...(parentStyle || {}) }}>
      {helperText ? (
        <>
          {isSM ? (
            <InputLabel sx={classes.inputLabel} htmlFor={id}>
              <Typography variant="old_font">{label}</Typography>
              <Typography
                variant="caption_old_font"
                sx={FormHelperTextProps || classes.helperText}
              >
                {helperText}
              </Typography>
            </InputLabel>
          ) : (
            <InputLabel sx={classes.inputLabel} htmlFor={id}>
              <div style={classes.responsivePosition}>
                <Typography variant="old_font">{label}</Typography>
                <Typography
                  variant="caption_old_font"
                  sx={FormHelperTextProps || classes.helperText}
                >
                  {helperText}
                </Typography>
              </div>
            </InputLabel>
          )}
        </>
      ) : null}

      <Autocomplete
        {...rest}
        id={id}
        multiple={multiple}
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={classes.inputContainer}
            inputProps={{
              ...params.inputProps,
              'data-testid': rest['input-data-testid'],
              'data-cy': rest['input-data-cy'],
            }}
            placeholder={placeholder}
            variant="outlined"
          />
        )}
      />
    </div>
  );
}

export default Input;
