import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  useMediaQuery,
  Dialog,
  DialogContent,
  Typography,
} from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';
import ACSnackbar from './../../ACSnackbar';
import LikesPopUp from './../LikesPopUp';
import DefaultCompany from './../../../icons/building_round.svg';
import { useAccount } from './../../../contexts/Account';
import {
  companyFetchResponsesCall,
  addReplyCall,
  getPostRepliesCall,
} from './../../../services/company_services';
import { advisorAddResponseCall } from './../../../services/advisor_services';
import { AccountType, PostType } from './../../../services/utils/types';
import PostAndAnswer from './../Answer';

const useStyles = (isSM) => ({
  backContainer: {
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    cursor: 'pointer',
    paddingLeft: isSM ? '20px' : '0px',
  },
  iconStyle: {
    fontSize: '15px',
    display: 'flex',
    color: '#3171F6',
  },
  backText: {
    display: 'flex',
    color: '#232B35',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
  },
});

function SinglePost({
  postData,
  backClick,
  replyLoading,
  doNotShowBackClick,
  singlePostLike,
  postLikesFilled,
}) {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const { t } = useTranslation();
  const history = useHistory();
  const {
    type,
    asLoggedUserDetails,
    accountDetails,
    currentUserInfo,
    isFreemium,
  } = useAccount();
  const [postingResponse, setPostingResponse] = useState(false);
  const [postingReply, setPostingReply] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [openLikesPopUp, setOpenLikesPopUp] = useState(false);
  const [newSingleReply, setNewSingleReply] = useState();
  const [newSingleReplyStatus, setNewSingleReplyStatus] =
    useState('NO_REPLY_CALL');
  const [newSingleResponse, setNewSingleResponse] = useState();
  const [newSingleResponseStatus, setNewSingleResponseStatus] =
    useState('NO_RESPONSE_CALL');
  const windowUrl = window.location.search;
  const queryParams = new URLSearchParams(windowUrl);
  const answerId = queryParams.get('answerId');

  let contact = { id: undefined };

  if (type === AccountType.COMPANY) {
    contact = accountDetails.contacts.find(
      (c) => c.id === asLoggedUserDetails.userId
    );
  }

  function attachmentsArray(data) {
    const results = data.map((element) => {
      const finalUrl = element.thumbnailUrl || element.url;
      return {
        url:
          finalUrl && finalUrl.includes('.pdf')
            ? finalUrl
            : `https://${finalUrl}`,
        name: element.filename,
      };
    });
    return results;
  }
  function renderPrimaryAttachment(data) {
    if (data !== null) {
      const finalUrl = data.thumbnailUrl || data.url;
      return `https://${finalUrl}`;
    }
    return null;
  }

  function isMounted(id) {
    return companyFetchResponsesCall({
      ACCOUNT_TYPE:
        type === 'company' ? AccountType.CONTACT : AccountType.ADVISOR,
      LIMIT: 50,
      OFFSET: 0,
      ONLY_BREAKTHROUGHS: false,
      ONLY_OWN_RESPONSES: false,
      POST_ID: id,
      USER_ID: type === AccountType.COMPANY ? contact.id : accountDetails.id,
    });
  }

  function submitResponse({ P_ID, RESPONSE_BODY }) {
    setPostingResponse(true);
    advisorAddResponseCall({
      ACCOUNT_TYPE:
        type === AccountType.COMPANY
          ? AccountType.CONTACT
          : AccountType.ADVISOR,
      BODY: RESPONSE_BODY,
      POST_ID: P_ID,
      USER_ID: type === AccountType.COMPANY ? contact.id : accountDetails.id,
    })
      .then((response) => {
        setNewSingleResponse(response.data.addResponse);
        setNewSingleResponseStatus('SUCCESS');
        setPostingResponse(false);
      })
      .catch(() => {
        setNewSingleResponseStatus('NO_RESPONSE_CALL');
        setPostingResponse(false);
        setShowErrorSnackbar(true);
      })
      .finally(() => {
        setNewSingleResponse();
        setNewSingleResponseStatus('NO_RESPONSE_CALL');
      });
  }

  function getPostReplies(id) {
    return getPostRepliesCall({
      ACCOUNT_TYPE:
        type === 'company' ? AccountType.CONTACT : AccountType.ADVISOR,
      LIMIT: 50,
      OFFSET: 0,
      ONLY_BREAKTHROUGHS: false,
      POST_ID: id,
      USER_ID: type === AccountType.COMPANY ? contact.id : accountDetails.id,
    });
  }

  function submitReply({ P_ID, REPLY_BODY, RESP_ID }) {
    setPostingReply(true);
    replyLoading(true);
    addReplyCall({
      ACCOUNT_TYPE:
        type === 'company' ? AccountType.CONTACT : AccountType.ADVISOR,
      BODY: REPLY_BODY,
      POST_ID: P_ID,
      RESPONSE_ID: RESP_ID,
      USER_ID: type === AccountType.COMPANY ? contact.id : accountDetails.id,
    })
      .then((response) => {
        setNewSingleReply(response.data.addReply);
        setNewSingleReplyStatus('SUCCESS');
        setPostingReply(false);
        replyLoading(false);
      })
      .catch((error) => {
        console.log('Add reply error');
        console.log(error);
        setNewSingleReplyStatus('NO_REPLY_CALL');
        setPostingReply(false);
        replyLoading(false);
        setShowErrorSnackbar(true);
      })
      .finally(() => {
        setPostingReply(false);
        replyLoading(false);
        setNewSingleReplyStatus('NO_REPLY_CALL');
      });
  }

  function editClickAction(postInfo) {
    history.push(`/${postInfo.type}/${postInfo.id}/edit`, {
      editingPost: postInfo,
      comingFrom: window.location.pathname,
    });
  }

  return (
    <>
      <Helmet>
        <title>{t('DASHBOARD-HELMET-TITLE')}</title>
      </Helmet>
      <Dialog
        open={openLikesPopUp}
        onClose={() => {
          setOpenLikesPopUp(false);
        }}
        aria-labelledby="likes-popUp-dialog"
        maxWidth="sm"
      >
        <DialogContent>
          <LikesPopUp />
        </DialogContent>
      </Dialog>
      <ACSnackbar
        open={showErrorSnackbar}
        text="ERROR"
        severity="error"
        onClose={() => setShowErrorSnackbar(false)}
        style={{ marginTop: isFreemium ? '50px' : '0px' }}
      />
      {!doNotShowBackClick && (
        <div
          style={classes.backContainer}
          onClick={() => {
            backClick();
          }}
        >
          <ArrowBackIos sx={classes.iconStyle} />
          <Typography sx={classes.backText}>{t('POST-BACK-BUTTON')}</Typography>
        </div>
      )}
      <div
        style={{
          marginRight: postData.type === 'boardMeeting' || isSM ? '0px' : '25%',
        }}
      >
        <PostAndAnswer
          isSingleBoardMeetingPost={postData.type === 'boardMeeting'}
          postData={postData}
          postBody={postData.body}
          attachments={
            postData.attachments !== null
              ? attachmentsArray(postData.attachments)
              : null
          }
          primaryAttachment={renderPrimaryAttachment(
            postData.primaryAttachment
          )}
          // likes
          postLikesIcon={postData.likesCount}
          postLikesIconClick={singlePostLike}
          postLikesFilled={postLikesFilled}
          // postLikesIconDisabled={postData.type === PostType.UPDATE}
          postLikesIconDisabled={false}
          // answers
          postAnswersHighlighted={answerId}
          postAnswersIcon={
            type === AccountType.ADVISOR || postData.type === PostType.UPDATE
              ? null
              : postData.responsesCount
          }
          postAnswersIconDisabled
          // comments
          postCommentsIcon={
            (postData.type === PostType.QUESTION &&
              type === AccountType.COMPANY) ||
            !postData.acceptResponses
              ? null
              : postData.responsesCount
          }
          typePost={postData.type}
          // avatars
          postCreatedAt={postData.createdAt}
          postCompanyName={
            postData.postCompanyContact
              ? postData.postCompanyContact.companyName
              : AccountType.COMPANY
          }
          postCompanyAvatar={
            postData.postCompanyContact !== null
              ? `${postData.postCompanyContact.imageURL}`
              : DefaultCompany
          }
          contributorAvatars={postData.contributors}
          contributorsDisabled
          // Get Answers Info
          id={postData.id}
          companyId={postData.companyId}
          isLoaded={isMounted}
          // FormatVersion
          isSinglePost
          // RESPONSE REPLY
          removeResponse={!postData.acceptResponses}
          submitResponse={(data) => submitResponse(data)}
          submitReply={(data) => submitReply(data)}
          replyLoading={postingReply}
          responseLoading={postingResponse}
          // Get Replies
          getPostReplies={getPostReplies}
          newSingleResponseStatus={newSingleResponseStatus}
          newSingleResponse={newSingleResponse}
          newSingleReply={newSingleReply}
          newSingleReplyStatus={newSingleReplyStatus}
          // Edit
          editClick={() => editClickAction(postData)}
        />
      </div>
    </>
  );
}

export default SinglePost;
