import React, { useState, useEffect } from 'react';
import { useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import { FilePond } from 'react-filepond';
import UserAvatarIcon from '../../icons/user-avatar.svg';
import { useAccount } from '../../contexts/Account';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css';

const filePondProfileOptions = {
  dropOnPage: 'false',
  stylePanelLayout: 'compact circle',
  allowImageCrop: 'false',
  allowDrop: 'false',
  allowRemove: 'false',
  allowProcess: 'false',
  allowBrowse: 'false',
  allowFileSizeValidation: 'false',
  allowFileTypeValidation: 'false',
  allowImageExifOrientation: 'false',
  allowImagePreview: 'true',
  allowImageResize: 'false',
  allowImageTransform: 'false',
  allowImageValidateSize: 'false',
  imageTransformVariantsIncludeOriginal: 'true',
  imagePreviewMaxFileSize: '10MB',
  imageResizeMode: 'contain',
  maxFileSize: '10MB',
  imageResizeTargetWidth: '600',
  imageResizeTargetHeight: '600',
  maxFiles: 1,
  credits: 'false',
  acceptedFileTypes: `${['image/jpg', 'image/jpeg', 'image/png', 'image/gif']}`,
  imageCropAspectRatio: '1:1',
  imageTransformOutputMimeType: 'image/png',
  imageEditAllowEdit: 'true',
  styleImageEditButtonEditItemPosition: 'bottom right',
  styleLoadIndicatorPosition: 'right bottom',
  styleProgressIndicatorPosition: 'right bottom',
  styleButtonRemoveItemPosition: 'left bottom',
  styleButtonProcessItemPosition: 'right bottom',
  instantUpload: 'false',
};

const useStyles = makeStyles((theme) => ({
  uploader: {
    width: (prop) => (prop.isXS ? '130px' : '120px'),
    height: (prop) => (prop.isXS ? '130px !important' : '120px'),
    cursor: 'pointer',
    '& .filepond--drop-label': {
      fontSize: '0.8rem',
      color: theme.palette.secondary.dark,
      backgroundColor: '#fff',
      cursor: 'pointer',
    },
    '& .filepond--file': {
      fontSize: '0.8rem',
      cursor: 'pointer',
    },
    '& .filepond--file-status-main': {
      marginRight: '16px',
      cursor: 'pointer',
    },
  },
}));

function ProfileImageUploader(props) {
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const classes = useStyles({ isXS });
  const { accountDetails } = useAccount();
  const [preloadedImage, setPreloadedImage] = useState();
  const [currentImage, setCurrentImage] = useState();
  const ref = React.useRef();
  const location = useLocation();

  const contact = location.pathname.includes('/advisor')
    ? accountDetails
    : accountDetails.userData;

  async function preloadFilepondImage(image) {
    const imageToPreloadURL = await fetch(image, {
      method: 'get',
      headers: {
        'Access-Control-Origin': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
      },
    })
      .then((response) => {
        return response.blob();
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log('preload', err);
      });

    setPreloadedImage(imageToPreloadURL);
  }

  useEffect(() => {
    if (contact && contact.contactS3Logo) {
      if (
        contact.contactS3Logo.location &&
        !contact.contactS3Logo.location.includes('undefined')
      ) {
        preloadFilepondImage(contact.contactS3Logo.location);
        // if (contact.image.location && !contact.image.location.includes('undefined')) {
        //   preloadFilepondImage();
      }
    } else if (contact && contact.image) {
      console.log(
        `${process.env.REACT_APP_IMAGES_URL}${contact.image.imageKey}`
      );
      preloadFilepondImage(
        `${process.env.REACT_APP_IMAGES_URL}${contact.image.imageKey}`
      );
    }
  }, []);

  useEffect(() => {
    if (preloadedImage) {
      const newBlob = preloadedImage.slice(0, preloadedImage.size, 'image/png');
      const newBlobURL = URL.createObjectURL(newBlob);
      setCurrentImage(newBlobURL);
    }
  }, [preloadedImage]);

  return (
    <div style={{ width: '140px' }}>
      <FilePond
        ref={ref}
        {...filePondProfileOptions}
        labelIdle={`
          <img src="${UserAvatarIcon}" width="140" />
          `}
        files={currentImage}
        className={classes.uploader}
        onerror={(err) => {
          // eslint-disable-next-line no-console
          console.log('filepond', err);
        }}
      />
    </div>
  );
}

export default ProfileImageUploader;
