import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import EmptyStateContent from '../../components/EmptyStateContent';
import LoadingIndicatorLogo from '../../components/LoadingIndicatorLogo';
import EmptyAnswerSvg from '../../images/empty-answers.svg';
import { useAccount } from '../../contexts/Account';
import { companyFetchPostsCall } from '../../services/company_services';
import PostResponseReply from '../../components/Post/ResponseReply';
import { AccountType } from '../../services/utils/types';
import DashboardSkeleton from '../../components/SkeletonLoading/dashboardSkeleton';
import Treatment from '../../components/Treatment';
import { featureFlags } from '../../splitSetup';

const useStyles = (isSM) => ({
  screenContainer: {
    paddingLeft: isSM ? '10px' : '30px',
    marginRight: isSM ? '0px' : '20%',
    paddingRight: isSM ? '10px' : '30px',
    paddingTop: '30px',
    display: 'flex',
    flexDirection: 'column',
    flex: 'auto',
    overflowX: 'scroll',
  },
  loadingIndicatorPosition: {
    width: '100%',
    height: '500px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '-70px',
  },
});

function AdvsiorBoardRoomMyAnswers() {
  const { t } = useTranslation();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const { accountDetails, asLoggedUserDetails, currentUserInfo } = useAccount();
  const [loading, setLoading] = useState(true);
  const [postsList, setPostsList] = useState([]);
  const [myAnswersArray, setMyAnswersArray] = useState([]);
  console.log('myAnswersArray', myAnswersArray);
  const params = useParams('/:companySlug/boardrooms');
  const { companySlug } = params;

  const imageArray = [
    {
      src: EmptyAnswerSvg,
      alt: 'empty-question.svg',
      width: '200',
    },
  ];

  function getPostsCompanyCall(companyID) {
    companyFetchPostsCall({
      ACCOUNT_TYPE: AccountType.ADVISOR,
      ADVISOR_ID: asLoggedUserDetails.userId,
      COMPANY_ID: companyID,
      LIMIT: 50,
      OFFSET: 0,
      ORDER_BY: 'createdAt',
      REVERSE: false,
      TYPE: 'all',
    }).then((response) => {
      setPostsList(response.data.getPosts);
    });
  }

  async function getCompanyId() {
    try {
      const companyMemberArray = accountDetails.boards.memberBoards;
      const filteredArray = companyMemberArray.filter(
        (element) => element.slug === companySlug
      );
      const selectedCompanyId = filteredArray[0].id;
      getPostsCompanyCall(selectedCompanyId);
    } catch (err) {
      throw new Error(err);
    }
  }

  useEffect(() => {
    if (postsList.length > 0) {
      const filter = asLoggedUserDetails.userId;
      const filteredArray = postsList
        .filter((element) =>
          element.contributors.some((subElement) => subElement.id === filter)
        )
        .map((element) => {
          return {
            ...element,
            subElements: element.contributors.filter(
              (subElement) => subElement.id === filter
            ),
          };
        });

      setMyAnswersArray(filteredArray);
      setLoading(false);
    }
  }, [postsList]);

  useEffect(() => {
    getCompanyId();
  }, [companySlug]);

  return (
    <>
      <Helmet>
        <title>{t('ADVISOR-BOARDROOMS-MY-ANSWERS-HELMET-TITLE')}</title>
      </Helmet>

      {loading ? (
        <div style={classes.screenContainer}>
          <DashboardSkeleton />
        </div>
      ) : (
        <>
          {myAnswersArray.length === 0 ? (
            <>
              <div style={classes.screenContainer}>
                <EmptyStateContent
                  imageArray={imageArray}
                  titleText={t(
                    'ADVISOR_BOARDROOMS_MY_ANSWERS_EMPTY_STATE_TITLE'
                  )}
                  contentText={t(
                    'ADVISOR_BOARDROOMS_MY_ANSWERS_EMPTY_STATE_CONTENT'
                  )}
                />
              </div>
            </>
          ) : (
            <div style={classes.screenContainer}>
              <PostResponseReply
                gotPostList={myAnswersArray}
                hideTopCards
                multiplePosts
              />
            </div>
          )}
        </>
      )}
    </>
  );
}

export default AdvsiorBoardRoomMyAnswers;
