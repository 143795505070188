import React from 'react';
import { useMediaQuery } from '@mui/material';
import CountAdvisorsTile from './CountAdvisorsTile';
import PostsCountTile from './PostsCountTile';
import DescriptionTile from './DescriptionTile';

const useStyles = ({isSM, screen}) => ({
  tilesContainer: {
    display: 'flex',
    displayDirection: 'row',
    flexDirection: isSM ? 'column' : '',
    width: '100%',
    marginBottom: screen == 'advisors' ? '5px' : '30px',
    justifyContent: 'space-between',
  },
});

function CompanyTiles({ screen }) {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles({isSM, screen});

  return (
    <div style={classes.tilesContainer}>
      <CountAdvisorsTile />
      <PostsCountTile />
      {!isSM && <DescriptionTile />}
    </div>
  );
}
export default CompanyTiles;
