import React from 'react';
import { Typography } from '@mui/material';

const useStyles = () => ({
  note: {
    paddingLeft: '20px',
  },
  mainInformationContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  contentPosition: {
    marginTop: '30px',
    fontWeight: 'bold',
    fontSize: '15px',
  },
  publicContentPosition: {
    marginTop: '30px',
    fontWeight: 500,
    fontSize: '20px',
  },
  contentDate: {
    marginBottom: '15px',
  },
  publicContentDate: {
    marginBottom: '15px',
    fontWeight: 500,
    fontSize: '15px',
  },
  publicNote: {
    fontSize: '16px',
    marginLeft: '20px',
  },
  publicBulletPoint: {
    fontSize: '30px',
    color: '#D9D9D9',
  },
  list: {
    paddingInlineStart: '15px',
  },
  publicList: {
    listStyle: 'none',
    paddingInlineStart: '15px',
  },
  listItem: {
    '&:before': {
      content: '*',
      position: 'absolute',
      left: '0px',
      top: '5px',
      width: '10px',
      height: '10px',
      backgroundColor: '#f9dd94',
    },
  },
  publicListItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: '-15px',
  },
});

function Experience({ content, publicProfile }) {
  const classes = useStyles();

  return (
    <div style={classes.mainInformationContainer}>
      <Typography
        variant="body1"
        sx={
          publicProfile
            ? classes.publicContentPosition
            : classes.contentPosition
        }
      >
        {content.position} - {content.company}
      </Typography>
      <Typography
        variant="body1"
        sx={publicProfile ? classes.publicContentDate : classes.contentDate}
      >
        {content.startDate} - {content.endDate || 'Current'}
      </Typography>
      <div>
        {content.notes
          ? content.notes.map((note, index) => (
              <ul
                style={publicProfile ? classes.publicList : classes.list}
                key={index}
              >
                <li
                  style={
                    publicProfile ? classes.publicListItem : classes.listItem
                  }
                >
                  {publicProfile && (
                    <Typography
                      sx={publicProfile ? classes.publicBulletPoint : {}}
                      variant="body1"
                    >
                      •
                    </Typography>
                  )}
                  <Typography
                    sx={publicProfile ? classes.publicNote : {}}
                    variant="body1"
                  >
                    {note}
                  </Typography>
                </li>
              </ul>
            ))
          : null}
      </div>
    </div>
  );
}

export default Experience;
