import React, { useEffect, useState } from 'react';
import {
  Avatar,
  CircularProgress,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { getCompanyInfoBoardroomMenu } from '../../../services/company_services';
import { useAccount } from '../../../contexts/Account';
import AdvisorItem from './advisorItem';

const useStyles = ({ isSM }) => ({
  container: {
    width: isSM ? '0%' : 'auto',
    minWidth: isSM ? '0%' : '280px',
    maxWidth: isSM ? '0%' : null,
    height: '76px',
    cursor: 'pointer',
    backgroundColor: '#F5F7FA',
  },
  loadingContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#F5F7FA',
  },
  cardContainer: {
    width: '100%',
    padding: '0px 15px',
  },
  displayName: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '13px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.75px',
    color: '#232B35',
    whiteSpace: 'nowrap',
  },
  infoContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    overflow: 'hidden',
    maxWidth: '100%',
  },
  rowContainer: {
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '100%',
  },
  expandContainer: {
    display: 'flex',
    justifyContent: 'end',
    fontFamily: 'Poppins',
    fontWeight: 400,
    marginRight: '20px',
  },
});

function CompanyChatLeftMenu(props) {
  const { conversations, company } = props;
  const { accountDetails } = useAccount();
  const [isExpanded, setIsExpanded] = useState(false);
  const [boardAdvisors, setBoardAdvisors] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles({ isSM });

  function formatText(text, size) {
    if (text && text.length > size) {
      text.substring(0, size);
      return `${text.substring(0, size)}...`;
    }
    return text;
  }

  async function getBoards() {
    setLoading(true);
    const result = await getCompanyInfoBoardroomMenu({
      companyId: company.id,
    });
    const results = result.data.getCompany.boardAdvisors;
    if (results && results.length > 0) {
      const usersWithoutConversations = results.filter((user) => {
        return !conversations.some(
          (conversation) =>
            conversation.conversationMemberAccountId === user.id ||
            user.id === accountDetails.id
        );
      });
      setBoardAdvisors(usersWithoutConversations);
    } else {
      setBoardAdvisors([]);
    }
    setLoading(false);
  }

  async function expandCompany() {
    if (isLoading) return;
    if (!isExpanded) {
      if (!boardAdvisors) {
        await getBoards();
      }
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  }

  useEffect(() => {
    if (!isFirstRender && boardAdvisors && boardAdvisors.length) {
      getBoards();
    } else {
      setIsFirstRender(false);
    }
  }, [conversations]);

  return (
    <>
      <div style={classes.container} role="none" onClick={expandCompany}>
        <div style={classes.rowContainer}>
          <div style={classes.infoContainer}>
            <div style={{ marginLeft: '15px' }}>
              <Avatar
                src={`https://${company.companyLogo}`}
                style={{ width: '48px', height: '48px' }}
              />
            </div>
            <div style={classes.cardContainer}>
              <Typography sx={classes.displayName}>
                {formatText(company.companyName, 15)}
              </Typography>
            </div>
          </div>
          <div style={classes.expandContainer}>
            {isExpanded ? (
              <ExpandMore fontSize="small" />
            ) : (
              <ChevronLeftIcon fontSize="small" />
            )}
          </div>
        </div>
      </div>
      {isLoading ? (
        <div style={classes.loadingContainer}>
          <CircularProgress />
        </div>
      ) : null}
      {!isLoading && boardAdvisors && boardAdvisors.length
        ? boardAdvisors.map((advisor) => (
            <div
              key={advisor.id}
              style={{ display: isExpanded ? 'block' : 'none' }}
            >
              <AdvisorItem advisorId={advisor.id} />
            </div>
          ))
        : null}
    </>
  );
}

export default CompanyChatLeftMenu;
