import React, { useState } from 'react';
import { TextField, useMediaQuery, InputAdornment } from '@mui/material';
import { SearchOutlined } from '@mui/icons-material';

const useStyles = () => ({
  keywordsInput: {
    display: 'flex',
    flex: 'auto',
    paddingBottom: '10px',
    paddingRight: '1px',
  },
});

function BoardMeetingSearchBar(props) {
  const { onSearchButtonClicked } = props;
  const [keywords, setKeywords] = useState('');

  const isMD = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles({ isMD, isSM });

  function textFieldKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      onSearchButtonClicked(keywords);
    }
  }

  return (
    <TextField
      sx={classes.keywordsInput}
      id="search-advisor-keyword-input"
      data-testid="search-advisor-keyword-input"
      data-cy="search-advisor-keyword-input"
      placeholder="Search live, upcoming, and past board meetings"
      value={keywords}
      onChange={(evt) => setKeywords(evt.target.value)}
      onKeyDown={(evt) => textFieldKeyPress(evt)}
      InputProps={{
        inputProps: {
          style: {
            padding: '12.5px 14px 12.5px 0px',
            borderRadius: '10px',
          },
        },
        sx: {
          borderRadius: '10px',
        },
        startAdornment: (
          <InputAdornment style={{ marginRight: '10px' }} position="start">
            <SearchOutlined
              style={{
                fontSize: '20px',
                color: '#c5ccd5',
              }}
            />
          </InputAdornment>
        ),
      }}
    />
  );
}

export default BoardMeetingSearchBar;
