import React, { useEffect, useState } from 'react';
import {
  Paper,
  Typography,
  useMediaQuery,
  Collapse,
  List,
  ListItemButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  CircularProgress,
} from '@mui/material';
import MoreHorizOutlined from '@mui/icons-material/MoreHorizOutlined';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getCompanyInfoBoardroomMenu } from '../../../services/company_services';
import { getAdvisor } from '../../../services/Backend/account';
import { resignFromBoard } from '../../../services/advisor_services';
import { useAccount } from '../../../contexts/Account';
import eventBus, { eventBusValues } from '../../../eventBus';
import CardBoard from './boards';
import { AccountType } from '../../../services/utils/types';
import CustomIcon from '../../../icons/customIcon';
import ACButton from '../../../components/ACButton';

const useStyles = () => ({
  outerContainer: {
    width: '100%',
  },
  container: {
    paddingLeft: '20px',
    paddingRight: '20px',
    borderRadius: '0px',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    width: '100%',
    paddingTop: '10px',
  },
  dialogContainer: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0px',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    marginBottom: '20px',
    marginLeft: '10px',
    color: '#232B35',
  },
  menuItemText: {
    fontSize: '20px',
    fontWeight: 500,
  },
  selectedMenuItem: {
    fontWeight: 700,
  },
  unSelectedMenuItem: {
    fontWeight: 500,
  },
});

function MenuItem({ label, route, isSelected, staticClass }) {
  const history = useHistory();
  const classes = useStyles();

  function handleClick() {
    history.push(route);
  }

  return (
    <ListItemButton
      className={staticClass}
      sx={{ ...classes.menuItem }}
      onClick={handleClick}
    >
      <div>
        <Typography
          sx={{
            ...classes.menuItemText,
            ...(isSelected
              ? classes.selectedMenuItem
              : classes.unSelectedMenuItem),
          }}
          variant="caption"
        >
          {label}
        </Typography>
      </div>
    </ListItemButton>
  );
}
function MemberItem({ advisorId, currentId }) {
  const [advisorData, setAdvisorData] = useState(false);

  useEffect(() => {
    let isMounted = true;
    function getAdvisorData() {
      getAdvisor(advisorId)
        .then((response) => {
          if (isMounted) {
            if (
              response.data.getAdvisor.accountStatus === 'active' &&
              response.data.getAdvisor.image
            ) {
              setAdvisorData(response.data.getAdvisor);
            }
          }
        })
        .catch(() => {});
    }
    if (advisorId !== currentId) {
      getAdvisorData();
    }
    return () => {
      isMounted = false;
    };
  }, [advisorId]);

  if (advisorData) {
    return (
      <CardBoard
        onClick={() => visitProfile(advisorData)}
        logo={
          advisorData.image && advisorData.image.imageKey
            ? `${process.env.REACT_APP_IMAGES_URL}${advisorData.image.imageKey}`
            : ''
        }
        companyName={advisorData.displayName}
        boardMember={advisorData}
        memberType={AccountType.ADVISOR}
        status="member"
        key={advisorData.id}
      />
    );
  }
  return null;
}
function visitProfile(advisor) {
  eventBus.dispatch(eventBusValues.triggerAdvisorDrawerAction, {
    ...advisor,
    boardRoomView: true,
  });
}

function AdvisorBoardroomsLeftMenu() {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { asLoggedUserDetails, accountDetails, currentPlan, isFreeTrial } =
    useAccount();
  const { salesforceValues } = currentPlan;
  const showBanner =
    !isFreeTrial &&
    salesforceValues.billingCycle === 'Monthly' &&
    ['Essentials', 'Professional', 'Executive'].includes(salesforceValues.tier);
  const classes = useStyles({ isSM, isFreeTrial, showBanner });
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const history = useHistory();
  const { t } = useTranslation();
  const params = useParams('/:companySlug/boardrooms');
  const { companySlug } = params;
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [companyBoardData, setCompanyBoardData] = useState({});
  const [boardAdvisorsData, setBoardAdvisorsData] = useState([]);
  const [companyBoardOwner, setCompanyBoardOwner] = useState();
  const [openWelcomePopup, setOpenWelcomePopup] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);

  const selectedCompany =
    asLoggedUserDetails.advisorRelatedCompanies.memberBoards.find(
      (element) => element.slug === companySlug
    );

  function handleBoardroomClick(data) {
    const companySlug = data.slug;
    const companyId = data.id;
    history.push({
      pathname: `/${companySlug}/boardroom`,
      state: companyId,
    });
  }

  function resignAction() {
    resignFromBoard({
      ADVISOR_ID: asLoggedUserDetails.userId,
      COMPANY_ID: companyBoardData.id,
    })
      .then(() => {
        if (
          accountDetails.viewedBoards &&
          accountDetails.viewedBoards.length > 0
        ) {
          accountDetails.viewedBoards = accountDetails.viewedBoards.filter(
            (board) => board != companyBoardData.id
          );
        }
        const newMemberBoards = [
          ...asLoggedUserDetails.advisorRelatedCompanies.memberBoards.filter(
            (element) => element.slug !== companySlug
          ),
        ];
        const nonRefMemberBoards = newMemberBoards.map((el) => ({ ...el }));
        accountDetails.boards.memberBoards = nonRefMemberBoards;
        asLoggedUserDetails.advisorRelatedCompanies.memberBoards =
          nonRefMemberBoards;
        history.push({
          pathname: '/dashboard',
          state: {
            resignedCompany: companyBoardData.displayName,
          },
        });
      })
      .catch(() => {
        setIsModalVisible(false);
        setIsModalLoading(false);
        // setResignSnackbar(true);
      });
  }

  useEffect(() => {
    let isMounted = true;
    if (queryParams.get('first_view')) {
      setOpenWelcomePopup(true);
    }
    function getCompanyData() {
      getCompanyInfoBoardroomMenu({ companyId: selectedCompany.id })
        .then((response) => {
          if (isMounted) {
            setCompanyBoardData(response.data.getCompany);
            const companyContacts = response.data.getCompany.contacts;
            const companyOwner = companyContacts.find((c) => c.isBoardOwner);
            setCompanyBoardOwner(companyOwner || companyContacts[0]);
          }
        })
        .catch((error) => {
          console.log(error);
          if (isMounted) {
            setShowSnackbar(true);
          }
        });
    }

    getCompanyData();
    return () => {
      isMounted = false;
    };
  }, [companySlug]);

  useEffect(() => {
    if (Object.keys(companyBoardData).length != 0) {
      if (
        companyBoardData.boardAdvisors &&
        companyBoardData.boardAdvisors.length > 0
      ) {
        setBoardAdvisorsData(companyBoardData.boardAdvisors);
      }
    }
  }, [companyBoardData]);

  return (
    <>
      <div style={classes.outerContainer}>
        <Paper sx={classes.container}>
          <div
            style={{
              width: '100%',
              paddingBottom: '20px',
            }}
          >
            <CardBoard
              onClick={() => visitProfile(companyBoardOwner)}
              logo={`${process.env.REACT_APP_IMAGES_URL}${
                companyBoardOwner && companyBoardOwner.image
                  ? companyBoardOwner.image.id
                  : ''
              }`}
              isBoardOwner
              companyName={
                companyBoardOwner ? companyBoardOwner.displayName : ''
              }
              boardMember={companyBoardOwner ? companyBoardOwner : {}}
              memberType={AccountType.COMPANY}
              status="member"
              key={companyBoardOwner ? companyBoardOwner.id : ''}
            />
          </div>
          <MenuItem
            route={`/${companySlug}/boardroom`}
            label={'Boardroom'}
            isSelected={location.pathname.includes('/boardroom')}
          />
          <MenuItem
            route={`/${companySlug}/board-meetings`}
            label={'Meetings'}
            isSelected={location.pathname.includes('/board-meetings')}
          />
          <MenuItem
            route={`/${companySlug}/my-answers`}
            label={'Answers'}
            isSelected={location.pathname.includes('/answers')}
          />
          <MenuItem
            route={`/${companySlug}/benefits`}
            label={'Benefits'}
            isSelected={location.pathname.includes('/benedits')}
          />
          <div>
            <Typography
              sx={{
                ...classes.menuItemText,
                marginLeft: '10px',
              }}
              variant="caption"
            >
              Members
            </Typography>
          </div>
          <div
            style={{
              width: '100%',
              paddingBottom: '10px',
              backgroundColor: '#fff',
            }}
          >
            {boardAdvisorsData &&
              boardAdvisorsData.length > 0 &&
              boardAdvisorsData.map((bm) => {
                return (
                  <MemberItem
                    key={bm.id}
                    advisorId={bm.id}
                    currentId={accountDetails.id}
                  />
                );
              })}
          </div>
          <button
            style={{
              ...classes.menuItem,
              border: 'none',
              backgroundColor: '#fff',
            }}
            type="submit"
            onClick={() => setIsCollapseOpen((prevValue) => !prevValue)}
          >
            <MoreHorizOutlined />
          </button>
          <Collapse in={isCollapseOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                onClick={() => {
                  setIsModalVisible(true);
                }}
              >
                <CustomIcon
                  iconname="trash-outlined"
                  style={{
                    color: 'red',
                    fontSize: '18px',
                    height: '27px',
                  }}
                />
                <Typography variant="caption" style={{ paddingLeft: '10px' }}>
                  {t('ADVISOR-BOARDROOMS-RESIGN')}
                </Typography>
              </ListItemButton>
            </List>
          </Collapse>
        </Paper>
      </div>
      <Dialog
        open={isModalVisible}
        sx={classes.dialogContainer}
        maxWidth="sm"
        onClose={() => {
          if (!isModalLoading) {
            setIsModalVisible(false);
          }
        }}
      >
        <div style={classes.dialogContainer}>
          <DialogContent>
            <DialogContentText>
              <Typography variant="caption" style={{ fontSize: '16px' }}>
                {t('ADVISOR-BOARDROOM-MODAL-RESIGN')}
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={classes.dialogActions}>
            {isModalLoading ? (
              <div>
                <CircularProgress />
              </div>
            ) : (
              <>
                <ACButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setIsModalVisible(false);
                  }}
                  style={{
                    paddingTop: '15px',
                    paddingBottom: '15px',
                  }}
                >
                  {t('CANCEL')}
                </ACButton>
                <ACButton
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setIsModalLoading(true);
                    resignAction();
                  }}
                  style={{
                    paddingTop: '15px',
                    paddingBottom: '15px',
                    marginRight: '10px',
                  }}
                >
                  {t('ADVISOR-BOARDROOM-LEFT-MENU-RESIGN')}
                </ACButton>
              </>
            )}
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
}

export default AdvisorBoardroomsLeftMenu;
