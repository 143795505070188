import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';
import ACButton from './../../../ACButton';

const useStyles = () => ({
  iconStyle: {
    fontSize: '15px',
    color: 'primary.main',
  },
});

function BackButton(props) {
  const {
    onClick,
    buttonColor,
    buttonSize,
    buttonVariant,
    loading,
    isDisabled,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <ACButton
      color={buttonColor}
      size={buttonSize}
      variant={buttonVariant}
      style={{
        backgroundColor: 'transparent',
        display: 'flex',
        boxShadow: 'none',
      }}
      onClick={() => onClick()}
      loading={loading ? 1 : 0}
      disabled={isDisabled}
      width="80px"
    >
      <ArrowBackIos color="primary" sx={classes.iconStyle} />
      <Typography variant="h1_old_font" color="primary">
        {t('POST-BACK-BUTTON')}
      </Typography>
    </ACButton>
  );
}

export default BackButton;
