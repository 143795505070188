import React from 'react';
import { Typography } from '@mui/material';
import CustomIcon from './../../../../icons/customIcon';

const useStyles = () => ({
  mainConatiner: {
    display: 'flex',
    flexDirection: 'row',
    flexShrink: 0,
  },
  shrinkControlContainer: {
    flexShrink: 0,
  },
  button: {
    border: 'none',
    display: 'flex',
    outline: 'none',
    backgroundColor: 'transparent',
    padding: '0px',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&:disabled': {
      cursor: 'default',
    },
  },
  commentsTextLarge: {
    fontStyle: 'normal',
    fontWeight: '600',
    marginTop: '4px',
    fontSize: '16px',
    color: '#232b35',
  },
  commentsTexMobile: {
    fontStyle: 'normal',
    fontWeight: '600',
    color: '#232b35',
  },
  badge: {
    fontSize: '10px',
  },
});

function CommentsButton(props) {
  const classes = useStyles();
  const { numComments, onClick, filled, disabled, iconSize } = props;

  function sizeSelector() {
    if (iconSize === 'xs') {
      return 19;
    }
    if (iconSize === 'sm') {
      return 24;
    }
    if (iconSize === 'lg') {
      return 28;
    }
    return 19;
  }

  function renderIcon() {
    if (filled) {
      return (
        <CustomIcon
          iconname="comments"
          style={{
            color: '#1663F7',
            fontSize: sizeSelector(),
            marginRight: '8px',
            marginTop: -1,
          }}
        />
      );
    }
    return (
      <CustomIcon
        iconname="comments"
        style={{
          color: '#232B35',
          fontSize: sizeSelector(),
          marginRight: '8px',
          marginTop: -1,
        }}
      />
    );
  }

  const unsetNumber =
    numComments === null || (numComments === undefined) | isNaN(numComments);

  function renderComments() {
    if (unsetNumber && iconSize === 'xs') {
      return (
        <Typography variant="caption" sx={classes.commentsTexMobile}>
          0 Comments
        </Typography>
      );
    }
    if (unsetNumber) {
      return (
        <Typography variant="body1" sx={classes.commentsTextLarge}>
          0 Comments
        </Typography>
      );
    }
    if (!unsetNumber && iconSize === 'xs') {
      return (
        <Typography variant="caption" sx={classes.commentsTexMobile}>
          {`${numComments} Comments`}
        </Typography>
      );
    }
    if (!unsetNumber) {
      return (
        <Typography variant="body1" sx={classes.commentsTextLarge}>
          {`${numComments} Comments`}
        </Typography>
      );
    }
    return null;
  }

  return (
    <div style={classes.mainConatiner}>
      <div style={classes.shrinkControlContainer}>
        <button
          style={classes.button}
          onClick={(event) => {
            onClick();
            event.stopPropagation();
          }}
          id="commentsButton"
          type="button"
          disabled={disabled}
        >
          {renderIcon()}
          {renderComments()}
        </button>
      </div>
    </div>
  );
}

export default CommentsButton;
