import React from 'react';
import { Box, Typography } from '@mui/material';
import Jason from './jason.png';
import Lia from './lia.png';
import Peter from './peter.png';
import Meeting from './meeting.png';
import avatar from './logo.png';
import CustomIcon from './../../../icons/customIcon';
import VideoCallOutlinedIcon from '@mui/icons-material/VideoCallOutlined';
import eventBus, { eventBusValues } from './../../../eventBus';

function MeetingsEmptyState() {
  const openDialog = () => {
    eventBus.dispatch(eventBusValues.triggerMeetAdvisors);
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#FBFCFD',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Box sx={{ position: 'relative', marginTop: '50px' }}>
        <VideoCallOutlinedIcon
          sx={{
            width: '50px',
            height: '54px',
            fontSize: '37px',
            position: 'absolute',
            top: -8,
            right: 10,
          }}
        />
        <Box
          sx={{
            backgroundColor: '#D7F1FD',
            width: '49px',
            height: '44px',
            borderRadius: '100px',
          }}
        />
      </Box>

      <Typography
        sx={{
          fontSize: '26px',
          fontWeight: 700,
          color: '#232B35',
          marginTop: '5px',
          textAlign: 'center',
        }}
      >
        Hold your first meeting
      </Typography>
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 500,
          color: '#232B35',
          marginTop: '10px',
          textAlign: 'center',
        }}
      >
        Book moderated meetings with experts, 1-1 or in groups
      </Typography>
      <button
        onClick={openDialog}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '20px',
          fontSize: '16px',
          borderRadius: '999px',
          fontWeight: 700,
          width: '193px',
          height: '48px',
          backgroundColor: '#3171F6',
          color: '#FFFFFF',
          borderColor: 'none',
          fontFamily: 'Poppins',
          border: 'none',
          outline: 'none',
          cursor: 'pointer',
          padding: '10px',
          marginTop: '20px',
        }}
        variant="contained"
        id="makeYourFirstPost"
      >
        <VideoCallOutlinedIcon
          sx={{
            width: '26px',
            height: '26px',
            //fontSize: '30px',
            marginRight: '5px',
            marginLeft: '5px',
          }}
        />
        Learn more
      </button>
      <Box
        id="MeetingEmptyState"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#FFFFFF',
          borderRadius: '10px',
          border: '1px solid #F0F1F3',
          alignItems: 'center',
          height: '100%',
          width: '100%',
          maxWidth: '799px',
          padding: '20px 20px 0px 20px',
          marginTop: '50px',
          marginBottom: '50px',
          boxShadow: '0px 0px 40px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            maxWidth: '720px',
            marginBottom: '40px',
          }}
        >
          <Box
            id="logoMeetingEmptyState"
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '70px',
                height: '70px',
                backgroundColor: '#FAF1EA',
                borderRadius: '999px',
                marginRight: '10px',
              }}
            >
              <img
                src={avatar}
                alt="avatar"
                style={{ width: '65px', height: '18px' }}
              />
            </Box>
            <Typography
              sx={{ fontSize: '16px', fontWeight: 600, color: '#232B35' }}
            >
              FlexiFlow
            </Typography>
          </Box>
          <Box
            id="buttonMeetingEmptyState"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '109px',
                height: '32px',
                backgroundColor: '#FFEED6',
                borderRadius: '999px',
                marginRight: '10px',
              }}
            >
              <CustomIcon
                iconname="calendar-meeting"
                style={{
                  width: '20px',
                  height: '20px',
                  fontSize: '16px',
                  marginRight: '5px',
                  marginTop: '-5px',
                }}
              />
              <Typography
                sx={{ fontSize: '14px', fontWeight: 600, color: '#232B35' }}
              >
                Meeting
              </Typography>
            </Box>
          </Box>
        </Box>
        <Typography
          id="textMeetingEmptyState"
          sx={{
            fontSize: '20px',
            fontWeight: 500,
            color: '#232B35',
            width: '100%',
            maxWidth: '713px',
            marginBottom: '30px',
          }}
        >
          Here is the recording and transcription from your
          <span style={{ fontWeight: 700 }}> Moderated Meeting </span>
          discussing fundraising, growth, and Q1 initiatives with four experts.
        </Typography>

        <img
          id="imgMeetingEmptyState"
          src={Meeting}
          alt="meeting"
          style={{
            display: 'flex',
            maxWidth: '724px',
            maxHeight: '470px',
            width: '100%',
            height: '100%',
            marginBottom: '50px',
            borderRadius: '10px',
          }}
        />

        <Box
          id="commentMeetingEmptyState-1"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#F0F4F8',
            borderRadius: '10px',
            height: '100%',
            maxHeight: { xxs: '100%', sm: '190px' },
            width: '100%',
            maxWidth: '728px',
            padding: '20px',
            marginBottom: '30px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '15px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={Jason}
                alt="jason"
                style={{
                  width: '60px',
                  height: '60px',
                  borderRadius: '99px',
                  marginRight: '10px',
                  objectFit: 'cover',
                }}
              />
              <Box>
                <Typography
                  sx={{ fontSize: '16px', fontWeight: 500, color: '#232B35' }}
                >
                  Jason Roberts
                </Typography>
                <Typography
                  sx={{ fontSize: '14px', fontWeight: 300, color: '#1C2F49' }}
                >
                  Felxiflow VP of Growth
                </Typography>
              </Box>
            </Box>
            <Typography
              sx={{ fontSize: '16px', fontWeight: 600, color: '#1C2F49' }}
            >
              1hr
            </Typography>
          </Box>
          <Typography
            sx={{ fontSize: '16px', fontWeight: 500, color: '#232B35' }}
          >
            Mark’s idea at the 4:55 mark is brilliant. We should look to
            implement that before moving forward with the remaining initiatives.
          </Typography>
        </Box>
        <Box
          id="commentMeetingEmptyState-2"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#F0F4F8',
            borderRadius: '10px',
            height: '100%',
            maxHeight: { xxs: '100%', sm: '130px' },
            width: '100%',
            maxWidth: '728px',
            padding: '20px',
            marginBottom: '20px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '15px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={Lia}
                alt="lia"
                style={{
                  width: '60px',
                  height: '60px',
                  borderRadius: '99px',
                  marginRight: '10px',
                }}
              />
              <Box>
                <Typography
                  sx={{ fontSize: '16px', fontWeight: 500, color: '#232B35' }}
                >
                  Lia Roberts
                </Typography>
                <Typography
                  sx={{ fontSize: '14px', fontWeight: 300, color: '#1C2F49' }}
                >
                  VP of Marketing
                </Typography>
              </Box>
            </Box>
            <Typography
              sx={{ fontSize: '16px', fontWeight: 600, color: '#1C2F49' }}
            >
              2d
            </Typography>
          </Box>
          <Typography
            sx={{ fontSize: '16px', fontWeight: 500, color: '#232B35' }}
          >
            Great insights!!
          </Typography>
        </Box>
        <Box
          id="commentMeetingEmptyState-3"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#F0F4F8',
            borderRadius: '10px',
            height: '100%',
            maxHeight: { xxs: '100%', sm: '190px' },
            width: '100%',
            maxWidth: '728px',
            padding: '20px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '15px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={Peter}
                alt="peter"
                style={{
                  width: '60px',
                  height: '60px',
                  borderRadius: '99px',
                  marginRight: '10px',
                }}
              />
              <Box>
                <Typography
                  sx={{ fontSize: '16px', fontWeight: 500, color: '#232B35' }}
                >
                  Peter Engles
                </Typography>
                <Typography
                  sx={{ fontSize: '14px', fontWeight: 300, color: '#1C2F49' }}
                >
                  FlexiFlow CEO
                </Typography>
              </Box>
            </Box>
            <Typography
              sx={{ fontSize: '16px', fontWeight: 600, color: '#1C2F49' }}
            >
              5d
            </Typography>
          </Box>
          <Typography
            sx={{ fontSize: '16px', fontWeight: 500, color: '#232B35' }}
          >
            Really interesting perspective. I think the offshore idea are
            particularly compelling. We could easily grow margin through
            efiiciencies and cross-selling.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default MeetingsEmptyState;
