import React, { createRef } from 'react';
import ACSnackbar from '../ACSnackbar';
import { useAccount } from '../../contexts/Account';

function BoardroomsTitleSnackbar({ successSnackbar, setSuccessSnackbar }) {
  const { isFreemium } = useAccount();
  const ref = createRef();

  return (
    <ACSnackbar
      ref={ref}
      open={successSnackbar}
      text="You've successfully subscribed to board meeting notifications"
      severity="success"
      onClose={() => setSuccessSnackbar(false)}
      autoHideDuration={6000}
      style={{ marginTop: isFreemium ? '50px' : '0px' }}
    />
  );
}
export default BoardroomsTitleSnackbar;
