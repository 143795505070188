import React, { useState } from 'react';
import {
  TextField,
  Button,
  IconButton,
  Typography,
  Box,
  useMediaQuery,
} from '@mui/material';
import ACSnackbar from './../../ACSnackbar';
import LinkIcon from '@mui/icons-material/Link';
import AddIcon from '@mui/icons-material/Add';
import CircularProgress from '@mui/material/CircularProgress';
import { useAccount } from './../../../contexts/Account';
import { mutationSendInviteContacts } from './../../../services/company_services';

export default function InviteTeamMembers({ inviteContact }) {
  const { accountDetails } = useAccount();
  const boardOwnerContact =
    accountDetails && accountDetails.contacts
      ? accountDetails.contacts?.find((contact) => contact.isBoardOwner)
      : [];
  const boardOwnerName = boardOwnerContact
    ? `${boardOwnerContact.givenName} ${boardOwnerContact.surName}`
    : 'No board owner found';
  const [emails, setEmails] = useState(['', '', '']);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isInviteButtonDisabled, setIsInviteButtonDisabled] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [emailErrors, setEmailErrors] = useState([]);
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const updateEmails = (index, value) => {
    const newEmails = [...emails];
    newEmails[index] = value;
    setEmails(newEmails);

    if (emailErrors[index]) {
      const newErrors = [...emailErrors];
      newErrors[index] = !validateEmail(value) && value !== '';
      setEmailErrors(newErrors);
    }

    setIsInviteButtonDisabled(
      !newEmails.some(validateEmail) || emailErrors.includes(true)
    );
  };

  const handleBlur = () => {
    const newErrors = emails.map(
      (email) => !validateEmail(email) && email !== ''
    );
    setEmailErrors(newErrors);
    setIsInviteButtonDisabled(
      !emails.some(validateEmail) || newErrors.includes(true)
    );
  };

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(
        `https://app.advisorycloud.com/join/${
          inviteContact ? 'team' : 'advisors'
        }/${accountDetails.slug}/${accountDetails.id}`
      )
      .then(() => {
        setSnackbarMessage(
          'Link copied, you can now share it with whoever you choose'
        );
        setSnackbarOpen(true);
      });
  };

  const handleAddMoreEmails = () => {
    setEmails([...emails, '']);
  };

  const handleInvite = async () => {
    const validEmails = emails.filter((email) => validateEmail(email));
    const baseUrl = `https://app.advisorycloud.com/join/${
      inviteContact ? 'team' : 'advisors'
    }/${accountDetails.slug}/${accountDetails.id}`;
    const links = validEmails.map(
      (email) => `${baseUrl}?email=${encodeURIComponent(email)}`
    );

    setIsSending(true);
    setIsInviteButtonDisabled(true);
    try {
      await mutationSendInviteContacts({
        EMAILS: validEmails,
        BOARD_OWNER: boardOwnerName,
        COMPANY_ID: accountDetails.id,
        COMPANY_NAME: accountDetails.companyName,
        LINKS: links,
      });
      setSnackbarMessage(
        'Your contacts have been invited to your AdvisoryCloud'
      );
      setSnackbarOpen(true);
      // onInviteSent();
    } catch (error) {
      console.log('error:', error);
    } finally {
      setIsSending(false);
    }
  };

  return (
    <Box>
      <ACSnackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        autoHideDuration={6000}
        text={snackbarMessage}
        severity="success"
        onClose={() => setSnackbarOpen(false)}
      />
      <Box
        sx={{
          paddingBottom: '30px',
          width: !isSM ? '560px' : '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '0px',
            paddingRight: '10px',
          }}
        >
          <Typography
            sx={{
              color: '#646D7A',
              fontSize: '13px',
              fontWeight: 500,
              lineHeight: '24px',
              letterSpacing: '-0.43px',
            }}
          >
            Invite your contacts
          </Typography>

          <IconButton
            onClick={handleCopyLink}
            disableRipple
            sx={{
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
            id="inviteYourContactsGetLink"
          >
            <LinkIcon
              sx={{ color: '#3171F6', fontSize: '18px', marginRight: '5px' }}
            />
            <Typography
              sx={{
                color: '#3171F6',
                fontSize: '13px',
                fontWeight: 500,
                lineHeight: '24px',
                letterSpacing: '-0.43px',
              }}
            >
              Get shareable link
            </Typography>
          </IconButton>
        </Box>
        <Box>
          {emails.map((email, index) => (
            <TextField
              error={emailErrors[index]}
              helperText={emailErrors[index] ? 'Invalid email' : ''}
              type="email"
              className="inviteContactsEmailInput"
              sx={{
                margin:
                  index === emails.length - 1 ? '0px' : '0px 0px 15px 0px',
                // width: '557px',
                '& .MuiInputBase-root': {
                  height: '44px',
                  borderRadius: '10px',
                },
                '& .MuiInputBase-input': {
                  height: '100%',
                },
                '& .Mui-error': {
                  color: '#f44336',
                },
                input: {
                  '&:-webkit-autofill': {
                    transitionDelay: '9999s',
                    transitionProperty:
                      'background-color, color, height, width',
                  },
                },
              }}
              key={index}
              value={email}
              onChange={(e) => updateEmails(index, e.target.value)}
              onBlur={handleBlur}
              placeholder="Email address"
              fullWidth
              margin="normal"
            />
          ))}
        </Box>
        <Button
          disableRipple
          sx={{
            color: '#646D7A',
            fontSize: '13px',
            fontWeight: 500,
            lineHeight: '24px',
            letterSpacing: '-0.43px',
            textTransform: 'none',
            marginTop: '0px',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
          onClick={handleAddMoreEmails}
          id="inviteYourContactsAddMore"
        >
          <AddIcon
            sx={{ color: '#646D7A', fontSize: '18px', marginRight: '5px' }}
          />
          <Typography
            sx={{
              color: '#646D7A',
              fontSize: '13px',
              fontWeight: 500,
              lineHeight: '24px',
              letterSpacing: '-0.43px',
            }}
          >
            Add more to invite in bulk
          </Typography>
        </Button>
        <Box sx={{ height: '20px', display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={handleInvite}
            disabled={isInviteButtonDisabled}
            style={{
              marginBottom: '20px',
              // position: 'relative',
              fontSize: '16px',
              borderRadius: '999px',
              fontWeight: 700,
              width: '133px',
              height: '44px',
              backgroundColor: isInviteButtonDisabled ? '#cccccc' : '#3171F6',
              color: '#FFFFFF',
              borderColor: 'none',
              fontFamily: 'Poppins',
              border: 'none',
              outline: 'none',
              cursor: isInviteButtonDisabled ? 'default' : 'pointer',
              textTransform: 'none',
            }}
            variant="contained"
            id="inviteYourContactsSend"
          >
            Invite
            {isSending && (
              <CircularProgress
                size={28}
                thickness={5}
                style={{
                  color: '#3171F6',
                  position: 'absolute',
                  left: '40%',
                  top: '20%',
                }}
              />
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
