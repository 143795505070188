import React from 'react';
import { Helmet } from 'react-helmet';
import { Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import ProfileUpgradeForm from '../../components/Company/OnboardingFreemium/ProfileUpgradeForm';
import { useAccount } from '../../contexts/Account';
import CardScene from '../../components/CardScene';

const useStyles = () => ({
  formContainer: {
    paddingTop: '48px',
    paddingLeft: '53px',
    paddingRight: '47px',
    paddingBottom: '52px',
  },
  stepSubtitle: {
    marginBottom: '5px',
    marginTop: '15px',
    color: '#646D7A',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
  },
  stepTitle: {
    // fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    color: '#232B35',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '-20px',
  },
});

function CompanyOnboardingProfileUpgrade(props) {
  const { isUpdate } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { accountDetails, tags, asLoggedUserDetails } = useAccount();
  const initialDesiredSkills = tags.skills.filter((skill) =>
    (accountDetails.desiredSkillsTagIds || []).includes(skill.id)
  );
  let formInitialValues = {
    companySkills: initialDesiredSkills || [],
    benefits: accountDetails.benefits || [],
  };

  let schemaShape = {
    companySkills: Yup.array().min(3).max(30),
  };

  const Step1Schema = Yup.object().shape(schemaShape);

  function renderForm(data) {
    const isValidIndex = Object.keys(data).indexOf('isValid');
    const IS_DISABLED = Object.values(data)[`${isValidIndex}`];

    return <ProfileUpgradeForm {...data} {...props} isDisabled={!IS_DISABLED} />;
  }

  const stepperHeader = () => {
    return (
      <div style={classes.headerContainer}>
        <Grid container direction="column">
          <Grid item>
            <Typography
              component="div"
              sx={classes.stepTitle}
              variant="h5"
              align="center"
            >
              Start building your advisory board
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              display="block"
              component="div"
              sx={classes.stepSubtitle}
              variant="h1"
              align="center"
            >
              Follow these simple steps to start building your advisory board
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>Building your advisory board</title>
      </Helmet>

      <CardScene header={stepperHeader()} isFreemiumOnboarding>
        <div style={classes.formContainer}>
          <Formik
            initialValues={formInitialValues}
            validationSchema={Step1Schema}
            validateOnMount
          >
            {renderForm}
          </Formik>
        </div>
      </CardScene>
    </>
  );
}

export default CompanyOnboardingProfileUpgrade;
