import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Collapse,
  IconButton,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import Grid from '@mui/material/Grid';
import Pagination from '@mui/material/Pagination';
import InputAdornment from '@mui/material/InputAdornment';
import MonitorIcon from '@mui/icons-material/Monitor';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import LoadingIndicatorLogo from '../../components/LoadingIndicatorLogo';
import { searchAdvisorByKeyword } from '../../services/advisor_services';
import AdvisorCardPublicSearch from '../../components/AdvisorCardPublicSearch';
import AdvisorSearchTopMenu from '../../components/Advisor/SearchTopMenu';
import NoRecordImages from './norecords.png';
import Sidebar from './sidebar.png';
import IframeEmbedModal from '../../components/IframeEmbedModal';
import DemoVideoModal from '../../components/DemoVideoModal';
import AdvisorSearchExpertiseFilter from '../../components/Advisor/SearchExpertiseFilter';
import CustomAutocomplete from '../../components/CustomAutocompleteMobile';
import { useAccount } from '../../contexts/Account';

const useStyles = (isSM) => ({
  loadingContainer: {
    alignItems: 'center',
    bottom: '0px',
    display: 'flex',
    justifyContent: 'center',
    left: '0px',
    position: 'fixed',
    right: '0px',
    top: '0px',
  },
  whiteBackground: {
    backgroundColor: 'white',
    marginTop: '10px',
  },
  title: {
    fontWeight: 'bold',
    fontSize: isSM ? '25px' : '32px',
    paddingTop: isSM ? '15px' : '50px',
    textAlign: 'center',
    color: '#273762',
    backgroundColor: 'white',
  },
  subtitle: {
    fontWeight: 500,
    fontSize: '18px',
    paddingTop: '15px',
    textAlign: 'center',
    color: '#273762',
    backgroundColor: 'white',
  },
  filterContainer: {
    padding: '25px',
    margin: '25px',
    marginLeft: isSM ? '25px' : '-10px',
    marginTop: isSM ? '25px' : '15px',
    border: 'solid 1px #F0F1F3',
    borderRadius: '10px',
  },
  mainContainer: {
    marginRight: isSM ? '0px' : '15px',
    marginLeft: isSM ? '0px' : '15px',
    marginTop: isSM ? '-20px' : '0px',
  },
  searchboxContainer: {
    margin: '15px',
    border: 'solid 1px #F0F1F3',
    borderRadius: '10px',
  },
  searchboxContainerInput: {
    display: 'flex',
    flex: 'auto',
    marginRight: '20px',
  },
  registerContainerTitle: {
    color: '#273762',
    fontWeight: 400,
    fontSize: '20px',
    textAlign: 'center',
    paddingTop: '15px',
  },
  registerContainerDescription: {
    color: '#7E7E88',
    fontSize: '12px',
    fontWeight: 400,
    textAlign: 'center',
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '20px',
    '& button': {
      width: '160px',
      height: '35px',
      marginBottom: '20px',
      textTransform: 'none',
      fontSize: '12px',
      borderColor: '#0F78FD',
      color: '#0F78FD',
    },
  },
  viewProfileButton: {
    background: '#0F78FD',
    color: '#FFF',
    '&:hover': {
      background: '#0F78FD',
    },
  },
  paginationContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  expertiseFilters: {
    display: isSM ? 'block' : 'flex',
    flexDirection: 'row',
    padding: '5px',
  },
  expertiseFiltersInput: {
    padding: '5px',
    width: isSM ? '100%' : '33.3%',
  },
  iconInButton: {
    fontSize: '12px',
  },
  noRecord: {
    padding: '45px',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  searchButton: {
    background: '#0F78FD',
    color: '#FFF',
    '&:hover': {
      background: '#0F78FD',
    },
    textTransform: 'none',
    width: '97px',
    height: '35px',
    borderRadius: '20px',
    fontWeight: 600,
  },
  noRecordImages: {
    height: isSM ? '100%' : '150px',
  },
  sidebar: {
    width: '100%',
  },
  noRecordTitle: {
    fontWeight: 'bold',
    fontSize: '32px',
    paddingTop: '10px',
    paddingBottom: '15px',
    textAlign: 'center',
    color: '#273762',
    width: '100%',
  },
  keywordsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '50px',
    borderBottomWidth: 2,
    borderBottom: 'solid',
    borderBottomColor: 'grey.300',
    padding: '10px',
  },
});

function AdvisorPublicSearch() {
  const { tags } = useAccount();
  const { t } = useTranslation();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const classes = useStyles(isSM);
  const [keyword, setKeyword] = useState('');
  const [advisors, setAdvisors] = useState([]);
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [skills, setSkills] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [loginModal, setLoginModal] = useState(false);
  const [demoVideoModal, setDemoVideoModal] = useState(false);
  const contentRef = useRef(null);
  const urlParams = useParams('/use-advisors/browse/:search/:tag');
  const { tag } = urlParams;
  const [openFilter, setOpenFilter] = React.useState(false);
  const history = useHistory();
  const iFrameSource =
    'https://register.advisorycloud.com/directory/b2b-step-1/';

  const search = (ky, page) => {
    setLoadingComplete(false);
    searchAdvisorByKeyword({
      keyword: ky || keyword,
      start: (page - 1) * 10,
      industryTagIds: industries.map((i) => i.label),
      skillTagIds: skills.map((s) => s.label),
    }).then((response) => {
      if (response.errors) {
        console.log(response.errors);
      } else {
        setPages(Math.ceil(response.data.searchAdvisorByKeyword.found / 10));
        setAdvisors(response.data.searchAdvisorByKeyword.data);
      }
      setLoadingComplete(true);
    });
  };

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    if (parsed.keyword) {
      setKeyword(parsed.keyword);
      search(parsed.keyword, currentPage);
    } else if (tag) {
      setKeyword(tag);
      search(tag, currentPage);
    } else {
      history.push(`/use-advisors/browse`);
    }
  }, []);

  function register() {
    setLoginModal(true);
  }

  const handleChange = (event, value) => {
    setCurrentPage(value);
    search(keyword, value);
  };

  function handleKeywordInput(event) {
    setKeyword(event.target.value);
  }

  function enterOnSearchBar(event) {
    if (event.keyCode === 13) {
      search();
    }
  }

  const handleClickFilter = () => {
    setOpenFilter(!openFilter);
  };

  const endFilter = () => {
    return (
      <InputAdornment position="end">
        <Button
          variant="outlined"
          sx={classes.searchButton}
          onClick={() => search()}
        >
          {t('SEARCH')}
        </Button>
        {isSM ? (
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={handleClickFilter}
          >
            <FilterAltIcon />
          </IconButton>
        ) : null}
      </InputAdornment>
    );
  };

  const IconSearch = () => {
    return (
      <InputAdornment position="start">
        <SearchIcon />
      </InputAdornment>
    );
  };

  const scrollToInput = () => {
    if (contentRef.current) {
      contentRef.current.scrollTo({
        top: 190,
        behavior: 'smooth',
      });
    }
  };

  return !loadingComplete ? (
    <div style={classes.companyContainer}>
      <div style={classes.loadingContainer}>
        <LoadingIndicatorLogo size={200} iconFontSize={93} iconRight={105} />
      </div>
    </div>
  ) : (
    <>
      <AdvisorSearchTopMenu />
      {tag ? (
        <>
          <Typography sx={classes.title} variant="h1">
            {t('ADVISOR-PUBLIC-SEARCH-TITLE', {
              keyword,
            })}
          </Typography>
        </>
      ) : (
        <>
          <Typography sx={classes.title} variant="h1">
            {t('FIND-THE-RIGHT-ADVISORS')}
          </Typography>
        </>
      )}
      <Typography sx={classes.subtitle} variant="h3">
        {t('ADVISOR-PUBLIC-SEARCH-DESCRIPTION')}
      </Typography>
      <Grid container spacing={2} sx={classes.whiteBackground}>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <AdvisorSearchExpertiseFilter />
        </Grid>
        <Grid item xs={12} sm={12} md={7} lg={8}>
          <div style={classes.mainContainer}>
            {!tag ? (
              <Paper sx={classes.searchboxContainer} elevation={0}>
                <Box sx={classes.keywordsContainer} className="searchBox">
                  <TextField
                    onKeyDown={enterOnSearchBar}
                    placeholder={t('SEARCH-BY-KEYWORDS')}
                    variant="standard"
                    sx={classes.searchboxContainerInput}
                    value={keyword}
                    onChange={handleKeywordInput}
                    startAdornment={IconSearch()}
                    InputProps={{
                      disableUnderline: true,
                      startAdornment: IconSearch(),
                      endAdornment: endFilter(),
                      style: { fontFamily: 'Poppins' },
                    }}
                  />
                </Box>
                {!isSM ? (
                  <div style={classes.expertiseFilters}>
                    <Autocomplete
                      options={tags.skills || []}
                      value={skills}
                      multiple
                      disableCloseOnSelect
                      getOptionLabel={(options) => options.label}
                      sx={classes.expertiseFiltersInput}
                      onChange={(e, value) => {
                        setSkills(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Filter Skills"
                          size="small"
                          InputProps={{
                            ...params.InputProps,
                            style: { height: '40px' },
                          }}
                        />
                      )}
                      renderTags={() => (
                        <Chip
                          label={`(${skills.length}) skills selected `}
                          sx={{
                            margin: '0',
                            padding: '0',
                            backgroundColor: '#D7F1FD',
                            maxHeight: '28px',
                          }}
                        />
                      )}
                    />
                    <Autocomplete
                      options={tags.industries || []}
                      value={industries}
                      multiple
                      disableCloseOnSelect
                      getOptionLabel={(options) => options.label}
                      onChange={(e, value) => {
                        setIndustries(value);
                      }}
                      sx={classes.expertiseFiltersInput}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Filter Industries"
                          size="small"
                          InputProps={{
                            ...params.InputProps,
                            style: { height: '40px' },
                          }}
                        />
                      )}
                      renderTags={() => (
                        <Chip
                          label={`(${industries.length}) industries selected `}
                          sx={{
                            margin: '0',
                            padding: '0',
                            backgroundColor: '#D7F1FD',
                            maxHeight: '28px',
                          }}
                        />
                      )}
                    />
                  </div>
                ) : (
                  <Collapse in={openFilter} timeout="auto" unmountOnExit>
                    <div style={classes.expertiseFilters} ref={contentRef}>
                      <CustomAutocomplete
                        items={tags.skills}
                        selectedItemsProp={skills}
                        placeholder="Skills"
                        onItemsChange={setSkills}
                        onSearch={search}
                        scrollToInput={scrollToInput}
                      />
                      <CustomAutocomplete
                        items={tags.industries}
                        selectedItemsProp={industries}
                        placeholder="Industry"
                        onItemsChange={setIndustries}
                        onSearch={search}
                        scrollToInput={scrollToInput}
                      />
                    </div>
                  </Collapse>
                )}
              </Paper>
            ) : null}
            {advisors.map((adv) => (
              <AdvisorCardPublicSearch
                key={adv.id}
                skills={skills}
                advisor={adv}
                setLoginModal={setLoginModal}
              />
            ))}
            {advisors.length === 0 ? (
              <Paper sx={classes.searchboxContainer} elevation={0}>
                <div style={classes.noRecord}>
                  <Typography sx={classes.noRecordTitle} variant="h1">
                    {t('ADVISOR-PUBLIC-SEARCH-NO-RESULTS-FOUND')}
                  </Typography>
                  <div>
                    <img
                      style={classes.noRecordImages}
                      src={NoRecordImages}
                      alt=""
                    />
                  </div>
                </div>
              </Paper>
            ) : (
              <div style={classes.paginationContainer}>
                <Pagination
                  count={pages}
                  page={currentPage}
                  onChange={handleChange}
                />
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={2}>
          <Paper sx={classes.filterContainer} elevation={0}>
            <div>
              <img style={classes.sidebar} src={Sidebar} alt="" />
            </div>
            <Typography sx={classes.registerContainerTitle}>
              {t('BUILD-AN-ADVISORY-BOARD')}
            </Typography>
            <Typography sx={classes.registerContainerDescription}>
              {t('BUILD-AN-ADVISORY-BOARD-DESCRIPTION')}
            </Typography>
            <div style={classes.actionsContainer}>
              <Button
                variant="outlined"
                onClick={() => register()}
                sx={classes.viewProfileButton}
              >
                {t('START-FOR-FREE')}
              </Button>
              <Button
                variant="text"
                onClick={() => setDemoVideoModal(true)}
                style={{
                  fontFamily: 'Poppins',
                }}
                endIcon={<MonitorIcon sx={classes.iconInButton} />}
              >
                {t('SEE-IN-ACTION')}
              </Button>
            </div>
          </Paper>
        </Grid>
      </Grid>

      <IframeEmbedModal
        openModal={loginModal}
        setOpenModal={setLoginModal}
        iframeEmbedLink={iFrameSource}
      />
      <DemoVideoModal
        loginModal={demoVideoModal}
        setLoginModal={setDemoVideoModal}
      />
    </>
  );
}

export default AdvisorPublicSearch;
