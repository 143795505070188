import React, { useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import eventBus, { eventBusValues } from '../../eventBus';
import { useAccount } from '../../contexts/Account';
import ConversationLeftMenu from './leftMenu';
import ConversationContainer from './Container';
import ConversationMobileMenu from '../Company/Menu/companymobile';
import CompanyUpperBar from '../Company/UpperBar';
import CompanyTopMenuMobile from '../Company/TopMenu/mobile';
import AdvisorUpperBar from '../Advisor/UpperBar';
import CompanyRightMenu from '../Company/RightMenu/index';
import { AccountType } from '../../services/utils/types';
import FreeTrialBanner from '../FreeTrialBanner';
import AdvisorRightMenu from '../Advisor/RightMenu/indexSplit';

const useStyles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flex: 1,
  },
  screenContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 'auto',
  },
});

function Conversation() {
  const classes = useStyles();
  const { conversations, isFreeTrial, accountDetails, type } =
    useAccount();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'), {
    noSsr: true,
  });
  const [showPostWrapperContent, setShowPostWrapperContent] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    if (!isSM) {
      if (!queryParams.get('conversationId') && !queryParams.get('senderId')) {
        if (conversations && conversations[0]) {
          history.replace(
            `/messages?conversationId=${conversations[0].conversationId}`
          );
        }
      }
    }
  }, []);

  function clickPostButton() {
    setShowPostWrapperContent(true);
  }

  function handleMenuClick() {
    eventBus.dispatch(eventBusValues.mobileMenuTrigger);
  }

  return (
    <>
      {isSM && type === AccountType.ADVISOR ? (
        <AdvisorRightMenu
          onDismiss={() => {
            eventBus.dispatch(eventBusValues.mobileMenuTrigger);
          }}
          accountDetails={accountDetails}
        />
      ) : null}
      {isFreeTrial && type == AccountType.ADVISOR && (
        <FreeTrialBanner show={isFreeTrial} />
      )}
      <div style={classes.container}>
        {type == AccountType.ADVISOR && (
          <ConversationLeftMenu />
        )}
        <div style={classes.content}>
          {type === AccountType.ADVISOR ? (
            <AdvisorUpperBar onAvatarMenuIconCLick={handleMenuClick} />
          ) : (
            <>
              <CompanyTopMenuMobile onMenuIconClick={handleMenuClick} />
              <CompanyUpperBar
                showPostPopUp={clickPostButton}
                showPostWrapperContent={showPostWrapperContent}
              />
              <ConversationMobileMenu />
              <CompanyRightMenu
                onDismiss={() => {
                  eventBus.dispatch(eventBusValues.mobileCompanyMenuTrigger);
                }}
                accountDetails={accountDetails}
              />
            </>
          )}
          <div style={classes.screenContainer}>
            <ConversationContainer />
          </div>
        </div>
      </div>
    </>
  );
}

export default Conversation;
