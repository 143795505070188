import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Box } from '@mui/material';

export default function BoardSkeleton() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #E6EDFF',
        borderRadius: '10px',
        backgroundColor: '#FFFFFF',
        padding: '25px',
        width: '100%',
        maxWidth: '360px',
        height: '361px',
      }}
    >
      <Skeleton
        animation="wave"
        variant="rounded"
        height={21}
        sx={{
          backgroundColor: '#F5F7FA',
          marginBottom: '20px',
          borderRadius: '100px',
          width: '100%',
          maxWidth: '109px',
          alignSelf: 'flex-end',
        }}
      />
      <Skeleton
        animation="wave"
        variant="circular"
        width={116}
        height={116}
        sx={{
          backgroundColor: '#F5F7FA',
          marginBottom: '20px',
          alignSelf: 'center',
        }}
      />

      <Skeleton
        animation="wave"
        variant="rounded"
        height={34}
        sx={{
          backgroundColor: '#F5F7FA',
          marginBottom: '20px',
          borderRadius: '100px',
          width: '100%',
          maxWidth: '201px',
          alignSelf: 'center',
        }}
      />
      <Skeleton
        animation="wave"
        variant="rounded"
        height={21}
        sx={{
          backgroundColor: '#F5F7FA',
          marginBottom: '10px',
          borderRadius: '100px',
          width: '100%',
        }}
      />
      <Skeleton
        animation="wave"
        variant="rounded"
        height={21}
        sx={{
          backgroundColor: '#F5F7FA',
          marginBottom: '10px',
          borderRadius: '100px',
          width: '100%',
        }}
      />
      <Skeleton
        animation="wave"
        variant="rounded"
        height={21}
        sx={{
          backgroundColor: '#F5F7FA',
          marginBottom: '10px',
          borderRadius: '100px',
          width: '100%',
        }}
      />
    </Box>
  );
}
