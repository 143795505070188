import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Paper, Divider } from '@mui/material';
import {
  Launch,
  FavoriteBorder,
  AddCircleOutlineRounded,
  HelpOutlineRounded,
  TrendingUp,
} from '@mui/icons-material';
import { useAccount } from './../../../contexts/Account';
import eventBus, { eventBusValues } from './../../../eventBus';
import { ReactComponent as Rocket } from '../../../images/charm-rocket.svg';

function getTopValue({ isFreeTrial, showSmallBanner }) {
  if (isFreeTrial) return '95px';
  if (showSmallBanner) return '70px';
  return '0px';
}

const useStyles = ({ isFreeTrial, showSmallBanner }) => ({
  container: {
    width: '340px',
    height: '340px',
    backgroundColor: 'transparent',
    position: 'absolute',
    zIndex: 2,
    top: getTopValue({ isFreeTrial, showSmallBanner }),
    right: '-10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  menuItemsContainer: {
    width: isFreeTrial ? '275px' : '260px',
    backgroundColor: 'white',
    marginTop: isFreeTrial || showSmallBanner ? '25px' : '65px',
    border: '1px solid #F5F7FA',
    boxShadow: '0px 1px 10px rgba(230, 237, 254, 0.25)',
    borderRadius: '12px',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '20px',
  },
  itemIcon: {
    marginRight: '17.5px',
    fontSize: '23px',
  },
  menuItemRed: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px',
    paddingRight: '20px',
    paddingLeft: '20px',
    color: '#646D7A',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    paddingBottom: '4px',
    paddingTop: '6px',
    marginRight: '-20px',
    marginLeft: '-20px',
    width: '260px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '32px',
    letterSpacing: '0.75px',
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
  menuItemLaunch: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: '#232B35',
    border: 'none',
    backgroundColor: '#D7F1FD',
    cursor: 'pointer',
    margin: '4px 10px 4px -10px',
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '14px',
    letterSpacing: '-0.25px',
    borderRadius: '12px',
    padding: '10px',
    height: '50px',
    textAlign: 'left',
    '&:hover': {
      backgroundColor: '#D7F1FD',
      boxShadow: 'none',
    },
  },
  menuItemLogout: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0px',
    paddingRight: '20px',
    paddingLeft: '20px',
    color: '#646D7A',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    paddingBottom: '4px',
    paddingTop: '6px',
    marginRight: '-20px',
    marginLeft: '-20px',
    width: '260px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '32px',
    letterSpacing: '0.75px',
    '&:hover': {
      color: '#232B35',
    },
  },
  divider: {
    width: '90%',
    marginTop: '10px',
    marginBottom: '10px',
    color: '#F5F7FA',
  },
});

function Menu(props) {
  const { handleAddCompanyContact, hideMenu } = props;
  const {
    accountDetails,
    signOut,
    isFreeTrial,
    currentPlan,
    asLoggedUserDetails,
    isFreemium,
    currentUserInfo,
  } = useAccount();
  const { salesforceValues } = currentPlan;
  const showExFremiumBanner =
    accountDetails.freemiumRegistration &&
    accountDetails.opportunityStage === 'discovery' &&
    !isFreemium;
  const showOnboardingBanner =
    accountDetails.opportunityStage === 'discovery' &&
    accountDetails.onboardingStatus !== 'Not Requisite' &&
    accountDetails.onboardingStatus !== 'Complete' &&
    !accountDetails.freemiumRegistration;
  const showBanner =
    !isFreeTrial &&
    salesforceValues.billingCycle === 'Monthly' &&
    ['Essentials', 'Professional', 'Executive'].includes(salesforceValues.tier);
  const classes = useStyles({
    isFreeTrial,
    showBanner,
    showSmallBanner: showExFremiumBanner || showOnboardingBanner,
  });
  const history = useHistory();
  const { t } = useTranslation();

  function handleMenuItem(url, newTab = false) {
    if (newTab) {
      window.open(url, '_blank');
    } else {
      window.location.replace(url);
    }
  }

  async function onLogoutClick() {
    await signOut();
  }

  function navigateInApp(path) {
    history.push(path);
  }

  function helpCenterClick() {
    const contact = accountDetails.contacts.find(
      (contact) => contact.id === asLoggedUserDetails.userId
    );
    const url = 'https://help.advisorycloud.com/advisory-board-help-center';
    const params = `member_type=company&email=${contact.email}&first_name=${contact.givenName}&last_name=${contact.surName}&sosfcontactid=${contact.salesforceContactId}&recurly_plan_code=${contact.recurlyPlanCode}&membership_tier=${currentPlan.salesforceValues.tier}`;
    window.open(`${url}?${params}`, '_blank');
  }

  function dispatchEvent() {
    eventBus.dispatch(eventBusValues.triggerCompanyDrawerAction, {
      company: accountDetails,
    });
  }

  const handleTeamOpenPopup = () => {
    eventBus.dispatch(eventBusValues.triggerInviteTeamModal);
  };

  function getOptions() {
    return (
      <div style={classes.menuItemsContainer}>
        <button
          style={classes.menuItemRed}
          onClick={dispatchEvent}
          type="button"
          id="navatarViewProfile"
        >
          <Launch sx={classes.itemIcon} />
          {t('VIEW-PROFILE-MENU-ITEM-TEXT')}
        </button>
        <button
          style={classes.menuItemRed}
          onClick={() => {
            helpCenterClick();
          }}
          type="submit"
          id="navatarHelpCenter"
        >
          <HelpOutlineRounded sx={classes.itemIcon} />
          {t('HELP-CENTER-MENU-ITEM-TEXT')}
        </button>
        {accountDetails.contacts[0].upgradeUrl && (
          <button
            style={classes.menuItemRed}
            onClick={() =>
              handleMenuItem(
                `${accountDetails.contacts[0].upgradeUrl}?iwm_advisor_id=${accountDetails.contacts[0].iwmId}&sosfcontactid=${accountDetails.contacts[0].salesforceContactId}&email=${accountDetails.contacts[0].email}&plancode=${accountDetails.contacts[0].recurlyPlanCode}`,
                true
              )
            }
            type="submit"
          >
            <TrendingUp sx={classes.itemIcon} />
            {t('UPGRADE-MEMBERSHIP-MENU-ITEM-TEXT')}
          </button>
        )}
        <button
          id="navatarActSettings"
          style={classes.menuItemRed}
          onClick={() => navigateInApp('/account')}
          type="submit"
        >
          <FavoriteBorder sx={classes.itemIcon} />
          Account Settings
        </button>
        <button
          id="navatarInviteTeam"
          style={classes.menuItemRed}
          onClick={handleTeamOpenPopup}
          type="submit"
        >
          <AddCircleOutlineRounded sx={classes.itemIcon} />
          Invite your team
        </button>
        {isFreeTrial && (
          <button
            id="navatarLaunchAdvisoryCloud"
            style={classes.menuItemLaunch}
            onClick={() => navigateInApp('/upgrade/b2b_ft')}
            type="submit"
          >
            <Rocket style={classes.itemIcon} />
            Launch Your Advisory Board
          </button>
        )}

        <Divider sx={classes.divider} />

        <button
          id="navatarLogout"
          style={classes.menuItemLogout}
          type="submit"
          onClick={onLogoutClick}
        >
          {t('LOGOUT-MENU-ITEM-TEXT')}
        </button>
      </div>
    );
  }

  return (
    <Paper sx={classes.container} elevation={0}>
      {getOptions()}
    </Paper>
  );
}
export default Menu;
