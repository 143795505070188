import React from 'react';
import { useTranslation } from 'react-i18next';
import IconUnderlay from '../IconUnderlay';
import { Paper, Typography, useMediaQuery } from '@mui/material';
import { PriorityHighOutlined, RefreshOutlined } from '@mui/icons-material';

const useStyles = (isSM) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: isSM ? '100%' : '580px',
    alignItems: 'center',
    padding: '30px 20px',
  },
  titleContainer: {
    maxWidth: '360px',
    marginTop: '20px',
    marginBottom: '40px',
    textAlign: 'center',
  },
  retryButton: {
    backgroundColor: 'transparent',
    border: 'none',
    display: 'flex',
    flexDirection: 'row',
    width: '80px',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  retryText: {
    marginLeft: '5px',
    color: 'primary.main',
  },
  priorityIcon: {
    fontSize: '60px',
  },
});

function UnhandledError({ onRetry, isAbleToRetry }) {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const { t } = useTranslation();

  function renderRetryButton() {
    if (isAbleToRetry) {
      return (
        <button onClick={onRetry} style={classes.retryButton} type="submit">
          <RefreshOutlined color="primary" />
          <Typography
            color="textSecondary"
            variant="h3_old_font"
            sx={classes.retryText}
          >
            {t('RETRY-BUTTON-TEXT')}
          </Typography>
        </button>
      );
    }

    return null;
  }

  return (
    <Paper
      data-cy="unhandled-error-card"
      sx={classes.container}
      elevation={isSM ? 0 : 2}
    >
      <IconUnderlay size={80}>
        <PriorityHighOutlined color="error" sx={classes.priorityIcon} />
      </IconUnderlay>

      <div style={classes.titleContainer}>
        <Typography
          color="textSecondary"
          variant={isSM ? 'h1_old_font' : 'h2_old_font'}
        >
          {t(
            isAbleToRetry
              ? 'UNHANDLED-RETRY-ERROR-TEXT'
              : 'UNHANDLED-ERROR-TEXT'
          )}
        </Typography>
      </div>
      {renderRetryButton()}
    </Paper>
  );
}

export default UnhandledError;
