import React from 'react';
import { ReactComponent as SendSVG } from './send.svg';
import { ReactComponent as StarSVG } from './star.svg';

const CircleSVGComponent = ({ text, icon }) => {
  return (
    <div style={{ width: '100%', textAlign: 'center', fontFamily: 'Poppins' }}>
      <div
        style={{
          margin: '0 auto',
          marginTop: '60px',
          width: '40px',
          height: '40px',
        }}
      >
        <div
          style={{
            height: '50px',
            width: '50px',
            backgroundColor: '#dbf7eb',
            borderRadius: '50%',
          }}
        >
          {icon === 'star' && (
            <StarSVG
              style={{
                marginLeft: '-14.5px',
                marginTop: '2px',
                height: '43px',
                width: '43px',
              }}
            />
          )}
          {icon === 'send' && (
            <SendSVG
              style={{
                marginLeft: '-22.5px',
                marginTop: '6px',
                height: '43px',
                width: '43px',
              }}
            />
          )}
        </div>
      </div>
      <p
        style={{
          fontFamily: 'Poppins',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '28px',
          lineHeight: '40px',
          letterSpacing: '-0.43px',
          color: '#232B35',
          marginTop: '20px',
        }}
      >
        {text}
      </p>
    </div>
  );
};

export default CircleSVGComponent;
