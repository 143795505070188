import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ACSnackbar from '../../components/ACSnackbar';
import { useMediaQuery } from '@mui/material';
import UsageForm from '../../components/Company/UsageForm';
import PublicRegistration from '../../layouts/PublicRegistration';
import { updateCompanyPlannedUsage } from '../../services/company_services';
import { useAccount } from '../../contexts/Account';

const CompanyOnboardingFreemiumStep1 = () => {
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const history = useHistory();
  const { asLoggedUserDetails } = useAccount();
  const [error, setError] = useState('Something went wrong');
  const [showSnackbar, setShowSnackbar] = useState(false);

  const onSubmit = async (selectedOption) => {
    try {
      let data = {
        CONTACT_ID: asLoggedUserDetails.userId,
        PLANNED_USAGE: selectedOption,
      };

      await updateCompanyPlannedUsage(data);
      history.push('/company/new/step2');
    } catch (error) {
      console.log(error);
      setShowSnackbar(true);
    }
  };

  return (
    <>
      <ACSnackbar
        onClose={() => setShowSnackbar(false)}
        open={showSnackbar}
        severity="error"
        text={error}
      />
      <PublicRegistration hideImage={isXS}>
        <UsageForm onSubmit={onSubmit} />
      </PublicRegistration>
    </>
  );
};

export default CompanyOnboardingFreemiumStep1;
