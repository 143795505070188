import { useMemo } from 'react';

export const useBoardOwner = (contacts) => {
  return useMemo(() => {
    if (!contacts || contacts.length === 0) {
      return null;
    }

    // Filtrar contactos que son boardOwners
    const boardOwners = contacts.filter((contact) => contact.isBoardOwner);

    // Ordenar los boardOwners por fecha de creación de más antiguo a más reciente
    boardOwners.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

    // Devolver el boardOwner más antiguo o null si no hay ninguno
    return boardOwners.length > 0 ? boardOwners[0] : null;
  }, [contacts]);
};
