import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  Slide,
  Typography,
  useMediaQuery,
  Avatar,
} from '@mui/material';
import { Clear } from '@mui/icons-material';
import ACButton from './../../ACButton';

const useStyles = (isSM) => ({
  dialogContainer: {
    borderRadius: isSM ? '0px' : '5px',
    boxShadow: '0 5px 20px rgba(0, 0, 0, 0.07)',
    padding: isSM ? '5px' : '30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    maxWidth: '40rem',
  },
  header: {
    textAlign: 'center',
    margin: isSM ? '0px' : '3em 5em 0em 5em',
    padding: isSM ? '5px 30px 0 30px' : '0px',
  },
  textHeadline: {
    textAlign: 'center',
    fontFamily: 'Open Sans',
    fontWeight: '800',
    fontSize: '24px',
    padding: '0 25px 25px 25px',
  },
  clearIcon: {
    margin: '0px 5px 0 5px',
    float: 'right',
    cursor: 'pointer',
    alignSelf: 'end',
  },
  imageContainer: {
    width: isSM ? '150px' : '120px',
    height: isSM ? '150px' : '120px',
    marginTop: isSM ? '10px' : '0px',
    marginBottom: isSM ? '20px' : '0px',
    boxShadow: '1px 2px 5px #bebebe',
    border: `solid 2px white`,
  },
  contactImageContainer: {
    width: '80px',
    height: '80px',
    marginRight: '15px',
  },
  logoContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    transform: isSM ? null : 'translateY(-15.5rem)',
    position: isSM ? 'static' : 'fixed',
    overflowY: 'visible',
  },
  buttonBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '2em',
    marginBottom: '3em',
    width: '100%',
  },
  contactContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mainButton: {
    borderWidth: '4px',
    padding: '15px 50px 15px 50px',
    textTransform: 'none',
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 'bold',
    fontSize: '14px',
    height: '45px',
    borderRadius: '5px',
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function WelcomePopUp({
  postJoinModal,
  setPostJoinModal,
  company,
  companyContact,
  advisor,
  isAcPlus,
}) {
  const { t } = useTranslation();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);

  function handleClose() {
    setPostJoinModal(false);
  }

  if (!company || !companyContact || isAcPlus) return null;
  return (
    <Dialog
      open={postJoinModal}
      TransitionComponent={Transition}
      onClose={handleClose}
      maxWidth="md"
      fullScreen={isSM}
      PaperProps={{
        sx: classes.dialogContainer,
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      {isSM ? (
        <div style={classes.clearIcon}>
          <Clear onClick={handleClose} />
        </div>
      ) : null}
      <div style={classes.logoContainer}>
        <Avatar
          src={company.image ? `https://${company.image.location}` : false}
          sx={{ ...classes.imageContainer, width: '44px', height: '44px' }}
        />
      </div>
      <div style={classes.header}>
        <Typography variant="h1_old_font" sx={classes.textHeadline}>
          {t('FIRST-WELCOME-TITLE', {
            advisorName: advisor ? advisor.givenName : '',
          })}
        </Typography>
        <Typography
          variant="body1_old_font"
          style={{
            fontSize: '1rem',
            marginBottom: '1.25rem',
          }}
        >
          {t('FIRST-WELCOME-TEXT')}
        </Typography>
        <div style={classes.contactContainer}>
          <Avatar
            sx={classes.contactImageContainer}
            src={
              companyContact.image
                ? `https://${companyContact.image.location}`
                : false
            }
          />
          <div>
            <Typography
              variant="body1_old_font"
              style={{
                fontSize: '1.25rem',
                textAlign: 'start',
              }}
            >
              {companyContact.displayName}
            </Typography>
            <Typography
              variant="body1_old_font"
              style={{
                fontSize: '1rem',
                marginBottom: '1rem',
                fontWeight: 600,
                textAlign: 'start',
              }}
            >
              {`${companyContact.isBoardOwner ? 'CEO, ' : ''}${
                company.displayName
              }`}
            </Typography>
          </div>
        </div>
      </div>
      <div style={classes.buttonBox}>
        <ACButton
          color="primary"
          size="large"
          variant="contained"
          id="welcomePopUpLgs"
          style={classes.mainButton}
          onClick={() => setPostJoinModal(false)}
        >
          {t('FIRST-WELCOME-BUTTON')}
        </ACButton>
      </div>
    </Dialog>
  );
}
