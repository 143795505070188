import React from 'react';
import { useLocation } from 'react-router-dom';
import { Paper, Typography, useMediaQuery } from '@mui/material';
import { useAccount } from '../../../contexts/Account';

const getClasses = ({ under1260, under1190 }) => ({
  tile: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    flex: 1,
    height: '240px',
    borderRadius: '16px',
    padding: '20px',
    cursor: 'pointer',
    backgroundColor: '#F5F7FA',
    marginLeft: '20px',
  },
  title: {
    fontWeight: 600,
    fontSize: { sm: '21px', md: '24px' },
    color: '#232B35',
  },
  body: {
    margin: under1260 ? '8px 0px' : '30px 0px',
    margin: { sm: '8px 0px', md: '20px 0px' },
    fontWeight: 400,
    fontSize: under1190 ? '16px' : '18px',
    color: '#232B35',
  },
  cta: {
    fontWeight: 400,
    fontSize: '18px',
    fontSize: under1190 ? '16px' : '18px',
    color: '#232B35',
  },
});

function DescriptionTile() {
  const location = useLocation();
  const under1260 = useMediaQuery((theme) => theme.breakpoints.down([1260]));
  const under1190 = useMediaQuery((theme) => theme.breakpoints.down([1190]));
  const under1120 = useMediaQuery((theme) => theme.breakpoints.down([1120]));
  const { isFreemium } = useAccount();
  const classes = getClasses({ under1260, under1190, under1120 });
  function getTitle() {
    if (location.pathname === '/dashboard')
      return `Welcome to your ${isFreemium ? 'AdvisoryCloud' : 'Boardroom'}`;
    if (location.pathname.startsWith('/advisors'))
      return 'Search and connect with Advisors';
    return 'Schedule moderated board meetings';
  }

  function getBody() {
    if (location.pathname === '/dashboard')
      return 'Take a quick tour to learn how to get the most from your experience on AdvisoryCloud';
    if (location.pathname.startsWith('/advisors'))
      return `See how easy it is to search, save, and invite new advisors to your ${
        isFreemium ? 'AdvisoryCloud' : 'boardroom'
      }.`;
    if (location.pathname.startsWith('/board-meetings'))
      return 'Board meetings are one of the best ways to get real-time advice in your boardroom.';
    return "Watch this quick video to see how much value you'll get from moderated board meetings.";
  }

  function getCTA() {
    if (location.pathname === '/dashboard') return 'Take the tour';
    if (location.pathname.startsWith('/advisors')) return 'Get Started';
    if (location.pathname.startsWith('/board-meetings')) return 'Learn more';
    return 'Watch the video';
  }

  function getId() {
    let text = 'b2bReactiveCard';
    if (location.pathname === '/dashboard') return `${text}Dashboard`;
    if (location.pathname.startsWith('/advisors')) return `${text}Advisors`;
    return `${text}Meetings`;
  }

  return (
    <Paper elevation={0} sx={classes.tile} id={getId()}>
      <Typography sx={classes.title}>{getTitle()}</Typography>
      <Typography sx={classes.body}>{getBody()}</Typography>
      {!under1120 && <Typography sx={classes.cta}>{getCTA()}</Typography>}
    </Paper>
  );
}
export default DescriptionTile;
