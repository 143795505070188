import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Typography, useMediaQuery, Grid } from '@mui/material';
import { useAccount } from '../../contexts/Account';
import CompanyStepper from '../../components/Company/Onboarding/CompanyStepper';
import Step2Form from '../../components/Company/Onboarding/Step2Form';
import CardScene from '../../components/CardScene';

const useStyles = (isSM) => ({
  formContainer: {
    paddingTop: '30px',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingBottom: '20px',
    backgroundColor: isSM && 'white',
  },
  stepSubtitle: {
    marginBottom: '5px',
    marginTop: '15px',
  },
  stepTitle: {
    color: 'primary.main',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
});

function step2(props) {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const { accountDetails } = useAccount();
  const { t } = useTranslation();
  const location = useLocation();
  const isCompanyContact =
    location.pathname === '/company-member/onboarding/profile';

  const stepperHeader = () => {
    return (
      <div style={classes.headerContainer}>
        <Grid container direction="column">
          <Grid item>
            <Typography
              component="div"
              sx={classes.stepTitle}
              variant="h5"
              align="center"
            >
              {isCompanyContact
                ? t('STEP2-COMPANY-CONTACT-TITLE')
                : t('STEP2-TITLE')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              display="block"
              component="div"
              sx={classes.stepSubtitle}
              variant="h1"
              align="center"
            >
              {isCompanyContact
                ? t('STEP2-COMPANY-CONTACT-HELMET-SUBTITLE', {
                    companyName: accountDetails.companyName,
                  })
                : t('STEP2-SUBTITLE')}
            </Typography>
          </Grid>
          {!isCompanyContact ? (
            <>
              <CompanyStepper activeStep={1} />
            </>
          ) : null}
        </Grid>
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>
          {isCompanyContact
            ? t('STEP2-COMPANY-CONTACT-HELMET-TITLE')
            : t('STEP2-HELMET-TITLE')}
        </title>
      </Helmet>

      <CardScene header={stepperHeader()}>
        <div style={classes.formContainer}>
          <Step2Form {...props} isSM={isSM} isCompanyContact/>
        </div>
      </CardScene>
    </>
  );
}

export default step2;
