import React, { useState, useRef, useEffect } from 'react';
import { useMediaQuery, Paper } from '@mui/material';
import PostCardBottom from './../CardBottom';
import AnswerHeader from './AnswerHeader';
import AnswerBody from './AnswerBody';
import useSavedAdvisor from './../../../hooks/useSavedAdvisor';

const useStyles = () => ({
  card: {
    width: '100%',
    borderRadius: '0px',
    display: 'flex',
    flexDirection: 'column',
    height: 'fit-content',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    marginBottom: '5px',
  },
  mainContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: 'fit-content',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    padding: '18px',
    background: '#F0F4F8',
    boxShadow: '0px 0px 72px rgba(0, 0, 0, 0.04)',
    borderRadius: '12px',
  },
  cardHighlighted: {
    width: '100%',
    borderRadius: '0px',
    display: 'flex',
    flexDirection: 'column',
    height: 'fit-content',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    backgroundColor: 'transparent',
  },
  separator: {
    marginBottom: '15px',
    width: '100%',
  },
});

const AnswerCard = ({
  // body
  body,
  // likes
  likes,
  likesClick,
  likesFilled,
  likesDisabled,
  // comments
  comments,
  commentsClick,
  commentsFilled,
  commentsBadge,
  commentsBadgeContent,
  commentsDisabled,
  // breakthroughs
  breakthroughs,
  breakthroughsClick,
  breakthroughsFilled,
  breakthroughsGold,
  breakthroughsBold,
  breakthroughsDisabled,
  // date and preview format
  createdAt,
  // avatars & others
  avatarClick,
  answerUrl,
  answerName,
  subtitle,
  answerAvatar,
  isOwner,
  answerBodyClick,
  answerBodyDisabled,
  isStar,
  userId,
  updateValue,
  ProfileHighlighterType,
  isHighlighted,
}) => {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);
  const [isSaved, saveAdvisor] = useSavedAdvisor(userId);

  const ref = useRef();
  function toggleEditing() {
    setIsEditing(!isEditing);
  }

  useEffect(() => {
    if (isHighlighted) {
      ref.current.scrollIntoView();
      window.scrollBy(0, -100);
    }
  }, []);

  return (
    <Paper
      ref={ref}
      elevation={0}
      sx={isHighlighted ? classes.cardHighlighted : classes.card}
    >
      <div style={classes.mainContainer}>
        <div style={{ ...classes.separator, width: '100%' }}>
          <AnswerHeader
            headerName={answerName}
            subtitle={subtitle}
            answerAvatar={answerAvatar}
            isOwner={isOwner}
            onEditClick={toggleEditing}
            isStar={isStar}
            isStarFilled={isSaved}
            starClicked={saveAdvisor}
            ProfileHighlighterType={ProfileHighlighterType}
            answerUrl={answerUrl}
            avatarClick={avatarClick}
          />
        </div>
        <div style={{ width: '100%' }}>
          <AnswerBody
            body={body}
            initialTime={createdAt}
            bodyDisabled={answerBodyDisabled}
            bodyClick={answerBodyClick}
            editing={isEditing}
            setEditFalse={toggleEditing}
            updateValue={updateValue}
          />
        </div>
      </div>
      {isEditing ? null : (
        <div style={{ marginTop: 8, marginLeft: 10 }}>
          <PostCardBottom
            size={isSM ? 'xs' : 'sm'}
            // likes
            likes={likes}
            likesClick={likesClick}
            likesFilled={likesFilled}
            likesDisabled={likesDisabled}
            // comments
            comments={comments}
            commentsClick={commentsClick}
            commentsFilled={commentsFilled}
            commentsBadge={commentsBadge}
            commentsBadgeContent={commentsBadgeContent}
            commentsDisabled={commentsDisabled}
            // breakthroughs
            breakthroughs={breakthroughs}
            breakthroughsClick={breakthroughsClick}
            breakthroughsFilled={breakthroughsFilled}
            breakthroughsGold={breakthroughsGold}
            breakthroughsBold={breakthroughsBold}
            breakthroughsDisabled={breakthroughsDisabled}
          />
        </div>
      )}
    </Paper>
  );
};

export default AnswerCard;
