import React, { useEffect, useState, useCallback } from 'react';
import { Box } from '@mui/material';

const useStyles = (props) => ({
  backgroundContainer: {
    backgroundColor: props.underlayColor
      ? props.underlayColor
      : 'theme.palette.secondary.light',
    borderRadius: '50%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  margin: {
    position: 'absolute',
    display: 'flex',
  },
});

export default function IconUnderlay(props) {
  const { children, id, onMount, size } = props;
  const [newSize, setNewSize] = useState(() => {
    return changeSize(size, children.props.fontSize);
  });

  useEffect(() => {
    setNewSize(changeSize(size, children.props.fontSize));
  }, [size, children.props.fontSize]);

  function isNil(value) {
    return value === undefined || value === null ? true : false;
  }

  const classes = useStyles(props);

  function changeSize(size, fontSize) {
    if (!isNil(size) && !isNaN(size)) {
      return parseInt(size);
    } else if (fontSize === 'large') {
      return 80;
    } else if (fontSize === 'small') {
      return 46;
    } else {
      return 50;
    }
  }

  const handleReference = useCallback((ref) => {
    if (onMount) {
      const data = { ref };

      if (id) {
        data.id = id;
      }

      onMount(data);
    }
  });

  return (
    <Box ref={handleReference}>
      <Box
        sx={classes.backgroundContainer}
        style={{
          width: newSize,
          height: newSize,
        }}
      >
        <Box sx={classes.margin}>{children}</Box>
      </Box>
    </Box>
  );
}
