import React from "react";
import { Paper, Box, Typography, Button, Grid } from "@mui/material";

const BoardCard = ({ board }) => {
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Paper
        elevation={1}
        sx={{
          height: "300px", // Altura fija para las tarjetas
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: "16px",
          borderRadius: "12px",
          backgroundColor: "#f9fbff",
          textAlign: "center",
        }}
      >
        {/* Avatar Placeholder */}
        <Box
          sx={{
            height: "50px",
            width: "50px",
            margin: "0 auto 16px",
            backgroundColor: "#dbeafe",
            borderRadius: "50%",
          }}
        ></Box>

        {/* Contenido */}
        <Box>
          <Typography sx={{ fontSize: "16px", fontWeight: "bold", marginBottom: "8px" }}>
            {board.name}
          </Typography>
          <Typography sx={{ fontSize: "14px", color: "#6b7280", marginBottom: "16px" }}>
            Member Since: {board.memberSince}
          </Typography>
          <Typography sx={{ fontSize: "14px", color: "#6b7280", marginBottom: "16px" }}>
            {board.description}
          </Typography>
        </Box>

        {/* Botón */}
        <Button
          variant="outlined"
          sx={{
            textTransform: "none",
            fontSize: "14px",
            padding: "6px 12px",
          }}
        >
          View
        </Button>
      </Paper>
    </Grid>
  );
};

export default BoardCard;
