import { createTheme } from '@mui/material';

export const ACTheme = createTheme({
  palette: {
    primary: {
      light: '#E6EDFF',
      main: '#0F78FD',
      dark: '#1169B4',
    },
    secondary: {
      light: '#F5F7FA',
      main: '#C9C9DB',
      dark: '#767680',
    },
    info: {
      light: '#BDD2F0',
      main: '#263250',
      dark: '#162F4B',
    },
  },
  typography: {
    fontFamily: ['Poppins', 'Open-Sans', 'Montserrat', 'Roboto'].join(','),
    h1: {
      fontSize: '1rem',
      fontWeight: 400,
    },
    h1_old_font: {
      fontSize: '1rem',
      fontWeight: 400,
      fontFamily: ['Poppins', 'Open-Sans', 'Montserrat', 'Roboto'].join(','),
    },
    h2: {
      fontSize: '1.1176rem',
      fontWeight: 500,
    },
    h2_old_font: {
      fontSize: '1.1176rem',
      fontWeight: 500,
      fontFamily: ['Poppins', 'Open-Sans', 'Montserrat', 'Roboto'].join(','),
    },
    h3: {
      fontSize: '0.875rem',
      fontWeight: 500,
    },
    h3_old_font: {
      fontSize: '0.875rem',
      fontWeight: 500,
      fontFamily: ['Poppins', 'Open-Sans', 'Montserrat', 'Roboto'].join(','),
    },
    h4: {
      fontSize: '1.25rem',
      fontWeight: 600,
    },
    h4_old_font: {
      fontSize: '1.25rem',
      fontWeight: 600,
      fontFamily: ['Poppins', 'Open-Sans', 'Montserrat', 'Roboto'].join(','),
    },
    h5: {
      fontSize: '1.875rem',
      fontWeight: 400,
    },
    h5_old_font: {
      fontSize: '1.875rem',
      fontWeight: 400,
      fontFamily: ['Poppins', 'Open-Sans', 'Montserrat', 'Roboto'].join(','),
    },
    h6: {
      fontSize: '3.25rem',
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: '1.375rem',
      fontWeight: 400,
    },
    subtitle1_old_font: {
      fontSize: '1.375rem',
      fontWeight: 400,
      fontFamily: ['Poppins', 'Open-Sans', 'Montserrat', 'Roboto'].join(','),
    },
    subtitle2: {
      fontSize: '1.125rem',
      fontWeight: 400,
    },
    subtitle2_old_font: {
      fontSize: '1.125rem',
      fontWeight: 400,
      fontFamily: ['Poppins', 'Open-Sans', 'Montserrat', 'Roboto'].join(','),
    },
    body1: {
      fontSize: '0.875rem',
      fontWeight: 400,
    },
    body1_old_font: {
      fontSize: '0.875rem',
      fontWeight: 400,
      fontFamily: ['Poppins', 'Open-Sans', 'Montserrat', 'Roboto'].join(','),
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 600,
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 600,
      fontFamily: ['Poppins', 'Open-Sans', 'Montserrat', 'Roboto'].join(','),
    },
    button: {
      fontSize: '0.875rem',
      fontWeight: 500,
    },
    caption: {
      fontSize: '0.75rem',
      fontWeight: 400,
    },
    caption_old_font: {
      fontSize: '0.75rem',
      fontWeight: 400,
      fontFamily: ['Poppins', 'Open-Sans', 'Montserrat', 'Roboto'].join(','),
    },
    old_font: {
      fontFamily: ['Poppins', 'Open-Sans', 'Montserrat', 'Roboto'].join(','),
    },
  },
  breakpoints: {
    values: {
      xxs: 0,
      xs: 600,
      sm: 1060,
      md: 1280,
      lg: 1920,
      xl: 2200,
    },
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          fontFamily: ['Poppins', 'Open-Sans', 'Montserrat', 'Roboto'].join(
            ','
          ),
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        inputProps: {
          style: {
            fontFamily: ['Poppins', 'Open-Sans', 'Montserrat', 'Roboto'].join(
              ','
            ),
          },
        },
      },
    },
    MuiSnackbar: {
      defaultProps: {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        autoHideDuration: 5000,
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          marginBottom: '6px',
          '&:before': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: '#F5F7FA',
          fontWeight: 600,
          fontSize: '17px',
          flexDirection: 'row-reverse',
          textAlign: 'left',
          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
          },
          '& .MuiAccordionSummary-content': {
            marginLeft: '8px',
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          fontSize: '15px',
          lineHeight: '22px',
        },
      },
    },
  },
});
