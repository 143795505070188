import React from 'react';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';

const PermissionRadio = ({
  permission,
  setPermission,
  setHideToAdvisors,
  setHideToContacts,
}) => {
  const handlePermissionChange = (event) => {
    setPermission(event.target.value);

    switch (event.target.value) {
      case 'Board Owner':
        setHideToAdvisors(true);
        setHideToContacts(true);
        break;
      case 'Company Users':
        setHideToAdvisors(true);
        setHideToContacts(false);
        break;
      case 'Company and Advisor Users':
        setHideToAdvisors(false);
        setHideToContacts(false);
        break;
      default:
        break;
    }
  };

  const pickerStyle = {
    color: '#97A3B7',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
  }

  const radioStyle = {
    padding: '7px',
  }

  return (
    <FormControl
      component="fieldset"
      sx={{
        marginLeft: '25px',
      }}
    >
      <FormLabel component="legend">Permissions</FormLabel>
      <RadioGroup
        aria-label="permission"
        name="permission"
        value={permission}
        onChange={handlePermissionChange}
        sx={{
          flexDirection: 'row',
        }}
      >
        <FormControlLabel
          value="Board Owner"
          control={<Radio sx={radioStyle} />}
          label="Board Owner"
          sx={pickerStyle}
        />
        <FormControlLabel
          value="Company Users"
          control={<Radio />}
          label="Company Users"
          sx={pickerStyle}
        />
        <FormControlLabel
          value="Company and Advisor Users"
          control={<Radio />}
          label="Company and Advisor Users"
          sx={pickerStyle}
        />
      </RadioGroup>
    </FormControl>
  );
};

export default PermissionRadio;
