import React from 'react';
import { Typography, Button, useMediaQuery, Box } from '@mui/material';
import { ReactComponent as Star } from './Star.svg';
import { ReactComponent as Rating } from './Rating.svg';
import eventBus, { eventBusValues } from '../../eventBus';

function ManagedTiles() {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const handleClick = () => {
    eventBus.dispatch(eventBusValues.triggerCompanyUpgradeAction);
  };

  return (
    <Box
      id="mabUpgardeHeader"
      sx={{
        width: '100%',
        height: { xxs: '100%', xs: '326px' },
        position: 'relative',
        overflow: 'hidden',
        borderRadius: '16px',
        marginBottom: '30px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        style={{
          width: '286px',
          height: '286px',
          borderRadius: '50%',
          background: '#DBF7EB59',
          position: 'absolute',
          top: '-130px',
          left: '-30px',
          zIndex: '-1',
        }}
      />
      <Box
        style={{
          width: '300px',
          height: '300px',
          borderRadius: '50%',
          background: '#D7F1FDCC',
          position: 'absolute',
          right: '-120px',
          bottom: '-100px',
          zIndex: '-1',
        }}
      />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '20px',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Poppins',
            fontSize: { xxs: '28px', xs: '36px' },
            fontWeight: 700,
            lineHeight: '45px',
            letterSpacing: '-0.43px',
            textAlign: 'center',
            maxWidth: '50%',
            marginBottom: '5px',
          }}
        >
          Upgrade to launch your advisory board today
        </Typography>
        <Typography
          sx={{
            marginTop: '10px',
            maxWidth: '90%',
            fontFamily: 'Poppins',
            fontSize: '15px',
            fontWeight: 500,
            lineHeight: '28px',
            letterSpacing: '0.75px',
            textAlign: 'center',
            marginBottom: '20px',
          }}
        >
          Unlock full access on AdvisoryCloud
        </Typography>

        <Box>
          <Button
            onClick={handleClick}
            disableRipple
            variant="contained"
            id='viewUpgradeOptions1'
            style={{
              width: '322px',
              height: '48px',
              borderRadius: '25px',
              fontFamily: 'Poppins',
              fontSize: '16px',
              fontWeight: 700,
              lineHeight: '24px',
              letterSpacing: '-0.43px',
              textAlign: 'left',
              textTransform: 'none',
              boxShadow: 'none',
              background: '#D7F1FD',
              border: '1px solid #4BC6FF',
              color: '#232B35',
            }}
          >
            View upgrade options
          </Button>
        </Box>
        <Typography
          sx={{
            marginTop: '15px',
            maxWidth: { xxs: '80%', xs: '40%', sm: '25%' },
            fontFamily: 'Poppins',
            fontSize: '10px',
            fontWeight: 300,
            lineHeight: '15px',
            letterSpacing: '0.75px',
            textAlign: 'center',
          }}
        >
          By clicking the button, you’ll be able to see pricing and confirm your
          upgrade
        </Typography>
        <Box
          id="TopBanner-WatchVideo"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            //cursor: 'pointer',
          }}
        >
          <Star
            style={{
              height: '14px',
            }}
          />
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: '12px',
              fontWeight: 500,
              lineHeight: '32px',
              letterSpacing: '0.5px',
              textAlign: 'left',
              marginRight: '10px',
            }}
          >
            Trustpilot
          </Typography>
          <Rating
            style={{
              marginRight: '5px',
              height: '14.7px',
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default ManagedTiles;
