import React from 'react';
import { useMediaQuery, Typography, Avatar } from '@mui/material';
import { useAccount } from '../../contexts/Account';

export default function AvatarsInvolved(props) {
  const { accountDetails } = useAccount();
  const newAdvisors = accountDetails.dashboardStats?.lastMonthAdvisors;
  const { avatars, noText, avatarSize, textStyle, limit } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const cleanAvatars = avatars.filter((ava) => ava);

  function getSliceValue() {
    if (limit) {
      return limit;
    }
    if (newAdvisors > 4) {
      return 5;
    }
    return newAdvisors;
  }

  return (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            marginLeft: newAdvisors >= 5 ? '-10px' : '10px',
          }}
        >
          {cleanAvatars.length > 0 &&
            cleanAvatars.slice(0, getSliceValue()).map((avatar, index) => {
              return (
                <div key={index} style={{ marginLeft: '-10px' }}>
                  <Avatar
                    src={avatar}
                    size={avatarSize || 'sm'}
                    sx={{
                      borderColor: 'lightGrey',
                      width: '35px',
                      height: '35px',
                    }}
                  />
                </div>
              );
            })}
        </div>
        {noText ? null : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingLeft: '5px',
              marginTop: '-3px',
            }}
          >
            <Typography
              variant="body1"
              sx={{
                color: (theme) => theme.palette.info.dark,
                fontWeight: 600,
                ...textStyle,
              }}
            >
              {!isSM &&
                `${avatars.length} ${
                  avatars.length === 1 ? 'Advisor' : 'Advisors'
                }`}
            </Typography>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
