import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Button,
  Typography,
} from '@mui/material';
import { useAccount } from './../../../contexts/Account';
import CustomIcon from './../../../icons/customIcon';

const useStyles = () => ({
  card: {
    marginBottom: '20px',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  cardheader: {
    paddingBottom: '0px',
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 500,
    marginTop: '5px',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    color: '#232B35',
  },
  mainTitle: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    color: '#232B35',
  },
  description: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    color: '#646D7A',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '10px',
    marginRight: '10px',
  },
  icon: {
    marginRight: '15px',
    marginTop: '-5px',
  },
  featured: {
    marginRight: '15px',
    marginTop: '-5px',
  },
  button: {
    textTransform: 'none',
    fontWeight: '700',
    fontSize: '15px',
    height: '28px',
    width: '105px',
    lineHeight: '24px',
    borderRadius: '40px',
    backgroundColor: '#6736FA',
    color: 'white',
    marginRight: '10px',
    '&:hover': {
      backgroundColor: '#6736FA',
    },
  },
  secondaryButton: {
    textTransform: 'none',
    fontWeight: '700',
    fontSize: '15px',
    height: '28px',
    width: '105px',
    lineHeight: '24px',
    borderRadius: '40px',
    color: '#6736FA',
    border: '2px solid #6736FA',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
});

const BenefitCard = (props) => {
  const { benefit, removeBenefit, editBenefit, featuredBenefit, isOnlyRead } =
    props;
  const classes = useStyles();
  const { isImpersonated } = useAccount();
  const categoryOptions = [
    {
      text: 'Compensation Potential',
      description: 'Compensated opportunities for advisors',
      icon: 'benefits-compensation',
    },
    {
      text: 'Equity Potential',
      description: 'Equity opportunities for advisors',
      icon: 'benefits-equity',
    },
    {
      text: 'Formal Roles',
      description: 'Formal role opportunities for advisors',
      icon: 'benefits-roles',
    },
    {
      text: 'Perks',
      description: 'Perks and discounts for advisors',
      icon: 'benefit-perk',
    },
  ];

  function getDescriptionByText(text) {
    const option = categoryOptions.find((option) => option.text === text);
    return option ? option.description : '';
  }

  function getIconByText(text) {
    const option = categoryOptions.find((option) => option.text === text);
    return option ? option.icon : '';
  }

  return (
    <Card elevation={0} sx={classes.card}>
      <CardHeader
        sx={classes.cardheader}
        title={
          <div style={classes.titleContainer}>
            {isImpersonated && (
              <div style={classes.featured}>
                <CustomIcon
                  // iconname={getIconByText(benefit.category)}
                  onClick={() => {
                    featuredBenefit(benefit);
                  }}
                  iconname={benefit.featured ? 'star' : 'star-outlined'}
                  color="primary"
                  fontSize="small"
                  style={{
                    color: '#232B35',
                  }}
                />
              </div>
            )}
            <div style={classes.icon}>
              <CustomIcon
                iconname={getIconByText(benefit.category)}
                color="primary"
                fontSize="small"
                style={{
                  color: '#232B35',
                }}
              />
            </div>
            <Typography sx={classes.title}>{benefit.category}</Typography>
          </div>
        }
        action={
          <div style={classes.actions}>
            {!isOnlyRead ? (
              <div>
                <Button
                  sx={classes.button}
                  onClick={() => {
                    editBenefit(benefit);
                  }}
                >
                  Edit
                </Button>
                <Button
                  sx={classes.secondaryButton}
                  onClick={() => {
                    removeBenefit(benefit);
                  }}
                >
                  Delete
                </Button>
              </div>
            ) : null}
          </div>
        }
      />
      <CardContent>
        <Typography sx={classes.mainTitle}>
          {getDescriptionByText(benefit.category)}
        </Typography>
        <Typography sx={classes.description}>{benefit.details}</Typography>
      </CardContent>
    </Card>
  );
};

export default BenefitCard;
