import { Auth, API, graphqlOperation } from 'aws-amplify';
import {
  getPublicAdvisorTags,
  getAdvisor,
  getCompanyByContactId,
} from '../Backend/account';
import { getAdvisorRecurlyPlan } from '../advisor_services';
import { isNil } from '../utils';
import { AccountType } from '../utils/types';
import {
  LoginResponses,
  LoginResponseTypes,
  ForgotPasswordResponses,
  GetAccountContextResponses,
  ConfirmEmailResponses,
} from './constants';

export async function signIn(username, password) {
  try {
    const authData = await Auth.signIn(username, password);
    return authData;
  } catch (error) {
    return error;
  }
}

export async function getCurrentUserInfo() {
  let error;
  let userInfo;
  let currentSession;

  try {
    [currentSession, userInfo] = await Promise.all([
      Auth.currentSession(),
      Auth.currentUserInfo(),
    ]);

    const accountType = JSON.parse(
      currentSession.idToken.payload.accountType
    )[0];

    currentSession.accountType = accountType;
  } catch (e) {
    error = e;
  }

  return error ? {} : { ...currentSession, ...userInfo };
}

export async function getSharedLegacyId({ jwt }) {
  try {
    const fetchResponse = await fetch(
      `${process.env.REACT_APP_LEGACY_URL_STORAGE}/token`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'set',
          token: jwt,
        }),
      }
    );
    return await fetchResponse.json();
  } catch (error) {
    console.error('NO shared token id');
    console.error(error);
  }
  return null;
}

/**
 * This function handle login, login is different to sign in.
 * Sign in only handle congnito sign in. login handle congnito sign
 * and retrieve the data necessary to make a login
 * @param {string} username -
 * @param {string} password -
 * @param {Function} authenticate - authenticate context
 * @param {boolean} addSharedLegacyId - add sharedLegacy ID if true
 * @returns { {response:Type, message:string } }
 */

const handledErrors = [
  LoginResponses.PASSWORD_ATTEMPTS_EXCEEDED,
  LoginResponses.INCORRECT_USERNAME_PASSWORD_CODE,
  LoginResponses.USER_NOT_FOUND_CODE,
  LoginResponses.NOT_AUTHORIZED_EXCEPTION,
  LoginResponses.PASSWORD_RESET_REQUIRED_EXCEPTION,
];

export async function logIn(
  username,
  password,
  authenticate,
  addSharedLegacyId
) {
  let signInError = {};
  let congnitoSignInData = {};
  try {
    let isAuthenticated = true;
    congnitoSignInData = await signIn(username, password);
    const accountType =
      congnitoSignInData.signInUserSession.idToken.payload.accountType.length >
        1 &&
      congnitoSignInData.signInUserSession.idToken.payload.accountType.includes(
        AccountType.ADVISOR
      )
        ? AccountType.ADVISOR
        : JSON.parse(
            congnitoSignInData.signInUserSession.idToken.payload.accountType
          )[0];

    if (accountType === AccountType.ADMIN) {
      return { response: LoginResponseTypes.ADMIN, message: '' };
    }

    if (congnitoSignInData.signInUserSession) {
      await authenticate(() => {
        isAuthenticated = false;
      }, accountType);
      if (isAuthenticated && (isNil(addSharedLegacyId) || !addSharedLegacyId)) {
        return {
          response: LoginResponseTypes.LOGIN_SUCCESS,
          message: '',
          data: congnitoSignInData,
        };
      }

      return {
        response: LoginResponseTypes.LOGIN_SUCCESS,
        message: '',
        data: congnitoSignInData,
      };
    }
    return {
      response: LoginResponseTypes.UKNOWN_ERROR_SUCCESS,
      message: '',
    };
  } catch (error) {
    if (signInError) {
      if (signInError.response === LoginResponseTypes.USER_MIGRATED) {
        return { response: LoginResponseTypes.USER_MIGRATED, message: '' };
      }
      if (handledErrors.includes(signInError.message)) {
        return {
          response: LoginResponseTypes.ERROR_IN_COGNITO_RESPONSE,
          message: signInError.message,
        };
      }
      return {
        response: LoginResponseTypes.COGNITO_UNKNOW_ERROR,
        message: '',
        data: congnitoSignInData,
      };
    }
    return { response: LoginResponseTypes.UKNOWN_ERROR, message: '' };
  }
}

export async function signOut() {
  return Auth.signOut({ global: true });
}

export async function forgotPassword(email, clientMetadata) {
  try {
    await Auth.forgotPassword(email, clientMetadata);

    return ForgotPasswordResponses.SUCCESS;
  } catch (error) {
    return error.code;
  }
}

export async function forgotPasswordSubmit(email, code, newPassword) {
  try {
    await Auth.forgotPasswordSubmit(email, code, newPassword);

    return ForgotPasswordResponses.SUCCESS;
  } catch (error) {
    return error.code;
  }
}
export async function changePassword({ oldPassword, newPassword }) {
  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(user, oldPassword, newPassword);
    return true;
  } catch (error) {
    console.log('ACD ERROR CUP', error);
  }
  return false;
}

export async function getAccountDetails(userData) {
  try {
    const id = userData.idToken.payload['cognito:username'];

    if (userData.accountType === AccountType.COMPANY) {
      const { data } = await getCompanyByContactId(id);
      return data.getCompanyByContactId;
    }

    const { data } = await getAdvisor(id);
    return data.getAdvisor;
  } catch (error) {
    console.log('ACD ERROR GAC', error);
    return error.message;
  }
}

export async function getAsyncRecurlyUpgradeUrl(recurlyPlanCode) {
  try {
    const { data } = await getAdvisorRecurlyPlan({ recurlyPlanCode });
    return data.getRecurlyPlan.upgradeUrl;
  } catch (error) {
    return error.message;
  }
}

export async function getUserAccountContext({ accountType }) {
  try {
    const currentUserInfo = await getCurrentUserInfo();
    if (currentUserInfo && currentUserInfo.accountType) {
      if (!isNil(accountType)) {
        currentUserInfo.accountType = accountType;
      }
      const accountDetails = await getAccountDetails(currentUserInfo);

      if (Object.keys(accountDetails).length > 0 && !isNil(accountDetails.id)) {
        let advisorOnboardingRedirectUrl = null;
        accountDetails.upgradeUrl = null;
        if (currentUserInfo.accountType === AccountType.ADVISOR) {
          const { data } = await getAdvisorRecurlyPlan({
            recurlyPlanCode: accountDetails.recurlyPlanCode,
          });
          advisorOnboardingRedirectUrl =
            data.getRecurlyPlan.onboardingRedirectUrl;
          accountDetails.upgradeUrl = data.getRecurlyPlan.upgradeUrl;
          accountDetails.maxBoardsUpgradeUrl =
            data.getRecurlyPlan.maxBoardsUpgradeUrl;
        }
        return {
          type: GetAccountContextResponses.SUCCESS,
          returnObject: {
            currentUserInfo,
            accountDetails,
            additionalDetails: {
              advisorOnboardingRedirectUrl,
              preferences: accountDetails.preferences,
            },
          },
        };
      }
      return {
        type: GetAccountContextResponses.ACCOUNT_DETAILS_ERROR,
        returnObject: accountDetails,
      };
    }
    return {
      type: GetAccountContextResponses.CANT_GET_CURRENT_USER_INFO,
      returnObject: currentUserInfo,
    };
  } catch (error) {
    return {
      type: GetAccountContextResponses.UKNOWN_ERROR,
      returnObject: error,
    };
  }
}

export async function getAndFormatTags() {
  try {
    const { data } = await getPublicAdvisorTags({
      skillsData: true,
      industriesData: true,
      positionsData: true,
    });
    const tags = {
      industries: data.getTags.industriesData,
      positions: data.getTags.positionsData,
      skills: data.getTags.skillsData,
    };
    return tags;
  } catch (error) {
    return error.message;
  }
}

export async function getImpersonateDetails(userData, userType) {
  try {
    await Auth.currentAuthenticatedUser();
    if (userType === AccountType.COMPANY) {
      const { data } = await getCompanyByContactId(userData);
      return data.getCompanyByContactId;
    }

    const { data } = await getAdvisor(userData);
    data.getAdvisor.upgradeUrl = await getAsyncRecurlyUpgradeUrl(
      data.getAdvisor.recurlyPlanCode
    );
    return data.getAdvisor;
  } catch (error) {
    console.log('ACD ERROR GAC', error);
    return error.message;
  }
}

export function getRecurlyUrl(recurlyPlanCode) {
  return getAdvisorRecurlyPlan({ recurlyPlanCode });
}

export async function confirmEmailCode(code) {
  try {
    await Auth.verifyCurrentUserAttributeSubmit('email', code);
    return ConfirmEmailResponses.SUCCESS;
  } catch (err) {
    return err;
  }
}

async function handleSignOutItem() {
  await signOut();
  window.location.replace('/login');
}

const companyAccountStatusSubscription = `
subscription onChangeCompanyContactAccountStatus($id: String!) {
    onChangeCompanyContactAccountStatus(id: $id) {
      id,
      accountStatus,
  }
}
`;

const advisorAccountStatusSubscription = `
subscription onChangeAdvisorsAccountStatus($id: String!) {
  onChangeAdvisorsAccountStatus(id: $id) {
      id,
      accountStatus,
  }
}
`;

export function accountStatusCompanySubscription({ id }) {
  return API.graphql(
    graphqlOperation(companyAccountStatusSubscription, { id })
  ).subscribe({
    next: () => {
      handleSignOutItem();
    },
    error: (error) => {
      // eslint-disable-next-line no-console
      console.warn('SUBSCRIPTION_ERROR:', error);
    },
  });
}

export function accountStatusAdvisorSubscription({ id }) {
  return API.graphql(
    graphqlOperation(advisorAccountStatusSubscription, { id })
  ).subscribe({
    next: () => {
      handleSignOutItem();
    },
    error: (error) => {
      // eslint-disable-next-line no-console
      console.warn('SUBSCRIPTION_ERROR:', error);
    },
  });
}
