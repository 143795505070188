import React from 'react';
import {
  Autocomplete,
  InputLabel,
  TextField,
  Typography,
  useMediaQuery,
  Grid,
} from '@mui/material';

const useStyles = () => ({
  inputLabel: {
    marginBottom: '10px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    color: '#232B35',
  },
  inputContainer: {
    width: '100%',
  },
  helperText: {
    color: 'grey.600',
  },
  sublabelText: {
    marginTop: '4px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    color: '#646D7A',
  },
});

function AutocompleteCustome({
  parentStyle,
  helperText,
  sublabelText,
  id,
  label,
  options,
  placeholder,
  FormHelperTextProps,
  inputStyles,
  multiple = true,
  ...rest
}) {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles();

  const onFocus = (event) => {
    if (event.target.autocomplete) {
      event.target.autocomplete = 'whatever';
    }
    if (event.target.tagName === 'INPUT') {
      event.target.type = 'search';
      event.target.autocomplete = 'off';
    }
  };

  return (
    <div style={{ ...classes.inputContainer, ...(parentStyle || {}) }}>
      {helperText ? (
        <>
          {isSM ? (
            <InputLabel sx={classes.inputLabel} htmlFor={id}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography variant="old_font">{label}</Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="caption_old_font"
                    sx={FormHelperTextProps || classes.helperText}
                  >
                    {helperText}
                  </Typography>
                </Grid>
              </Grid>
            </InputLabel>
          ) : (
            <InputLabel sx={classes.inputLabel} htmlFor={id}>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Typography variant="old_font">{label}</Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="caption_old_font"
                    sx={FormHelperTextProps || classes.helperText}
                  >
                    {helperText}
                  </Typography>
                </Grid>
              </Grid>
              {sublabelText && (
                <div style={classes.sublabelText}>{sublabelText}</div>
              )}
            </InputLabel>
          )}
        </>
      ) : null}

      <Autocomplete
        {...rest}
        id={id}
        multiple={multiple}
        options={options}
        onFocus={onFocus}
        disableCloseOnSelect={true}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={classes.inputContainer}
            InputProps={{
              ...params.InputProps,
              autoComplete: 'off',
              'data-testid': rest['input-data-testid'],
              'data-cy': rest['input-data-cy'],
              style: {
                borderRadius: '10px',
                paddingTop: inputStyles && inputStyles.paddingTop ? inputStyles.paddingTop : '4px',
                paddingBottom: inputStyles && inputStyles.paddingBottom ? inputStyles.paddingBottom : '4px',
              },
            }}
            placeholder={placeholder}
            variant="outlined"
          />
        )}
      />
    </div>
  );
}

export default AutocompleteCustome;
