import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Paper, useMediaQuery } from '@mui/material';
import AdvisoryCloudLogo from '../../components/AdvisoryCloudLogo';
import ACSnackbar from '../../components/ACSnackbar';
import TermsOfService from '../../components/TermsOfService';
import { useAccount } from '../../contexts/Account';
import {
  acceptCompanyTermsOfService,
  isTermsOfService,
} from '../../services/company_services/index';
import { OnboardingStatus } from '../../services/utils/types';

const useStyles = (isSM) => ({
  container: {
    display: 'flex',
    width: '100%',
    paddingTop: '40px',
    alignItems: 'center',
    flexDirection: 'column',
    paddingBottom: isSM ? '0px' : '20px',
    height: isSM ? 'auto' : '100vh',
    backgroundColor: isSM ? 'white' : null,
    overflow: isSM ? 'scroll' : 'hidden',
  },
  termsCard: {
    width: isSM ? '100%' : '60%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 'auto',
    padding: '20px',
    maxHeight: '505px',
    marginTop: '30px',
  },
});

function CompanyTermsOfService({ history }) {
  const [loading, setLoading] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const classes = useStyles(isSM);
  const {
    accountDetails,
    type,
    currentUserInfo,
    acceptCompanyTOS,
    isFreemium,
  } = useAccount();
  const contact = accountDetails.contacts.find(
    (c) => c.id === currentUserInfo.username
  );
  const callNotRequiredValuesArray = [
    OnboardingStatus.SCHEDULED,
    OnboardingStatus.COMPLETE,
    OnboardingStatus.NOT_REQUISITE,
  ];

  async function handleContinueClick() {
    setLoading(true);
    try {
      await acceptCompanyTermsOfService({
        CONTACT_ID: contact.id,
        TERMS: isTermsOfService.ACCEPTED,
      });

      acceptCompanyTOS();

      if (document.referrer) {
        window.location.replace(document.referrer);
      }

      if (
        callNotRequiredValuesArray.includes(accountDetails.onboarding_status)
      ) {
        history.push(
          isFreemium ? '/company/new/step1' : '/company/onboarding/step1'
        );
      } else {
        let CALL_URL_PARAMS = `
          name=${encodeURIComponent(`${contact.givenName} ${contact.surName}`)}&
          email=${encodeURIComponent(contact.email)}&
          company=${encodeURIComponent(accountDetails.companyName)}&
          user=${encodeURIComponent(contact.id)}&
          account-type=${type}&
          workflow=signup
        `;

        if (
          contact.phoneNumber &&
          contact.phoneNumber.internationalPhoneNumber
        ) {
          CALL_URL_PARAMS = `${CALL_URL_PARAMS}&mobile=${encodeURIComponent(
            contact.phoneNumber.internationalPhoneNumber.replace('+', '')
          )}`;
        }

        const SINGLELINE_URL_PARAMS = CALL_URL_PARAMS.replace(/\s/g, '');

        history.push({
          pathname: isFreemium
            ? '/company/freeaccountonboarding/call'
            : '/company/onboarding/call',
          search: `?${SINGLELINE_URL_PARAMS}`,
        });
      }
    } catch (err) {
      setLoading(false);
      setShowErrorSnackbar(true);
    }
  }

  return (
    <>
      <Helmet>
        <title>{t('COMPANY-TERMS-OF-SERVICE-HELMET-TITLE')}</title>
      </Helmet>
      <ACSnackbar
        open={showErrorSnackbar}
        text={t('ERROR-TERMS-OF-SERVICE')}
        severity="error"
        onClose={() => setShowErrorSnackbar(false)}
        autoHideDuration={6000}
      />

      <div style={classes.container}>
        <AdvisoryCloudLogo
          removeHyperlink
          onClick={() => history.push('/dashboard')}
        />

        <Paper sx={classes.termsCard}>
          <TermsOfService
            loading={loading}
            onContinueClick={handleContinueClick}
          />
        </Paper>
      </div>
    </>
  );
}

export default CompanyTermsOfService;
