import React from "react";
import { Paper, Box, Typography, Avatar, IconButton } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

const invitationsData = [
  { name: "CSCONNECT", time: "2 minutes ago", avatar: "https://via.placeholder.com/40" },
  { name: "Bloom", time: "4 hours ago", avatar: "https://via.placeholder.com/40" },
  { name: "HireList.AI", time: "8 days ago", avatar: "https://via.placeholder.com/40" },
  { name: "Skyriver IT", time: "1 week ago", avatar: "https://via.placeholder.com/40" },
];

const Invitations = () => (
  <Paper
    elevation={1}
    sx={{
      padding: "16px",
      borderRadius: "12px",
      backgroundColor: "#fff",
    }}
  >
    {/* Título y Descripción */}
    <Typography sx={{ fontSize: "20px", fontWeight: "bold", marginBottom: "8px" }}>
      Invitations
    </Typography>
    <Typography sx={{ fontSize: "14px", color: "#555", marginBottom: "20px" }}>
      You have received these invitations due to your profession, expertise, and other characteristics this board would
      find valuable. Please note: companies are looking for new and diverse opinions which is why some invitations may be
      outside your area of expertise.
    </Typography>

    {/* Lista de Invitaciones */}
    <Box sx={{ display: "flex", gap: "16px", flexWrap: "wrap" }}>
      {invitationsData.map((invitation, index) => (
        <Paper
          key={index}
          elevation={1}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "16px",
            borderRadius: "12px",
            width: "250px",
            // backgroundColor: "#f9fbff",
          }}
        >
          {/* Avatar e Información */}
          <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
            <Avatar
              src={invitation.avatar}
              alt={invitation.name}
              sx={{ width: "48px", height: "48px", backgroundColor: "#dbeafe" }}
            />
            <Box>
              <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
                {invitation.name}
              </Typography>
              <Typography sx={{ fontSize: "12px", color: "#6b7280" }}>
                {invitation.time}
              </Typography>
            </Box>
          </Box>

          {/* Botones de Acción */}
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <IconButton color="success">
              <CheckCircleIcon />
            </IconButton>
            <IconButton color="error">
              <CancelIcon />
            </IconButton>
          </Box>
        </Paper>
      ))}
    </Box>
  </Paper>
);

export default Invitations;
