import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import AvatarsInvolved from './../../AvatarsInvolved';
import CustomIcon from './../../../icons/customIcon';

const classes = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: 'transparent',
    alignItems: 'center',
    marginBottom: '5px',
  },
  rightGroup: {
    display: 'flex',
    width: 'fit-content',
    height: 'fit-content',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  iconContainer: {
    height: '25px',
    padding: '0px 5px',
  },
  textContainer: {
    paddingTop: '2px',
    paddingBottom: '5px',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '15px',
    color: '#646D7A',
  },
  contributorsButton: {
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    '&:disabled': {
      cursor: 'default',
    },
    backgroundColor: 'transparent',
  },
};

function BoardMeetingCommentsHeader(props) {
  const { contributors } = props;
  const { t } = useTranslation();

  function renderContributorsAvatar() {
    if (contributors === null || contributors === undefined) {
      return null;
    }
    if (contributors.length === 0) {
      return null;
    }
    return (
      <button
        type="button"
        style={classes.contributorsButton}
        onClick={() => {}}
        disabled
      >
        <AvatarsInvolved avatars={contributors} />
      </button>
    );
  }

  return (
    <div style={classes.container}>
      <div>
        <Typography sx={classes.titleContainer}>
          <span style={classes.iconContainer}>
            <CustomIcon
              iconname="comments"
              style={{ color: '#D7F1FD', display: 'flex' }}
            />
          </span>
          <span style={classes.textContainer}>
            {t('POST-BOARD-MEETING-COMMENTS')}
          </span>
        </Typography>
      </div>
      <div style={classes.rightGroup}>
        <div style={classes.avatarsContainer}>{renderContributorsAvatar()}</div>
      </div>
    </div>
  );
}

export default BoardMeetingCommentsHeader;
