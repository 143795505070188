import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Menu,
  MenuItem,
  Fade,
  Box,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Edit, DeleteForever, FileCopy, MoreVert } from '@mui/icons-material';
import ACSnackbar from './../../../ACSnackbar';
import { useAccount } from './../../../../contexts/Account';

const useStyles = (isSM) => ({
  backDrop: {
    zIndex: 999,
  },
  elipsies: {
    color: 'secondary.main',
    position: 'relative',
    fontSize: isSM ? '20px' : '25px',
  },
  button: {
    padding: '0px',
    margin: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    border: 'none',
    width: '10px',
    height: '22px',
    overflow: 'hidden',
  },
  iconStyle: {
    fontSize: '15px',
    marginBottom: '2px',
    marginRight: '5px',
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  menuItemText: {
    fontWeight: 600,
  },
});

function MoreMenu(props) {
  const { editClick, deleteClick, answerUrl, isOwner } = props;
  const { isFreemium } = useAccount();
  const [anchorEl, setAnchorEl] = useState(null);
  const [successSnackbar, setSuccessSnackbar] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const open = Boolean(anchorEl);
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function copyToClipboard() {
    navigator.clipboard.writeText(answerUrl);
    setSuccessMessage(t('Answer link has been copied to your clipboard.'));
    setSuccessSnackbar(true);
  }

  const Default = () => {
    return (
      <React.Fragment>
        <MenuItem onClick={() => copyToClipboard()}>
          <div style={classes.menuItem}>
            <FileCopy sx={classes.iconStyle} />
            <Typography variant="caption_old_font" sx={classes.menuItemText}>
              Copy Link
            </Typography>
          </div>
        </MenuItem>
        {isOwner ? (
          <div>
            <MenuItem onClick={() => editClick()}>
              <div style={classes.menuItem}>
                <Edit sx={classes.iconStyle} />
                <Typography
                  variant="caption_old_font"
                  sx={classes.menuItemText}
                >
                  Edit
                </Typography>
              </div>
            </MenuItem>
            <MenuItem onClick={() => deleteClick()}>
              <div style={classes.menuItem}>
                <DeleteForever sx={classes.iconStyle} />
                <Typography
                  variant="caption_old_font"
                  sx={classes.menuItemText}
                >
                  Delete
                </Typography>
              </div>
            </MenuItem>
          </div>
        ) : null}
      </React.Fragment>
    );
  };

  function renderContentMenu() {
    return <Default />;
  }

  return (
    <div>
      <ACSnackbar
        open={successSnackbar}
        text={successMessage}
        severity="success"
        onClose={() => setSuccessSnackbar(false)}
        autoHideDuration={6000}
        style={{ marginTop: isFreemium ? '50px' : '0px' }}
      />
      <button
        type="button"
        aria-controls="fade-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={classes.button}
      >
        <MoreVert sx={classes.elipsies} />
      </button>
      <Box sx={classes.backDrop} open={open} onClick={handleClose}>
        <Menu
          id="fade-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <div>{renderContentMenu()}</div>
        </Menu>
      </Box>
    </div>
  );
}

export default MoreMenu;
