import React, { useState, useEffect, useRef } from 'react';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useLocation, useHistory } from 'react-router-dom';
import { AccountType } from './../../../services/utils/types';
import Header from './header';
import ChatBox from './chatBox';
import NoChat from './NoChat';
import LoadingIndicatorLogo from './../../LoadingIndicatorLogo';
import dateFormat from './../../../utils/dateFormat';
import { useAccount } from './../../../contexts/Account';
import {
  getConversationMessages,
  createConversationMessage,
  readMessages,
} from './../../../services/conversation_services';
import eventBus, { eventBusValues } from './../../../eventBus';
import CompanyEmptyState from './../../EmptyState/CompanyEmptyState';
import { ReactComponent as ConfettiIcon } from './assets/confetti.svg';
import Conffeti from '../../../utils/confetti';
import BoardJoinButton from '../../BoardJoinButton';
import {
  getCompanyContacts,
  getCompanyInfoBoardroomMenu,
} from '../../../services/company_services';
import CompanyOpportunitySlide from '../../Company/OpportunityDrawer/CompanyOpportunitySlide';
import { getAdvisorRecurlyPlan, getBoardInvitation } from '../../../services/advisor_services';
import { featureFlags } from '../../../splitSetup';
import Treatment from '../../Treatment';
import JSConfetti from 'js-confetti';

const useStyles = (isSM) => ({
  messagesContainer: {
    overflow: 'scroll',
    overscrollBehavior: 'contain',
    backgroundColor: 'white',
    height: '100%',
    marginTop: '60px',
  },
  screenContainer: {
    position: 'fixed',
    backgroundColor: 'white',
    width: isSM ? '100%' : '80%',
    minWidth: isSM ? '100%' : 'calc(100% - 350px)',
    maxWidth: isSM ? '100%' : 'calc(100% - 280px)',
  },
  screenContainerSlide: {
    position: 'relative',
    width: '100%',
    backgroundColor: 'white',
  },
  name: {
    fontWeight: 700,
    fontSize: '14px',
    fontFamily: 'Poppins',
  },
  hour: {
    fontWeight: 500,
    fontSize: '10px',
    color: '#97A3B7',
    marginLeft: '10px',
    fontFamily: 'Poppins',
  },
  message: {
    marginTop: 5,
    fontWeight: 300,
    fontSize: '14px',
    color: '#232B35',
    fontFamily: 'Poppins',
    margin: '1px 0',
    whiteSpace: 'break-spaces',
    overflowWrap: 'anywhere',
  },
  loadingContainer: {
    alignItems: 'center',
    bottom: '0px',
    display: 'flex',
    justifyContent: 'center',
    left: '0px',
    position: 'fixed',
    right: '0px',
    top: '0px',
    zIndex: 9999,
  },
  gapBottom: {
    height: isSM ? '180px' : '50px',
  },
  DrawerGapBottom: {
    height: isSM ? '120px' : '30px',
  },
});

const ConversationContainer = (props) => {
  const { propConversationId, isDrawer } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const [noItemSelected, setNoItemSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chatboxHeight, setChatboxHeight] = useState(54);
  const [sortDates, setSortDates] = useState([]);
  const [groupDates, setGroupDates] = useState([]);
  const [conversationId, setConversationId] = useState('');
  const [senderConversationMemberId, setSenderConversationMemberId] = useState('');
  const divRef = useRef(null);

  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
    }
  }, [groupDates])


  const {
    accountDetails,
    type,
    asLoggedUserDetails,
    conversations,
    updateReadMessages,
    currentUserInfo,
  } = useAccount();

  const messagesEndRef = useRef(null);
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);

  function moreThanfiveDays(date) {
    const date1ml = date.getTime();
    const now = new Date();
    const date2ml = now.getTime();
    const diferencesml = date2ml - date1ml;
    return Math.floor(diferencesml / (1000 * 60 * 60 * 24)) > 5;
  }

  function groupByDate(arr) {
    const groups = [];
    arr.forEach((item) => {
      const date = new Date(item.createdAt);
      const dateString = moreThanfiveDays(date)
        ? dateFormat(date, 'dddd, mmmm d yyyy')
        : dateFormat(date, 'DDDD');

      item.hourString = dateFormat(date, 'shortTime');

      const groupRelated = groups.find((gr) => gr.dateString === dateString);
      if (groupRelated) {
        groupRelated.messages.push(item);
      } else {
        groups.push({
          date,
          dateString,
          messages: [item],
        });
      }
    });

    return groups;
  }

  function sendMessage(message) {
    return createConversationMessage({
      conversationId,
      senderConversationMemberId,
      accountType: type,
      senderId: asLoggedUserDetails.userId,
      receiver: queryParams.get('senderId'),
      message,
    }).then(({ data }) => {
      const newMsg = data.createConversationMessage;
      if (conversationId) {
        eventBus.dispatch(
          `${eventBusValues.onNewMessage}${conversationId}`,
          newMsg
        );
      }
      if (!conversationId && data && newMsg) {
        setConversationId(newMsg.conversationId);
        setSenderConversationMemberId(newMsg.senderConversationMemberId);
        sortDates.push(newMsg);
        setSortDates(sortDates);
        const group = groupByDate(sortDates);
        setGroupDates(group);
      }
    });
  }

  function setNewMessage(newMessage) {
    sortDates.push(newMessage);
    setSortDates(sortDates);
    const group = groupByDate(sortDates);
    setGroupDates(group);
  }

  function getConversation(conversationParam, senderParam) {
    setLoading(true);
    setNoItemSelected(false);
    const params = conversationParam
      ? { conversationId: conversationParam }
      : {
        member1:
          type === AccountType.COMPANY
            ? asLoggedUserDetails.userId
            : senderParam,
        member2:
          type !== AccountType.COMPANY
            ? asLoggedUserDetails.userId
            : senderParam,
      };
    if (conversationParam && !isDrawer) {
      readMessages({
        accountId: asLoggedUserDetails.userId,
        accountType: type,
        conversationId: conversationParam,
      });
      updateReadMessages(conversations, conversationParam);
    }
    getConversationMessages(params)
      .then(({ data }) => {
        setLoading(false);
        const msgs = data.getConversationMessages.sort((a, b) => {
          return new Date(a.createdAt) - new Date(b.createdAt);
        });
        setSortDates(msgs);
        const group = groupByDate(msgs);
        setGroupDates(group);
        if (!conversationId && data.getConversationMessages.length > 0) {
          setConversationId(data.getConversationMessages[0].conversationId);
          setSenderConversationMemberId(
            data.getConversationMessages[0].senderConversationMemberId
          );
        }
      })
      .catch((error) => {
        console.error('Error: ', error);
        setLoading(false);
        history.push('/messages');
      });
  }

  useEffect(() => {
    if (propConversationId) {
      getConversation(propConversationId);
    }
  }, [propConversationId]);

  function viewProfile(msg) {
    if (
      msg.senderAccountType === AccountType.ADVISOR &&
      msg.senderId !== accountDetails.id &&
      !isDrawer
    ) {
      eventBus.dispatch(eventBusValues.triggerAdvisorDrawerAction, {
        fetchAdvisor: true,
        id: msg.senderId,
        boardRoomView: true,
      });
    } else if (
      msg.senderAccountType === AccountType.COMPANY &&
      msg.profile?.companyId !== accountDetails.id &&
      !isDrawer
    ) {
      eventBus.dispatch(eventBusValues.triggerCompanyDrawerAction, {
        companyId: msg.profile?.companyId,
      });
    }
  }

  function getCursorStyle(msg) {
    if (
      ((msg.senderAccountType === AccountType.ADVISOR &&
        msg.senderId !== accountDetails.id) ||
        (type === AccountType.ADVISOR && msg.profile?.companyId)) &&
      !isDrawer
    ) {
      return 'pointer';
    }
    return 'default';
  }

  useEffect(() => {
    if (
      queryParams.get('senderId') &&
      queryParams.get('senderId') === asLoggedUserDetails.userId
    ) {
      setNoItemSelected(true);
    } else if (
      queryParams.get('conversationId') ||
      queryParams.get('senderId')
    ) {
      getConversation(
        queryParams.get('conversationId'),
        queryParams.get('senderId')
      );
    }
    const hsubs = history.listen((locationParams) => {
      const newQueryParams = new URLSearchParams(locationParams.search);
      const conversationParam = newQueryParams.get('conversationId');
      const senderParam = newQueryParams.get('senderId');
      if (!conversationParam && !senderParam && !propConversationId) {
        setConversationId('');
        setNoItemSelected(true);
        setGroupDates([]);
      }
      if (conversationParam && conversationParam !== conversationId) {
        setGroupDates([]);
        getConversation(conversationParam, senderParam);
      }
      if (senderParam) {
        setConversationId('');
        setGroupDates([]);
        setSortDates([]);
        setNoItemSelected(false);
      }
    });
    return () => {
      hsubs();
    };
  }, []);

  useEffect(() => {
    if (conversationId) {
      eventBus.on(
        `${eventBusValues.onNewMessage}${conversationId}`,
        (newMessage) => {
          if (
            window.location.search.includes(conversationId) ||
            conversationId === propConversationId
          ) {
            setNewMessage(newMessage);
          }
        }
      );
    }
    return () => {
      eventBus.remove(`${eventBusValues.onNewMessage}${conversationId}`);
    };
  }, [conversationId]);
  const canvas = document.getElementById('custom_canvas');
  const confetti = new Conffeti({ canvas: canvas });

  function oldConversationContainer() {
    return (
      <div
        ref={divRef}
        style={{
          ...(isDrawer
            ? classes.screenContainerSlide
            : classes.screenContainer),
          height: isDrawer
            ? `100vh`
            : `calc(100vh - 80px - ${chatboxHeight}px)`,
          overflow: isDrawer ? 'hidden' : null,
        }}
      >
        <canvas
          id="custom_canvas"
          style={{
            position: 'fixed',
            zIndex: 99999,
            display: 'hide',
            height: '100dvh',
            width: '100%',
            pointerEvents: 'none',
          }}
        ></canvas>

        <Header
          propConversationId={propConversationId}
          isDrawer={isDrawer}
          groupDates={groupDates}
        />
        {type === AccountType.ADVISOR ? (
          <Header
            propConversationId={propConversationId}
            isDrawer={isDrawer}
            groupDates={groupDates}
          />
        ) : null}
        <div style={classes.messagesContainer}>
          {noItemSelected && type === AccountType.ADVISOR ? <NoChat /> : null}
          {noItemSelected && type === AccountType.COMPANY ? (
            <Box padding="75px 45px" maxWidth="1200px">
              <CompanyEmptyState
                content="Your conversations with advisors will appear here"
                buttonText="Start messaging"
                buttonAction={() => { }}
                buttonId="getStartedButton"
              />
            </Box>
          ) : null}
          {loading ? (
            <div style={classes.loadingContainer}>
              <LoadingIndicatorLogo
                size={200}
                iconFontSize={93}
                iconRight={105}
              />
            </div>
          ) : null}
          <List
            style={{
              paddingBottom: '8px',
              marginTop: isDrawer ? '150px' : '20px',
            }}
          >
            {groupDates.map((group) => (
              <React.Fragment key={group.dateString}>
                <Divider style={{ fontFamily: 'Poppins' }}>
                  {group.dateString}
                </Divider>
                {group.messages.map((chat, index) => (
                  <ListItem
                    style={{
                      paddingLeft: '30px',
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: 5,
                    }}
                    alignItems="flex-start"
                    key={`${chat.profile?.displayName}-${chat.createdAt}`}
                  >
                    <div style={{ display: 'flex' }}>
                      {chat.profile?.image ||
                        chat.profile?.contactLogoLocation ? (
                        <ListItemAvatar>
                          <img
                            alt=""
                            src={
                              chat.profile?.image
                                ? `${process.env.REACT_APP_IMAGES_URL}${chat.profile?.image.imageKey}`
                                : chat.profile?.contactLogoLocation
                            }
                            style={{
                              width: '45px',
                              height: '45px',
                              marginRight: '15px',
                              marginTop: '-5px',
                              cursor: getCursorStyle(chat),
                            }}
                            onClick={() => viewProfile(chat)}
                            role="none"
                          />
                        </ListItemAvatar>
                      ) : (
                        <ListItemAvatar>
                          <Avatar
                            onClick={() => viewProfile(chat)}
                            style={{
                              cursor: getCursorStyle(chat),
                            }}
                          >
                            {chat.profile?.displayName.charAt(0)}
                          </Avatar>
                        </ListItemAvatar>
                      )}

                      <ListItemText
                        primary={
                          <div>
                            <span style={classes.name}>
                              {chat.profile?.displayName}
                            </span>
                            <span style={classes.hour}>{chat.hourString}</span>
                          </div>
                        }
                        secondary={
                          <span style={classes.message}>{chat.message}</span>
                        }
                      />
                    </div>
                  </ListItem>
                ))}
              </React.Fragment>
            ))}
          </List>
          <div
            style={isDrawer ? classes.DrawerGapBottom : classes.gapBottom}
            ref={messagesEndRef}
          />
        </div>
        <ChatBox
          sendMessage={sendMessage}
          chatboxHeight={chatboxHeight}
          setChatboxHeight={setChatboxHeight}
          disabled={noItemSelected}
          isDrawer={isDrawer}
        />
      </div>
    );
  }

  function newConversationContainer() {
    return (
      <div

        style={{
          ...(isDrawer
            ? classes.screenContainerSlide
            : classes.screenContainer),
          height: isDrawer
            ? `100vh`
            : `calc(100vh - 80px - ${chatboxHeight}px)`,
          overflow: isDrawer ? 'hidden' : null,
        }}
      >
        <canvas
          id="custom_canvas"
          style={{
            position: 'fixed',
            zIndex: 99999,
            display: 'hide',
            height: '100dvh',
            width: '100%',
            pointerEvents: 'none',
          }}
        ></canvas>

        <Header
          propConversationId={propConversationId}
          isDrawer={isDrawer}
          groupDates={groupDates}
        />
        {type === AccountType.ADVISOR ? (
          <Header
            propConversationId={propConversationId}
            isDrawer={isDrawer}
            groupDates={groupDates}
          />
        ) : null}
        <div ref={divRef} style={classes.messagesContainer}>
          {noItemSelected && type === AccountType.ADVISOR ? <NoChat /> : null}
          {noItemSelected && type === AccountType.COMPANY ? (
            <Box padding="75px 45px" maxWidth="1200px">
              <CompanyEmptyState
                content="Your conversations with advisors will appear here"
                buttonText="Start messaging"
                buttonAction={() => { }}
                buttonId="getStartedButton"
              />
            </Box>
          ) : null}
          {loading ? (
            <div style={classes.loadingContainer}>
              <LoadingIndicatorLogo
                size={200}
                iconFontSize={93}
                iconRight={105}
              />
            </div>
          ) : null}
          <List
            id="messagesList"

            style={{
              paddingBottom: '8px',
              marginTop: isDrawer ? '150px' : '20px',
            }}
          >
            {groupDates.map((group) => (
              <React.Fragment key={group.dateString}>
                <Divider style={{ fontFamily: 'Poppins' }}>
                  {group.dateString}
                </Divider>
                {group.messages.map((chat, index) => (
                  <ListItem
                    style={{
                      paddingLeft: '30px',
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: 5,
                    }}
                    alignItems="flex-start"
                    key={`${chat.profile?.displayName}-${chat.createdAt}`}
                  >
                    <div style={{ display: 'flex' }}>
                      {chat.profile?.image ||
                        chat.profile?.contactLogoLocation ? (
                        <ListItemAvatar>
                          <img
                            alt=""
                            src={
                              chat.profile?.image
                                ? `${process.env.REACT_APP_IMAGES_URL}${chat.profile?.image.imageKey}`
                                : chat.profile?.contactLogoLocation
                            }
                            style={{
                              width: '45px',
                              height: '45px',
                              marginRight: '15px',
                              marginTop: '-5px',
                              cursor: getCursorStyle(chat),
                            }}
                            onClick={() => viewProfile(chat)}
                            role="none"
                          />
                        </ListItemAvatar>
                      ) : (
                        <ListItemAvatar>
                          <Avatar
                            onClick={() => viewProfile(chat)}
                            style={{
                              cursor: getCursorStyle(chat),
                            }}
                          >
                            {chat.profile?.displayName.charAt(0)}
                          </Avatar>
                        </ListItemAvatar>
                      )}

                      <ListItemText
                        primary={
                          <div>
                            <span style={classes.name}>
                              {chat.profile?.displayName}
                            </span>
                            <span style={classes.hour}>{chat.hourString}</span>
                          </div>
                        }
                        secondary={
                          <span style={classes.message}>{chat.message}</span>
                        }
                      />
                    </div>
                    <div>
                      {type === 'advisor' && chat.type === 'itj' ? (
                        <InvitationObject
                          inviteId={chat.invitationId}
                          companyId={chat.profile?.companyId}
                          confetti={confetti}
                        />
                      ) : null}
                    </div>
                  </ListItem>
                ))}
              </React.Fragment>
            ))}
          </List>
          <div
            style={isDrawer ? classes.DrawerGapBottom : classes.gapBottom}
            ref={messagesEndRef}
          />
        </div>
        <ChatBox
          sendMessage={sendMessage}
          chatboxHeight={chatboxHeight}
          setChatboxHeight={setChatboxHeight}
          disabled={noItemSelected}
          isDrawer={isDrawer}
        />
      </div>
    );
  }

  return (
    <Treatment
      userId={currentUserInfo.username}
      splitNames={featureFlags.itj_advisor}
      updateOnSdkTimedout
      conditionFlag
      evaluatedComponent={newConversationContainer()}
      conditionFailedComponent={oldConversationContainer()}
    />
  );
};

const InvitationObject = ({ ...params }) => {
  const canvas = document.getElementById('custom_canvas')
  const jsConfetti = new JSConfetti({ canvas: canvas });
  const companyChatId = params?.companyId;
  function throwConfetti() {
    jsConfetti.addConfetti()
  }


  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const classes = useStyles(isXS);
  const boxRef = useRef(null);

  const { accountDetails, type } = useAccount();

  const [company, setCompany] = useState({});
  const [companyCEO, setCompanyCEO] = useState({});
  const [companyContacts, setCompanyContacts] = useState([]);
  const [isFreemiumCompany, setIsFreemiumCompany] = useState(false);
  const [isAcPlusCompany, setIsAcPlusCompany] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [openExitDialog, setOpenExitDialog] = useState(false);
  const [invitation, setInvitation] = useState({});

  useEffect(() => {
    getBoardInvitation(params.inviteId).then((invitation) => {
      setInvitation(invitation.data.getBoardInvitation);
    });
    if (companyChatId) {
      getCompanyInfoBoardroomMenu({
        companyId: companyChatId
      }).then((company) => {
        setCompany(company.data.getCompany);
      });
      getCompanyContacts({
        companyId: companyChatId,
      }).then((contacts) => {
        console.log('contacts: ', contacts);
        setCompanyContacts(contacts.data.getCompanyContacts);
        setCompanyCEO(contacts.data.getCompanyContacts[0]);
      });
    }
  }, []);


  useEffect(() => {
    if (!company.id) return;

    getAdvisorRecurlyPlan({
      recurlyPlanCode: company.recurlyPlanCode,
    })
      .then((result) => {
        const planCode = result.data.getRecurlyPlan;
        setIsFreemiumCompany(planCode.isFreemium);
        setIsAcPlusCompany(planCode.isAcPlus);
      })
      .catch((err) => {
        console.log('getAdvisorRecurlyPlan error', err);
      });
  }, [company]);

  const getInvitationTitle = () => {
    let title = 'Advisory Board Invitation';
    const invitationLastUpdated = new Date(invitation.updatedAt);
    if (invitation.status === 'pending') {
    } else if (invitation.status === 'accepted') {
      title += `: Accepted on ${invitationLastUpdated.getMonth()}/${invitationLastUpdated.getDay()}/${invitationLastUpdated.getFullYear()}`;
    } else if (invitation.status === 'declined' || !invitation) {
      title += `: Declined on ${invitationLastUpdated.getMonth()}/${invitationLastUpdated.getDay()}/${invitationLastUpdated.getFullYear()}`;
    }

    return title;
  };

  const getCompanyStoryAndMission = () => {
    let baseCharacters = 250;
    let splittedText = '';
    const endingCharacters = ['.', '!', '?', ' ', '\n', '\r', '\t',];
    if (!company.storyAndMission) return 'No info available';

    if (company.storyAndMission.length > baseCharacters) {
      splittedText = company.storyAndMission.substring(0, baseCharacters) + '...';

      while (!endingCharacters.includes(company.storyAndMission[baseCharacters])) {
        baseCharacters++;
        splittedText = company.storyAndMission.substring(0, baseCharacters) + '...';
      }
      return splittedText;
    }

    return company.storyAndMission;
  };

  return (
    <div style={{ marginLeft: 50 }} key={params.key}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: !isSM ? '50dvw' : "75dvw",
          borderRadius: 12,
          backgroundColor: 'hsla(216, 33%, 97%, 1)',
        }}
      >
        <div
          style={{
            marginLeft: 20,
            marginTop: 20,
            marginBottom: 20,
            marginRight: isSM ? 20 : 0,
            display: 'flex',
            flexDirection: 'column',
            rowGap: 20,
            textAlign: isSM ? "justify" : "inherit"
          }}
        >
          <div
            style={{
              display: 'flex',
              flex: 'row',
              columnGap: '2%',
              alignItems: 'flex-end',
            }}
          >
            <ConfettiIcon
              cursor={'pointer'}
              color="hsla(255, 95%, 60%, 1)"
              transform="scale(0.9)"
              onClick={throwConfetti}
            />
            <Typography
              style={{
                color: 'hsla(213, 20%, 17%, 1)',
                fontWeight: 700,
                lineHeight: '24px',
                letterSpacing: '-0.43px',
                fontSize: '16px',
              }}
            >
              {getInvitationTitle()}
            </Typography>
          </div>
          <div>
            <Typography>
              {getCompanyStoryAndMission()}
            </Typography>
          </div>
          <div
            style={{
              display: 'inline-flex',
              flexDirection: !isSM ? 'row' : "column",
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'inline-flex',
                flexDirection: 'row',
                columnGap: 10,
                width: !isSM ? '10rem' : "100%",
                minHeight: '45',
              }}
            >
              <div
                onClick={() => setIsOpen(true)}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: !isSM ? "100%" : "95%",
                  height: !isSM ? 'auto' : '55px',
                  marginLeft: !isSM ? 0 : "0.5rem",
                  color: 'hsla(255, 95%, 60%, 1)',
                  font: 'Poppins',
                  fontWeight: 600,
                  lineHeight: '24px',
                  letterSpacing: '-0.43px',
                  borderRadius: 40,
                  borderWidth: '2px',
                  borderStyle: 'solid',
                  borderColor: 'hsla(255, 95%, 60%, 1)',
                  cursor: 'pointer',
                  pointerEvents: 'auto',
                  marginBottom: 10
                }}
              >
                View Details
              </div>
            </div>
            {invitation.status ? (
              <div
                style={{
                  display: 'inline-flex',
                  flexDirection: 'row',
                  marginRight: 30,
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <BoardJoinButton
                  featureFlag={true}
                  launchConfetti={throwConfetti}
                  sx={{
                    button: {
                      minWidth: '0px',
                      width: isSM ? "100%" : '11rem',
                      marginBottom: '0px',
                    },
                    container: { marginLeft: 'auto' },
                    buttonContainer: {
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      flexDirection: isSM ? 'column' : 'row',
                      rowGap: 10,
                    },
                  }}
                  invitation={{...invitation, id: invitation.invitationId}}
                  boardId={invitation.companyId}
                  company={company}
                  companyCEO={companyCEO?.id || ''}
                  staticClass="joinButtonTop"
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {company.id && (
        <SwipeableDrawer
          disableSwipeToOpen
          anchor={
            type === AccountType.ADVISOR ||
              (company &&
                company.id &&
                accountDetails &&
                company.id != accountDetails.id)
              ? 'right'
              : 'left'
          }
          open={isOpen}
          onOpen={() => { }}
          onClose={() => {
            setIsOpen(false);
          }}
          PaperProps={{
            sx: classes.advisorPaper,
            style: {
              width: '50%',
              paddingBottom: '0px',
            },
            ref: boxRef,
          }}
        >
          {company && company.id ? (
            accountDetails && company.id != accountDetails.id ? (
              <CompanyOpportunitySlide
                invitation={invitation}
                company={company}
                skills={[]}
                handleClickAway={() => {
                  setIsOpen(false);
                }}
                companyMembers={companyContacts}
                companyName={company.companyName}
                boardOwner={{}}
                hidePostSection={false}
                isFreemiumCompany={isFreemiumCompany}
                isAcPlusCompany={isAcPlusCompany}
              />
            ) : null
          ) : (
            <div style={classes.loadingContainer}>
              <LoadingIndicatorLogo
                size={200}
                iconFontSize={93}
                iconRight={105}
              />
            </div>
          )}
          <Dialog
            open={openExitDialog}
            sx={classes.dialogContainer}
            maxWidth="sm"
            onClose={() => {
              setOpenExitDialog(false);
            }}
          >
            <div style={classes.dialogContainer}>
              <DialogContent>
                <DialogContentText sx={classes.dialogText}>
                  Are you sure you want to close without saving?
                </DialogContentText>
              </DialogContent>
              <DialogActions sx={classes.dialogActions}>
                <Button
                  variant="outlined"
                  color="primary"
                  sx={classes.actionsButton}
                  onClick={() => {
                    setOpenExitDialog(false);
                    setIsEditing(false);
                    setIsOpen(false);
                    setBoardOwner({});
                    setCompanyMembers([]);
                    setCompany({});
                  }}
                  style={{
                    paddingTop: isSM ? '30px' : '0px',
                    paddingBottom: isSM ? '30px' : '0px',
                  }}
                >
                  Discard Changes
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  sx={classes.actionsButton}
                  onClick={() => {
                    setOpenExitDialog(false);
                    if (formRef.current) {
                      formRef.current.triggerFunction();
                    }
                  }}
                  style={{
                    paddingTop: isSM ? '30px' : '0px',
                    paddingBottom: isSM ? '30px' : '0px',
                  }}
                >
                  Save Changes
                </Button>
              </DialogActions>
            </div>
          </Dialog>
        </SwipeableDrawer>
      )}
    </div>
  );
};

export default ConversationContainer;
