import React from 'react';
import { Box, Typography } from '@mui/material';

const getClasses = () => ({
  countContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '15px',
  },
  count: {
    fontWeight: 600,
    fontSize: '36px',
    color: '#232B35',
    height: '44px',
  },
  countDecorator: {
    fontWeight: 300,
    fontSize: '12px',
    color: '#232B35',
  },
});

function TextGroup(props) {
  const { qty, decorator, width = '50%', id } = props;
  const classes = getClasses();

  return (
    <Box sx={{ ...classes.countContainer, width }} id={id || null}>
      <Typography sx={classes.count}>{qty}</Typography>
      <Typography sx={classes.countDecorator}>{decorator}</Typography>
    </Box>
  );
}
export default TextGroup;
