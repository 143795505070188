import React, { useMemo, useEffect } from 'react';
import { Autocomplete, Divider, Typography, TextField } from '@mui/material';
import ACButton from './../../ACButton';
import ACSnackbar from './../../ACSnackbar';
import { Form } from 'formik';
import { useLocation } from 'react-router-dom';
import FilePondProfile from './../../ProfileUploader';
import Input from './Input';

const useStyles = (isXS) => ({
  companyWebsiteContainer: {
    display: 'flex',
    flex: 'auto',
    flexDirection: 'column',
  },
  continueButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: '25px',
  },
  dividerMargin: {
    marginBottom: '15px',
  },
  fieldContainer: {
    marginBottom: '15px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
  },
  thumbnailContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    maxHeight: '250px',
    width: '170px',
  },
  phoneContainer: {
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: isXS ? '0' : '5%',
  },
  phoneInfoContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: isXS ? 'column' : 'row',
  },
  phoneCountryCodeContainer: {
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
  },
  labelSeparator: {
    marginBottom: '10px',
  },
  autocomplete: {
    width: isXS ? '175px' : '240px',
  },
  errorSeparatorCode: {
    marginTop: '2px',
  },
  errorSeparatorPhone: {
    marginTop: '-13px',
  },
});

function Step2FormikForm(props) {
  const {
    handleChange,
    handleBlur,
    values,
    isValid,
    setFieldValue,
    errors,
    t,
    isSnackbarVisible,
    setIsSnackbarVisible,
    setUploaderOutput,
    setFilePondStatus,
    submitStep2Form,
    isXS,
    isUpdate,
    countryToFlag,
    countries,
    NullCountry,
    filePondStatus,
    isLoading,
    onValid,
  } = props;
  const classes = useStyles(isXS);
  const firstNamePlaceholder = useMemo(() => values.yourFirstName, []);
  const lastNamePlaceholder = useMemo(() => values.yourLastName, []);
  const location = useLocation();
  const isCompanyContact =
    location.pathname === '/company-member/onboarding/profile';
  const isContinueButtonDisabled =
    !isValid ||
    (filePondStatus && !isCompanyContact) ||
    values.yourPhoneCountryCode === NullCountry.NULL_COUNTRY;

  useEffect(() => {
    if (onValid) {
      onValid(!isContinueButtonDisabled);
    }
  }, [isContinueButtonDisabled]);

  return (
    <Form>
      <ACSnackbar
        id="onboarding-step1-error-snackbar"
        onClose={() => setIsSnackbarVisible(false)}
        open={isSnackbarVisible}
        severity="error"
        text={t('STEP1-ERROR-SNACKBAR-TEXT')}
      />
      <div style={classes.row}>
        <div style={classes.thumbnailContainer}>
          <FilePondProfile
            resultFiles={setUploaderOutput}
            status={setFilePondStatus}
          />
        </div>
        <div style={classes.companyWebsiteContainer}>
          <Input
            style={classes.fieldContainer}
            id="yourFirstName"
            inputProps={{
              'data-cy': 'txtYourFirstName',
            }}
            label={t('STEP2-YOUR-FIRST-NAME-INPUT-LABEL')}
            onBlur={handleBlur}
            onChange={handleChange}
            size="small"
            value={values.yourFirstName}
            placeholder={firstNamePlaceholder}
          />

          <Input
            style={classes.fieldContainer}
            id="yourLastName"
            inputProps={{
              'data-cy': 'txtYourLastName',
            }}
            label={t('STEP2-YOUR-LAST-NAME-INPUT-LABEL')}
            onBlur={handleBlur}
            onChange={handleChange}
            size="small"
            value={values.yourLastName}
            placeholder={lastNamePlaceholder}
          />
        </div>
      </div>

      <br />

      <div style={{ ...classes.dividerMargin, ...classes.row }}>
        <Input
          style={classes.fieldContainer}
          id="yourTitle"
          inputProps={{
            'data-cy': 'txtYourTitle',
          }}
          label={t('STEP2-YOUR-TITLE-INPUT-LABEL')}
          onBlur={handleBlur}
          onChange={handleChange}
          size="small"
          value={values.yourTitle}
        />
      </div>

      <div style={{ ...classes.dividerMargin, ...classes.row }}>
        <Input
          style={classes.fieldContainer}
          id="yourBiography"
          inputProps={{
            'data-cy': 'txtYourBiography',
          }}
          label={t('STEP2-YOUR-BIOGRAPHY-INPUT-LABEL')}
          multiline
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={t('STEP2-YOUR-BIOGRAPHY-INPUT-PLACEHOLDER')}
          rows={4}
          value={values.yourBiography}
        />
      </div>

      <div style={{ ...classes.dividerMargin, ...classes.row }}>
        <Typography>{t('STEP2-PHONE-NUMBER-TITLE')}</Typography>
      </div>

      <div style={{ ...classes.dividerMargin, ...classes.row }}>
        <div style={classes.phoneInfoContainer}>
          <div style={classes.phoneCountryCodeContainer}>
            <Typography variant="old_font" sx={classes.labelSeparator}>
              {t('STEP2-COUNTRY-CODE')}
            </Typography>
            <Autocomplete
              id="yourPhoneCountryCode"
              onBlur={handleBlur}
              onChange={(e, value) => {
                if (value === null) {
                  setFieldValue(
                    'yourPhoneCountryCode',
                    NullCountry.NULL_COUNTRY
                  );
                } else {
                  setFieldValue('yourPhoneCountryCode', value.code);
                }
              }}
              sx={classes.autocomplete}
              options={countries}
              autoHighlight
              renderOption={(props, option) => (
                <li {...props}>
                  <span>{countryToFlag(option.code)}</span>
                  <span>{`${option.label} (${option.code}) +${option.phone}`}</span>
                </li>
              )}
              getOptionLabel={(option) => {
                if (option.code === NullCountry.NULL_COUNTRY) {
                  return `${option.label}`;
                }
                return `${countryToFlag(option.code)} ${option.label}`;
                // eslint-disable-next-line react/jsx-curly-newline
              }}
              defaultValue={countries.find(
                (country) => country.code === values.yourPhoneCountryCode
              )}
              value={
                countries.find(
                  (element) => element.code === values.yourPhoneCountryCode
                ) || {}
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
            {values.yourPhoneCountryCode === NullCountry.NULL_COUNTRY && (
              <Typography
                variant="caption_old_font"
                color="error"
                sx={classes.errorSeparatorCode}
              >
                {t('STEP2-COUNTRY-CODE-REQUIRED')}
              </Typography>
            )}
          </div>
          <div style={classes.phoneContainer}>
            <Typography>{t('STEP2-PHONE-NUMBER')}</Typography>
            <Input
              style={classes.fieldContainer}
              id="yourPhoneNumber"
              inputProps={{
                'data-cy': 'txtYourPhoneNumber',
              }}
              onBlur={handleBlur}
              onChange={handleChange}
              size="small"
              value={values.yourPhoneNumber}
            />
            {errors.yourPhoneNumber && (
              <Typography
                variant="caption_old_font"
                color="error"
                sx={classes.errorSeparatorPhone}
              >
                {errors.yourPhoneNumber}
              </Typography>
            )}
          </div>
        </div>
      </div>

      {!isUpdate && <Divider />}

      {!isUpdate && (
        <div style={classes.continueButtonContainer}>
          <ACButton
            color="primary"
            data-cy="btnStep2Continue"
            //disabled={isContinueButtonDisabled}
            loading={isLoading ? 1 : 0}
            style={{ width: 200 }}
            onClick={() => {
              submitStep2Form(values);
            }}
          >
            {t('CONTINUE-BUTTON-TEXT')}
          </ACButton>
        </div>
      )}
    </Form>
  );
}

export default Step2FormikForm;
