import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  CircularProgress,
  Paper,
  Typography,
  useMediaQuery,
  Menu,
  MenuItem,
  Fade,
  Box,
} from '@mui/material';
import { zonedTimeToUtc } from 'date-fns-tz';
import { google, outlook, ics, office365 } from 'calendar-link';
import { getSinglePostCall } from '../../services/company_services';
import { mutationUpdateAdvisorEmailPreferences } from '../../services/advisor_services';
import { formatBoardJoinedDate } from '../../services/date';
import { useAccount } from '../../contexts/Account';
import { ReactComponent as Building1 } from '../../icons/building_1.svg';
import { ReactComponent as Building2 } from '../../icons/building_2.svg';
import { AccountType } from '../../services/utils/types';
import { ReactComponent as OutlookIcon } from '../Post/Card/PostCardBody/BoardMeetingActions/outlook.svg';
import { ReactComponent as AppleIcon } from '../Post/Card/PostCardBody/BoardMeetingActions/apple.svg';
import { ReactComponent as MicrosoftIcon } from '../Post/Card/PostCardBody/BoardMeetingActions/microsoft.svg';
import { ReactComponent as GoogleIcon } from '../Post/Card/PostCardBody/BoardMeetingActions/google.svg';

const useStyles = (isSM) => ({
  tilesContainer: {
    display: 'flex',
    flexDirection: isSM ? 'column' : 'row',
    width: '100%',
    marginTop: '20px',
    justifyContent: 'space-between',
  },
  tile: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: isSM ? '100%' : '49%',
    marginTop: isSM ? '10px' : '',
    height: '238px',
    borderRadius: '16px',
    padding: '8px',
    cursor: 'pointer',
  },
  topText: {
    fontWeight: 500,
    fontSize: '12px',
    position: 'absolute',
    top: '21px',
    right: '18px',
    color: '#74879A',
  },
  bottomText: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: isSM ? '13px' : '18px',
    position: 'absolute',
    bottom: '31px',
    right: '30px',
    cursor: 'pointer',
    textTransform: 'none',
    color: 'inherit',
  },
  titleContainer: {
    display: 'flex',
    marginTop: '40px',
    alignItems: 'center',
  },
  tileTitle: {
    fontWeight: 600,
    fontSize: isSM ? '18px' : '24px',
  },
  tileText: {
    fontWeight: 400,
    fontSize: isSM ? '13px' : '18px',
    marginTop: '24px',
  },
  secondTileText: {
    fontWeight: 400,
    fontSize: isSM ? '13px' : '18px',
    marginTop: isSM ? '4px' : '30px',
  },
  featuredTile: {
    backgroundColor: '#DBF7EB',
  },
  chooseTile: {
    backgroundColor: '#F5F7FA',
  },
  buildingIconContainer: {
    position: 'absolute',
    right: '0px',
    bottom: '0px',
    lineHeight: 0,
  },
  windowIconContainer: {
    position: 'absolute',
    right: '18px',
    bottom: '19px',
    lineHeight: 0,
    pointerEvents: 'none',
  },
  backDrop: {
    zIndex: 999,
  },
  elipsies: {
    color: 'secondary.main',
    position: 'relative',
    fontSize: isSM ? '20px' : '25px',
  },
  button: {
    padding: '0px',
    margin: '0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    border: 'none',
    width: '10px',
    height: '22px',
    overflow: 'hidden',
  },
  iconStyle: {
    height: '14px',
    marginBottom: '2px',
    marginRight: '11px',
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  menuItemText: {
    fontSize: '14px',
    marginBottom: '5px',
    marginRight: '11px',
  },
  actionsMenu: {
    width: '140px',
    borderRadius: '12px',
    border: '1px solid var(--Gray-5, #F5F7FA)',
    boxShadow: '0px 1px 10px 0px rgba(230, 237, 254, 0.25)',
  },
});

function BoardroomsTiles({
  company,
  setSuccessSnackbar,
  automaticCalendarLink,
}) {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down([1150]));
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles(isSM);
  const [boardMeetingDate, setBoardMeetingDate] = useState('');
  const { accountDetails, type } = useAccount();
  const { t } = useTranslation();
  const companyHasUpcomingMeeting = useMemo(() => {
    const today = new Date();
    if (!company || !company.upcomingBoardMeeting) {
      return false;
    }
    const meetingDate = new Date(company.upcomingBoardMeeting);
    if (today > meetingDate) {
      return false;
    }
    return true;
  }, [company]);

  useEffect(() => {
    const date = formatBoardJoinedDate(company.upcomingBoardMeeting, true);
    setBoardMeetingDate(date);
  }, [company]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  function addToCalendar(event) {
    event.stopPropagation();
    if (!companyHasUpcomingMeeting) {
      event.stopPropagation();
      subscribe();
      return;
    }
    if (automaticCalendarLink) {
      if (!anchorEl) {
        setAnchorEl(event.currentTarget);
      } else {
        setAnchorEl(null);
      }
      return;
    }
    setLoading(true);
    getSinglePostCall({
      ACCOUNT_TYPE:
        type === AccountType.COMPANY
          ? AccountType.CONTACT
          : AccountType.ADVISOR,
      USER_ID: accountDetails.id,
      POST_ID: company.upcomingBoardMeetingData.id,
    }).then(function (resp) {
      const post = resp.data.getPost;
      window.open(post.calendarLink, '_blank');
      setLoading(false);
    });
  }

  function addToCalendarAction(event, type) {
    setLoading(true);
    handleClose();

    event.stopPropagation();
    getSinglePostCall({
      ACCOUNT_TYPE:
        type === AccountType.COMPANY
          ? AccountType.CONTACT
          : AccountType.ADVISOR,
      USER_ID: accountDetails.id,
      POST_ID: company.upcomingBoardMeetingData.id,
    }).then(function (resp) {
      const post = resp.data.getPost;
      let dateUTC = zonedTimeToUtc(post.meetingTime, post.meetingTimeZone);
      if (dateUTC != 'Invalid Date') {
        dateUTC = dateUTC.toISOString();
        const eventCalendar = {
          title: post.postTitle,
          description: post.body,
          start: post.meetingTime,
          duration: [1, 'hour'],
          location: `${window.location.origin}/post/${post.id}`,
        };
        switch (type) {
          case 'apple': {
            const appleCalendarLink = ics(eventCalendar);
            var link = document.createElement('a');
            link.href = appleCalendarLink;
            link.setAttribute('download', 'boardMeeting.ics');
            document.body.appendChild(link);
            link.click();
            break;
          }
          case 'google': {
            const googleCalendarLink = google(eventCalendar);
            window.open(googleCalendarLink, '_blank');
            break;
          }
          case 'outlook': {
            const outlookCalendarLink = outlook(eventCalendar);
            window.open(outlookCalendarLink, '_blank');
            break;
          }
          case 'microsoft': {
            const office365CalendarLink = office365(eventCalendar);
            window.open(office365CalendarLink, '_blank');
            break;
          }
        }
      }
      setLoading(false);
    });
  }

  async function subscribe() {
    setLoading(true);
    if (accountDetails.emailPreferences?.blockNewBoardMeetingPost) {
      await mutationUpdateAdvisorEmailPreferences({
        ATTRIBUTE: 'blockNewBoardMeetingPost',
        ADVISOR_ID: accountDetails.id,
        VALUE: false,
      });
    }
    setSuccessSnackbar(true);
    setLoading(false);
  }

  return (
    <div style={classes.tilesContainer}>
      <Paper
        elevation={0}
        sx={{ ...classes.tile, ...classes.featuredTile }}
        id={
          companyHasUpcomingMeeting
            ? 'boardMeetingTileScheduled'
            : 'boardMeetingTileNotScheduled'
        }
        onClick={addToCalendar}
      >
        <Typography sx={classes.topText}>
          {companyHasUpcomingMeeting
            ? t('NON-Q-FEATURED-OPPORTUNITY-TEXT')
            : t('FEATURED-OPPORTUNITY-TEXT')}
        </Typography>
        <Button
          variant="text"
          color="primary"
          startIcon={loading ? <CircularProgress size={25} /> : null}
          sx={classes.bottomText}
          onClick={addToCalendar}
          id={
            companyHasUpcomingMeeting
              ? 'boardMeetingJoinUsAction'
              : 'boardMeetingSubscribeAction'
          }
        >
          {companyHasUpcomingMeeting ? t('ADD-TO-CALENDAR') : t('SUBSCRIBE')}
        </Button>
        <Box sx={classes.backDrop} open={open} onClick={handleClose}>
          <Menu
            id="fade-menu"
            anchorEl={anchorEl}
            sx={{ '& .MuiMenu-paper': { ...classes.actionsMenu } }}
            // keepMounted
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={(e) => addToCalendarAction(e, 'apple')}>
              <div style={classes.menuItem}>
                <AppleIcon style={classes.iconStyle} />
                <Typography
                  variant="caption_old_font"
                  sx={classes.menuItemText}
                >
                  Apple
                </Typography>
              </div>
            </MenuItem>
            <MenuItem onClick={(e) => addToCalendarAction(e, 'google')}>
              <div style={classes.menuItem}>
                <GoogleIcon style={classes.iconStyle} />
                <Typography
                  variant="caption_old_font"
                  sx={classes.menuItemText}
                >
                  Google
                </Typography>
              </div>
            </MenuItem>
            <MenuItem onClick={(e) => addToCalendarAction(e, 'outlook')}>
              <div style={classes.menuItem}>
                <OutlookIcon style={classes.iconStyle} />
                <Typography
                  variant="caption_old_font"
                  sx={classes.menuItemText}
                >
                  Outlook
                </Typography>
              </div>
            </MenuItem>
            <MenuItem onClick={(e) => addToCalendarAction(e, 'microsoft')}>
              <div style={classes.menuItem}>
                <MicrosoftIcon style={classes.iconStyle} />
                <Typography
                  variant="caption_old_font"
                  sx={classes.menuItemText}
                >
                  Microsoft
                </Typography>
              </div>
            </MenuItem>
          </Menu>
        </Box>

        <div style={{ ...classes.titleContainer, marginLeft: '20px' }}>
          <Typography sx={classes.tileTitle}>
            {companyHasUpcomingMeeting
              ? company.upcomingBoardMeetingData &&
                company.upcomingBoardMeetingData.postTitle
                ? company.upcomingBoardMeetingData.postTitle
                : t('Q-BOARD-MEETING')
              : t('NON-Q-BOARD-MEETING')}
          </Typography>
        </div>
        <Typography
          sx={classes.tileText}
          style={{
            marginTop: isSM ? '5px' : '10px',
            marginLeft: isSM ? '5px' : '20px',
          }}
        >
          {companyHasUpcomingMeeting
            ? t('JOIN-US', { boardMeetingDate })
            : t('SUBSCRIBE-EMAIL')}
        </Typography>
        <div style={classes.windowIconContainer}>
          <Building2 />
        </div>
      </Paper>
      <Paper
        elevation={0}
        sx={{ ...classes.tile, ...classes.chooseTile }}
        id="boardRoomTour"
      >
        <Typography
          sx={classes.tileTitle}
          style={{
            marginTop: isSM ? 5 : '20px',
            marginLeft: isSM ? 5 : '20px',
          }}
        >
          About Digital Boardrooms
        </Typography>
        <Typography
          sx={classes.secondTileText}
          style={{ marginLeft: isSM ? 5 : '20px' }}
        >
          Learn how to interact and collaborate with company leadership and
          fellow board members.
        </Typography>
        <Typography
          sx={classes.secondTileText}
          style={{ marginLeft: isSM ? 5 : '20px' }}
        >
          {`Take the tour >>`}
        </Typography>
        <div style={classes.buildingIconContainer}>
          <Building1 />
        </div>
      </Paper>
    </div>
  );
}
export default BoardroomsTiles;
