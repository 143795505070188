import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Box,
  Button,
  Paper,
  Typography,
  useMediaQuery,
  IconButton,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@mui/material';
import {
  EditOutlined,
  AddCircleOutlineOutlined,
  CancelRounded,
  Edit,
  CheckCircle,
} from '@mui/icons-material';
// import AddCircleOutlineOutlined from '@mui/icons-material/AddCircleOutlineOutlined';
import CompanyContact from './../Contact/CompanyContact';
import AutocompleteCustome from '../OnboardingFreemium/Autocomplete';
import Tag from './../../Tag';
import CompanyOwnSlideHeader from './CompanyOwnSlideHeader';
import CompanyOwnProfileTilesSlide from './CompanyOwnProfileTilesSlide';
import BrandYourCompanySlider from '../OnboardingFreemium/BrandYourCompanySlider';
import {
  slideUpdateCompany,
  uploadCompanyLogoCall,
} from './../../../services/company_services';
import CustomIcon from './../../../icons/customIcon';
import { useAccount } from './../../../contexts/Account';
import DefaultAdvisorAvatar from './../../../icons/user-avatar.svg';
import ACButton from './../../ACButton';
import { ReactComponent as PerksImage } from './../../../scenes/Boardrooms/img/perks.svg';
import { ReactComponent as RolesImage } from './../../../scenes/Boardrooms/img/formal_roles.svg';
import { ReactComponent as EquityImage } from './../../../scenes/Boardrooms/img/equity_potential.svg';
import { ReactComponent as CompensationImage } from './../../../scenes/Boardrooms/img/compensation_potential.svg';
import StarSymbol from './../../Advisor/Profile/components/star-symbol-white.svg';
import eventBus, { eventBusValues } from './../../../eventBus';
import LoadingIndicatorLogo from './../../LoadingIndicatorLogo';

const useStyles = (isSM) => ({
  card: {
    width: '100%',
    paddingBottom: '50px',
  },
  companyInformationSectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: isSM ? '20px' : '40px',
    paddingRight: '20px',
    paddingTop: '20px',
    width: '100%',
    paddingBottom: '20px',
  },
  mainInformationContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  tagsTitle: {
    fontWeight: 600,
    fontFamily: 'Poppins',
    fontSize: '20px',
    color: '#232B35',
    marginTop: '30px',
    marginBottom: '15px',
    letterSpacing: '-0.43px',
  },
  upperBarText: {
    fontFamily: 'Poppins',
    fontSize: '90%',
  },
  generalMargin: {
    marginRight: '8px',
    marginBottom: '5px',
  },
  tagsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  boardsContainer: {
    '& > *': {},
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  biographyText: {
    whiteSpace: 'pre-wrap',
    fontSize: '16px',
    fontFamily: 'Poppins',
    fontWeight: 400,
  },
  upperBar: {
    width: '100%',
    backgroundColor: '#232B35',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#ffffff',
    height: '60px',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    padding: isSM ? '15px' : '10px',
    cursor: 'pointer',
  },
  benefitsContainer: {
    marginTop: '20px',
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'row',
  },
  benefitItem: {
    border: '1px solid #EFF0F7',
    borderRadius: '12px',
    marginRight: isSM ? '0px' : '1rem',
    marginBottom: isSM ? '20px' : '0px',
    width: '330px',
    padding: '24px',
    margin: '14px 15px 14px 0px',
  },
  benefitsDetailsText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    color: '#646D7A',
  },
  benefitsCategoryText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    color: '#232B35',
    whiteSpace: 'nowrap',
    marginBottom: '11px',
  },
  additionalBenefitsTitle: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '24px',
    marginBottom: '10px',
  },
  additionalBenefitsRightContainer: {
    display: !isSM ? 'flex' : 'none',
    flexDirection: 'column',
    width: '30%',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginRight: '4%',
  },
  additionalBenefitsLeftContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  benefitImage: {
    width: '118px',
    height: '96px',
  },
  button: {
    height: '20px',
    width: '20px',
    marginLeft: '3px',
  },
  iconbutton: {
    height: '17px',
    width: '17px',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginLeft: '10px',
  },
  mainButton: {
    width: '94px',
    height: '44px',
    borderRadius: '10px',
    letterSpacing: -0.43,
    fontStyle: 'normal',
    textTransform: 'none',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '24px',
    backgroundColor: '#3171F6',
    color: 'white',
    marginLeft: '10px',
    marginTop: '10px',
    marginBottom: '10px',
    '&:hover': {
      backgroundColor: '#3171F6',
    },
    '&:disabled': {
      opacity: 0.5,
      backgroundColor: '#3171F6',
      color: 'white',
    },
  },
  secondaryButton: {
    width: '94px',
    height: '44px',
    letterSpacing: -0.43,
    fontStyle: 'normal',
    textTransform: 'none',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#646D7A',
    marginLeft: '10px',
    marginTop: '10px',
    marginBottom: '10px',
    '&:disabled': {
      opacity: 0.5,
      color: '#646D7A',
    },
  },
  addNew: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: '500',
    color: '#3171F6',
    fontStyle: 'normal',
    letterSpacing: -0.43,
    cursor: 'pointer',
  },
  loadingContainer: {
    alignItems: 'center',
    bottom: '0px',
    display: 'flex',
    justifyContent: 'center',
    left: '0px',
    position: 'fixed',
    right: '0px',
    top: '0px',
  },
  subtitle: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '400',
    color: '#232B35',
    fontStyle: 'normal',
    fontWeight: 700,
    letterSpacing: -0.43,
  },
});

const CompanyOwnProfileSlide = forwardRef((props, ref) => {
  let {
    company,
    companyMembers,
    handleClickAway,
    setCompany,
    boxRef,
    setIsEditing,
  } = props;
  const {
    accountDetails,
    tags,
    asLoggedUserDetails,
    editAccountInformation,
    isFreemium,
    isImpersonated,
  } = useAccount();
  let { industries, skills } = tags;
  const { t } = useTranslation();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down([1230]));
  const classes = useStyles(isSM);
  const [loading, setLoading] = useState(false);
  const [isEditingState, setIsEditingState] = useState(false);
  const [inEditForm, setInEditForm] = useState('');
  const [companyCEO, setCompanyCEO] = useState('');
  const [addBenefitModal, setAddBenefitModal] = useState(false);
  const [benefitToEdit, setBenefitToEdit] = useState(null);
  const [category, setCategory] = useState('');
  const [details, setDetails] = useState('');
  const [editValues, setEditValues] = useState({
    displayName: company.displayName,
    storyAndMission: company.storyAndMission,
    desiredSkillsTagIds: company.desiredSkillsTagIds
      ? company.desiredSkillsTagIds
      : [],
    industryTagIds: company.industryTagIds ? company.industryTagIds : [],
    desiredIndustriesTagIds: company.desiredIndustriesTagIds || [],
    benefits: company.benefits ? company.benefits : [],
    website: company.website,
    companySize: company.companySize,
  });
  const [brandYourCompanySlider, setBrandYourCompanySlider] = useState(
    accountDetails.brandFreemium
  );
  const [uploaderOutput, setUploaderOutput] = useState({});
  const categoryOptions = [
    {
      text: 'Compensation Potential',
      description: 'Compensated opportunities for advisors',
      icon: 'benefits-compensation',
      image: <CompensationImage style={classes.benefitImage} />,
    },
    {
      text: 'Equity Potential',
      description: 'Equity opportunities for advisors',
      icon: 'benefits-equity',
      image: <EquityImage style={classes.benefitImage} />,
    },
    {
      text: 'Formal Roles',
      description: 'Formal role opportunities for advisors',
      icon: 'benefits-roles',
      image: <RolesImage style={classes.benefitImage} />,
    },
    {
      text: 'Perks',
      description: 'Perks and discounts for advisors',
      icon: 'benefit-perk',
      image: <PerksImage style={classes.benefitImage} />,
    },
  ];

  let featuredBenefits = [];
  featuredBenefits = company.benefits;

  const desiredSkills = company.desiredSkillsTagIds
    ? company.desiredSkillsTagIds
    : [];

  skills = skills
    ? skills.filter((skill) => desiredSkills.includes(skill.id))
    : [];

  const industryTags = company.industryTagIds ? company.industryTagIds : [];

  industries = industries
    ? industries.filter((industry) => industryTags.includes(industry.id))
    : [];

  const desiredIndustriesTags = company.desiredIndustriesTagIds
    ? company.desiredIndustriesTagIds
    : [];

  const [desiredIndustries, setDesiredIndustries] = useState(
    tags.industries.filter((industry) =>
      desiredIndustriesTags.includes(industry.id)
    )
  );

  useImperativeHandle(ref, () => ({
    triggerFunction: async () => {
      await sendFormData();
      handleClickAway();
    },
  }));

  useEffect(() => {
    if (companyMembers.length > 0) {
      setCompanyCEO(companyMembers[0]);
    }
  }, [companyMembers]);

  useEffect(() => {
    if (isEditingState) {
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
  }, [isEditingState]);

  function handleEditChange(event) {
    const { name, value } = event.target;
    setEditValues({ ...editValues, [name]: value });
  }

  function addNewBenefit(benefit) {
    let updatedBenefits = null;
    if (benefitToEdit) {
      updatedBenefits = editValues.benefits.map((bnf) => {
        if (
          bnf.category == benefitToEdit.category &&
          bnf.details == benefitToEdit.details
        ) {
          return benefit;
        }
        return bnf;
      });
      setBenefitToEdit(null);
    } else {
      updatedBenefits = [...editValues.benefits, benefit];
    }
    setBenefits(updatedBenefits);
    return updatedBenefits;
  }

  const featuredBenefit = (benefitToFeatured) => {
    const featuredCount = editValues.benefits
      ? editValues.benefits.filter((bnf) => bnf.featured).length
      : 0;
    if (featuredCount == 3 && !benefitToFeatured.featured) {
      return;
    }
    const updatedBenefits = editValues.benefits.map((bnf) => {
      if (
        bnf.category == benefitToFeatured.category &&
        bnf.details == benefitToFeatured.details
      ) {
        return {
          ...benefitToFeatured,
          featured: benefitToFeatured.featured ? false : true,
        };
      }
      return bnf;
    });
    setBenefits(updatedBenefits);
  };

  const removeBenefit = (benefitToDelete) => {
    const updatedBenefits = editValues.benefits.filter(
      (benefit) =>
        benefit.details !== benefitToDelete.details ||
        benefit.category !== benefitToDelete.category
    );
    setBenefits(updatedBenefits);
  };

  const editBenefit = (benefit) => {
    setAddBenefitModal(true);
    setBenefitToEdit(benefit);
    setCategory(benefit.category);
    setDetails(benefit.details);
  };

  const benefitsToShow = () => {
    if (isEditingState) {
      return editValues.benefits;
    }
    return featuredBenefits;
  };

  const setBenefits = (benefits) => {
    setEditValues({ ...editValues, benefits });
  };

  function handleIndustryAutocompleteChange(event, vals) {
    setEditValues({
      ...editValues,
      industryTagIds: vals.map((val) => val.id),
    });
  }

  function handleDesiredIndustryAutocompleteChange(event, vals) {
    if (vals.length > 10) {
      return;
    } else {
      setEditValues({
        ...editValues,
        desiredIndustriesTagIds: vals.map((val) => val.id),
      });
      setDesiredIndustries(vals);
    }
  }

  function handleSkillsAutocompleteChange(event, vals) {
    setEditValues({
      ...editValues,
      desiredSkillsTagIds: vals.map((val) => val.id),
    });
  }

  function renderCompanyBiographyText() {
    if (!company) return;
    if (!company.storyAndMission) return;
    return (
      <>
        <Typography variant="body1" sx={classes.biographyText}>
          {company.storyAndMission}
        </Typography>
      </>
    );
  }

  function renderEditSaveButton(field) {
    if (isEditingState && !inEditForm) {
      return null;
    }

    if (!isEditingState || (inEditForm !== field && inEditForm)) {
      return (
        <IconButton
          sx={classes.button}
          onClick={() => {
            setIsEditingState(true);
            setInEditForm(field);
          }}
        >
          <EditOutlined sx={{ ...classes.iconbutton }} />
        </IconButton>
      );
    }
    return (
      <>
        <IconButton
          sx={classes.button}
          disabled={editValues[field] == company[field]}
          onClick={sendFormData}
        >
          <CheckCircle
            sx={{
              ...classes.iconbutton,
              color:
                editValues[field] != company[field] ? '#19AD6E' : '#F5F7FA',
            }}
          />
        </IconButton>
        <IconButton
          sx={classes.button}
          // disabled={editValues[field] == company[field]}
          onClick={() => {
            setIsEditingState(false);
            setInEditForm('');
          }}
        >
          <CancelRounded
            sx={{
              ...classes.iconbutton,
              // color:
              //   editValues[field] != company[field] ? '#FEABAC' : '#F5F7FA',
              color: '#E0293F',
            }}
          />
        </IconButton>
      </>
    );
  }

  function getIconByText(text) {
    const option = categoryOptions.find((option) => option.text === text);
    return option ? option.icon : '';
  }

  function formatDetails(text) {
    var urlPattern = /(https?:\/\/[^\s]+)/g;
    var cleanedText = text.replace(urlPattern, '');

    cleanedText = cleanedText.substring(0, 250);

    return cleanedText;
  }

  const getHeaderText = () => {
    if (accountDetails.userSegment === 'managed_advisory_board') {
      return (
        <>
          Let your network know you're building an Advisory Board!{' '}
          <span style={{ textDecoration: 'underline' }}>Learn more</span>
        </>
      );
    }
    return (
      <>
        Let your network know you're building an AdvisoryCloud!{' '}
        <span style={{ textDecoration: 'underline' }}>Learn more</span>
      </>
    );
  };

  async function sendFormData() {
    try {
      setLoading(true);
      if (addBenefitModal) {
        if (category && details && details.length >= 10) {
          editValues.benefits = addNewBenefit({ category, details });
        }
        setCategory('');
        setDetails('');
        setAddBenefitModal(false);
        setBenefitToEdit(null);
      }
      let data = {
        CONTACT_ID: asLoggedUserDetails.userId,
        DISPLAY_NAME: editValues.displayName || '',
        COMPANY_NAME: editValues.displayName || '',
        COMPANY_STORY: editValues.storyAndMission || '',
        COMPANY_INDUSTRY: editValues.industryTagIds,
        COMPANY_DESIRED_INDUSTRY: editValues.desiredIndustriesTagIds,
        COMPANY_SKILLS: editValues.desiredSkillsTagIds,
        COMPANY_BENEFITS: editValues.benefits.map((e) => JSON.stringify(e)),
        COMPANY_WEBSITE: editValues.website || '',
        COMPANY_SIZE: editValues.companySize || '',
        BRAND_FREEMIUM: !!brandYourCompanySlider,
      };
      await slideUpdateCompany(data);

      let accountDetailsModifiedValues = {
        displayName: editValues.displayName,
        storyAndMission: editValues.storyAndMission,
        industryTagIds: editValues.industryTagIds,
        desiredSkillsTagIds: editValues.desiredSkillsTagIds,
        desiredIndustriesTagIds: editValues.desiredIndustriesTagIds,
        benefits: editValues.benefits,
        companySize: editValues.companySize,
        website: editValues.website,
        brandFreemium: brandYourCompanySlider,
      };

      if (
        uploaderOutput &&
        uploaderOutput.image != uploaderOutput.originalImage
      ) {
        const response = await uploadCompanyLogoCall({
          CONTACT_ID: asLoggedUserDetails.userId,
          COMPANY_LOGO_EDITED: uploaderOutput.image,
          COMPANY_LOGO_ORIGINAL: uploaderOutput.originalImage,
        });
        if (!uploaderOutput.image) {
          accountDetailsModifiedValues.image = null;
          accountDetailsModifiedValues.companyS3Logo = null;
        } else {
          const fixedLocation =
            response.data.uploadCompanyLogo.location.split('https://');
          const lastIndex = fixedLocation[1].lastIndexOf('.');
          const extension = fixedLocation[1].slice(lastIndex + 1);
          accountDetailsModifiedValues.image = {
            id: response.data.uploadCompanyLogo.id + '.' + extension,
            location: fixedLocation[1],
          };
          accountDetailsModifiedValues.companyS3Logo = {
            id: response.data.uploadCompanyLogo.id + '.' + extension,
            location: response.data.uploadCompanyLogo.location,
          };
        }
      }

      editAccountInformation(accountDetailsModifiedValues);
      setCompany({
        ...company,
        ...accountDetailsModifiedValues,
      });
      setIsEditingState(false);
      setInEditForm('');
      setLoading(false);
      return true;
    } catch (error) {
      throw error;
    }
  }

  return (
    <>
      <div style={classes.upperBar} id="slidePrefileUpperBar">
        <Typography variant="body1" sx={classes.upperBarText}>
          <img
            src={StarSymbol}
            alt="star-symbol"
            style={{
              marginBottom: '-10px',
              marginRight: '10px',
              width: '30px',
              height: '30px',
              cursor: 'default',
            }}
          />
          {getHeaderText()}
        </Typography>
      </div>
      <Paper sx={classes.card}>
        <div style={classes.companyInformationSectionContainer}>
          <div style={classes.mainInformationContainer}>
            <CompanyOwnSlideHeader
              handleClickAway={handleClickAway}
              company={company}
              companyCEO={companyCEO}
              isEditingState={isEditingState}
              setIsEditingState={setIsEditingState}
              sendFormData={sendFormData}
              handleEditChange={handleEditChange}
              editValues={editValues}
              renderEditSaveButton={renderEditSaveButton}
              inEditForm={inEditForm}
              setInEditForm={setInEditForm}
              setUploaderOutput={setUploaderOutput}
              boxRef={boxRef}
            />
          </div>
          {isFreemium && isEditingState && !inEditForm && (
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography
                  sx={{
                    ...classes.tagsTitle,
                    marginTop: '25px',
                    marginBottom: '0px',
                    fontSize: isSM ? '14px' : '20px',
                  }}
                >
                  Brand your AdvisoryCloud
                </Typography>
              </Grid>
              <Grid item sx={{ marginTop: '25px', flexGrow: 1 }}>
                <BrandYourCompanySlider
                  brandYourCompanySlider={brandYourCompanySlider}
                  setBrandYourCompanySlider={setBrandYourCompanySlider}
                ></BrandYourCompanySlider>
              </Grid>
              <Grid item></Grid>
            </Grid>
          )}

          <CompanyOwnProfileTilesSlide company={company} />
          <Typography variant="body1" sx={{ ...classes.tagsTitle }}>
            About {company.displayName}
            {renderEditSaveButton('storyAndMission')}
          </Typography>
          {isEditingState &&
          (inEditForm == 'storyAndMission' || !inEditForm) ? (
            <TextField
              multiline
              onChange={handleEditChange}
              name="storyAndMission"
              height={269}
              textFieldDisplayName="Biography"
              placeholder={t('ADVISOR-ONBOARDING-STEP-1-BIOGRAPHY-PLACEHOLDER')}
              value={editValues.storyAndMission}
              InputProps={{
                maxLength: 2000,
                style: {
                  ...classes.fieldContainer,
                  // paddingTop: '0px',
                  borderRadius: '10px',
                },
              }}
            />
          ) : (
            renderCompanyBiographyText()
          )}
          <Typography variant="body1" sx={classes.tagsTitle}>
            Industry{renderEditSaveButton('industryTagIds')}
          </Typography>
          {isEditingState && (inEditForm == 'industryTagIds' || !inEditForm) ? (
            <AutocompleteCustome
              parentStyle={classes.fieldContainer}
              defaultValue={industries}
              onChange={handleIndustryAutocompleteChange}
              getOptionLabel={(option) => option.label}
              name="industryTagIds"
              limitTags={3}
              options={tags.industries}
              placeholder="Start typing or click to select a industry."
            />
          ) : (
            <div className="skillTagBlock" style={classes.tagsContainer}>
              {industries.map((skill, index) => (
                <Box sx={classes.generalMargin} key={index}>
                  <Tag label={skill.label} />
                </Box>
              ))}
            </div>
          )}
          <Typography variant="body1" sx={classes.tagsTitle}>
            Desired Industries {renderEditSaveButton('desiredIndustriesTagIds')}
          </Typography>
          {isEditingState &&
          (inEditForm == 'desiredIndustriesTagIds' || !inEditForm) ? (
            <>
              <Typography
                variant="caption_old_font"
                sx={{
                  ...classes.subtextText,
                  marginTop: '-20px',
                  textAlign: 'right',
                  color: 'grey.600',
                }}
              >
                You can select up to 10 industries
              </Typography>
              <AutocompleteCustome
                parentStyle={classes.fieldContainer}
                value={desiredIndustries}
                onChange={handleDesiredIndustryAutocompleteChange}
                limitTags={3}
                getOptionLabel={(option) => option.label}
                name="desiredIndustriesTagIds"
                options={tags.industries}
                placeholder="Start typing or click to select a industry."
              />
            </>
          ) : (
            <div className="skillTagBlock" style={classes.tagsContainer}>
              {desiredIndustries.map((skill, index) => (
                <Box sx={classes.generalMargin} key={index}>
                  <Tag label={skill.label} />
                </Box>
              ))}
            </div>
          )}

          <Typography variant="body1" sx={classes.tagsTitle}>
            {t('SEEKING-GUIDANCE-ON-LABEL')}
            {renderEditSaveButton('desiredSkillsTagIds')}
          </Typography>
          {isEditingState &&
          (inEditForm == 'desiredSkillsTagIds' || !inEditForm) ? (
            <AutocompleteCustome
              parentStyle={classes.fieldContainer}
              defaultValue={skills}
              onChange={handleSkillsAutocompleteChange}
              getOptionLabel={(option) => option.label}
              name="desiredSkillsTagIds"
              limitTags={3}
              options={tags.skills}
              placeholder="Start typing or click to select a skill."
            />
          ) : (
            <div className="skillTagBlock" style={classes.tagsContainer}>
              {skills.map((skill, index) => (
                <Box sx={classes.generalMargin} key={index}>
                  <Tag label={skill.label} />
                </Box>
              ))}
            </div>
          )}

          <Typography variant="body1" sx={{ ...classes.tagsTitle }}>
            Team Members
          </Typography>
          <div style={classes.boardsContainer}>
            {companyMembers ? (
              <>
                {companyMembers.map((member) => (
                  <Box className={classes.generalMargin} key={member.id}>
                    <CompanyContact
                      contact={member}
                      customAvatar={() => (
                        <Avatar
                          sx={{ width: '40px', height: '40px' }}
                          src={
                            member.contactLogoId
                              ? member.contactLogoLocation
                              : DefaultAdvisorAvatar
                          }
                        />
                      )}
                    />
                  </Box>
                ))}
              </>
            ) : null}
          </div>
          <Typography
            sx={classes.addNew}
            onClick={() =>
              eventBus.dispatch(eventBusValues.triggerInviteTeamModal)
            }
          >
            <span id="inviteTeamMemberCta">
              <AddCircleOutlineOutlined
                sx={{
                  fontSize: '17px',
                  marginRight: '6px',
                  marginLeft: '3px',
                  marginBottom: '-3px',
                }}
              />
              Add another team member
            </span>
          </Typography>

          <div id="benefitsSection">
            <Typography variant="body1" sx={classes.tagsTitle}>
              Benefits for advisors{renderEditSaveButton('benefits')}
            </Typography>
            <Grid container style={classes.benefitsContainer}>
              {featuredBenefits &&
                benefitsToShow().map((benefit) => (
                  <div
                    className="featureBenefitItem"
                    style={classes.benefitItem}
                  >
                    <div style={classes.additionalBenefitsLeftContainer}>
                      <div style={{ marginRight: '8px', marginTop: '-5px' }}>
                        <CustomIcon
                          iconname={getIconByText(benefit.category)}
                          color="primary"
                          fontSize="small"
                          style={{
                            color: '#232B35',
                            display: 'inline',
                          }}
                        />
                      </div>
                      <Typography sx={classes.benefitsCategoryText}>
                        {benefit.category}
                      </Typography>
                      <div style={classes.actions}>
                        {isEditingState &&
                        (inEditForm == 'benefits' || !inEditForm) ? (
                          <div>
                            <IconButton
                              sx={classes.button}
                              onClick={() => {
                                editBenefit(benefit);
                              }}
                            >
                              <Edit sx={classes.iconbutton} />
                            </IconButton>
                            <IconButton
                              sx={classes.button}
                              onClick={() => {
                                removeBenefit(benefit);
                              }}
                            >
                              <CancelRounded sx={classes.iconbutton} />
                            </IconButton>
                            {isImpersonated && (
                              <IconButton
                                sx={classes.button}
                                onClick={() => {
                                  featuredBenefit(benefit);
                                }}
                              >
                                <CustomIcon
                                  iconname={
                                    benefit.featured ? 'star' : 'star-outlined'
                                  }
                                  style={{
                                    fontSize: '18px',
                                    marginTop: '-3px',
                                  }}
                                />
                              </IconButton>
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div style={classes.additionalBenefitsLeftContainer}>
                      <Box sx={{ width: '100%' }}>
                        <Typography sx={classes.benefitsDetailsText}>
                          {formatDetails(benefit.details)}
                        </Typography>
                      </Box>
                    </div>
                  </div>
                ))}
            </Grid>
          </div>
          {addBenefitModal ? (
            <Box>
              <Grid container justifyContent="space-between">
                <Grid item sx={{ marginTop: '5px', marginBottom: '8px' }}>
                  <Typography
                    variant="caption_old_font"
                    sx={classes.subtextText}
                  >
                    Choose any benefits or compensation opportunities for
                    advisors
                  </Typography>
                </Grid>
              </Grid>
              <FormControl fullWidth>
                <InputLabel
                  sx={{
                    height: '44px',
                    top: '-4px',
                    fontFamily: 'Poppins',
                    display: !category ? 'block' : 'none',
                  }}
                  id="demo-simple-select-label"
                  variant="filled"
                >
                  Select a benefits category
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={category || ''}
                  placeholder="Select a benefits category"
                  sx={{
                    height: '44px',
                    borderRadius: '10px',
                  }}
                  onChange={(e) => {
                    setCategory(e.target.value);
                  }}
                >
                  {categoryOptions.map((option) => (
                    <MenuItem
                      sx={classes.menuItem}
                      key={option.text}
                      value={option.text}
                    >
                      {option.text}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div style={classes.formInput}>
                <TextField
                  placeholder="A short description of your benefit is required. e.g. Offering equity awards for select members of our advisory board"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={5}
                  style={{
                    marginRight: 10,
                    marginTop: 15,
                  }}
                  value={details || ''}
                  InputProps={{
                    maxLength: 2000,
                    style: {
                      fontFamily: 'Poppins',
                      borderRadius: '10px',
                    },
                  }}
                  onChange={(evt) => setDetails(evt.target.value)}
                />
              </div>
              <Grid container justifyContent="flex-end">
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      addNewBenefit({ category, details });
                      setCategory('');
                      setDetails('');
                      setAddBenefitModal(false);
                      setBenefitToEdit(null);
                    }}
                    sx={classes.mainButton}
                    disabled={!category || !details || details.length < 10}
                  >
                    Save
                  </Button>
                </Box>
                <Box>
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => {
                      setCategory('');
                      setDetails('');
                      setAddBenefitModal(false);
                      setBenefitToEdit(null);
                    }}
                    sx={classes.secondaryButton}
                  >
                    Discard
                  </Button>
                </Box>
              </Grid>
            </Box>
          ) : null}
          {!addBenefitModal &&
          isEditingState &&
          (inEditForm == 'benefits' || !inEditForm) ? (
            <div style={classes.addContentContainer}>
              <ACButton
                color="primary"
                size="medium"
                variant="text"
                style={{
                  borderWidth: 2,
                  borderRadius: 5,
                  paddingLeft: 0,
                }}
                onClick={() => {
                  setAddBenefitModal(true);
                }}
              >
                <AddCircleOutlineOutlined sx={classes.plusIcon} />
                <Typography variant="old_font">Add a benefit</Typography>
              </ACButton>
            </div>
          ) : null}
        </div>
      </Paper>
      {loading ? (
        <div style={classes.loadingContainer}>
          <LoadingIndicatorLogo size={200} iconFontSize={93} iconRight={105} />
        </div>
      ) : null}
    </>
  );
});

export default CompanyOwnProfileSlide;
