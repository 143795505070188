import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Box, Typography, useMediaQuery } from '@mui/material';
import ACSnackbar from '../../components/ACSnackbar';
import EmailImage from '../../images/mail-check-outlined.svg';
import { forgotPassword } from '../../services/Authentication';
import CardScene from '../../components/CardScene';
import CardFooter from '../../components/CardFooter';
import OopsWindow from '../Oops';

const useStyles = (isSM) => ({
  card: {
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: 'white',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    height: '350px',
    width: !isSM && '600px',
    paddingTop: '20px',
  },
  confirmationLabelTop: {
    textAlign: 'center',
    color: 'secondary.dark',
    wordBreak: 'break-all',
    margin: '0px',
    padding: '0px',
    display: 'inline-flex',
    flexDirection: 'column',
  },
  subtitle: {
    maxWidth: '400px',
    textAlign: 'center',
    color: 'secondary.dark',
  },
  emailCheckedImage: {
    height: '100px',
    width: '100px',
  },
});

const CompanyConfirmation = () => {
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email');
  const [isQueryParamCompliant] = useState(() => {
    return email !== null;
  });

  async function handleResendConfirmationEmailButton() {
    await forgotPassword(queryParams.get('email'), {
      workflow: 'signup',
    });
    setIsSnackbarVisible(true);
  }

  return (
    <>
      {isQueryParamCompliant ? (
        <>
          <Helmet>
            <title>{t('COMPANY-HELMET-TITLE')}</title>
          </Helmet>
          <CardScene>
            <div id="confirmation-card" style={classes.card}>
              <ACSnackbar
                id="confirmation-request-received-snackbar"
                text={t('COMPANY-REQUEST-RECEIVED-LABEL')}
                onClose={() => setIsSnackbarVisible(false)}
                open={isSnackbarVisible}
              />
              <img
                alt={t('COMPANY-EMAIL-CHECKED-IMAGE-ALT')}
                style={classes.emailCheckedImage}
                src={EmailImage}
              />
              <Box sx={classes.confirmationLabelTop}>
                <Typography variant="h2_old_font">
                  {t('COMPANY-LABEL')}
                </Typography>
                <Typography
                  sx={classes.confirmationLabelTop}
                  variant="h2_old_font"
                >
                  {String(email)}
                </Typography>
              </Box>
              <Typography sx={classes.subtitle} variant="h2_old_font">
                {t('COMPANY-CLICK-EMAIL-LABEL')}
              </Typography>

              <CardFooter onClickFooter={handleResendConfirmationEmailButton} />
            </div>
          </CardScene>
        </>
      ) : (
        <>
          <Helmet>
            <title>{t('PASSWORD_RESET_HEADER_TITLE')}</title>
          </Helmet>
          <OopsWindow />
        </>
      )}
    </>
  );
};

export default CompanyConfirmation;
