import React from "react";
import CustomIcon from './../../../icons/customIcon';
import { Typography } from "@mui/material"; 

const useStyles = (props) => ({
  mainConatiner: {
    display: "flex",
    flexDirection: "row",
    flexShrink: 0,
  },
  shrinkControlContainer: {
    flexShrink: 0,
  },
  button: {
    border: "none",
    display: "flex",
    outline: "none",
    backgroundColor: "transparent",
    flexShrink: 0,
    padding: 0,
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    width: "fit-content",
    "&:disabled": {
      cursor: "default",
    },
  },
  brkthText: {
    marginTop: 3,
    color: props.goldColor ? "#FAC35F" : 'theme.palette.info.dark',
    fontWeight: props.bold ? "bold" : null,
  },
  brkthTextMobile: {
    color: props.goldColor ? "#FAC35F" : 'theme.palette.info.dark',
    fontWeight: props.bold ? "bold" : null,
    fontSize: "0.7rem",
  },
});


export default function BreakthroughsComponent(props) {
  const {
    numBreakthroughs,
    onClick,
    iconSize,
    filled,
    disabled,
    goldColor,
    bold,
  } = props;
  const classes = useStyles(props);

  const catchTrueBreakthrough = numBreakthroughs.toString();

  function renderBrkth() {
    if (numBreakthroughs === null || numBreakthroughs === undefined) {
      return null;
    }
    if (isNaN(numBreakthroughs)) {
      return (
        <Typography
          variant="body1"
          sx={classes.brkthText}
          style={{ fontSize: iconSize === "xs" ? "0.7rem" : "0.875rem" }}
        >
          Breakthroughs
        </Typography>
      );
    }
    if (numBreakthroughs && catchTrueBreakthrough === "true") {
      return (
        <Typography
          variant="body1"
          sx={classes.brkthText}
          style={{ fontSize: iconSize === "xs" ? "0.7rem" : "0.875rem" }}
        >
          Breakthroughs
        </Typography>
      );
    }
    return (
      <Typography
        variant="body1"
        sx={classes.brkthText}
        style={{ fontSize: iconSize === "xs" ? "0.7rem" : "0.875rem" }}
      >
        {`${numBreakthroughs} Breakthroughs`}
      </Typography>
    );
  }

  function sizeSelector() {
    if (iconSize === "xs") {
      return 15.5;
    }
    if (iconSize === "sm") {
      return 17;
    }
    if (iconSize === "md") {
      return 19.5;
    }
    if (iconSize === "lg") {
      return 23;
    }
    return 17;
  }

  function renderIcon() {
    if (filled) {
      return "breakthrough";
    }
    return "breakthrough-outlined";
  }

  return (
    <React.Fragment>
      <div className={classes.mainConatiner}>
        <div className={classes.shrinkControlContainer}>
          <button
            className={classes.button}
            onClick={(event) => {
              onClick();
              event.stopPropagation();
            }}
            id="breakthroughsButton"
            type="button"
            disabled={disabled}
          >
            <CustomIcon
              iconname={renderIcon()}
              style={{
                color: goldColor ? "#FAC35F" : "#162F4B",
                fontSize: sizeSelector(),
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            />
            {renderBrkth()}
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}
