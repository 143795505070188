import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Button } from '@mui/material';
import { Save } from '@mui/icons-material';

const useStyles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    padding: '0px',
    alignItems: 'center',
    border: 'none',
    cursor: 'pointer',
    minWidth: '60px',
  },
  icon: {
    width: '15px',
    height: '15px',
    marginRight: '0px',
    marginTop: '-1px',
  },
  text: {
    fontWeight: 'bold',
    fontFamily: 'Poppins',
  },
});

function EditButton({ onClick, disabled }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      // type="submit"
      style={{ ...classes.container, opacity: disabled ? 0.4 : 1 }}
    >
      <Save color="primary" sx={classes.icon} />
      <Typography sx={classes.text} color="primary">
        {t('SAVE-BUTTON-TEXT')}
      </Typography>
    </Button>
  );
}

export default EditButton;
