import React from 'react';
import { Helmet } from 'react-helmet';
import { Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CompanyStepper from '../../components/Company/Onboarding/CompanyStepper';
import Step1Form from '../../components/Company/Onboarding/Step1Form';
import { useAccount } from '../../contexts/Account';
import CardScene from '../../components/CardScene';

const useStyles = () => ({
  formContainer: {
    paddingTop: '30px',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingBottom: '20px',
  },
  stepSubtitle: {
    marginBottom: '5px',
    marginTop: '15px',
  },
  stepTitle: {
    color: 'primary.main',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
});

function step1(props) {
  const { isUpdate } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const { accountDetails, tags } = useAccount();
  const initialAdvisorSkills = tags.skills.filter((skill) =>
    (accountDetails.desiredSkillsTagIds || []).includes(skill.id)
  );
  const initialIndustries = tags.industries.filter((industry) =>
    (accountDetails.industryTagIds || []).includes(industry.id)
  );
  let formInitialValues = {
    advisorSkills: initialAdvisorSkills || [],
    companyIndustry: initialIndustries || [],
    companyName: accountDetails.companyName,
    companyPerks: accountDetails.perks,
    companyStoryAndMission: accountDetails.storyAndMission,
    companyWebsite: accountDetails.website,
  };

  if (isUpdate) {
    formInitialValues = {
      ...formInitialValues,
      companySize: accountDetails.companySize,
      displayName: accountDetails.displayName,
    };
  }

  let schemaShape = {
    companyName: Yup.string().required(),
    companyWebsite: Yup.string(),
    companyStoryAndMission: Yup.string().min(0),
    companyIndustry: Yup.array().min(3).max(10),
    advisorSkills: Yup.array().min(5).max(30),
  };

  if (isUpdate) {
    schemaShape = {
      ...schemaShape,
      displayName: Yup.string().required(),
      companySize: Yup.string().required(),
    };
  }
  const Step1Schema = Yup.object().shape(schemaShape);

  function renderForm(data) {
    const isValidIndex = Object.keys(data).indexOf('isValid');
    const IS_DISABLED = Object.values(data)[`${isValidIndex}`];

    return <Step1Form {...data} {...props} isDisabled={!IS_DISABLED} />;
  }

  const stepperHeader = () => {
    return (
      <div style={classes.headerContainer}>
        <Grid container direction="column">
          <Grid item>
            <Typography
              component="div"
              sx={classes.stepTitle}
              variant="h5_old_font"
              align="center"
            >
              {t('STEP1-TITLE')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              display="block"
              component="div"
              sx={classes.stepSubtitle}
              variant="h1_old_font"
              align="center"
            >
              {t('STEP1-SUBTITLE')}
            </Typography>
          </Grid>
          <CompanyStepper activeStep={0} />
        </Grid>
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>{t('STEP1-HELMET-TITLE')}</title>
      </Helmet>

      <CardScene header={stepperHeader()}>
        <div style={classes.formContainer}>
          <Formik
            initialValues={formInitialValues}
            validationSchema={Step1Schema}
            validateOnMount
          >
            {renderForm}
          </Formik>
        </div>
      </CardScene>
    </>
  );
}

export default step1;
