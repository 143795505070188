import React, { useState } from 'react';
import { Box, Button, Typography, Paper, useMediaQuery } from '@mui/material';
import { ReactComponent as TeamIcon } from './team.svg';
import { ReactComponent as MyselfIcon } from './myself.svg';
import { ReactComponent as OrganizationIcon } from './organization.svg';
import { useAccount } from './../../../contexts/Account';

const useStyles = ({ isXS }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    paddingBottom: '30px',
  },
  bodyText: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#2E384D',
  },
  rectangle: {
    width: isXS ? '283px' : '250px',
    height: '283px',
    borderRadius: '10px',
    margin: '10px',
    padding: isXS ? '20px' : '40px 20px',
    cursor: 'pointer',
    borderStyle: 'solid',
    borderColor: '#C7D0DE',
    borderWidth: 1,
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  selected: {
    borderColor: '#3171F6',
    borderStyle: 'solid',
    borderWidth: 3,
    padding: '38px 20px',
  },
  title: {
    fontFamily: 'Poppins',
    fontSize: '17px',
    fontWeight: 600,
    lineHeight: '26px',
    letterSpacing: 0.5,
    textAlign: 'left',
    marginBottom: '10px',
    textAlign: 'center',
  },
  description: {
    color: '#646D7A',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '21px',
    letterSpacing: 0.5,
    textAlign: 'center',
  },
  icon: {
    width: 62,
    height: 62,
    margin: '0 auto',
    marginBottom: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '98%',
    position: isXS ? 'sticky' : 'relative',
    bottom: 0,
    padding: '10px 0px 15px 0px',
    background: 'white',
  },
  button: {
    width: isXS ? '96%' : '339px',
    height: '44px',
    borderRadius: '50px',
    marginTop: '10px',
    marginBottom: '15px',

    border: 'none',
    outline: 'none',
    boxShadow: 'none',
    textTransform: 'none',

    color: '#FFFFFF',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '-0.4300000071525574px',
    textAlign: 'center',

    backgroundColor: '#3171F6',
    '&:hover': {
      boxShadow: 'none',
    },
    '&:disabled': {
      backgroundColor: '#E0E0E0',
      opacity: '1',
    },
  },
  container: {
    display: isXS ? 'block' : 'flex',
    justifyContent: 'space-between',
    marginBottom: 12,
  },
  titleText: {
    fontFamily: 'Poppins',
    fontSize: '28px',
    fontWeight: 700,
    lineHeight: isXS ? '34px' : '24px',
    letterSpacing: '-0.43px',
    textAlign: 'center',
  },
  subTitleText: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    textAlign: 'center',
    color: 'rgba(100, 109, 122, 1)',
  },
});

const UsageForm = ({ onSubmit }) => {
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const classes = useStyles({ isXS });
  const [loading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState(false);
  const { accountDetails } = useAccount();

  const options = [
    {
      title: 'For my organization',
      description: 'Get outside perspectives for key decisions and initiatives',
      icon: <OrganizationIcon></OrganizationIcon>,
    },
    {
      title: 'For my team',
      description:
        'Collaborate with experts to improve your team’s performance and ideas',
      icon: <TeamIcon></TeamIcon>,
    },
    {
      title: 'For myself',
      description:
        'Find mentors and experts, collaborate on personal initiatives and projects',
      icon: <MyselfIcon></MyselfIcon>,
    },
  ];

  const [selectedOption, setSelectedOption] = useState(null);

  const handleOptionClick = (index) => {
    setSelected(true);
    setSelectedOption(index);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    if (selected) {
      onSubmit(options[selectedOption].title, setIsLoading);
    }
  };

  const getHeaderText = () => {
    if (accountDetails.userSegment === 'managed_advisory_board') {
      return 'How are you planning to use your advisory board?';
    }
    return 'How are you planning to use AdvisoryCloud?';
  };

  return (
    <Box sx={classes.root}>
      <Typography variant="h2" sx={classes.titleText}>
        {getHeaderText()}
      </Typography>
      <Box margin="16px 0 32px">
        <Typography sx={classes.subTitleText}>
          We'll streamline your setup experience accordingly
        </Typography>
      </Box>
      <div style={classes.container}>
        {options.map((option, index) => (
          <Paper
            key={index}
            sx={{
              ...classes.rectangle,
              ...(selectedOption === index ? classes.selected : {}),
            }}
            onClick={() => handleOptionClick(index)}
            id={'B2B-AOBF-Step1-' + 'Option' + (index + 1)}
          >
            <div style={classes.icon}>{option.icon}</div>
            <Typography variant="h6" sx={classes.title}>
              {option.title}
            </Typography>
            <Typography sx={classes.description}>
              {option.description}
            </Typography>
          </Paper>
        ))}
      </div>
      <div style={classes.buttonContainer}>
        <Button
          id="B2B-AOBF-Step1-Continue"
          sx={classes.button}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!selected || loading}
        >
          Continue
        </Button>
      </div>
    </Box>
  );
};

export default UsageForm;
