import React, { useEffect, useState } from 'react';
import { useAccount } from './../../../contexts/Account';
import AdvisorItem from './AdvisorItem';
import { getAdvisorWithResponses } from './../../../services/Backend/account';
import { getAdvisorImagePath, isNil } from './../../../services/utils';

function AdvisorFetched(props) {
  const {
    id,
    buttonTitles,
    requestMeetingClickAction,
    answersClickAction,
    commentsClickAction,
    individualAdvisor,
    invitedAdvisorsMap,
    boardAdvisorsMap,
    mapSkills,
    removeBio,
    removeBelowButtons,
    onClickAction,
    isFavoritePage,
    index,
    setIsLoading,
  } = props;
  const { accountDetails } = useAccount();
  const [advisor, setAdvisor] = useState(null);

  useEffect(() => {
    let isMounted = true;
    if (index % 10 === 0 && setIsLoading) setIsLoading(true);
    function getAdvisorData() {
      getAdvisorWithResponses(id, accountDetails.id)
        .then((response) => {
          if (isMounted) {
            setAdvisor(response.data.getAdvisor);
          }
        })
        .catch((err) => {
          console.error({ err });
        })
        .finally(() => {
          if (isMounted && index % 10 === 0 && setIsLoading)
            setIsLoading(false);
        });
    }
    getAdvisorData();
    return () => {
      isMounted = false;
    };
  }, []);

  if (!advisor) {
    return null;
  }

  return (
    <AdvisorItem
      advisor={advisor}
      key={advisor.id}
      buttonTitles={
        !isNil(buttonTitles) && !boardAdvisorsMap[advisor.id]
          ? buttonTitles(advisor, index)
          : null
      }
      // answers
      requestMeetingClick={() => {
        requestMeetingClickAction(advisor);
      }}
      answers={advisor.countAnswers ? advisor.countAnswers : 0}
      answersClick={() => {
        individualAdvisor
          ? individualAdvisor.answersClickAction(advisor)
          : answersClickAction(advisor);
      }}
      comments={advisor.countComments ? advisor.countComments : 0}
      commentsClick={() => {
        individualAdvisor
          ? individualAdvisor.commentsClickAction(advisor)
          : commentsClickAction(advisor);
      }}
      invitedToJoinDate={
        invitedAdvisorsMap[advisor.id]
          ? invitedAdvisorsMap[advisor.id].inviteDate
          : null
      }
      showBottom={boardAdvisorsMap[advisor.id]}
      advisorName={advisor.displayName}
      advisorInfo={advisor.title}
      advisorAvatar={getAdvisorImagePath(advisor)}
      skills={mapSkills(advisor.skillTagIds)}
      bio={advisor.biography ? advisor.biography : ''}
      removeBio={removeBio || !advisor.biography}
      advisorDetailData={advisor}
      isStar
      removeBelowButtons={removeBelowButtons}
      cardClick={() => onClickAction(advisor)}
      isCardClickable
      isFavoritePage={isFavoritePage}
    />
  );
}
export default AdvisorFetched;
