import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Box } from '@mui/material';

export default function CardSkeleton() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #E6EDFF',
        borderRadius: '10px',
        backgroundColor: '#FFFFFF',
        padding: '25px',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xxs: 'column', sm: 'row' },
          justifyContent: { xxs: 'center', sm: 'space-between' },
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexShrink: 0,
            justifyContent: { xxs: 'center', sm: 'space-between' },
          }}
        >
          <Skeleton
            animation="wave"
            variant="circular"
            width={116}
            height={116}
            sx={{
              backgroundColor: '#F5F7FA',
              marginBottom: { xxs: '10px', sm: '0px' },
            }}
          />
        </Box>
        <Skeleton
          animation="wave"
          variant="rounded"
          sx={{
            display: { xxs: 'flex', sm: 'none' },
            width: { xxs: '100%', sm: '148px' },
            height: '48.38px',
            backgroundColor: '#F5F7FA',
            borderRadius: '25px',
            flexShrink: 0,
            marginTop: { xxs: '20px', sm: '0px' },
            marginBottom: { xxs: '20px', sm: '0px' },
          }}
        />
        <Box sx={{ flexGrow: 1, marginLeft: { xxs: '0px', sm: '20px' } }}>
          <Skeleton
            animation="wave"
            variant="rounded"
            height={34}
            sx={{
              backgroundColor: '#F5F7FA',
              marginBottom: '10px',
              borderRadius: '100px',
              width: '100%',
              maxWidth: '201px',
            }}
          />
          <Skeleton
            animation="wave"
            variant="rounded"
            height={22}
            sx={{
              backgroundColor: '#F5F7FA',
              marginBottom: '20px',
              borderRadius: '100px',
              width: '100%',
              maxWidth: '685px',
            }}
          />
          <Box
            sx={{
              display: 'flex',
              gap: '10px',
              flexWrap: 'wrap',
            }}
          >
            <Skeleton
              animation="wave"
              variant="rounded"
              height={34}
              sx={{
                backgroundColor: '#F5F7FA',
                borderRadius: '100px',
                flexGrow: 1,
                maxWidth: '201px',
                minWidth: '120px',
              }}
            />
            <Skeleton
              animation="wave"
              variant="rounded"
              height={34}
              sx={{
                backgroundColor: '#F5F7FA',
                borderRadius: '100px',
                flexGrow: 1,
                maxWidth: '138px',
                minWidth: '100px',
              }}
            />
            <Skeleton
              animation="wave"
              variant="rounded"
              height={34}
              sx={{
                backgroundColor: '#F5F7FA',
                borderRadius: '100px',
                flexGrow: 1,
                maxWidth: '133px',
                minWidth: '100px',
              }}
            />
            <Skeleton
              animation="wave"
              variant="rounded"
              height={34}
              sx={{
                backgroundColor: '#F5F7FA',
                borderRadius: '100px',
                flexGrow: 1,
                maxWidth: '163px',
                minWidth: '120px',
              }}
            />
          </Box>
        </Box>
        <Skeleton
          animation="wave"
          variant="rounded"
          sx={{
            display: { xxs: 'none', sm: 'flex' },
            width: '148px',
            height: '48.38px',
            backgroundColor: '#F5F7FA',
            borderRadius: '25px',
            flexShrink: 0,
            marginTop: { xxs: '20px', sm: '0px' },
          }}
        />
      </Box>
      <Box sx={{ flexGrow: 1, marginTop: '40px' }}>
        <Skeleton
          animation="wave"
          variant="rounded"
          height={21}
          sx={{
            backgroundColor: '#F5F7FA',
            borderRadius: '100px',
            marginBottom: '10px',
            width: '100%',
            // maxWidth: '961px',
          }}
        />
        <Skeleton
          animation="wave"
          variant="rounded"
          height={21}
          sx={{
            backgroundColor: '#F5F7FA',
            borderRadius: '100px',
            marginBottom: '20px',
            width: '100%',
            //maxWidth: '961px',
          }}
        />
        <Box sx={{ display: 'flex', gap: '0px 20px' }}>
          <Skeleton
            animation="wave"
            variant="rounded"
            height={34}
            sx={{
              backgroundColor: '#F5F7FA',
              borderRadius: '100px',
              width: '100%',
              maxWidth: '138px',
              flexGrow: 1,
            }}
          />
          <Skeleton
            animation="wave"
            variant="rounded"
            height={34}
            sx={{
              backgroundColor: '#F5F7FA',
              borderRadius: '100px',
              width: '100%',
              maxWidth: '138px',
              flexGrow: 1,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}
