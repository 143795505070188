import React from 'react';
import { Box } from '@mui/material';
import CircleSVGComponent from './CircleSVGComponent';
import InviteTeamMembers from '../Company/InviteTeamMembers';

export default function CompanyMyAdvisorsEmptyState() {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', maxWidth: '1200px' }}>
      <Box
        sx={{
          paddingLeft: '30px',
          paddingRight: '30px',
          paddingBottom: '30px',
          width: '560px',
        }}
      >
        <Box width={{ md: '112%', xxs: '104%' }}>
          <CircleSVGComponent icon="send" text="Invite your contacts!" />
        </Box>
        <InviteTeamMembers />
      </Box>
    </Box>
  );
}
