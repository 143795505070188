import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Paper, Typography, useMediaQuery } from '@mui/material';
import { PriorityHighOutlined } from '@mui/icons-material';
import IconUnderlay from '../IconUnderlay';

const useStyles = (isSM) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: isSM ? '100%' : '580px',
    alignItems: 'center',
    padding: '30px 20px',
  },
  titleContainer: {
    marginBottom: '10px',
    marginTop: '20px',
    textAlign: 'center',
  },
  subtitleContainer: {
    maxWidth: '400px',
    marginTop: '20px',
    marginBottom: '30px',
    textAlign: 'center',
  },
  priorityIcon: {
    fontSize: '60px',
  },
  textAroundButton: {
    display: 'inline',
    fontWeight: 'lighter',
  },
  textButton: {
    color: 'primary.main',
    padding: '0px',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    display: 'inline',
  },
  textContainer: {
    textAlign: 'center',
    width: '100%',
  },
  emailTitleText: {
    wordBreak: 'break-word',
  },
});

function ExpiredError({ onResend, email }) {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const { t } = useTranslation();

  return (
    <Paper sx={classes.container} elevation={isSM ? 0 : 2}>
      <IconUnderlay size={80}>
        <PriorityHighOutlined color="error" sx={classes.priorityIcon} />
      </IconUnderlay>

      <div style={classes.titleContainer}>
        <Typography color="textSecondary" variant={isSM ? 'h1' : 'h2'}>
          {t('EXPIRED-LINK-TOP-TITLE')}
        </Typography>
        <Typography
          sx={classes.emailTitleText}
          color="textSecondary"
          variant={isSM ? 'h1' : 'h2'}
        >
          {t('EXPIRED-LINK-TITLE', { email })}
        </Typography>
      </div>
      <div style={classes.subtitleContainer}>
        <Typography color="textSecondary" variant={isSM ? 'h1' : 'h2'}>
          {t('EXPIRED-LINK-SUBTITLE')}
        </Typography>
      </div>
      <div style={classes.textContainer}>
        <Trans
          t={t}
          i18nKey="ERROR_SUPPORT_LABEL"
          components={{
            typo: (
              <Typography
                color="textSecondary"
                sx={classes.textAroundButton}
                variant="body1"
              />
            ),
            btn: (
              <Typography
                variant="body1"
                sx={classes.textButton}
                onClick={onResend}
              />
            ),
          }}
        />
      </div>
    </Paper>
  );
}

export default ExpiredError;
