import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Typography, useMediaQuery } from '@mui/material';

const useStyles = (isSM) => ({
  supportButtonMailto: {
    border: 'none',
    padding: '0px',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    display: 'flex',
  },
  supportLabelContainerMailto: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: isSM ? 'column' : 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  supportLabelMailto: {
    marginLeft: '2px',
    color: 'secondary.dark',
    display: 'inline',
  },
  supportButtonLabelMailto: {
    color: 'primary.main',
    display: 'inline',
  },
  supportMailto: {
    display: 'inline',
    textDecoration: 'none',
    color: 'primary.main',
  },
  supportButton: {
    padding: '0px',
    border: 'none',
    backgroundColor: 'transparent',
    marginBottom: '4px',
    minWidth: '272px',
    color: 'primary.main',
    display: 'inline-flex',
    cursor: 'pointer',
  },
  supportLabelContainer: {
    display: 'flex',
    flexDirection: isSM ? 'column' : 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  supportLabel: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    marginLeft: '4px',
    marginTop: '-2.8px',
    color: 'secondary.dark',
  },
});

function CardFooter(props) {
  const { t } = useTranslation();
  const { onClickFooter, mailto } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);

  return (
    <>
      {mailto ? (
        <Trans
          t={t}
          i18nKey="CONFIRMATION-SUPPORT-LABEL"
          components={{
            d: <div style={classes.supportLabelContainerMailto} />,
            btn: (
              <button
                id="resend-reset-password-email-button"
                type="submit"
                onClick={onClickFooter}
                style={classes.supportButtonMailto}
              >
                <fragment />
              </button>
            ),
            lnk: (
              <a
                style={classes.supportMailto}
                id="resend-reset-email-us-button"
                href="mailto:member.success@advisorycloud.com"
              >
                <fragment />
              </a>
            ),
            lbl_btn: (
              <Typography
                variant="body1_old_font"
                sx={classes.supportButtonLabelMailto}
              />
            ),
            lbl_div: (
              <Typography
                variant="body1_old_font"
                sx={classes.supportLabelMailto}
              />
            ),
            lbl_lnk: (
              <Typography variant="body1_old_font" sx={classes.supportMailto} />
            ),
          }}
        />
      ) : (
        <Trans
          t={t}
          i18nKey="COMPANY-SUPPORT-LABEL"
          components={{
            d: <div style={classes.supportLabelContainer} />,
            btn: (
              <button
                id="resend-confirmation-email-button"
                type="submit"
                onClick={onClickFooter}
                style={classes.supportButton}
              >
                <fragment />
              </button>
            ),
            nth: <Typography variant="body1_old_font" />,
            th1: (
              <Typography sx={classes.supportLabel} variant="body1_old_font" />
            ),
          }}
        />
      )}
    </>
  );
}

export default CardFooter;
