import React, { useEffect, useState } from 'react';
import { useMediaQuery, Paper } from '@mui/material';
import AdvisoryCloudLogo from '../../components/AdvisoryCloudLogo';
import { useHistory } from 'react-router';

const useStyles = () => ({
  card: {
    backgroundColor: 'white',
    borderRadius: '10px',
    marginTop: '50px',
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '0px',
    width: 'auto',
    marginBottom: '-20px',
    backgroundColor: 'white',
  },
  header: {
    display: 'flex',
    marginBottom: '-10px',
  },
});

const CardScene = (props) => {
  const { children, paperId, header, footer, clearPaper } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles();
  const [isHeader, setIsHeader] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (header === undefined || header === null) {
      setIsHeader(false);
    } else {
      setIsHeader(true);
    }
  }, []);

  const Header = () => {
    return (
      <>
        <div style={classes.header}>{header}</div>
      </>
    );
  };

  return (
    <>
      <div style={classes.container}>
        <AdvisoryCloudLogo
          removeHyperlink
          onClick={() => history.push('/dashboard')}
          width={189}
        />
        {isHeader && <Header />}
        {clearPaper ? (
          children
        ) : (
          <Paper id={paperId} elevation={!isSM ? 3 : 0} sx={classes.card}>
            {children}
          </Paper>
        )}
        {footer}
      </div>
    </>
  );
};

export default CardScene;
