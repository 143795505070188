import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Autocomplete,
  Box,
  Typography,
  TextField,
  InputAdornment,
  useMediaQuery,
} from '@mui/material';
import { SearchOutlined } from '@mui/icons-material';
import ACButton from '../ACButton';
import { getPublicAdvisorTags } from '../../services/Backend/account';
import GateElementModal from '../GateElementModal';

const useStyles = (isSM) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    width: isSM ? '90%' : '60%',
    backgroundColor: 'common.white',
    borderWidth: '2px',
    border: 'solid',
    borderRadius: '5px',
    borderColor: 'grey.300',
  },
  keywordsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '50px',
    borderBottomWidth: '2px',
    borderBottom: 'solid',
    borderBottomColor: 'grey.300',
    paddingRight: '10px',
  },
  keywordsInput: {
    display: 'flex',
    flex: 'auto',
    marginRight: '20px',
  },
  filtersContainer: {
    display: 'flex',
    flexDirection: isSM ? 'column' : 'row',
    padding: '10px',
  },
  autocomplete: {
    marginRight: '10px',
    marginTop: isSM ? '10px' : '0px',
    width: isSM ? '100%' : '33%',
    paddingLeft: isSM ? '0px' : '6px',
  },
  searchButton: {
    borderRadius: '5px',
  },
});

function AdvisorFilter(props) {
  const {
    advisorFreeTrial,
    disableSearchButton,
    hidePosition,
    onSearchButtonClicked,
  } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const classes = useStyles(isSM);
  const [keywords, setKeywords] = useState('');
  const [skills, setSkills] = useState([]);
  const [loginModal, setLoginModal] = useState(false);
  const [position, setPosition] = useState('');
  const [industries, setIndustries] = useState([]);

  useEffect(() => {
    getPublicAdvisorTags({
      skillsData: true,
      industriesData: true,
      positionsData: true,
    }).then((response) => {
      if (response.errors) {
        console.log(response.errors);
      } else {
        setSkills(response.data.getTags.skillsData);
        setPosition(response.data.getTags.positionsData);
        setIndustries(response.data.getTags.industriesData);
      }
    });
  }, []);

  function textFieldKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      onSearchButtonClicked({
        keywords,
        skills,
        position,
        industries,
      });
    }
  }

  function renderTextField(params, placeholderValue) {
    const paramsWithEmptyInput = params;
    paramsWithEmptyInput.InputProps.startAdornment = undefined;
    paramsWithEmptyInput.InputProps.sx = {
      input: {
        color: 'white',
        '&::placeholder': {
          color: 'white',
        },
      },
    };

    return (
      <TextField
        {...paramsWithEmptyInput}
        size="small"
        placeholder={t(placeholderValue)}
        variant="outlined"
      />
    );
  }

  return (
    <>
      <GateElementModal loginModal={loginModal} setLoginModal={setLoginModal} />
      <form style={classes.container}>
        <Box className="searchBox" sx={classes.keywordsContainer}>
          <TextField
            sx={{ ...classes.keywordsInput, fieldset: { border: 'none' } }}
            id="search-advisor-keyword-input"
            data-testid="search-advisor-keyword-input"
            data-cy="search-advisor-keyword-input"
            placeholder="Search by Keywords"
            value={keywords}
            onChange={(evt) => setKeywords(evt.target.value)}
            onKeyDown={(evt) => textFieldKeyPress(evt)}
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ marginRight: 10 }}>
                  <SearchOutlined htmlColor="grey" />
                </InputAdornment>
              ),
              sx: {
                input: {
                  color: 'white',
                  '&::placeholder': {
                    color: 'white',
                  },
                },
              },
            }}
          />

          <ACButton
            onClick={() =>
              onSearchButtonClicked({
                keywords,
                skills,
                position,
                industries,
              })
            }
            disabled={disableSearchButton}
            color="primary"
            style={classes.searchButton}
          >
            <Typography variant="body2">Search</Typography>
          </ACButton>
        </Box>

        {!advisorFreeTrial && (
          <div style={classes.filtersContainer}>
            <Autocomplete
              sx={{ ...classes.autocomplete, fieldset: { border: 'none' } }}
              id="search-advisor-skills-autocomplete"
              data-testid="search-advisor-skills-autocomplete"
              data-cy="search-advisor-skills-autocomplete"
              onChange={() => setLoginModal(true)}
              onKeyDown={(evt) => textFieldKeyPress(evt)}
              options={skills}
              getOptionLabel={(options) => options.label}
              multiple
              renderInput={(params) => (
                <>{renderTextField(params, 'FILTER-SKILLS')}</>
              )}
            />

            {hidePosition ? null : (
              <Autocomplete
                sx={{ ...classes.autocomplete, fieldset: { border: 'none' } }}
                id="search-advisor-position-autocomplete"
                data-testid="search-advisor-position-autocomplete"
                data-cy="search-advisor-position-autocomplete"
                onChange={() => setLoginModal(true)}
                onKeyDown={(evt) => textFieldKeyPress(evt)}
                options={position}
                getOptionLabel={(options) => options.label}
                multiple
                renderInput={(params) => (
                  <>{renderTextField(params, 'FILTER-POSITION')}</>
                )}
              />
            )}

            <Autocomplete
              sx={{ ...classes.autocomplete, fieldset: { border: 'none' } }}
              id="search-advisor-industry-autocomplete"
              data-testid="search-advisor-industry-autocomplete"
              data-cy="search-advisor-industry-autocomplete"
              onChange={() => setLoginModal(true)}
              onKeyDown={(evt) => textFieldKeyPress(evt)}
              getOptionLabel={(options) => options.label}
              options={industries}
              multiple
              renderInput={(params) => (
                <>{renderTextField(params, 'FILTER-INDUSTRY')}</>
              )}
            />
          </div>
        )}
      </form>
    </>
  );
}

export default AdvisorFilter;
