import React from 'react';
import { CustomCarousel } from './carousel';
import { Box, Button, Typography } from '@mui/material';
import ManagedTiles from './FreeTrialTiles';
import HeroSection from './hero';
import { ReactComponent as Conversation } from './conversation.svg';
import { ReactComponent as Tasks } from './tasks.svg';
import { ReactComponent as Meeting } from './meeting.svg';
import { ReactComponent as Stocks } from './stocks.svg';
import { ReactComponent as Person } from './person.svg';
import AccordionList from '../../components/ManagedAdvisoryBoard/AccordionList';
import GettingStarted from './gettingStarted';
import GetFeedback from './getFeedbackFT';
import eventBus, { eventBusValues } from '../../eventBus';
import { useAccount } from '../../contexts/Account';

const advisoryBoardBenefits = [
  {
    id: 'expertise_and_insight',
    className: 'useCaseTile',
    title: 'Expertise and Insight',
    description:
      'Your advisory board can offer a wealth of expertise across various fields, providing valuable insights into industry trends, market dynamics, and emerging opportunities. By tapping into their experience, you can make informed decisions that align with your strategic objectives.',
    color: '#D7F1FD',
  },
  {
    id: 'network_expansion',
    className: 'useCaseTile',
    title: 'Network Expansion',
    description:
      'Each member of your advisory board brings their own network of contacts and connections. Leveraging these networks can open doors to new partnerships, collaborations, and business opportunities that can change everything for your organization going forward.',
    color: '#DBF7EB',
  },
  {
    id: 'credibility',
    className: 'useCaseTile',
    title: 'Credibility',
    description:
      'Associating your organization with respected industry experts can enhance your credibility and reputation within your industry and beyond. Their endorsement lends legitimacy to your initiatives and can attract attention from stakeholders, investors, and customers.',
    color: '#FFEED6',
  },
  {
    id: 'strategic_guidance',
    className: 'useCaseTile',
    title: 'Strategic Guidance',
    description:
      'With diverse perspectives represented on your advisory board, you can receive strategic guidance on key initiatives, challenges, and long-term planning. Their input can help you refine your strategies, identify potential risks, and navigate complex issues more effectively.',
    color: '#DBF7EB',
  },
  {
    id: 'access_to_resources',
    className: 'useCaseTile',
    title: 'Access to Resources',
    description:
      "Whether it's access to funding, talent, or specialized knowledge, your advisory board members can provide valuable resources to support your organization's growth and development. Their connections and resources can help you overcome resource constraints and capitalize on opportunities more effectively.",
    color: '#D7F1FD',
  },
  {
    id: 'problem_solving',
    className: 'useCaseTile',
    title: 'Problem Solving',
    description:
      'When faced with obstacles or opportunities, your advisory board can serve as a think tank, brainstorming solutions and innovative ideas to drive growth and overcome challenges. Their fresh perspective creativity can spark innovation and foster a culture of continuous improvement within your organization.',
    color: '#FEF8D1',
  },
];

const advisoryNumberWays = [
  {
    title: 'Post',
    description:
      'Post questions and updates in your AdvisoryCloud to get feedback, ideas, suggestions, and support from your advisors.',
    color: '#DBF7EB',
  },
  {
    title: '1:1 Meetings',
    description:
      'Get guidance, connections, and priceless information through 1:1 meetings with advisors.',
    color: '#FFEED6',
  },
  {
    title: 'Focus Groups',
    description:
      'Conduct focus groups with advisors to tackle specific challenges and initiatives you’re working on.',
    color: '#D7F1FD',
  },
  {
    title: 'Surveys',
    description:
      'Commission custom surveys and polls to get opinions and feedback at scale for your company, team, or project.',
    color: '#FEF8D1',
  },
  {
    title: 'Custom',
    description:
      'With AdvisoryCloud Plus you can work with our team to structure a custom solution to get the insight you’re looking for',
    color: '#D7E2FD',
  },
];

function ManagedAdvisoryBoard() {
  const { currentPlan } = useAccount();
  const tier =
    currentPlan &&
    currentPlan?.salesforceValues &&
    currentPlan.salesforceValues.tier
      ? currentPlan.salesforceValues.tier.toLowerCase()
      : '';
  const accordionInfo = [
    {
      id: 'benefits_managed_advisory_board',
      title: 'What are the benefits of a Managed Advisory Board',
      body: (
        <>
          <Typography>
            Building an advisory board from your network of advisors can be a
            strategic move with numerous benefits. By assembling a diverse group
            of knowledgeable individuals, you can gain valuable insights,
            guidance, and support to achieve your goals and drive success for
            your organization. Here are some specific benefits you can expect:
          </Typography>
          <br />
          <Typography component="ol">
            <Typography component="li">
              <strong>Expertise and Insight: </strong>Your advisory board can
              offer a wealth of expertise across various fields, providing
              valuable insights into industry trends, market dynamics, and
              emerging opportunities. By tapping into their collective wisdom,
              you can make informed decisions that align with your strategic
              objectives.
            </Typography>
            <Typography component="li">
              <strong>Network Expansion: </strong>Each member of your advisory
              board brings with them their own network of contacts and
              connections. Leveraging these networks can open doors to new
              partnerships, collaborations, and business opportunities that can
              propel your organization forward.
            </Typography>
            <Typography component="li">
              <strong>Strategic Guidance: </strong>With diverse perspectives
              represented on your advisory board, you can receive strategic
              guidance on key initiatives, challenges, and long-term planning.
              Their input can help you refine your strategies, identify
              potential risks, and navigate complex issues more effectively.
            </Typography>
            <Typography component="li">
              <strong>Credibility and Reputation: </strong>Associating your
              organization with respected industry experts can enhance your
              credibility and reputation within your industry and beyond. Their
              endorsement can lend legitimacy to your initiatives and attract
              attention from stakeholders, investors, and customers.
            </Typography>
            <Typography component="li">
              <strong>Problem Solving and Innovation: </strong>When faced with
              obstacles or opportunities, your advisory board can serve as a
              think tank, brainstorming solutions and innovative ideas to drive
              growth and overcome challenges. Their collective creativity can
              spark innovation and foster a culture of continuous improvement
              within your organization.
            </Typography>
            <Typography component="li">
              <strong>Access to Resources: </strong>Whether it's access to
              funding, talent, or specialized knowledge, your advisory board
              members can provide valuable resources to support your
              organization's growth and development. Their connections and
              resources can help you overcome resource constraints and
              capitalize on opportunities more effectively.
            </Typography>
            <Typography component="li">
              <strong>Accountability and Governance: </strong>A well-structured
              advisory board can also provide accountability and governance,
              ensuring that your organization operates ethically, transparently,
              and in accordance with best practices. Their oversight can help
              you mitigate risks and maintain compliance with regulatory
              requirements.
            </Typography>
          </Typography>
        </>
      ),
    },
    {
      id: 'pay_advisors_to_collaborate',
      title: 'Do I have to pay the advisors to collaborate?',
      body: (
        <>
          <Typography>
            All memberships allow you to invite advisors to your secure
            boardroom and collaborate with them through posts, messages, and
            quarterly board meetings without additional payment. Your advisors
            participate for the ability to add their role on your advisory board
            to their resume and LinkedIn profiles.
          </Typography>
          <br />
          <Typography>
            For additional 1:1 meetings, focus groups, project-based work,
            custom surveys and more substantial engagements, advisors can be
            hired at their hourly rate and you can work with our team to
            structure these.
          </Typography>
        </>
      ),
    },
    {
      id: 'how_do_you_choose_the_advisors',
      title: 'How do you choose the advisors that join my advisory board?',
      body: (
        <>
          {tier !== 'traditional' ? (
            <>
              <Typography>
                We’ll work with you to understand your challenges and needs,
                shape your company profile to attract the right talent, then
                publish your profile on our platform to crowdsource advisors who
                match your current needs.
              </Typography>
              <br />
              <Typography>
                Your account manager will also help you search and save advisors
                from our network and invite them to join your advisory board. If
                you want complete control over every person on your advisory
                board you can choose our Traditional plan, where we’ll help you
                hand-pick up to 12 advisors best suited for your needs.
              </Typography>
            </>
          ) : (
            <>
              <Typography>
                We’ll work with you to understand your challenges and needs,
                shape your company profile to appeal to the right advisors, and
                launch your advisory board.
              </Typography>
              <br />
              <Typography>
                Your account manager will help hand-pick up to 12 advisors
                best-suited to meet your goals, which you can then save,
                message, or invite to join your advisory board.
              </Typography>
            </>
          )}
        </>
      ),
    },
    {
      id: 'can_i_change_or_add_more_advisors',
      title: 'Can I change or add more advisors to my board at any time?',
      body: (
        <>
          {tier !== 'traditional' ? (
            <Typography>
              Yes, you can always add more advisors to your board; there is no
              maximum number of advisors that you can have on your advisory
              board. If you need additional help sourcing advisors, your account
              manager will be ready to help.
            </Typography>
          ) : (
            <Typography>
              If you want a larger group of advisors, you can always consider
              our Crowdsourced plan, where we’ll publish your profile to our
              platform and let the right advisors join your board. Either way,
              your account manager is always available to help you source the
              best advisors for your current needs.
            </Typography>
          )}
        </>
      ),
    },
    {
      id: 'can_i_invite_people_from_my_team',
      title: 'Can I invite people from my team and network?',
      body: (
        <>
          <Typography>
            Yes! One of the best parts about our platform is the ability to
            invite your teammates and anyone from your personal network to
            collaborate with you and the advisors in our network.
          </Typography>
        </>
      ),
    },
    {
      id: 'how_will_i_receive_feedback',
      title: 'How will I receive feedback from my advisory board?',
      body: (
        <>
          <Typography>
            You can post questions, updates, and host quarterly meetings with
            your advisors all from your digital boardroom on AdvisoryCloud.
          </Typography>
        </>
      ),
    },
    {
      id: 'how_do_the_board_meetings_work',
      title: 'How do the board meetings work?',
      body: (
        <>
          <Typography>
            You’ll meet remotely via video meeting with your advisors each
            quarter to gain perspective and discuss your challenges and
            opportunities. Our team works with you on agenda development and
            moderates the quarterly board meetings so you can focus on getting
            insights.
          </Typography>
        </>
      ),
    },
  ];
  const handleClick = () => {
    eventBus.dispatch(eventBusValues.triggerCompanyUpgradeAction, {});
  };
  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          padding: { xxs: '0px 10px', sm: '40px' },
          maxWidth: '1260px',
        }}
      >
        <ManagedTiles />
      </Box>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          backgroundColor: { xxs: '#FFFFFF', sm: '#FBFCFD' },
          border: '1px solid #F5F7FA',
          padding: { xxs: '0px', sm: '40px 40px 40px 60px' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            backgroundColor: '#FFFFFF',
            border: { xxs: 'none', sm: '1px solid #F5F7FA' },
            maxWidth: '1160px',
            borderRadius: '10px',
            padding: '40px',
            marginBottom: '40px',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: '22px',
              fontWeight: 500,
              lineHeight: '32px',
              letterSpacing: '-0.43px',
              textAlign: 'center',
              marginBottom: '30px',
            }}
          >
            How it works
          </Typography>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-around',
              flexWrap: 'wrap',
            }}
          >
            <HeroSection
              className="mabValue1"
              Photo={Conversation}
              title="Work with a dedicated account manager and moderator"
            />
            <HeroSection
              className="mabValue2"
              Photo={Tasks}
              title="Assemble an advisory board based on your needs"
            />
            <HeroSection
              className="mabValue3"
              Photo={Meeting}
              title="Hold quarterly board meetings moderated by our team"
            />
            <HeroSection
              className="mabValue4"
              Photo={Stocks}
              title="Collaborate through posts, messages, 1:1 meetings, and more"
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: '100%',
            maxWidth: '1160px',
            backgroundColor: '#FFFFFF',
            border: { xxs: 'none', sm: '1px solid #F5F7FA' },
            borderRadius: '10px',
            padding: { xxs: '50px 0px', xs: '50px 40px' },
            marginBottom: '40px',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '22px',
              lineHeight: '32px',
              letterSpacing: '-0.43px',
              textAlign: 'center',
              marginBottom: '10px',
              padding: { xxs: '20px', xs: '0px' },
            }}
          >
            How an Advisory Board can help
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '15px',
              lineHeight: '22px',
              letterSpacing: '0.75px',
              textAlign: 'center',
              marginBottom: '40px',
              padding: { xxs: '20px', xs: '0px' },
            }}
          >
            Benefits you can expect from your advisory board
          </Typography>
          <CustomCarousel items={advisoryBoardBenefits} option={1} />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            maxWidth: '1160px',
            backgroundColor: '#FFFFFF',
            border: { xxs: 'none', sm: '1px solid #F5F7FA' },
            borderRadius: '10px',
            padding: '40px',
            marginBottom: '0px',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              width: { xxs: '190px', sm: '225px' },
              height: { xxs: '190px', sm: '225px' },
              borderRadius: '50%',
              background: '#D7F1FDCC',
              position: 'absolute',
              left: '50px',
              bottom: '60px',
              zIndex: 1,
            }}
          />
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: { xxs: '22px', sm: '36px' },
              lineHeight: '42px',
              letterSpacing: '-0.43px',
              marginLeft: { xxs: '0px', sm: '200px' },
              marginBottom: '20px',
              maxWidth: '755px',
              zIndex: 1,
            }}
          >
            “We’ve been able to connect with incredible advisors for our
            business on AdvisoryCloud”
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              zIndex: 1,
              marginLeft: { xxs: '0px', sm: '100px' },
            }}
          >
            <Person style={{ marginRight: '10px' }} />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: '20px',
              }}
            >
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '15px',
                  lineHeight: '22px',
                  letterSpacing: '0.75px',
                  marginBottom: '10px',
                }}
              >
                Rick Rolston
              </Typography>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '15px',
                  lineHeight: '22px',
                  letterSpacing: '0.75px',
                  marginBottom: '10px',
                }}
              >
                Founder and CEO - BuiltSpace Technologies
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            width: '100%',
            maxWidth: '1160px',
            borderRadius: '10px',
            padding: { xxs: '50px 0px', xs: '30px 40px' },
            marginBottom: '30px',
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontSize: { xxs: '28px', xs: '36px' },
                fontWeight: 700,
                lineHeight: '45px',
                letterSpacing: '-0.43px',
                textAlign: 'center',
                maxWidth: '50%',
                marginBottom: '5px',
              }}
            >
              Upgrade to launch your advisory board today
            </Typography>
            <Typography
              sx={{
                marginTop: '10px',
                maxWidth: '90%',
                fontFamily: 'Poppins',
                fontSize: '15px',
                fontWeight: 500,
                lineHeight: '28px',
                letterSpacing: '0.75px',
                textAlign: 'center',
                marginBottom: '20px',
              }}
            >
              Unlock full access on AdvisoryCloud
            </Typography>

            <Box>
              <Button
                onClick={handleClick}
                disableRipple
                variant="contained"
                id="viewUpgradeOptions2"
                style={{
                  width: '322px',
                  height: '48px',
                  borderRadius: '25px',
                  fontFamily: 'Poppins',
                  fontSize: '16px',
                  fontWeight: 700,
                  lineHeight: '24px',
                  letterSpacing: '-0.43px',
                  textAlign: 'left',
                  textTransform: 'none',
                  boxShadow: 'none',
                  background: '#D7F1FD',
                  border: '1px solid #4BC6FF',
                  color: '#232B35',
                }}
              >
                View upgrade options
              </Button>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            width: '100%',
            maxWidth: '1160px',
            backgroundColor: '#FFFFFF',
            border: { xxs: 'none', sm: '1px solid #F5F7FA' },
            borderRadius: '10px',
            padding: { xxs: '50px 0px', xs: '50px 40px' },
            marginBottom: '30px',
          }}
        >
          <GettingStarted />
        </Box>
        <Box
          sx={{
            width: '100%',
            maxWidth: '1160px',
            borderRadius: '10px',
            padding: { xxs: '50px 0px', xs: '30px 40px' },
            marginBottom: '30px',
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontSize: { xxs: '28px', xs: '36px' },
                fontWeight: 700,
                lineHeight: '45px',
                letterSpacing: '-0.43px',
                textAlign: 'center',
                maxWidth: '50%',
                marginBottom: '5px',
              }}
            >
              Upgrade to launch your advisory board today
            </Typography>
            <Typography
              sx={{
                marginTop: '10px',
                maxWidth: '90%',
                fontFamily: 'Poppins',
                fontSize: '15px',
                fontWeight: 500,
                lineHeight: '28px',
                letterSpacing: '0.75px',
                textAlign: 'center',
                marginBottom: '20px',
              }}
            >
              Unlock full access on AdvisoryCloud
            </Typography>

            <Box>
              <Button
                onClick={handleClick}
                disableRipple
                variant="contained"
                id="viewUpgradeOptions3"
                style={{
                  width: '322px',
                  height: '48px',
                  borderRadius: '25px',
                  fontFamily: 'Poppins',
                  fontSize: '16px',
                  fontWeight: 700,
                  lineHeight: '24px',
                  letterSpacing: '-0.43px',
                  textAlign: 'left',
                  textTransform: 'none',
                  boxShadow: 'none',
                  background: '#D7F1FD',
                  border: '1px solid #4BC6FF',
                  color: '#232B35',
                }}
              >
                View upgrade options
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: '100%',
            maxWidth: '1160px',
            borderRadius: '10px',
            padding: { xxs: '50px 0px', xs: '50px 40px' },
            marginBottom: '40px',
          }}
        >
          <GetFeedback />
        </Box>

        <Box
          sx={{
            width: '100%',
            maxWidth: '1160px',
            backgroundColor: '#FFFFFF',
            border: { xxs: 'none', sm: '1px solid #F5F7FA' },
            borderRadius: '10px',
            padding: { xxs: '50px 0px', xs: '50px 40px' },
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '22px',
              lineHeight: '32px',
              letterSpacing: '-0.43px',
              textAlign: 'center',
              marginBottom: '30px',
              padding: { xxs: '20px', xs: '0px' },
            }}
          >
            Tap into advisors in a number of ways
          </Typography>

          <CustomCarousel items={advisoryNumberWays} option={2} />
        </Box>

        <Box
          sx={{
            width: '100%',
            maxWidth: '1160px',
            borderRadius: '10px',
            padding: { xxs: '50px 0px', xs: '30px 40px' },
            marginBottom: '30px',
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontSize: { xxs: '28px', xs: '36px' },
                fontWeight: 700,
                lineHeight: '45px',
                letterSpacing: '-0.43px',
                textAlign: 'center',
                maxWidth: '50%',
                marginBottom: '5px',
              }}
            >
              Upgrade to launch your advisory board today
            </Typography>
            <Typography
              sx={{
                marginTop: '10px',
                maxWidth: '90%',
                fontFamily: 'Poppins',
                fontSize: '15px',
                fontWeight: 500,
                lineHeight: '28px',
                letterSpacing: '0.75px',
                textAlign: 'center',
                marginBottom: '20px',
              }}
            >
              Unlock full access on AdvisoryCloud
            </Typography>

            <Box>
              <Button
                onClick={handleClick}
                disableRipple
                variant="contained"
                id="viewUpgradeOptions4"
                style={{
                  width: '322px',
                  height: '48px',
                  borderRadius: '25px',
                  fontFamily: 'Poppins',
                  fontSize: '16px',
                  fontWeight: 700,
                  lineHeight: '24px',
                  letterSpacing: '-0.43px',
                  textAlign: 'left',
                  textTransform: 'none',
                  boxShadow: 'none',
                  background: '#D7F1FD',
                  border: '1px solid #4BC6FF',
                  color: '#232B35',
                }}
              >
                View upgrade options
              </Button>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            width: '100%',
            maxWidth: '1160px',
            backgroundColor: '#FFFFFF',
            border: { xxs: 'none', sm: '1px solid #F5F7FA' },
            borderRadius: '10px',
            padding: { xxs: '50px 10px', xs: '50px 100px' },
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '22px',
              lineHeight: '32px',
              letterSpacing: '-0.43px',
              textAlign: 'center',
              marginBottom: '30px',
              padding: { xxs: '20px', xs: '0px' },
            }}
          >
            Frequently Asked Questions
          </Typography>
          <AccordionList items={accordionInfo} />
        </Box>
      </Box>
    </Box>
  );
}

export default ManagedAdvisoryBoard;
