import React, { useEffect, useState } from 'react';
import { Tooltip, useMediaQuery } from '@mui/material';
import PasswordField from './PasswordField';

const errorCodes = {
  PASSWORDS_NOT_MATCHING: 1,
};

const useStyles = (isSM) => ({
  validationMessage: {
    color: '#d32f2f',
  },
  inputsContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '68px',
    justifyContent: 'space-between',
    marginBottom: '10px',
    width: isSM ? '100%' : null,
  },
  arrow: {
    '&:before': {
      border: '2px solid #5A5A5A',
    },
    color: 'white',
  },
  tooltip: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #EFF0F6',
    borderRadius: '10px',
    color: '#4A4A4A',
    width: '227px',
    height: '189px',
  },
});

export default function Password(props) {
  const {
    onKeyUpData,
    passwordLabel,
    validationMessage,
    width,
    passwordTooltip,
    validations,
    placeholder,
    disableAutoFocus,
    setPasswordForValidation,
  } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const [password, setPassword] = useState('');
  const [isPasswordFocused, setPasswordFocused] = useState(false);

  function handleOnKeyUp(event) {
    if (onKeyUpData) {
      onKeyUpData({
        id: event.target.id,
        content: event.target.value,
        allCharactersCount: event.target.value.length,
        withoutLineBreaksCount: event.target.value.replace(
          /(?:\r\n|\r|\n)/g,
          ''
        ).length,
      });
    }
  }

  function showPasswordTooltip(txt) {
    return (
      validations.filter((validation) => !validation.criteria(txt)).length > 0
    );
  }

  function handlePasswordChange(event) {
    setPassword(event.target.value);
    setPasswordForValidation(event.target.value);
  }

  function renderValidationMessage() {
    if (!validationMessage) return null;

    return <span style={classes.validationMessage}>{validationMessage}</span>;
  }

  return (
    <div style={classes.inputsContainer}>
      <Tooltip
        disableHoverListener
        placement="bottom-start"
        // classes={{
        //   arrow: classes.arrow,
        //   tooltip: classes.tooltip,
        // }}
        componentsProps={{
          arrow: { sx: classes.arrow },
          tooltip: { sx: classes.tooltip },
        }}
        title={<>{passwordTooltip}</>}
        open={isPasswordFocused && showPasswordTooltip(password)}
      >
        <PasswordField
          id="passwordSignUpAC"
          onChange={handlePasswordChange}
          onKeyUp={handleOnKeyUp}
          value={password}
          label={passwordLabel}
          width={width}
          onFocus={() => setPasswordFocused(true)}
          onBlur={() => setPasswordFocused(false)}
          placeholder={placeholder}
          disableAutoFocus={disableAutoFocus}
        />
      </Tooltip>


      {renderValidationMessage()}
    </div>
  );
}
