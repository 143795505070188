import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import ACSnackbar from '../../components/ACSnackbar';
import ConversationContainer from '../../components/Conversation/Container';

const useStyles = () => ({
  screenContainer: {
    paddingTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    flex: 'auto',
  },
});

function Conversation() {
  const { t } = useTranslation();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const [legacyIdErrorSnackbarVisible, setLegacyIdErrorSnackbarVisible] =
    useState(false);

  useEffect(() => {}, []);

  return (
    <>
      <Helmet>
        <title>{t('DASHBOARD-HELMET-TITLE')}</title>
      </Helmet>
      <div style={classes.screenContainer}>
        <ConversationContainer />
      </div>
      <ACSnackbar
        open={legacyIdErrorSnackbarVisible}
        text={t('DASHBOARD-IWM-ID-NOT-PRESENT-ERROR')}
        severity="error"
        onClose={() => setLegacyIdErrorSnackbarVisible(false)}
        autoHideDuration={5000}
      />
    </>
  );
}

export default Conversation;
