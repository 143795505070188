import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import ACSnackbar from '../../components/ACSnackbar';
import AdvisorPublicJoinForm from '../../components/Advisor/PublicJoinForm';
import PublicRegistration from '../../layouts/PublicRegistration';
import { advisorPublicJoin } from '../../services/advisor_services';
import { getCompanyPublicInfoBySlug } from '../../services/company_services';

const AdvisorPublicJoin = () => {
  const { companySlug, companyId } = useParams(
    '/join/advisors/:companySlug/:companyId'
  );
  const history = useHistory();
  const [companyDisplayName, setCompanyDisplayName] = useState('');
  const [image, setImage] = useState(null);
  const [error, setError] = useState('Something went wrong');
  const [showSnackbar, setShowSnackbar] = useState(false);

  useEffect(() => {
    const getCompanyInfo = async () => {
      try {
        const response = await getCompanyPublicInfoBySlug({
          slug: companySlug,
          preventWithOwnerImage: true,
        });
        setImage(response.data.getCompany.image);
        setCompanyDisplayName(response.data.getCompany.displayName);
      } catch (error) {
        console.log(error);
      }
    };
    getCompanyInfo();
  }, [companySlug]);

  const onSubmit = async ({ givenName, surName, email }) => {
    try {
      const response = await advisorPublicJoin({
        givenName,
        surName,
        email,
        companyId,
      });
      if (response.errors) {
        setError(getError(response));
        setShowSnackbar(true);
      } else {
        if (
          response.data &&
          response.data.advisorPublicJoin &&
          response.data.advisorPublicJoin.checkoutRedirectUrl
        ) {
          window.location.assign(
            response.data.advisorPublicJoin.checkoutRedirectUrl
          );
        } else {
          history.push(`/advisor/confirmation?email=${email}`);
        }
      }
    } catch (error) {
      console.log(error);
      setShowSnackbar(true);
    }
  };

  return (
    <>
      <ACSnackbar
        onClose={() => setShowSnackbar(false)}
        open={showSnackbar}
        severity="error"
        text={error}
      />
      <PublicRegistration>
        <AdvisorPublicJoinForm
          companySlug={companyDisplayName}
          onSubmit={onSubmit}
          image={image}
        />
      </PublicRegistration>
    </>
  );
};

export default AdvisorPublicJoin;
