import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { ReactComponent as ReplyIcon } from './reply.svg';
import { ReactComponent as LaunchIcon } from './launch.svg';
import { RWebShare } from 'react-web-share';
import { useAccount } from './../../../contexts/Account';
import eventBus, { eventBusValues } from './../../../eventBus';

const useStyles = () => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconButton: {
    margin: '0 4px !important',
  },
  icon: {
    color: '#232B35',
    height: '20px',
    width: '20px',
  },
});

const ActionButtons = () => {
  const classes = useStyles();
  const { accountDetails } = useAccount();
  const { t } = useTranslation();

  function openProfile(event) {
    event.stopPropagation();
    window.open(
      `${window.location.origin}/profile/${accountDetails.publicProfileUrl}`,
      '_blank'
    );
  }

  function edit(event) {
    event.stopPropagation();
    eventBus.dispatch(eventBusValues.triggerAdvisorDrawerAction, {
      ...accountDetails,
      openInEdit: true,
    });
  }

  const handleIcon3Click = (event) => {
    event.stopPropagation();
  };

  return (
    <div style={classes.root}>
      <IconButton sx={classes.iconButton} onClick={edit}>
        <EditOutlinedIcon sx={classes.icon} />
      </IconButton>
      <IconButton sx={classes.iconButton} onClick={openProfile}>
        <LaunchIcon
          style={{ ...classes.icon, height: '15px', width: '15px' }}
        />
      </IconButton>
      <Box onClick={handleIcon3Click}>
        <RWebShare
          data={{
            text: `${t('ADVISOR-LINKEDIN-POST-TEXT')}`,
            url: `${window.location.origin}/profile/${accountDetails.publicProfileUrl}`,
            title: 'Share your advisor profile',
          }}
        >
          <IconButton sx={classes.iconButton}>
            <ReplyIcon style={classes.icon} />
          </IconButton>
        </RWebShare>
      </Box>
    </div>
  );
};

export default ActionButtons;
