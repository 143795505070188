import React from "react";
import { Box, Typography, Paper, Avatar } from "@mui/material";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

const NewsAndEvents = () => {
  return (
    <Paper
      elevation={1}
      sx={{
        display: "flex",
        alignItems: "center",
        padding: "16px",
        borderRadius: "12px",
        backgroundColor: "#fff",
    }}
    >
      {/* Icono */}
      <Avatar
        sx={{
          backgroundColor: "#dbeafe",
          color: "#2563eb",
          marginRight: "16px",
          width: "48px",
          height: "48px",
        }}
      >
        <NotificationsActiveIcon fontSize="medium" />
      </Avatar>

      {/* Contenido */}
      <Box>
        <Typography sx={{ fontWeight: "bold", marginBottom: "4px" }}>
          News & Events
        </Typography>
        <Typography variant="body2" sx={{ color: "#4b5563" }}>
          Calendar sync is now live and will automatically add your board meetings to your Google, Apple, or Outlook
          calendar.
        </Typography>
      </Box>
    </Paper>
  );
};

export default NewsAndEvents;
