import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, Dialog, Typography } from '@mui/material';
import { Clear } from '@mui/icons-material';
import RequestModalItem from './RequestModalItem';

const useStyles = (isSM) => ({
  dialogContainer: {
    borderRadius: isSM ? '0px' : '15px',
    boxShadow: '0 5px 20px rgba(0, 0, 0, 0.07)',
    padding: isSM ? '5px' : '30px 30px 0 30px',
    width: isSM ? '100%' : '960px',
    maxWidth: isSM ? '100%' : '960px',
    height: isSM ? '100%' : '560px',
    maxHeight: isSM ? '100%' : '560px',
    overflow: 'hidden',
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0',
  },
  mainContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: isSM ? 'column' : 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    position: 'relative',
  },
  textHeadline: {
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '36px',
    letterSpacing: '-0.43px',
    padding: '9px',
    paddingBottom: '5px',
    color: '#232B35',
  },
  textSubHeader: {
    fontSize: '16px',
    padding: isSM ? '15px 30px' : '15px 150px',
    textAlign: 'center',
    letterSpacing: '-0.43px',
    fontWeight: 400,
    color: '#232B35',
  },
  textThirdTitle: {
    fontSize: '14px',
    textAlign: 'center',
    letterSpacing: '-0.43px',
    fontWeight: 700,
    color: '#232B35',
  },
  footerContainer: {
    marginTop: isSM ? '-5px' : '5px',
    paddingBottom: '30px',
    paddingInline: isSM ? '30px' : '135px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textFooter: {
    fontSize: '14px',
    marginBottom: isSM ? '5px' : '0px',
    marginTop: '-15px',
    textAlign: 'center',
    color: '#646D7A',
  },
  clearIcon: {
    margin: '5px 5px 0 5px',
    float: 'right',
    cursor: 'pointer',
  },
  grayLine: {
    width: '220px',
    height: '2px',
    backgroundColor: '#C7D0DE',
    position: 'absolute',
    top: '100px',
  },
});

const RequestModal = (props) => {
  const { isOpen, setIsOpen } = props;
  const { t } = useTranslation();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const hideGrayLines = useMediaQuery((theme) =>
    theme.breakpoints.down([1170])
  );
  const classes = useStyles(isSM);

  function handleClose() {
    setIsOpen(false);
  }

  return (
    <Dialog
      open={isOpen}
      fullScreen={isSM}
      onClose={handleClose}
      PaperProps={{
        sx: classes.dialogContainer,
      }}
    >
      {isSM ? (
        <div style={classes.clearIcon}>
          <Clear onClick={handleClose} style={{ float: 'right' }} />
        </div>
      ) : null}
      <div>
        <div style={classes.container}>
          <Typography variant="h1" sx={classes.textHeadline}>
            {t('REQUEST-MODAL-ON-YOUR-WAY')}
          </Typography>
          <Typography variant="h2" sx={classes.textSubHeader}>
            {t('REQUEST-MODAL-SUBTITLE')}
          </Typography>
          <Typography variant="h3" sx={classes.textThirdTitle}>
            {t('REQUEST-MODAL-NEXT')}
          </Typography>
          <div style={classes.mainContainer}>
            {!hideGrayLines && (
              <>
                <div
                  style={{
                    ...classes.grayLine,
                    width: '122px',
                    left: '205px',
                  }}
                />
                <div
                  style={{
                    ...classes.grayLine,
                    left: '527px',
                    width: '122px',
                  }}
                />
              </>
            )}
            <RequestModalItem
              titleWIthIcon
              title={t('REQUEST-MODAL-TITLE-1')}
              text={t('REQUEST-MODAL-SUBTITLE-1')}
              image="request_join_modal_1.png"
            />
            <RequestModalItem
              title={t('REQUEST-MODAL-TITLE-2')}
              text={t('REQUEST-MODAL-SUBTITLE-2')}
              image="request_join_modal_2.png"
              biggerImage
            />
            <RequestModalItem
              title={t('REQUEST-MODAL-TITLE-3')}
              text={t('REQUEST-MODAL-SUBTITLE-3')}
              image="request_join_modal_3.png"
            />
          </div>
          <div style={classes.footerContainer}>
            <Typography sx={classes.textFooter}>
              {t('REQUEST-MODAL-BOTTOM')}
            </Typography>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default RequestModal;
