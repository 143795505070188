import React from 'react';
import { Box, IconButton } from '@mui/material';
import CustomIcon from '../../../icons/customIcon';
import { useAccount } from '../../../contexts/Account';
import eventBus, { eventBusValues } from '../../../eventBus';
import useCanMessage from '../../../hooks/useMessagePermission';

const useStyles = () => ({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '58px',
    background: '#FFF',
    borderRadius: '12px',
    justifyContent: 'space-between',
    marginTop: '10px',
    paddingLeft: '10px',
    paddingRight: '10px',
    '&:hover': {
      backgroundColor: '#E0E9F8',
      boxShadow: 'none',
    },
    cursor: 'pointer',
  },
  boardOwnerContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '76px',
    backgroundColor: '#E0E9F8',
    borderRadius: '12px',
    justifyContent: 'space-between',
    marginTop: '18px',
    paddingLeft: '21px',
    paddingRight: '21px',
    cursor: 'pointer',
  },
  image: {
    width: '40px',
    height: '40px',
    borderRadius: '8px',
  },
  sendIconContainer: {
    width: '34px',
    height: '34px',
    background: '#F5F7FA',
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  companyName: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    color: '#232B35',
    marginLeft: '10px',
  },
  title: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    color: '#232B35',
    marginLeft: '10px',
  },
  pill: {
    position: 'absolute',
    top: '-8px',
    right: '-6px',
    height: '18px',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    fontSize: '10px',
    fontWeight: 600,
    letterSpacing: '-0.32px',
    paddingRight: '10px',
    paddingLeft: '10px',
  },
  invitationPill: {
    background: '#4BC6FF',
  },
  pendingPill: {
    background: '#1D95CD',
  },
});

const StatusPill = ({ status }) => {
  const classes = useStyles();

  if (!status || status === 'member') {
    return null;
  }

  return (
    <div
      style={{
        ...classes.pill,
        ...(status === 'invitation'
          ? classes.invitationPill
          : classes.pendingPill),
      }}
    >
      {status === 'invitation' ? 'Invitation' : 'Pending'}
    </div>
  );
};

const BoardCard = ({
  logo,
  companyName,
  status,
  onClick,
  boardMember,
  memberType,
  isRecentlyMessaged,
  isBoardOwner,
}) => {
  const classes = useStyles();
  const { conversations } = useAccount();
  const canMessage = useCanMessage(boardMember.id, memberType);
  const trimmedCompanyName = companyName
    ? companyName.length > 20
      ? companyName.substring(0, 10) + '...'
      : companyName
    : '';
  const trimmedTitle =
    boardMember && boardMember.title
      ? boardMember.title.length > 15
        ? boardMember.title.substring(0, 15) + '...'
        : boardMember.title
      : '';

  function conversationClick(member) {
    if (canMessage === undefined) return;
    if (canMessage) {
      const found = conversations.find(
        (conversation) => conversation.conversationMemberAccountId === member.id
      );
      if (found) {
        window.location.replace(
          `${window.location.origin}/messages?conversationId=${found.conversationId}`
        );
      } else {
        eventBus.dispatch(eventBusValues.onSendConversationNewMessage, {
          senderId: member.id,
          senderName: member.displayName,
        });
      }
    } else {
      eventBus.dispatch(eventBusValues.triggerAdvisorUpgradeAction, {
        gatedAction: true,
        companyName: companyName,
      });
    }
  }

  function renderIcon() {
    return isRecentlyMessaged ? (
      <IconButton
        style={{ width: '100%', height: '100%' }}
        onClick={() => conversationClick(boardMember)}
      >
        <CustomIcon iconname="comments" style={{ fontSize: 20, zIndex: 3 }} />
      </IconButton>
    ) : (
      <IconButton
        style={{ width: '100%', height: '100%' }}
        onClick={() => conversationClick(boardMember)}
      >
        <CustomIcon iconname="airplane" style={{ fontSize: 16, zIndex: 3 }} />
      </IconButton>
    );
  }
  return (
    <Box sx={isBoardOwner ? classes.boardOwnerContainer : classes.container}>
      <div
        onClick={onClick}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'start',
          width: '80%',
        }}
      >
        <img src={logo} alt="Company Logo" style={classes.image} />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={classes.companyName}>{trimmedCompanyName}</div>
          {boardMember && boardMember.title && (
            <div style={classes.title}>{trimmedTitle}</div>
          )}
        </div>
      </div>
      <div style={classes.sendIconContainer}>{renderIcon()}</div>
      <StatusPill status={status} />
    </Box>
  );
};

export default BoardCard;
