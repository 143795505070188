import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EmptyQuestionSvg from '../../images/empty-question.svg';
import {
  companyFetchPostsCall,
  getSinglePostCall,
} from '../../services/company_services';
import SceneContentStateSelector from '../../components/SceneContentStateSelector';
import FilteredFeed from '../../components/FilteredFeed';
import {
  AccountType,
  PostType,
  EndpointParameterFilters,
  EndpointResponseObjectName,
} from '../../services/utils/types';
import { useAccount } from '../../contexts/Account';

function CompanyMyQuestions() {
  const { t } = useTranslation();
  const { accountDetails, asLoggedUserDetails } = useAccount();
  const params = useParams('/:companySlug/questions/:postId');
  const { postId } = params;
  const imageArray = [
    {
      src: EmptyQuestionSvg,
      alt: 'empty-question.svg',
      width: '200',
    },
  ];

  const singlePostRender = postId !== undefined;
  const { companyName } = accountDetails;

  const postRenderedSelector = {
    multiplePost: {
      parameters: {
        ACCOUNT_TYPE: AccountType.CONTACT,
        COMPANY_CONTACT_ID: asLoggedUserDetails.userId,
        COMPANY_ID: accountDetails.id,
        LIMIT: EndpointParameterFilters.FETCH_LIMIT,
        OFFSET: 0,
        ORDER_BY: EndpointParameterFilters.ORDER_BY_CREATED_AT,
        REVERSE: false,
        TYPE: PostType.QUESTION,
      },
      asyncName: EndpointResponseObjectName.GET_POSTS,
      graphqlEndpoint: companyFetchPostsCall,
      contentTag: FilteredFeed,
    },
    singlePost: {
      parameters: {
        ACCOUNT_TYPE: AccountType.CONTACT,
        USER_ID: asLoggedUserDetails.userId,
        POST_ID: postId,
      },
      asyncName: EndpointResponseObjectName.GET_POST,
      graphqlEndpoint: getSinglePostCall,
      contentTag: FilteredFeed,
    },
  };

  return (
    <SceneContentStateSelector
      helmetTitle={t('COMPANY-MY-QUESTIONS-HELMET-TITLE')}
      endPointAppSyncName={
        singlePostRender
          ? postRenderedSelector.singlePost.asyncName
          : postRenderedSelector.multiplePost.asyncName
      }
      endPointCall={
        singlePostRender
          ? postRenderedSelector.singlePost.graphqlEndpoint
          : postRenderedSelector.multiplePost.graphqlEndpoint
      }
      endPointCallParameters={
        singlePostRender
          ? postRenderedSelector.singlePost.parameters
          : postRenderedSelector.multiplePost.parameters
      }
      ContentTag={
        singlePostRender
          ? postRenderedSelector.singlePost.contentTag
          : postRenderedSelector.multiplePost.contentTag
      }
      contentProps={{
        asyncName: singlePostRender
          ? postRenderedSelector.singlePost.asyncName
          : postRenderedSelector.multiplePost.asyncName,
      }}
      emptyStateProps={{
        imageArray,
        titleText: t('ADVISOR_BOARDROOMS_QUESTIONS_EMPTY_STATE_TITLE', {
          companyName,
        }),
        contentText: t('ADVISOR_BOARDROOMS_QUESTIONS_EMPTY_STATE_CONTENT', {
          companyName,
        }),
      }}
    />
  );
}

export default CompanyMyQuestions;
