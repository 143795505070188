import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  TextField,
  Button,
  Typography,
  Box,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import ACSnackbar from '../../components/ACSnackbar';
import PublicRegistration from '../../layouts/PublicRegistration';
import LinkIcon from '@mui/icons-material/Link';
import AddIcon from '@mui/icons-material/Add';
import CircularProgress from '@mui/material/CircularProgress';
import {
  mutationSendInviteContacts,
  updateCompanyOnBoarding,
} from '../../services/company_services';
import { useAccount } from '../../contexts/Account';

const CompanyOnboardingFreemiumStep5 = () => {
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const history = useHistory();
  const { accountDetails, currentPlan, asLoggedUserDetails } = useAccount();
  const initialEmailsState = ['', '', ''];
  const [emails, setEmails] = useState(['']);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarInviteOpen, setSnackbarInviteOpen] = useState(false);
  const [isInviteButtonDisabled, setIsInviteButtonDisabled] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const [emailErrors, setEmailErrors] = useState([]);

  const boardOwnerContact = accountDetails.contacts?.find(
    (contact) => contact.isBoardOwner
  );
  const boardOwner = boardOwnerContact
    ? `${boardOwnerContact.givenName} ${boardOwnerContact.surName}`
    : 'No board owner found';

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const updateEmails = (index, value) => {
    const newEmails = [...emails];
    newEmails[index] = value;
    setEmails(newEmails);

    if (emailErrors[index]) {
      const newErrors = [...emailErrors];
      newErrors[index] = !validateEmail(value) && value !== '';
      setEmailErrors(newErrors);
    }

    setIsInviteButtonDisabled(
      !newEmails.some(validateEmail) || emailErrors.includes(true)
    );
  };

  const handleBlur = () => {
    const newErrors = emails.map(
      (email) => !validateEmail(email) && email !== ''
    );
    setEmailErrors(newErrors);
    setIsInviteButtonDisabled(
      !emails.some(validateEmail) || newErrors.includes(true)
    );
  };

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(
        `https://app.advisorycloud.com/join/advisors/${accountDetails.slug}/${accountDetails.id}`
      )
      .then(() => {
        setSnackbarOpen(true);
      });
  };

  const handleAddMoreEmails = () => {
    setEmails([...emails, '']);
  };

  const handleInvite = async () => {
    const validEmails = emails.filter((email) => validateEmail(email));
    if (validEmails.length > 0) {
      const baseUrl = `https://app.advisorycloud.com/join/advisors/${accountDetails.slug}/${accountDetails.id}`;
      const links = validEmails.map(
        (email) => `${baseUrl}?email=${encodeURIComponent(email)}`
      );

      setIsSending(true);
      setIsInviteButtonDisabled(true);
      try {
        await Promise.all([
          updateCompanyOnBoarding({
            CONTACT_ID: asLoggedUserDetails.userId,
            ON_BOARDING_STATE: true,
            PHONE_COUNTRY_CODE:
              accountDetails.userData?.phoneNumber.countryCode || 'US',
          }),
          mutationSendInviteContacts({
            EMAILS: validEmails,
            BOARD_OWNER: boardOwner,
            COMPANY_ID: accountDetails.id,
            COMPANY_NAME: accountDetails.companyName,
            LINKS: links,
          }),
        ]);
      } catch (error) {
        console.log('error:', error);
      } finally {
        setSnackbarInviteOpen(true);
        setIsSending(false);
        setTimeout(() => {
          window.location.replace(currentPlan.onboardingRedirectUrl);
        }, 3000);
      }
    } else {
      await updateCompanyOnBoarding({
        CONTACT_ID: asLoggedUserDetails.userId,
        ON_BOARDING_STATE: true,
        PHONE_COUNTRY_CODE:
          accountDetails.userData?.phoneNumber.countryCode || 'US',
      });
      window.location.replace(currentPlan.onboardingRedirectUrl);
    }
  };

  useEffect(() => {
    setEmails(initialEmailsState);
  }, []);

  return (
    <>
      <ACSnackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        autoHideDuration={3000}
        text="Link copied, you can now share it with whoever you choose"
        severity="success"
        onClose={() => setSnackbarOpen(false)}
      />
      <ACSnackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarInviteOpen}
        autoHideDuration={3000}
        text="Your contacts have been invited to your AdvisoryCloud"
        severity="success"
        onClose={() => setSnackbarOpen(false)}
      />
      <PublicRegistration hideImage={isXS}>
        <Box
          sx={{
            borderRadius: '10px',
            width: '100%',
            height: '100%',
            maxWidth: { xxs: '100%', sm: '600px' },
            maxHeight: { xxs: '100%', sm: '500px' },
            m: 'auto',
            padding: '10px',
          }}
        >
          <Typography
            sx={{
              color: '#232B35',
              textAlign: 'center',
              fontSize: '28px',
              fontWeight: 700,
              lineHeight: '24px',
              letterSpacing: '-0.43px',
              marginTop: '10px',
              marginBottom: '20px',
            }}
          >
            Invite your contacts
          </Typography>
          <Typography
            sx={{
              color: '#646D7A',
              textAlign: 'center',
              fontSize: '14px',
              fontWeight: 500,
              lineHeight: '24px',
              letterSpacing: '-0.43px',
              marginBottom: '20px',
            }}
          >
            {accountDetails.userSegment === 'managed_advisory_board'
              ? 'Invite colleagues and professional contacts to join your advisory board'
              : 'Invite colleagues and professional contacts to join your AdvisoryCloud'}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '0px',
              paddingRight: '10px',
            }}
          >
            <Typography
              sx={{
                color: '#646D7A',
                fontSize: '13px',
                fontWeight: 500,
                lineHeight: '24px',
                letterSpacing: '-0.43px',
              }}
            >
              Send invites
            </Typography>

            <IconButton
              id="shareableLink"
              onClick={handleCopyLink}
              disableRipple
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <LinkIcon
                sx={{ color: '#3171F6', fontSize: '18px', marginRight: '5px' }}
              />
              <Typography
                sx={{
                  color: '#3171F6',
                  fontSize: '13px',
                  fontWeight: 500,
                  lineHeight: '24px',
                  letterSpacing: '-0.43px',
                }}
              >
                Get shareable link
              </Typography>
            </IconButton>
          </Box>
          <Box
            sx={{
              height: '100%',
              maxHeight: '180px',
              minHeight: '180px',
              display: 'flex',
              flexDirection: 'column',
              scrollbarGutter: 'stable',
              overflowY: 'scroll',
              paddingRight: '10px',
              marginBottom: '5px',
              '&::-webkit-scrollbar': {
                width: '8px',
              },
              '&::-webkit-scrollbar-thumb': {
                borderRadius: '5px',
                visibility: 'hidden',
              },
              '&:hover::-webkit-scrollbar-thumb': {
                visibility: 'visible',
                backgroundColor: '#F8F8F8',
                backgroundColor: '#C7C7C7',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#B7B7B7',
              },
              '&::-webkit-scrollbar-thumb:active': {
                backgroundColor: '#B7B7B7',
                boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
              },
              '&:hover': {
                overflowY: 'auto',
              },
            }}
          >
            {emails.map((email, index) => (
              <TextField
                error={emailErrors[index]}
                helperText={emailErrors[index] ? 'Invalid email' : ''}
                type="email"
                className="inviteTeamMembersEmailInput"
                sx={{
                  margin:
                    index === emails.length - 1 ? '0px' : '0px 0px 15px 0px',
                  width: { xxs: '100%', sm: '557px' },
                  '& .MuiInputBase-root': {
                    height: '44px',
                    borderRadius: '10px',
                  },
                  '& .MuiInputBase-input': {
                    height: '100%',
                  },
                  '& .Mui-error': {
                    color: '#f44336',
                  },
                  input: {
                    '&:-webkit-autofill': {
                      transitionDelay: '9999s',
                      transitionProperty:
                        'background-color, color, height, width',
                    },
                  },
                }}
                key={index}
                value={email}
                onChange={(e) => updateEmails(index, e.target.value)}
                onBlur={handleBlur}
                placeholder="Email address"
                fullWidth
                margin="normal"
              />
            ))}
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingRight: '20px',
            }}
          >
            <Button
              id="addMore"
              disableRipple
              sx={{
                color: '#646D7A',
                fontSize: '13px',
                fontWeight: 500,
                lineHeight: '24px',
                letterSpacing: '-0.43px',
                textTransform: 'none',
                marginTop: '0px',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
              onClick={handleAddMoreEmails}
            >
              <AddIcon
                sx={{
                  color: '#646D7A',
                  fontSize: '18px',
                  marginRight: '5px',
                }}
              />
              <Typography
                sx={{
                  color: '#646D7A',
                  fontSize: '13px',
                  fontWeight: 500,
                  lineHeight: '24px',
                  letterSpacing: '-0.43px',
                }}
              >
                Add more
              </Typography>
            </Button>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              zIndex: 1000,
              justifyContent: 'center',
              alignItems: 'center',
              position: isXS ? 'sticky' : 'relative',
              bottom: 0,
              padding: '10px 0px 15px 0px',
              background: 'white',
            }}
          >
            <Button
              id="continue"
              onClick={handleInvite}
              disabled={isSending}
              style={{
                width: isXS ? '98%' : '339px',
                height: '44px',
                borderRadius: '50px',
                marginTop: '10px',

                border: 'none',
                outline: 'none',
                boxShadow: 'none',
                textTransform: 'none',

                color: '#FFFFFF',
                fontFamily: 'Poppins',
                fontSize: '16px',
                fontWeight: 700,
                lineHeight: '24px',
                letterSpacing: '-0.4300000071525574px',
                textAlign: 'center',

                backgroundColor: '#3171F6',
                '&:hover': {
                  boxShadow: 'none',
                },
                '&:disabled': {
                  backgroundColor: '#E0E0E0',
                  opacity: '1',
                },
              }}
              variant="contained"
            >
              {!isInviteButtonDisabled
                ? 'Invite and finish setup'
                : 'Finish setup'}
              {isSending && (
                <CircularProgress
                  size={28}
                  thickness={5}
                  style={{
                    color: '#3171F6',
                    position: 'absolute',
                    left: '45%',
                    top: '20%',
                  }}
                />
              )}
            </Button>
          </Box>
          <Box>
            <Button
              id="back"
              type="button"
              variant="outlined"
              color="primary"
              disableRipple
              onClick={() =>
                isSending ? null : history.push('/company/new/step4')
              }
              sx={{
                borderRadius: '50px',
                width: '100%',
                border: '0px solid',
                backgroundColor: 'transparent',
                '&:hover': {
                  backgroundColor: 'transparent',
                  border: '0px solid',
                },
                fontWeight: '500',
                fontSize: '13px',
                textTransform: 'none',
                color: '#646D7A',
              }}
            >
              Back
            </Button>
          </Box>
        </Box>
      </PublicRegistration>
    </>
  );
};

export default CompanyOnboardingFreemiumStep5;
