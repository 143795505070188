import React, { useState, useEffect } from 'react';
import {
  Paper,
  Typography,
  useMediaQuery,
  Avatar,
  Button,
} from '@mui/material';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import StarIcon from '@mui/icons-material/Star';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { getAdvisorImagePath } from '../../services/utils';
import StarSymbol from '../Advisor/Profile/components/star-symbol.svg';
import eventBus, { eventBusValues } from '../../eventBus';

const useStyles = (isSM) => ({
  card: {
    padding: '15px',
    margin: '15px',
    border: 'solid 1px #F0F1F3',
    borderRadius: '10px',
  },
  publicProfileHeaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '15px',
  },
  title: {
    fontWeight: 400,
    fontSize: '14px',
    textAlign: isSM ? 'center' : 'auto',
    paddingTop: isSM ? '20px' : '0px',
    color: '#273762',
    display: 'flex',
    alignItems: 'center',
  },
  headline: {
    fontWeight: 400,
    fontSize: '12px',
    color: '#9D9DAA',
    maxHeight: '50px',
    minWidth: '0px',
    marginTop: '0px',
    position: 'relative',
    textOverflow: 'ellipsis',
    overflowWrap: 'break-word',
    textAlign: isSM ? 'center' : 'auto',
  },
  primarySkillsTitle: {
    fontWeight: 300,
    fontSize: '12px',
    textAlign: isSM ? 'center' : 'auto',
    paddingTop: isSM ? '20px' : '30px',
    color: '#767680',
    textTransform: 'uppercase',
  },
  primarySkills: {
    fontWeight: 700,
    textAlign: isSM ? 'center' : 'auto',
    paddingTop: '0px',
    color: '#273762',
  },
  imageAndBiographyContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  iconInButton: {
    fontSize: '12px',
  },
  biographyTitle: {
    fontWeight: 300,
    fontSize: '12px',
    textAlign: isSM ? 'center' : 'auto',
    color: '#767680',
    textTransform: 'uppercase',
  },
  imageContainer: {
    width: isSM ? '180px' : '120px',
  },
  avatar: {
    width: isSM ? '180px' : '120px',
    height: isSM ? '180px' : '120px',
    margin: isSM ? 'auto' : '0',
    boxShadow: '1px 2px 5px #bebebe',
    border: 'solid 2px white',
  },
  headlineContainer: {
    marginLeft: '20px',
    marginRight: '15px',
    width: isSM ? '100%' : 'calc(100% - 300px)',
  },
  biographyInformationContainer: {
    fontWeight: 400,
    marginTop: '15px',
    marginLeft: '20px',
    color: '#273762',
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  favoriteEmptyIconConatiner: {
    position: 'absolute',
  },
  favoriteEmptyIconButton: {
    color: '#9D9DAA',
    position: 'relative',
    top: '-20px',
    left: '-20px',
    padding: 3,
    fontSize: '30px',
    cursor: 'pointer',
  },
  favoriteIconButton: {
    color: '#FFD700',
    position: 'absolute',
    padding: '3px',
    fontSize: isSM ? '40px' : '30px',
    cursor: 'pointer',
  },
  viewProfileButton: {
    background: '#0F78FD',
    color: '#FFF',
    width: '120px',
    height: '35px',
    marginBottom: '10px',
    textTransform: 'none',
    fontSize: '12px',
    borderColor: '#0F78FD',
    '&:hover': {
      background: '#0F78FD',
    },
  },
  addToBoard: {
    color: '#0F78FD',
    width: '120px',
    textTransform: 'none',
    fontSize: '12px',
    borderColor: '#0F78FD',
    height: '20px',
    '&:hover': {
      background: 'white',
    },
  },
  linkButton: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    color: '#1078fd',
    fontSize: '12px',
    fontWeight: 400,
    display: 'inline',
    margin: '0px',
    padding: '0px',
  },
});

function AdvisorCardPublicSearch({ advisor, skills, setLoginModal }) {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const [isFavorite] = useState(false);
  const [skillsString, setSkills] = useState('');
  const [shortBiografy, setShortBiografy] = useState('');
  const [viewMore, setViewMore] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles(isSM);

  useEffect(() => {
    const advisorSkills = advisor.skillTagIds ? advisor.skillTagIds : [];
    let skillsContainer = skills
      ? skills
          .filter((skill) => advisorSkills.includes(skill.id))
          .map((sk) => sk.label)
          .slice(0, 10)
      : [];
    skillsContainer = skillsContainer.join(' • ');
    setSkills(skillsContainer);
    let shortBio = '';
    if (advisor.biography && advisor.biography.length > 250) {
      shortBio = advisor.biography.slice(0, 250);
      const lastwhiteSpace = shortBio.lastIndexOf(' ');
      shortBio = shortBio.slice(0, lastwhiteSpace).concat('... ');
    } else {
      shortBio = advisor.biography;
    }
    setShortBiografy(shortBio);
  }, []);

  function selectFavorite() {
    setLoginModal(true);
  }

  function visitProfile() {
    eventBus.dispatch(eventBusValues.triggerAdvisorDrawerAction, {
      fetchAdvisor: 'public',
      id: advisor.id,
    });
  }

  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: '#ffffff',
      '&:before': {
        border: '1px solid #E6EDFF',
        boxShadow: '0px 1px 10px 0px #E6EDFE',
      },
    },
    [`& .${tooltipClasses.tooltip}`]: {
      color: '#232B35',
      padding: '10px',
      backgroundColor: '#ffffff',
      borderRadius: '10px',
      border: '1px solid #E6EDFF',
      boxShadow: '0px 1px 10px 0px #E6EDFE',
      fontSize: 12,
    },
  }));

  return (
    <Paper sx={classes.card} elevation={0}>
      <div>
        {!isSM ? (
          <div style={classes.publicProfileHeaderContainer}>
            <div style={classes.favoriteEmptyIconConatiner}>
              {isFavorite ? (
                <StarIcon
                  sx={classes.favoriteIconButton}
                  onClick={selectFavorite}
                />
              ) : (
                <StarBorderIcon
                  sx={classes.favoriteEmptyIconButton}
                  onClick={selectFavorite}
                />
              )}
            </div>
            <div style={classes.mainInformationContainer}>
              <div style={classes.imageAndBiographyContainer}>
                <div style={classes.imageContainer}>
                  {advisor.featuredAdvisor ? (
                    <div
                      style={{
                        borderRadius: '50%',
                        width: isSM ? '188px' : '128px',
                        height: isSM ? '188px' : '128px',

                        background:
                          'linear-gradient(180deg, #4DF4FF 0%, rgba(38, 83, 242, 0.69) 43.75%, rgba(61, 19, 182, 0.63) 80.21%, #6736FA 100%)',
                        padding: '4px',
                      }}
                    >
                      <Avatar
                        src={getAdvisorImagePath(advisor)}
                        sx={{ ...classes.avatar }}
                      />
                    </div>
                  ) : (
                    <Avatar
                      src={getAdvisorImagePath(advisor)}
                      sx={{ ...classes.avatar }}
                    />
                  )}
                </div>

                <div style={classes.headlineContainer}>
                  <Typography sx={classes.title}>
                    {advisor.displayName}
                    {advisor.featuredAdvisor && (
                      <CustomTooltip
                        title="Featured Advisors have priority search listings and may participate in our featured advisor interviews and podcasts."
                        arrow
                        placement="right"
                      >
                        <img
                          src={StarSymbol}
                          alt="star-symbol"
                          style={{
                            marginTop: '-5px',
                            marginLeft: '5px',
                            width: '20px',
                            height: '20px',
                            cursor: 'default',
                          }}
                        />
                      </CustomTooltip>
                    )}
                  </Typography>

                  <Typography sx={classes.headline}>
                    {advisor.headline}
                  </Typography>
                  {skillsString ? (
                    <Typography sx={classes.primarySkillsTitle}>
                      {t('PUBLIC-SEARCH-CARD-PRIMARY-SKILLS')}
                    </Typography>
                  ) : null}
                  <Typography sx={classes.primarySkills}>
                    {skillsString}
                  </Typography>
                </div>
                <div style={classes.actionsContainer}>
                  <Button
                    variant="outlined"
                    sx={classes.viewProfileButton}
                    onClick={() => {
                      visitProfile();
                    }}
                  >
                    {t('VIEW_PROFILE')}
                  </Button>
                  <Button
                    variant="text"
                    sx={classes.addToBoard}
                    startIcon={<PersonAddAltIcon sx={classes.iconInButton} />}
                    onClick={() => setLoginModal(true)}
                  >
                    {t('PUBLIC-SEARCH-CARD-JOIN-BOARD')}
                  </Button>
                </div>
              </div>
              {advisor.biography ? (
                <div style={classes.biographyInformationContainer}>
                  <Typography sx={classes.biographyTitle}>
                    {t('PUBLIC-ADVISOR-BIOGRAPHY-LABEL')}
                  </Typography>
                  {viewMore ? (
                    <Typography>
                      {advisor.biography}
                      <button
                        type="button"
                        style={classes.linkButton}
                        onClick={() => setViewMore(!viewMore)}
                      >
                        {t('PUBLIC-SEARCH-CARD-VIEW-LESS')}
                      </button>
                    </Typography>
                  ) : (
                    <Typography>
                      {shortBiografy}
                      {advisor.biography && advisor.biography.length > 250 ? (
                        <button
                          onClick={() => setViewMore(!viewMore)}
                          type="button"
                          style={classes.linkButton}
                        >
                          {t('PUBLIC-SEARCH-CARD-VIEW-MORE')}
                        </button>
                      ) : null}
                    </Typography>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        ) : (
          <div style={classes.publicProfileHeaderContainer}>
            {isFavorite ? (
              <StarIcon
                sx={classes.favoriteIconButton}
                onClick={selectFavorite}
              />
            ) : (
              <StarBorderIcon
                sx={classes.favoriteEmptyIconButton}
                onClick={selectFavorite}
              />
            )}

            <div style={classes.mainInformationContainer}>
              <div style={classes.imageAndBiographyContainer}>
                <div style={classes.imageContainer}>
                  <Avatar
                    src={getAdvisorImagePath(advisor)}
                    sx={{ ...classes.avatar }}
                  />
                </div>
                <div style={classes.actionsContainer}>
                  <Button
                    variant="outlined"
                    sx={classes.viewProfileButton}
                    onClick={() => {
                      visitProfile();
                    }}
                  >
                    {t('VIEW_PROFILE')}
                  </Button>
                  <Button
                    sx={classes.addToBoard}
                    variant="text"
                    startIcon={<PersonAddAltIcon sx={classes.iconInButton} />}
                    onClick={() => setLoginModal(true)}
                  >
                    {t('PUBLIC-SEARCH-CARD-JOIN-BOARD')}
                  </Button>
                </div>
              </div>
              <div style={classes.headlineContainer}>
                <Typography sx={classes.title}>
                  {advisor.displayName}
                </Typography>

                <Typography sx={classes.headline}>
                  {advisor.headline}
                </Typography>

                {skillsString ? (
                  <Typography sx={classes.primarySkillsTitle}>
                    {t('PUBLIC-SEARCH-CARD-PRIMARY-SKILLS')}
                  </Typography>
                ) : null}
                <Typography sx={classes.primarySkills}>
                  {skillsString}
                </Typography>
              </div>
              {advisor.biography ? (
                <div style={classes.biographyInformationContainer}>
                  <Typography sx={classes.biographyTitle}>
                    {t('PUBLIC-ADVISOR-BIOGRAPHY-LABEL')}
                  </Typography>
                  {viewMore ? (
                    <Typography>
                      {advisor.biography}
                      <button
                        onClick={() => setViewMore(!viewMore)}
                        type="button"
                        style={classes.linkButton}
                      >
                        {t('PUBLIC-SEARCH-CARD-VIEW-LESS')}
                      </button>
                    </Typography>
                  ) : (
                    <Typography>
                      {shortBiografy}
                      {advisor.biography && advisor.biography.length > 250 ? (
                        <button
                          onClick={() => setViewMore(!viewMore)}
                          type="button"
                          style={classes.linkButton}
                        >
                          {t('PUBLIC-SEARCH-CARD-VIEW-MORE')}
                        </button>
                      ) : null}
                    </Typography>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        )}
      </div>
    </Paper>
  );
}

export default AdvisorCardPublicSearch;
