import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid } from '@mui/material';
import { PriorityHigh } from '@mui/icons-material';
import IconUnderlay from '../../components/IconUnderlay';
import CardScene from '../../components/CardScene';

const useStyles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '300px',
    marginTop: '-10px',
    backgroundColor: 'white',
  },
  icon: {
    fontSize: '70px',
    color: 'error.main',
  },
  text: {
    maxWidth: 400,
    textAlign: 'center',
    color: 'secondary.dark',
  },
});

function OopsWindow() {
  const { t } = useTranslation();
  const classes = useStyles();

  const RenderHeader = () => {
    return (
      <>
        <Typography variant="h2" color="textSecondary" sx={classes.text}>
          {t('OOPS')}
        </Typography>
      </>
    );
  };

  const RenderOopsWindow = () => {
    return (
      <>
        <CardScene header={<RenderHeader />}>
          <div style={classes.container}>
            <Grid
              container
              justify="center"
              alignItems="center"
              direction="column"
              spacing={2}
            >
              <Grid item>
                <IconUnderlay size={90}>
                  <PriorityHigh sx={classes.icon} />
                </IconUnderlay>
              </Grid>
              <Grid item>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  direction="column"
                >
                  <Typography
                    variant="h2"
                    color="textSecondary"
                    sx={classes.text}
                  >
                    {t('PASSWORD_RESET_LINK_ERROR')}
                  </Typography>
                  <Typography
                    variant="h2"
                    color="textSecondary"
                    sx={classes.text}
                  >
                    {t('PASSWORD_RESET_LINK_ERROR_ATTEMPT')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </CardScene>
      </>
    );
  };

  return (
    <>
      <RenderOopsWindow />
    </>
  );
}

export default OopsWindow;
