import React, { useEffect } from 'react';

function TokensIFrame() {
  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  const impersonateUserId = params.get('impersonate');
  const impersonateUserType = params.get('userType');
  document.cookie = `impersonateUserId=${impersonateUserId};domain=advisorycloud.com;path=/;`;
  document.cookie = `impersonateUserType=${impersonateUserType};domain=advisorycloud.com;path=/;`;

  function goToDasboard() {
    window.location.assign(
      `/dashboard?impersonate=${impersonateUserId}&userType=${impersonateUserType}&getCredentials=false`
    );
  }

  function messageHandler(event) {
    if (event.data && event.data.length) {
      event.data.forEach((storageItem) => {
        localStorage.setItem(storageItem[0], storageItem[1]);
      });
      setTimeout(function () {
        goToDasboard();
      }, 3000);
    }
  }

  useEffect(() => {
    window.addEventListener('message', messageHandler);
    return () => window.removeEventListener('message', messageHandler);
  }, []);

  return (
    <iframe
      title="sharedCredentials"
      src={`${process.env.REACT_APP_ADMIN_URL}/sharedCredentials`}
    />
  );
}

export default TokensIFrame;
