import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size';
import ACSnackbar from './../../ACSnackbar';

import { useTranslation } from 'react-i18next';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import 'filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css';
import { DokaImageEditorModal } from 'react-doka';
import {
  locale_en_gb,
  createDefaultImageReader,
  createDefaultImageWriter,
  setPlugins,
  plugin_crop,
  plugin_crop_locale_en_gb,
  plugin_crop_defaults,
  plugin_finetune,
  plugin_finetune_locale_en_gb,
  plugin_finetune_defaults,
  plugin_filter,
  plugin_filter_locale_en_gb,
  plugin_filter_defaults,
  plugin_decorate,
  plugin_decorate_defaults,
  plugin_decorate_locale_en_gb,
  component_shape_editor_locale_en_gb,
} from 'doka/doka';
import { useAccount } from './../../../contexts/Account';
import 'doka/doka.css';
import { labels } from './filePondLabels';

registerPlugin(
  FilePondPluginImagePreview,
  FilePondPluginImageExifOrientation,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType,
  FilePondPluginImageResize,
  FilePondPluginImageTransform,
  FilePondPluginImageCrop,
  FilePondPluginImageEdit,
  FilePondPluginFileEncode,
  FilePondPluginImageValidateSize
);

setPlugins(plugin_crop, plugin_finetune, plugin_filter, plugin_decorate);

const editorDefaults = {
  imageReader: createDefaultImageReader(),
  imageWriter: createDefaultImageWriter(),
  ...plugin_crop_defaults,
  ...plugin_finetune_defaults,
  ...plugin_filter_defaults,
  ...plugin_decorate_defaults,
  locale: {
    ...locale_en_gb,
    ...plugin_crop_locale_en_gb,
    ...plugin_finetune_locale_en_gb,
    ...plugin_filter_locale_en_gb,
    ...plugin_decorate_locale_en_gb,
    ...component_shape_editor_locale_en_gb,
  },
};

const filePondCompanyOptions = {
  dropOnPage: 'true',
  stylePanelLayout: 'compact',
  stylePanelAspectRatio: '1:1',
  allowDrop: 'true',
  allowRemove: 'true',
  allowProcess: 'true',
  allowBrowse: 'true',
  allowFileSizeValidation: 'true',
  allowFileTypeValidation: 'true',
  allowImageExifOrientation: 'true',
  allowImagePreview: 'true',
  allowImageResize: 'true',
  allowImageTransform: 'true',
  allowImageCrop: 'true',
  allowFileEncode: 'true',
  allowImageValidateSize: 'true',
  imageTransformVariantsIncludeOriginal: 'true',
  imagePreviewMaxFileSize: '10MB',
  imageResizeMode: 'contain',
  maxFileSize: '10MB',
  imageResizeTargetWidth: '600',
  imageResizeTargetHeight: '600',
  imageCropAspectRatio: '1:1',
  maxFiles: 1,
  credits: 'false',
  acceptedFileTypes: `${['image/jpg', 'image/jpeg', 'image/png', 'image/gif']}`,
  imageTransformOutputMimeType: 'image/png',
  imageEditAllowEdit: 'true',
  styleImageEditButtonEditItemPosition: 'bottom right',
  instantUpload: 'false',
};

const useStyles = makeStyles((theme) => ({
  uploader: {
    width: '120px',
    height: '120px',
    '& .filepond--drop-label': {
      marginTop: '20%',
      fontSize: '0.8rem',
      color: theme.palette.secondary.dark,
    },
    '& .filepond--file': {
      fontSize: '0.8rem',
    },
    '& .filepond--file-info': {
      display: 'none',
    },
  },
}));

function CompanyLogoUploader(props) {
  const { url, status, resultFiles } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const { accountDetails } = useAccount();
  const [preloadedImage, setPreloadedImage] = useState();
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [currentImage, setCurrentImage] = useState(url);
  const [dokaImage, setDokaImage] = useState();
  const [openModal, setOpenModal] = useState(false);
  const ref = React.useRef();

  async function preloadFilepondImage() {
    const imageToPreloadURL = await fetch(
      `${accountDetails.companyS3Logo.location}`,
      {
        method: 'get',
        headers: {
          'Access-Control-Origin': '*',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': '*',
        },
      }
    )
      .then((response) => {
        return response.blob();
      })
      .catch(() => {
        setShowErrorSnackbar(true);
      });

    setPreloadedImage(imageToPreloadURL);
  }

  useEffect(() => {
    if (!accountDetails.image.location.includes('undefined')) {
      preloadFilepondImage();
    }
  }, []);

  useEffect(() => {
    if (preloadedImage) {
      const newBlob = preloadedImage.slice(0, preloadedImage.size, 'image/png');
      const newBlobURL = URL.createObjectURL(newBlob);

      setCurrentImage(newBlobURL);
    }
  }, [preloadedImage]);

  function setupFiles(values) {
    if (values.length === 0) {
      setCurrentImage(null);
      setDokaImage(null);
      status(true);
      setOpenModal(false);
      resultFiles({});
    }
    if (values.length === 1) {
      status(false);
      setCurrentImage(values);
      setOpenModal(false);
    }
    return null;
  }

  function catchUpdate(data) {
    const result = URL.createObjectURL(data);
    setCurrentImage(result);
    setOpenModal(false);
  }

  function catchOutputFiles(data) {
    const outputFiles = ref.current.getFile();

    const base64Images = outputFiles.getFileEncodeBase64String();

    const originalImageBase64String = base64Images[0].data;
    const imageBase64String = base64Images[1].data;

    const originalImageType = data[0].file.type;
    const imageType = data[1].file.type;

    const originalImageBase64URL = `data:${originalImageType};base64,${originalImageBase64String}=`;
    const imageBase64URL = `data:${imageType};base64,${imageBase64String}=`;

    resultFiles({
      originalImage: originalImageBase64URL,
      image: imageBase64URL,
    });
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <ACSnackbar
        open={showErrorSnackbar}
        text={t('PROFILE-UPLOADER-ERROR')}
        severity="error"
        onClose={() => setShowErrorSnackbar(false)}
      />

      <Typography
        variant="old_font"
        sx={{
          color: 'black',
          marginBottom: '5px',
        }}
      >
        {t('COMPANY-LOGO-UPLOADER-LABEL')}
      </Typography>
      <FilePond
        ref={ref}
        {...labels}
        {...filePondCompanyOptions}
        labelIdle={`${t('COMPANY-LOGO-UPLOADER-DRAG')}
          <span class="filepond--label-action">${t(
            'COMPANY-LOGO-UPLOADER-BROWSE'
          )}</span>`}
        files={currentImage}
        className={classes.uploader}
        onerror={() => status(true)}
        onupdatefiles={(files) => {
          setupFiles(files);
        }}
        onpreparefile={(files, output) => {
          const originalInput = URL.createObjectURL(output[0].file);
          setDokaImage(originalInput);
          catchOutputFiles(output);
        }}
        imageEditEditor={{
          open: () => {
            setOpenModal(true);
          },
        }}
      />
      <div
        style={{
          marginTop: '-15px',
          paddingBottom: '10px',
          width: '120px',
        }}
      >
        <Typography
          component="div"
          variant="old_font"
          color="textSecondary"
          sx={{
            fontSize: '0.6rem',
          }}
        >
          {t('COMPANY-LOGO-UPLOADER-LIMITS-PART1')}
          {` ${t('COMPANY-LOGO-UPLOADER-LIMITS-PART2')}`}
        </Typography>
      </div>
      {openModal ? (
        <DokaImageEditorModal
          {...editorDefaults}
          src={dokaImage}
          onClose={() => setOpenModal(false)}
          onHide={() => setOpenModal(false)}
          onProcess={({ dest }) => catchUpdate(dest)}
          imageWriter={createDefaultImageWriter({
            mimeType: 'image/png',
          })}
        />
      ) : null}
    </div>
  );
}

export default CompanyLogoUploader;
