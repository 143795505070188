import React, { useEffect, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import DefaultAdvisorAvatar from './../../../icons/user-avatar.svg';
import CustomIcon from './../../../icons/customIcon';
import { useAccount } from './../../../contexts/Account';
import eventBus, { eventBusValues } from './../../../eventBus';
import useCanMessage from './../../../hooks/useMessagePermission';

const StatusPill = ({ status }) => {
  if (!status || status === 'member') {
    return null;
  }

  return (
    <div
      style={{
        position: 'absolute',
        top: '-8px',
        right: '-6px',
        height: '18px',
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
        fontSize: '10px',
        fontWeight: 600,
        letterSpacing: '-0.32px',
        paddingRight: 10,
        paddingLeft: 10,
        background: status === 'invitation' ? '#4BC6FF' : '#1D95CD',
      }}
    >
      {status === 'invitation' ? 'Invitation' : 'Pending'}
    </div>
  );
};

const BoardCard = ({
  logo,
  memberName,
  companyName,
  status,
  onClick,
  boardMember,
  memberType,
  isRecentlyMessaged,
  isBoardOwner,
  boardMembersTab,
}) => {
  const { conversations } = useAccount();
  const canMessage = useCanMessage(boardMember.id, memberType);
  const [alreadyMessagedBoardOwner, setAlreadyMessagedBoardOwner] =
    useState(false);
  const trimmedMemberName = memberName
    ? memberName.length > 20
      ? memberName.substring(0, 10) + '...'
      : memberName
    : '';
  const trimmedTitle =
    boardMember && boardMember.title
      ? boardMember.title.length > 18
        ? boardMember.title.substring(0, 18) + '...'
        : boardMember.title
      : '';

  function conversationClick(member) {
    if (canMessage === undefined) return;
    if (canMessage) {
      const found = conversations.find(
        (conversation) => conversation.conversationMemberAccountId === member.id
      );

      if (found) {
        window.location.replace(
          `${window.location.origin}/messages?conversationId=${found.conversationId}`
        );
      } else {
        eventBus.dispatch(eventBusValues.onSendConversationNewMessage, {
          senderId: member.id,
          senderName: member.displayName,
        });
      }
    } else {
      eventBus.dispatch(eventBusValues.triggerAdvisorUpgradeAction, {
        gatedAction: true,
        companyName: companyName,
      });
    }
  }

  function findConversation() {
    if (boardMembersTab) {
      const index = conversations.findIndex(
        (conversation) =>
          conversation.conversationMemberAccountId === boardMember.id
      );
      if (index >= 0) {
        return true;
      }
    }
    return false;
  }

  function renderIcon() {
    if (isRecentlyMessaged || alreadyMessagedBoardOwner || findConversation()) {
      return (
        <IconButton
          style={{ width: '100%', height: '100%', color: ' #232B35' }}
          onClick={() => conversationClick(boardMember)}
        >
          <CustomIcon iconname="comments" style={{ fontSize: 20, zIndex: 3 }} />
        </IconButton>
      );
    }

    return (
      <IconButton
        style={{ width: '100%', height: '100%', color: ' #232B35' }}
        onClick={() => conversationClick(boardMember)}
      >
        <CustomIcon iconname="airplane" style={{ fontSize: 16, zIndex: 3 }} />
      </IconButton>
    );
  }

  function findingConversation() {
    if (!isRecentlyMessaged && isBoardOwner) {
      const index = conversations.findIndex(
        (conversation) =>
          conversation.conversationMemberAccountId === boardMember.id
      );
      setAlreadyMessagedBoardOwner(index > -1);
    }
  }

  useEffect(() => {
    findingConversation();
    eventBus.on(eventBusValues.onNewConversation, findingConversation);

    return () => {
      eventBus.remove(eventBusValues.onNewConversation, findingConversation);
    };
  }, []);

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: isBoardOwner ? '76px' : '74px',
        borderRadius: '12px',
        justifyContent: 'space-between',
        paddingLeft: '21px',
        paddingRight: '21px',
        marginTop: isBoardOwner ? '18px' : '',
        '&:hover': isBoardOwner
          ? {
              backgroundColor: '#E0E9F8',
              boxShadow: 'none',
            }
          : '',
        cursor: 'pointer',
      }}
      className={isBoardOwner ? '' : ' advisorBoardMemberCard'}
      id={isBoardOwner ? 'boardOwnerCard' : ''}
    >
      <div
        onClick={onClick ? onClick : conversationClick}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'start',
          width: '80%',
        }}
      >
        <img
          src={
            logo && !logo.includes('undefined') && !logo.includes('null')
              ? logo
              : DefaultAdvisorAvatar
          }
          alt="Company Logo"
          style={{
            width: '50px',
            height: '50px',
            borderRadius: '25px',
          }}
        />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              fontFamily: 'Poppins',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '13px',
              lineHeight: '24px',
              letterSpacing: '-0.43px',
              color: '#232B35',
              marginLeft: 10,
            }}
          >
            {trimmedMemberName}
          </div>
          {boardMember && boardMember.title && (
            <div
              style={{
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontWeight: 300,
                fontSize: '10px',
                lineHeight: '15px',
                letterSpacing: '-0.43px',
                color: '#1C2F49',
                marginLeft: 10,
              }}
            >
              {trimmedTitle}
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          width: 34,
          height: 34,
          background: '#F5F7FA',
          borderRadius: 20,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {renderIcon()}
      </div>
      <StatusPill status={status} />
    </Box>
  );
};

export default BoardCard;
