import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Typography,
  Dialog,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Card from './Card';
import { useAccount } from '../../contexts/Account';
import { querySavedInsightsAdvisor } from '../../services/advisor_services';
import {
  getAllInsightsV2,
  querySavedInsightsCompany,
} from '../../services/company_services';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import LoadingIndicatorLogo from '../../components/LoadingIndicatorLogo';
import { AccountType } from '../../services/utils/types';
import CompanyTopTilesContainer from '../../components/Company/TopTiles';
import Treatment from '../../components/Treatment';
import { featureFlags } from '../../splitSetup';

function Insights() {
  const { accountDetails, type, currentUserInfo } = useAccount();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [iframeVisible, setIframeVisible] = useState(false);
  const [insightsData, setInsightsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [iframeUrl, setIframeUrl] = useState('');
  const [savedInsights, setSavedInsights] = useState([]);

  const openIframeModal = (url) => {
    setIframeUrl(url);
    setIframeVisible(true);
  };

  const closeIframeModal = () => {
    setIframeVisible(false);
    setLoading(true);
  };

  const handleIframeLoad = () => {
    setLoading(false);
  };

  function findAllBoardOwners() {
    if (type === AccountType.ADVISOR) {
      return accountDetails;
    }
    if (
      !accountDetails ||
      !accountDetails.contacts ||
      !Array.isArray(accountDetails.contacts)
    ) {
      return [];
    }
    const boardOwners = accountDetails.contacts.filter(
      (contact) => contact.isBoardOwner === true
    );

    return boardOwners[0];
  }
  const currentUser = findAllBoardOwners();

  function sortInsightsByPriority(insightsData) {
    const favorites = insightsData.filter((insight) =>
      savedInsights.some((saved) => saved.insightId === insight.id)
    );
    const nonFavorites = insightsData.filter(
      (insight) =>
        !savedInsights.some((saved) => saved.insightId === insight.id)
    );
    favorites.sort((a, b) => b.priority - a.priority);
    nonFavorites.sort((a, b) => b.priority - a.priority);

    return [...favorites, ...nonFavorites];
  }
  const sortedInsights = sortInsightsByPriority(insightsData);

  useEffect(() => {
    async function fetchInsights() {
      try {
        const response = await getAllInsightsV2({ status: 'ALL' });
        setInsightsData(response.data.getInsightsV2);
      } catch (error) {
        console.error('Error on insights call:', error);
      }
    }

    fetchInsights();
  }, []);

  useEffect(() => {
    async function fetchSavedInsights() {
      try {
        if (type === AccountType.ADVISOR) {
          const response = await querySavedInsightsAdvisor({
            ADVISOR_ID: currentUser.id,
          });
          setSavedInsights(response.data.getSavedInsightsAdvisor);
          return;
        }
        const response = await querySavedInsightsCompany({
          CONTACT_ID: currentUser.id,
        });
        setSavedInsights(response.data.getSavedInsightsCompany);
      } catch (error) {
        console.error('Error fetching saved insights:', error);
      }
    }

    fetchSavedInsights();
  }, []);

  function topTile() {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          padding: '20px',
          margin: {
            xxs:
              type === AccountType.ADVISOR ? '40px 0px 60px' : '40px 40px 60px',
            sm: '20px 30px 60px',
          },
          overflow: 'hidden',
          position: 'relative',
          maxWidth: '1200px',
          zIndex: { xxs: type === AccountType.ADVISOR ? 1 : null, sm: null },
          borderRadius: '16px',
          '::before': {
            content: "''",
            position: 'absolute',
            width: { xxs: '300px', xs: '500px' },
            height: { xxs: '300px', xs: '500px' },
            borderRadius: '50%',
            backgroundColor: '#DBF7EB',
            transform: 'translate(-50%, -50%)',
            top: 0,
            left: 0,
            zIndex: { xxs: -1, md: type === AccountType.ADVISOR ? 0 : -1 },
          },
          '::after': {
            content: "''",
            position: 'absolute',
            width: { xxs: '300px', xs: '500px' },
            height: { xxs: '300px', xs: '500px' },
            borderRadius: '50%',
            backgroundColor: '#D7F1FD',
            transform: 'translate(50%, 50%)',
            bottom: 0,
            right: 0,
            zIndex: { xxs: -1, md: type === AccountType.ADVISOR ? 0 : -1 },
          },
        }}
      >
        <Typography
          sx={{
            fontSize: '28px',
            color: '#232B35',
            fontWeight: 700,
            width: {
              '@media (min-width:1550px)': { width: '40%' },
              xxs: '100%',
              xs: '80%',
              sm: '70%',
              md: '50%',
            },
            marginBottom: '20px',
          }}
        >
          Get feedback from the most important perspectives
        </Typography>
        <Typography
          sx={{
            fontSize: '16px',
            color: '#232B35',
            fontWeight: 500,
            width: { xxs: '100%', xs: '80%', sm: '62%', md: '42%' },
            marginBottom: '20px',
          }}
        >
          Commission custom feedback, surveys, panels and more with our network
          of 10,000+ professionals
        </Typography>
        <button
          id="INSIGHT_BUTTON_START"
          style={{
            marginBottom: '20px',
            fontSize: '16px',
            borderRadius: '999px',
            fontWeight: 700,
            width: '157px',
            height: '48px',
            backgroundColor: '#3171F6',
            color: '#FFFFFF',
            borderColor: 'none',
            fontFamily: 'Poppins',
            border: 'none',
            outline: 'none',
            cursor: 'pointer',
          }}
          variant="contained"
        >
          Get started
        </button>
        <Typography
          id="INSIGHT_WATCH_BUTTON"
          sx={{
            fontSize: '14px',
            color: '#232B35',
            fontWeight: 500,
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <PlayCircleOutlineOutlinedIcon
            id="INSIGHT_WATCH_BUTTON"
            sx={{ marginRight: '5px', cursor: 'pointer' }}
          />
          Watch the video
        </Typography>
      </Box>
    );
  }
  return (
    <>
      <Helmet>
        <title>Insights</title>
      </Helmet>

      <Dialog
        open={iframeVisible}
        onClose={closeIframeModal}
        fullScreen={isSM}
        fullWidth={!isSM}
        PaperProps={{
          style: {
            height: isSM ? '100%' : '700px',
            width: isSM ? '100%' : '800px',
            maxHeight: '100vh',
            maxWidth: '100vw',
            borderRadius: '10px',
          },
        }}
      >
        {isSM && (
          <IconButton
            onClick={closeIframeModal}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        )}
        {loading && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 1,
            }}
          >
            <LoadingIndicatorLogo
              size={200}
              iconFontSize={93}
              iconRight={105}
            />
          </Box>
        )}
        <Box sx={{ overflow: 'hidden', height: '100%', width: '100%' }}>
          <iframe
            src={`${iframeUrl}?first_name=${encodeURIComponent(
              currentUser.givenName
            )}&last_name=${encodeURIComponent(
              currentUser.surName
            )}&plancode=${encodeURIComponent(
              accountDetails.recurlyPlanCode
            )}&cognito_id=${encodeURIComponent(
              currentUser.id
            )}&sosfcontactid=${encodeURIComponent(
              accountDetails.salesforceContactId
            )}&email=${encodeURIComponent(currentUser.email)}`}
            onLoad={handleIframeLoad}
            title="demoVideo"
            allow="autoplay; fullscreen; picture-in-picture"
            width="100%"
            height="100%"
            style={
              loading
                ? { display: 'none' }
                : { border: 'none', overflow: 'hidden' }
            }
            frameBorder="0"
          />
        </Box>
      </Dialog>
      {type === AccountType.ADVISOR ? (
        topTile()
      ) : (
        <div style={{ padding: '30px', maxWidth: '1200px' }}>
          <CompanyTopTilesContainer
            circleFirstColor="rgba(219, 247, 235, 0.5)"
            circleSecondColor="rgba(215, 241, 253, 0.5)"
            title="Get feedback from the most important perspectives"
            subtitle="Commission custom feedback, surveys, panels and more with our network of professionals"
            basicButtons
            circleAlternative
          />
        </div>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          maxWidth: '1200px',
          margin: '40px 40px 60px 40px',
        }}
      >
        <Typography
          sx={{
            fontSize: '16px',
            color: '#232B35',
            marginBottom: '40px',
            fontWeight: 600,
          }}
        >
          Select one of our standard use cases or work with our team on a custom
          solution
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            gap: 3,
            width: '100%',
          }}
        >
          {sortedInsights.map((insight, index) => (
            <Card
              key={index}
              onClick={() => openIframeModal(insight.url)}
              title={insight.title}
              body={insight.body}
              id={insight.id}
              url={insight.url}
              icon={insight.icon}
              currentUser={currentUser}
            />
          ))}
        </Box>
      </Box>
      <Box sx={{ height: '30px' }} />
    </>
  );
}

export default Insights;
