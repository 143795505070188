import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  FormControl,
  OutlinedInput,
  Menu,
  MenuItem,
  Chip,
  InputAdornment,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { styled } from '@mui/material/styles';

const CustomOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  '& .MuiOutlinedInput-input': {
    height: '30px',
    padding: '7px 5px 7px 20px',
  },
  '& .MuiOutlinedInput-root': {
    alignItems: 'center',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: '15px',
    border: 'solid 1px #E6EDFF',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'inherit',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.primary.main,
  },
  '& .MuiInputAdornment-root': {
    height: '100%',
    alignItems: 'center',
  },
}));

const CustomAutocomplete = ({
  items,
  selectedItemsProp,
  placeholder,
  onItemsChange,
  onSearch,
  labelField = 'label',
  valueField = 'id',
  scrollToInput,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [filteredItems, setFilteredItems] = useState(items || []);
  const [selectedItems, setSelectedItems] = useState([]);
  const open = Boolean(anchorEl);

  useEffect(() => {
    setSelectedItems(selectedItemsProp);
  }, [selectedItemsProp]);

  useEffect(() => {
    setFilteredItems(items);
  }, [items]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    debounceFilter(value);
  };

  const debounceFilter = useCallback(
    (value) => {
      setTimeout(() => {
        const filtered = items.filter((item) =>
          item[labelField].toLowerCase().includes(value.toLowerCase())
        );
        setFilteredItems(filtered);
        if (value)
          setAnchorEl(document.getElementById(`search-input-${placeholder}`));
      }, 1000);
    },
    [items, labelField]
  );

  const handleMenuItemClick = (option) => {
    const updatedItems = selectedItems.includes(option)
      ? selectedItems.filter((item) => item[valueField] !== option[valueField])
      : [...selectedItems, option];
    setSelectedItems(updatedItems);
    onItemsChange(updatedItems);
    setInputValue('');
  };

  const handleClick = () => {
    if (!anchorEl)
      setAnchorEl(document.getElementById(`search-input-${placeholder}`));
  };

  const handleClose = () => {
    setAnchorEl(null);
    onSearch(selectedItems);
    setFilteredItems(items);
  };

  const handleDeleteItems = () => {
    setSelectedItems([]);
    setInputValue('');
    onItemsChange([]);
    onSearch([]);
  };

  return (
    <Box onClick={scrollToInput}>
      <FormControl
        sx={{ width: isMobile ? '100%' : '250px', marginTop: '10px' }}
        variant="outlined"
      >
        <CustomOutlinedInput
          id={`search-input-${placeholder}`}
          value={inputValue}
          onChange={handleInputChange}
          onClick={handleClick}
          startAdornment={
            selectedItems.length > 0 && (
              <Chip
                label={`(${selectedItems.length}) ${placeholder} selected`}
                onDelete={handleDeleteItems}
                sx={{
                  marginRight: '-5px',
                  padding: '0',
                  backgroundColor:
                    placeholder == 'Skills' ? '#F5F7FA' : '#D7F1FD',
                }}
              />
            )
          }
          endAdornment={
            <InputAdornment position="end">
              {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </InputAdornment>
          }
          placeholder={`Filter ${placeholder}`}
        />
        <Menu
          id={`${placeholder}-menu`}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: { maxHeight: 300, width: isMobile ? '100%' : '250px' },
          }}
        >
          {filteredItems.length > 0 ? (
            filteredItems.map((item) => (
              <MenuItem
                key={item[valueField]}
                onClick={() => handleMenuItemClick(item)}
                selected={selectedItems.some(
                  (selected) => selected[valueField] === item[valueField]
                )}
              >
                {item[labelField]}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>No options</MenuItem>
          )}
        </Menu>
      </FormControl>
    </Box>
  );
};

export default CustomAutocomplete;
