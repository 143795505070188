import React from 'react';
import Box from '@mui/material/Box';
import buildBoardIcon from './buildBoardIcon.svg';
import AddIcon from '@mui/icons-material/Add';

const useStyles = () => ({
  splitContainer: {
    position: 'relative',
    display: 'flex',
    backgroundColor: '#F5F7FA',
    alignItems: 'center',
    width: '100%',
    height: '76px',
    borderRadius: '12px',
    marginTop: '18px',
    padding: '10px',
    cursor: 'pointer',
  },
  splitImage: {
    width: '42px',
    height: '42px',
    marginRight: '16px',
    marginLeft: '8px',
    borderRadius: '8px',
  },
  splitCompanyName: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0.25px',
    color: '#97A3B7',
  },
  circle: {
    position: 'absolute',
    borderRadius: '999px',
    left: '28.5px',
    backgroundColor: '#3171F6',
    height: '20px',
    width: '20px',
  },
  icon: {
    position: 'absolute',
    left: '30px',
    color: '#C7D0DE',
    fontSize: 'large',
  },
});

const NoBoardCard = ({ onClick }) => {
  const classes = useStyles();
  return (
    <Box id="buildBoardCard" sx={classes.splitContainer} onClick={onClick}>
      <img src={buildBoardIcon} style={classes.splitImage} />
      <div style={classes.circle} />
      <AddIcon style={classes.icon} />
      <div style={classes.splitCompanyName}>
        Build your own
        <br />
        advisory board
      </div>
    </Box>
  );
};

export default NoBoardCard;
