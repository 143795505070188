import {
  getLinkedinAccessToken,
  setLinkedinComment,
} from '../advisor_services';

const authBaseUrl = process.env.REACT_APP_LINKEDIN_AUTH_URL;
const clientId = process.env.REACT_APP_LINKEDIN_CLIENTID;
const redirectUri = `${window.location.origin}/dashboard`;
const responseType = 'code';
const state = Math.random(); // WARNING: using weak random value for testing ONLY
const scope = 'r_liteprofile r_emailaddress w_member_social';
let childWindow = null;

export const localstorageKey = 'linkedin_token';

export async function authorize() {
  try {
    const authUrl = `${authBaseUrl}?response_type=${responseType}&client_id=${clientId}&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&state=${state}&scope=${encodeURIComponent(scope)}`;
    window.location.assign(authUrl);
  } catch (error) {
    console.error('Error logging out from linkedin application', error);
  }
}

export async function authorizeWithPopUp() {
  try {
    const authUrl = `${authBaseUrl}?response_type=${responseType}&client_id=${clientId}&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&state=${state}&scope=${encodeURIComponent(scope)}`;
    const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
width=600,height=600,left=100,top=100`;

    childWindow = window.open(authUrl, 'Linkedin LogIn', params);
  } catch (error) {
    console.error('Error logging out from linkedin application', error);
  }
}

export async function closeChild() {
  try {
    childWindow.close();
    childWindow = null;
  } catch (error) {
    console.error('Error', error);
  }
}

export async function getAccessToken(req) {
  try {
    const response = await getLinkedinAccessToken({
      authorizeCode: req.code,
      redirectUri,
    });
    if (response.data && response.data.getLinkedinAccessToken) {
      const expiresIn = response.data.getLinkedinAccessToken.expire;
      const accessToken = response.data.getLinkedinAccessToken.token;
      const tokenJson = `{"accessToken":"${accessToken}","expiresIn":"${expiresIn}"}`;
      localStorage.setItem(localstorageKey, tokenJson);
    }
    return response;
  } catch (error) {
    console.error('Error logging in linkedin', error);
    return error;
  }
}

export async function postShare(title, text, shareUrl, shareThumbnailUrl, companyId) {
  const accessJson = JSON.parse(localStorage.getItem(localstorageKey));
  
  const response = await setLinkedinComment({
    accessToken: accessJson.accessToken,
    title,
    text,
    shareUrl,
    shareThumbnailUrl,
    companyId,
  });
  return response.data.setLinkedinComment;
}

export function isLinkedinAuthenticated() {
  const accessJson = JSON.parse(localStorage.getItem(localstorageKey));
  const now = new Date().getTime();
  return accessJson && accessJson.accessToken && accessJson.expiresIn > now;
}
