import React, { useEffect, useState, useLayoutEffect } from 'react';
import { Redirect, Switch, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { ACTheme } from './themes';
import ScrollToTop from './services/ScrollTop';
import { useAccount } from './contexts/Account';
import initi18n from './services/Translation';
import Route from './components/ProtectedRoute';
import PasswordReset from './scenes/PasswordReset';
import Login from './scenes/Login/index';
import AdvisorAccount from './scenes/AdvisorAccount';
import AdvisorConfirmation from './scenes/AdvisorConfirmation/index';
import AdvisorTermsOfService from './scenes/AdvisorTermsOfService/index';
import CompanyConfirmation from './scenes/CompanyConfirmation/index';
import CompanyOnboardingStep1 from './scenes/CompanyOnboarding/step1';
import AdvisorOnboardingStep2 from './scenes/AdvisorOnboarding/step2';
import AdvisorOnboardingStep4 from './scenes/AdvisorOnboarding/step4';
import CompanyOnboardingStep2 from './scenes/CompanyOnboarding/step2';
import AdvisorOnboardingStep3 from './scenes/AdvisorOnboarding/step3';
import AdvisorOnboardingStep5 from './scenes/AdvisorOnboarding/Step5';
import AdvisorOnboardingStep6 from './scenes/AdvisorOnboarding/Step6';
import CompanyTermsOfService from './scenes/CompanyTermsOfService';
import CompanyOnboardingCall from './scenes/CompanyOnboarding/call';

import CompanyOnboardingFreemiumStep1 from './scenes/CompanyOnboardingFreemium/step1';
import CompanyOnboardingFreemiumStep2 from './scenes/CompanyOnboardingFreemium/step2';
import CompanyOnboardingFreemiumStep3 from './scenes/CompanyOnboardingFreemium/step3';
import CompanyOnboardingFreemiumStep4 from './scenes/CompanyOnboardingFreemium/step4';
import CompanyOnboardingFreemiumStep5 from './scenes/CompanyOnboardingFreemium/step5';

import CompanyOnboardingProfileUpgrade from './scenes/CompanyOnboardingFreemium/profileUpgrade';
import Conversation from './components/Conversation';
import PasswordSet from './scenes/PasswordSet';
import WelcomeCompanyMember from './scenes/WelcomeCompanyMember';
import BaseLayout from './layouts/Base';
import BasePublicLayout from './layouts/BasePublic';
import AdvisorLayout from './layouts/Advisor';
import CompanyLayout from './layouts/Company';
import Layout from './layouts/Layout';
import AdvisorPublicSearch from './scenes/AdvisorPublicSearch';
import AdvisorPublicJoin from './scenes/AdvisorPublicJoin';
import FrontPage from './scenes/PublicDirectory/FrontPage';
import Logout from './scenes/Logout';
import FreeTrialBanner from './components/FreeTrialBanner';
import ConditionalComponent from './components/ConditionalComponent';
import { AccountType, SnackbarType } from './services/utils/types';
import TokensIFrame from './components/TokensIFrame';
import AdvisorDrawer from '././components/Advisor/Drawer';
import CompanyOpportunityDrawer from '././components/Company/OpportunityDrawer';
import AdvisorFreeTrialModal from '././components/Advisor/FreeTrialModal';
import CompanyFreeTrialModal from '././components/Company/FreeTrialModal';
import CompanyFreemiumModal from '././components/Company/FreemiumModal';
import NewConversationPopUp from '././components/Conversation/NewPopUp';
import ConversationDrawer from '././components/Conversation/Drawer';
import AdvisorFeaturedUpgradeModal from '././components/Advisor/FeatureUpgradeModal';
import PublicContactRegister from './scenes/PublicContactRegister';
import AdvisorFreemiumModal from '././components/Advisor/FreemiumModal';
import FreemiumAdvisorBanner from '././components/Advisor/FreemiumAdvisorBanner';
import ACSnackbar from './components/ACSnackbar';
import LoadingIndicatorLogo from './components/LoadingIndicatorLogo';
import MinimumDisplayTime from './components/EndpointCaller/minimumDisplayTime';
import LoadingIcon from './scenes/LoadingIcon';
import { planCodeSubscription } from './services/subscriptions';
import PasswordVerify from './scenes/PasswordVerify';
import AOBF from './scenes/AOBF';

function Router() {
  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  const [url, setUrl] = useState(window.location.href);
  const [isLogin, setIsLogin] = useState(false);
  const {
    authenticate,
    type,
    isImpersonated,
    impersonate,
    isFreeTrial,
    currentUserInfo,
    initializeTags,
    isFreemium,
    updateRecurlyPlanCode,
    accountDetails,
  } = useAccount();
  const [visibleDisplayTime, setIsVisibileDisplayTime] = useState(false);
  const [isAppDataReady, setIsAppDataReady] = useState(false);
  const [complete, setComplete] = useState(false);
  const [snackbarSuccess, setSnackbarSuccess] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const location = useLocation();

  useEffect(() => {
    if (url.includes('/login')) {
      setIsLogin(true);
      setUrl('');
    }
  }, [url]);

  useEffect(() => {
    if (!accountDetails?.id) {
      return;
    }

    const subscription = planCodeSubscription({
      id: accountDetails.id,
      onUpdate: (updatedAdvisor) => {
        if (accountDetails?.id === updatedAdvisor.id) {
          updateRecurlyPlanCode(updatedAdvisor);
          window.location.reload();
        }
      },
      onError: (error) => {
        console.error('Subscription error:', error);
      },
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [accountDetails?.id, updateRecurlyPlanCode]);

  async function initializeAppData() {
    if (params.has('experience')) {
      await authenticate(() => {}, params.get('experience'));
    } else {
      await authenticate();
    }
    await initi18n();
    await initializeTags();
    setIsAppDataReady(true);
  }

  async function impersonateUser(userId, usertype) {
    await impersonate(userId, usertype);
    await initi18n();
    setIsAppDataReady(true);
  }

  const handleUpgradeSuccess = (message) => {
    setSnackMessage(message);
    setSnackbarSuccess(true);
    setTimeout(() => {
      window.location.reload();
    }, 5000);
  };

  useLayoutEffect(() => {
    if (window.location.href.indexOf('impersonate') > -1) {
      const impersonateUserId = params.get('impersonate');
      const impersonateUserType = params.get('userType');
      if (params.get('getCredentials') === 'true') {
        localStorage.setItem(
          'impersonationUrl',
          `/sharedCredentials?impersonate=${impersonateUserId}&userType=${impersonateUserType}&getCredentials=false`
        );
        window.location.assign(
          `/sharedCredentials?impersonate=${impersonateUserId}&userType=${impersonateUserType}&getCredentials=false`
        );
      }
      if (!isImpersonated) {
        impersonateUser(impersonateUserId, impersonateUserType);
      }
    } else if (localStorage.getItem('impersonationUrl')) {
      if (!url.includes('/profile/')) {
        let impersonationUrl = localStorage.getItem('impersonationUrl');
        window.location.assign(impersonationUrl);
        const impersonateUserId = params.get('impersonate');
        const impersonateUserType = params.get('userType');
        if (!isImpersonated) {
          impersonateUser(impersonateUserId, impersonateUserType);
        }
      } else {
        initializeAppData();
      }
    } else {
      initializeAppData();
    }
  }, []);

  useEffect(() => {
    if (!visibleDisplayTime && isAppDataReady) {
      setComplete(true);
    }
  }, [visibleDisplayTime, isAppDataReady]);

  if (!complete) {
    return <LoadingIcon />;
  }

  function setMetadataInfo(content) {
    document
      .querySelector('meta[name="description"]')
      .setAttribute('content', content);
  }

  if (url.includes('/profile/')) {
    setMetadataInfo('View the advisor profile on AdvisoryCloud');
  } else {
    setMetadataInfo(
      'AdvisoryCloud is the leading marketplace for advisors. Create a profile on AdvisoryCloud to make yourself available for advisory work, special projects, and formal board positions.'
    );
  }

  return (
    <ScrollToTop>
      <ThemeProvider theme={ACTheme}>
        <AdvisorDrawer
          publicProfileView={location.pathname.includes('/use-advisors')}
        />
        <NewConversationPopUp />
        {type === AccountType.ADVISOR && currentUserInfo && isFreemium ? (
          <>
            <AdvisorFreemiumModal onUpgradeSuccess={handleUpgradeSuccess} />
            <ACSnackbar
              style={{ marginTop: isFreemium ? '50px' : '0px' }}
              open={snackbarSuccess}
              text={snackMessage ? snackMessage : ''}
              severity={SnackbarType.SUCCESS}
              onClose={() => {
                setSnackbarSuccess(false);
              }}
              autoHideDuration={5000}
            />
          </>
        ) : null}
        {type === AccountType.ADVISOR && currentUserInfo && !isFreemium ? (
          <AdvisorFreeTrialModal />
        ) : null}
        {type === AccountType.ADVISOR && currentUserInfo ? (
          <AdvisorFeaturedUpgradeModal />
        ) : null}
        <CompanyOpportunityDrawer />
        {type === AccountType.COMPANY && currentUserInfo && !isFreemium ? (
          <CompanyFreeTrialModal />
        ) : null}
        {type === AccountType.COMPANY &&
        currentUserInfo &&
        isFreeTrial &&
        isFreemium ? (
          <CompanyFreemiumModal />
        ) : null}
        {type === AccountType.ADVISOR ? <ConversationDrawer /> : null}
      </ThemeProvider>
      <Switch>
        <Route isPublic exact path="/login" component={Login} />
        <Route
          isPublic
          exact
          path="/use-advisors/search"
          component={AdvisorPublicSearch}
        />
        <Route
          isPublic
          exact
          path="/use-advisors/search"
          component={AdvisorPublicSearch}
        />
        <Route
          isPublic
          exact
          path="/join/advisors/:companySlug/:companyId"
          component={AdvisorPublicJoin}
        />
        <Route
          isPublic
          exact
          path="/join/team/:companySlug/:id"
          component={PublicContactRegister}
        />
        <Route
          isPublic
          exact
          path="/use-advisors/browse/expertise/:tag"
          component={AdvisorPublicSearch}
        />
        <Route
          isPublic
          exact
          path="/sharedCredentials"
          component={TokensIFrame}
        />
        <Route isPublic path="/password/set" component={PasswordSet} />
        <Route isPublic path="/password/verify" component={PasswordVerify} />
        <Route isPublic path="/password/reset" component={PasswordReset} />
        <Route
          exact
          isOnboarding
          path="/company-member/onboarding/profile"
          component={CompanyOnboardingStep2}
        />
        <Route
          isPublic
          path="/company-member/welcome"
          component={WelcomeCompanyMember}
        />
        <Route
          isPublic
          path="/advisor/confirmation"
          component={AdvisorConfirmation}
        />
        <Route
          isPublic
          path="/company/confirmation"
          component={CompanyConfirmation}
        />
        <Route
          isPublic
          path="/advisor/confirmation"
          component={CompanyConfirmation}
        />
        <Route
          exact
          isPublic
          path="/profile"
          component={() => <BasePublicLayout />}
        />
        <Route
          exact
          isPublic
          path="/use-advisors"
          component={() => <FrontPage />}
        />
        <Route
          exact
          isPublic
          path="/use-advisors/browse"
          component={() => <FrontPage />}
        />
        <Route exact isPublic path="/logout" component={Logout} />
        <Route
          exact
          isTermsOfServiceScene
          isOnboarding
          path="/company/onboarding/terms-of-service"
          component={CompanyTermsOfService}
        />
        <Route
          exact
          isTermsOfServiceScene
          isOnboarding
          path="/advisor/onboarding/terms-of-service"
          component={AdvisorTermsOfService}
        />
        <Route
          exact
          isOnboarding
          path="/company/onboarding/step2"
          component={CompanyOnboardingStep2}
        />
        <Route
          exact
          isOnboarding
          path="/company/onboarding/step1"
          component={CompanyOnboardingStep1}
        />
        <Route
          exact
          isOnboarding
          path="/company/new/step1"
          component={CompanyOnboardingFreemiumStep1}
        />
        <Route
          exact
          isOnboarding
          path="/company/new/step2"
          component={CompanyOnboardingFreemiumStep2}
        />
        <Route
          exact
          isOnboarding
          path="/company/new/step3"
          component={CompanyOnboardingFreemiumStep3}
        />
        <Route
          exact
          isOnboarding
          path="/company/new/step4"
          component={CompanyOnboardingFreemiumStep4}
        />
        <Route
          exact
          isOnboarding
          path="/company/new/step5"
          component={CompanyOnboardingFreemiumStep5}
        />
        <Route
          exact
          path="/setup-step-1"
          component={CompanyOnboardingFreemiumStep1}
        />
        <Route
          exact
          isOnboarding
          path="/setup-step-2"
          component={CompanyOnboardingFreemiumStep2}
        />
        <Route
          exact
          isOnboarding
          path="/setup-step-3"
          component={CompanyOnboardingProfileUpgrade}
        />
        <Route
          exact
          path="/setup-step-4"
          component={CompanyOnboardingFreemiumStep3}
        />
        <Route
          exact
          isSetUp
          path="/setup-step-5"
          component={CompanyOnboardingFreemiumStep4}
        />
        <Route
          exact
          isOnboarding
          path="/advisor/aobf"
          component={AOBF}
        />
        {/* <Route
          exact
          isOnboarding
          path="/advisor/onboarding/step2"
          component={AdvisorOnboardingStep2}
        />
        <Route
          exact
          isOnboarding
          path="/advisor/onboarding/step3"
          component={AdvisorOnboardingStep3}
        />
        <Route
          exact
          isOnboarding
          path="/advisor/onboarding/step4"
          component={AdvisorOnboardingStep4}
        />
        <Route
          exact
          isOnboarding
          path="/advisor/onboarding/step5"
          component={AdvisorOnboardingStep5}
        />
        <Route
          exact
          isOnboarding
          path="/advisor/onboarding/step6"
          component={AdvisorOnboardingStep6}
        /> */}
        <Route
          exact
          isOnboarding
          isOnboardingCall
          path="/company/onboarding/call"
          component={CompanyOnboardingCall}
        />
        <Route exact path="/skeleton/loading/test" component={LoadingIcon} />
        <Route
          exact
          isOnboardingCall
          wantsToUpgrade
          path="/onboarding/call/upgrade"
          component={CompanyOnboardingCall}
        />
        <Route path="/updates" component={CompanyLayout} />
        <Route path="/update" component={CompanyLayout} />
        <Route exact path="/updates/archived" component={CompanyLayout} />
        <Route path="/insight" component={CompanyLayout} />
        <Route path="/meeting" component={CompanyLayout} />
        <Route
          exact
          path="/dashboard"
          component={() => (
            <ConditionalComponent
              conditionFunction={() => type === AccountType.COMPANY}
              ValidConditionComponent={CompanyLayout}
              InvalidConditionComponent={AdvisorLayout}
            />
          )}
        />
        <Route
          path="/v2/dashboard"
          component={Layout}
        />
        <Route
          path="/board-meetings"
          component={() => (
            <ConditionalComponent
              conditionFunction={() => type === AccountType.COMPANY}
              ValidConditionComponent={CompanyLayout}
              InvalidConditionComponent={AdvisorLayout}
            />
          )}
        />
        <Route
          path="/post/:postId?"
          component={() => (
            <ConditionalComponent
              conditionFunction={() => type === AccountType.COMPANY}
              ValidConditionComponent={CompanyLayout}
              InvalidConditionComponent={AdvisorLayout}
            />
          )}
        />

        <Route
          isPublic
          path="/profile/:advisorSlug"
          component={() => <BasePublicLayout />}
        />
        <Route
          exact
          path="/account"
          component={() => (
            <ConditionalComponent
              conditionFunction={() => type === AccountType.COMPANY}
              ValidConditionComponent={() => <CompanyLayout />}
              InvalidConditionComponent={() => (
                <>
                  {isFreeTrial && !isFreemium && (
                    <FreeTrialBanner show={isFreeTrial} />
                  )}
                  {isFreemium && <FreemiumAdvisorBanner />}

                  <BaseLayout>
                    <AdvisorAccount />
                  </BaseLayout>
                </>
              )}
            />
          )}
        />
        <Route path="/questions" component={CompanyLayout} />
        <Route
          exact
          path="/insights"
          component={() => (
            <ConditionalComponent
              conditionFunction={() => type === AccountType.COMPANY}
              ValidConditionComponent={() => {
                return <CompanyLayout />;
              }}
              InvalidConditionComponent={() => {
                return <AdvisorLayout />;
              }}
            />
          )}
        />
        <Route path="/question" component={CompanyLayout} />
        <Route exact path="/questions/archived" component={CompanyLayout} />
        <Route exact path="/breakthroughs" component={CompanyLayout} />
        <Route exact path="/networking" component={AdvisorLayout} />
        <Route exact path="/advisors" component={CompanyLayout} />
        <Route exact path="/advisors/search" component={CompanyLayout} />
        <Route exact path="/advisors/saved" component={CompanyLayout} />
        <Route exact path="/advisors/top_matches" component={CompanyLayout} />
        <Route path="/advisors/:publicProfileUrl" component={CompanyLayout} />
        <Route path="/board-directory" component={AdvisorLayout} />
        {type === AccountType.COMPANY ? (
          <Route path="/messages" component={CompanyLayout} />
        ) : (
          <Route path="/messages" component={Conversation} />
        )}

        <Route path="/upgrade/b2b_general" component={CompanyLayout} />

        <Route
          path="/upgrade/b2b_managed_advisory_board"
          component={CompanyLayout}
        />
        <Route path="/upgrade/b2b_ft" component={CompanyLayout} />

        <Route path="/upgrade/b2b_ac_plus" component={CompanyLayout} />

        <Route path="/:companySlug/boardroom" component={AdvisorLayout} />
        <Route path="/:companySlug/updates" component={AdvisorLayout} />
        <Route path="/:companySlug/update" component={AdvisorLayout} />
        <Route path="/:companySlug/questions" component={AdvisorLayout} />
        <Route path="/:companySlug/question" component={AdvisorLayout} />
        <Route path="/:companySlug/board-meetings" component={AdvisorLayout} />
        <Route
          isNotFreeTrialScene
          path="/:companySlug/my-answers"
          component={AdvisorLayout}
        />
        <Route path="/:companySlug/benefits" component={AdvisorLayout} />
        <Route path="/advisor/opportunities" component={AdvisorLayout} />
        <Route
          isNotFreeTrialScene
          path="/advisor/boardroom/:id"
          component={AdvisorLayout}
        />
        <Route path="/:companySlug/post/:postId?" component={AdvisorLayout} />
        <Redirect from="/" to="/login" />
      </Switch>
    </ScrollToTop>
  );
}

export default Router;
