import React from 'react';
import { IconButton } from '@mui/material';
import CustomIcon from './../../../icons/customIcon';

const useStyles = () => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconButton: {
    margin: '0 16px !important',
  },
});

const ActionButtons = (props) => {
  const { companyBoardData } = props;
  const classes = useStyles();

  function openProfile(event) {
    console.log(event);
  }
  function sendToWebsite(event) {
    event.stopPropagation();
    window.open(companyBoardData.website);
  }

  if (!companyBoardData.id) return null;
  return (
    <div style={classes.root}>
      <IconButton sx={classes.iconButton} onClick={openProfile}>
        <CustomIcon
          iconname="join_page"
          style={{
            color: '#232B35',
            fontSize: 16,
            height: 16,
            width: 16,
          }}
        />
      </IconButton>
      {companyBoardData && companyBoardData.website && (
        <IconButton sx={classes.iconButton} onClick={sendToWebsite}>
          <CustomIcon
            iconname="company_website"
            style={{
              color: '#232B35',
              fontSize: 16,
              height: 16,
              width: 16,
            }}
          />
        </IconButton>
      )}
    </div>
  );
};

export default ActionButtons;
