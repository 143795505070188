import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import { Paper, useMediaQuery, Typography, Link } from '@mui/material';
import { PriorityHighOutlined } from '@mui/icons-material';
import IconUnderlay from '../../components/IconUnderlay';
import ACSnackbar from '../../components/ACSnackbar';
import TermsOfService from '../../components/TermsOfService';
import AdvisoryCloudLogo from '../../components/AdvisoryCloudLogo';
import { useAccount } from '../../contexts/Account';
import {
  acceptAdvisorTermsOfService,
  isTermsOfService,
  completeOnboarding,
} from '../../services/advisor_services';
import { getRecurlyUrl } from '../../services/Authentication';

const useStyles = ({ isSM, hasRecurlyError }) => ({
  container: {
    display: 'flex',
    width: '100%',
    paddingTop: '40px',
    alignItems: 'center',
    flexDirection: 'column',
    paddingBottom: isSM ? '0px' : '20px',
    height: isSM ? 'auto' : '100vh',
    overflow: isSM ? 'scroll' : 'hidden',
  },
  termsCard: {
    width: isSM ? '100%' : '60%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 'auto',
    padding: '20px',
    maxHeight: hasRecurlyError ? '300px' : '505px',
    marginTop: '30px',
  },
  recurlyErrorContainer: {
    display: 'flex',
    flex: 'auto',
    alignItems: 'center',
    paddingTop: '20px',
    flexDirection: 'column',
  },
  priorityIcon: {
    fontSize: '60px',
  },
  titleLabel: {
    marginTop: '50px',
    color: 'palette.background',
  },
  continueLink: {
    marginTop: '20px',
  },
});

function AdvisorTermsOfService() {
  const [loading, setLoading] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [hasRecurlyError, setHasRecurlyError] = useState(false);
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const classes = useStyles({ isSM, hasRecurlyError });
  const { accountDetails, acceptAdvisorTOS } = useAccount();
  const [url, setUrl] = useState();
  const [asyncStatus, setAsyncStatus] = useState(false);
  const history = useHistory();

  async function setTermsOfService() {
    try {
      await acceptAdvisorTermsOfService({
        USER_ID: accountDetails.id,
        TERMS: isTermsOfService.ACCEPTED,
      });
      await completeOnboarding({ id: accountDetails.id });
      acceptAdvisorTOS();
    } catch (err) {
      throw new Error();
    }
  }

  async function setRecurlyUrl() {
    try {
      const recurly = await getRecurlyUrl(accountDetails.recurlyPlanCode);
      setUrl(recurly.data.getRecurlyPlan.onboardingRedirectUrl);
    } catch (err) {
      throw new Error();
    }
  }

  function handleWindowLocation() {
    if (document.referrer) {
      window.location.replace(document.referrer);
    }
    if (url) {
      window.location.replace(
        `${url}?iwm_advisor_id=${accountDetails.iwmId}&sosfcontactid=${accountDetails.salesforceContactId}&email=${accountDetails.email}&plancode=${accountDetails.recurlyPlanCode}`
      );
    }
  }

  React.useEffect(() => {
    if (asyncStatus) {
      handleWindowLocation();
    }
  }, [asyncStatus]);

  function handleContinueClick() {
    Promise.all([setLoading(true), setTermsOfService(), setRecurlyUrl()])
      .then(() => {
        setLoading(false);
        setAsyncStatus(true);
      })
      .catch(() => {
        setLoading(false);
        setShowErrorSnackbar(true);
        setHasRecurlyError(true);
      });
  }

  function renderCardContent() {
    if (hasRecurlyError) {
      return (
        <div style={classes.recurlyErrorContainer}>
          <IconUnderlay size={80}>
            <PriorityHighOutlined color="error" sx={classes.priorityIcon} />
          </IconUnderlay>

          <Typography sx={classes.titleLabel} variant="h2">
            {t('RECURLY-URL-ERROR-TITLE')}
          </Typography>

          <Link href="/login">
            <Typography sx={classes.continueLink} variant="h2">
              {t('RECURLY-URL-CONTINUE-LINK')}
            </Typography>
          </Link>
        </div>
      );
    }

    return (
      <TermsOfService loading={loading} onContinueClick={handleContinueClick} />
    );
  }

  return (
    <>
      <Helmet>
        <title>
          {t(
            hasRecurlyError
              ? 'ADVISOR-ERROR-TERMS-OF-SERVICE-HELMET-TITLE'
              : 'ADVISOR-TERMS-OF-SERVICE-HELMET-TITLE'
          )}
        </title>
      </Helmet>
      <ACSnackbar
        open={showErrorSnackbar}
        text={t('ERROR-TERMS-OF-SERVICE')}
        severity="error"
        onClose={() => setShowErrorSnackbar(false)}
        autoHideDuration={6000}
      />

      <div style={classes.container}>
        <AdvisoryCloudLogo
          removeHyperlink
          onClick={() => history.push('/dashboard')}
        />

        <Paper sx={classes.termsCard}>{renderCardContent()}</Paper>
      </div>
    </>
  );
}

export default AdvisorTermsOfService;
