import React from 'react';
import { Paper, Typography, Grid } from '@mui/material';
import BoardCard from '../../advisorComponents/boardCard';

const boardsData = [
  {
    name: 'Earthbound Automation',
    description:
      'Optimizing Industrial Control Systems: Earthbound Automation Boosts Efficiency and Output for Manufacturing.',
    memberSince: 2024,
  },
  {
    name: 'Plero',
    description:
      'Empowering nonprofits with sustainable funding: Plero revolutionizes financial stability.',
    memberSince: 2022,
  },
  {
    name: 'MARFI Systems, Inc.',
    description:
      'Empowering SMBs with scalable, enterprise-level IT and cybersecurity solutions to boost efficiency.',
    memberSince: 2023,
  },
  {
    name: 'Bloom',
    description:
      'Revolutionizing personal wellness with an all-in-one health app that generates smart insights.',
    memberSince: 2021,
  },
  {
    name: 'Vitelis',
    description:
      'Transforming industries by activating data to drive unprecedented performance and growth.',
    memberSince: 2019,
  },
  {
    name: 'Skyriver IT',
    description:
      'Freeing entrepreneurs from IT frustrations with exceptional customer service and cutting-edge solutions.',
    memberSince: 2018,
  },
  {
    name: 'CSCONNECT',
    description:
      'Revolutionizing Dining: AI-Powered AR Menus Boost Customer Engagement and Drive Results.',
    memberSince: 2018,
  },
  {
    name: 'HireList.AI',
    description:
      'An AI-powered talent acquisition platform designed to revolutionize the recruitment and hiring process.',
    memberSince: 2018,
  },
];

const MyBoards = () => (
  <Paper
    elevation={3}
    sx={{
      padding: '20px',
      borderRadius: '16px',
      backgroundColor: '#fff',
    }}
  >
    {/* Título */}
    <Typography
      sx={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '16px' }}
    >
      My Boards
    </Typography>

    {/* Contenedor de Tableros */}
    <Grid container spacing={3}>
      {boardsData.map((board, index) => (
        <BoardCard key={index} board={board} />
      ))}
    </Grid>
  </Paper>
);

export default MyBoards;
