import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

export default async () => {
  const I18N_CONFIG = {
    react: {
      useSuspense: false,
    },
    fallbackLng: {
      en: ['en'],
      es: ['es'],
      default: ['en'],
    },
    lng: 'en',
    ns: 'translation',
    supportedLngs: ['en', 'es'],
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      allowMultiLoading: true,
      requestOptions: {
        mode: 'cors',
        credentials: 'same-origin',
        cache: 'default',
      },
      reloadInterval: false,
      crossDomain: false,
    },
    detection: {
      order: ['queryString', 'localStorage'],
    },
    debug: process.env.NODE_ENV === 'development',
  };

  if (process.env.NODE_ENV !== 'test') {
    await i18n
      .use(LanguageDetector)
      .use(Backend)
      .use(initReactI18next)
      .init(I18N_CONFIG);
  }
};
