import React from 'react';
import { Box, Typography } from '@mui/material';
import Dashboard from './dashboard.png';
import Women from './women.png';
import Men from './man.png';
import avatar from './logo.png';
import CustomIcon from './../../../icons/customIcon';
import eventBus, { eventBusValues } from './../../../eventBus';
import { useAccount } from './../../../contexts/Account';

function DashboardEmptyState() {
  const { accountDetails } = useAccount();

  const openDialog = () => {
    eventBus.dispatch(eventBusValues.openCreatePost, 'update');
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#FBFCFD',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Box sx={{ position: 'relative', marginTop: '50px' }}>
        <CustomIcon
          iconname="post"
          style={{
            width: '50px',
            height: '50px',
            fontSize: '37px',
            position: 'absolute',
            top: -8,
            right: 10,
          }}
        />
        <Box
          sx={{
            backgroundColor: '#D7F1FD',
            width: '49px',
            height: '44px',
            borderRadius: '100px',
          }}
        />
      </Box>

      <Typography
        sx={{
          fontSize: '26px',
          fontWeight: 700,
          color: '#232B35',
          marginTop: '5px',
          textAlign: 'center',
        }}
      >
        Make your first post
      </Typography>
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 500,
          color: '#232B35',
          marginTop: '10px',
          textAlign: 'center',
        }}
      >
        Make your first post to your{' '}
        {accountDetails.userSegment === 'managed_advisory_board'
          ? ' advisory board'
          : ' AdvisoryCloud'}
      </Typography>
      <button
        onClick={openDialog}
        style={{
          marginBottom: '20px',
          fontSize: '16px',
          borderRadius: '999px',
          fontWeight: 700,
          width: '282px',
          height: '48px',
          backgroundColor: '#3171F6',
          color: '#FFFFFF',
          borderColor: 'none',
          fontFamily: 'Poppins',
          border: 'none',
          outline: 'none',
          cursor: 'pointer',
          padding: '10px',
          marginTop: '20px',
        }}
        variant="contained"
        id="makeYourFirstPost"
      >
        <CustomIcon
          iconname="post"
          style={{
            width: '20px',
            height: '20px',
            fontSize: '16px',
            marginRight: '5px',
          }}
        />
        Start with a welcome post
      </button>
      <Box
        id="DashboardEmptyState"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#FFFFFF',
          borderRadius: '10px',
          border: '1px solid #F0F1F3',
          alignItems: 'center',
          height: '100%',
          width: '100%',
          maxWidth: '799px',
          padding: '20px 20px 0px 20px',
          marginTop: '50px',
          marginBottom: '50px',
          boxShadow: '0px 0px 40px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            maxWidth: '720px',
            marginBottom: '40px',
          }}
        >
          <Box
            id="logoDashboardEmptyState"
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '70px',
                height: '70px',
                backgroundColor: '#FAF1EA',
                borderRadius: '999px',
                marginRight: '10px',
              }}
            >
              <img
                src={avatar}
                alt="avatar"
                style={{ width: '65px', height: '18px' }}
              />
            </Box>
            <Typography
              sx={{ fontSize: '16px', fontWeight: 600, color: '#232B35' }}
            >
              FlexiFlow
            </Typography>
          </Box>
          <Box
            id="postDashboardEmptyState"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '97px',
                height: '32px',
                backgroundColor: '#D7F1FD',
                borderRadius: '999px',
                marginRight: '10px',
              }}
            >
              <CustomIcon
                iconname="post"
                style={{
                  width: '18px',
                  height: '19px',
                  fontSize: '15px',
                  marginRight: '5px',
                  marginTop: '-5px',
                }}
              />
              <Typography
                sx={{ fontSize: '14px', fontWeight: 600, color: '#232B35' }}
              >
                Post
              </Typography>
            </Box>
            <Typography
              sx={{ fontSize: '16px', fontWeight: 600, color: '#232B35' }}
            >
              2d
            </Typography>
          </Box>
        </Box>
        <Typography
          id="textDashboardEmptyState"
          sx={{
            fontSize: '18px',
            fontWeight: 500,
            color: '#232B35',
            width: '100%',
            maxWidth: '713px',
            marginBottom: '30px',
          }}
        >
          👋 Hi team - I've added some screenshots of our landing page below and
          a link to our strategy document - we'd love your input on the
          following questions...
        </Typography>
        <img
          id="imgDashboardEmptyState"
          src={Dashboard}
          alt="dashboard"
          style={{
            display: 'flex',
            maxWidth: '725px',
            maxHeight: '392px',
            width: '100%',
            height: '100%',
            marginBottom: '50px',
          }}
        />
        <Box
          id="commentDashboardEmptyState-1"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#F0F4F8',
            borderRadius: '10px',
            height: '100%',
            maxHeight: { xxs: '100%', sm: '190px' },
            width: '100%',
            maxWidth: '728px',
            padding: '20px',
            marginBottom: '30px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '15px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={Women}
                alt="women"
                style={{
                  width: '60px',
                  height: '60px',
                  borderRadius: '99px',
                  marginRight: '10px',
                  objectFit: 'cover',
                }}
              />
              <Box>
                <Typography
                  sx={{ fontSize: '16px', fontWeight: 500, color: '#232B35' }}
                >
                  Lisa Roberts
                </Typography>
                <Typography
                  sx={{ fontSize: '14px', fontWeight: 300, color: '#1C2F49' }}
                >
                  VP of Marketing
                </Typography>
              </Box>
            </Box>
            <Typography
              sx={{ fontSize: '16px', fontWeight: 600, color: '#1C2F49' }}
            >
              1hr
            </Typography>
          </Box>
          <Typography
            sx={{ fontSize: '16px', fontWeight: 500, color: '#232B35' }}
          >
            The landing page is a great start - I would shift the copy to be
            more benefit driven for the user. Highlight the pain points more. I
            would also add additional social proof above the fold that speaks to
            the pain point resolution.
          </Typography>
        </Box>
        <Box
          id="commentDashboardEmptyState-2"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#F0F4F8',
            borderRadius: '10px',
            height: '100%',
            maxHeight: { xxs: '100%', sm: '190px' },
            width: '100%',
            maxWidth: '728px',
            padding: '20px',
            marginBottom: '30px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '15px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={Men}
                alt="men"
                style={{
                  width: '60px',
                  height: '60px',
                  borderRadius: '99px',
                  marginRight: '10px',
                }}
              />
              <Box>
                <Typography
                  sx={{ fontSize: '16px', fontWeight: 500, color: '#232B35' }}
                >
                  Calvin Williams
                </Typography>
                <Typography
                  sx={{ fontSize: '14px', fontWeight: 300, color: '#1C2F49' }}
                >
                  Chief Marketing Officer
                </Typography>
              </Box>
            </Box>
            <Typography
              sx={{ fontSize: '16px', fontWeight: 600, color: '#1C2F49' }}
            >
              2d
            </Typography>
          </Box>
          <Typography
            sx={{ fontSize: '16px', fontWeight: 500, color: '#232B35' }}
          >
            I agree with Lisa - here's a list of my answers:
          </Typography>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 500,
              color: '#232B35',
              marginLeft: '20px',
              marginTop: '20px',
            }}
          >
            1. How would you improve the click through rate?
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default DashboardEmptyState;
