const recurlyHost = process.env.REACT_APP_RECURLY_URL;

export function editBillingInfo(token) {
  const authUrl = `${recurlyHost}/account/billing_info/edit?ht=${token}`;
  window.open(authUrl, '_blank');
}

export function viewPastInvoices(token) {
  const authUrl = `${recurlyHost}/account/${token}`;
  window.open(authUrl, '_blank');
}
