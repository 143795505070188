import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Paper,
  useMediaQuery,
  ClickAwayListener,
  Typography,
} from '@mui/material';
import AdvisoryCloudLogo from '../../components/AdvisoryCloudLogo';
import CompanyChatLeftMenu from './companyLeftMenu/index';
import ConversationLeftMenuItem from './leftMenuItem';
import { useAccount } from '../../contexts/Account';
import eventBus, { eventBusValues } from '../../eventBus';
import { AccountType } from '../../services/utils/types';

const useStyles = ({ isSM, showBannerValue }) => ({
  outerContainer: {
    width: isSM ? '0px' : '20%',
    minWidth: isSM ? '100%' : '280px',
    maxWidth: isSM ? '100%' : '350px',
  },
  container: {
    width: isSM ? '100%' : '20%',
    minWidth: isSM ? '100%' : '280px',
    maxWidth: isSM ? '100%' : '350px',
    top: isSM ? '70px' : `${0 + showBannerValue}px`,
    minHeight: isSM ? 'calc(100vh - 70px)' : '100vh',
    borderRadius: '0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'fixed',
    zIndex: 2,
    backgroundColor: '#FBFCFD',
  },
  messageContainer: {
    borderTop: '1px solid #E6EDFF',
    width: isSM ? '100%' : '20%',
    minWidth: isSM ? '100%' : '280px',
    maxWidth: isSM ? '100%' : '350px',
    position: 'fixed',
    top: `${139 + showBannerValue}px`,
    bottom: '0px',
    overflowY: 'auto',
    borderRadius: '0px',
    backgroundColor: '#FBFCFD',
  },
  logoContainer: {
    position: 'absolute',
    top: '-5px',
  },
  backIcon: {
    position: 'absolute !important',
    top: '10px',
    left: '10px',
  },
  loadingContainer: {
    alignItems: 'center',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
  },
  conversationsTitle: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.75px',
    color: '#646D7A',
    padding: '15px 30px',
  },
});

function ConversationLeftMenu() {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { conversations, accountDetails, type, currentUserInfo, isFreeTrial } =
    useAccount();
  const [displayedConversations, setDisplayedConversations] =
    useState(conversations);
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const [hasParams, setHasParams] = useState(
    queryParams.get('conversationId') || queryParams.get('senderId')
  );
  const classes = useStyles({
    isSM,
    hasParams,
    showBannerValue: isFreeTrial ? 50 : 0,
  });
  const [show, setShow] = useState(!hasParams && conversations[0]);

  useEffect(() => {
    const orderConversations = (newMessage) => {
      setDisplayedConversations((oldConversations) => {
        const updatedValue = [...oldConversations];
        if (updatedValue[0].conversationId === newMessage.conversationId) {
          return updatedValue;
        }
        const index = updatedValue.findIndex(
          (conversation) =>
            conversation.conversationId === newMessage.conversationId
        );
        const item = updatedValue.splice(index, 1)[0];
        updatedValue.splice(0, 0, item);
        return updatedValue;
      });
    };

    eventBus.on(eventBusValues.onHideMenu, (data) => {
      setShow(data.show);
    });

    eventBus.on(eventBusValues.onNewConversation, () => {
      setDisplayedConversations((oldConversations) => {
        return [...oldConversations];
      });
    });

    eventBus.on(eventBusValues.onNewMessage, (data) => {
      orderConversations(data);
    });

    eventBus.on(eventBusValues.onSendNewMessage, (data) => {
      orderConversations(data);
    });

    return () => {
      eventBus.remove(eventBusValues.onNewConversation);
      eventBus.remove(eventBusValues.onHideMenu);
      eventBus.remove(eventBusValues.onNewMessage);
      eventBus.remove(eventBusValues.onSendNewMessage);
    };
  }, []);

  useEffect(() => {
    const hsubs = history.listen((locationParams) => {
      const newQueryParams = new URLSearchParams(locationParams.search);
      setHasParams(
        newQueryParams.get('conversationId') || newQueryParams.get('senderId')
      );
      setShow(
        !newQueryParams.get('conversationId') && !newQueryParams.get('senderId')
      );
    });
    return () => {
      hsubs();
    };
  }, []);

  function content() {
    return (
      <div style={classes.outerContainer}>
        <Paper sx={classes.container}>
          {!isSM && (
            <div style={classes.logoContainer}>
              <AdvisoryCloudLogo
                removeHyperlink
                onClick={() => {
                  eventBus.dispatch(eventBusValues.closeAdvisorDrawer);
                  history.push('/dashboard');
                }}
                width={isSM ? 113 : 153}
              />
            </div>
          )}
          <Paper sx={classes.messageContainer}>
            <Typography sx={classes.conversationsTitle}>
              Conversations
            </Typography>
            {displayedConversations.map((conversation) => (
              <ConversationLeftMenuItem
                key={conversation.conversationId}
                id={conversation.conversationId}
                updatedConvestions={displayedConversations}
                setShow={setShow}
              />
            ))}
            {type == AccountType.ADVISOR &&
            accountDetails.boards &&
            accountDetails.boards.memberBoards &&
            accountDetails.boards.memberBoards.length
              ? accountDetails.boards.memberBoards.map((company) => (
                  <CompanyChatLeftMenu
                    key={company.id}
                    company={company}
                    conversations={displayedConversations}
                  />
                ))
              : null}
          </Paper>
        </Paper>
      </div>
    );
  }

  return (
    <>
      {isSM && show ? (
        <ClickAwayListener onClickAway={() => setShow(false)}>
          {content()}
        </ClickAwayListener>
      ) : null}
      {!isSM ? content() : null}
    </>
  );
}

export default ConversationLeftMenu;
