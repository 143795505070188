import React, { useState, useEffect } from 'react';
import {
  Paper,
  Button,
  Typography,
  CircularProgress,
  useMediaQuery,
  Collapse,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as BoardroomIcon } from './boardroom.svg';
import { useAccount } from '../../contexts/Account';
import AdvisorBoardroomCompanyQuestions from '../../scenes/Boardrooms/Questions';
import MobileCompanyBox from './MobileBoardroomsLayout/MobileCompanyBox';
import MobileMenuCollapse from './MobileBoardroomsLayout/MobileMenuCollapse';
import ActionButtons from './MobileBoardroomsLayout/actions';
import AdvisorBoardroomCompanyMeetings from '../../scenes/Boardrooms/Meetings';
import AdvisorBoardroomMyAnswers from '../../scenes/Boardrooms/MyAnswers';
import AdvisorBoardroomCompanyUpdates from '../../scenes/Boardrooms/CompanyUpdates';
import AdvisorBoardroom from '../../scenes/Boardrooms';
import Benefits from '../../scenes/Boardrooms/Benefits';
import BoardroomsCompaniesTiles from '../../components/BoardroomsTiles';
import BoardroomsTitleSnackbar from '../../components/BoardroomsTiles/boardroomsTitleSnackbar';
import eventBus, { eventBusValues } from '../../eventBus';
import {
  getAdvisorRecurlyPlan,
  resignFromBoard,
} from '../../services/advisor_services';
import { getCompanyBySlug } from '../../services/company_services';

const useStyles = ({ isSM, isWrapped }) => ({
  advisorContainer: {
    display: 'flex',
    flexDirection: 'column',
    overscrollBehavior: 'contain',
    flex: 'auto',
    backgroundColor: '#FFF',
  },
  viewContainer: {
    width: '100%',
    display: 'flex',
    flex: 'auto',
    overflowX: 'hidden',
    overflowY: 'hidden',
    flexDirection: 'row',
  },
  contentContainer: {
    flex: 1,
    overflowY: 'hidden',
    width: '100%',
  },
  selectedNavLink: {
    fontWeight: 'bold',
    color: '#232B35',
    display: 'flex',
    alignItems: 'center',
  },
  navLink: {
    display: 'flex',
    color: '#646D7A',
    alignItems: 'center',
  },
  dividerText: {
    fontSize: '15px',
    fontWeight: 400,
    marginRight: '30px',
    marginTop: '15px',
    marginBottom: '-20px',
    color: 'secondary.dark',
  },
  boardroomsTilesContainer: {
    width: '100%',
    minHeight: isSM ? '' : '358px',
    borderBottom: '1px solid rgb(230,237,254)',
    marginLeft: '1px',
    backgroundColor: '#FFF',
    borderRadius: '0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    paddingRight: isSM ? '20px' : '30px',
    zIndex: 1,
    position: 'relative',
    boxShadow: 'none',
  },
  navContainer: {
    display: 'flex',
    flex: 'auto',
    maxWidth: '1120px',
    height: '75px',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: isSM ? '0px' : '20px',
    overflowX: isSM ? 'scroll' : 'hidden',
    overflowY: 'hidden',
  },
  modalLoadingContainer: {
    width: '80px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'end',
  },
  subMenuButton: {
    '&:hover': {
      background: 'transparent',
    },
  },
  dividerContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    marginRight: '15px',
  },
  buttonsContainer: {
    display: 'flex',
    flex: 'auto',
    height: '75px',
    marginTop: '30px',
    flexDirection: 'row',
    justifyContent: isWrapped ? 'left' : '',
    alignItems: 'center',
  },
});

function CustomMenuItem({
  label,
  route,
  id,
  section,
  setSection,
  companyFreemium,
}) {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const classes = useStyles({ isSM });

  function handleMenuItem() {
    setSection(route);
  }

  return (
    <>
      <div id={id}>
        <Button
          type="button"
          sx={{ ...classes.subMenuButton, marginRight: '20px' }}
          onClick={handleMenuItem}
          disableRipple
        >
          <div
            style={
              section === route ? classes.selectedNavLink : classes.navLink
            }
          >
            {label === 'AdvisoryCloud' && (
              <BoardroomIcon
                style={{
                  marginRight: '4px',
                  marginBottom: '10px',
                  width: '25px',
                }}
                iconname="boardroom_icon"
              />
            )}
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                style={{
                  fontWeight: 500,
                  fontSize: '16px',
                  textTransform: 'none',
                  marginBottom: '5px',
                }}
              >
                {label}
              </Typography>
              {companyFreemium && section === route && (
                <Box
                  sx={{
                    height: '2px',
                    backgroundColor: '#232B35',
                    borderRadius: '20px',
                  }}
                />
              )}
            </Box>
          </div>
        </Button>
      </div>
    </>
  );
}

function AdvisorLayout() {
  const {
    accountDetails,
    isAuthenticated,
    asLoggedUserDetails,
    currentUserInfo,
  } = useAccount();
  const { t } = useTranslation();
  const location = useLocation();
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [successSnackbar, setSuccessSnackbar] = useState(false);
  const [collapseOpen, setCollapseOpen] = useState(false);
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const [company, setCompany] = useState({});
  const [companyFreemium, setCompanyFreemium] = useState(false);
  const [isACPlusCompany, setIsACPlusCompany] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams('/:companySlug/');
  const history = useHistory();
  const { companySlug } = params;

  function getRouteName() {
    if (location.pathname.includes('boardroom')) {
      return 'Boardroom';
    } else if (location.pathname.includes('benefits')) {
      return 'Benefits';
    } else if (location.pathname.includes('my-answers')) {
      return 'Answers';
    } else if (location.pathname.includes('board-meetings')) {
      return 'Board Meetings';
    }
  }
  function getSection() {
    if (location.pathname.includes('boardroom')) {
      return 'boardroom';
    } else if (location.pathname.includes('benefits')) {
      return 'benefits';
    } else if (location.pathname.includes('my-answers')) {
      return 'my-answers';
    } else if (location.pathname.includes('board-meetings')) {
      return 'board-meetings';
    }
  }
  const [section, setSection] = useState(getSection());

  const routeName = getRouteName();

  function resignAction() {
    setIsModalLoading(true);
    resignFromBoard({
      ADVISOR_ID: asLoggedUserDetails.userId,
      COMPANY_ID: company.id,
    })
      .then(() => {
        if (
          accountDetails.viewedBoards &&
          accountDetails.viewedBoards.length > 0
        ) {
          accountDetails.viewedBoards = accountDetails.viewedBoards.filter(
            (board) => board != company.id
          );
        }
        const newMemberBoards = [
          ...asLoggedUserDetails.advisorRelatedCompanies.memberBoards.filter(
            (element) => element.slug !== companySlug
          ),
        ];
        const nonRefMemberBoards = newMemberBoards.map((el) => ({ ...el }));
        accountDetails.boards.memberBoards = nonRefMemberBoards;
        asLoggedUserDetails.advisorRelatedCompanies.memberBoards =
          nonRefMemberBoards;
        history.push({
          pathname: '/dashboard',
          state: {
            resignedCompany: company.displayName,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function renderContent() {
    switch (section) {
      case 'boardroom':
        return <AdvisorBoardroom />;
      case 'my-answers':
        return <AdvisorBoardroomMyAnswers />;
      case 'benefits':
        return <Benefits />;
      case 'board-meetings':
        return <AdvisorBoardroomCompanyMeetings />;
      case 'questions':
        return <AdvisorBoardroomCompanyQuestions />;
      case 'updates':
        return <AdvisorBoardroomCompanyUpdates />;
      default:
        return <AdvisorBoardroom />;
    }
  }

  useEffect(() => {
    eventBus.on(
      eventBusValues.boardroomChangeSection,
      ({ section, postId }) => {
        setSection(section);
      }
    );

    return () => {
      eventBus.remove(eventBusValues.boardroomChangeSection);
    };
  }, []);

  useEffect(() => {
    async function fetchData(planCode) {
      const planResponses = await getAdvisorRecurlyPlan({
        recurlyPlanCode: planCode,
      });
      if (
        planResponses &&
        planResponses.data &&
        planResponses.data.getRecurlyPlan.isFreemium &&
        planResponses.data.getRecurlyPlan.isFreemium === true
      ) {
        setIsLoading(false);

        setCompanyFreemium(true);
      }
      setIsACPlusCompany(planResponses.data?.getRecurlyPlan?.isAcPlus || false);
    }
    if (companySlug) {
      setIsLoading(true);
      getCompanyBySlug({ slug: companySlug }).then(({ data }) => {
        if (data.getCompany) {
          try {
            setCompany(data.getCompany);
            fetchData(data.getCompany.recurlyPlanCode).then(() => {
              setIsLoading(false);
            });
          } catch (error) {
            setIsLoading(false);

            console.log('error:', error);
          }
        }
      });
    }
  }, ['']);

  const classes = useStyles({ isSM });

  return (
    <>
      <BoardroomsTitleSnackbar
        successSnackbar={successSnackbar}
        setSuccessSnackbar={setSuccessSnackbar}
      />
      <>
        {/* {isImpersonated && <ImpersonateUpperBar />} */}
        <div style={classes.advisorContainer}>
          {isAuthenticated &&
            !isXS &&
            (companyFreemium || isACPlusCompany ? (
              <Paper
                elevation={0}
                sx={{
                  width: '100%',
                  minHeight: isSM ? '' : '358px',
                  borderLeft: '1px solid #EFF0F6',
                  backgroundColor: '#FFF',
                  borderRadius: '0px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start',
                  paddingRight: isSM ? '20px' : '30px',
                  paddingBottom: '20px',
                  zIndex: 1,
                  position: 'relative',
                  boxShadow: 'none',
                  borderBottom: '1px solid rgb(230,237,254)',
                }}
              >
                <div style={{ width: '100%' }}>
                  <div style={{ width: '100%' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        marginTop: '20px',
                        height: '326px',
                        maxWidth: '1140px',
                        marginLeft: '30px',
                        overflow: 'hidden',
                        position: 'relative',
                        borderRadius: '10px',
                        '::before': {
                          content: "''",
                          position: 'absolute',
                          width: '300px',
                          height: '300px',
                          borderRadius: '50%',
                          backgroundColor: '#D7F1FD',
                          transform: 'translate(-50%, -50%)',
                          top: 250,
                          left: 100,
                          zIndex: -1,
                        },
                        '::after': {
                          content: "''",
                          position: 'absolute',
                          width: '300px',
                          height: '300px',
                          borderRadius: '50%',
                          backgroundColor: '#DBF7EB',
                          transform: 'translate(50%, 50%)',
                          bottom: 300,
                          right: 0,
                          zIndex: -1,
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '28px',
                          color: '#232B35',
                          fontWeight: 700,
                          width: {
                            '@media (min-width:1550px)': { width: '40%' },
                            xxs: '100%',
                            xs: '80%',
                            sm: '70%',
                            md: '50%',
                          },
                          marginBottom: '20px',
                        }}
                      >
                        Welcome to the {company.companyName} AdvisoryCloud
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '16px',
                          color: '#232B35',
                          fontWeight: 500,
                          width: {
                            xxs: '100%',
                            xs: '80%',
                            sm: '62%',
                            md: '42%',
                          },
                          marginBottom: '20px',
                        }}
                      >
                        Get the latest updates from leadership, provide
                        insights, and collaborate with fellow advisors
                      </Typography>
                      <button
                        id="BOARDROOM_BUTTON_START"
                        style={{
                          marginBottom: '20px',
                          fontSize: '16px',
                          borderRadius: '999px',
                          fontWeight: 700,
                          width: '157px',
                          height: '48px',
                          backgroundColor: '#3171F6',
                          color: '#FFFFFF',
                          borderColor: 'none',
                          fontFamily: 'Poppins',
                          border: 'none',
                          outline: 'none',
                          cursor: 'pointer',
                        }}
                        variant="contained"
                      >
                        Get started
                      </button>
                      {/* <Typography
                            id="BOARDROOM_WATCH_BUTTON"
                            sx={{
                              fontSize: '14px',
                              color: '#232B35',
                              fontWeight: 500,
                              display: 'flex',
                              alignItems: 'center',
                              cursor: 'pointer',
                            }}
                          >
                            <PlayCircleOutlineOutlinedIcon
                              id="BOARDROOM_WATCH_BUTTON"
                              sx={{ marginRight: '5px', cursor: 'pointer' }}
                            />
                            Watch the video
                          </Typography> */}
                    </Box>
                  </div>
                  <div style={classes.navContainer}>
                    <div
                      style={classes.buttonsContainer}
                      id="boardRoomSubNavBar"
                    >
                      <CustomMenuItem
                        label={'AdvisoryCloud'}
                        route={`boardroom`}
                        section={section}
                        setSection={setSection}
                        id="boardroomOption"
                        companyFreemium={companyFreemium}
                      />
                      {/* <CustomMenuItem
                            label={'Benefits'}
                            route={'benefits'}
                            section={section}
                            setSection={setSection}
                            id="benefitsOption"
                            companyFreemium={companyFreemium}
                          /> */}
                      <CustomMenuItem
                        label={'Answers'}
                        route={'my-answers'}
                        section={section}
                        setSection={setSection}
                        id="answersOption"
                        companyFreemium={companyFreemium}
                      />
                    </div>
                    {isModalLoading ? (
                      <div style={classes.modalLoadingContainer}>
                        <CircularProgress />
                      </div>
                    ) : (
                      null
                    )}
                  </div>
                </div>
              </Paper>
            ) : (
              <Paper elevation={0} sx={classes.boardroomsTilesContainer}>
                <div style={{ width: '100%' }}>
                  <div
                    style={{
                      width: '100%',
                      maxWidth: '1140px',
                      paddingLeft: '30px',
                    }}
                  >
                    <BoardroomsCompaniesTiles
                      setSuccessSnackbar={setSuccessSnackbar}
                      company={company}
                      automaticCalendarLink
                    />
                  </div>
                  <div style={classes.navContainer}>
                    <div
                      style={classes.buttonsContainer}
                      id="boardRoomSubNavBar"
                    >
                      <CustomMenuItem
                        label={'Boardroom'}
                        route={`boardroom`}
                        section={section}
                        setSection={setSection}
                        id="boardroomOption"
                      />
                      <CustomMenuItem
                        label={'Benefits'}
                        route={'benefits'}
                        section={section}
                        setSection={setSection}
                        id="benefitsOption"
                      />
                      <CustomMenuItem
                        label={'Answers'}
                        route={'my-answers'}
                        section={section}
                        setSection={setSection}
                        id="answersOption"
                      />
                      <CustomMenuItem
                        label={'Meetings'}
                        route={'board-meetings'}
                        section={section}
                        setSection={setSection}
                        id="meetingsOption"
                      />
                    </div>
                    {isModalLoading ? (
                      <div style={classes.modalLoadingContainer}>
                        <CircularProgress />
                      </div>
                    ) : (
                      null
                    )}
                  </div>
                </div>
              </Paper>
            ))}
          {isAuthenticated && isXS && (
            <>
              <MobileCompanyBox
                companyName={company.displayName}
                logo={company.image ? `https://${company.image.location}` : ''}
                onClick={() => setCollapseOpen(true)}
                isOpen={collapseOpen}
                setCollapseOpen={setCollapseOpen}
              />
              {company && (
                <Collapse in={collapseOpen} timeout="auto" unmountOnExit>
                  <ActionButtons companyBoardData={company} />
                  <MobileMenuCollapse companyBoardData={company} />
                </Collapse>
              )}
              <div style={classes.dividerContainer}>
                <Typography sx={classes.dividerText}>{routeName}</Typography>
              </div>
            </>
          )}

          <div style={classes.viewContainer}>
            <div style={classes.contentContainer}>{renderContent()}</div>
          </div>
          {/* {isAuthenticated && (
          <AdvisorRightMenuSplit
            onDismiss={() => {
              eventBus.dispatch(eventBusValues.mobileMenuTrigger);
            }}
            accountDetails={accountDetails}
          />
        )} */}
        </div>
        {/* <ACSnackbar
        open={successSnackbar}
        text="You've successfully subscribed to board meeting notifications"
        severity="success"
        onClose={() => setSuccessSnackbar(false)}
        autoHideDuration={6000}
      /> */}
      </>
    </>
  );
}

export default AdvisorLayout;
