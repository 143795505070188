import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  TextField,
} from '@mui/material';
import FilePondCompany from './../LogoFreemiumUploader/index';
import { useAccount } from './../../../contexts/Account';

const useStyles = ({ isXS }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    paddingBottom: '20px',
  },
  bodyText: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#2E384D',
  },
  button: {
    width: isXS ? '96%' : '339px',
    height: '44px',
    borderRadius: '50px',
    marginTop: '10px',

    border: 'none',
    outline: 'none',
    boxShadow: 'none',
    textTransform: 'none',

    color: '#FFFFFF',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '-0.4300000071525574px',
    textAlign: 'center',

    backgroundColor: '#3171F6',
    '&:hover': {
      boxShadow: 'none',
    },
    '&:disabled': {
      backgroundColor: '#E0E0E0',
      opacity: '1',
    },

    position: isXS ? 'sticky' : 'relative',
    bottom: isXS ? '10px' : undefined,
  },
  backButton: {
    borderRadius: '50px',
    width: '100%',
    marginTop: '12px',
    border: '0px solid',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
      border: '0px solid',
    },
    fontWeight: '500',
    fontSize: '13px',
    textTransform: 'none',
    color: '#646D7A',
  },
  inputLabelMain: {
    textAlign: 'start',
    marginBottom: '10px',
    fontFamily: 'Poppins',
    fontSize: '13px',
    fontWeight: '500',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    textAlign: 'left',
    color: 'rgba(100, 109, 122, 1)',
  },
  inputContainer: {
    width: '340px',
    marginTop: '20px',
  },
  inputTextField: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    textAlign: 'left',
    borderRadius: '10px',
    background: 'rgba(245, 247, 250, 1)',
    height: '44px',
  },
  titleText: {
    fontFamily: 'Poppins',
    fontSize: '28px',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    textAlign: 'center',
  },
  subTitleText: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    textAlign: 'center',
    color: '#646D7A',
  },
});

const logoAndNameForm = ({ onSubmit, onBack }) => {
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const classes = useStyles({ isXS });
  const [loading, setIsLoading] = useState(false);
  const [filePondStatus, setFilePondStatus] = useState(true);
  const [uploaderOutput, setUploaderOutput] = useState([]);
  const [companyName, setCompanyName] = useState('');
  const [companyStory, setCompanyStory] = useState('');
  const { accountDetails } = useAccount();

  const handleSubmit = () => {
    setIsLoading(true);
    onSubmit(companyName, uploaderOutput, companyStory);
  };

  return (
    <Box sx={classes.container}>
      <Typography variant="h2" sx={classes.titleText}>
        Set up your{' '}
        {accountDetails.userSegment === 'managed_advisory_board'
          ? 'advisory board'
          : 'AdvisoryCloud'}
      </Typography>
      <Box margin="16px 0 32px">
        <Typography sx={classes.subTitleText}>
          {accountDetails.userSegment === 'managed_advisory_board'
            ? 'Tell us a little bit about your company'
            : 'Brand your AdvisoryCloud for your company, project, or initiative'}
        </Typography>
      </Box>
      <div>
        <FilePondCompany
          id="AOBF-B2B-Freemium-companyLogo"
          disabled={false}
          resultFiles={setUploaderOutput}
          status={setFilePondStatus}
          uploaderText={
            accountDetails.userSegment === 'managed_advisory_board'
              ? 'Add your company logo'
              : null
          }
        />
      </div>
      <Box style={classes.inputContainer}>
        <Typography sx={classes.inputLabelMain}>
          {accountDetails.userSegment === 'managed_advisory_board'
            ? 'Company name'
            : 'Name your AdvisoryCloud'}
        </Typography>
        <TextField
          id="AOBF-B2B-Freemium-companyName"
          variant="outlined"
          style={{ width: '100%', marginBottom: '10px' }}
          size="small"
          InputProps={{ sx: classes.inputTextField }}
          placeholder={
            accountDetails.userSegment === 'managed_advisory_board'
              ? 'e.g. Acme Inc.'
              : "e.g. Bob's AdvisoryCloud, Acme Inc., etc."
          }
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
        />
        {accountDetails.userSegment === 'managed_advisory_board' ? null : (
          <Typography sx={classes.inputLabelMain}>
            This is what people will see when you invite them to join
          </Typography>
        )}
      </Box>
      {accountDetails.userSegment === 'managed_advisory_board' ? (
        <Box sx={{ ...classes.inputContainer, marginTop: '10px' }}>
          <Typography sx={classes.inputLabelMain}>
            Tell us about your company
          </Typography>
          <TextField
            id="AOBF-B2B-Freemium-storyAndMission"
            variant="outlined"
            style={{
              width: '100%',
              marginBottom: '10px',
            }}
            multiline
            minRows={3}
            maxRows={6}
            InputProps={{
              sx: {
                ...classes.inputTextField,
                backgroundColor: 'white',
                height: 'auto',
              },
            }}
            placeholder="e.g. We're a technology company focused on..."
            value={companyStory}
            onChange={(e) => setCompanyStory(e.target.value)}
          />
        </Box>
      ) : null}

      <Button
        id="B2B-AOBF-Step3-Continue"
        sx={classes.button}
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={loading}
      >
        Continue
      </Button>
      <Button
        type="button"
        variant="outlined"
        color="primary"
        disableRipple
        onClick={loading ? null : onBack}
        sx={classes.backButton}
      >
        Back
      </Button>
    </Box>
  );
};

export default logoAndNameForm;
