import React, { useEffect, useState } from 'react';
import { Paper, Typography, IconButton } from '@mui/material';
import { useAccount } from '../../contexts/Account';
import CustomIcon from '../../icons/customIcon';
import InsightsImg from './insights.png';
import {
  querySavedInsightsAdvisor,
  mutationSaveAdvisorInsight,
} from '../../services/advisor_services';
import {
  mutationSaveCompanyInsights,
  querySavedInsightsCompany,
} from '../../services/company_services';
import { AccountType } from '../../services/utils/types';

export default function Card({
  onClick,
  title,
  body,
  id,
  icon,
  url,
  currentUser,
}) {
  const { type } = useAccount();
  const [starFilled, setStarFilled] = useState(false);

  function starSelector() {
    if (starFilled) {
      return (
        <CustomIcon
          iconname="star"
          style={{ fontSize: '20px', color: '#FFC400' }}
        />
      );
    } else {
      return (
        <CustomIcon
          iconname="star-outlined"
          style={{ fontSize: '20px', color: '#C9C9DA' }}
        />
      );
    }
  }

  const handleSaveInsight = (event) => {
    event.stopPropagation();
    setStarFilled((prevValue) => !prevValue);
    if (type === AccountType.ADVISOR) {
      mutationSaveAdvisorInsight({
        INSIGHT_ID: id,
        ADVISOR_ID: currentUser.id,
      }).catch((error) => {
        console.error(error);
        setStarFilled((prevValue) => !prevValue);
      });
      return;
    }
    mutationSaveCompanyInsights({
      INSIGHT_ID: id,
      COMPANY_ID: currentUser.companyId,
      CONTACT_ID: currentUser.id,
    }).catch((error) => {
      console.error(error);
      setStarFilled((prevValue) => !prevValue);
    });
  };

  useEffect(() => {
    async function getIsFavorite() {
      try {
        let isFavorite;
        if (type === AccountType.ADVISOR) {
          const response = await querySavedInsightsAdvisor({
            ADVISOR_ID: currentUser.id,
          });
          isFavorite = response.data.getSavedInsightsAdvisor.some(
            (savedInsight) => savedInsight.insightId === id
          );
        } else {
          const response = await querySavedInsightsCompany({
            CONTACT_ID: currentUser.id,
          });
          isFavorite = response.data.getSavedInsightsCompany.some(
            (savedInsight) => savedInsight.insightId === id
          );
        }

        setStarFilled(isFavorite);
      } catch (error) {
        console.error('Error fetching saved insights:', error);
      }
    }

    getIsFavorite();
  }, [id]);

  return (
    <Paper
      id={title.toLowerCase().replace(/\s+/g, '_')}
      className="insightTiles"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        width: '275px',
        height: '260px',
        textAlign: 'center',
        position: 'relative',
        padding: '20px',
        boxShadow: '0px 1px 10px 0px #E6EDFE',
        borderRadius: '10px',
        cursor: 'pointer',
        '&:hover': {
          boxShadow: '0px 0px 40px rgba(0, 0, 0, 0.1)',
        },
      }}
      onClick={() => onClick(url)}
    >
      <IconButton
        disableRipple
        sx={{
          position: 'absolute',
          top: 0,
          right: 10,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
        onClick={handleSaveInsight}
      >
        {starSelector()}
      </IconButton>
      <img
        style={{ height: '40px', marginBottom: '20px' }}
        src={
          icon ? `${process.env.REACT_APP_INSIGHTS_URL}${icon}` : InsightsImg
        }
        alt="Insights"
      />
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 600,
          minHeight: '35px',
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 2,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          marginBottom: '10px',
          width: '75%',
          lineHeight: '1.2',
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          fontSize: '14px',
          marginBottom: '10px',
          overflow: 'hidden',
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 3,
          lineHeight: 'normal',
          height: '4.5em',
          width: '100%',
        }}
      >
        {body}
      </Typography>
    </Paper>
  );
}
