import { Box, Typography } from '@mui/material';
import React from 'react';

function HeroSection({ Photo, title, className }) {
  return (
    <Box className={className || null}>
      <Photo />
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: '15px',
          lineHeight: '20px',
          letterSpacing: '0.75px',
          maxWidth: '191px',
          textAlign: 'center',
        }}
      >
        {title}
      </Typography>
    </Box>
  );
}

export default HeroSection;
