import React from 'react';

function ArchivedQuestions() {
  return (
    <div>
      <span>Archived questions</span>
    </div>
  );
}

export default ArchivedQuestions;
