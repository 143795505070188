import React from 'react';
import { ReactComponent as Sealth } from '../ManagedAdvisoryBoard/stealth.svg';
import { ReactComponent as Assemble } from '../ManagedAdvisoryBoard/assemble.svg';
import { ReactComponent as Collaborate } from '../ManagedAdvisoryBoard/collaborate.svg';
import { Box, Typography } from '@mui/material';

function GettingStarted() {
  return (
    <Box>
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: '28px',
          lineHeight: '32px',
          letterSpacing: '-0.43px',
          textAlign: 'center',
          marginBottom: '10px',
        }}
      >
        Getting started is easy
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xxs: 'column', sm: 'row' },
          alignItems: { xxs: 'center', sm: 'normal' },
          position: 'relative',
        }}
      >
        <Box
          sx={{
            width: { xxs: '200px', sm: '242px' },
            height: { xxs: '200px', sm: '242px' },
            borderRadius: '50%',
            background: '#FEF8D1',
            position: 'absolute',
            left: { xxs: '0%', xs: '25%', sm: '8%', md: '5%' },
            top: { xxs: '5%', sm: '2%' },
            zIndex: 1,
          }}
        />
        <Box
          sx={{
            marginLeft: { xxs: '0px', sm: '10px' },
            marginBottom: { xxs: '-60px', sm: '0px' },
            width: '100%',
            zIndex: 2,
          }}
        >
          <Sealth style={{ width: '100%', minWidth: '350px' }} />
        </Box>

        <Box
          sx={{
            marginTop: { xxs: '0px', sm: '50px' },
            maxWidth: '800px',
            minWidth: '300px',
            marginLeft: { xxs: '30px', sm: '0px' },
            padding: '20px 20px 20px 0px',
            zIndex: 2,
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '22px',
              lineHeight: '32px',
              letterSpacing: '-0.43px',
              marginBottom: '10px',
            }}
          >
            Create your profile
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '15px',
              lineHeight: '22px',
              letterSpacing: '0.75px',
              marginBottom: '20px',
              color: 'rgba(100, 109, 122, 1)',
            }}
          >
            Describe your company, team, project and what your goals and
            challenges are.
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '15px',
              lineHeight: '22px',
              letterSpacing: '0.75px',
              color: 'rgba(100, 109, 122, 1)',
            }}
          >
            Add additional information that would help you connect with the
            right advisors.
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xxs: 'column-reverse', sm: 'row' },
          alignItems: { xxs: 'center', sm: 'normal' },
          position: 'relative',
          marginTop: { xxs: '100px', sm: '0px' },
        }}
      >
        <Box
          sx={{
            marginTop: { xxs: '0px', sm: '50px' },
            maxWidth: '412px',
            minWidth: '250px',
            marginLeft: { xxs: '0px', sm: '60px' },
            padding: '20px',
            zIndex: 2,
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '22px',
              lineHeight: '32px',
              letterSpacing: '-0.43px',
              marginBottom: '10px',
            }}
          >
            Invite advisors from the network
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '15px',
              lineHeight: '22px',
              letterSpacing: '0.75px',
              marginBottom: '20px',
              color: 'rgba(100, 109, 122, 1)',
            }}
          >
            Search the directory to find the best matches to invite to your
            AdvisoryCloud.
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '15px',
              lineHeight: '22px',
              letterSpacing: '0.75px',
              color: 'rgba(100, 109, 122, 1)',
            }}
          >
            Invite team members and colleagues to engage and collaborate as
            well.
          </Typography>
        </Box>
        <Box
          sx={{
            width: { xxs: '200px', sm: '242px' },
            height: { xxs: '200px', sm: '242px' },
            borderRadius: '50%',
            background: '#D7F1FD',
            position: 'absolute',
            right: { xxs: '0%', xs: '25%', sm: '-2%', md: '-2%', lg: '0%' },
            top: { xxs: '-5%', sm: '-10%' },
            zIndex: 1,
          }}
        />
        <Box
          sx={{
            marginLeft: { xxs: '0px', sm: '20px' },
            width: '100%',
            zIndex: 2,
          }}
        >
          <Assemble style={{ width: '100%', minWidth: '350px' }} />
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xxs: 'column', sm: 'row' },
          alignItems: { xxs: 'center', sm: 'normal' },
          position: 'relative',
          marginTop: { xxs: '40px', sm: '0px' },
        }}
      >
        <Box
          sx={{
            width: { xxs: '200px', sm: '242px' },
            height: { xxs: '200px', sm: '242px' },
            borderRadius: '50%',
            background: '#DBF7EB',
            position: 'absolute',
            left: { xxs: '0%', xs: '25%', sm: '8%', md: '5%' },
            top: { xxs: '5%', sm: '2%' },
            zIndex: 1,
          }}
        />
        <Box
          sx={{
            marginLeft: { xxs: '0px', sm: '20px' },
            zIndex: 2,
            marginBottom: { xxs: '-80px', sm: '0px' },
            width: '100%',
          }}
        >
          <Collaborate style={{ width: '100%', minWidth: '350px' }} />
        </Box>

        <Box
          sx={{
            marginTop: { xxs: '0px', sm: '50px' },
            maxWidth: '800px',
            minWidth: '300px',
            marginLeft: { xxs: '30px', sm: '0px' },
            padding: '20px 20px 20px 0px',
            zIndex: 2,
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '22px',
              lineHeight: '32px',
              letterSpacing: '-0.43px',
              marginBottom: '10px',
            }}
          >
            Collaborate in your AdvisoryCloud
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '15px',
              lineHeight: '22px',
              letterSpacing: '0.75px',
              marginBottom: '20px',
              color: 'rgba(100, 109, 122, 1)',
            }}
          >
            Post questions, message, meet and learn from the advisors in your
            AdvisoryCloud.
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '15px',
              lineHeight: '22px',
              letterSpacing: '0.75px',
              marginBottom: '20px',
              color: 'rgba(100, 109, 122, 1)',
            }}
          >
            Reach your goals faster and discover new opportunities with outside
            expertise.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default GettingStarted;
