import React from 'react';
import { Box, Typography } from '@mui/material';
import avatar from './logo.png';
import director from './director.png';
import recruiting from './recruiting.png';
import chro from './chro.png';
import resources from './resources.png';
import CustomIcon from './../../../icons/customIcon';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import TuneIcon from '@mui/icons-material/Tune';
import { useHistory } from 'react-router-dom';

function InsightsEmptyState() {
  const history = useHistory();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#FBFCFD',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <Box sx={{ position: 'relative', marginTop: '50px' }}>
        <CustomIcon
          iconname="insights"
          style={{
            width: '50px',
            height: '50px',
            fontSize: '37px',
            position: 'absolute',
            top: -8,
            right: 10,
          }}
        />
        <Box
          sx={{
            backgroundColor: '#DBF7EB',
            width: '49px',
            height: '44px',
            borderRadius: '100px',
          }}
        />
      </Box>

      <Typography
        sx={{
          fontSize: '26px',
          fontWeight: 700,
          color: '#232B35',
          marginTop: '5px',
          textAlign: 'center',
        }}
      >
        Start your first insight
      </Typography>
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 500,
          color: '#232B35',
          marginTop: '10px',
          textAlign: 'center',
        }}
      >
        Get feedback from a custom audiences
      </Typography>
      <button
        onClick={() => history.push('/insights')}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '20px',
          fontSize: '16px',
          borderRadius: '999px',
          fontWeight: 700,
          width: '193px',
          height: '48px',
          backgroundColor: '#3171F6',
          color: '#FFFFFF',
          borderColor: 'none',
          fontFamily: 'Poppins',
          border: 'none',
          outline: 'none',
          cursor: 'pointer',
          padding: '10px',
          marginTop: '20px',
        }}
        variant="contained"
        id="makeYourFirstPost"
      >
        <CustomIcon
          iconname="insights"
          style={{
            width: '21px',
            height: '22px',
            fontSize: '20px',
            marginRight: '5px',
            marginTop: '-8px',
          }}
        />
        Go to insights
      </button>
      <Box
        id="InsightsEmptyState"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#FFFFFF',
          borderRadius: '10px',
          border: '1px solid #F0F1F3',
          alignItems: 'center',
          height: '100%',
          width: '100%',
          maxWidth: '799px',
          padding: '20px ',
          marginTop: '50px',
          marginBottom: '50px',
          boxShadow: '0px 0px 40px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            maxWidth: '720px',
            marginBottom: '40px',
          }}
        >
          <Box
            id="logoInsightEmptyState"
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '70px',
                height: '70px',
                backgroundColor: '#FAF1EA',
                borderRadius: '999px',
                marginRight: '10px',
              }}
            >
              <img
                src={avatar}
                alt="avatar"
                style={{ width: '65px', height: '18px' }}
              />
            </Box>
            <Typography
              sx={{ fontSize: '16px', fontWeight: 600, color: '#232B35' }}
            >
              FlexiFlow
            </Typography>
          </Box>
          <Box
            id="buttonInsightEmptyState"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '108px',
                height: '32px',
                backgroundColor: '#DBF7EB',
                borderRadius: '999px',
                marginRight: '10px',
              }}
            >
              <CustomIcon
                iconname="insights"
                style={{
                  width: '21px',
                  height: '22px',
                  fontSize: '20px',
                  marginRight: '5px',
                  marginTop: '-8px',
                }}
              />
              <Typography
                sx={{ fontSize: '14px', fontWeight: 600, color: '#232B35' }}
              >
                insight
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ width: '100%', maxWidth: '720px' }}>
          <Typography
            id="titleInsightEmptyState"
            sx={{
              fontSize: '22px',
              fontWeight: 800,
              color: '#232B35',
              marginBottom: '10px',
            }}
          >
            Target Customer Survey
          </Typography>
          <Box
            id="textInsightEmptyState"
            sx={{
              display: 'flex',
              flexDirection: { xxs: 'column', sm: 'row' },
              justifyContent: { xxs: 'center', sm: 'space-between' },
              alignItems: { xxs: 'center', sm: 'initial' },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: 'solid 2px #D7F1FD',
                width: '100%',
                maxWidth: '436px',
                height: '100%',
                maxHeight: '97px',
                borderRadius: '10px',
                padding: '20px',
              }}
            >
              <Typography
                sx={{
                  fontSize: '18px',
                  fontWeight: 500,
                  color: '#232B35',
                }}
              >
                What pain points would you pay to solve that our product doesn’t
                satisfy?
              </Typography>
            </Box>
            <Box
              id="participantsInsightEmptyState"
              sx={{ marginTop: { xxs: '10px', sm: '0px' } }}
            >
              <Typography
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '14px',
                  fontWeight: 500,
                  color: '#232B35',
                  marginBottom: '5px',
                }}
              >
                <GroupsOutlinedIcon
                  sx={{
                    fontSize: '24px',
                    marginRight: '10px',
                  }}
                />
                Participants:
                <span style={{ marginLeft: '5px', fontWeight: 700 }}>100</span>
              </Typography>
              <Typography
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '14px',
                  fontWeight: 500,
                  color: '#232B35',
                  marginBottom: '5px',
                }}
              >
                <AccessTimeIcon
                  sx={{
                    fontSize: '24px',
                    marginRight: '10px',
                  }}
                />
                Time to result:
                <span style={{ marginLeft: '5px', fontWeight: 700 }}>
                  72 hours
                </span>
              </Typography>
              <Typography
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '14px',
                  fontWeight: 500,
                  color: '#232B35',
                  marginBottom: '5px',
                }}
              >
                <TuneIcon
                  sx={{
                    fontSize: '24px',
                    marginRight: '10px',
                    transform: 'rotate(90deg)',
                  }}
                />
                Audience.
              </Typography>
              <Box sx={{ display: 'flex', marginTop: '10px' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '81px',
                    height: '24px',
                    backgroundColor: '#D7E2FD80',
                    borderRadius: '999px',
                    marginRight: '5px',
                  }}
                >
                  <Typography
                    sx={{ fontSize: '12px', fontWeight: 600, color: '#232B35' }}
                  >
                    People Ops
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '81px',
                    height: '24px',
                    backgroundColor: '#D7E2FD80',
                    borderRadius: '999px',
                    marginRight: '5px',
                  }}
                >
                  <Typography
                    sx={{ fontSize: '12px', fontWeight: 600, color: '#232B35' }}
                  >
                    VP & CxO
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '81px',
                    height: '24px',
                    backgroundColor: '#D7E2FD80',
                    borderRadius: '999px',
                    marginRight: '5px',
                  }}
                >
                  <Typography
                    sx={{ fontSize: '12px', fontWeight: 600, color: '#232B35' }}
                  >
                    SHRM
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 500,
              color: '#232B35',
              marginBottom: '30px',
              marginTop: { xxs: '15px', sm: '0px' },
            }}
          >
            Question 1 of 5
          </Typography>
        </Box>
        <Box
          id="commentInsightEmptyState-1"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#E2F6FF',
            borderRadius: '10px 10px 10px 0px',
            height: '100%',
            maxHeight: { xxs: '100%', sm: '81px' },
            width: '100%',
            maxWidth: '728px',
            padding: '10px',
            marginBottom: '30px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xxs: 'column', sm: 'row' },
              alignItems: { xxs: 'normal', sm: 'center' },
              justifyContent: 'space-between',
              marginBottom: '15px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <img
                src={resources}
                alt="resources"
                style={{
                  width: '60px',
                  height: '60px',
                  borderRadius: '99px',
                  marginRight: '10px',
                  objectFit: 'cover',
                  transform: 'scaleX(-1)',
                }}
              />
              <Box>
                <Typography
                  sx={{ fontSize: '13px', fontWeight: 800, color: '#232B35' }}
                >
                  VP of Human Resources
                </Typography>
                <Typography
                  sx={{ fontSize: '12px', fontWeight: 600, color: '#1C2F49' }}
                >
                  20+ years in Fortune 100
                </Typography>
              </Box>
            </Box>
            <Typography
              sx={{
                fontSize: '15px',
                fontWeight: 500,
                color: '#232B35',
                maxWidth: '422px',
                padding: { xxs: '10px', sm: '0px' },
              }}
            >
              “We need a new solution for our recruitment process that improves
              time to hire in a remote environment...”
            </Typography>
          </Box>
        </Box>
        <Box
          id="commentInsightEmptyState-2"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#E6FDF3',
            borderRadius: '10px 10px 10px 0px',
            height: '100%',
            maxHeight: { xxs: '100%', sm: '81px' },
            width: '100%',
            maxWidth: '728px',
            padding: '10px',
            marginBottom: '30px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xxs: 'column', sm: 'row' },
              alignItems: { xxs: 'normal', sm: 'center' },
              justifyContent: 'space-between',
              marginBottom: '15px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={recruiting}
                alt="recruiting"
                style={{
                  width: '60px',
                  height: '60px',
                  borderRadius: '99px',
                  marginRight: '10px',
                  objectFit: 'cover',
                  transform: 'scaleX(-1)',
                }}
              />
              <Box>
                <Typography
                  sx={{ fontSize: '13px', fontWeight: 800, color: '#232B35' }}
                >
                  Head of Recruiting
                </Typography>
                <Typography
                  sx={{ fontSize: '12px', fontWeight: 600, color: '#1C2F49' }}
                >
                  Ex-KornFerry & Adecco
                </Typography>
              </Box>
            </Box>
            <Typography
              sx={{
                fontSize: '15px',
                fontWeight: 500,
                color: '#232B35',
                maxWidth: '422px',
                padding: { xxs: '10px', sm: '0px' },
              }}
            >
              “Implementing AI to automate our administrative tasks for better
              productivity...”
            </Typography>
          </Box>
        </Box>
        <Box
          id="commentInsightEmptyState-3"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#FFEED6',
            borderRadius: '10px 10px 10px 0px',
            height: '100%',
            maxHeight: { xxs: '100%', sm: '81px' },
            width: '100%',
            maxWidth: '728px',
            padding: '10px',
            marginBottom: '30px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xxs: 'column', sm: 'row' },
              alignItems: { xxs: 'normal', sm: 'center' },
              justifyContent: 'space-between',
              marginBottom: '15px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={chro}
                alt="chro"
                style={{
                  width: '60px',
                  height: '60px',
                  borderRadius: '99px',
                  marginRight: '10px',
                  objectFit: 'cover',
                  transform: 'scaleX(-1)',
                }}
              />
              <Box>
                <Typography
                  sx={{ fontSize: '13px', fontWeight: 800, color: '#232B35' }}
                >
                  CHRO
                </Typography>
                <Typography
                  sx={{ fontSize: '12px', fontWeight: 600, color: '#1C2F49' }}
                >
                  20+ Scaling Startups
                </Typography>
              </Box>
            </Box>
            <Typography
              sx={{
                fontSize: '15px',
                fontWeight: 500,
                color: '#232B35',
                maxWidth: '422px',
                padding: { xxs: '10px', sm: '0px' },
              }}
            >
              “We need a flexible talent management system to track and develop
              employee skills and career paths...”
            </Typography>
          </Box>
        </Box>
        <Box
          id="commentInsightEmptyState-4"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#FFFBDF',
            borderRadius: '10px 10px 10px 0px',
            height: '100%',
            maxHeight: { xxs: '100%', sm: '81px' },
            width: '100%',
            maxWidth: '728px',
            padding: '10px',
            marginBottom: '30px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xxs: 'column', sm: 'row' },
              alignItems: { xxs: 'normal', sm: 'center' },
              justifyContent: 'space-between',
              marginBottom: '15px',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={director}
                alt="director"
                style={{
                  width: '60px',
                  height: '60px',
                  borderRadius: '99px',
                  marginRight: '10px',
                  objectFit: 'cover',
                  transform: 'scaleX(-1)',
                }}
              />
              <Box>
                <Typography
                  sx={{ fontSize: '13px', fontWeight: 800, color: '#232B35' }}
                >
                  Director, People Ops
                </Typography>
                <Typography
                  sx={{ fontSize: '12px', fontWeight: 600, color: '#1C2F49' }}
                >
                  20+ years @ Amazon
                </Typography>
              </Box>
            </Box>
            <Typography
              sx={{
                fontSize: '15px',
                fontWeight: 500,
                color: '#232B35',
                maxWidth: '422px',
                padding: { xxs: '10px', sm: '0px' },
              }}
            >
              “We need help implementing data analytics to make more help with
              forward looking workforce planning...”
            </Typography>
          </Box>
        </Box>
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 500,
            color: '#1C2F49',
            marginTop: '20px',
            alignSelf: { xxs: 'center', sm: 'start' },
            marginLeft: { xxs: '0px', sm: '15px' },
          }}
        >
          View all 100 responses
        </Typography>
        <Box sx={{ height: '30px' }} />
      </Box>
    </Box>
  );
}

export default InsightsEmptyState;
