import React, { useState } from 'react';
import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from '@mui/material';

const AccordionList = ({ items }) => {
  const [expanded, setExpanded] = useState('');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box>
      {items.map((item, index) => (
        <Accordion
          key={index}
          id={item.id || null}
          className='faqQuestion'
          expanded={expanded === `panel${index + 1}`}
          onChange={handleChange(`panel${index + 1}`)}
          disableGutters
        >
          <AccordionSummary
            expandIcon={<ExpandMore sx={{ transform: 'rotate(270deg)' }} />}
            aria-controls={`panel${index + 1}-content`}
            id={`panel${index + 1}-header`}
          >
            {item.title}
          </AccordionSummary>
          <AccordionDetails
            sx={{
              textAlign: { xxs: 'left', sm: 'justify' },
              padding: { xxs: '40px 24px 30px', sm: '40px 48px 30px' },
            }}
          >
            {item.body}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default AccordionList;
