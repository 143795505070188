import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const useStyles = (isSM) => ({
  container: {
    width: '470',
  },
  contentNoBorder: {
    width: '470',
  },
  dialogContainer: {
    borderRadius: '5px',
    boxShadow: '0 5px 20px rgba(0, 0, 0, 0.07)',
    padding: isSM ? '5px' : '30px',
  },
  dialogActions: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    marginTop: '5px',
    marginBottom: '-10px',
  },
  mainButton: {
    textTransform: 'none',
    fontFamily: 'Poppins',
    fontWeight: '700',
    fontSize: '15px',
    height: '45px',
    width: '130px',
    lineHeight: '24px',
    borderRadius: '40px',
    backgroundColor: '#6736FA',
    color: 'white',
    marginRight: '10px',
    '&:hover': {
      backgroundColor: '#6736FA',
    },
  },
  secondaryButton: {
    textTransform: 'none',
    fontWeight: '700',
    fontSize: '15px',
    height: '45px',
    width: '130px',
    lineHeight: '24px',
    borderRadius: '40px',
    color: '#6736FA',
    border: '2px solid #6736FA',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  featuredLabel: {
    fontWeight: '500',
    fontSize: '15px',
  },
  featuredLabelContainer: {
    marginTop: '10px',
  },
});

export default function AddBenefitModal({
  addBenefitModal,
  setAddBenefitModal,
  addNewBenefit,
  benefitToEdit,
  setBenefitToEdit,
}) {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const categoryOptions = [
    'Compensation Potential',
    'Equity Potential',
    'Formal Roles',
    'Perks',
  ];
  const [category, setCategory] = useState(
    benefitToEdit ? benefitToEdit.category : ''
  );
  const [details, setDetails] = useState(
    benefitToEdit ? benefitToEdit.details : ''
  );
  const [featured, setFeatured] = useState(
    benefitToEdit ? benefitToEdit.featured : false
  );
  const classes = useStyles(isSM);
  function handleClose() {
    setBenefitToEdit(null);
    setAddBenefitModal(false);
  }

  function addNewBenefitButton() {
    addNewBenefit({ category, details, featured });
    handleClose();
  }

  async function handleACChange(event) {
    const {
      target: { value },
    } = event;
    setCategory(value);
  }

  const inputLabelProps = {
    style: {
      fontFamily: 'Poppins',
    },
  };

  return (
    <Dialog
      open={addBenefitModal}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: classes.dialogContainer,
      }}
    >
      <DialogContent sx={classes.contentNoBorder}>
        <Box sx={classes.container}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="demo-simple-select-label">Category</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={category || ''}
              label="Category"
              onChange={(e, newValue) => handleACChange(e, newValue)}
            >
              {categoryOptions.map((option) => (
                <MenuItem sx={classes.menuItem} key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div style={classes.formInput}>
            <TextField
              placeholder="Description"
              label="Description"
              variant="outlined"
              fullWidth
              multiline
              rows={5}
              style={{
                marginRight: 10,
                marginTop: 15,
              }}
              value={details || ''}
              InputLabelProps={inputLabelProps}
              inputProps={{
                maxLength: 2000,
                style: {
                  fontFamily: 'Poppins',
                },
              }}
              onChange={(evt) => setDetails(evt.target.value)}
            />
          </div>
        </Box>
      </DialogContent>
      <DialogActions sx={classes.dialogActions}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            handleClose();
          }}
          sx={classes.secondaryButton}
        >
          {t('CANCEL')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            addNewBenefitButton();
          }}
          sx={classes.mainButton}
          disabled={!category || !details}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
