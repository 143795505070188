import React, { useState, useMemo } from 'react';
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  Autocomplete,
  Button,
  Box,
  Typography,
  Chip,
} from '@mui/material';
import { useAccount } from '../../../contexts/Account';
import CallWindow from '../../Company/UpgradeCall';
import {
  addAOBFRelations,
  completeAOBF,
  updateAdvisorOnboardingStep6,
} from '../../../services/advisor_services';
import LoadingLogoSkeleton from '../../SkeletonLoading/loadingLogoSkeleton';
import ACSnackbar from '../../ACSnackbar';

const styles = () => ({
  label: {
    fontSize: '13px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '-0.4300000071525574px',
    color: '#646D7A',
    marginBottom: '8px',
  },
  info: {
    fontStyle: 'italic',
    fontSize: '14px',
    fontWeight: 300,
    lineHeight: '24px',
    letterSpacing: '-0.4300000071525574px',
    color: '#646D7A',
    marginBottom: '16px',
  },
  optional: {
    fontSize: '13px',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '-0.4300000071525574px',
    marginTop: '16px',
  },
  footerText: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '-0.4300000071525574px',
    textAlign: 'center',
    marginTop: '24px',
    marginBottom: '36px',
  },
  textField: {
    marginBottom: '8px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',
      '& fieldset': {
        borderColor: '#C7D0DE',
      },
      // '&:hover fieldset': {
      //   borderColor: '#C7D0DE',
      // },
      // '&.Mui-focused fieldset': {
      //   borderColor: '#C7D0DE',
      // },
    },
  },
  gridContainerProps: {
    container: true,
    spacing: 0,
    justifyContent: 'center',
  },
  gridItemProps: {
    item: true,
    xxs: 12,
    md: 8,
  },
});

const AOBFForm = () => {
  const { editAccountInformation, accountDetails, tags, currentPlan } =
    useAccount();

  const classes = styles();
  const [firstName, setFirstName] = useState(accountDetails.givenName || '');
  const [lastName, setLastName] = useState(accountDetails.surName || '');
  const [profession, setProfession] = useState('');
  const [skillsSelected, setSkillsSelected] = useState([]);
  const [additionalSkills, setAdditionalSkills] = useState('');
  const currentPhone =
    accountDetails.phoneNumber && accountDetails.phoneNumber.phoneNumber;
  const [phoneInput, setPhoneInput] = useState(
    currentPhone === '5555555555' ? '' : currentPhone
  );
  const [isLoading, setIsLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const multiSelectTags = useMemo(() => {
    const selectedTagsIds = {
      sk_1663: true,
      sk_1664: true,
      sk_1665: true,
      sk_1666: true,
      sk_967: true,
      sk_1667: true,
      sk_1065: true,
      sk_1668: true,
      sk_1669: true,
      sk_777: true,
      sk_1670: true,
      sk_1671: true,
      sk_1672: true,
      sk_1673: true,
      sk_1674: true,
    };
    if (tags && tags.skills) {
      return tags.skills.filter((tag) => selectedTagsIds[tag.id]);
    }
    return [];
  }, []);

  const professionOptions = [
    'Founder or CEO',
    'Marketing',
    'Business Professional',
    'Operations',
    'Technology',
    'Product',
    'Finance',
    'Sales',
    'Human Resources',
  ];

  const disableButton = () => {
    if (
      !firstName ||
      !lastName ||
      !profession ||
      !phoneInput ||
      !additionalSkills ||
      isLoading
    ) {
      return true;
    }
    if (skillsSelected.length === 0 || phoneInput.length < 7) {
      return true;
    }
    return false;
  };

  const handleRemoveAllSkills = () => {
    setSkillsSelected([]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      await addAOBFRelations({
        advisorId: accountDetails.id,
        profession,
      });
      await completeAOBF({
        id: accountDetails.id,
        givenName: firstName,
        surName: lastName,
        skillTagIds: skillsSelected.map((skill) => skill.id),
        aobfAdditionalExpertise: additionalSkills,
        phoneNumber: phoneInput,
      });
      accountDetails.onboardingStatus = 'Complete';
      editAccountInformation({
        onboardingStatus: 'Complete',
        givenName: firstName,
        surName: lastName,
      });
      await updateAdvisorOnboardingStep6({
        ADVISOR_ID: accountDetails.id,
        ONBOARDING_STATUS: 'Complete',
      });
      window.location.replace(currentPlan.onboardingRedirectUrl);
    } catch (error) {
      setIsLoading(false);
      setOpenSnackbar(true);
      console.error(error);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ p: 2 }}>
      <Grid {...classes.gridContainerProps}>
        <Grid {...classes.gridItemProps}>
          <Typography sx={classes.label}>Your name:</Typography>
        </Grid>
      </Grid>
      <Grid {...classes.gridContainerProps}>
        <Grid item xxs={12} md={4}>
          <TextField
            fullWidth
            placeholder="First"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            sx={{ ...classes.textField, paddingRight: { xxs: 0, md: '6px' } }}
          />
        </Grid>
        <Grid item xxs={12} md={4}>
          <TextField
            fullWidth
            placeholder="Last"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            sx={{ ...classes.textField, paddingLeft: { xxs: 0, md: '6px' } }}
          />
        </Grid>
      </Grid>
      <Grid {...classes.gridContainerProps}>
        <Grid {...classes.gridItemProps}>
          <Typography sx={classes.label}>Your profession:</Typography>
        </Grid>
        <Grid {...classes.gridItemProps}>
          <Select
            fullWidth
            value={profession}
            onChange={(e) => setProfession(e.target.value)}
            displayEmpty
            sx={{
              marginBottom: '8px',
              borderRadius: '10px',
              // '& .MuiOutlinedInput-root': {
              //   borderRadius: '10px',
              //   '& fieldset': {
              //     borderColor: profession ? 'green' : '#C7D0DE',
              //   },
              //   '&:hover fieldset': {
              //     borderColor: profession ? 'darkgreen' : '#C7D0DE',
              //   },
              //   '&.Mui-focused fieldset': {
              //     borderColor: profession ? 'darkgreen' : '#C7D0DE',
              //   },
              // },
            }}
          >
            <MenuItem value="" disabled>
              Select one
            </MenuItem>
            {professionOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid {...classes.gridItemProps}>
          <Typography sx={classes.label}>Your areas of expertise:</Typography>
          <Typography sx={classes.info}>
            This is one component of what companies will use to automatically
            invite you to join their board.
          </Typography>
        </Grid>

        <Grid {...classes.gridItemProps}>
          <Autocomplete
            multiple
            disableCloseOnSelect
            options={multiSelectTags}
            getOptionLabel={(option) => option.label}
            value={skillsSelected}
            onChange={(event, newValue) => setSkillsSelected(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={
                  skillsSelected.length ? '' : 'Select all the apply'
                }
                sx={classes.textField}
              />
            )}
            renderTags={() =>
              skillsSelected.length > 0 ? (
                <Chip
                  label={`(${skillsSelected.length}) selected `}
                  onDelete={handleRemoveAllSkills}
                  sx={{
                    margin: '0',
                    padding: '0',
                    backgroundColor: '#F5F7FA',
                  }}
                />
              ) : null
            }
          />
        </Grid>
        <Grid {...classes.gridItemProps}>
          <Typography sx={classes.label}>
            Please add up to 3 additional skills, industries or targeted areas
            of expertise:
          </Typography>
        </Grid>
        <Grid {...classes.gridItemProps}>
          <TextField
            fullWidth
            placeholder="ex: Software, B2B, Artificial Intelligence"
            value={additionalSkills}
            onChange={(e) => setAdditionalSkills(e.target.value)}
            inputProps={{ maxLength: 200 }}
            sx={classes.textField}
          />
        </Grid>
        <Grid {...classes.gridItemProps}>
          <Typography sx={classes.label}>Your mobile phone:</Typography>
        </Grid>
        <Grid {...classes.gridItemProps}>
          <TextField
            fullWidth
            placeholder="ex: 555-555-5555"
            value={phoneInput}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d{0,10}$/.test(value)) {
                setPhoneInput(value);
              }
            }}
            inputProps={{ maxLength: 10 }}
            sx={classes.textField}
          />
          <Typography sx={classes.optional}>
            {'(Optional) Schedule your complimentary onboarding call:'}
          </Typography>
          <Typography sx={classes.info}>
            Get 1:1 recommendations, guidance and help syncing your calendar
            with your upcoming board meetings.
          </Typography>
        </Grid>
        <Grid {...classes.gridItemProps} md={12} mt={'16px'}>
          <CallWindow
            meetingId="SOIDIV_NewAdvisorComplimentaryOnboarding"
            meetingData="NewAdvisorComplimentaryOnboarding"
            psz="10"
          />
          <Typography sx={classes.footerText}>
            When you click View Dashboard you'll see a pop-up to take a 60
            second tour to see how everything works and immediately find boards
            that are a good match for you.
          </Typography>
        </Grid>
        <Grid {...classes.gridItemProps} md={5}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disableRipple
            disabled={disableButton()}
            sx={{
              borderRadius: '50px',
              backgroundColor: '#3171F6',
              '&:hover': {
                backgroundColor: '#3171F6',
              },
              height: '48px',
              textTransform: 'none',
              fontSize: '16px',
              fontWeight: 700,
            }}
          >
            {isLoading ? <LoadingLogoSkeleton /> : 'View Dashboard'}
          </Button>
        </Grid>
      </Grid>
      <ACSnackbar
        onClose={() => setOpenSnackbar(false)}
        open={openSnackbar}
        severity="error"
        text="Something went wrong"
      />
    </Box>
  );
};

export default AOBFForm;
