import React, { useState } from 'react';
import {
  FormControl,
  Select,
  MenuItem,
  Box,
  Typography,
  useMediaQuery,
} from '@mui/material';

function ActionButton(props) {
  const { buttonTitles, label, width } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);

  const handleButtonClick = (event) => {
    event.stopPropagation();
    setOpen(true);
  };

  const onCloseClick = (event) => {
    event.stopPropagation();
    setOpen(false);
  };

  return (
    <Box
      className="cardActions"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#2271f2',
        alignItems: 'center',
        width: isSM ? width : '148px',
        height: '40px',
        borderRadius: '50px',
      }}
      onClick={handleButtonClick}
    >
      <FormControl
        sx={{
          width: isSM ? width : '148px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          // margin: '0 10px',
          backgroundColor: '#2271f2',
          borderRadius: '25px',
        }}
      >
        <Typography
          sx={{
            color: 'white !important',
            textAlign: 'center',
            width: '100%',
            position: 'absolute',
            fontWeight: 'bold',
            marginRight: '5px',
            paddingRight: '11px',
          }}
          variant={isSM ? 'caption' : 'body2'}
        >
          {label}
        </Typography>
        <Select
          open={open}
          onClose={onCloseClick}
          onOpen={handleButtonClick}
          defaultValue=""
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            sx: {
              '& .MuiMenu-paper': {
                borderRadius: '12px',
                border: '1px solid var(--Gray-5, #F5F7FA)',
                boxShadow: '0px 1px 10px 0px rgba(230, 237, 254, 0.25)',
              },
            },
          }}
          sx={{
            color: 'white !important',
            width: '100%',
            height: '110%',
            borderRadius: '50px',
            fieldset: {
              border: 'none',
            },
            '.MuiSvgIcon-root ': {
              fill: 'white !important',
              marginLeft: '5px',
              fontSize: '22px',
              right: '10px',
            },
            '.MuiSvgIcon-select ': {
              fill: 'white !important',
            },
          }}
          inputProps={{
            classes: {
              icon: {
                color: '#fff !important',
                backgroundColor: '#fff',
              },
              root: {
                color: '#fff !important',
                backgroundColor: '#fff',
              },
            },
          }}
        >
          <MenuItem value="" sx={{ display: 'none' }}></MenuItem>
          {buttonTitles.map((item) => {
            return (
              <MenuItem
                key={item.title}
                onClick={item.click}
                selected={false}
                sx={{
                  '&.Mui-selected': { backgroundColor: 'transparent' },
                }}
                className={
                  'cardActionItem' +
                  item.title.replace(/ /g, '').replace(':', '-')
                }
              >
                {item.icon}
                {item.title}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}

export default ActionButton;
