import React from 'react';
import IconUnderlay from '../IconUnderlay';
import { Paper, Typography, useMediaQuery } from '@mui/material';
import { PriorityHighOutlined } from '@mui/icons-material';
import { useTranslation, Trans } from 'react-i18next';

const useStyles = (isSM) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: isSM ? '100%' : '580px',
    alignItems: 'center',
    padding: '30px 20px',
  },
  titleContainer: {
    maxWidth: '360px',
    marginTop: '20px',
    marginBottom: '40px',
    textAlign: 'center',
  },
  textAroundButton: {
    display: 'inline',
    fontWeight: 'lighter',
  },
  textButton: {
    color: 'primary.main',
    padding: '0px',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    display: 'inline',
  },
  textContainer: {
    textAlign: 'center',
    width: '100%',
  },
});

function UnhandledError({ onRetry }) {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const { t } = useTranslation();

  return (
    <Paper sx={classes.container} elevation={isSM ? 0 : 2}>
      <IconUnderlay size={80}>
        <PriorityHighOutlined color="error" style={{ fontSize: '60px' }} />
      </IconUnderlay>

      <div style={classes.titleContainer}>
        <Typography color="textSecondary" variant={isSM ? 'h1' : 'h2'}>
          {t('LOGIN-ATTEMPT-ERROR-TITLE')}
        </Typography>
      </div>

      <div style={classes.textContainer}>
        <Trans
          t={t}
          i18nKey="LOGIN_ATTEMPT_ERROR_BUTTON_TEXT"
          components={{
            typo: (
              <Typography
                color="textSecondary"
                sx={classes.textAroundButton}
                variant="body1"
              />
            ),
            btn: (
              <Typography
                variant="body1"
                sx={classes.textButton}
                onClick={onRetry}
              />
            ),
          }}
        />
      </div>
    </Paper>
  );
}

export default UnhandledError;
