export function formatBoardJoinedDate(
  dateString = '',
  returnStringVersion = false
) {
  let formattedDate = '';
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  if (returnStringVersion) {
    return `${monthNames[month - 1]} ${day}, ${year}`;
  }

  formattedDate += month < 10 ? `0${month}` : `${month}`;
  formattedDate += day < 10 ? `/0${day}` : `/${day}`;
  formattedDate += `/${year}`;

  return formattedDate;
}

export function isDateExpired(dateString) {
  const today = new Date();
  const expirationDate = new Date(dateString);

  return today > expirationDate;
}
