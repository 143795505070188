import React from 'react';
import { Chip } from '@mui/material';

function ChipWithRef(props) {
  const { label, allowedItems, chipIndex, isHighlighted, highlightColor } = props;
  if (chipIndex >= allowedItems) {
    return null;
  }

  return (
    <Chip
      sx={{
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontSize: 12,
        lineHeight: '24px',
        letterSpacing: '0.75px',
        color: '#232B35',
        marginTop: '15px',
        marginRight: '12px',
        padding: '6px 16px',
        gap: 12,
        background: !isHighlighted ? '#F5F7FA' : highlightColor,
        borderRadius: '100px',
        border: 'none',
        display: 'inline-flex',
      }}
      label={<span dangerouslySetInnerHTML={{ __html: label }} />}
      variant="outlined"
    />
  );
}

export default ChipWithRef;
