export function getAdvisorQueryParams({
  accountDetails,
  isFreeTrial,
  isFreemium,
}) {
  return `currentEmail=${accountDetails.email}&sosfcontactid=${
    accountDetails.salesforceContactId
  }&name=${accountDetails.displayName}&phone=${
    accountDetails.phoneNumber?.phoneNumber
  }&free_trial=${isFreeTrial && !isFreemium}&recurly_account_id=${
    accountDetails.recurlyAccountCode
  }&advisorkocstatus=${accountDetails.kocStatus}&advisorkocdatetime=${
    accountDetails.kocDateTime
  }&advisorkocuser=${accountDetails.kocUser}`;
}
