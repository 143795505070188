import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { useAccount } from '../../contexts/Account';
import eventBus, { eventBusValues } from '../../eventBus';

const IDS = {
  '/dashboard': 'dashboard',
  '/advisors': 'myAdvisors',
  '/advisors/saved': 'savedAdvisors',
  '/advisors/invited': 'invitedAdvisors',
  '/board-meetings': 'boardMeetings',
  '/messages': 'messages',
};

function CompanyEmptyState(props) {
  const { isFreemium, accountDetails } = useAccount();
  const history = useHistory();
  const location = useLocation();
  const currentPath = location.pathname;
  const {
    content = 'Start browsing advisors to invite to your boardroom',
    buttonText = 'Search Advisors',
    buttonAction = () => {
      history.push('/advisors/search');
    },
    buttonId,
  } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const openDialog = () => {
    eventBus.dispatch(eventBusValues.openCreatePost, 'update');
  };

  function getContentByRoute() {
    if (currentPath.includes('/dashboard')) {
      return {
        content: 'Create a welcome post for your AdvisoryCloud',
        buttonText: 'Create Post',
        buttonAction: openDialog,
      };
    }

    if (isFreemium) {
      if (currentPath.includes('/dashboard')) {
        return {
          content: 'Create a welcome post for your AdvisoryCloud',
          buttonText: 'Create Post',
          buttonAction: openDialog,
        };
      }
      if (currentPath.includes('/advisors/saved')) {
        return {
          content: 'Search and save advisors from our network',
          buttonText: 'Search all advisors',
          buttonAction: () => {
            history.push('/advisors/search');
          },
        };
      }
      if (currentPath.includes('/advisors/invited')) {
        return {
          content: `Start inviting advisors to join your ${
            isFreemium ? 'AdvisoryCloud' : 'board'
          }`,
          buttonText: 'Search AdvisoryCloud',
          buttonAction: () => {
            history.push('/advisors/search');
          },
        };
      }
      if (currentPath.includes('/advisors')) {
        return {
          content: `Start inviting advisors to join your ${
            isFreemium ? 'AdvisoryCloud' : 'board'
          }`,
          buttonText: 'Search AdvisoryCloud',
          buttonAction: () => {
            history.push('/advisors/search');
          },
        };
      }
      if (currentPath.includes('/messages')) {
        return {
          content: isFreemium
            ? `Your conversations with advisors and members of your ${
                accountDetails.userSegment === 'managed_advisory_board'
                  ? 'advisory board'
                  : 'AdvisoryCloud'
              } will appear here`
            : 'Add Advisors to your board to start messaging',
          buttonText: 'Search AdvisoryCloud',
          buttonAction: () => {
            history.push('/advisors/search');
          },
        };
      }
    }
  }

  return (
    <Box
      display="flex"
      flexDirection={isSM ? 'column' : 'row'}
      alignItems="center"
      justifyContent="space-between"
      pt="30px"
      id={`${IDS[window.location.pathname] || ''}EmptyStateContainer`}
    >
      <Box maxWidth="420px" minWidth="300px" pr={isSM ? '0px' : '20px'}>
        <Typography
          sx={{ fontWeight: 400, fontSize: '18px', color: '#232B35' }}
        >
          {window.location.pathname.startsWith('/dashboard')
            ? 'Welcome to AdvisoryCloud'
            : ''}
        </Typography>

        <Typography
          sx={{ fontWeight: 700, fontSize: '28px', color: '#232B35' }}
          padding="30px 0px"
        >
          {currentPath.includes('/dashboard')
            ? getContentByRoute().content
            : isFreemium
            ? getContentByRoute().content
            : content}
        </Typography>
        <Button
          id={isFreemium ? 'FreemiumEmptyStateButton' : buttonId || null}
          sx={{
            backgroundColor: '#DBF7EB',
            fontWeight: 600,
            fontSize: '20px',
            color: '#232B35',
            width: '100%',
            height: '100px',
            borderRadius: '50px',
            textTransform: isFreemium ? 'none' : 'uppercase',
            '&:hover': {
              backgroundColor: '#DBF7EB',
            },
          }}
          onClick={
            currentPath.includes('/dashboard')
              ? getContentByRoute().buttonAction
              : isFreemium
              ? getContentByRoute().buttonAction
              : buttonAction
          }
        >
          {currentPath.includes('/dashboard')
            ? getContentByRoute().buttonText
            : isFreemium
            ? getContentByRoute().buttonText
            : buttonText}
        </Button>
      </Box>
      <Box>
        <img
          src="https://cdn.advisorycloud.com/images/company_empty_state.png"
          alt="Company Empty State"
          style={{ maxHeight: isXS ? '300px' : '380px' }}
        />
      </Box>
    </Box>
  );
}

export default CompanyEmptyState;
