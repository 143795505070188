import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ACSnackbar from './../../ACSnackbar';
import { useAccount } from './../../../contexts/Account';
import { inviteToJoin } from './../../../services/company_services';
import { SnackbarType } from './../../../services/utils/types';
import InviteToJoinConfirmationModal from './../InviteToJoinConfirmationModal';
import { convertTimestamp } from './../../../services/utils';
import AdvisorList from './../../Advisor/List';
import Modal from './modal';
import eventBus, { eventBusValues } from './../../../eventBus';

function CompanySearchAdvisorsContent(props) {
  const {
    endpointResponse,
    isMainCallFetching,
    incrementOffset,
    loading,
    searchApplied,
  } = props;

  const [selectedAdvisor, setSelectedAdvisor] = useState(null);
  const [showInviteToJoinConfirmation, setShowInviteToJoinConfirmation] =
    useState(false);
  const [inviteModalLoading, setInviteModalLoading] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [snackbarSuccess, setSnackbarSuccess] = useState(false);
  const [snackbarWarning, setSnackbarWarning] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalContentText, setModalContentText] = useState('');
  const [modalButtonText, setModalButtonText] = useState('');
  const [modalOnButtonClick, setModalOnButtonClick] = useState();
  const [processing, setProcessing] = useState([]);
  const [refresh, doRefresh] = useState(0);
  const [invitationNote, setInvitationNote] = useState("");

  const { t } = useTranslation();
  const history = useHistory();
  const { accountDetails, asLoggedUserDetails, isFreeTrial, type } =
    useAccount();

  const closeModal = () => setShowModal(false);

  function requestMeetingClickAction(advisor) {
    if (isFreeTrial) {
      eventBus.dispatch(eventBusValues.triggerCompanyUpgradeAction);
    } else {
      setSelectedAdvisor(advisor);
      const url = `${process.env.REACT_APP_LEGACY_URL}/profile/${advisor.publicProfileUrl}/speak-with-me?advisor_first_name=${advisor.givenName}&advisor_last_name=${advisor.surName}`;
      window.open(url, '_blank');
    }
  }

  function openAdvisorProfile(advisor) {
    eventBus.dispatch(eventBusValues.triggerAdvisorDrawerAction, advisor);
  }

  function showCallScheduledModal(onboardingDate) {
    setShowModal(true);
    setModalContentText(
      t('CALL_SCHEDULED_MODAL_CONTENT_TEXT', {
        date: convertTimestamp(onboardingDate, true),
      })
    );
    setModalButtonText(t('CLOSE'));
    setModalOnButtonClick(() => closeModal);
  }

  const redirectToOnboardingCall = () => {
    let CALL_URL_PARAMS = `
      name=${encodeURIComponent(
        `${accountDetails.userData.givenName} ${accountDetails.userData.surName}`
      )}&
      email=${encodeURIComponent(accountDetails.userData.email)}&
      company=${encodeURIComponent(accountDetails.companyName)}&
      user=${encodeURIComponent(accountDetails.userData.id)}&
      account-type=${type}&
      workflow=signup
    `;

    if (
      accountDetails.userData.phoneNumber &&
      accountDetails.userData.phoneNumber.internationalPhoneNumber
    ) {
      CALL_URL_PARAMS = `${CALL_URL_PARAMS}&mobile=${encodeURIComponent(
        accountDetails.userData.phoneNumber.internationalPhoneNumber.replace(
          '+',
          ''
        )
      )}`;
    }

    history.push({
      pathname: '/company/onboarding/call',
      search: `?${CALL_URL_PARAMS.replace(/\s/g, '')}`,
    });
  };

  function showCallNotScheduledModal() {
    setShowModal(true);
    setModalContentText(t('CALL_NOT_SCHEDULED_MODAL_CONTENT_TEXT'));
    setModalButtonText(t('SCHEDULE_CALL'));
    setModalOnButtonClick(() => redirectToOnboardingCall);
  }

  function showCallNotScheduledModalCustom(message) {
    setShowModal(true);
    setModalContentText(message);
    setModalButtonText(t('CLOSE'));
    setModalOnButtonClick(() => closeModal);
  }

  function inviteToJoinCall(advisorId) {
    setProcessing([...processing, advisorId]);
    inviteToJoin({
      ADVISOR_ID: advisorId,
      COMPANY_ID: accountDetails.id,
      USER_ID: asLoggedUserDetails.userId,
      INVITATION_NOTE: invitationNote
    })
      .then(() => {
        if (accountDetails.invitedAdvisors) {
          accountDetails.invitedAdvisors.push({
            id: selectedAdvisor.id,
            inviteDate: new Date().toISOString(),
          });
        } else {
          accountDetails.invitedAdvisors = [
            {
              id: selectedAdvisor.id,
              inviteDate: new Date().toISOString(),
            },
          ];
        }
        doRefresh(refresh + 1);
        // editAccountInformation({
        //   invitedAdvisors: accountDetails.invitedAdvisors,
        // });
        setShowInviteToJoinConfirmation(false);
        setInviteModalLoading(false);
        setSnackMessage(
          `${selectedAdvisor.displayName} has been invited to your board`
        );
        setSnackbarSuccess(true);
      })
      .catch((e) => {
        setShowInviteToJoinConfirmation(false);
        setInviteModalLoading(false);
        const err = JSON.parse(e.errors[0].message);
        const { failValue, code } = err;
        if (code && code === 'UnsuccessfullyInvitedToJoin') {
          showCallNotScheduledModalCustom(err.error);
        }
        const failValueModal = {
          '': () => showCallNotScheduledModal(),
          null: () => showCallNotScheduledModal(),
          undefined: () => showCallNotScheduledModal(),
          'Not Scheduled': () => showCallNotScheduledModal(),
          Scheduled: () =>
            showCallScheduledModal(accountDetails.onboardingDate),
        };
        if (
          failValueModal[failValue] &&
          code !== 'UnsuccessfullyInvitedToJoin'
        ) {
          failValueModal[failValue]();
        }
        setSnackMessage('Warning');
        setSnackbarWarning(true);
      });
  }

  function confirmInviteToJoin() {
    setInviteModalLoading(true);
    inviteToJoinCall(selectedAdvisor.id);
  }

  return (
    <>
      <InviteToJoinConfirmationModal
        isVisible={showInviteToJoinConfirmation}
        advisor={selectedAdvisor}
        onConfirm={() => confirmInviteToJoin()}
        onClose={() => setShowInviteToJoinConfirmation(false)}
        isLoading={inviteModalLoading}
        setInvitationNote={setInvitationNote}
      />
      <ACSnackbar
        open={snackbarSuccess}
        text={snackMessage ? t(snackMessage) : ''}
        severity={SnackbarType.SUCCESS}
        onClose={() => setSnackbarSuccess(false)}
        autoHideDuration={6000}
      />
      <ACSnackbar
        open={snackbarWarning}
        text={snackMessage ? t(snackMessage) : ''}
        severity={SnackbarType.WARNING}
        onClose={() => setSnackbarWarning(false)}
        autoHideDuration={6000}
      />
      <Modal
        isVisible={showModal}
        contentText={modalContentText}
        buttonText={modalButtonText}
        onButtonClick={modalOnButtonClick}
        onClose={() => setShowModal(false)}
      />
      {endpointResponse.length > 0 && !loading ? (
        <AdvisorList
          onClickAction={openAdvisorProfile}
          requestMeetingClickAction={requestMeetingClickAction}
          advisorList={endpointResponse}
          removeBelowButtons
          isMainCallLoading={isMainCallFetching}
          incrementOffset={incrementOffset}
          refresh={refresh}
          searchApplied={searchApplied}
          endPagination={!loading}
        />
      ) : null}
    </>
  );
}

export default CompanySearchAdvisorsContent;
