import React from 'react';
import { Typography, useMediaQuery, Avatar, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { getAdvisorImagePath } from '../../services/utils';
import StarSymbol from '../Advisor/Profile/components/star-symbol.svg';

const getClasses = (isSM) => ({
  card: {
    width: '100%',
  },
  publicProfileHeaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingBottom: isSM ? '0px' : '40px',
    paddingRight: isSM ? '0px' : '20px',
    paddingTop: isSM ? '0px' : '20px',
  },
  title: {
    fontSize: '36px',
    fontWeight: 600,
    textAlign: isSM ? 'center' : 'auto',
    paddingTop: isSM ? '20px' : '0px',
  },
  headline: {
    fontSize: '16px',
    color: '#232B35',
    minWidth: '0px',
    marginTop: '10px',
    position: 'relative',
    wordBreak: 'keep-all',
    textOverflow: 'ellipsis',
    overflowWrap: 'break-word',
    textAlign: isSM ? 'center' : 'auto',
  },
  titlePosition: {
    fontSize: '16px',
    color: '#646D7A',
    maxHeight: '50px',
    minWidth: '0px',
    marginTop: '0px',
    position: 'relative',
    fontWeight: 400,
    wordBreak: 'keep-all',
    textOverflow: 'ellipsis',
    overflowWrap: 'break-word',
    textAlign: isSM ? 'center' : 'auto',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  imageAndBiographyContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  imageContainer: {
    width: '232px',
    height: '232px',
    margin: isSM ? 'auto' : '0 20px 0 0',
    marginTop: '20px',
  },
  biographyInformationContainer: {
    display: 'flex',
    marginLeft: '10px',
    width: '70%',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  positionAndTimeContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '40px',
    marginBottom: '20px',
  },
  mobileImageContainer: {
    width: '100%',
    marginTop: '15px',
  },
});

function PublicProfileCard({ advisor }) {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = getClasses(isSM);

  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: '#ffffff',
      '&:before': {
        border: '1px solid #E6EDFF',
        boxShadow: '0px 1px 10px 0px #E6EDFE',
      },
    },
    [`& .${tooltipClasses.tooltip}`]: {
      color: '#232B35',
      padding: '10px',
      backgroundColor: '#ffffff',
      borderRadius: '10px',
      border: '1px solid #E6EDFF',
      boxShadow: '0px 1px 10px 0px #E6EDFE',
      fontSize: 12,
    },
  }));

  return (
    <div style={classes.card}>
      {!isSM ? (
        <div style={classes.publicProfileHeaderContainer}>
          <div style={classes.mainInformationContainer}>
            <div style={classes.row}>
              <div style={classes.imageAndBiographyContainer}>
                {advisor.featuredAdvisor ? (
                  <div
                    style={{
                      display: 'inline-block',
                      borderRadius: '50%',
                      width: '240px',
                      height: '240px',
                      marginRight: '20px',
                      background:
                        'linear-gradient(180deg, #4DF4FF 0%, rgba(38, 83, 242, 0.69) 43.75%, rgba(61, 19, 182, 0.63) 80.21%, #6736FA 100%)',
                      padding: '4px',
                    }}
                  >
                    <Avatar
                      sx={{ ...classes.imageContainer, marginTop: '0px' }}
                      src={getAdvisorImagePath(advisor)}
                    />
                  </div>
                ) : (
                  <Avatar
                    sx={classes.imageContainer}
                    src={getAdvisorImagePath(advisor)}
                  />
                )}
                <div style={classes.biographyInformationContainer}>
                  <Typography sx={classes.title}>
                    {advisor.displayName}
                    {advisor.featuredAdvisor && (
                      <CustomTooltip
                        title="Featured Advisors have priority search listings and may participate in our featured advisor interviews and podcasts."
                        arrow
                        placement="right"
                      >
                        <img
                          src={StarSymbol}
                          alt="star-symbol"
                          style={{
                            marginLeft: '10px',
                            width: '31px',
                            height: '31px',
                            cursor: 'default',
                          }}
                        />
                      </CustomTooltip>
                    )}
                  </Typography>

                  <div style={classes.positionAndTimeContainer}>
                    <Typography sx={advisor.title ? classes.titlePosition : {}}>
                      {advisor.title}
                    </Typography>
                  </div>
                  <div>
                    <Typography sx={classes.headline}>
                      {advisor.headline}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={classes.publicProfileHeaderContainer}>
          <Box
            sx={classes.mobileImageContainer}
            display="flex"
            flexDirection="column"
          >
            <Avatar
              style={classes.imageContainer}
              src={getAdvisorImagePath(advisor)}
            />
            <Typography sx={classes.title}>{advisor.displayName}</Typography>
            <Typography sx={classes.headline}>{advisor.headline}</Typography>
          </Box>
        </div>
      )}
    </div>
  );
}

export default PublicProfileCard;
