import React, { useEffect, useState } from 'react';
import { useMediaQuery, Card, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import ACAvatar from './../../ACAvatar';
import HighlighterPill from './HighlighterPill';
import eventBus, { eventBusValues } from './../../../eventBus';
import CustomIcon from './../../../icons/customIcon';

function setCardPadding(sizes) {
  if (sizes.isXS) {
    return '5px';
  }
  if (sizes.isSM) {
    return '10px';
  }
  return '15px';
}

const useStyles = (sizes) => ({
  card: {
    padding: setCardPadding(sizes),
    borderRadius: '15px',
    position: 'relative',
    height: '360px',
    border: '1px solid',
    borderColor: 'primary.light',
    width: { xxs: '90vw', xs: '100%' },
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  headline: {
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 400,
    color: '#646D7A',
  },
  companyName: {
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: 600,
  },
  companyNameContainer: {
    height: 'auto',
    margin: '20px 0',
  },
  avatarContainer: {
    textAlign: 'center',
    alignItems: 'center',
  },
});

const CompanyDetailGrid = (props) => {
  const {
    company,
    cardClick,
    isCardClickable,
    meetingHighlighter,
    upcomingBoardMeeting,
    advisorAvatar,
    boardMeetingStaticClasses,
  } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isXS = useMediaQuery((theme) => theme.breakpoints.down([350]));
  const classes = useStyles({ isSM, isXS, isCardClickable });
  const options = {
    shouldForwardProp: (prop) => prop !== 'hoverShadow',
  };
  const StyledCard = styled(
    Card,
    options
  )(({ theme, hoverShadow = 1 }) => ({
    ':hover': {
      boxShadow: '0 1px 10px #E6EDFE',
    },
  }));

  const [isFavorite, setIsFavorite] = useState(company.isFavorite);

  const companyName = company.displayName || '';
  const headline = company.headline || '';
  const trimmedCompanyName =
    companyName.length > 35
      ? companyName.substring(0, 50) + '...'
      : companyName;
  const trimmedHeadline =
    headline.length > 100 ? headline.substring(0, 100) + '...' : headline;

  function displayPeerAdvisoryBoard() {
    const p2pFlag = company.boardType == 'P2P';
    return p2pFlag;
  }

  useEffect(() => {
    eventBus.on(
      `${eventBusValues.onCompanyStarClick}_${company.id}`,
      (value) => {
        setIsFavorite(value);
      }
    );
    return () => {
      eventBus.remove(`${eventBusValues.onCompanyStarClick}_${company.id}`);
    };
  });

  return (
    <React.Fragment>
      <StyledCard
        hover={10}
        onClick={cardClick}
        elevation={0}
        id={company.slug}
        className={`${
          boardMeetingStaticClasses ? ' boardMeetingOpp' : ''
        } boardDirectoryCard`}
        sx={classes.card}
        style={{
          cursor: cardClick ? 'pointer' : 'default',
        }}
      >
        <div style={{ height: '80px', display: 'flex' }}>
          {isFavorite && (
            <CustomIcon
              iconname="star-filled"
              style={{
                display: 'flex',
                color: '#6736FA',
              }}
            />
          )}
          {!displayPeerAdvisoryBoard() && meetingHighlighter ? (
            <HighlighterPill
              isMeeting
              type={'Upcoming meeting'}
              CustomIcon={<CustomIcon iconname="calendar-meeting" />}
              pillColor="#DBF7EB"
              upcomingBoardMeeting={upcomingBoardMeeting}
            />
          ) : null}
          {displayPeerAdvisoryBoard() ? (
            <HighlighterPill
              type="Peer Advisory Board"
              CustomIcon={<CustomIcon iconname="people-team" />}
              pillColor="#D7F1FD"
            />
          ) : null}
        </div>

        <div style={classes.avatarContainer}>
          <div style={classes.imageContainer}>
            <ACAvatar avatarClick={() => {}} image={advisorAvatar} size="xl" />
          </div>
        </div>
        <div style={classes.companyNameContainer}>
          <Typography sx={classes.companyName}>
            <span>{trimmedCompanyName}</span>
          </Typography>
        </div>
        <Typography id="biographyParagraph" sx={classes.headline}>
          <span>{trimmedHeadline}</span>
        </Typography>
      </StyledCard>
    </React.Fragment>
  );
};

export default CompanyDetailGrid;
