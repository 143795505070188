export default function getError(error) {
  try {
    let text = '';
    const { errors } = error;
    console.log(errors);
    errors.forEach((err) => {
      let gqlError;
      try {
        gqlError = JSON.parse(err.message);
        if (gqlError.message) {
          text += `${gqlError.message}`;
        } else if (gqlError.msg) {
          text += `${gqlError.msg}`;
        } else if (gqlError.error) {
          text += `${gqlError.error}`;
        }
      } catch (error) {
        if (typeof err.message === 'string') {
          text += `${err.message}\n`;
        }
      }
    });
    if (text !== '') {
      return text;
    }
  } catch (error) {
    console.log(error);
    return 'Something went wrong';
  }
  return 'Something went wrong';
}
