import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, useMediaQuery } from '@mui/material';
import Slider from 'react-slick';
import ElipseLeftImage from './ellipseLeft.png';
import ElipseRightImage from './ellipseRight.png';
import BookMeetting from './icons/bookMeetting.svg';
import AddToBoard from './icons/addToBoard.svg';
import Insights from './icons/insights.svg';
import HireAProject from './icons/vaadin_handshake.svg';
import Conversation from './icons/prime_comments.svg';
import PlayCircleOutlineRoundedIcon from '@mui/icons-material/PlayCircleOutlineRounded';
import { useAccount } from '../../contexts/Account';
import { getAdvisorQueryParams } from '../../utils/queryParams';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const useStyles = (isSM, isMD) => ({
  conteiner: {
    position: 'relative',
    width: '100%',
    marginTop: '20px',
    marginBottom: '20px',
    borderRadius: '16px',
    overflow: 'hidden',
    minHeight: !isSM ? '280px' : '310px',
  },
  elipseLeftImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: !isSM ? '160px' : '80px',
  },
  elipseRightImage: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    height: !isSM ? '160px' : '80px',
  },
  title: {
    top: '20px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '28px',
    lineHeight: '40px',
    color: '#232B35',
    textAlign: 'center',
    zIndex: 1,
    position: 'relative',
  },
  description: {
    top: '20px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '28px',
    color: '#232B35',
    textAlign: 'center',
    zIndex: 1,
    position: 'relative',
    maxWidth: !isSM ? '415px' : '100%',
    margin: !isSM ? '0 auto' : 0,
  },
  icons: {
    top: '20px',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    zIndex: 1,
    position: 'relative',
    flexWrap: 'wrap',
  },
  icon: {
    margin: !isSM ? '0 10px' : '0 10px 20px',
    width: !isSM ? '139px' : 'calc(50% - 20px)',
    height: '133px',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    cursor: 'pointer',
    border: '1px solid #E6EDFF',
    '&:hover': {
      boxShadow: '0px 1px 10px 0px #E6EDFE',
    },
  },
  iconImg: {
    width: '30px',
    marginTop: '25px',
  },
  iconMobile: {
    margin: '10px',
    width: '139px',
    height: '133px',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    cursor: 'pointer',
    border: '1px solid #E6EDFF',
    '&:hover': {
      boxShadow: '0px 1px 10px 0px #E6EDFE',
    },
  },
  text: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '18px',
    letterSpacing: '-0.43px',
    textAlign: 'center',
    margin: '16px',
  },
  videoContainer: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '30px',
    marginBottom: '10px',
  },
  video: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    position: 'relative',
    zIndex: 1,
  },
  videoImg: {
    width: '50px',
  },
  message: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '32px',
    color: '#232B35',
    position: 'relative',
    zIndex: 1,
  },
});

const NetworkingTile = () => {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down([1142]));
  const isMD = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const { accountDetails, isFreeTrial, isFreemium } = useAccount();
  const styles = useStyles(isSM, isMD);
  const history = useHistory();

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
  };

  const iconsData = [
    {
      imgSrc: AddToBoard,
      alt: 'Build your own board',
      marginTop: '31px',
      text: 'Build your own board',
      onClick: () =>
        window.location.replace(
          `https://advisorycloud.com/add-a-board-to-your-membership?${getAdvisorQueryParams(
            { accountDetails, isFreeTrial, isFreemium }
          )}`,
          '_blank'
        ),
      id: 'addToYourAdvisoryCloudTitle',
    },
    {
      imgSrc: Conversation,
      alt: 'Start a conversation',
      marginTop: '25px',
      text: 'Start a conversation',
      id: 'conversationTitle',
    },
    {
      imgSrc: BookMeetting,
      alt: 'Book 1:1 video meetings',
      marginTop: '26px',
      text: 'Book 1:1 video meetings',
      id: 'bookMeettingTitle',
    },
    {
      imgSrc: HireAProject,
      alt: 'Hire for a project',
      marginTop: '25px',
      text: 'Hire for a project',
      id: 'hireProjectTitle',
    },
    {
      imgSrc: Insights,
      alt: 'Get custom insights',
      marginTop: '25px',
      text: 'Get custom insights',
      onClick: () => {
        history.push('/insights');
      },
      id: 'insightsTitle',
    },
  ];

  return (
    <>
      <div style={styles.conteiner}>
        <div style={styles.title}>Tap into the network</div>
        <div style={styles.description}>
          Leverage our network of senior professionals in a number of valuable
          formats
        </div>
        {isSM ? (
          <div style={{ zIndex: 2, position: 'relative' }}>
            <Slider {...sliderSettings}>
              {iconsData.map((icon, index) => (
                <Box>
                  <Box
                    key={index}
                    sx={{ ...styles.iconMobile, marginTop: '35px'}}
                    onClick={icon.onClick}
                    id={icon.id}
                  >
                    <img
                      src={icon.imgSrc}
                      alt={icon.alt}
                      style={{ ...styles.iconImg, marginTop: icon.marginTop }}
                    />
                    <div style={{ ...styles.text, margin: '10px' }}>
                      {icon.text}
                    </div>
                  </Box>
                </Box>
              ))}
            </Slider>
          </div>
        ) : (
          <div style={styles.icons}>
            {iconsData.map((icon, index) => (
              <Box
                key={index}
                sx={{ ...styles.icon }}
                onClick={icon.onClick}
                id={icon.id}
              >
                <img
                  src={icon.imgSrc}
                  alt={icon.alt}
                  style={{ ...styles.iconImg, marginTop: icon.marginTop }}
                />
                <div style={{ ...styles.text, margin: '10px' }}>
                  {icon.text}
                </div>
              </Box>
            ))}
          </div>
        )}
        <img src={ElipseLeftImage} style={styles.elipseLeftImage} />
        <img src={ElipseRightImage} style={styles.elipseRightImage} />
      </div>
    </>
  );
};

export default NetworkingTile;
