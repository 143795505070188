import React, { useCallback } from 'react';
import { useMediaQuery } from "@mui/material";
import { ReactComponent as ACBlue } from './AdvisoryCloudLogo.svg';
import { ReactComponent as ACWhite } from './AdvisoryCloudLogoLargeWhite.svg';
import { ReactComponent as ACNewWhite } from './AdvisoryCloudLogoLargeWhite.svg';
import { ReactComponent as ACDark } from './AdvisoryCloudLogoDark.svg';

function getMeasures(props, defaultSize, filter) {
  if (props.width && props.height) {
    if (filter === 'width') {
      return props.height;
    }
    return props.width;
  }
  if (filter === 'width') {
    if (props.width) {
      return null;
    }

    return props.height || defaultSize;
  }

  if (props.height) {
    return null;
  }
  return props.width || defaultSize
}

const useStyles = (props) => ({
  desktopImage: {
    width: getMeasures(props, 335, 'height'),
    height: getMeasures(props, 73, 'width'),
    cursor: props.cursor,
  },
  mobileImage: {
    width: getMeasures(props, 250, 'height'),
    height: getMeasures(props, 55, 'width'),
    cursor: props.cursor,
  },
  pointerCursor: {
    cursor: 'pointer',
  },
});

export default function AdvisoryCloudLogo(props) {
  const {
    height,
    id,
    link,
    removeHyperlink,
    onClick,
    onMount,
    width,
    variant,
    cursor,
  } = props;

  const classes = useStyles({...props, cursor: cursor || 'pointer'});
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const handleReference = useCallback((ref) => {
    if (onMount) {
      const data = { ref };

      if (id) {
        data.id = id;
      }

      onMount(data);
    }
  });

  if (removeHyperlink) {
    if (variant === 'white') {
      return (
        <ACWhite
          alt="AdvisoryCloud Logo"
          style={isMobile ? classes.mobileImage : classes.desktopImage}
          onClick={() => onClick()}
          
        />
      );
    }
    if (variant === 'newWhite') {
      return (
        <ACNewWhite
          alt="AdvisoryCloud Logo"
          style={isMobile ? classes.mobileImage : classes.desktopImage}
          onClick={() => onClick()}
          
        />
      );
    }
    if (variant === 'dark') {
      return (
        <ACDark
          alt="AdvisoryCloud Logo"
          style={isMobile ? classes.mobileImage : classes.desktopImage}
          onClick={() => onClick()}
          
        />
      );
    }
    return (
      <ACBlue
        alt="AdvisoryCloud Logo"
        style={isMobile ? classes.mobileImage : classes.desktopImage}
        onClick={() => onClick()}
        
      />
    );
  }

  if (variant === 'white') {
    return (
      <a ref={handleReference} href={link || 'https://www.advisorycloud.com'}>
        <ACWhite
          alt="AdvisoryCloud Logo"
          style={isMobile ? classes.mobileImage : classes.desktopImage}
        />
      </a>
    );
  }
  if (variant === 'newWhite') {
    return (
      <a ref={handleReference} href={link || 'https://www.advisorycloud.com'}>
        <ACNewWhite
          alt="AdvisoryCloud Logo"
          style={isMobile ? classes.mobileImage : classes.desktopImage}
        />
      </a>
    );
  }
  if (variant === 'dark') {
    return (
      <a ref={handleReference} href={link || 'https://www.advisorycloud.com'}>
        <ACDark
          alt="AdvisoryCloud Logo"
          style={isMobile ? classes.mobileImage : classes.desktopImage}
        />
      </a>
    );
  }

  return (
    <a
      aria-label="ACLogo"
      form="logo"
      ref={handleReference}
      href={link || 'https://www.advisorycloud.com'}
      style={{ cursor: cursor || 'pointer' }}
    >
      <ACBlue
        alt="AdvisoryCloud Logo"
        style={isMobile ? classes.mobileImage : classes.desktopImage}
      />
    </a>
  );
}
