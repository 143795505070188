import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Box, Divider } from '@mui/material';
import { ReactComponent as Star } from './Star.svg';
import { ReactComponent as Arrow } from './arrowDown.svg';
import { ReactComponent as Team } from './team-icon.svg';
import CompanyIcon from './company.png';
import { set } from 'date-fns';

const CardPlanCode = ({
  onClick,
  membership,
  onContinue,
  userSegment,
  setOpenModal,
}) => {
  const history = useHistory();
  const handleClick = () => {
    if (membership === 'plus') {
      onClick(1);
    } else if (membership === 'managed') {
      onClick(2);
    }
  };

  const handleClickContinue = () => {
    if (membership === 'plus') {
      onClick(1);
      onContinue();
    } else if (membership === 'managed') {
      onClick(2);
      onContinue();
    }
  };

  const ManagedSegment =
    userSegment === 'managed_advisory_board' && membership === 'managed';
  const PlusSegment =
    userSegment !== 'managed_advisory_board' && membership === 'plus';
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {membership === 'current' && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '60px',
          }}
        >
          <Arrow />
          <Typography
            sx={{
              color: '#646D7A',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '20px',
              letterSpacing: '-0.5px',
              textAlign: 'center',
              marginLeft: '5px',
            }}
          >
            Your current plan
          </Typography>
        </Box>
      )}
      {ManagedSegment && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '-15px',
          }}
        >
          <Star />
          <Typography
            sx={{
              color: '#3171F6',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: '20px',
              letterSpacing: '-0.5px',
              textAlign: 'center',
              marginLeft: '5px',
            }}
          >
            Recommended plan
          </Typography>
        </Box>
      )}
      {PlusSegment && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '20px',
          }}
        >
          <Star />
          <Typography
            sx={{
              color: '#3171F6',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 700,
              lineHeight: '20px',
              letterSpacing: '-0.5px',
              textAlign: 'center',
              marginLeft: '5px',
            }}
          >
            Recommended plan
          </Typography>
        </Box>
      )}
      <Box
        onClick={handleClick}
        sx={{
          width: '259px',
          height:
            membership === 'current'
              ? '399px'
              : membership === 'managed'
              ? '529px'
              : '493px',
          border: ManagedSegment || PlusSegment ? 'none' : '1px solid #EFF0F6',
          borderColor: ManagedSegment || PlusSegment ? '#3171F6' : '#C7D0DE',
          borderRadius: '15px',
          position: 'relative',
          zIndex: 1,
          boxShadow:
            ManagedSegment || PlusSegment ? '0 0 5px 0px #3171F6' : 'none',
          marginTop:
            membership === 'current'
              ? '20px'
              : PlusSegment
              ? '0px'
              : ManagedSegment
              ? '36px'
              : '38.5px',
        }}
      >
        <Box>
          <Box
            sx={{
              display: 'grid',
              placeItems: 'center',
              marginTop: '25px',
            }}
          >
            {membership === 'plus' && <Team alt="company" style={{}} />}
            {membership === 'managed' && (
              <img
                src={CompanyIcon}
                alt="company"
                style={{ width: '52px', height: '52px' }}
              />
            )}
          </Box>

          <Typography
            sx={{
              color: '#232B35',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: 800,
              lineHeight: '24px',
              letterSpacing: '-0.43px',
              marginTop: '15px',
              marginBottom: '-10px',
              textAlign: 'center',
            }}
          >
            {membership === 'current' && 'AdvisoryCould'}
            {membership === 'managed' && 'Managed Advisory Board'}
            {membership === 'plus' && 'AdvisoryCould Plus'}
          </Typography>

          <Typography
            sx={{
              color: '#232B35',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '20px',
              letterSpacing: '-0.5px',
              marginBottom: '0px',
              textAlign: 'center',
              padding: '20px',
            }}
          >
            {membership === 'current' &&
              'Use the platform to collaborate with your network'}
            {membership === 'managed' &&
              'Get regular feedback from a dedicated advisory board'}
            {membership === 'plus' &&
              'Collaborate with advisors from our network on-demand'}
          </Typography>
          {membership === 'current' && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginBottom: '20px',
                }}
              >
                <Typography
                  sx={{
                    color: '#232B35',
                    fontSize: '28px',
                    fontStyle: 'normal',
                    fontWeight: 800,
                    lineHeight: '24px',
                    letterSpacing: '-0.43px',
                    textAlign: 'center',
                    marginBottom: '20px',
                  }}
                >
                  Free
                </Typography>
                <Divider sx={{ width: '70%' }} />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '15px',
                  marginBottom: '15px',
                }}
              >
                <Box
                  sx={{
                    width: '5.87px',
                    height: '5.87px',
                    backgroundColor: '#D7F1FD',
                    borderRadius: 99,
                    marginRight: '10px',
                  }}
                />
                <Typography
                  sx={{
                    color: '#232B35',
                    fontSize: '13px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '23px',
                    letterSpacing: '-0.5px',
                  }}
                >
                  Limited access to 10,000+ advisors
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '15px',
                  marginBottom: '15px',
                }}
              >
                <Box
                  sx={{
                    width: '5.87px',
                    height: '5.87px',
                    backgroundColor: '#D7F1FD',
                    borderRadius: 99,
                    marginRight: '10px',
                  }}
                />
                <Typography
                  sx={{
                    color: '#232B35',
                    fontSize: '13px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '23px',
                    letterSpacing: '-0.5px',
                  }}
                >
                  Invite your own advisors
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '15px',
                  marginBottom: '15px',
                }}
              >
                <Box
                  sx={{
                    width: '5.87px',
                    height: '5.87px',
                    backgroundColor: '#D7F1FD',
                    borderRadius: 99,
                    marginRight: '10px',
                  }}
                />
                <Typography
                  sx={{
                    color: '#232B35',
                    fontSize: '13px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '23px',
                    letterSpacing: '-0.5px',
                  }}
                >
                  Secure workspace to collaborate
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '15px',
                  marginBottom: '15px',
                }}
              >
                <Box
                  sx={{
                    width: '5.87px',
                    height: '5.87px',
                    backgroundColor: '#D7F1FD',
                    borderRadius: 99,
                    marginRight: '10px',
                  }}
                />
                <Typography
                  sx={{
                    color: '#232B35',
                    fontSize: '13px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '23px',
                    letterSpacing: '-0.5px',
                  }}
                >
                  1 team member
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '15px',
                  marginBottom: '15px',
                }}
              >
                <Box
                  sx={{
                    width: '5.87px',
                    height: '5.87px',
                    backgroundColor: '#D7F1FD',
                    borderRadius: 99,
                    marginRight: '10px',
                  }}
                />
                <Typography
                  sx={{
                    color: '#232B35',
                    fontSize: '13px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '23px',
                    letterSpacing: '-0.5px',
                  }}
                >
                  Limited messaging
                </Typography>
              </Box>
            </>
          )}
          {membership === 'managed' && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginBottom: '20px',
                }}
              >
                <button
                  style={{
                    marginBottom: '20px',
                    position: 'relative',
                    fontSize: '15px',
                    borderRadius: '999px',
                    fontWeight: 700,
                    width: '168px',
                    height: '44px',
                    backgroundColor: '#D7F1FD',
                    border: '1px solid #4BC6FF',
                    color: '#232B35',
                    borderColor: 'none',
                    fontFamily: 'Poppins',
                    outline: 'none',
                    cursor: 'pointer',
                  }}
                  variant="contained"
                  onClick={handleClickContinue}
                >
                  Get started
                </button>
                <Typography
                  sx={{
                    color: '#232B35',
                    fontSize: '13px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '24px',
                    letterSpacing: '-0.5px',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    if (setOpenModal) {
                      setOpenModal(false);
                    }
                    history.push('/upgrade/b2b_managed_advisory_board');
                  }}
                >
                  Learn More
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '15px',
                  marginBottom: '15px',
                }}
              >
                <Box
                  sx={{
                    width: '5.87px',
                    height: '5.87px',
                    backgroundColor: '#D7F1FD',
                    borderRadius: 99,
                    marginRight: '10px',
                  }}
                />
                <Typography
                  sx={{
                    color: '#232B35',
                    fontSize: '13px',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    lineHeight: '23px',
                    letterSpacing: '-0.5px',
                  }}
                >
                  Everything in AdvisoryCloud Plus
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '15px',
                  marginBottom: '15px',
                }}
              >
                <Box
                  sx={{
                    width: '5.87px',
                    height: '5.87px',
                    backgroundColor: '#D7F1FD',
                    borderRadius: 99,
                    marginRight: '10px',
                  }}
                />
                <Typography
                  sx={{
                    color: '#232B35',
                    fontSize: '13px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '23px',
                    letterSpacing: '-0.5px',
                  }}
                >
                  Dedicated Account Manager
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '15px',
                  marginBottom: '15px',
                }}
              >
                <Box
                  sx={{
                    width: '5.87px',
                    height: '5.87px',
                    backgroundColor: '#D7F1FD',
                    borderRadius: 99,
                    marginRight: '10px',
                  }}
                />
                <Typography
                  sx={{
                    color: '#232B35',
                    fontSize: '13px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '23px',
                    letterSpacing: '-0.5px',
                  }}
                >
                  Advisory Board Assembly in 2 weeks
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '15px',
                  marginBottom: '15px',
                }}
              >
                <Box
                  sx={{
                    width: '5.87px',
                    height: '5.87px',
                    backgroundColor: '#D7F1FD',
                    borderRadius: 99,
                    marginRight: '10px',
                  }}
                />
                <Typography
                  sx={{
                    color: '#232B35',
                    fontSize: '13px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '23px',
                    letterSpacing: '-0.5px',
                  }}
                >
                  Moderated Quarterly Meetings
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '15px',
                  marginBottom: '15px',
                }}
              >
                <Box
                  sx={{
                    width: '5.87px',
                    height: '5.87px',
                    backgroundColor: '#D7F1FD',
                    borderRadius: 99,
                    marginRight: '10px',
                  }}
                />
                <Typography
                  sx={{
                    color: '#232B35',
                    fontSize: '13px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '23px',
                    letterSpacing: '-0.5px',
                  }}
                >
                  Meeting Transcripts and Analysis
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '15px',
                }}
              >
                <Box
                  sx={{
                    width: '5.87px',
                    height: '5.87px',
                    backgroundColor: '#D7F1FD',
                    borderRadius: 99,
                    marginRight: '10px',
                  }}
                />
                <Typography
                  sx={{
                    color: '#232B35',
                    fontSize: '13px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '23px',
                    letterSpacing: '-0.5px',
                  }}
                >
                  and more...
                </Typography>
              </Box>
            </>
          )}
          {membership === 'plus' && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginBottom: '20px',
                }}
              >
                <button
                  style={{
                    marginBottom: '20px',
                    position: 'relative',
                    fontSize: '15px',
                    borderRadius: '999px',
                    fontWeight: 700,
                    width: '168px',
                    height: '44px',
                    backgroundColor: '#DBF7EB',
                    border: '1px solid #2CF9A1',
                    color: '#232B35',
                    borderColor: 'none',
                    fontFamily: 'Poppins',
                    outline: 'none',
                    cursor: 'pointer',
                  }}
                  variant="contained"
                  onClick={handleClickContinue}
                >
                  Get started
                </button>
                <Typography
                  sx={{
                    color: '#232B35',
                    fontSize: '13px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '24px',
                    letterSpacing: '-0.5px',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    if (setOpenModal) {
                      setOpenModal(false);
                    }
                    history.push('/upgrade/b2b_ac_plus');
                  }}
                >
                  Learn More
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '15px',
                  marginBottom: '15px',
                }}
              >
                <Box
                  sx={{
                    width: '5.87px',
                    height: '5.87px',
                    backgroundColor: '#DBF7EB',
                    borderRadius: 99,
                    marginRight: '10px',
                  }}
                />
                <Typography
                  sx={{
                    color: '#232B35',
                    fontSize: '13px',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    lineHeight: '23px',
                    letterSpacing: '-0.5px',
                  }}
                >
                  Everything in AdvisoryCloud Free
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '15px',
                  marginBottom: '15px',
                }}
              >
                <Box
                  sx={{
                    width: '5.87px',
                    height: '5.87px',
                    backgroundColor: '#DBF7EB',
                    borderRadius: 99,
                    marginRight: '10px',
                  }}
                />
                <Typography
                  sx={{
                    color: '#232B35',
                    fontSize: '13px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '23px',
                    letterSpacing: '-0.5px',
                  }}
                >
                  Full access to 10,000+ advisors
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '15px',
                  marginBottom: '15px',
                }}
              >
                <Box
                  sx={{
                    width: '5.87px',
                    height: '5.87px',
                    backgroundColor: '#DBF7EB',
                    borderRadius: 99,
                    marginRight: '10px',
                  }}
                />
                <Typography
                  sx={{
                    color: '#232B35',
                    fontSize: '13px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '23px',
                    letterSpacing: '-0.5px',
                  }}
                >
                  Meet with advisors 1:1 or in groups
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '15px',
                  marginBottom: '15px',
                }}
              >
                <Box
                  sx={{
                    width: '5.87px',
                    height: '5.87px',
                    backgroundColor: '#DBF7EB',
                    borderRadius: 99,
                    marginRight: '10px',
                  }}
                />
                <Typography
                  sx={{
                    color: '#232B35',
                    fontSize: '13px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '23px',
                    letterSpacing: '-0.5px',
                  }}
                >
                  1:1 onboarding and support
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '15px',
                }}
              >
                <Box
                  sx={{
                    width: '5.87px',
                    height: '5.87px',
                    backgroundColor: '#DBF7EB',
                    borderRadius: 99,
                    marginRight: '10px',
                  }}
                />
                <Typography
                  sx={{
                    color: '#232B35',
                    fontSize: '13px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '23px',
                    letterSpacing: '-0.5px',
                  }}
                >
                  and more...
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

function Step1({ onContinue, onOptionChange, isUserSegment, setOpenModal }) {
  const [selectedPlan, setSelectedPlan] = useState('month');
  const [selectedOption, setSelectedOption] = useState(
    isUserSegment === 'managed_advisory_board' ? 2 : 1
  );
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  const handleSelectOption = (option) => {
    setSelectedOption(option);
    let selectedData;
    let counterpartData;

    onOptionChange(selectedPlan, option, selectedData, counterpartData);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: { xxs: '20px', sm: '5px' },
      }}
    >
      <Typography
        sx={{
          color: '#232B35',
          textAlign: 'center',
          fontSize: '28px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: '24px',
          letterSpacing: '-0.43px',
          marginTop: '40px',
          marginBottom: '10px',
        }}
      >
        Get full access to AdvisoryCloud
      </Typography>
      <Box>
        <Typography
          sx={{
            color: '#232B35',
            textAlign: 'center',
            fontSize: '15px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '23px',
            letterSpacing: '-.5px',
            marginBottom: '20px',
            maxWidth: '733px',
          }}
        >
          Based on your interests and goals, we recommend upgrading to{' '}
          {isUserSegment === 'managed_advisory_board'
            ? 'a Managed Advisory Board'
            : 'AdvisoryCloud Plus'}
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xxs: 'column', xs: 'row' },
          justifyContent: { xxs: 'center', sm: 'space-between' },
          flexWrap: 'wrap',
          marginBottom: '30px',
          gap: 5,
        }}
      >
        <CardPlanCode membership="current" />
        <CardPlanCode
          selected={selectedOption === 1}
          membership="plus"
          onClick={handleSelectOption}
          onContinue={onContinue}
          userSegment={isUserSegment}
          setOpenModal={setOpenModal}
        />
        <CardPlanCode
          selected={selectedOption === 2}
          membership="managed"
          onClick={handleSelectOption}
          onContinue={onContinue}
          userSegment={isUserSegment}
          setOpenModal={setOpenModal}
        />
      </Box>
    </Box>
  );
}

export default Step1;
