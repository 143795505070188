import React from 'react';
import { Box, Typography } from '@mui/material';

const getClasses = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '15px',
  },
  txt: {
    fontWeight: 300,
    fontSize: '12px',
    color: '#232B35',
  },
  txtStyle: {
    fontWeight: 300,
    fontSize: '12px',
    color: '#232B35',
    fontFamily: 'Poppins',
  },
});

function TileEmptyState(props) {
  const { txt, Icon } = props;
  const classes = getClasses();

  return (
    <Box sx={classes.container}>
      <Icon style={{ width: '90px', height: '90px', marginBottom: '15px' }} />
      <Typography sx={classes.txt} style={classes.txtStyle}>{txt}</Typography>
    </Box>
  );
}
export default TileEmptyState;
