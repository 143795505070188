import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, OutlinedInput, Typography, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Check, Clear } from '@mui/icons-material';
import DecoratedButton from './decoratedButton';
import ACSnackbar from '../../../ACSnackbar';
import PasswordField from '../../../PasswordSet/PasswordField';
import { useAccount } from '../../../../contexts/Account';
import { forgotPasswordSubmit } from '../../../../services/Authentication';
import {
  ForgotPasswordErrorResponses,
  ForgotPasswordResponses,
} from '../../../../services/Authentication/constants';
import CustomIcon from '../../../../icons/customIcon';

const useStyles = (isSM) => ({
  topRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  titleText: {
    fontWeight: 'bold',
  },
  bottomRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginTop: '15px',
  },
  form: {
    width: '100%',
    display: 'flex',
    justifyContent: isSM ? 'center' : 'space-between',
    alignItems: isSM ? 'flex-start' : 'center',
    flexDirection: isSM ? 'column' : 'row',
  },
  formElement: {
    height: '80px',
    width: isSM ? '100%' : '30%',
    minWidth: 200,
  },
  formElementCode: {
    height: '80px',
    width: isSM ? '50%' : '10%',
    minWidth: '200px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  formElementButton: {
    height: isSM ? 'auto' : 'auto',
    width: isSM ? '100%' : '15%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: isSM ? '20px' : '40px',
    marginBottom: '20px',
    marginRight: isSM ? '0px' : '30px',
  },
  acIcon: {
    marginBottom: '1px',
    fontSize: '15px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  fieldWrapperCode: {
    width: '100%',
    minHeight: '42px',
    height: '42px',
    marginTop: '10px',
  },
  errorPosition: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },

  error: {
    color: 'secondary.dark',
    marginLeft: '5px',
  },

  success: {
    color: 'primary.main',
    marginLeft: '5px',
  },

  iconError: {
    color: 'secondary.dark',
    fontSize: '17px',
    marginTop: '6px',
  },

  iconSuccess: {
    color: 'primary.main',
    fontSize: '17px',
    marginTop: '5px',
  },
  errorMessagesFlex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const FieldWrapper = withStyles((theme) => ({
  root: {
    '& .MuiInputBase-input': {
      color: 'common.black',
      height: 5,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'secondary.main',
    },
    '& .MuiInputBase-input:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 100px #fafafa inset',
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: 'primary.main',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'primary.main',
    },
    '& .Mui-error:after': {
      borderBottomColor: 'error.main',
    },
  },
}))(OutlinedInput);

function AdvisorPasswordChange(props) {
  const { title, finishFlow } = props;
  const { t } = useTranslation();
  const { accountDetails, isFreemium } = useAccount();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isValidAll, setIsValidAll] = useState(false);
  const [renderErrors, setRenderErrors] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [snackBarMessages, setSnackBarMessages] = useState();
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const [numberRetries, setNumberRetries] = useState(0);

  const [validations] = useState([
    { message: `${t('PASSWORD_VALIDATION_MIN_CHARACTERS')}`, status: true },
    { message: `${t('PASSWORD_VALIDATION_MAX_CHARACTERS')}`, status: true },
    {
      message: `${t('PASSWORD_VALIDATION_SPECIAL_CHARACTERS')}`,
      status: true,
    },
    { message: `${t('PASSWORD_VALIDATION_NUMBER')}`, status: true },
    { message: `${t('PASSWORD_VALIDATION_UPPER_CASE')}`, status: true },
    { message: `${t('PASSWORD_VALIDATION_LOWER_CASE')}`, status: true },
    { message: `${t('PASSWORD_VALIDATION_MATCH')}`, status: true },
    { message: `${t('PASSWORD_VALIDATION_CODE')}`, status: true },
  ]);

  function clearForm() {
    for (let i = 0, len = validations.length; i < len; i += 1) {
      validations[i].status = true;
    }
    setCode('');
    setNewPassword('');
    setConfirmPassword('');
  }

  async function cognitoResetPassword() {
    setIsLoading(true);
    try {
      // ForgotPassword function
      const forgotPasswordResult = 'SUCCESS';
      // await forgotPassword(accountDetails.email)

      if (forgotPasswordResult === ForgotPasswordResponses.SUCCESS) {
        setSnackBarMessages(t('ADVISOR_SETTING_CARD_FORGOT_PASSWORD_NEW_CODE'));
        setIsSnackbarVisible(true);
        setIsLoading(false);
      } else {
        setSnackBarMessages(
          t('ADVISOR_SETTING_CARD_FORGOT_PASSWORD_RESPONSE_CALL')
        );
        setIsSnackbarVisible(true);
        setIsLoading(false);
      }
    } catch (err) {
      setSnackBarMessages(t('ADVISOR_SETTING_CARD_FORGOT_PASSWORD_RESPONSE'));
      setIsSnackbarVisible(true);
    }
  }

  async function handleRetries() {
    if (numberRetries === 3) {
      await cognitoResetPassword();
      clearForm();
      setNumberRetries(0);
      setIsLoading(false);
    } else if (numberRetries < 3) {
      setSnackBarMessages(t('ADVISOR_SETTING_CARD_FORGOT_PASSWORD_RESPONSE'));
      setIsSnackbarVisible(true);
      clearForm();
      setNumberRetries(numberRetries + 1);
      setIsLoading(false);
    }
  }

  async function sendNewPassword() {
    setIsLoading(true);
    try {
      const TransientErrors = [
        ForgotPasswordErrorResponses.TOO_MANY_REQUESTS,
        ForgotPasswordErrorResponses.THROTTLING_EXCEPTION,
        ForgotPasswordErrorResponses.LIMIT_EXCEEDED,
      ];

      // send code and email
      const newPasswordSubmit = await forgotPasswordSubmit(
        accountDetails.email,
        code,
        confirmPassword
      );

      if (newPasswordSubmit === ForgotPasswordResponses.SUCCESS) {
        setIsLoading(false);
        finishFlow(t('ADVISOR_SETTING_CARD_CHANGE_PASSWORD_SUCCESS'));
      } else if (
        newPasswordSubmit === ForgotPasswordResponses.CODE_MISMATCH_EXCEPTION ||
        newPasswordSubmit === ForgotPasswordResponses.EXPIRED_CODE_EXCEPTION
      ) {
        handleRetries();
      } else if (TransientErrors.includes(newPasswordSubmit)) {
        setSnackBarMessages(t('ADVISOR_SETTING_CARD_FORGOT_PASSWORD_RESPONSE'));
        setIsSnackbarVisible(true);
        setIsLoading(false);
      } else {
        setSnackBarMessages(
          t('ADVISOR_SETTING_CARD_FORGOT_PASSWORD_RESPONSE_CALL')
        );
        setIsSnackbarVisible(true);
        setIsLoading(false);
      }
    } catch (err) {
      setSnackBarMessages(t('ADVISOR_SETTING_CARD_FORGOT_PASSWORD_RESPONSE'));
      setIsSnackbarVisible(true);
    }
    setIsLoading(false);
  }

  const saveACIcon = () => {
    return <CustomIcon iconname="save-outlined" style={classes.acIcon} />;
  };

  function displayErrors() {
    return (
      <>
        <Grid
          container
          direction="column"
          alignItems="center"
          sx={classes.errorPosition}
        >
          {validations.map((validation) => {
            return (
              <div style={classes.errorMessagesFlex} key={validation.message}>
                <div>
                  {validation.status ? (
                    <Clear sx={classes.iconError} />
                  ) : (
                    <Check sx={classes.iconSuccess} />
                  )}
                </div>
                <div>
                  <Typography
                    sx={validation.status ? classes.error : classes.success}
                  >
                    {validation.message}
                  </Typography>
                </div>
              </div>
            );
          })}
        </Grid>
      </>
    );
  }

  function validatePassword(value) {
    let error;
    // Password must be a minimum of 8 characters
    if (value.length < 8) {
      error = ' ';
      validations[0].status = true;
    } else {
      validations[0].status = false;
    }
    // Password cannot be more than 99 characters
    if (value.length <= 99) {
      validations[1].status = false;
    } else {
      error = ' ';
      validations[1].status = true;
    }
    // Password must contain a special character
    if (!value.match(`[@,#,%,^,*,(,),!,?,~,$,&]`)) {
      error = ' ';
      validations[2].status = true;
    } else {
      validations[2].status = false;
    }
    // Password must contain a number
    if (!value.match(`[0-9]`)) {
      error = ' ';
      validations[3].status = true;
    } else {
      validations[3].status = false;
    }
    // Password must contain an upper case letter
    if (!value.match(`[A-Z]`)) {
      error = ' ';
      validations[4].status = true;
    } else {
      validations[4].status = false;
    }
    // Password must contain a lower case letter
    if (!value.match(`[a-z]`)) {
      error = ' ';
      validations[5].status = true;
    } else {
      validations[5].status = false;
    }
    // // Confirm password must be identical to password
    // if (value !== confirmPassword) {
    //   error = ' ';
    //   validations[6].status = true;
    // } else {
    //   validations[6].status = false;
    // }
    return error;
  }

  function validateConfirmPassword(value) {
    let error;
    // Confirm password must be identical to password
    if (value !== newPassword) {
      error = ' ';
      validations[6].status = true;
    } else {
      validations[6].status = false;
    }
    return error;
  }

  function validateCode(value) {
    let error;
    // Required Code
    if (value.length < 1) {
      error = ' ';
      validations[7].status = true;
    } else {
      validations[7].status = false;
    }
    return error;
  }

  useEffect(() => {
    const foundErrors = validations.filter(
      (element) => element.status === true
    );

    if (newPassword.length === 0 && code.length === 0) {
      setRenderErrors(false);
    } else {
      setRenderErrors(true);
    }

    if (foundErrors.length > 0) {
      setIsValidAll(true);
    } else {
      setRenderErrors(false);
      setIsValidAll(false);
    }
  }, [newPassword, confirmPassword, code]);

  function handleNewPassword(event) {
    setNewPassword(event.target.value);
    validatePassword(event.target.value);
  }

  function handleConfirmNewPassword(event) {
    setConfirmPassword(event.target.value);
    validateConfirmPassword(event.target.value);
  }

  function handleCode(event) {
    setCode(event.target.value);
    validateCode(event.target.value);
  }

  return (
    <>
      <ACSnackbar
        text={snackBarMessages}
        onClose={() => setIsSnackbarVisible(false)}
        open={isSnackbarVisible}
        severity="error"
        variant="filled"
        style={{ marginTop: isFreemium ? '50px' : '0px' }}
      />
      <div style={classes.topRow}>
        <Typography variant="h1" sx={classes.titleText}>
          {title}
        </Typography>
      </div>

      <div style={classes.bottomRow}>
        <form data-testid="formAdvisorChangePassword" style={classes.form}>
          <div style={classes.formElementCode}>
            <Typography color="textSecondary">
              {t('ADVISOR_SETTING_CARD_CHANGE_PASSWORD_TYPECODE')}
            </Typography>
            <FieldWrapper
              sx={classes.fieldWrapperCode}
              onChange={handleCode}
              value={code}
              autoComplete="off"
              id="enterChangePasswordCode"
              name="code"
              variant="outlined"
              inputProps={{
                'aria-label': `${t('ARIA_LABEL_ADVISOR_CHANGE_PASSWORD_CODE')}`,
                'aria-required': 'true',
              }}
            />
          </div>
          <div style={classes.formElement}>
            <Typography color="textSecondary">
              {t('ADVISOR_SETTING_CARD_CHANGE_PASSWORD_NEWPASSWORD')}
            </Typography>
            <PasswordField
              id="newPasswordField"
              style={{ height: '42px' }}
              onChange={handleNewPassword}
              value={newPassword}
              width="100%"
            />
          </div>
          <div style={classes.formElement}>
            <Typography color="textSecondary">
              {t('ADVISOR_SETTING_CARD_CHANGE_PASSWORD_RETYPE')}
            </Typography>
            <PasswordField
              id="confirmNewPasswordField"
              style={{ height: '42px' }}
              onChange={handleConfirmNewPassword}
              value={confirmPassword}
              width="100%"
            />
          </div>
          <div style={classes.formElementButton}>
            <DecoratedButton
              buttonIcon={saveACIcon()}
              buttonText={t('ADVISOR_SETTING_CARD_SAVE_PASSWORD')}
              clickAction={() => sendNewPassword()}
              loading={isLoading}
              isDisabled={isValidAll}
            />
          </div>
        </form>

        {isValidAll ? (
          <Typography color="primary">
            {t('ADVISOR_SETTING_CARD_CHANGE_PASSWORD_SENT_CODE_MESSAGE')}
          </Typography>
        ) : null}

        {renderErrors && displayErrors()}
      </div>
    </>
  );
}

export default AdvisorPasswordChange;
