import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { Box, useMediaQuery } from '@mui/material';
import EmptyStateContent from '../../components/EmptyStateContent';
import { useAccount } from '../../contexts/Account';
import EndpointCaller from '../EndpointCaller';
import {
  CallTypes,
  useInfiniteScroll,
} from '../Post/ResponseReply/useInfiniteScroll';
import {
  getIndexArrayValues,
  getValuesFromKeyInDataResponse,
} from '../../services/utils';
import {
  AccountType,
  EndpointResponseObjectName,
} from '../../services/utils/types';
import CompanyEmptyState from '../EmptyState/CompanyEmptyState';
import CardSkeleton from '../SkeletonLoading/cardSkeleton';
import Treatment from '../Treatment';
import { featureFlags } from '../../splitSetup';
import BoardSkeleton from '../SkeletonLoading/boardSkeleton';
import DashboardSkeleton from '../SkeletonLoading/dashboardSkeleton';

function getSidePadding(styleProps) {
  if (styleProps.setFullScreen) return '0px';
  if (styleProps.isSM) return '0px';
  if (styleProps.location.pathname === '/advisors') return '0px';
  if (styleProps.location.pathname === '/advisors/search') return '0px';
  if (styleProps.location.pathname === '/networking') return '0px';
  if (styleProps.location.pathname === '/advisors/saved') return '0px';
  if (styleProps.location.pathname === '/advisors/top_matches') return '0px';
  if (styleProps.location.pathname === '/advisors/invited') return '0px';
  if (styleProps.location.pathname === '/board-directory') return '0px';
  if (styleProps.location.pathname === '/board-meetings') return '0px';
  return '30px';
}

function getTopPadding(styleProps) {
  if (styleProps.setFullScreen) return '0px';
  if (styleProps.location.pathname.startsWith('/question/')) return '0px';
  if (styleProps.location.pathname.startsWith('/update/')) return '0px';
  if (styleProps.location.pathname === '/board-meetings') return '14px';
  if (
    ['/board-directory', '/networking'].includes(styleProps.location.pathname)
  )
    return '20px';
  return '30px';
}

const useStyles = (styleProps) => ({
  screenContainer: {
    paddingLeft: getSidePadding(styleProps),
    paddingTop: getTopPadding(styleProps),
    paddingRight: getSidePadding(styleProps),
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '1200px',
    minHeight: '500px',
    flex: 'auto',
    marginRight: '0px',
  },
  invisible: {
    display: 'none',
  },
});

function SceneContentStateSelector(props) {
  const {
    helmetTitle,
    endPointAppSyncName,
    endPointCall,
    endPointCallParameters,
    frontendFilters = {},
    ContentTag,
    contentProps,
    emptyStateProps,
    doNotShowEmptyStateScene,
    setFullScreen,
    doNotFetch,
    toggleSearchButton,
    isCardSkeleton,
    isBoardSekeleton,
    isDashboardSkeleton,
  } = props;
  const { type, tags, currentUserInfo } = useAccount();
  const location = useLocation();
  const isSM = useMediaQuery((theme) => {
    return location.pathname.includes('/board-directory')
      ? theme.breakpoints.down([1272])
      : theme.breakpoints.down('sm');
  });
  const isMD = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const isSmallScreen = useMediaQuery('(max-width:945px)');
  const styleProps = {
    isSM,
    isMD,
    setFullScreen,
    location,
  };
  const classes = useStyles(styleProps);
  const [loading, setLoading] = useState(true);
  const [dataEntriesCount, setDataEntriesCount] = useState(-1);
  const [lastFetch, setLastFetch] = useState(false);

  const endPointCallObjectArray = [
    {
      endPointAppSyncName,
      endPointCall,
      endPointCallParameters,
      type: CallTypes.MAIN_CALL,
    },
  ];

  useEffect(() => {
    setLoading(true);
  }, [endPointCallParameters]);

  const {
    posts,
    isMainCallFetching,
    setInitialPosts,
    incrementOffset,
    setDefaultResponseObject,
    addPostLike,
  } = useInfiniteScroll({
    endpointCalls: endPointCallObjectArray,
    setLastFetch: setLastFetch,
  });

  function getResponseArray(post) {
    if (EndpointResponseObjectName.GET_POSTS === endPointAppSyncName)
      return post;
    if (EndpointResponseObjectName.GET_MY_ADVISORS === endPointAppSyncName)
      return post;
    if (
      EndpointResponseObjectName.GET_BOARD_MEETING_POSTS === endPointAppSyncName
    )
      return post;
    if (
      EndpointResponseObjectName.SEARCH_ADVISOR_BY_FILTERS ===
      endPointAppSyncName || EndpointResponseObjectName.SEARCH_ADVISOR_BY_FILTERS_LEGACY ===
      endPointAppSyncName
    )
      return post.advisors;
    if (EndpointResponseObjectName.SEARCH_COMPANIES === endPointAppSyncName)
      return post;
    if (
      EndpointResponseObjectName.GET_RESPONSES_BY_ADVISOR ===
      endPointAppSyncName
    )
      return post.posts;
    return [post];
  }

  function GetEndpointResponseArray(array) {
    setDefaultResponseObject(endPointAppSyncName);
    if (
      array &&
      array.length > 0 &&
      array[0] &&
      array[0].data &&
      array[0].data[endPointAppSyncName]
    ) {
      const post = getValuesFromKeyInDataResponse(
        getIndexArrayValues(array, 0),
        endPointAppSyncName
      );
      const data = getResponseArray(post);
      let emptyFilters = true;
      if (frontendFilters) {
        emptyFilters =
          (!frontendFilters.keywords || frontendFilters.keywords === '') &&
          (!frontendFilters.skills || frontendFilters.skills.length === 0) &&
          (!frontendFilters.industries ||
            frontendFilters.industries.length === 0);
      }
      if (frontendFilters && !emptyFilters) {
        const filteredData = data.filter(function (item) {
          const nameCheck = frontendFilters.keywords
            ? item.displayName
                .toLowerCase()
                .includes(frontendFilters.keywords.toLowerCase())
            : false;

          const headlineCheck = frontendFilters.keywords
            ? item.headline &&
              item.headline
                .toLowerCase()
                .includes(frontendFilters.keywords.toLowerCase())
            : false;

          const bioCheck = frontendFilters.keywords
            ? item.biography &&
              item.biography
                .toLowerCase()
                .includes(frontendFilters.keywords.toLowerCase())
            : false;

          const skillsCheck =
            frontendFilters.skills && frontendFilters.skills.length
              ? frontendFilters.skills.some(
                  (skill) =>
                    item.skillTagIds && item.skillTagIds.includes(skill.id)
                )
              : false;

          const industryCheck =
            frontendFilters.industries && frontendFilters.industries.length
              ? item.industryTagIds &&
                item.industryTagIds.some((industryId) =>
                  frontendFilters.industries
                    .map((industry) => industry.id)
                    .includes(industryId)
                )
              : false;
          if (
            nameCheck ||
            skillsCheck ||
            industryCheck ||
            headlineCheck ||
            bioCheck
          ) {
            return true;
          }
          if (
            frontendFilters.keywords &&
            item.skillTagIds &&
            item.skillTagIds.length > 0
          ) {
            let found = false;
            const relatedSkills = tags.skills.filter((skill) =>
              skill.label
                .toLowerCase()
                .includes(frontendFilters.keywords.toLowerCase())
            );
            item.skillTagIds.forEach((skillId) => {
              if (
                !found &&
                relatedSkills.some((skill) => skill.id === skillId)
              ) {
                found = true;
              }
            });
            if (found) {
              return true;
            }
          }
          if (
            frontendFilters.keywords &&
            item.industryTagIds &&
            item.industryTagIds.length > 0
          ) {
            let found = false;
            const relatedIndustries = tags.industries.filter((industry) =>
              industry.label
                .toLowerCase()
                .includes(frontendFilters.keywords.toLowerCase())
            );
            item.industryTagIds.forEach((industryId) => {
              if (
                !found &&
                relatedIndustries.some((industry) => industry.id === industryId)
              ) {
                found = true;
              }
            });
            if (found) {
              return true;
            }
          }
          return false;
        });

        setInitialPosts(filteredData, post.offset);
      } else {
        setInitialPosts(data, post.offset);
      }
      setDataEntriesCount(data.length);
    } else {
      setDataEntriesCount(0);
    }
    setLoading(false);
    try {
      toggleSearchButton(false);
    } catch (error) {
      // error throwed when not passing this prop, shouldn't bother about it
    }
  }

  function renderEmptyState() {
    if (doNotShowEmptyStateScene) return null;
    if (type !== AccountType.COMPANY) {
      return (
        <EmptyStateContent
          imageArray={emptyStateProps.imageArray}
          titleText={emptyStateProps.titleText}
          contentText={emptyStateProps.contentText}
          buttonText={emptyStateProps.buttonText}
          onButtonClick={emptyStateProps.onButtonClick}
        />
      );
    }
    return <CompanyEmptyState />;
  }

  return (
    <>
      <Helmet>
        <title>{helmetTitle}</title>
      </Helmet>
      <div style={{ ...classes.screenContainer }}>
        <EndpointCaller
          endPointCallObjectArray={endPointCallObjectArray}
          onEndpointCallsFinished={GetEndpointResponseArray}
          executeCalls={loading}
          resetLoading={() => setLoading(true)}
          doNotFetch={doNotFetch}
          isCardSkeleton={isCardSkeleton}
          isBoardSekeleton={isBoardSekeleton}
          isDashboardSkeleton={isDashboardSkeleton}
        />
        {loading && isCardSkeleton && <CardSkeleton />}
        {loading && isBoardSekeleton && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: isSmallScreen ? 'center' : 'initial',
                flexWrap: isSM ? 'wrap' : 'nowrap',
                gap: { xxs: '20px 60px', md: '20px 20px' },
              }}
            >
              <BoardSkeleton />
              <BoardSkeleton />
              <BoardSkeleton />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: { xxs: 'center', md: 'space-around' },
                flexWrap: isSM ? 'wrap' : 'nowrap',
                gap: '20px 10px',
              }}
            >
              <BoardSkeleton />
              <BoardSkeleton />
              <BoardSkeleton />
            </Box>
          </Box>
        )}
        {loading && isDashboardSkeleton && <DashboardSkeleton />}

        <div style={loading ? classes.invisible : {}}>
          {dataEntriesCount > 0 ? (
            <>
              <ContentTag
                endpointResponse={posts}
                contentProps={contentProps}
                incrementOffset={incrementOffset}
                isMainCallFetching={isMainCallFetching}
                addPostLike={addPostLike}
                loading={loading}
                {...contentProps}
                isBoardSekeleton={isBoardSekeleton}
                lastFetch={lastFetch}
              />
            </>
          ) : (
            renderEmptyState()
          )}
        </div>
      </div>
    </>
  );
}

export default SceneContentStateSelector;
