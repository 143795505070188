import React from 'react';
import { Button, Box, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import CircleSVGComponent from './CircleSVGComponent';

export default function CompanySavedAdvisorsEmptyState() {
  const history = useHistory();

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', maxWidth:'1200px' }}>
      <Box
        sx={{
          paddingLeft: '30px',
          paddingRight: '30px',
          paddingBottom: '30px',
          width: '560px',
        }}
      >
        <CircleSVGComponent icon="star" text="Saved advisors appear here" />

        <Typography
          sx={{
            color: '#232B35',
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '28px',
            textAlign: 'center',
            letterSpacing: '-0.43px',
            marginTop: '-15px',
            marginBottom: '15px',
          }}
        >
          Search and save your first advisor
        </Typography>

        <Box sx={{ height: '20px', display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={() => {
              history.push('/advisors/search');
            }}
            style={{
              marginBottom: '20px',
              fontSize: '16px',
              borderRadius: '999px',
              fontWeight: 700,
              width: '205px',
              height: '44px',
              backgroundColor: '#3171F6',
              color: '#FFFFFF',
              borderColor: 'none',
              fontFamily: 'Poppins',
              border: 'none',
              outline: 'none',
              cursor: 'pointer',
              textTransform: 'none',
            }}
            variant="contained"
            id="inviteYourContactsSend"
          >
            Search Advisors
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
