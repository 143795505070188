import React, { useEffect } from 'react';

const useStyles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  scheduleOnceContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
});

function CallWindow(props) {
  const { meetingId, meetingData, style, psz } = props;
  const classes = useStyles();

  useEffect(() => {
    const widget = document.createElement('script');
    widget.type = 'text/javascript';
    widget.src = 'https://cdn.oncehub.com/mergedjs/so.js';

    document.getElementById(meetingId).appendChild(widget);

    return () => {
      const scheduleOnceScript = document.getElementById(meetingId);
      if (scheduleOnceScript) {
        scheduleOnceScript.parentNode.removeChild(scheduleOnceScript);
      }
    };
  }, []);

  return (
    <div style={classes.container}>
      <div style={classes.scheduleOnceContainer}>
        <div
          id={meetingId}
          data-so-page={meetingData}
          data-height="550"
          data-style={style ? style : "border: 1px solid #d8d8d8; max-width: 900px;"}
          data-psz={psz ? psz : "11"}
        />
      </div>
    </div>
  );
}

export default CallWindow;
