import React, { useState } from 'react';
import AdvisoryCloudLogo from './../components/AdvisoryCloudLogo';
import { useAccount } from '../contexts/Account';
import { Box, Avatar, Button, Typography, Drawer, List, ListItem, ListItemText, IconButton } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import MenuIcon from '@mui/icons-material/Menu';

const TopBar = () => {
  const { accountDetails } = useAccount();
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const menuItems = [
    "Dashboard",
    "Company Advisory Boards",
    "Peer Advisory Boards",
    "Personal Advisory Boards",
    "What's new",
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 20px',
        backgroundColor: '#fff',
        borderBottom: '1px solid #ddd',
        position: 'relative',
        zIndex: 1200,
      }}
    >
      {/* Logo */}
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <AdvisoryCloudLogo
          removeHyperlink
          onClick={() => {}}
          cursor="default"
        />
      </Box>

      {/* Menú de opciones */}
      <Box
        sx={{
          display: { xs: 'none', md: 'flex' },
          alignItems: 'center',
          gap: '20px',
        }}
      >
        {menuItems.map((item, index) => (
          <Typography
            key={index}
            sx={{
              fontSize: '14px',
              fontWeight: index === 0 ? 'bold' : 'normal',
              color: index === 0 ? '#2563eb' : '#000',
              cursor: 'pointer',
            }}
          >
            {item}
          </Typography>
        ))}
      </Box>

      {/* Botón de sincronización y Avatar */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
        <IconButton
          sx={{
            display: { xs: 'flex', md: 'none' },
            color: '#2563eb',
          }}
        >
          <SyncIcon />
        </IconButton>
        <Button
          variant="outlined"
          startIcon={<SyncIcon />}
          sx={{
            display: { xs: 'none', md: 'flex' },
            textTransform: 'none',
            fontSize: '14px',
            padding: '5px 12px',
            borderRadius: '8px',
            borderColor: '#2563eb',
            color: '#2563eb',
            '&:hover': {
              borderColor: '#1e3a8a',
              backgroundColor: '#f1f5ff',
            },
          }}
        >
          Sync calendar
        </Button>

        <Avatar
          src={
            accountDetails.image && accountDetails.image.imageKey
              ? `${process.env.REACT_APP_IMAGES_URL}${accountDetails.image.imageKey}`
              : ''
          }
          alt="User Avatar"
          sx={{
            width: 40,
            height: 40,
            border: '2px solid #2563eb',
            cursor: 'pointer',
          }}
        />

        {/* Menú Hamburguesa */}
        <IconButton
          onClick={toggleDrawer(true)}
          sx={{
            display: { xs: 'block', md: 'none' },
            color: '#2563eb',
          }}
        >
          <MenuIcon />
        </IconButton>

        {/* Drawer para Menú en Mobile */}
        <Drawer
          anchor="right"
          open={isDrawerOpen}
          onClose={toggleDrawer(false)}
          ModalProps={{
            keepMounted: true, // Mejora el rendimiento al mantener el Drawer montado
          }}
          sx={{
            '& .MuiDrawer-paper': {
              width: '100%',
              maxWidth: '400px',
              marginTop: '64px', // No cubre la TopBar
              padding: '20px',
              backgroundColor: '#f9fbff',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '20px',
            }}
          >
            <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>
              Menu
            </Typography>
            <List>
              {menuItems.map((item, index) => (
                <ListItem button key={index} sx={{ textAlign: 'center' }}>
                  <ListItemText
                    primary={item}
                    primaryTypographyProps={{
                      fontSize: '16px',
                      fontWeight: index === 0 ? 'bold' : 'normal',
                      color: index === 0 ? '#2563eb' : '#000',
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
      </Box>
    </Box>
  );
};

export default TopBar;
