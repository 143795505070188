import JSConfetti from 'js-confetti';

class Conffeti {
  constructor({ canvas }) {
    this.confetti = new JSConfetti({canvas: canvas});
  }

  throwConfetti() {
    this.confetti.addConfetti();
  }

  clearConfetti() {
    this.confetti.clear();
  }
  

}

export default Conffeti;