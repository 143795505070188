import React from 'react';
import { Paper, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CompanyForm from './CompanyForm';
import { useAccount } from '../../contexts/Account';
import CompanyPreferences from './CompanyPreferences';

const useStyles = ({ isXS }) => ({
  formContainer: {
    paddingTop: isXS ? '0px' : '20px',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingBottom: '20px',
    border: 'solid 1px #E5E5E5',
    borderRadius: '10px',
    marginBottom: '20px',
  },
});

function CompanyInformationEditCard(props) {
  const { onCancelClick } = props;
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const classes = useStyles({ isXS });
  const { t } = useTranslation();
  const { accountDetails, asLoggedUserDetails } = useAccount();
  const isBoardOwner = accountDetails.userData.isBoardOwner;

  const contact =
    accountDetails.contacts?.find(
      (companyContact) => companyContact.id === asLoggedUserDetails.userId
    ) || {};

  const members =
    accountDetails.contacts?.filter(
      (contact) =>
        contact.id !== asLoggedUserDetails.userId &&
        (contact.accountStatus === 'active' || contact.accountStatus === 'new')
    ) || [];

  const formInitialValues = {
    companyWebsite: accountDetails.website || '',
    displayIndustry: accountDetails.displayIndustry,
    yourFirstName: contact.givenName || '',
    yourLastName: contact.surName || '',
    yourTitle: contact.title || '',
    yourBiography: contact.biography || '',
    yourPhoneCountryCode: contact.phoneNumber?.countryCode || '',
    yourPhoneNumber:
      contact.phoneNumber?.phoneNumber === '5555555555'
        ? ''
        : contact.phoneNumber?.phoneNumber || '',
    members: members || [],
    headline: accountDetails.headline || '',
  };

  const schemaShape = {
    yourBiography: Yup.string().required(t('Your biography is required')),
    yourFirstName: Yup.string().required(t('First name is required')),
    yourLastName: Yup.string().required(t('Last name is required')),
    yourTitle: Yup.string().required(t('Title is required')),
    yourPhoneCountryCode: Yup.string().required(t('Country code is required')),
    yourPhoneNumber: Yup.string().required(t('Phone number is required')),
  };
  const Step1Schema = Yup.object().shape(schemaShape);

  function renderForm(data) {
    const isValidIndex = Object.keys(data).indexOf('isValid');
    const IS_DISABLED = Object.values(data)[isValidIndex];

    return (
      <CompanyForm
        isUpdate
        isBoardOwner={isBoardOwner}
        {...data}
        {...props}
        isDisabled={!IS_DISABLED}
        onCancelClick={onCancelClick}
      />
    );
  }

  return (
    <Paper sx={{ marginBottom: 10, boxShadow: 'none', maxWidth: '1200px' }}>
      <div style={classes.formContainer}>
        <Formik
          initialValues={formInitialValues}
          validationSchema={Step1Schema}
          validateOnMount
        >
          {renderForm}
        </Formik>
      </div>
      {isBoardOwner && (
        <>
          <div style={classes.formContainer}>
            <CompanyPreferences freemiumView hideBillingInformation />
          </div>
          <div style={classes.formContainer}>
            <CompanyPreferences freemiumView hidePlanDetails />
          </div>
        </>
      )}
    </Paper>
  );
}

export default CompanyInformationEditCard;
