const skillTags = [
  'Acquisitions',
  'Advertising',
  'Angel Investing',
  'Artificial Intelligence',
  'Asset Management',
  'B2B Marketing',
  'B2C Marketing',
  'Big Data',
  'Billing Systems',
  'Biomedical Engineering',
  'Capital Markets',
  'Cellular Communications',
  'Chemical Engineering',
  'Clean Technology',
  'Cloud Computing',
  'Communications',
  'Consumer Packaged Goods',
  'Corporate Social Responsibility',
  'Customer Acquisition',
  'Cyber-Security',
  'Data Management',
  'Debt Restructuring',
  'Digital Transformation',
  'Drug Development',
  'Educational Technology',
  'Electrical Engineering',
  'Electronic Payments',
  'Environmental Science',
  'Equity Capital Markets',
  'Equity Valuation',
  'Federal Grants Management',
  'Financial Modeling',
  'Financial Strategy',
  'Genomics',
  'Global HR Management',
  'Go-To-Market Strategy',
  'Hardware Architecture',
  'Healthcare Consulting',
  'IPO',
  'IT Management',
  'Marketing',
  'Mechanical Engineering',
  'Medical Devices',
  'Mobile Communications',
  'Mobile Marketing',
  'Organizational Leadership',
  'Private Equity',
  'Research And Development',
  'Robotics',
  'SaaS',
  'Scaling',
  'SEO',
  'Social Media Marketing',
  'Software Development',
  'Start-Ups',
  'Telecommunications',
  'Telemedicine',
  'Trademarks',
  'UI / UX',
  'Valuation',
  'Venture Capital',
  'Viral Marketing',
  'Wearable Technology',
  'Web Applications',
];
const industryTags = [
  'Accounting',
  'Aerospace & Defense',
  'Apparel & Fashion',
  'Automotive',
  'Biotechnology',
  'Casinos and Gambling',
  'Commercial Real Estate',
  'Computer & Network Security',
  'Computer Software',
  'Consumer Electronics',
  'eLearning',
  'Entertainment',
  'Environmental Services',
  'Financial Services',
  'Food & Beverages',
  'Health Wellness and Fitness',
  'Higher Education',
  'Hospital & Hospice',
  'Hospitality',
  'Industrial Chemicals',
  'Investment Banking',
  'Investment Management',
  'Legal Services',
  'Luxury Goods & Jewelry',
  'Management Consulting',
  'Mining and Metals',
  'Nanotechnology',
  'Online Media',
  'Paper & Forest Products',
  'Pharmaceuticals',
  'Professional Training & Development',
  'Public Relations & Communications',
  'Publishing',
  'Recruiting & Staffing',
  'Saas',
  'Semiconductors',
  'Telecom',
  'Transportation',
  'Travel & Tourism',
  'Utilities',
  'Venture Capital & Private Equity',
];

module.exports = {
  skillTags,
  industryTags,
};
