import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { Clear, Edit } from '@mui/icons-material';

const useStyles = () => ({
  itemExperience: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: '20px',
  },
  positionText: {
    fontSize: '105%',
    fontWeight: 700,
  },
  bulletList: {
    margin: '0px',
    paddingLeft: '15px',
  },

  editButton: {
    border: 'none',
    padding: '0px',
    marginRight: '10px',
    display: 'inline',
    backgroundColor: '#fff',
    alignItems: 'center',
    cursor: 'pointer',
  },
  editIcon: {
    width: '15px',
    height: '15px',
    display: 'inline',
    marginRight: '2px',
  },
  removeIcon: {
    fontSize: '17px',
    bottom: '-3px',
    position: 'relative',
    display: 'inline',
    cursor: 'pointer',
  },
});

function AdvisorExperience(props) {
  const { experienceArray, edit, editAction, deleteAction } = props;
  const [isDeleting, setIsDeleting] = useState(false);

  const classes = useStyles();

  return (
    <div
      style={{
        marginTop: '-10px',
      }}
    >
      {experienceArray.map((exp, index) => (
        <div style={classes.itemExperience} key={exp.position + exp.company}>
          {edit ? (
            <div>
              <button
                onClick={() => {
                  editAction(index);
                }}
                type="submit"
                style={classes.editButton}
              >
                <Edit color="primary" sx={classes.editIcon} />
                <Typography
                  variant="body1"
                  color="primary"
                  style={{ display: 'inline' }}
                >
                  Edit
                </Typography>
              </button>
              <button
                onClick={() => {
                  setIsDeleting(index);
                }}
                type="submit"
                style={classes.editButton}
              >
                <Clear sx={classes.removeIcon} />
              </button>
              {isDeleting === index ? (
                <>
                  <Typography
                    variant="body1"
                    sx={{
                      display: 'inline',
                      marginRight: '10px',
                      marginLeft: '10px',
                    }}
                  >
                    Are you sure you want to remove this position?
                  </Typography>
                  <Typography
                    variant="body1"
                    color="error"
                    sx={classes.editButton}
                    onClick={() => {
                      setIsDeleting(false);
                    }}
                  >
                    Cancel
                  </Typography>
                  <Typography
                    variant="body1"
                    color="primary"
                    sx={classes.editButton}
                    onClick={() => {
                      setIsDeleting(false);
                      deleteAction(index);
                    }}
                  >
                    Confirm
                  </Typography>
                </>
              ) : null}
            </div>
          ) : null}
          <Typography sx={classes.positionText}>{exp.position}</Typography>
          <Typography>{exp.company}</Typography>
          <Typography>
            {`${exp.startDate} - ${exp.endDate ? exp.endDate : 'Present'}`}
          </Typography>
          {exp.notes ? (
            <ul style={classes.bulletList}>
              {exp.notes.map((bullet) => (
                <li key={bullet}>{bullet}</li>
              ))}
            </ul>
          ) : null}
        </div>
      ))}
    </div>
  );
}

export default AdvisorExperience;
