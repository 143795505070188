import React from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import Box from '@mui/material/Box';

const useStyles = () => ({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: '97px',
    background: '#FBFCFD',
    borderRadius: '12px',
    justifyContent: 'space-between',
    marginTop: '18px',
    marginLeft: '20px',
    marginRight: '20px',
    paddingLeft: '21px',
    paddingRight: '21px',
    '&:hover': {
      backgroundColor: '#E0E9F8',
      boxShadow: 'none',
    },
    cursor: 'pointer',
  },
  image: {
    width: '70px',
    height: '70px',
    borderRadius: '8px',
  },
  sendIconContainer: {
    width: '34px',
    height: '34px',
    background: '#DAE1F1',
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  companyName: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '-0.25px',
    color: '#232B35',
    marginLeft: '20px',
  },
});

const BoardCard = ({ logo, companyName, onClick, isOpen, setCollapseOpen }) => {
  const classes = useStyles();
  const trimmedCompanyName = companyName
    ? companyName.length > 20
      ? companyName.substring(0, 20) + '...'
      : companyName
    : '';

  return (
    <Box sx={classes.container}>
      <div
        onClick={onClick}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'start',
          width: '80%',
        }}
      >
        {logo && <img src={logo} alt="Company Logo" style={classes.image} />}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={classes.companyName}>{trimmedCompanyName}</div>
        </div>
      </div>
      <div style={classes.sendIconContainer}>
        {isOpen ? (
          <ExpandMore
            onClick={() => setCollapseOpen(false)}
            sx={classes.menuLabel}
            fontSize="small"
          />
        ) : (
          <ExpandLess
            onClick={() => setCollapseOpen(true)}
            sx={classes.menuLabel}
            fontSize="small"
          />
        )}
      </div>
    </Box>
  );
};

export default BoardCard;
