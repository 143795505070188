import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  useMediaQuery,
  Dialog,
  Typography,
  Button,
  CircularProgress,
} from '@mui/material';
import { Clear } from '@mui/icons-material';
import ACSnackbar from './../../ACSnackbar';
import { ReactComponent as ACCheckCircleIcon } from './../../../icons/ACCheckCircleIcon.svg';
import { useAccount } from './../../../contexts/Account';
import { getAdvisorPlanUpgrades } from './../../../services/advisor_services';
import { mutationConvertCompanyPlanCodeEarly } from './../../../services/company_services';
import eventBus, { eventBusValues } from './../../../eventBus';
import { SnackbarType } from './../../../services/utils/types';
import CompanyOnboardingCall from './../UpgradeCall';

const useStyles = ({ isSM, isXS }) => ({
  dialogContainer: {
    borderRadius: isSM ? '0px' : '15px',
    boxShadow: '0 5px 20px rgba(0, 0, 0, 0.07)',
    padding: isSM ? '5px' : '30px',
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: isSM ? '10px' : '50px',
    paddingRight: isSM ? '10px' : '50px',
  },
  mainContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: isSM ? 'column' : 'row',
    justifyContent: 'space-evenly',
    alignItems: 'flex-center',
    marginTop: isSM ? '5px' : '10px',
    padding: '10px',
  },
  textHeadline: {
    textAlign: 'center',
    fontWeight: 800,
    fontSize: '20px',
    lineHeight: '24px',
    padding: '25px',
    paddingBottom: '5px',
    color: '#232B35',
  },
  textSubHeader: {
    fontSize: '16px',
    padding: '15px',
    lineHeight: '24px',
    textAlign: 'center',
    fontWeight: 400,
    color: '#232B35',
  },
  perksContainer: {
    width: isSM ? '100%' : null,
    display: 'flex',
    textAlign: isSM ? 'center' : 'left',
    padding: isSM ? '10px 5px 25px 5px' : '15px',
    flexDirection: 'column',
  },
  perksList: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    textAlign: 'left',
  },
  plansContainer: {
    width: isSM ? '100%' : null,
    display: 'flex',
    textAlign: isSM ? 'center' : 'left',
    padding: isSM ? '10px 25px 10px 40px' : '20px 45px',
    flexDirection: 'column',
    alignItems: 'center',
  },
  topButtonText: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 500,
    color: '#232B35',
    paddingBottom: '8px',
    display: 'flex',
  },
  bottomButtonText: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '24px',
    marginTop: '5px',
    color: '#232B35',
  },
  moneyText: {
    fontSize: '24px',
    fontWeight: 800,
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    color: '#232B35',
  },
  sideText: {
    fontSize: '10px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  planOption: {
    width: isXS ? '100%' : '225px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: isXS ? 'center' : 'space-between',
    alignItems: 'flex-center',
    cursor: 'pointer',
    backgroundColor: 'white',
    marginBottom: '20px',
    borderRadius: '15px',
    outline: 'none',
    background: 'none',
    '& :focus': {
      outline: 'none',
    },
    position: 'relative',
  },
  upgradeContainer: {
    marginTop: isSM ? '-5px' : '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonText: {
    fontWeight: 700,
    fontSize: '15px',
  },
  savePill: {
    fontSize: '14px',
    fontWeight: 700,
    background: '#6736FA',
    borderRadius: '20px',
    padding: '5px 11px',
    color: 'white',
    whiteSpace: 'nowrap',
    position: 'absolute',
  },
  currentPlanText: {
    fontSize: '10px',
    fontWeight: 400,
    lineHeight: '24px',
    color: '#232B35',
    position: 'absolute',
  },
  textNotice: {
    fontSize: isSM ? '10px' : '12px',
    marginBottom: isSM ? '5px' : '0px',
    marginTop: '15px',
    textAlign: 'center',
    color: '#646D7A',
    lineHeight: '18px',
    fontWeight: 400,
  },
  perkItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: isSM ? '10px' : '20px',
  },
  checkIcon: {
    marginRight: '12px',
    height: '100%',
    width: '38px',
  },
  perkText: {
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '22px',
    color: '#232B35',
  },
  clearIcon: {
    margin: '5px 5px 0 5px',
    float: 'right',
    cursor: 'pointer',
  },
  iframeContainer: {
    position: 'relative',
    paddingBottom: isSM ? '0px' : '50%',
    height: isSM ? '100%' : '0px',
    width: isSM ? '100vw' : '80vmax',
    overflow: 'hidden',
  },
  iframe: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    overflowX: 'visible',
  },
  mainButton: {
    borderWidth: '2px',
    padding: '15px 50px 15px 50px',
    textTransform: 'none',
    height: '45px',
    borderRadius: '40px',
    borderColor: '#3171F6',
  },
});

const errorCodes = {
  recurlyError: 'RECURLY_ERROR',
  dynamoError: 'DYNAMO_DB_ERROR',
  salesforceError: 'SALESFORCE_ERROR',
};

const FreeTrialModalAdvisor = () => {
  const { t } = useTranslation();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const classes = useStyles({ isSM, isXS });
  const [openModal, setOpenModal] = useState(false);

  const [snackMessage, setSnackMessage] = useState('');
  const [snackbarSuccess, setSnackbarSuccess] = useState(false);
  const [snackbarError, setSnackbarError] = useState(false);

  const [currentPlan, setCurrentPlan] = useState({});
  const [upgradePlan, setUpgradePlan] = useState(false);
  const { accountDetails, editAccountInformation } = useAccount();
  const { search } = useLocation();

  const [selectedPlan, setSelectedPlan] = useState(0);
  const [loading, setLoading] = useState(false);

  const [onboardingUrl, setOnboardingUrl] = useState(false);

  useEffect(() => {
    eventBus.on(eventBusValues.triggerCompanyUpgradeAction, (data) => {
      setOpenModal(true);
    });

    const profileParam = new URLSearchParams(search).get('ft-pop-up');
    if (profileParam === 'banner') {
      setOpenModal(true);
    } else if (profileParam === 'gate') {
      setOpenModal(true);
    }

    return () => {
      eventBus.remove(eventBusValues.triggerCompanyUpgradeAction);
    };
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (accountDetails) {
        const planResponses = await getAdvisorPlanUpgrades({
          RECURLY_PLAN_CODE: accountDetails.recurlyPlanCode,
        });
        if (planResponses.data && planResponses.data.getFreeTrialUpgrades) {
          const planCodesArr = planResponses.data.getFreeTrialUpgrades;
          setCurrentPlan(planCodesArr[0]);
          if (planCodesArr.length > 1) {
            setUpgradePlan(planCodesArr[1]);
          }
        }
      }
    }
    fetchData();
  }, [accountDetails]);

  function handleClose() {
    setOpenModal(false);
    setSelectedPlan(0);
  }

  async function upgradeCall() {
    if (selectedPlan && !loading) {
      setLoading(true);
      mutationConvertCompanyPlanCodeEarly({
        COMPANY_ID: accountDetails.id,
        COMPANY_CONTACT_ID: accountDetails.contacts[0].id,
        IS_UPGRADE: selectedPlan === 2,
      })
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.convertCompanyPlanCodeEarly
          ) {
            const newCompany = response.data.convertCompanyPlanCodeEarly;
            setSnackMessage(
              'Your account has been upgraded, you now have full access to AdvisoryCloud'
            );
            setSnackbarSuccess(true);
            setLoading(false);
            setOnboardingUrl(true);
            editAccountInformation(
              {
                freeTrialEndsOn: newCompany.freeTrialEndsOn,
                recurlyPlanCode: newCompany.recurlyPlanCode,
              },
              true
            );
            // handleClose();
          }
        })
        .catch((e) => {
          try {
            const err = JSON.parse(e.errors[0].message);
            if (err.code === errorCodes.recurlyError) {
              setSnackMessage(t('FREE_TRIAL_CONVERTION_ERROR_RECURLY'));
              setSnackbarError(true);
            } else if (err.code === errorCodes.dynamoError) {
              setSnackMessage(t('FREE_TRIAL_CONVERTION_ERROR_DYNAMO'));
              setSnackbarError(true);
            } else if (err.code === errorCodes.salesforceError) {
              setSnackMessage(
                'Your account has been upgraded, you now have full access to AdvisoryCloud'
              );
              setSnackbarSuccess(true);
            } else {
              setSnackMessage(t('FREE_TRIAL_CONVERTION_ERROR_UNEXP'));
              setSnackbarError(true);
            }
            setLoading(false);
            handleClose();
          } catch (err) {
            setSnackMessage(t('FREE_TRIAL_CONVERTION_ERROR_UNEXP'));
            setSnackbarError(true);
            setLoading(false);
          }
        });
    } else {
      setSnackMessage('Select an upgrade option to continue');
      setSnackbarSuccess(true);
    }
  }

  function getPlanBorder(selected) {
    if (selected) {
      return { border: '3px #3171F6 solid', padding: '10px 35px 10px 13px' };
    }
    return { border: '1px #C7D0DE solid', padding: '12px 37px 12px 15px' };
  }

  function getPillPosition(selected) {
    if (selected) {
      return {
        top: '-15px',
        right: '-25px',
      };
    }
    return {
      top: '-13px',
      right: '-23px',
    };
  }

  function getCurrentPlanPosition(selected) {
    if (selected) {
      return {
        top: '-24px',
        left: '8px',
      };
    }
    return {
      top: '-22px',
      left: '10px',
    };
  }

  function getMonthlyOffer(price) {
    try {
      return Math.floor(price / 12);
    } catch (error) {
      return '???';
    }
  }

  function getPercentage() {
    try {
      const monthlyPerYear = currentPlan.recurlyPlanCodePrice * 12;
      const percentage = upgradePlan.recurlyPlanCodePrice / monthlyPerYear;
      return Math.floor((1 - percentage) * 100);
    } catch (error) {
      return '??';
    }
  }

  function getButtonBackground() {
    if (selectedPlan) {
      return '#3171F6';
    }
    return 'white';
  }

  function getButtonColor() {
    if (selectedPlan) {
      return '#FFFFFF';
    }
    return '#3171F6';
  }

  return (
    <div>
      <Dialog
        open={openModal}
        fullScreen={isSM}
        onClose={handleClose}
        PaperProps={{
          id: 'freeTrialUpgradeModal',
          sx: classes.dialogContainer,
        }}
      >
        {isSM || onboardingUrl ? (
          <div style={classes.clearIcon}>
            <Clear onClick={handleClose} style={{ float: 'right' }} />
          </div>
        ) : null}
        {onboardingUrl ? (
          <CompanyOnboardingCall
            meetingId="SOIDIV_VABOBCall"
            meetingData="VABOBCall"
            psz="00"
            style="border: 1px solid #d8d8d8; min-width: 290px; max-width: 900px;"
          />
        ) : null}
        {currentPlan && !onboardingUrl ? (
          <div>
            <div style={classes.container}>
              <Typography variant="h1" sx={classes.textHeadline}>
                Unlock full access to start building your advisory board
              </Typography>
              <Typography variant="h2" sx={classes.textSubHeader}>
                Start your paid membership today to get access to all features
                and benefits
              </Typography>
              <div style={classes.mainContainer}>
                <div style={classes.perksContainer}>
                  <Typography
                    variant="h1"
                    style={{
                      fontFamily: 'Poppins',
                      fontSize: 16,
                      fontWeight: 700,
                      lineHeight: '24px',
                      color: '#232B35',
                    }}
                  >
                    {t('FREE_TRIA_MODAL_MEMBERSHIP_V2')}
                  </Typography>
                  <div style={classes.perksList}>
                    <div style={classes.perkItem}>
                      <ACCheckCircleIcon
                        style={{ ...classes.checkIcon, width: '47px' }}
                      />
                      <Typography sx={classes.perkText}>
                        Work with an account manager to publish your profile and
                        assemble your advisory board
                      </Typography>
                    </div>
                    <div style={classes.perkItem}>
                      <ACCheckCircleIcon style={classes.checkIcon} />
                      <Typography sx={classes.perkText}>
                        Post unlimited questions and receive immediate feedback
                        from your advisory board
                      </Typography>
                    </div>
                    <div style={classes.perkItem}>
                      <ACCheckCircleIcon style={classes.checkIcon} />
                      <Typography sx={classes.perkText}>
                        Schedule quarterly advisory board meetings with your
                        advisors moderated by AdvisoryCloud
                      </Typography>
                    </div>
                  </div>
                </div>
                <div style={classes.plansContainer}>
                  <button
                    disabled={loading}
                    type="button"
                    style={{
                      ...classes.planOption,
                      ...getPlanBorder(selectedPlan == 1),
                    }}
                    onClick={() => setSelectedPlan(1)}
                    id="currentPlanButton"
                  >
                    <div>
                      <Typography sx={classes.topButtonText}>
                        {t('MONTHLY_MEMBERSHIP')}
                      </Typography>
                      <Typography sx={classes.moneyText}>
                        {`$${currentPlan.recurlyPlanCodePrice}`}
                        <span style={classes.sideText}>
                          &nbsp;
                          {t('PER_MONTH')}
                        </span>
                      </Typography>

                      <Typography sx={classes.bottomButtonText}>
                        Billed monthly for 12 months
                      </Typography>
                    </div>
                    <Typography
                      sx={classes.currentPlanText}
                      style={getCurrentPlanPosition(selectedPlan == 1)}
                    >
                      {t('CURRENT_PLAN_V2')}
                    </Typography>
                  </button>
                  {upgradePlan ? (
                    <button
                      disabled={loading}
                      type="button"
                      style={{
                        ...classes.planOption,
                        ...getPlanBorder(selectedPlan == 2),
                      }}
                      onClick={() => setSelectedPlan(2)}
                      id="upgradePlanButton"
                    >
                      <div>
                        <Typography sx={classes.topButtonText}>
                          {t('ANNUAL_MEMBERSHIP')}
                        </Typography>
                        <Typography sx={classes.moneyText}>
                          {`$${getMonthlyOffer(
                            upgradePlan.recurlyPlanCodePrice
                          )}`}
                          <span style={classes.sideText}>
                            &nbsp;
                            {t('PER_MONTH')}
                          </span>
                        </Typography>
                        <Typography sx={classes.bottomButtonText}>
                          {t('FREE_TRIAL_MODAL_BILLED_ANNUAL_QTY', {
                            qty: upgradePlan.recurlyPlanCodePrice,
                          })}
                        </Typography>
                      </div>
                      <Typography
                        sx={classes.savePill}
                        style={getPillPosition(selectedPlan == 2)}
                      >
                        {t('FREE_TRIAL_SAVES_PERCENTAGE', {
                          save: getPercentage(),
                        })}
                      </Typography>
                    </button>
                  ) : null}
                </div>
              </div>
              <div style={classes.upgradeContainer}>
                <Button
                  color="primary"
                  size="large"
                  variant={selectedPlan ? 'contained' : 'outlined'}
                  disabled={loading}
                  style={{
                    ...classes.mainButton,
                    cursor: selectedPlan ? 'pointer' : 'not-allowed',
                    backgroundColor: getButtonBackground(),
                  }}
                  className="ftUpgradeButton"
                  onClick={upgradeCall}
                  id="freeTrialUpgradeButton"
                >
                  {loading ? (
                    <CircularProgress
                      style={{ marginRight: '35px', marginLeft: '35px' }}
                    />
                  ) : (
                    <Typography
                      sx={classes.buttonText}
                      style={{
                        color: getButtonColor(),
                      }}
                    >
                      {t('UPGRADE-NOW')}
                    </Typography>
                  )}
                </Button>
                <Typography sx={classes.textNotice}>
                  {t('FREE_TRIAL_MODAL_UPGRADE_NOTICE_V2')}
                </Typography>
              </div>
            </div>
          </div>
        ) : null}
      </Dialog>
      <ACSnackbar
        open={snackbarSuccess}
        text={snackMessage ? t(snackMessage) : ''}
        severity={SnackbarType.SUCCESS}
        onClose={() => {
          setSnackbarSuccess(false);
        }}
        autoHideDuration={6000}
      />
      <ACSnackbar
        open={snackbarError}
        text={snackMessage ? t(snackMessage) : ''}
        severity={SnackbarType.ERROR}
        onClose={() => {
          setSnackbarError(false);
        }}
        autoHideDuration={6000}
      />
    </div>
  );
};

export default FreeTrialModalAdvisor;
